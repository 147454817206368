/**
 * Machine names for filtering criterias for activity page (between tabs).
 * @note Those values are used as 'route' names (e.g. '/newsFeed/all', etc.)
 * @see ActivityPage.js
 * @see FilterTabs.js
 */
enum FilterType {
  /**
   * ACTIVITIES = all, because of usage route names as filtering criteria.
   * On url /newsFeed/all should be displayed all activities accept messages.
   */
  ACTIVITIES = 'all',
  LIKES = 'likes',
  VIEWS = 'view',
  FAVORITES = 'favourite',
  OTHER = 'other',
  INCOME = 'income',
  OUTCOME = 'outcome',
}

export default FilterType;
