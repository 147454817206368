import {useQuery} from '@apollo/client';

import PROCESSING_STATUS_QUERY from '../graphql/queries/processingStatus.gql';

/**
 * Component provides user current state according to payment process.
 * processingStatus will be used by different components to react on status change.
 * @see setPaymentProcessingStatus
 * @returns {String} Processing status
 */
const usePaymentProcessingStatus = () => {
  const {data} = useQuery(PROCESSING_STATUS_QUERY);

  const {
    payment: {processingStatus},
  } = data;

  return processingStatus;
};

export default usePaymentProcessingStatus;
