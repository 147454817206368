import React, {forwardRef} from 'react';

import type {SpacingProps} from './Spacing';
import Spacing from './Spacing';
import SpacingSize from '../../constants/SpacingSize';

const SpacingShort = forwardRef<HTMLDivElement, Omit<SpacingProps, 'size'>>(
  (props, ref) => <Spacing {...props} ref={ref} size={SpacingSize.SHORT} />,
);

export default SpacingShort;
