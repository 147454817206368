import type {FC, HTMLAttributes, ReactNode} from 'react';
import React from 'react';
import cn from 'classnames';

import SpacingSize from '../../constants/SpacingSize';
import css from './Action.css';

export interface ActionProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
  spacing?: SpacingSize;
  vertical?: boolean;
}

/**
 * Single item inside actions
 * @see Actions
 */
const Action: FC<ActionProps> = ({
  children,
  className,
  spacing = SpacingSize.NORMAL,
  vertical = false,
  ...props
}) => (
  <div
    className={cn(
      spacing && css[spacing],
      css.action,
      vertical && css.vertical,
      className,
    )}
    {...props}
  >
    {children}
  </div>
);

export default Action;
