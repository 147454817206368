import type {FC, ReactNode} from 'react';
import {useContext, useEffect} from 'react';

import PopupLoadTrackingContext from './PopupLoadTrackingContext';

type PopupLoadTrackerProps = {
  children?: ReactNode;
};

/**
 * Is used to track popup load completion.
 * Render this component together with loaded popup content to track properly.
 *
 * Also {@see PopupLoadTrackingProvider} and it's usage.
 */
const PopupLoadTracker: FC<PopupLoadTrackerProps> = ({children}) => {
  const trackLoadComplete = useContext(PopupLoadTrackingContext);

  useEffect(() => {
    trackLoadComplete();
  }, [trackLoadComplete]);

  return children;
};

export default PopupLoadTracker;
