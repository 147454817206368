import {MSG} from '../constants/socketEventNames';

export default {
  CreditsBalanceIncreaseSubscription: {
    incomingEvents: [`${MSG}creditBalanceIncrease`],

    fromServer: (data) => ({
      data: {
        credits: {
          balanceIncrease: {
            ...data,
            __typename: 'BalanceIncrease',
          },
          __typename: 'Credits',
        },
      },
    }),
  },

  CreditsBalanceDecreaseSubscription: {
    incomingEvents: [`${MSG}creditBalanceDecrease`],

    fromServer: (data) => ({
      data: {
        credits: {
          balanceDecrease: {
            ...data,
            __typename: 'BalanceDecrease',
          },
          __typename: 'Credits',
        },
      },
    }),
  },
};
