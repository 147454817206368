import isUndefined from 'lodash/isUndefined';

import logger from '@core/logger';
import createTranslationsMap from '@core/translations/createTranslationsMap';

const getPeriodTranslationByKey = (key, params = {}) => {
  const PERIOD_TRANSLATIONS = createTranslationsMap((t) => ({
    'day.period': t('paymentPagePackage', 'text.per_period_D'),
    'week.period': t('paymentPagePackage', 'text.per_period_W'),
    'month.period': t('paymentPagePackage', 'text.per_period_M'),
    'item.message': t('paymentPagePackage', 'text.per_item_message'),
    // TODO: need for coins model in future implementation
    'item.credit': t('paymentPagePackage', 'text.per_amount', params),
  }));

  return PERIOD_TRANSLATIONS[key];
};

/**
 * @param {String} periodKey
 * @return {String}
 */
const getPeriodTranslation = (periodKey) => {
  if (!periodKey) {
    return '';
  }

  const translation = getPeriodTranslationByKey(periodKey);

  if (!isUndefined(translation)) {
    return translation;
  }

  logger.sendWarning(
    `[getPeriodTranslation] LOST TRANSLATE: (feature: paymentPagePackage, key: ${periodKey})`,
  );

  return periodKey;
};

export default getPeriodTranslation;
