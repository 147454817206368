import {getClientInstance} from '@core/graphql/client';
import PAYMENT_BANNER_OPEN_IN_NEW_TAB_QUERY from '@core/payment/common/graphql/queries/paymentBannerOpenInNewTab.gql';

/**
 * Unset flag isOpenInNewWindow
 */
const optimisticallyResetIsNeedOpenPaymentPageInNewTabFlag = () => {
  getClientInstance().writeQuery({
    query: PAYMENT_BANNER_OPEN_IN_NEW_TAB_QUERY,
    data: {
      userFeatures: {
        paymentBanner: {
          isOpenInNewWindow: false,
          __typename: 'PaymentBanner',
        },
        __typename: 'UserFeatures',
      },
    },
  });
};

export default optimisticallyResetIsNeedOpenPaymentPageInNewTabFlag;
