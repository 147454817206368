import type {PaymentProcessStep} from '../../types/paymentProcess';
import getPaymentDescriptorText from '../../../widgets/descriptor/utils/getPaymentDescriptorText';
import trackDescriptor from '../../../widgets/descriptor/utils/trackPaymentDescriptor';
import getPaymentDescriptorTrackData from '../../../widgets/descriptor/utils/getPaymentDescriptorTrackData';
import isCardMethods from '../../utils/isCardMethods';

/**
 * Send payment descriptor info track data to server
 */
const trackPaymentDescriptor: PaymentProcessStep = async (data) => {
  const {paymentResult, paymentData} = data;
  const {descriptor, companyName, hideDescriptor} =
    getPaymentDescriptorTrackData();

  const {via, method} = paymentData;
  const {orderId} = paymentResult;

  if (!hideDescriptor && isCardMethods(method)) {
    trackDescriptor({
      action: via,
      descriptor,
      descriptorText: getPaymentDescriptorText({descriptor, companyName}),
      orderId,
    });
  }

  return data;
};

export default trackPaymentDescriptor;
