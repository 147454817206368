const TYPES = {
  SPIN_NOW: 'spinNowPopup',
  EXTRA_DISCOUNT: 'extraDiscountPopup',
  EXTRA_DISCOUNT_INTERACTIVE_LIKE: 'extraDiscountInteractiveLikePopup',
  INTERACTIVE_LIKE: 'interactiveLikePopup',
  RM_BUNDLE_POPUP_ONE_MONTH: 'RMBundle1sPopup',
  RM_BUNDLE_POPUP_ONE_WEEK: 'RMBundle2sPopup',
  EXTRA_DISCOUNT_EXPIRED: 'userExtraDiscountExpired',
  PROFILES_VIEW: 'profilesViewPopup',
  PROFILES_VIEW_EXPIRED: 'profilesViewExpired',
  BUY_THREE_DAY: 'buy3DayGet1WeekPopup',
  BUY_ONE_PLUS_ONE: 'buyOnePlusOnePopup',
  PRIORITY_MAN: 'priorityManPopup',
  PRIORITY_MAN_EXPIRED: 'priorityManFeatureEnds',
  SEARCH_LIMITS: 'expandSearchLimitsPopup',
  SEARCH_LIMITS_EXPIRED: 'expandSearchLimitsExpired',
  OPEN_COINS_CONFIRMATION: 'needConfirmEmail',
  SHOW_EXTRA_COINS_POPUP: 'showExtraCoinsPopup',
  CREDITS_BALANCE_INCREASE: 'creditBalanceIncrease',
  CREDITS_BALANCE_DECREASE: 'creditBalanceDecrease',
  SPENDER_SPECIAL_OFFER_POPUP: 'spenderShowSpecialOffer',
  SHOW_BUY_COINS_MOTIVATION_BANNER: 'showBuyCoinsMotivationBanner',
  CHAT_MATCH: 'chatMatch',
};

/**
 * These types of events should be processed instantly without any conditions.
 * They don't open any popups, just complete some pedal logic.
 * @type {string[]}
 */
export const INSTANT_EVENTS = [
  TYPES.EXTRA_DISCOUNT_EXPIRED,
  TYPES.PROFILES_VIEW_EXPIRED,
  TYPES.SEARCH_LIMITS_EXPIRED,
];

/**
 * These type of events are used to resolve appropriate interaction type.
 * @see src/packages/dating/websocket/resolvers/extraEvents.js
 * @type {string[]}
 */
export const NOT_INDEPENDENT_MSG_TYPES = [
  TYPES.EXTRA_DISCOUNT_EXPIRED,
  TYPES.PROFILES_VIEW_EXPIRED,
  TYPES.PRIORITY_MAN_EXPIRED,
  TYPES.SEARCH_LIMITS_EXPIRED,
];

export default TYPES;
