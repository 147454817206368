import type {FC, ReactNode} from 'react';
import React from 'react';

import css from '../styles/PaymentPendingTextWrapp.css';

const PaymentPendingTextWrapp: FC<{children: ReactNode}> = ({children}) => (
  <div className={css.text}>{children}</div>
);

export default PaymentPendingTextWrapp;
