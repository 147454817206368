import type {ReactNode, FC, ReactElement} from 'react';
import React, {isValidElement, Component} from 'react';
import last from 'lodash/last';
import isBoolean from 'lodash/isBoolean';

import logger from '@core/logger';

import {Consumer} from './PopupContext';
/**
 * Use CSS hire only for reason that such styles don't "style" anything.
 * they are like "logc" part of component and we avoid to add inline styles if they
 * have no dynamic.
 */
import css from '../styles/PopupDisplayer.css';
import PopupPriority from '../constants/PopupPriority';
import type {
  PopupComponentOptions,
  WrapPopupOptions,
} from '../types/PopupOptions';
import type PopupData from '../types/PopupData';

type ContentWrapCreator = (
  child: ReactNode,
  options: WrapPopupOptions,
  hidden: boolean,
) => ReactElement;

type PopupDisplayerProps = {
  popupComponent: FC<PopupComponentOptions>;
  wrapContent?: ContentWrapCreator;
};

type PopupDisplayerState = {
  hasError: boolean;
};

// Render the list of popups
const renderList = (
  list: PopupData[],
  wrapContent?: ContentWrapCreator,
): ReactNode => {
  const count = list.length;

  /**
   * When new popup opens, we might still need the previous ones.
   * For example, we need the SearchLocationPicker (opened in popup)
   * to be able to change search filters opened in popup {@see SearchLocation}.
   * That's why we render hidden popups.
   */
  return list.map(({children: Child, options}, i) => {
    const hidden = i < count - 1;

    if (
      hidden &&
      [PopupPriority.LOW, PopupPriority.MIDDLE].includes(options.priority) &&
      !options.wasShown
    ) {
      // Hidden low and middle priority popups don't depend on currently active one, so it's safe to skip rendering.
      return null;
    }

    // Add to options wasShown flag not to rerender shown popups from queue
    Object.assign(options, {
      wasShown: true,
    });

    const child = (
      <div key={options.key} className={hidden ? css.hidden : css.wrapper}>
        {/* @ts-expect-error -- cant add correct type for not correct element */}
        {isValidElement(Child) ? Child : <Child />}
      </div>
    );

    return wrapContent ? wrapContent(child, options, hidden) : child;
  });
};

class PopupDisplayer extends Component<
  PopupDisplayerProps,
  PopupDisplayerState
> {
  constructor(props: PopupDisplayerProps) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(): PopupDisplayerState {
    return {hasError: true};
  }

  componentDidCatch(error: Error) {
    logger.sendError(`[PopupDisplayer] Problem during rendering. ${error}`);
  }

  render() {
    const {popupComponent: Popup, wrapContent} = this.props;

    if (this.state.hasError) {
      return null;
    }

    return (
      <Consumer>
        {({close, list, scrollableRefCallback}) => {
          if (!list.length) {
            return null;
          }

          const {options} = last(list);
          return (
            <Popup
              close={close}
              showCloseButton={options.showCloseButton}
              withInnerScroll={options.withInnerScroll}
              showScrollToTop={options.showScrollToTop}
              lazyScroll={options.lazyScroll}
              disabledCloseButton={options.disabledCloseButton}
              flatCloseButton={options.flatCloseButton}
              // isCloseButtonInverseAppearance - props for Backbone popups
              flatCoverCloseButton={options.flatCoverCloseButton}
              inverseCloseButton={
                isBoolean(options.inverseCloseButton)
                  ? options.inverseCloseButton
                  : options.isCloseButtonInverseAppearance
              }
              fullSize={options.fullSize}
              trackingName={options.trackingName}
              trackingConceptId={options.trackingConceptId}
              fullHeight={options.fullHeight}
              inlineContent={options.inlineContent}
              inverse={options.inverse}
              smallsize={options.small}
              translucent={options.translucent}
              appearFromRight={options.appearFromRight}
              scrollPosition={options.scrollPosition}
              pinnedToLeft={options.pinnedToLeft}
              scrollableRefCallback={scrollableRefCallback}
              ignorePopperInteraction={options.ignorePopperInteraction}
              fullCustomized={options.fullCustomized}
            >
              {renderList(list, wrapContent)}
            </Popup>
          );
        }}
      </Consumer>
    );
  }
}

export default PopupDisplayer;
