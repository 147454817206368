enum RecipientWidgetAppearance {
  /**
   * Regular widget but with reply label.
   */
  WITH_REPLY_LABEL = 'withReplyLabel',

  /**
   * User photos have default size (60x60)
   */
  REGULAR_WIDGET = 'regularWidget',

  /**
   * User photos are minimized up to 40x40 and online badge is moved outside
   * message counter move over user photo
   */
  MINIMIZED_WITH_OUTSIDE_ONLINE = 'minimizedWithOutsideOnline',

  /**
   * Depending on the conditions, we need to show different distance view
   */
  WITH_MESSAGE_PREVIEW = 'withMessagePreview',
}

export default RecipientWidgetAppearance;
