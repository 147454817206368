import {useQuery} from '@apollo/client';

import PAY_ANSWER_QUERY from '../../payProcess/graphql/queries/payAnswer.gql';

/**
 * Return true if user on second stage of compact(mob) payment page.
 * Detect by existing stockId in url.
 * @return {String|null}
 */
const useFailVia = () => {
  const {data} = useQuery(PAY_ANSWER_QUERY);
  return data?.payment.payAnswer?.failPageLogic;
};

export default useFailVia;
