import {useEffect} from 'react';
import defer from 'lodash/defer';

import resetPaymentPackagesCache from '../../../common/utils/resetPaymentPackagesCache';

const useResetPaymentPackagesCache = (needResetCache = false) => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (needResetCache) {
      return () => {
        /**
         * useQuery unmounts, but it does not unsubscribe immediately.
         * Wait some time to avoid re-fetch.
         */
        defer(() => {
          resetPaymentPackagesCache(false);
        });
      };
    }
  }, [needResetCache]);
};

export default useResetPaymentPackagesCache;
