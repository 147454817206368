import type {StoreObject} from '@apollo/client';
import type {DocumentNode} from 'graphql';

import {getClientInstance} from '@core/graphql/client';

type TypeNameItemsParams<VariablesType> = {
  typename: string;
  query: DocumentNode;
  setVariables: (item: StoreObject) => VariablesType;
};

const getTypeNameItems = <QueryType, VariablesType>({
  typename,
  query,
  setVariables,
}: TypeNameItemsParams<VariablesType>): QueryType[] => {
  const client = getClientInstance();
  const cached = client.cache.extract();

  return (
    Object.values(cached)
      // eslint-disable-next-line no-underscore-dangle
      .filter((item) => item.__typename === typename)
      .map((item) =>
        client.readQuery<QueryType, VariablesType>({
          query,
          variables: setVariables(item),
        }),
      )
      .filter(Boolean)
  );
};

export default getTypeNameItems;
