import {mergeArrayFieldUniq as merge} from '../utils/mergeArrayField';

export default {
  Query: {
    fields: {
      activityUsers: {
        read(activityUsers = []) {
          return activityUsers;
        },
        merge,
      },
      allActivities: {
        read(activities = []) {
          return activities;
        },
        merge,
      },
    },
  },
};
