/**
 * Input border type
 * @see InputFrame.tsx
 */
enum InputBorder {
  DEFAULT = 'default',
  NONE = 'unbordered',
  FULL = 'full', // border on all element directions (top, left, bottom, right)
}

export default InputBorder;
