import getTimeInSeconds from '@core/utils/timer/getTimeInSeconds';

/**
 * Provides default values for lastMessage when there is no lastMessage
 * in the answer from the server for a new recipient.
 */
const getEmptyLastMessage = (recipientId) => {
  return {
    id: recipientId,
    timestamp: getTimeInSeconds(),
    type: '',
    text: '',
    isSending: false,
    __typename: 'LastMessage',
  };
};

export default getEmptyLastMessage;
