import forEach from 'lodash/forEach';

/**
 * Reset style of photo to default
 * Drop down scale of photo to original
 * @see Gallery.js
 * @param galleryElem
 */
const resetPhotoElements = (galleryElem) => {
  const defaultTransform = 'translate3d(0px, 0px, 0px) scale(1)';
  const photoList = galleryElem.querySelectorAll('img');
  forEach(photoList, (image) => {
    const {style} = image;
    if (style.transform !== defaultTransform) {
      style.transform = defaultTransform;
    }
  });
};

export default resetPhotoElements;
