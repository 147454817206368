import first from 'lodash/first';

import {TRACK_PAYMENT_PENDING_PAGE_STEP} from '@core/tracking/constants/identities';

type DataItem = {
  orderId: string;
  step: string;
};

type PrepareDataParams = DataItem[];

// Convert data to tracking friendly format for backend
const prepareData = (
  dataList: PrepareDataParams,
): Promise<{
  trackingName: string;
  type: string;
  orderId: string;
  step: string;
}> => {
  const item = first(dataList);

  return Promise.resolve({
    trackingName: TRACK_PAYMENT_PENDING_PAGE_STEP.METHOD,
    type: TRACK_PAYMENT_PENDING_PAGE_STEP.METHOD,
    orderId: item.orderId,
    step: item.step,
  });
};

export default prepareData;
