import t from '@core/translations/translate';

/**
 * Checks if a browser tab is currently open
 */
export const isTabOpen = (tab: Window) => {
  return tab && !tab.closed;
};

export const closeTab = (tab: Window) => {
  isTabOpen(tab) && tab.close();
};

/**
 * Opens new empty tab. Useful for cases when we get new tab's url using async action.
 * "Loading..." text is used to let user know something is happening now.
 */
const openLoadingWindow = (): Window => {
  const tab = window.open('');
  if (tab) {
    const text = t('popup', 'title.loading');
    tab.document.title = text;
    tab.document.body.innerHTML = text;
  }
  return tab;
};

export default openLoadingWindow;
