import {useEffect} from 'react';
import {useQuery, useReactiveVar} from '@apollo/client';
import {useLocation} from 'react-router-dom';
import isNull from 'lodash/isNull';
import isNil from 'lodash/isNil';

import isPaymentUrl from '@core/utils/url/isPayUrl';
import isAutoRenewalAgreeCheckedVar from '@core/graphql/vars/isAutoRenewalAgreeCheckedVar';
import isAllowedPaymentMethod from '@core/payment/common/utils/isAllowedPaymentMethod';

import usePaymentParams from '../../../common/utils/usePaymentParams';
import isAutoRenewalAgreeAction from './isAutoRenewalAgreeAction';
import useActivePackage from '../../package/utils/useActivePackage';
import AUTO_RENEWAL_AGREE_QUERY from '../graphql/queries/autoRenewalAgree.gql';

// Last active PP action
let lastAction = null;

const useAutoRenewalAgree = () => {
  const {pathname} = useLocation();
  const {activePackage} = useActivePackage();
  const {action} = usePaymentParams();
  const isAutoRenewalAgreeChecked = useReactiveVar(
    isAutoRenewalAgreeCheckedVar,
  );

  const {data, loading, error} = useQuery(AUTO_RENEWAL_AGREE_QUERY, {
    variables: {action},
  });

  const autoRenewalAgreeDefaultValue =
    data?.payment?.templateSettings?.additionalTermsStatusMap?.autoRenewalAgree;

  const isAllowedAutoRenewalAgree = !isNull(autoRenewalAgreeDefaultValue);

  useEffect(() => {
    // Reset isAutoRenewalAgreeChecked value if autoRenewalAgree not allowed
    if (
      !loading &&
      !isAllowedAutoRenewalAgree &&
      !isNull(isAutoRenewalAgreeChecked)
    ) {
      isAutoRenewalAgreeCheckedVar(null);
    }
  }, [loading, isAllowedAutoRenewalAgree, isAutoRenewalAgreeChecked]);

  useEffect(() => {
    /*
      When action of PP is changed we reload autoRenewalAgreement query
      and drop to null reactive variable with state of checkbox
    */
    if (
      (lastAction && action !== lastAction) ||
      (loading && !isNil(isAutoRenewalAgreeChecked))
    ) {
      isAutoRenewalAgreeCheckedVar(null);
    }

    lastAction = action;
  }, [loading, isAutoRenewalAgreeChecked, action]);

  if (loading || error) {
    return {
      isAutoRenewalAgree: false,
      autoRenewalAgreeDefaultValue: null,
      isAutoRenewalAgreeChecked: null,
      setIsAutoRenewalAgreeChecked: () => {},
    };
  }

  const isAutoRenewalAgree =
    !loading &&
    isAllowedAutoRenewalAgree &&
    isPaymentUrl(pathname) &&
    isAllowedPaymentMethod(activePackage, action) &&
    isAutoRenewalAgreeAction(action);

  return {
    isAutoRenewalAgreeChecked,
    setIsAutoRenewalAgreeChecked: isAutoRenewalAgreeCheckedVar,
    isAutoRenewalAgree,
    autoRenewalAgreeDefaultValue,
  };
};

export default useAutoRenewalAgree;
