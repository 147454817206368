import type {FC} from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import type {PlaceholderBarProps} from './PlaceholderBar';
import PlaceholderBar from './PlaceholderBar';
import css from './PlaceholderBar.css';

/**
 * Bar placeholder with additional spaces. Created to fit inputs and buttons height
 */
const Input: FC<PlaceholderBarProps> = ({className, ...props}) => (
  <div className={css.input}>
    <PlaceholderBar {...props} className={cn(className, css.inputBar)} />
  </div>
);

Input.propTypes /* remove-proptypes */ = {
  className: PropTypes.string,
};

export default Input;
