import {from} from 'rxjs';
import {concatMap, map} from 'rxjs/operators';

import ACTIVITY_SUBSCRIPTION from '../graphql/subscriptions/interactionActivity.gql';

let observable;

/**
 * @param {ApolloClient} client
 * @param {DocumentNode} fetchUsersQuery - {@see TargetUsersByIdQuery}
 * @returns {Observable}
 */
const getActivitySubscriptionObservable = (client, fetchUsersQuery) => {
  observable =
    observable ||
    from(
      client.subscribe({
        query: ACTIVITY_SUBSCRIPTION,
      }),
    ).pipe(
      concatMap(({data: {activity}}) =>
        from(
          client.query({
            query: fetchUsersQuery,
            variables: {ids: [activity.userId]},
          }),
        ).pipe(
          map(({data}) => {
            const [user] = data.userList;
            return {
              data: {
                activity,
                user,
              },
            };
          }),
        ),
      ),
    );

  return observable;
};

export default getActivitySubscriptionObservable;
