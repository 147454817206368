import {useQuery, useReactiveVar} from '@apollo/client';
import find from 'lodash/find';

import paymentBaseDataLoadingVar from '@core/graphql/vars/paymentBaseDataLoadingVar';

import PAYMENT_METHODS from '../constants/paymentMethods';
import ALT_METHODS_SETTINGS_QUERY from '../graphql/queries/altMethodsSettings.gql';
import usePaymentParams from './usePaymentParams';
import useFailVia from './useFailVia';

const ALT_METHOD_SETTINGS_MAP = {
  [PAYMENT_METHODS.PAYGARDEN]: 'PaygardenSettings',
  [PAYMENT_METHODS.APPLE_PAY]: 'ApplePaySettings',
  [PAYMENT_METHODS.GOOGLE_PAY]: 'GooglePaySettings',
  [PAYMENT_METHODS.COMM_DOO_SEPA]: 'CommDooSepaSettings',
  [PAYMENT_METHODS.TRUST_PAY_IDEAL]: 'TrustPayIdealSettings',
  [PAYMENT_METHODS.NUVEI_IDEAL]: 'NuveiIdealSettings',
  [PAYMENT_METHODS.NOVAL_NET_SEPA]: 'NovalNetSepaSettings',
  [PAYMENT_METHODS.VENDO_SEPA]: 'VendoSepaSettings',
  [PAYMENT_METHODS.TRUST_PAY_SEPA]: 'TrustPaySepaSettings',
  [PAYMENT_METHODS.COMM_DOO_SEPA]: 'CommDooSepaSettings',
  [PAYMENT_METHODS.MICRO_PAYMENT_SEPA]: 'MicropaymentSepaSettings',
  [PAYMENT_METHODS.PAY_PAL_V2]: 'Paypalv2Settings',
  [PAYMENT_METHODS.SOFORT]: 'SofortSettings',
  [PAYMENT_METHODS.NUVEI_MY_BANK]: 'NuveiMyBankSettings',
  [PAYMENT_METHODS.KONBINI]: 'KonbiniSettings',
  [PAYMENT_METHODS.SALT_EDGE_AIS]: 'SaltEdgeAisSettings',
};

export const getSettingsByMethod = (altMethodsSettings, method) => {
  if (!method || !altMethodsSettings) {
    return null;
  }

  return (
    find(altMethodsSettings, {
      __typename: ALT_METHOD_SETTINGS_MAP[method],
    }) || null
  );
};

const useAltMethodsSettings = ({paymentMethod, skip = false}) => {
  const {action, via, prevVia} = usePaymentParams();
  const baseDataLoading = useReactiveVar(paymentBaseDataLoadingVar);
  const failVia = useFailVia();

  const {data, loading, error} = useQuery(ALT_METHODS_SETTINGS_QUERY, {
    variables: {
      action,
      // Use fail via if it exists for re-fetch calls after decline
      via: failVia || via,
      prevVia: failVia ? via : prevVia,
    },
    skip: skip || baseDataLoading,
    /**
     * cache-only policy is to force catch data from usePaymentPackages on decline
     */
    fetchPolicy: failVia ? 'cache-only' : 'cache-first',
  });

  const settings = getSettingsByMethod(
    data?.payment.packagesData.altMethodsSettings,
    paymentMethod,
  );

  return {settings, loading, error};
};

export default useAltMethodsSettings;
