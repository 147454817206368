import type {FC} from 'react';
import React from 'react';

import type {TextWithIconProps} from '../text/TextWithIcon';
import TextWithIcon from '../text/TextWithIcon';

const HeadingWithIcon: FC<Omit<TextWithIconProps, 'heading'>> = (props) => (
  <TextWithIcon {...props} heading />
);

export default HeadingWithIcon;
