import {ajax} from 'rxjs/ajax';
import values from 'lodash/values';
import last from 'lodash/last';

import getVideoErrorMessage from '@core/mediaUpload/utils/getVideoErrorMessage';
import {VIA_USER_STORIES} from '@core/stories/uploadPopup/constants/storyUploadPopup';

const getUploadUrl = ({via, attachToUser}) => {
  switch (via) {
    case VIA_USER_STORIES:
      return `/api/v1/userStories/videoFragmentUpload?via=${via}&attachToUser=${attachToUser}`;
    default:
      return `/video/upload?via=${via}&attachToUser=${attachToUser}`;
  }
};

/**
 * Upload video
 * @param {Object} props
 * @param {Blob} props.file - Video file
 * @param {String} props.via - Via to upload with. @see viaList
 * @param {Boolean} props.attachToUser - Should it be attached to profile
 * @param {rxjs/Subject} props.progress$ - Progress observable if needed
 * @returns {Promise}
 */
const uploadVideo = ({file, via, attachToUser, progress$}) => {
  const body = new FormData();
  body.append('video-blob', file);

  return new Promise((resolve, reject) => {
    ajax({
      url: getUploadUrl({via, attachToUser}),
      method: 'POST',
      body,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      progressSubscriber: progress$,
    }).subscribe(({response: {data, meta, status}}) => {
      if (status === 'error') {
        getVideoErrorMessage(
          last(values(meta.description.errors)),
          file.name,
        ).then((error) => reject(new Error(error)));
        return;
      }
      const {id, videoUrl, avatar, isConverted, group, duration} = data;
      resolve({
        id,
        url: videoUrl,
        previewUrl: avatar,
        isConverted,
        group,
        duration,
      });
    }, reject);
  });
};

export default uploadVideo;
