import React, {forwardRef} from 'react';
import {Link} from 'react-router-dom';

import type {CommonTabProps} from './types';
import useTabWrapper from './useTabProps';

export interface TabLinkProps extends CommonTabProps {
  to: string;
  /**
   * prevent/allow tabbed navigation appending to browser history
   */
  replace?: boolean;
}

/**
 * Static link with tab appearance
 */
const TabLink = forwardRef<HTMLAnchorElement, TabLinkProps>(
  ({replace = false, ...props}, ref) => {
    const {wrap, ...rest} = useTabWrapper(props);

    return wrap(<Link ref={ref} to={props.to} replace={replace} {...rest} />);
  },
);

export default TabLink;
