import {getClientInstance} from '@core/graphql/client';

import PAY_ANSWER_QUERY from '../../graphql/queries/payAnswer.gql';
import type {PayAnswerQuery} from '../../graphql/queries/payAnswer';
import type {PaymentProcessStep} from '../../types/paymentProcess';

/**
 * Get processing status that indicate start of payment process
 */
const processPaymentAnswer: PaymentProcessStep = async (data) => {
  const {paymentResult} = data;
  const client = getClientInstance();

  const payAnswer = client.readQuery<PayAnswerQuery>({query: PAY_ANSWER_QUERY});

  client.writeQuery<PayAnswerQuery>({
    query: PAY_ANSWER_QUERY,
    data: {
      ...payAnswer,
      payment: {
        ...payAnswer.payment,
        payAnswer: {
          ...payAnswer.payment.payAnswer,
          status: Boolean(paymentResult.status),
          isEmptyFormAfterDecline: paymentResult.isEmptyFormAfterDecline,
          failReason: paymentResult.reason || paymentResult.failPageReason,
          declineMessageType:
            paymentResult?.templateSettings?.declineMessageType || null,
          declineScreenTemplate: paymentResult.declineScreenTemplate,
          orderId: paymentResult.orderId,
          __typename: 'PayAnswer',
        },
        __typename: 'Payment',
      },
    },
  });

  return data;
};

export default processPaymentAnswer;
