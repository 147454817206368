import isNull from 'lodash/isNull';
import merge from 'lodash/merge';

import {ApplePaySDKVersion} from '@core/payment/payProcess/constants/ApplePaySDKVersion';

import {CardWalletAdditionalFieldEnum} from '../../../types/graphql';

/**
 * Checks if Apple Pay is supported in the current browsing context.
 *
 * @returns {boolean} - Returns true if Apple Pay is supported, otherwise false.
 */
export const isApplePaySupported = (): boolean => {
  return 'ApplePaySession' in window;
};

/**
 * This constant stores the latest version of Apple Pay that has been checked
 * for compatibility or feature support in the application.
 * The latest version you may check here:
 * https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_on_the_web_version_history
 *
 * Value: Represents the version number, e.g., 16.
 */
let applePayVersionDetected: number | null = null;

/**
 * Function to get the latest version of Apple Pay supported by the user’s device.
 *
 * This function checks if the Apple Pay version has already been detected and returns it if available.
 * If Apple Pay is not supported on the device, it sets the detected version to 0 and returns it.
 * Otherwise, it iterates from the latest supported version down to the minimum supported version,
 * checking which version is supported by the user's device.
 *
 * @return {number} The latest Apple Pay version supported by the device, or 0 if not supported.
 */
export const getLatestApplePayVersionSupported = () => {
  if (!isNull(applePayVersionDetected)) {
    return applePayVersionDetected;
  }

  if (!isApplePaySupported()) {
    applePayVersionDetected = 0;

    return applePayVersionDetected;
  }

  let version = ApplePaySDKVersion.VERSION_14;
  while (
    !window.ApplePaySession.supportsVersion(version) &&
    version > ApplePaySDKVersion.VERSION_1
  ) {
    --version;
  }
  applePayVersionDetected = version;

  return applePayVersionDetected;
};

type ApplePayPaymentRequest = ApplePayJS.ApplePayPaymentRequest;

type CreateApplePayRequestOptions = {
  request: Partial<ApplePayPaymentRequest>;
  cardWalletAdditionalFields: CardWalletAdditionalFieldEnum[];
};

type VersionConfigGetter = (
  options?: CreateApplePayRequestOptions,
) => Partial<ApplePayPaymentRequest>;

type VersionConfigMap = Record<string, VersionConfigGetter>;

const versionConfigMap: VersionConfigMap = {
  // The 10th version of Apple Pay available in macOS 11 and iOS 14.
  [ApplePaySDKVersion.VERSION_10]: ({cardWalletAdditionalFields}) => {
    const result: Partial<ApplePayPaymentRequest> = {};

    if (
      cardWalletAdditionalFields.includes(
        CardWalletAdditionalFieldEnum.address,
      ) ||
      cardWalletAdditionalFields.includes(CardWalletAdditionalFieldEnum.city) ||
      cardWalletAdditionalFields.includes(
        CardWalletAdditionalFieldEnum.country_code,
      ) ||
      cardWalletAdditionalFields.includes(
        CardWalletAdditionalFieldEnum.name_first,
      ) ||
      cardWalletAdditionalFields.includes(
        CardWalletAdditionalFieldEnum.name_last,
      )
    ) {
      result.requiredBillingContactFields = ['postalAddress', 'name'];
    }

    const requestedPhone = cardWalletAdditionalFields.includes(
      CardWalletAdditionalFieldEnum.address,
    );
    const requestedEmail = cardWalletAdditionalFields.includes(
      CardWalletAdditionalFieldEnum.email,
    );

    if (requestedPhone || requestedEmail) {
      result.requiredShippingContactFields = [];

      if (requestedPhone) {
        result.requiredShippingContactFields.push('phone');
      }

      if (requestedEmail) {
        result.requiredShippingContactFields.push('email');
      }
    }

    return result;
  },
};

/**
 * Creates an Apple Pay payment request based on the provided options.
 *
 * This function takes in options which include the initial Apple Pay request and merges it
 * with configuration settings that are determined by the latest supported Apple Pay version.
 * It ensures that only configurations applicable to the supported Apple Pay version are applied.
 *
 * @param {CreateApplePayRequestOptions} options - The options for creating the Apple Pay request, including the initial request.
 * @returns {Partial<ApplePayPaymentRequest>} - A partial Apple Pay payment request object with the applicable configurations merged.
 */
export const createApplePayRequest = (
  options: CreateApplePayRequestOptions,
): Partial<ApplePayPaymentRequest> => {
  const {request} = options;
  const applePayVersion = getLatestApplePayVersionSupported();
  const result: Partial<ApplePayPaymentRequest> = request;

  const availableVersionConfigGetters: VersionConfigGetter[] = [];
  Object.keys(versionConfigMap).forEach((configVersion) => {
    if (Number(configVersion) <= applePayVersion) {
      availableVersionConfigGetters.push(versionConfigMap[configVersion]);
    }
  });

  try {
    const configParts = availableVersionConfigGetters.map((item) =>
      item(options),
    );

    if (!configParts.length) {
      return result;
    }

    configParts.forEach((config) => {
      merge(result, config);
    });

    return result;
  } catch (err) {
    return result;
  }
};

/**
 * Conditional execution of the Apple Pay feature based on version support.
 *
 * This function returns another function that takes a set of parameters of type CallbackParams.
 * If the provided support version meets or exceeds the latest Apple Pay version supported,
 * it invokes the given callback with those parameters.
 *
 * @param supportVersion - The version of Apple Pay support to compare against the device's latest supported version.
 * @param callback - A callback function to execute if the requirements are met.
 * @returns A function that takes parameters of type CallbackParams and conditionally executes the callback based on the version support check.
 */
export const execApplePayFeature = <CallbackParams = undefined>(
  supportVersion: number,
  callback: (params: CallbackParams) => void,
) => {
  return (params: CallbackParams) => {
    if (supportVersion <= getLatestApplePayVersionSupported()) {
      callback(params);
    }
  };
};
