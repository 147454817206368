type FieldDefaults<T> = {
  [K in keyof T]: T[K];
};

type FieldPolicies<T> = {
  [K in keyof T]: (value?: T[K]) => T[K];
};

/**
 * Helper to simplify `typePolicies` creation when you need only specify default field values.
 */
export default function createDefaults<T extends object>(
  fields: FieldDefaults<T>,
): FieldPolicies<T> {
  const policies = {} as FieldPolicies<T>;
  Object.entries(fields).forEach(([fieldName, defaultValue]) => {
    policies[fieldName] = (value = defaultValue) => value;
  });
  return policies;
}
