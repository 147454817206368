import {getClientInstance} from '@core/graphql/client';
import isOneClickAllowedVar from '@core/graphql/vars/isOneClickAllowedVar';

import IS_ONE_CLICK_ALLOWED_QUERY from '../graphql/queries/isOneClickAllowed.gql';

/**
 * @param {Object} params
 * @param {String} params.source - for different payment logic cam be different result.
 * @param {String} params.via - payment via
 * @param {String} [params.fetchPolicy] - query fetch policy.
 * For now only credits buying has another logic on server
 * @return {Promise<Boolean>}
 */
const isCardOneClickAllowed = async ({
  source,
  via,
  fetchPolicy = 'cache-first',
} = {}) => {
  const {
    data: {
      payment: {isOneClickAllowed},
    },
  } = await getClientInstance().query({
    query: IS_ONE_CLICK_ALLOWED_QUERY,
    variables: {source, via},
    fetchPolicy,
  });

  isOneClickAllowedVar(isOneClickAllowed);

  return isOneClickAllowed;
};

export default isCardOneClickAllowed;
