import type {ComponentType} from 'react';
import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import type ThreeDSecureAbortPopupProps from '../types/threeDSecureAbortPopupProps';

type openThreeDSecureAbortPopupProps = {
  ThreeDSecureAbortPopup: ComponentType<ThreeDSecureAbortPopupProps>;
  abortPayment: () => void;
};

const openThreeDSecureAbortPopup = ({
  ThreeDSecureAbortPopup,
  abortPayment,
}: openThreeDSecureAbortPopupProps): void => {
  const handleBackClick = () => PopupService.closePopup();
  const handleAbortClick = () => {
    PopupService.closePopup(true);
    abortPayment();
  };

  PopupService.openPopup(
    <ThreeDSecureAbortPopup
      onBackClick={handleBackClick}
      onAbortClick={handleAbortClick}
    />,
    {
      trackingName: '3DsAbort',
      autoLoadTracking: true,
    },
  );
};

export default openThreeDSecureAbortPopup;
