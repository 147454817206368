import type {FC, ReactNode} from 'react';
import React from 'react';
import cn from 'classnames';

import type {CSSModule} from '../../types';
import baseCss from './Loader.css';

export interface LoaderProps {
  children?: ReactNode;
  className?: string;
  icon?: ReactNode;
  active?: boolean;
  micro?: boolean;
  small?: boolean;
  medium?: boolean;
  inverse?: boolean;
  fullscreen?: boolean;
  fullsize?: boolean;
  fixed?: boolean;
  text?: ReactNode;
  'data-test'?: string;
}

/**
 * @class Loader
 * @classdesc
 * Wrapping component to display loader around block. Is invisible
 * until you pass positive value inside 'active' prop.
 */
const Loader: FC<
  // `LoaderProps` without `css` inside to make it more suitable for `@phoenix/ui`.
  LoaderProps & {css: CSSModule}
> = ({
  children,
  css,
  className,
  micro,
  small,
  medium,
  inverse,
  active = true,
  fullscreen,
  fullsize,
  fixed,
  text,
  icon,
  'data-test': dataTest = 'loader',
}) => {
  const classNames = cn(
    baseCss.loader,
    !children && baseCss.static, // If no children is passed - loader works not as wrapper, but as standalone block
    active && baseCss.active,
    active && css.active,
    // @todo Replace with one prop "size" like in <Row> or <Spacing> components its done.
    micro && baseCss.micro,
    micro && css.micro,
    small && baseCss.small,
    small && css.small,
    medium && baseCss.medium,
    medium && css.medium,
    inverse && baseCss.inverse,
    inverse && css.inverse,
    text && baseCss.withText,
    fullscreen && baseCss.fullscreen,
    fullsize && baseCss.fullsize,
    fixed && baseCss.fixed,
    className,
  );

  const iconLayout = (
    <span className={cn(baseCss.icon, css.icon)}>
      <span className={css.iconInner}>{icon}</span>
    </span>
  );

  if (text) {
    return (
      <div className={classNames} data-test={dataTest}>
        <span className={baseCss.wrap}>
          {iconLayout}
          <span className={baseCss.text}>{text}</span>
        </span>
        <div className={baseCss.overlay}>{children}</div>
      </div>
    );
  }

  return (
    <div className={classNames} data-test={dataTest}>
      {iconLayout}
      <div className={baseCss.overlay}>{children}</div>
    </div>
  );
};

export default Loader;
