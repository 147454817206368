import {MSG} from '../constants/socketEventNames';

/**
 * After moderator block user, we receive interaction
 */
export default {
  BlockUserForStremingSubscription: {
    incomingEvents: `${MSG}internalVideoMessage`,

    fromServer({action}) {
      if (action !== 'turnOffVideo') {
        return null;
      }

      return {
        data: {
          streaming: {
            blocked: true,
            __typename: 'Streaming',
          },
        },
      };
    },
  },
};
