import get from 'lodash/get';

import logger from '@core/logger';
import {getClientInstance} from '@core/graphql/client';

import PAYMENT_ACCOUNT_STATUS_QUERY from '../graphql/queries/paymentAccountStatus.gql';

/**
 * Utility for reusable check on paid status of user
 * @returns {Object} isPaid
 */
const getPaymentStatus = async () => {
  try {
    const {data} = await getClientInstance().query({
      query: PAYMENT_ACCOUNT_STATUS_QUERY,
    });
    return get(data, 'payment.accountStatus.isPaid', false);
  } catch (e) {
    logger.sendWarning(`[getPaymentStatus] ${e}`);
    return false;
  }
};

export default getPaymentStatus;
