import React, {useMemo} from 'react';

import PaymentParamsProvider from '../containers/PaymentParamsProvider';
import PAYMENT_SOURCES from '../constants/paymentSources';

/**
 * Create and provide payment params based on custom action and via.
 * @param {Function} Component
 * @param {Function|Object} mapPropsToParams
 */
const withStaticPaymentParamsProvider =
  (Component, mapPropsToParams) => (props) => {
    const {
      action,
      via,
      source = PAYMENT_SOURCES.PAYMENT_PAGE,
    } = typeof mapPropsToParams === 'function'
      ? mapPropsToParams(props)
      : mapPropsToParams;

    const params = useMemo(
      () => ({action, via, source}),
      [action, via, source],
    );

    return (
      <PaymentParamsProvider params={params}>
        <Component {...props} />
      </PaymentParamsProvider>
    );
  };

export default withStaticPaymentParamsProvider;
