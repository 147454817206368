import includes from 'lodash/includes';

import logger from '@core/logger';
import {getClientInstance} from '@core/graphql/client';

import PROCESSING_STATUS, {TRANSITIONS} from '../constants/processingStatus';
import PROCESSING_STATUS_QUERY from '../graphql/queries/processingStatus.gql';

/**
 * Component sets user current processingStatus.
 * @see usePaymentProcessingStatus
 * @param {String} value - New processing status value
 * @param {Boolean} force - skip or not the logger
 */
const setPaymentProcessingStatus = (value, force = false) => {
  const client = getClientInstance();
  const data = client.readQuery({query: PROCESSING_STATUS_QUERY});

  const {
    payment: {processingStatus},
  } = data;

  const possibleTransitions = TRANSITIONS[processingStatus];

  /**
   * Do nothing if util was called with the same value.
   * Relevant for cases when it's called by hooks which used in multiple components
   */
  if (processingStatus === value) {
    return;
  }

  if (
    !force &&
    (!possibleTransitions || !includes(possibleTransitions, value))
  ) {
    logger.sendError(
      `[setPaymentProcessingStatus] processingStatus transition from ${processingStatus} to ${value}`,
    );
    return;
  }

  client.writeQuery({
    query: PROCESSING_STATUS_QUERY,
    data: {
      ...data,
      payment: {
        ...data.payment,
        processingStatus: value,
      },
    },
  });
};

/**
 * Loading status indicates load of packages data.
 */
export const setLoading = () => {
  setPaymentProcessingStatus(PROCESSING_STATUS.LOADING);
};

/**
 * Ready to payment
 */
export const setReady = () => {
  setPaymentProcessingStatus(PROCESSING_STATUS.READY);
};

/**
 * Payment in process
 */
export const setProcessing = () => {
  setPaymentProcessingStatus(PROCESSING_STATUS.PROCESSING);
};

/**
 * Payment failed
 */
export const setFailed = () => {
  setPaymentProcessingStatus(PROCESSING_STATUS.FAILED);
};

/**
 * Payment was successful
 */
export const setSuccess = () => {
  setPaymentProcessingStatus(PROCESSING_STATUS.SUCCESS);
};

/**
 * Payment as initial
 */
export const setInitial = (force) => {
  setPaymentProcessingStatus(PROCESSING_STATUS.INITIAL, force);
};

export default setPaymentProcessingStatus;
