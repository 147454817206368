import getUserAgentParser from '@core/utils/getUserAgentParser';

import {getCSSZoom} from './getSizeBasedOnZoom';

const isSafari = ['Safari', 'Mobile Safari'].includes(
  getUserAgentParser().getBrowser().name,
);

const getZoomedSize = (size: number, zoom: number): number =>
  Number((size * zoom).toFixed(2));

const getScroll = (scrollSize: number, zoom: number) => {
  // For unknown reasons, Safari incorrectly calculates scroll height if zoom is bigger than 1.
  if (scrollSize === 0 || !isSafari) {
    return 0;
  }

  const diff = scrollSize * zoom - scrollSize;

  return diff / zoom;
};

const getBoundingClientRect = (element: HTMLElement): DOMRect => {
  const boundingRect = element.getBoundingClientRect();
  const zoom = getCSSZoom();

  /**
   * If 'currentCSSZoom' exists in the documentElement,
   * it means that zoom is included in the 'getBoundingClientRect' calculations,
   * but if 'currentCSSZoom' is not in the document, then we will have to finish it ourselves.
   */
  if (zoom === 1 || 'currentCSSZoom' in document.documentElement) {
    return boundingRect;
  }

  const {width, height, left, top} = boundingRect;
  const zoomedWidth = getZoomedSize(width, zoom);
  const zoomedHeight = getZoomedSize(height, zoom);
  const zoomedLeft = getZoomedSize(
    left + getScroll(window.scrollX, zoom),
    zoom,
  );
  const zoomedTop = getZoomedSize(top + getScroll(window.scrollY, zoom), zoom);
  const zoomedX = zoomedLeft + zoomedWidth;
  const zoomedY = zoomedTop + zoomedHeight;

  return {
    ...boundingRect,
    left: zoomedLeft,
    top: zoomedTop,
    width: zoomedWidth,
    height: zoomedHeight,
    right: zoomedX,
    bottom: zoomedY,
    x: zoomedX,
    y: zoomedY,
  };
};

export default getBoundingClientRect;
