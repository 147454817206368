import type {Client} from '@core/graphql/types';

import COMPLETION_TOTAL_QUERY from '../graphql/queries/completionTotal.gql';
import type {CompletionTotalQuery} from '../graphql/queries/completionTotal';
import PROFILE_COMPLETION_PARAMS from '../graphql/queries/profileCompletionParams.gql';
import type {ProfileCompletionParamsQuery} from '../graphql/queries/profileCompletionParams';

let observer;

/**
 * It starts listener for update profile completion percents
 * witch we're getting from server
 */
const startProfileCompletionListener = (client: Client) => {
  if (!observer) {
    observer = client.watchQuery<ProfileCompletionParamsQuery>({
      query: PROFILE_COMPLETION_PARAMS,
    });

    observer.subscribe(() => {
      /**
       * Update data only if it's used anywhere.
       * @see MediaUploadMotivationWithProfileProgression
       * @see ProfileCompletion
       */
      if (
        client.readQuery<CompletionTotalQuery>({query: COMPLETION_TOTAL_QUERY})
      ) {
        client.query<CompletionTotalQuery>({
          query: COMPLETION_TOTAL_QUERY,
          fetchPolicy: 'network-only',
        });
      }
    });
  }

  return observer;
};

export default startProfileCompletionListener;
