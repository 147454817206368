enum SystemNotificationAppearance {
  /**
   * Notifications will be placed in heading and will shift content
   * when appear.
   */
  FIXED_ON_TOP = 'asList',

  /**
   * Notifications are like floating cards over content
   */
  FLOATING = 'floating',
}

export default SystemNotificationAppearance;
