import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import unionBy from 'lodash/unionBy';

export default {
  UserFeatures: {
    keyFields: [],
    fields: {
      messageAssistant: {
        merge(existing, incoming) {
          const {prevMessage = existing?.message || null} = incoming;
          return {...incoming, prevMessage};
        },
      },
      firstMessageGeneration: {
        merge(existing, incoming) {
          const {reason} = incoming;

          return existing
            ? {
                ...existing,
                ...omitBy(incoming, isNull),
                reason,
              }
            : incoming;
        },
      },
      reelsFeature: {
        merge(existing, incoming) {
          return existing
            ? {
                ...existing,
                reels: unionBy(existing?.reels, incoming.reels, 'videoId'),
              }
            : incoming;
        },
      },
    },
  },
  PersonalIdentity: {
    keyFields: [],
  },
  Reels: {
    fields: {
      isViewed: (isViewed = false) => isViewed,
    },
  },
};
