/**
 * Size variables for photo widgets. Meaning:
 * 1. Normal - all descriptions, overlays and other widgets have all elements
 * 2. Small - not all elements are displayed. All descriptions are shorter.
 * 3. Smallest - the same as 'small' but with decreased size of descriptions
 * 4. NONE - without any descriptions and elements
 */
enum PhotoSize {
  NONE = 'none',
  SMALLEST = 'smallest',
  SMALL = 'small',
  NORMAL = 'normal',
}

export default PhotoSize;
