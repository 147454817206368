import once from 'lodash/once';
import {loadErrorMessages, loadDevMessages} from '@apollo/client/dev';
import type {GraphQLErrors, NetworkError} from '@apollo/client/errors';
import type {Operation, InMemoryCache} from '@apollo/client';
import {ApolloClient, ApolloLink, split} from '@apollo/client';
import {BatchHttpLink} from '@apollo/client/link/batch-http';
import {onError} from '@apollo/client/link/error';
import fetch from 'unfetch';
import get from 'lodash/get';

import logger from '@core/logger';
import {localStorage} from '@core/utils/storage/storage';
import {replaceLocation} from '@core/utils/url';
import {getCookie, setCookie} from '@core/utils/cookie';
import {getAbsoluteUrl} from '@core/utils/url/getUrl';
import {getWebSocketLink, isWebSocketOperation} from '@core/websocket/utils/ws';
import {PAY_CACHE_PERSIST_DISABLED} from '@core/payment/common/constants/cookies';

import type {Client} from './types';
import createCache from './utils/createCache';
import cachePersistManager from './utils/cachePersistManager';
import apolloLogger from './utils/logger/logger';
import accessErrorVar from './vars/accessErrorVar';
import DONT_BATCH_OPERATION_NAMES from './constants/dontBatchOperationNames';
import type {CsrfTokenQuery} from './graphql/queries/csrfToken';
import CSRF_TOKEN_QUERY from './graphql/queries/csrfToken.gql';

declare global {
  const DEV_TOOLS_ENABLED: boolean;
}

if (process.env.NODE_ENV !== 'production') {
  loadDevMessages();
  loadErrorMessages();
}

let client: Client | null;

const CSRF_TOKEN_NAME = 'x-csrf-token';
const API_URL = 'graphql';
const PROFILING_API_URL = 'graphql/profiling';

export const STATE_KEY = '__APOLLO_STATE__';
const RELOAD_ONCE_COOKIE_NAME = 'FieldErrorReloadOnce';
/**
 * 4 hours in seconds
 */
const RELOAD_ONCE_COOKIE_EXPIRES = 14400;

/**
 * Need to add list of operation names form GraphQL queries to measure performance
 * Using to separate some query to another URL (profiling URL) and check performance by XHProf profiling
 */
const PROFILING_STORAGE_KEY = 'graphqlProfiling';

/**
 * Apollo client's `setContext` for some reason duplicates queries when used after other links.
 * That's why custom link is used instead.
 */
const getAuthHeader = (): ApolloLink =>
  new ApolloLink((operation, forward) => {
    const previousContext = operation.getContext();
    const {headers} = previousContext;

    const data = client?.readQuery<CsrfTokenQuery>({query: CSRF_TOKEN_QUERY});

    if (data) {
      operation.setContext({
        ...previousContext,
        headers: {
          ...headers,
          [CSRF_TOKEN_NAME]: data.site.csrfToken,
        },
      });
    }

    return forward(operation);
  });

/**
 * Redirects to the specified url.
 * Redirect only once to avoid useless duplicated redirects
 * in case of multiple graphql requests at the same time.
 */
const redirectOnce = once((url: string) => {
  // Notify about this redirect to help find why it happens
  console.log(`Redirecting to ${url}`);
  replaceLocation(url);
});

const reloadOnce = once(() => {
  console.log('Reloading');
  window.location.reload();
});

/**
 * Custom fetch wrapper to handle redirects via /api/graphql route.
 */
const fetchWithRedirect = async (
  ...args: Parameters<typeof fetch>
): Promise<Response> => {
  const response = await fetch(...args);
  /**
   * Ajax-request redirect to another domain (www -> m) leads to errors.
   * And fetch does not allow to handle it properly,
   * that's why custom `Redirect` header is used here.
   * It is difficult to use response body instead of header, because
   * response.json() / response.text() can only be called once.
   * TODO: remove this support of custom header if we does not have platform redirect anymore.
   */
  let redirect = response.headers.get('Redirect');

  if (!redirect) {
    /**
     * Support of redirect format like in old API.
     * Needed to simplify redirect logic to /usercheck/ on nginx side.
     */
    const res = response.clone ? response.clone() : response;
    try {
      /**
       * response.clone() causes res.json() to never complete for some requests in SERVER_ENVIRONMENT,
       * so we can't use fetchWithRedirect for SSR.
       * TODO: find the reason of this strange bug of node-fetch.
       */
      const data = await res.json();
      ({redirect} = data.meta);
    } catch (e) {
      return response;
    }
  }

  if (redirect) {
    redirectOnce(redirect);
    // Never resolve to avoid useless apollo-client errors while redirecting.
    return new Promise(() => {});
  }

  return response;
};

const getLinkParams = (url: string) => ({
  uri: getAbsoluteUrl(url),
  // See comment near res.json() in fetchWithRedirect
  fetch: SERVER_ENVIRONMENT ? fetch : fetchWithRedirect,
  credentials: 'same-origin' as const,
  headers: {
    // Flag of ajax request for nginx to use custom redirect logic.
    'X-Requested-With': 'XMLHttpRequest',
    ...(SERVER_ENVIRONMENT && global.context.headers),
  },
  ...(SERVER_ENVIRONMENT && {
    fetchOptions: {
      timeout: 30000,
    },
  }),
  batchKey: ({operationName}: Operation) => {
    // Split heavy search request from batch to optimize loading
    return DONT_BATCH_OPERATION_NAMES.includes(operationName)
      ? operationName
      : 'default';
  },
  batchMax: 100,
});

/**
 * Function need for unit test to create isolate instance for each test
 */
const getBatchHttpLink = (url: string): BatchHttpLink =>
  new BatchHttpLink(getLinkParams(url));

let createSsrClient: ((link: ApolloLink) => Client) | undefined;
if (SERVER_ENVIRONMENT) {
  createSsrClient = (link: ApolloLink) =>
    new ApolloClient({ssrMode: true, link, cache: createCache()});
}

/**
 * This ApolloClient instance is used to reuse cached responses
 * while rendering multiple pages in single process.
 */
let commonSsrClient: Client | undefined;

/**
 * This client is used to avoid duplicated requests for SSR.
 * DO NOT USE IT!
 */
export const getCommonSsrClient = (): Client | undefined => {
  if (SERVER_ENVIRONMENT && !commonSsrClient) {
    const errorModificator = onError(
      ({
        graphQLErrors,
        networkError,
        operation: {operationName, query, variables},
      }: {
        graphQLErrors?: GraphQLErrors;
        networkError?: NetworkError & {
          handled?: boolean;
          statusCode?: number;
          bodyText?: string;
          result?: string | Record<string, any>;
        };
        operation: Operation;
      }) => {
        // Display errors here to handle all of them

        if (graphQLErrors) {
          console.error(`GraphQLError '${operationName}':`);
          graphQLErrors.forEach((err) => {
            console.error(err);
          });
        }

        if (networkError && !networkError.handled) {
          networkError.handled = true;
          const {
            statusCode = '',
            stack,
            bodyText = '',
            result = '',
          } = networkError;
          const body = bodyText || (result && JSON.stringify(result));
          const maxSize = 1000;
          console.error(
            `NetworkError ${statusCode}: ${stack.split('\n', 1)[0]}${
              body.length > 0
                ? `\n\nResponse body:\n${
                    body.length > maxSize
                      ? `${body.slice(0, maxSize - 3)}...`
                      : body
                  }`
                : ''
            }\n\nRequest:`,
          );
        }

        let vars = JSON.stringify(variables);
        vars = vars === '{}' ? '' : `${vars}\n`;
        console.error(vars + (query?.loc?.source?.body || ''));
      },
    );

    commonSsrClient = createSsrClient(
      ApolloLink.from([errorModificator, getBatchHttpLink(API_URL)]),
    );
  }

  return commonSsrClient;
};

/**
 * We can't instantly create and return client instance from module due to problem,
 * that we need to use is inside AppView and other global modules (if we try to include
 * module with already created instance we get fatal errors about "non-existing" of AppData,
 * that is used in client headers setting)
 *
 * Encapsulate creating all dependencies needed for ApolloClient
 * inside one function to create isolated instance for each test
 * @returns {Object}
 */
export const createClient = ({
  getOptimizationLink,
}: {
  getOptimizationLink?: (cache: InMemoryCache) => ApolloLink;
} = {}): Client => {
  if (SERVER_ENVIRONMENT) {
    return createSsrClient(
      /**
       * getCommonSsrClient - is the optimization to don't waste time
       * waiting for the same network requests for each page we render.
       * But we can't use single client instance for all pages to avoid useless data in cache.
       * So use new client with separate cache for each page.
       * WARNING: The client.query() doesn't work properly with this link, so do not use it.
       */
      new ApolloLink((operation) =>
        getCommonSsrClient().watchQuery<never>(operation),
      ),
    );
  }

  const cache = createCache();

  if (!getCookie(PAY_CACHE_PERSIST_DISABLED)) {
    cachePersistManager.initialize(cache);
  }

  if (window && window[STATE_KEY]) {
    cache.restore(window[STATE_KEY]);
  }

  const innerClient = new ApolloClient({
    link: ApolloLink.from([
      onError(({graphQLErrors, networkError, operation}) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({message, locations, path, extensions}) => {
            const category = get(extensions, 'category', null);

            if (category === 'ACL') {
              /**
               * AppDataQuery is used as the initial query for authenticated user.
               * But we request it for guest too to avoid additional query
               * to check is user authenticated. We know about USER_IS_GUEST errors
               * in this case, so it's not error, actually.
               */
              if (
                message === 'USER_IS_GUEST' &&
                operation.operationName === 'AppDataQuery'
              ) {
                return;
              }

              accessErrorVar({
                accessError: message,
                /**
                 * Sometimes error message processing depends on variables
                 * @see accessErrorCodeMap (VIEW_PROFILE_RESTRICTION)
                 */
                accessErrorData: {...operation.variables},
              });
              return;
            }

            if (message === 'CSRF_TOKEN_NOT_MATCHED') {
              innerClient
                .query<CsrfTokenQuery>({
                  query: CSRF_TOKEN_QUERY,
                  fetchPolicy: 'network-only',
                })
                .then(() => {
                  /**
                   * So that mutations that are performed asynchronously do not disappear
                   * and mutations with an optimistic answer that have disappeared by the csrf token
                   */
                  const mutation = {
                    ...operation.query,
                    [operation.operationName]: operation.query,
                  };
                  innerClient.mutate<never>({
                    mutation,
                    variables: operation.variables,
                  });
                });

              return;
            }

            /**
             * It will make one reload per four hours to get new frontend, if user has wrong field name in query
             * (there is an old field name on frontend and this filed has deleted on backand for example we need to get
             * new frontend without this wrong field)
             */
            if (
              message.startsWith('Cannot query field') &&
              !getCookie(RELOAD_ONCE_COOKIE_NAME)
            ) {
              setCookie(RELOAD_ONCE_COOKIE_NAME, '1', {
                expires: RELOAD_ONCE_COOKIE_EXPIRES,
              });

              logger.sendError(
                `[GraphQL error with reload page]: Message: ${message}, Location: ${JSON.stringify(
                  locations,
                )}, Path: ${path}`,
              );

              reloadOnce();

              return;
            }

            /**
             * All other errors, expect ACL must be logged.
             * Because we don't know what is it, and it's not a normal application behaviour.
             */
            logger.sendError(
              `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
                locations,
              )}, Path: ${path}`,
            );
          });
        }

        if (networkError) {
          logger.sendInfo(`[Network error]: ${networkError}`);
        }
      }),
      split(
        ({operationName}) => isWebSocketOperation(operationName),
        getWebSocketLink(),
        ApolloLink.from([
          ...[getOptimizationLink?.(cache)].filter(Boolean),
          apolloLogger,
          getAuthHeader(),
          split(
            // split based on operation type
            ({operationName}) => {
              const operationNames = localStorage
                .getItem(PROFILING_STORAGE_KEY, '')
                .split(';');
              return operationNames.includes(operationName);
            },
            getBatchHttpLink(PROFILING_API_URL),
            getBatchHttpLink(API_URL),
          ),
        ]),
      ),
    ]),
    cache,
    connectToDevTools:
      DEV_TOOLS_ENABLED || Boolean(getCookie('apolloDevToolsAllowed')),
  });

  return innerClient;
};

/**
 * Get client instance. Works as singleton.
 * Used only inside GraphQLProvider (but there we can directly pass client instance)
 *
 * WARNING: client.query() doesn't work properly for SSR, so DO NOT USE IT.
 *
 * @param [options] - See options usage in src/packages/dating/graphql/utils/setupClient.ts.
 * @param [options.getOptimizationLink]
 */
export const getClientInstance = (options?: {
  getOptimizationLink?: (cache: InMemoryCache) => ApolloLink;
}): Client => {
  if (!client) {
    client = createClient(options);
  }

  return client;
};

/**
 * Allows to destroy client.
 * Useful to create another client with clean cache for SSR.
 */
export const destroyClient = (): void => {
  client = null;
};
