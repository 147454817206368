import isObject from 'lodash/isObject';

import type {RequestBody} from '@core/utils/fetch/fetch';

/**
 * Convert data to backend-readable form
 * for future usage inside 'fetch'
 */
const prepareBody = (data: RequestBody): string =>
  Object.keys(data)
    .map((key) => {
      let value = data[key];
      if (isObject(value)) {
        value = encodeURIComponent(JSON.stringify(value));
      }

      return `${key}=${value}`;
    })
    .join('&');

export default prepareBody;
