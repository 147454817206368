import type {FC, HTMLAttributes} from 'react';
import React from 'react';
import cn from 'classnames';

import css from './InputIconRipple.css';

export interface InputIconRippleProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  inverse?: boolean;
  active?: boolean;
  focused?: boolean;
  hover?: boolean;
  animated?: boolean;
}

/**
 * InputIconRipple is used rarely, for e.g.
 * @see Radio.tsx
 * @see Checkbox.tsx
 */
const InputIconRipple: FC<InputIconRippleProps> = ({
  className,
  inverse = false,
  focused = false,
  hover = false,
  animated = false,
  active = false,
  ...props
}) => (
  <div
    className={cn(
      hover && css.hover,
      inverse && css.inverse,
      active && css.active,
      focused && css.focused,
      animated && css.animated,
      css.container,
      className,
    )}
    {...props}
  />
);

export default InputIconRipple;
