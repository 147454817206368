import {TRACK_PAYMENT_PENDING_PAGE_STEP} from '@core/tracking/constants/identities';

export default {
  TrackPaymentPendingPageStepMutation: {
    toServer: (params) => ({
      method: TRACK_PAYMENT_PENDING_PAGE_STEP.METHOD,
      params,
    }),

    fromServer: (response, error) => ({
      data: {
        trackPaymentPendingPageStep: {
          error: error || null,
          __typename: 'TrackPaymentPendingPageStepMutationResult',
        },
      },
    }),
  },
};
