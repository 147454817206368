import type {FC, ReactNode} from 'react';
import React from 'react';

import css from '../styles/PaymentPendingProgressWrapp.css';

const PaymentPendingProgressWrapp: FC<{children: ReactNode}> = ({children}) => (
  <div className={css.progress}>{children}</div>
);

export default PaymentPendingProgressWrapp;
