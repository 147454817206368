import {makeVar} from '@apollo/client';

type DescriptorData = {
  descriptor?: string;
  companyName?: string | null;
  hideDescriptor?: boolean;
};

const paymentDescriptorDataVar = makeVar<DescriptorData | null>(null);

export default paymentDescriptorDataVar;
