import CONFIRMATION_QUERY from '../graphql/queries/confirmation.gql';

/**
 * @param {ApolloClient} client
 * @param {String} email
 * @param {String} service
 */
const updateEmailDataInCache = (client, email, service) => {
  const queryData = client.readQuery({
    query: CONFIRMATION_QUERY,
  });

  if (!queryData) {
    // Do nothing. If there is no data in cache - it will be fetched in other place with actual values.
    return;
  }

  client.writeQuery({
    query: CONFIRMATION_QUERY,
    data: {
      ...queryData,
      userFeatures: {
        ...queryData.userFeatures,
        emailData: {
          ...queryData.userFeatures.emailData,
          email,
          service,
        },
      },
    },
  });
};

export default updateEmailDataInCache;
