import {getClientInstance} from '@core/graphql/client';
import CSRF_TOKEN_QUERY from '@core/graphql/graphql/queries/csrfToken.gql';
import type {CsrfTokenQuery} from '@core/graphql/graphql/queries/csrfToken';

import type {BodyParams} from './nestedObjectForUrlSearchParams';
import nestedObjectForUrlSearchParams from './nestedObjectForUrlSearchParams';

type FetchDataParams = {
  url: string;
  body?: BodyParams;
  signal?: AbortSignal;
};

/**
 * Wrapper over fetch(Fetch API) with CSRF token.
 */
const fetchData = ({url, body, signal}: FetchDataParams): Promise<Response> => {
  const bodyAsUrlSearchParams = body
    ? new URLSearchParams(nestedObjectForUrlSearchParams(body))
    : new URLSearchParams();

  bodyAsUrlSearchParams.append(
    'CSRF_TOKEN',
    getClientInstance().readQuery<CsrfTokenQuery>({
      query: CSRF_TOKEN_QUERY,
    })?.site?.csrfToken,
  );

  return fetch(url, {
    body: bodyAsUrlSearchParams,
    headers: {
      accept: 'application/json, text/javascript, */*; q=0.01',
      'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'x-requested-with': 'XMLHttpRequest',
    },
    method: 'POST',
    ...(signal ? {signal} : null),
  });
};

export default fetchData;
