import {useCallback, useEffect, useRef, useState} from 'react';
import type {Observable} from 'rxjs';
import {Subject} from 'rxjs';
import {distinctUntilChanged, startWith} from 'rxjs/operators';
import memoize from 'lodash/memoize';
import isEqual from 'lodash/isEqual';

import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import {CURRENT_USER_ID} from '@core/application/constants/bootstrapParams';

import {localStorage, sessionStorage} from './storage';

const memoizedStorageSubject = memoize(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  <T>(_key: string, _isSessionStorage: boolean) => new Subject<T>(),
  // Resolver used to prevent conflicts between different storages with the same key
  (key, withSessionStorage) =>
    `${key}:${withSessionStorage ? 'sessionStorage' : 'localStorage'}`,
);

const getStorageObservable = <T>(
  storageKey: string,
  withSessionStorage = false,
): Subject<T> => {
  return memoizedStorageSubject(storageKey, withSessionStorage);
};

/**
 * Get personalized storage key
 */
const getStorageKey = (storageKey: string): string => {
  const userId = getBootstrapParam(CURRENT_USER_ID);
  return userId ? `${storageKey}:${userId}` : storageKey;
};

/**
 * Base util to write data to selected storage
 */
const writeToStorage = <T = string>(
  storageKey: string,
  value: T,
  withSessionStorage = false,
  silent = false,
): void => {
  const key = getStorageKey(storageKey);

  const storage = withSessionStorage ? sessionStorage : localStorage;

  storage.setItem(key, value);

  if (silent) {
    return;
  }

  const storageSubject = getStorageObservable<T>(
    storageKey,
    withSessionStorage,
  );

  storageSubject.next(value);
};

/**
 * Base util for read data from selected storage
 */
const readFromStorage = <T>(
  storageKey: string,
  withSessionStorage: boolean,
): T | null => {
  const storage = withSessionStorage ? sessionStorage : localStorage;

  return storage.getItem(getStorageKey(storageKey));
};

/**
 * Read data from personalized local storage
 */
export const readFromLocalStorage = <T = string>(
  storageKey: string,
): T | null => {
  return readFromStorage(storageKey, false);
};

/**
 * Write data to personalized local storage
 */
export const writeToLocalStorage = <T = string>(
  storageKey: string,
  value: T,
  silent = false,
): void => {
  writeToStorage(storageKey, value, false, silent);
};

/**
 * localStorage observable
 */
export const watchLocalStorage = <T>(storageKey: string): Observable<T> => {
  return getStorageObservable<T>(storageKey, false).pipe(
    startWith(readFromLocalStorage(storageKey)),
    distinctUntilChanged(),
  );
};

/**
 * Read data from personalized session storage
 */
export const readFromSessionStorage = <T>(storageKey: string): T | null => {
  return readFromStorage(storageKey, true);
};

/**
 * Write data to personalized session storage
 */
export const writeToSessionStorage = <T>(
  storageKey: string,
  value: T,
  silent = false,
): void => {
  writeToStorage<T>(storageKey, value, true, silent);
};

const useStorage = <T>(
  storageKey: string,
  withSessionStorage = false,
): [T, (value: T) => void] => {
  const storageData: T | null = readFromStorage(storageKey, withSessionStorage);

  const [, setState] = useState<T>(storageData);

  useEffect(() => {
    const storageSubject$ = getStorageObservable(
      storageKey,
      withSessionStorage,
    );

    const listener = storageSubject$.subscribe((data: T) => {
      setState(data);
    });

    return () => {
      listener.unsubscribe();
    };
  }, [storageKey, withSessionStorage]);

  const setStorageData = useCallback(
    (value: T) => {
      writeToStorage(storageKey, value, withSessionStorage);
    },
    [storageKey, withSessionStorage],
  );

  const storageDataRef = useRef<T>(storageData);

  if (!isEqual(storageData, storageDataRef.current)) {
    storageDataRef.current = storageData;
  }

  return [storageDataRef.current, setStorageData];
};

export const useLocalStorage = <T>(
  storageKey: string,
): [T, (value: T) => void] => useStorage<T>(storageKey);

export const useSessionStorage = <T>(
  storageKey: string,
): [T, (value: T) => void] => useStorage<T>(storageKey, true);
