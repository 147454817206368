enum CurrentUserMenuAppearance {
  /**
   * Default list, with payment banner in middle of it.
   */
  AS_LIST = 'asList',

  /**
   * Designed as content that is "layered" above user info.
   * Additionally items are placed as "masonry" grid
   */
  AS_LAYERED_MASONRY = 'asLayeredMasonry',

  /**
   * Designed as content that is "layered" above user info, but
   * items are displayed as regular list.
   */
  AS_LAYERED_LIST = 'asLayeredList',

  /**
   * Default list, with payment banner in middle of it
   * and with blurred photo on background.
   */
  AS_LIST_WITH_BLURRED_PHOTO = 'asListWithBlurredPhoto',
}

export default CurrentUserMenuAppearance;
