enum SearchPageAppearance {
  /**
   * Just a free-scrollable content in any direction,
   * with different column count and spacing.
   */
  ORDINARY = 'ordinary',

  /**
   * Just a free-scrollable content in any direction,
   * with different column count and spacing.
   * But with upgrade button
   */
  ORDINARY_WITH_PAYMENT_BUTTON = 'ordinaryWithPaymentButton',

  /**
   * Search become similar to 'like gallery', with fullscreen widget that
   * can be scrolled one-by-one like slider.
   */
  FULLSCREEN_SLIDER = 'fullscreenSlider',
}

export default SearchPageAppearance;
