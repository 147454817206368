import type {Dayjs} from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(utc);
dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(isoWeek);

const timezonedDayjs = (...args: Parameters<typeof dayjs>): Dayjs => {
  return dayjs(...args).tz();
};

const timezonedUnix = (value: number): Dayjs => {
  return dayjs.unix(value).tz();
};

Object.assign(timezonedDayjs, dayjs);
timezonedDayjs.unix = timezonedUnix;

// In case when we need dayjs time without timezone shift
export const defaultDayjs = dayjs;

export default timezonedDayjs;
