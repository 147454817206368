import includes from 'lodash/includes';

import {getClientInstance} from '@core/graphql/client';
import t from '@core/translations/translate';
import trackMediaUploadError from '@core/tracking/mediaUploadError/trackMediaUploadError';

import PHOTO_UPLOAD_SETTINGS_QUERY from '../../graphql/queries/photoUploadSettings.gql';
import BYTES_IN_MB from '../../constants/bytesInMb';
import validateImageSize from './utils/validateImageSize';
import getFileExtension from '../getFileExtension';
import getAllowedExtensionsAsString from '../getAllowedExtensionsAsString';

/**
 * Validate photo before upload. AKA frontend validation.
 * @param {Blob} file
 * @returns {Promise} Validation promise which is rejected with validation error if file is invalid.
 */
export default async function validatePhoto({file}) {
  const {
    data: {
      userFeatures: {
        photoSettings: {
          allowedExtensions,
          maxSizeInMb,
          minPhotoResolution: {width, height},
        },
      },
    },
  } = await getClientInstance().query({query: PHOTO_UPLOAD_SETTINGS_QUERY});

  const allowedExtensionsString = getAllowedExtensionsAsString({
    extensions: allowedExtensions,
  });

  const extension = getFileExtension(file.name);
  if (extension && !includes(allowedExtensions, extension)) {
    trackMediaUploadError({
      errorType: 'uploadPhotoInDisabledFormat',
      format: extension,
    });
    throw new Error(
      t('uploadButtonMessages', 'error.invalid_extension_photo', {
        '{allowedExtensions}': allowedExtensionsString,
      }),
    );
  }

  if (file.size > maxSizeInMb * BYTES_IN_MB) {
    trackMediaUploadError({
      errorType: 'uploadPhotoSizeLimit',
    });
    throw new Error(
      t('uploadPhotoNative', 'error.size_limit', {'{size}': maxSizeInMb}),
    );
  }

  await validateImageSize({
    file,
    width,
    height,
    maxSizeInMb,
    allowedExtensionsString,
  });
}
