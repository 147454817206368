import {getClientInstance} from '@core/graphql/client';
import isMobileViewport from '@core/responsive/isMobileViewport';

import type {
  MiniMessengerAllowedQuery,
  MiniMessengerAllowedQueryVariables,
} from '../graphql/queries/miniMessengerAllowed';
import MINI_MESSENGER_ALLOWED_QUERY from '../graphql/queries/miniMessengerAllowed.gql';

/**
 * Check mini messenger permission
 */
const isMiniMessengerAllowed = async (): Promise<boolean> => {
  /**
   * Used for tablet with screen width less then 767px, like Samsung Galaxy Tab S6,
   * when used ear of notification and mini messenger for chat
   */
  if (isMobileViewport()) {
    // We don't have mini messenger on mob, but back returns true.
    return false;
  }

  const {data} = await getClientInstance().query<
    MiniMessengerAllowedQuery,
    MiniMessengerAllowedQueryVariables
  >({
    query: MINI_MESSENGER_ALLOWED_QUERY,
  });
  return data.messenger.miniMessengerAllowed;
};

export default isMiniMessengerAllowed;
