import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import ROUTES from '@core/application/constants/routes';

import type {DefaultRouteQuery} from './graphql/queries/defaultRoute';
import DEFAULT_ROUTE_QUERY from './graphql/queries/defaultRoute.gql';

/**
 * Hook for replacing or pushing new route into history.
 * @param replace - true if we should use "replace" action, false - if "push".
 */
const useRedirectToDefaultPage = ({replace = false} = {}) => {
  const {data, loading, error} =
    useQuery<DefaultRouteQuery>(DEFAULT_ROUTE_QUERY);
  const {replace: replaceHistory, push} = useHistory();

  const defaultRoute = loading || error || !data ? '' : data.site?.defaultRoute;
  const defaultPage = defaultRoute || ROUTES.SEARCH;

  const isAuthorizedZone = data?.isAuthorizedZone ?? false;

  return useCallback(() => {
    if (isAuthorizedZone) {
      const action = replace ? replaceHistory : push;

      action(defaultPage);

      return;
    }

    window.location.assign('/');
  }, [isAuthorizedZone, replace, replaceHistory, push, defaultPage]);
};

export default useRedirectToDefaultPage;
