import logger from '@core/logger';
import {getClientInstance} from '@core/graphql/client';

import PAGE_ENTER_TIME_QUERY from '../graphql/queries/pageEnterTime.gql';

/**
 * Set time when user enter payment page in cache
 */
export const setPageEnterTime = () => {
  getClientInstance().writeQuery({
    query: PAGE_ENTER_TIME_QUERY,
    data: {
      payment: {
        pageEnterTime: Date.now(),
        __typename: 'Payment',
      },
    },
  });
};

/**
 * Get time interval between enter page and now (pay click) for tracking
 * @returns {number}
 */
export const getPageViewTime = () => {
  const {
    payment: {pageEnterTime},
  } = getClientInstance().readQuery({
    query: PAGE_ENTER_TIME_QUERY,
  });

  if (!pageEnterTime) {
    logger.sendError(
      '[getPageViewTime] try to get payment page view time without pageEnterTime',
    );
    return 0;
  }

  return Math.floor((Date.now() - pageEnterTime) / 1000);
};
