import React from 'react';

import replaceParams from '@core/translations/utils/replaceParams';
import t from '@core/translations/translate';

/**
 * @param {Object} props
 * @param {String} props.billingPolicyText
 * @param {String} props.billingPolicyLink
 * @param {String} props.billingPolicyLinkText
 * @param {String} props.title
 * @param {String} [props.siteName]
 * @param {Boolean} props.showPrivacyPolicy
 * @param {Boolean} props.showConsentAgreement
 * @param {Boolean} props.isShowRefundPolicy
 * @param {Function} props.paymentBillingPolicyLink
 * @param {Function} props.paymentPrivacyPolicyLink
 * @param {Function} props.paymentRefundPolicyLink
 * @param {Function} props.paymentConsentAgreementLink
 * @param {Function} props.paymentTermsOfUseLink
 * @return {Array}
 */
const formatLinksAsArray = ({
  billingPolicyText,
  billingPolicyLink,
  billingPolicyLinkText,
  showPrivacyPolicy,
  isShowRefundPolicy,
  showConsentAgreement,
  title,
  siteName,
  paymentBillingPolicyLink: PaymentBillingPolicyLink,
  paymentPrivacyPolicyLink: PaymentPrivacyPolicyLink,
  paymentRefundPolicyLink: PaymentRefundPolicyLink,
  paymentConsentAgreementLink: PaymentConsentAgreementLink,
  paymentTermsOfUseLink: PaymentTermsOfUseLink,
}) => {
  let billingPolicyLinks = [
    billingPolicyText
      .replace(/{payButtonText}/gi, title)
      .replace(/{sitename}/gi, siteName),
  ];

  showPrivacyPolicy &&
    billingPolicyLinks.push('{privacyPolicyLink}', '{termsOfUseLink}');

  isShowRefundPolicy && billingPolicyLinks.push('{refundPolicyLink}');

  showConsentAgreement && billingPolicyLinks.push('{consentAgreementLink}');

  billingPolicyLinks = replaceParams(
    billingPolicyLinks.join(', '),
    {
      '{billingPolicyLinkText}': (
        <PaymentBillingPolicyLink
          href={billingPolicyLink}
          text={billingPolicyLinkText}
        />
      ),
      '{privacyPolicyLink}': (
        <PaymentPrivacyPolicyLink
          href="/pay/page/privacypolicy?action=features"
          text={t('popupStaticPage', 'title.privacy_policy')}
        />
      ),
      '{termsOfUseLink}': (
        <PaymentTermsOfUseLink
          href="/pay/page/terms?action=features"
          text={t('popupStaticPage', 'title.terms')}
        />
      ),
      '{refundPolicyLink}': (
        <PaymentRefundPolicyLink
          href="/pay/page/privacypolicy?action=features"
          text={t('popupStaticPage', 'title.refund_policy')}
        />
      ),
      '{consentAgreementLink}': (
        <PaymentConsentAgreementLink
          href="/staticPage/consentAgreement"
          text={t('footer', 'text.consentAgreement')}
        />
      ),
    },
    true,
  );

  return billingPolicyLinks;
};

export default formatLinksAsArray;
