import React from 'react';
import {useQuery} from '@apollo/client';
import PropTypes from 'prop-types';

import getPrimaryPhotoData from '@core/user/photo/utils/getPrimaryPhotoData';

import CONFIRMATION_MOTIVATION_USER_QUERY from '../graphql/queries/confirmationMotivationUser.gql';

/**
 * User motivation for confirmation block.
 * Displays user photo with motivation text.
 */
const ConfirmationUserMotivation = ({
  userId,
  confirmationLayout: ConfirmationUserMotivationLayout,
  confirmationPlaceholder: ConfirmationUserMotivationPlaceholder,
  errorBoundary: ErrorBoundary,
}) => {
  const {data, loading, error} = useQuery(CONFIRMATION_MOTIVATION_USER_QUERY, {
    variables: {userId},
  });

  if (error) return <ErrorBoundary errorSize={ErrorBoundary.SIZE.SMALL} />;
  if (loading) return <ConfirmationUserMotivationPlaceholder />;

  const {profile} = data.user;

  return (
    <ConfirmationUserMotivationLayout
      photo={getPrimaryPhotoData(profile)}
      userId={userId}
      login={profile.login}
      online={profile.statuses.isOnline}
    />
  );
};

ConfirmationUserMotivation.propTypes /* remove-proptypes */ = {
  userId: PropTypes.string,
  confirmationLayout: PropTypes.func.isRequired,
  confirmationPlaceholder: PropTypes.func.isRequired,
  errorBoundary: PropTypes.func.isRequired,
};

export default ConfirmationUserMotivation;
