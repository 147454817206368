import type {Observable} from 'rxjs';
import {fromEvent} from 'rxjs';
import {map} from 'rxjs/operators';

const createVisibilityChangeObservable = (): Observable<string> =>
  fromEvent(window.document, 'visibilitychange').pipe(
    map(() => window.document.visibilityState),
  );

export default createVisibilityChangeObservable;
