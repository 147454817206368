import isUndefined from 'lodash/isUndefined';
import parseHostname from 'util/parseHostname';
import hostnameConverter from 'util/hostnameConverter';

import logger from '@core/logger';

// eslint-disable-next-line import/no-mutable-exports
let getParameter: <T = string>(name: string) => T | null;

if (SERVER_ENVIRONMENT) {
  getParameter = (name) => {
    const {parameters} = global.context;

    if (isUndefined(parameters[name])) {
      logger.sendError(`[getParameter] Missing site parameter: ${name}`);
      return null;
    }

    return parameters[name];
  };
} else {
  const {sitename} = parseHostname(hostnameConverter(window.location.hostname));

  let parameters = {};
  try {
    // Converts a hash string to normal, and to an object of parameters
    parameters = JSON.parse(atob(window[btoa(sitename)]));
  } catch (ex) {
    logger.sendError('[getParameter] No site parameters provided');
  }

  /**
   * Site parameters that are located in the front-end repository and are managed in it
   * and get into the layout
   * @see util/getSitesConfig, frontend/config/*,
   *      frontend/server/getLayout.js, frontend/scripts/generateParameters.js
   */
  getParameter = (name) => {
    if (isUndefined(parameters[name])) {
      logger.sendError(`[getParameter] Missing site parameter: ${name}`);
      return null;
    }

    return parameters[name];
  };
}

export default getParameter;
