import createDefaults from '../utils/createDefaults';

const defaultBanInfo = {
  isBanned: false,
  period: null,
  __typename: 'RoomBanInfo',
};

export default {
  Query: {
    fields: createDefaults({
      chatrooms: {__typename: 'Chatrooms'},
    }),
  },
  Chatrooms: {
    keyFields: [],
    fields: createDefaults({
      messages: null, // null means loading, Array - loaded.
      recipientsIds: [],
      activeRecipientsTab: 'all',
      banInfo: defaultBanInfo,
    }),
  },
};
