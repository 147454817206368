import type {ACLRule} from '../types';
import type {BannedQuery} from '../graphql/queries/banned';
import BANNED_QUERY from '../graphql/queries/banned.gql';

/**
 * User banned access rule.
 */
const BannedRule: ACLRule = {
  errorCode: 'IP_IS_BANNED',
  query: BANNED_QUERY,
  resolver: (data: BannedQuery) => !data.isBanned,
};

export default BannedRule;
