// Used for unit tests
export const FALLBACK_URL = 'http://localhost:3000';

// Used for indexOf method
export const NOT_FOUND = -1;

// Need for check if url has protocol
export const PROTOCOL_SEPARATOR = '://';

// Need for slit path from host
export const PATH_SEPARATOR = '/';
