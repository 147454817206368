import uniqBy from 'lodash/uniqBy';
import type {Reference, StoreObject} from '@apollo/client/utilities';

export const mergeArrayField = <T extends StoreObject | Reference>(
  existing: T[] | undefined,
  incoming: T[],
): T[] => [...(existing || []), ...(incoming || [])];

export const mergeArrayFieldUniq = <T extends StoreObject | Reference>(
  existing: T[] | undefined,
  incoming: T[],
): T[] => uniqBy(mergeArrayField(existing, incoming), '__ref');
