import ROUTES from '@core/application/constants/routes';

import clearUrl from './clearUrl';

/**
 * @param {string} url
 * @returns {boolean}
 */
const isPayUrl = (url) => {
  const pathName = clearUrl(url);

  return Boolean(pathName) && pathName.startsWith(ROUTES.PAY);
};

export default isPayUrl;
