import type {InMemoryCache, NormalizedCacheObject} from '@apollo/client';
import {CachePersistor, LocalStorageWrapper} from 'apollo3-cache-persist';

import {isPayUrl} from '@core/utils/url';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';
import isNeedOpenPaymentPageInNewTab from '@core/payment/common/utils/isNeedOpenPaymentPageInNewTab';
import optimisticallyResetIsNeedOpenPaymentPageInNewTabFlag from '@core/payment/common/utils/optimisticallyResetIsNeedOpenPaymentPageInNewTabFlag';
import {
  getActiveDialogId,
  setActiveDialogId,
} from '@core/messenger/messagesList/utils/activeDialog';
import isPreloadedPaymentData from '@core/payment/common/utils/isPreloadedPaymentData';
import {DECLINE_VIA} from '@core/payment/common/constants/declineVia';

import routeVar from '../vars/routeVar';
import clearSearchBannersCache from './clearSearchBannersCache';

const MAX_CACHE_SIZE = 3145728; // 3 Mb limit because we don`t want to overfill storage with this stuff

const canInvalidatePaymentFields = () => {
  const paymentDataIsPreloaded = isPreloadedPaymentData();

  /**
   * Invalidate payment field if preload logic is not activated.
   */
  if (!paymentDataIsPreloaded) {
    return true;
  }

  /**
   * restorePersistedCache is called on cache initialization;
   * If the previous route was the payment page route,
   * it can be considered that the page was reloaded, so the payment fields needs to invalidate.
   */
  return isPayUrl(routeVar().previous);
};

/**
 * Manager for controlling persistence lifecycle of Apollo InMemoryCache
 *
 * @class CachePersistManager
 */
class CachePersistManager {
  instance: CachePersistor<NormalizedCacheObject> = null;

  cache: InMemoryCache = null;

  /**
   * Adds cache persist middleware to Apollo cache instance and runs persist flow
   */
  async initialize(cache: InMemoryCache) {
    this.cache = cache;
    this.instance = new CachePersistor({
      cache: this.cache,
      storage: new LocalStorageWrapper(window.localStorage),
      maxSize: MAX_CACHE_SIZE,
      trigger: false, // persist cache manually only
    });

    await this.restorePersistedCache();
  }

  async restorePersistedCache() {
    const {pathname, search} = window.location;

    // Populate in memory cache only for payment page
    if (this.instance && isPayUrl(pathname)) {
      await this.instance.restore();

      // Reloads messenger photo gallery data after back to site from payment page
      invalidateCacheByTypename(this, 'Messenger', 'gallery');

      // Reloads free messages count after PP leave affects MessengerChatPreloadQuery
      invalidateCacheByTypename(this, 'FreeMessages');

      // Invalidate LikeGallery cache
      invalidateCacheByTypename(this, 'ROOT_QUERY', 'likeGallery');

      // Invalidate tracking codes to avoid doubling of tracking codes when user exit from PP
      invalidateCacheByTypename(this, 'TrackingCodes');
      invalidateCacheByTypename(this, 'UserFeatures', 'trackingCodes');

      invalidateCacheByTypename(this, 'UserFeatures', 'paymentLastOrder');

      invalidateCacheByTypename(this, 'Payment', 'processingStatus');

      /**
       * If payment was declined (ex., from remarketing offer popup),
       * we don't need to invalidate payAnswer cache to show declined message
       */
      if (!search.includes(`via=${DECLINE_VIA}`)) {
        invalidateCacheByTypename(this, 'Payment', 'payAnswer');
      }

      // Invalidate after visited pay/remarketingOffer
      invalidateCacheByTypename(this, 'Payment', 'remarketingOfferData');

      if (canInvalidatePaymentFields()) {
        invalidateCacheByTypename(this, 'Payment', 'templateSettings');
        invalidateCacheByTypename(this, 'Payment', 'stepFlow');
      }

      invalidateCacheByTypename(this, 'Credits', 'debtBalance');

      const id = getActiveDialogId();
      setActiveDialogId(null);
      /**
       * Invalidate all messages because they may become outdated while we are on PP.
       * But keep active dialog messages to open messenger faster when going back.
       * We will update them in background {@see useMessages}.
       */
      invalidateCacheByTypename(this, 'Recipient', {
        filter: (key) => key !== `Recipient:${id}`,
        fieldName: 'messages',
      });

      clearSearchBannersCache(this);

      if (await isNeedOpenPaymentPageInNewTab('cache-only')) {
        /*
          We need to unset this flag in cache because:
          - it is part off appdata and causes fetching on PP load if it is invalidated
          - we cannot get it from AppData query because on react PP frontend page visit action triggered after page load
          and this flag stays unchanged
        */
        optimisticallyResetIsNeedOpenPaymentPageInNewTabFlag();
      }

      return;
    }

    // for now, we clear cache on other pages because we don`t need it there
    if (await this.hasCacheAsync()) {
      await this.clearPersistStorage();
    }
  }

  async hasCacheAsync() {
    if (!this.instance) {
      return false;
    }

    return Boolean(await this.instance.getSize());
  }

  hasCacheSync() {
    if (!this.instance) {
      return false;
    }

    const data = this.instance.storage.storage.getItem(
      this.instance.storage.key,
    );

    if (data == null) {
      return false;
    }

    return typeof data === 'string' ? Boolean(data.length) : false;
  }

  async persistCache() {
    if (this.instance) {
      await this.instance.persist();
    }
  }

  async clearPersistStorage() {
    if (this.instance) {
      await this.instance.purge();
    }
  }
}

export default new CachePersistManager();
