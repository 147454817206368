import emojiRegex from 'emoji-regex';

import css from './styles/Emoji.css';

/**
 * Wraps emoji symbols in <span> tags.
 * @param {string} text
 * @param {boolean} scaled
 * @return {string}
 */
const wrapEmoji = (text, scaled = true) =>
  text.replace(
    emojiRegex(),
    `<span class="${css.emoji} ${scaled ? css.scaled : ''}">$&</span>`,
  );

export default wrapEmoji;
