import {localStorage} from '@core/utils/storage';

import {SHOW_REDIRECT_URL} from '../../common/constants/localStorageKeys';

const showRedirectUrl = (redirectUrl: string, isClient: boolean = true) => {
  if (redirectUrl && localStorage.getItem(SHOW_REDIRECT_URL)) {
    // eslint-disable-next-line no-console
    console.log(
      `${isClient ? 'Client navigation' : 'Server navigation'}: ${redirectUrl}`,
    );
  }
};

export default showRedirectUrl;
