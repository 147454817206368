import getTypeNameItems from '@core/graphql/utils/getTypeNameItems';
import isCompactViewportCached from '@core/responsive/isCompactViewportCached';

import CACHED_USER_QUERY from '../profile/target/graphql/queries/cachedUser.gql';
import type {
  CachedUserQuery,
  CachedUserQueryVariables,
} from '../profile/target/graphql/queries/cachedUser';
import CACHED_USER_EXPANDED_QUERY from '../profile/target/graphql/queries/cachedUserExpanded.gql';
import type {
  CachedUserExpandedQuery,
  CachedUserExpandedQueryVariables,
} from '../profile/target/graphql/queries/cachedUserExpanded';

const PHOTO_LEVELS = [0, 3]; // Normal And Social levels (@see unsetPhotoLevel.yaml)

type User = CachedUserExpandedQuery | CachedUserQuery;
type UserVariables =
  | CachedUserQueryVariables
  | CachedUserExpandedQueryVariables;

type GetUsersWithCachedUrlParams = {
  excludeUserId?: string | null;
  isPrettyPhoto?: boolean;
  useViewports?: boolean;
};

const getUsersWithCachedUrl = ({
  excludeUserId = null,
  isPrettyPhoto = false,
  useViewports = true,
}: GetUsersWithCachedUrlParams = {}) => {
  return getTypeNameItems<User, UserVariables>({
    typename: 'UserData',
    query:
      useViewports && isCompactViewportCached()
        ? CACHED_USER_QUERY
        : CACHED_USER_EXPANDED_QUERY,
    setVariables: (item) => ({userId: String(item.id)}),
  })
    .filter((item) => {
      if (
        item.user?.id === excludeUserId ||
        item.user?.profile?.statuses?.isAdmin
      ) {
        return false;
      }

      const primaryPhoto = item.user?.profile?.photos?.primaryPhoto;

      if (!primaryPhoto) {
        return false;
      }

      if (isPrettyPhoto) {
        return (
          /**
           * If 'naughty mode' is non-adult we need to filter photos by level.
           * If no photo is found - we can't impact on it, but photo(-s) will be fetched from server later
           */
          primaryPhoto.cachedUrl && PHOTO_LEVELS.includes(primaryPhoto.level)
        );
      }

      return primaryPhoto.cachedUrl;
    })
    .map(({user}) => user);
};

export default getUsersWithCachedUrl;
