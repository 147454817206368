// Service socket events
export const DISCONNECT = 'disconnect';
export const RECONNECT = 'reconnect';
export const AUTH = 'auth';
export const LIVECHAT_AUTH = 'livechatauth';
export const CONNECTED = 'connected';

/**
 * Room events
 * @type {string}
 */
export const ROOMS = 'rooms';

/**
 * Message events and default events
 * @type {string}
 */
export const MSG = 'msg';

// Other events
export const PMA_POPUP = 'sendingPmaPopup';
export const ACTION_POPUP = 'likeActionPopup';
