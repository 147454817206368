enum SectionTestName {
  PHOTO_LIST = 'photoList',
  VIDEO_LIST = 'videoList',
  MEDIA_MOTIVATION = 'mediaMotivation',
  VIDEO_MOTIVATION = 'videoMotivation',
  SAFE_BANNER = 'safeBanner',
  BASIC_INFO = 'basicInfo',
}

export default SectionTestName;
