import memoize from 'memoize-one';
import orderBy from 'lodash/orderBy';

import type {PhotoFragment} from '../../../photo/graphql/fragments/photo';

type AllPhotos = PhotoFragment & {
  createdOn: string;
  pendingDelete?: boolean;
};

/**
 * Correct display of photos by date of creation
 * @param {Array} allPhotos
 * @returns {Array} array of photos
 */
const sortPhotosByCreateDate = memoize((allPhotos: AllPhotos[]) =>
  orderBy(allPhotos, (photo) => new Date(photo.createdOn), ['desc']),
);

export default sortPhotosByCreateDate;
