import {getClientInstance} from '@core/graphql/client';
import type {Client} from '@core/graphql/types';

import type {MessengerQuery} from '../graphql/queries/messenger';
import MESSENGER_QUERY from '../graphql/queries/messenger.gql';

type Recipients = MessengerQuery['messenger']['initialData']['recipients'];
type UpdateRecipientsInCacheOptions = {
  client: Client;
  updater: (recipients: Recipients) => Recipients;
};

const updateRecipientsInCache = ({
  client = getClientInstance(),
  updater,
}: UpdateRecipientsInCacheOptions): void => {
  const data = client.readQuery<MessengerQuery>({query: MESSENGER_QUERY});

  if (!data) return;

  const newRecipients = updater(data.messenger.initialData.recipients);

  client.writeQuery<MessengerQuery>({
    query: MESSENGER_QUERY,
    data: {
      ...data,
      messenger: {
        ...data.messenger,
        initialData: {
          ...data.messenger.initialData,
          recipients: newRecipients,
        },
      },
    },
  });
};

export default updateRecipientsInCache;
