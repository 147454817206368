let touchYPosition = 0;

const getScrollableParent = (
  target: HTMLElement,
  currentTarget: HTMLElement,
  isMoveToBottom: boolean,
): HTMLElement => {
  const onTop = target === currentTarget;
  const node = onTop ? currentTarget : target;
  const {scrollTop, scrollHeight, clientHeight} = node;

  const preventScroll = isMoveToBottom
    ? scrollTop <= 0
    : scrollHeight - clientHeight === scrollTop;
  const isScrollable =
    target.scrollHeight > target.clientHeight &&
    window.getComputedStyle(node).overflowY === 'auto';

  if ((isScrollable && !preventScroll) || onTop) {
    return target;
  }

  return getScrollableParent(
    target.parentElement as HTMLElement,
    currentTarget,
    isMoveToBottom,
  );
};

const handleTouchmove = (event: TouchEvent): void => {
  const {clientY} = event.changedTouches[0];
  const isMoveToBottom = clientY > touchYPosition;
  const {scrollTop, scrollHeight, clientHeight} = getScrollableParent(
    event.target as HTMLElement,
    event.currentTarget as HTMLElement,
    isMoveToBottom,
  );
  const preventScroll = isMoveToBottom
    ? scrollTop <= 0
    : scrollHeight - clientHeight === scrollTop;

  if (preventScroll && event.cancelable) {
    event.preventDefault();
  }
};

const handleTouchstart = (event: TouchEvent): void => {
  touchYPosition = event.changedTouches[0].clientY;

  document.body.addEventListener('touchmove', handleTouchmove, {
    passive: false,
  });

  document.body.addEventListener(
    'touchend',
    () => {
      document.body.removeEventListener('touchmove', handleTouchmove);
    },
    {once: true},
  );
};

export const lockPageScrollListeners = (): void => {
  document.body.addEventListener('touchstart', handleTouchstart);
  document.documentElement.classList.add('lock-scrolling');
};

export const unlockPageScrollListeners = (): void => {
  document.body.removeEventListener('touchstart', handleTouchstart);
  document.documentElement.classList.remove('lock-scrolling');
};
