import type {ReactNode, FC} from 'react';
import React, {Children, cloneElement, isValidElement} from 'react';

import {SpacingSize} from '@core/ui/constants';

import css from './List.css';

/**
 * Typographic list (withIcon or not)
 */
export type ListProps = {
  icon?: ReactNode;
  bulletSpacing?: SpacingSize;
  spacingSize?: SpacingSize;
  children: ReactNode;
  bulleted?: boolean;
  muted?: boolean;
  small?: boolean;
};

const List: FC<ListProps> = ({
  children,
  spacingSize = SpacingSize.SHORTEST,
  ...props
}) => (
  <ul className={css.list} data-test="List">
    {Children.map(children, (child: ReactNode) => {
      if (isValidElement(child)) {
        return cloneElement<Omit<ListProps, 'children'>>(child, {
          spacingSize,
          ...props,
        });
      }
      return null;
    })}
  </ul>
);

export default List;
