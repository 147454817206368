// This file has `.tsx` extension to match corresponding test file.

import type {ApolloError} from '@apollo/client';
import {useQuery} from '@apollo/client';

import type {SiteNameQuery} from '../graphql/queries/siteName';
import SITE_NAME_QUERY from '../graphql/queries/siteName.gql';

type SiteNameResult = {
  siteName?: string;
  loading: boolean;
  error?: ApolloError;
};

/**
 * Utility hook for retrieving current site name.
 */
const useSiteName = (): SiteNameResult => {
  const {data, loading, error} = useQuery<SiteNameQuery>(SITE_NAME_QUERY);

  return {
    siteName: data?.site.name,
    loading,
    error,
  };
};

export default useSiteName;
