import React from 'react';
import includes from 'lodash/includes';

import PopupService from '@core/popup/utils/PopupService';
import {
  getAllHashedLinksList,
  decodeLink,
  isHashed,
  formatParams,
} from '@core/application/utils/hashedStaticLinks';
import {parsePathname} from '@core/utils/url';

import STATIC_PAGES, {STATIC_POPUP_TEST_ID} from '../constants/pages';

/**
 * @class StaticPagePopupHelperAbstract
 * @classdesc Helper class for opening static page popups.
 */
export default class StaticPagePopupHelperAbstract {
  PAGES = STATIC_PAGES;

  openPopup(
    name,
    event,
    {
      small = false,
      siteId = null,
      externalDomain = null,
      placeholders = null,
      scrollToId = null,
    } = {},
  ) {
    /**
     * No need to open popup on static page. This page has global event listeners for click on links with static hrefs.
     */
    if (
      !name ||
      window.location.pathname.startsWith('/staticPage') ||
      includes(getAllHashedLinksList(), window.location.pathname)
    ) {
      return null;
    }

    let page = name;
    const testId = STATIC_POPUP_TEST_ID[name];

    /**
     * There are a few other terms pages, we need to open correct popups for them
     * and if static link is hashed - unhash it and get link params
     */
    if (event) {
      if (event.currentTarget.search) {
        const {search} = event.currentTarget;
        page = `${name}${search}`;
      } else if (event.currentTarget.pathname) {
        const decodedLink = decodeLink(event.currentTarget.pathname);

        if (isHashed(decodedLink)) {
          const pathname = parsePathname(decodedLink);
          page = `${name}${formatParams(pathname.options)}`;
        }
      }
    }

    this.preventEventIfNeeded(event);

    const StaticPageContent = this.getComponent();

    return PopupService.openPopup(
      <StaticPageContent
        page={page}
        siteId={siteId}
        externalDomain={externalDomain}
        placeholders={placeholders}
        scrollToId={scrollToId}
        data-test={testId}
        isPopup
      />,
      {
        small,
        withInnerScroll: !scrollToId,
        groupKey: StaticPageContent,
        trackingName: page,
      },
    );
  }

  /**
   * @abstract
   */
  getComponent = () => {};

  /**
   * @private
   * @param event {Event}
   */
  preventEventIfNeeded = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  };
}
