import type {FC, HTMLAttributes, ReactNode} from 'react';
import React from 'react';
import cn from 'classnames';

import baseCss from './Container.css';

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
  unspaced?: boolean;
}

/**
 * Container component, needed to center content on screen. Used mostly on web
 * on mobile it looks like added 10px space at the edges of screen
 */
const Container: FC<ContainerProps> = ({
  children,
  className,
  unspaced = false,
  ...props
}) => (
  <div
    className={cn(baseCss.container, className, unspaced && baseCss.unspaced)}
    {...props}
  >
    {children}
  </div>
);

export default Container;
