/**
 * This file is named with a .tsx extension to ensure that the IDE (e.g., WebStorm)
 * correctly links it with its associated test file (also .tsx).
 * While this file may not directly use JSX, the extension prevents potential tooling issues.
 */

import once from 'lodash/once';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import type {ApolloClient} from '@apollo/client';
import {useApolloClient} from '@apollo/client';

import wait from '@core/utils/timer/wait';
import {isPayUrl} from '@core/utils/url';
import {localStorage} from '@core/utils/storage';

import TRACKING_CODES_QUERY from '../graphql/queries/trackingCodes.gql';
import type {TrackingCodesQuery} from '../graphql/queries/trackingCodes';
import applyTrackingCodes from '../utils/applyTrackingCodes';

type LoadTrackingCodesParams = {
  client: ApolloClient<any>;
  delayed?: boolean;
};

const loadTrackingCodes = async ({
  client,
  delayed = false,
}: LoadTrackingCodesParams): Promise<void> => {
  const {data} = await client.query<TrackingCodesQuery>({
    query: TRACKING_CODES_QUERY,
    variables: delayed ? {useAnalytics: false, isHidden: true} : {},
  });

  if (!data) {
    return;
  }

  const {trackingCodes} = data.userFeatures;

  if (localStorage.getItem('debugTrackingCodes')) {
    // eslint-disable-next-line no-console
    console.log(trackingCodes);
  }

  await applyTrackingCodes(trackingCodes);
};

const loadTrackingCodesOnce = once(async (client: ApolloClient<any>) => {
  await loadTrackingCodes({client});
  // Some tracking codes are loaded by the first query, then backend needs more time to prepare the rest.
  await wait(3000);
  await loadTrackingCodes({client, delayed: true});
});

const TrackingCodes = () => {
  const client = useApolloClient();
  const {pathname} = useLocation();

  useEffect(() => {
    if (!isPayUrl(pathname)) {
      loadTrackingCodesOnce(client);
    }
  }, [client, pathname]);

  return null;
};

export default TrackingCodes;
