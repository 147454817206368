import type {FC} from 'react';
import React from 'react';
import cn from 'classnames';

import type {PlaceholderProps} from './Placeholder';
import Placeholder from './Placeholder';
import css from './PlaceholderBlock.css';

export interface PlaceholderBlockProps extends PlaceholderProps {
  size?: 40 | 60 | 80 | 120 | 200;
  /**
   * Means, that width and height will be equal. Can't be used together with "size" prop
   */
  square?: boolean;
  rounded?: boolean;
  flexible?: boolean;
}

/**
 * Block (square) placeholder, used mainly
 * as placeholder for user photo.
 */
const Block: FC<PlaceholderBlockProps> = ({
  className,
  size,
  square,
  rounded,
  flexible,
  ...props
}) => {
  const placeholder = (
    <Placeholder
      {...props}
      className={cn(
        className,
        css.block,
        flexible && css.flexible,
        rounded && css.rounded,
        size && !square && css[`size${size}`],
      )}
    />
  );

  if (square) {
    return <div className={css.square}>{placeholder}</div>;
  }

  return placeholder;
};

export default Block;
