import invert from 'lodash/invert';

import {TRUSTED_STATUSES, STATUS_ID_MAP} from '../constants/status';

const STATUS_NAME_MAP = invert(STATUS_ID_MAP);

/**
 * Due to security reasons, the verification status field has been shortened and the status enum has been converted
 * to a numeric field so these functions convert the status to a human-readable form
 * @see {trusted: {sv}} type in graphql schema
 * @param {String} name
 * @returns {Number}
 */
const getTrustedIdByStatus = (name) =>
  Number(
    STATUS_NAME_MAP[name] || STATUS_NAME_MAP[TRUSTED_STATUSES.NON_VERIFIED],
  );

export default getTrustedIdByStatus;
