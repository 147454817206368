import {useApolloClient} from '@apollo/client';

import TARGET_USER_WITH_PRELOAD_DATA_FRAGMENT from '../graphql/fragments/targetUserWithPreloadData.gql';
import type {TargetUserWithPreloadDataFragment} from '../graphql/fragments/targetUserWithPreloadData';

const useCachedTargetUserData = (userId: string) => {
  const client = useApolloClient();

  if (!userId) {
    return null;
  }

  return client.readFragment<TargetUserWithPreloadDataFragment>({
    fragment: TARGET_USER_WITH_PRELOAD_DATA_FRAGMENT,
    fragmentName: 'TargetUserWithPreloadData',
    id: `UserData:${userId}`,
  });
};

export default useCachedTargetUserData;
