import getHistory from '@core/application/utils/getHistory';
import {readFromLocalStorage} from '@core/utils/storage';

import {MINI_MESSENGER_VISIBLE_RECIPIENT_KEY} from '../constants/storageKeys';
import isMiniMessengerAllowed from './isMiniMessengerAllowed';

const CHAT_PAGE = '/chat/with/';

const getActiveRecipientId = async () => {
  const history = getHistory();
  const path = history.location.pathname;

  if (path.startsWith(CHAT_PAGE)) {
    return path.replace(CHAT_PAGE, '');
  }

  if (await isMiniMessengerAllowed()) {
    const data = readFromLocalStorage(MINI_MESSENGER_VISIBLE_RECIPIENT_KEY);
    return data || null;
  }

  return null;
};

export default getActiveRecipientId;
