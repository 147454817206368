import {TRUSTED_STATUSES} from '@core/trusted/constants/status';
import getTrustedIdByStatus from '@core/trusted/utils/getTrustedIdByStatus';
import getCurrentUserId from '@core/user/profile/current/utils/getCurrentUserId';

import {MSG} from '../constants/socketEventNames';

export default {
  InteractionMessageTrustedStatusSubscription: {
    incomingEvents: `${MSG}onChangeTrustedStatus`,
    fromServer: async ({isTrusted}) => ({
      data: {
        user: {
          id: await getCurrentUserId(),
          trusted: {
            sv: getTrustedIdByStatus(
              isTrusted
                ? TRUSTED_STATUSES.VERIFIED
                : TRUSTED_STATUSES.NON_VERIFIED,
            ),
            __typename: 'Trusted',
          },
          __typename: 'UserData',
        },
      },
    }),
  },
};
