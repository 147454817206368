import React, {Children, cloneElement} from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import isFunction from 'lodash/isFunction';
import {Field, getIn} from 'formik';

import componentPropType from '@core/propTypes/component';

/**
 * Wrapper around select widget to make it working without
 * passing additional props with 'Formik' forms
 */
const FormSelect = ({
  validate,
  name,
  error,
  parser,
  selectComponent: Select,
  selectValueParser,
  onChange,
  onBlur,
  isIOSError,
  children,
  ...props
}) => (
  <Field name={name} validate={validate}>
    {({field, form}) => {
      const formError = getIn(form.errors, name);
      const touched = getIn(form.touched, name);
      const restField = omit(field, ['onChange', 'value', 'onBlur']);

      const value = isFunction(selectValueParser)
        ? selectValueParser(field.value)
        : field.value;

      const handleChange = (event, val, ...args) => {
        const valueForCallback = isFunction(parser) ? parser(val) : val;
        onChange && onChange(event, val, ...args);

        /**
         * Needed in case when we have iOS picker keyboard
         * To prevent issue with select data change
         */
        if (isIOSError) return;

        form.setFieldValue(name, valueForCallback);
      };

      const handleBlur = (event) => {
        field.onBlur(event);
        onBlur && onBlur(event);
      };

      return (
        <Select
          onChange={handleChange}
          value={value}
          onBlur={handleBlur}
          {...restField}
          {...props}
          error={error || (touched && formError)}
        >
          {Children.map(children, (child) =>
            cloneElement(child, {
              active: String(field.value) === String(child.props.value),
            }),
          )}
        </Select>
      );
    }}
  </Field>
);

FormSelect.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  selectComponent: componentPropType.isRequired,
  validate: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  isIOSError: PropTypes.bool,
  parser: PropTypes.func,
  /**
   * if need set select value by some rule
   * @see FormTribe
   */
  selectValueParser: PropTypes.func,
  onChange: PropTypes.func,
};

export default FormSelect;
