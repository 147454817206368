export default {
  LikeGallery: {
    fields: {
      /**
       * Sets initial `hasMore` value to satisfy LikeGalleryListQuery.
       */
      gallery: (gallery) => {
        if (gallery?.users) {
          if (!('hasMore' in gallery)) {
            return {
              ...gallery,
              /**
               * Is server has more users to show.
               * We can't use users.length instead of this flag because users may gone after like/skip,
               * so set it only on initial load.
               * {@see fetchMoreUpdater} is also update this flag.
               * Also {@see useLikeGalleryData} to find usage.
               *
               * @returns {boolean}
               */
              hasMore: gallery.users.length > 0,
            };
          }
        }
        return gallery;
      },
    },
  },
  Gallery: {
    fields: {
      users: {
        // allows to access `gallery.users` when processing the gallery field above.
        keyArgs: false,
      },

      /**
       * Index of current user in like gallery
       * @return {number}
       */
      currentIndex: (currentIndex = 0) => currentIndex,
    },
  },
};
