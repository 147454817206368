type ScriptOptions = {
  id: string;
  src: string;
  onLoad?: () => void;
};

/**
 * Adds a `<script>` element to the `body`.
 */
const addScriptNode = ({id, src, onLoad = null}: ScriptOptions): void => {
  if (!src) return;

  let script = document.getElementById(id) as HTMLScriptElement;
  // Don't add the same script
  if (script?.src === src) {
    onLoad?.();
    return;
  }

  script = document.createElement('script');
  script.id = id;
  script.src = src;

  document.body.appendChild(script);

  if (onLoad) {
    script.addEventListener('load', onLoad);
  }
};

export default addScriptNode;
