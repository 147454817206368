import once from 'lodash/once';

import {getClientInstance} from '@core/graphql/client';

import type {StatusApproveSubscription} from '../graphql/subscriptions/statusApprove';
import STATUS_APPROVE_SUBSCRIPTION from '../graphql/subscriptions/statusApprove.gql';

const getApproveStatusSubscription = once(() =>
  getClientInstance().subscribe<StatusApproveSubscription>({
    query: STATUS_APPROVE_SUBSCRIPTION,
  }),
);

export default getApproveStatusSubscription;
