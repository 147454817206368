import type {Observable} from 'rxjs';
import {from} from 'rxjs';
import {map} from 'rxjs/operators';

import getDisapproveVideoSubscription from '@core/user/profile/current/utils/getDisapproveVideoSubscription';

import NOTIFICATION_TYPES from '../constants/notificationTypes';
import type {CommonNotification} from '../types';

/**
 * Returns an observable that emits normalized results based on the disapprove video subscription.
 *
 * Please note that for TEST USERS before the video download is complete
 * we receive a message that it was approved (although it was not approved through the admin panel).
 * If a bug is created for video approval/disapproval notification - check
 * if the user is a test user or not
 */
const videoApproveAndDisapproveDataSource =
  (): Observable<CommonNotification> =>
    // 'from' is used to convert zen-observable into RxJS compatible observable
    from(getDisapproveVideoSubscription()).pipe(
      map(({data}) => ({
        type: data.disapprove.video
          ? NOTIFICATION_TYPES.DISAPPROVE_VIDEO
          : NOTIFICATION_TYPES.APPROVE_VIDEO,
      })),
    );

export default videoApproveAndDisapproveDataSource;
