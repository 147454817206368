import React, {useCallback, useRef, cloneElement} from 'react';
import PropTypes from 'prop-types';
import QuickPinchZoom, {make3dTransformValue} from 'react-quick-pinch-zoom';

import {
  MIN_SCALE,
  MAX_SCALE,
  ZOOM_DURATION_MS,
} from '../constants/galleryOptions';

// Allow zoom with mouse wheel without press ctrl
const allowInterceptWheel = () => false;

const PinchZoom = ({children, onZoomUpdate, ...props}) => {
  const pinchZoomChildRef = useRef();

  // Required handle zoom change method according to react-quick-pinch-zoom documentation
  const handleZoomUpdate = useCallback(
    ({x, y, scale}) => {
      const container = pinchZoomChildRef.current;

      const fixedScale = Number(scale.toFixed(2));
      const isZoomed = fixedScale > MIN_SCALE;

      let value;

      // Sometimes the scale is not correct(the bug 'react-quick-pinch-zoom'), it must be corrected
      if (isZoomed) {
        value = make3dTransformValue({
          x: Number(x.toFixed(2)),
          y: Number(y.toFixed(2)),
          scale: fixedScale,
        });
      } else {
        value = make3dTransformValue({x: 0, y: 0, scale: 1});
      }

      container?.style.setProperty('transform', value);
      onZoomUpdate?.(isZoomed);
    },
    [onZoomUpdate],
  );

  return (
    <QuickPinchZoom
      minZoom={MIN_SCALE}
      maxZoom={MAX_SCALE}
      zoomOutFactor={MIN_SCALE}
      tapZoomFactor={MAX_SCALE}
      animationDuration={ZOOM_DURATION_MS}
      shouldCancelHandledTouchEndEvents={false}
      draggableUnZoomed={false}
      doubleTapZoomOutOnMaxScale
      shouldInterceptWheel={allowInterceptWheel}
      onUpdate={handleZoomUpdate}
      {...props}
    >
      {cloneElement(children, {
        ref: pinchZoomChildRef,
      })}
    </QuickPinchZoom>
  );
};

PinchZoom.propTypes /* remove-proptypes */ = {
  children: PropTypes.node,
  onZoomUpdate: PropTypes.func,
  containerProps: PropTypes.shape({className: PropTypes.string}),
};

export default PinchZoom;
