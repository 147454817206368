import type {HTMLAttributes, ReactNode, MutableRefObject} from 'react';
import React, {PureComponent, isValidElement} from 'react';
import pickBy from 'lodash/pickBy';

import type {SelectValue} from './Select';

export type NativeSelectValue = {
  name: ReactNode;
  value: string | number;
};

/**
 * Convert array values to string.
 * Is needed to simplify working with SelectNative component
 * because we can pass as 'disabledValues' array of numbers,
 * but 'values' are in ANY case strings.
 *
 * @param {Array} values
 * @returns {Array}
 */
const normalizeArray = (values: SelectValue[]): string[] =>
  values.map((value) => String(value));

export interface SelectNativeProps extends HTMLAttributes<HTMLSelectElement> {
  selectedValue?: SelectValue | SelectValue[];
  values: NativeSelectValue[];
  name?: string;
  disabled?: boolean;
  disabledValues?: SelectValue[];
  hiddenValues?: SelectValue[];
  innerRef?: MutableRefObject<HTMLSelectElement>;
  multiple?: boolean;
}

interface SelectNativeState {
  values: NativeSelectValue[];
  disabledValues: SelectValue[];
  normalizedDisabledValues: string[];
  normalizedHiddenValues: string[];
}

/**
 * @class SelectNative
 * @classdesc Native select. Nothing else
 */
export default class SelectNative extends PureComponent<
  SelectNativeProps,
  SelectNativeState
> {
  static defaultProps = {
    selectedValue: null,
    disabledValues: [],
    /**
     * Needed in case when an option should be hidden from the list
     * to avoid manual selection and select in other way from the code
     */
    hiddenValues: [],
    disabled: false,
    name: '',
    tabIndex: 0,
    multiple: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      values: [],
      disabledValues: [],
      normalizedDisabledValues: [],
      normalizedHiddenValues: [],
    };
  }

  static getDerivedStateFromProps(
    props: SelectNativeProps,
    state: SelectNativeState,
  ) {
    if (
      props.values !== state.values ||
      props.disabledValues !== state.disabledValues
    ) {
      return {
        values: props.values,
        disabledValues: props.disabledValues,
        normalizedDisabledValues: normalizeArray(props.disabledValues),
        normalizedHiddenValues: normalizeArray(props.hiddenValues),
      };
    }

    return null;
  }

  getName({name, value}: NativeSelectValue): ReactNode {
    if (typeof name === 'string') {
      return name;
    }

    if (isValidElement(name)) {
      return name.props.children;
    }

    return value;
  }

  render() {
    const {values, onChange, onFocus, onBlur, onTouchStart, ...otherProps} =
      this.props;

    const props: HTMLAttributes<HTMLSelectElement> &
      Pick<SelectNativeProps, 'disabled' | 'name' | 'multiple'> & {
        value: SelectValue | SelectValue[];
        ref: MutableRefObject<HTMLSelectElement>;
      } = {
      className: this.props.className,
      disabled: this.props.disabled,
      /**
       * Must not be 'null' after init
       * @see documentation about <select />
       */
      value: this.props.selectedValue,
      ref: this.props.innerRef,
      name: this.props.name,
      tabIndex: this.props.tabIndex,
      multiple: this.props.multiple,
    };

    /**
     * Get all possible 'data-attribute' props.
     * Needed to testing purposes.
     */
    const dataProps = pickBy(otherProps, (value, key) =>
      key.startsWith('data-'),
    );

    if (onChange) props.onChange = onChange;
    if (onFocus) props.onFocus = onFocus;
    if (onBlur) props.onBlur = onBlur;
    if (onTouchStart) props.onTouchStart = onTouchStart;

    return (
      // @ts-expect-error: DOM type for value support string | number | readonly string[] we pass array od string | number
      <select {...props} {...dataProps}>
        {values.map((entry) => (
          <option
            key={entry.value}
            value={entry.value}
            disabled={
              this.state.normalizedDisabledValues.indexOf(
                String(entry.value),
              ) !== -1
            }
            // Needed in case when an option should be hidden from the list to avoid manual selection and select in other way from the code
            hidden={
              this.state.normalizedHiddenValues.indexOf(String(entry.value)) !==
              -1
            }
          >
            {this.getName(entry)}
          </option>
        ))}
      </select>
    );
  }
}
