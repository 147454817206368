/**
 * List of payment oneClick flows for card payment.
 */
enum PaymentOneClickFlows {
  /**
   * Default oneClick flow
   */
  SIMPLE = 'simple',
  /**
   * Flow for 3ds request on oneClick.
   */
  USE_3DS = 'use3ds',
  /**
   * Flow for CVV form on oneClick.
   */
  USE_CVV = 'useCvv',
}

export default PaymentOneClickFlows;
