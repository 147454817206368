import {useContext} from 'react';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';

import type {InitialPaymentParams} from '../constants/initialPaymentParams';
import PaymentParamsContext from '../containers/PaymentParamsContext';

/**
 * Return payment params provided by {@see withLocationPaymentParamsProvider} or {@see withStaticPaymentParamsProvider}.
 * @see PaymentParamsProvider
 */
const usePaymentParams = (): Partial<InitialPaymentParams> => {
  const params = useContext(PaymentParamsContext);

  return omitBy<InitialPaymentParams>(params, isNull);
};

export default usePaymentParams;
