import PAYMENT_METHODS from '../../../common/constants/paymentMethods';

export default [
  PAYMENT_METHODS.CARD,
  PAYMENT_METHODS.TRUST_PAY_IDEAL,
  PAYMENT_METHODS.NUVEI_IDEAL,
  PAYMENT_METHODS.COMM_DOO_SEPA,
  PAYMENT_METHODS.NUVEI_MY_BANK,
  PAYMENT_METHODS.NOVAL_NET_SEPA,
  PAYMENT_METHODS.VENDO_SEPA,
  PAYMENT_METHODS.TRUST_PAY_SEPA,
  PAYMENT_METHODS.SALT_EDGE_AIS,
  PAYMENT_METHODS.MICRO_PAYMENT_SEPA,
  PAYMENT_METHODS.SOFORT,
];
