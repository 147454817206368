/**
 * Type of payment system
 */

/**
 * @const {string}
 * All interaction with payment system is based on buying some amount of coins.
 * User flow is based in general on:
 * - choose package with amount of coins, which is needed, in popup
 * - go to PP for buying this package
 */
const COINS = 'coins';

/**
 * @const {string}
 * All interaction with payment system is based on buying packages that includes some amount of provided features.
 * User flow is based in general on:
 * - buying membership package
 * - after buying membership allowed to buy additional "features"
 */
const MEMBERSHIP = 'membership';

export default {
  COINS,
  MEMBERSHIP,
};
