import {useHistory, useLocation} from 'react-router-dom';
import {useEffect, useRef} from 'react';
import {useApolloClient} from '@apollo/client';
import isFunction from 'lodash/isFunction';

import {useCacheRestApiPaymentTemplateSettings} from '@core/payment/common/utils/useRestApiPaymentTemplateSettings';
import logger from '@core/logger';
import isPayUrl from '@core/utils/url/isPayUrl';
import isPaySuccessUrl from '@core/utils/url/isPaySuccessUrl';
import paymentBaseDataLoadingVar from '@core/graphql/vars/paymentBaseDataLoadingVar';
import PAYMENT_ACTIONS, {
  LEGACY_BACKBONE_ACTIONS,
} from '@core/payment/common/constants/paymentActions';

import isEnabledReactPaymentPage from '../utils/isEnabledReactPaymentPage';
import usePaymentParams from '../utils/usePaymentParams';
import {setLoading, setReady} from '../utils/setPaymentProcessingStatus';
import PAYMENT_BASE_DATA_QUERY from '../../pages/graphql/queries/paymentBaseData.gql';
import {getErrorRedirect} from '../utils/useValidateVia';
import {setInitPaymentVariables} from '../utils/paymentInitVariables';

/**
 * Excluded payment routes. Skip preload payment data on selected routes.
 */
const excludePayRoutes = [
  '/pay/appFunnel/newOffer',
  '/pay/appFunnel/getTheApp',
  '/pay/retryPermission',
];

/**
 * Component for preload base payment data and caching it
 * Should be called once during initialization
 * Used for optimize the loading and render speed of the payment page
 * @param isReact - react payment page by default
 * @param ?processExternalPay - process external payment for 3d secure
 */
const PaymentBaseDataLoader = ({isReact, processExternalPay}) => {
  const {
    action,
    prevVia,
    via,
    originalAction,
    source,
    is3dSecureSuccess,
    threeDSecureComplete,
  } = usePaymentParams();

  const client = useApolloClient();

  const history = useHistory();

  const {pathname} = useLocation();

  /**
   * Should be called once for payment page
   */
  const isCalledRef = useRef(false);

  const isPaymentPage = isPayUrl(pathname) && !isPaySuccessUrl(pathname);

  const isExcludePaymentRoute =
    isPaymentPage &&
    excludePayRoutes.some((route) => pathname.startsWith(route));

  const isLegacyBackboneAction = LEGACY_BACKBONE_ACTIONS.includes(action);

  /**
   * Some payment actions used rect payment page by default without cookie
   */
  const isReactPaymentPage =
    isPaymentPage &&
    !isLegacyBackboneAction &&
    (isReact ||
      [PAYMENT_ACTIONS.APP_FUNNEL, PAYMENT_ACTIONS.IFRAME_PP].includes(
        originalAction,
      ) ||
      PAYMENT_ACTIONS.MESSAGE === action ||
      isEnabledReactPaymentPage());

  const isBuggyAlternativeAction = pathname.indexOf('pay/alternative') !== -1;

  if (isBuggyAlternativeAction) {
    logger.sendError('[PaymentBaseDataLoader] Buggy pay/alternative action', {
      skipPercentageCheck: true,
    });
  }

  const isAllowed =
    !isCalledRef.current &&
    isReactPaymentPage &&
    !isBuggyAlternativeAction &&
    !isExcludePaymentRoute;

  /**
   * Need to disable next query preloading if route changed
   * from excluded payment route to allowed payment route
   * because the preload data is not relevant after
   * data was loaded by usePaymentPackages hook.
   */
  useEffect(() => {
    if (isExcludePaymentRoute) {
      isCalledRef.current = true;
    }
  }, [isExcludePaymentRoute]);

  useEffect(() => {
    if (!isAllowed) {
      return;
    }

    paymentBaseDataLoadingVar(true);

    setLoading();

    const variables = {
      action,
      prevVia,
      via,
      source,
      orderIds: [],
    };

    client
      .query({
        query: PAYMENT_BASE_DATA_QUERY,
        variables,
      })
      .then(async () => {
        setInitPaymentVariables(variables);
        setReady();

        if (threeDSecureComplete && isFunction(processExternalPay)) {
          await processExternalPay(action, is3dSecureSuccess);
        }
      })
      .catch((error) => {
        logger.sendError(
          `[PaymentBaseDataLoader] No initial data for payment page ${error}`,
        );

        const errorRedirectUrl = getErrorRedirect({error, via});

        // Redirect to via=unknown if received invalid via
        if (errorRedirectUrl) {
          history.replace(errorRedirectUrl);
        }
      })
      .finally(() => {
        paymentBaseDataLoadingVar(false);
      });

    isCalledRef.current = true;
  }, [
    client,
    action,
    source,
    prevVia,
    via,
    history,
    isAllowed,
    threeDSecureComplete,
    is3dSecureSuccess,
    processExternalPay,
  ]);

  useEffect(() => {
    return () => setInitPaymentVariables(null);
  }, []);

  useCacheRestApiPaymentTemplateSettings();

  return null;
};

export default PaymentBaseDataLoader;
