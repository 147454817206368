import type {ApolloError} from '@apollo/client';
import {useQuery} from '@apollo/client';

import isMale from '../../../utils/isMale';
import isGay from '../../../utils/isGay';
import isLesbian from '../../../utils/isLesbian';
import isFemale from '../../../utils/isFemale';
import CURRENT_USER_SEXUAL_ORIENTATION from '../graphql/queries/currentUserSexualOrientation.gql';
import type {CurrentUserSexualOrientationQuery} from '../graphql/queries/currentUserSexualOrientation';

type UseOrientationAndGenderParams = {
  skip?: boolean;
};

type UseOrientationAndGenderResult = {
  loading: boolean;
  error: ApolloError;
  orientation: {
    isGay: boolean;
    isLesbian: boolean;
  };
  gender: {
    isMale: boolean;
    isFemale: boolean;
  };
};

/**
 * Hook for getting user orientation and gender
 */
const useOrientationAndGender = ({
  skip,
}: UseOrientationAndGenderParams = {}): UseOrientationAndGenderResult => {
  const {data, loading, error} = useQuery<CurrentUserSexualOrientationQuery>(
    CURRENT_USER_SEXUAL_ORIENTATION,
    {
      skip,
    },
  );

  if (!data || loading || error) {
    return {
      loading,
      error,
      orientation: {
        isGay: false,
        isLesbian: false,
      },
      gender: {
        isMale: false,
        isFemale: false,
      },
    };
  }

  const {gender} = data.myUser.profile;
  const {sexualOrientation} = data.myUser.profile.attributes;

  return {
    loading,
    error,
    orientation: {
      isGay: isGay(gender, sexualOrientation),
      isLesbian: isLesbian(gender, sexualOrientation),
    },
    gender: {
      isMale: isMale(gender),
      isFemale: isFemale(gender),
    },
  };
};

export default useOrientationAndGender;
