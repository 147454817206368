import {makeVar} from '@apollo/client';

type Routes = {
  current: string | null;
  previous: string | null;
};

const routeVar = makeVar<Routes>({current: null, previous: null});

export default routeVar;
