enum UserActionsAppearance {
  ROUNDED = 'rounded',

  VERTICAL = 'vertical',

  ROUNDED_REVERSE = 'rounderReverse',

  NARROW_WITH_DIVIDER = 'narrowWithDivider',

  ROUND_AND_COLORIZED = 'roundAndColorized',

  WITH_MAIN_FLIRT_ACTION = 'withMainFlirtAction',

  FLAT_ROUND_WITH_SEPARATED_FAVORITE = 'flatRoundWithSeparatedFavorite',
}

export default UserActionsAppearance;
