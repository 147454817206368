import {MSG} from '../constants/socketEventNames';

export default {
  ChatMatchSubscription: {
    incomingEvents: [`${MSG}chatMatch`],
    fromServer: (data) => ({
      data: {
        matchCommunicationUserId: data.userId,
      },
    }),
  },
};
