import merge from 'lodash/merge';

import activity from './activity';
import chatrooms from './chatrooms';
import credits from './credits';
import likeGallery from './likeGallery';
import messenger from './messenger';
import motivationBanners from './motivationBanners';
import payment from './payment';
import photo from './photo';
import route from './route';
import search from './search';
import worldWide from './worldWide';
import discreetProfiles from './discreetProfiles';
import stories from './stories';
import user from './user';
import video from './video';
import blog from './blog';
import favorites from './favorites';
import speedDating from './speedDating';
import userFeatures from './userFeatures';
import disapprove from './disapprove';
import deviceDetection from './deviceDetection';
import microFeatures from './microFeatures';
import remarketing from './remarketing';
import removeAccount from './removeAccount';

/**
 * typePolicies for Apollo cache.
 * @see createCache
 */
export default merge(
  {},
  activity,
  chatrooms,
  credits,
  likeGallery,
  messenger,
  motivationBanners,
  payment,
  photo,
  route,
  search,
  worldWide,
  discreetProfiles,
  stories,
  user,
  video,
  blog,
  favorites,
  speedDating,
  userFeatures,
  disapprove,
  deviceDetection,
  microFeatures,
  remarketing,
  removeAccount,
);
