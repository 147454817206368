import type {PaymentProcessStep} from '../../types/paymentProcess';
import PaymentPageSuccessOrders from '../../utils/PaymentPageSuccessOrders';
import type {
  PaymentDataResponse,
  PaymentDataRequest,
} from '../../types/paymentFlow';
import activateFeaturesAfterSuccessByVia from './activateFeaturesAfterSuccessByVia';

export const addSuccessOrderInner = (
  params: Pick<PaymentDataResponse, 'status' | 'orderId'> &
    Pick<PaymentDataRequest, 'via' | 'replaceRedirectToPostMessage'>,
) => {
  const {status, orderId, via, replaceRedirectToPostMessage} = params;

  if (orderId && !replaceRedirectToPostMessage && status) {
    activateFeaturesAfterSuccessByVia();
    PaymentPageSuccessOrders.add(orderId, via);
  }
};

const addSuccessOrder: PaymentProcessStep = async (data) => {
  const {paymentResult, paymentData} = data;
  const {status, orderId} = paymentResult;
  const {via, replaceRedirectToPostMessage} = paymentData;

  addSuccessOrderInner({via, replaceRedirectToPostMessage, status, orderId});

  return data;
};

export default addSuccessOrder;
