import type {ReactNode, FC} from 'react';
import React, {createElement} from 'react';
import cn from 'classnames';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import css from './Heading.css';

export enum HeadingAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

enum HeadingWeight {
  NORMAL = 'normal',
  BOLD = 'bold',
}

export enum HeadingLevel {
  H1 = 1,
  H2 = 2,
  H3 = 3,
  H4 = 4,
}

export type HeadingProps = {
  align?: HeadingAlign;
  children: ReactNode;
  className?: string;
  spaced?: boolean;
  inverse?: boolean;
  inline?: boolean;
  success?: boolean;
  muted?: boolean;
  highlighted?: boolean;
  fontWeight?: HeadingWeight;
  trackingName?: string;
  inherit?: boolean;
  primary?: boolean;
  danger?: boolean;
  warning?: boolean;
  level?: HeadingLevel;
  'data-test'?: string;
};

type HeadingStaticProps = {
  ALIGN: typeof HeadingAlign;
  WEIGHT: typeof HeadingWeight;
};

const Heading: FC<HeadingProps> & HeadingStaticProps = ({
  children,
  trackingName,
  className,
  spaced = false,
  inverse = false,
  success = false,
  inline = false,
  align,
  inherit = false,
  muted = false,
  highlighted = false,
  primary = false,
  danger = false,
  warning = false,
  level = 2,
  fontWeight,
  'data-test': dataTest,
}) => {
  const content = createElement(
    `h${level}`,
    {
      className: cn(
        css.heading,
        css[`h${level}`],
        align && css[align],
        fontWeight && css[fontWeight],
        spaced && css.spaced,
        inverse && css.inverse,
        muted && css.muted,
        (highlighted || primary) && css.highlighted,
        danger && css.danger,
        warning && css.warning,
        success && css.success,
        inline && css.inline,
        inherit && css.inherit,
        className,
      ),
      'data-test': dataTest,
    },
    children,
  );

  return trackingName ? (
    <AddBabciaUBTracking trackingName={trackingName}>
      {content}
    </AddBabciaUBTracking>
  ) : (
    content
  );
};

Heading.ALIGN = HeadingAlign;
Heading.WEIGHT = HeadingWeight;

export default Heading;
