import {useQuery} from '@apollo/client';

import VIDEO_UPLOAD_SETTINGS_QUERY from '@core/mediaUpload/graphql/queries/videoUploadSettings.gql';

export default function useUploadSettings() {
  const {data, loading, error} = useQuery(VIDEO_UPLOAD_SETTINGS_QUERY);
  if (loading || error) {
    return {loading, error};
  }
  return data.userFeatures.userVideo.settings;
}
