import type {ReactNode, FC} from 'react';
import React, {useContext, useState, useRef, useMemo, useEffect} from 'react';
import type {Subscription} from 'rxjs';
import type {RouteProps} from 'react-router-dom';
import {Route} from 'react-router-dom';
import {useApolloClient} from '@apollo/client';
import isEqual from 'lodash/isEqual';

import type {ACLRule} from '../types';
import AccessErrorContext from './AccessErrorContext';
import getErrorRedirect from '../utils/getErrorRedirect';
import getErrorsObservable from '../utils/getErrorsObservable';

type ACLRouteProps = {
  rules: ACLRule[];
  fallback?: ReactNode;
} & RouteProps;

type ACLRouteState = {
  loading?: boolean;
  errorCode?: string;
};

/**
 * Helper component for mix access rules with default Route component.
 * In nutshell, we check one by one all access rules, and, if error will emerge - we redirect
 * user to some page
 */
const ACLRoute: FC<ACLRouteProps> = ({rules, fallback, ...props}) => {
  const {rulesMap} = useContext(AccessErrorContext);
  const client = useApolloClient();
  const prevRules = useRef<ACLRule[]>();
  const [state, setState] = useState<ACLRouteState>({});
  const errorsObservable = useRef<Subscription>();

  const rendering = useRef(true);
  rendering.current = true;

  useEffect(() => {
    rendering.current = false;
  });

  const delayedState = useRef<ACLRouteState>();

  useMemo(
    () => {
      const skip = isEqual(rules, prevRules.current);
      prevRules.current = rules;

      if (skip) {
        return;
      }

      Object.assign(state, {
        loading: true,
        errorCode: null,
      });

      let isSyncUpdate = true;

      /**
       * Allows to change state before mounting without warnings.
       */
      const updateState = (nextState: ACLRouteState) => {
        if (isSyncUpdate) {
          // Just modify the state when rules checked synchronously.
          Object.assign(state, nextState);
        } else if (rendering.current) {
          // To call `setState` AFTER render to avoid React warnings.
          delayedState.current = nextState;
        } else {
          setState(nextState);
        }
      };

      const checkAllRules = () => {
        /**
         * Unsubscribe from previous rules since when we change
         * route - component doesn't unmount, it calls update.
         */
        errorsObservable.current?.unsubscribe();

        errorsObservable.current = getErrorsObservable(rules, client).subscribe(
          (errors) => {
            // Just set first error
            updateState({
              errorCode: errors[0],
              loading: false,
            });
          },
        );
      };

      checkAllRules();

      isSyncUpdate = false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we don't need `state` here.
    [rules],
  );

  const nextState = delayedState.current;
  delayedState.current = null;

  useEffect(() => {
    if (nextState) {
      setState(nextState);
    }
  }, [nextState]);

  useEffect(
    () => () => {
      errorsObservable.current?.unsubscribe();
    },
    [],
  );

  const {loading, errorCode} = state;
  /**
   * When no rule was executed - show loader.
   * Because rules work async and, sometimes, they need to fetch some data from server.
   */
  if (loading && fallback) {
    return fallback;
  }

  // Indicates, that some ACL error was caught, and we need to go somewhere.
  if (errorCode) {
    return getErrorRedirect(errorCode, rulesMap)(props);
  }

  return <Route {...props} />;
};

export default ACLRoute;
