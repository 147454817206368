import PopperPlacement from '../../constants/PopperPlacement';

/**
 * Changes the direction of the popover when direction of the text is right to left
 * @param {String} direction
 * @param {Boolean} rtl
 * @returns {String}
 */
const changeRtlDirection = (
  direction: PopperPlacement,
  rtl: boolean,
): PopperPlacement => {
  if (!rtl) return direction;

  switch (direction) {
    case PopperPlacement.BOTTOM_END:
      return PopperPlacement.BOTTOM_START;
    case PopperPlacement.TOP_END:
      return PopperPlacement.TOP_START;
    case PopperPlacement.BOTTOM_START:
      return PopperPlacement.BOTTOM_END;
    case PopperPlacement.TOP_START:
      return PopperPlacement.TOP_END;
    case PopperPlacement.LEFT:
      return PopperPlacement.RIGHT;
    case PopperPlacement.RIGHT:
      return PopperPlacement.LEFT;
    default:
      return direction;
  }
};

export default changeRtlDirection;
