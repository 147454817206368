import {getClientInstance} from '@core/graphql/client';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';

import type {PaymentProcessStep} from '../../types/paymentProcess';

const updateMicroFeaturesAfterPayment: PaymentProcessStep = async (data) => {
  invalidateCacheByTypename(getClientInstance(), 'MicroFeatures');

  return data;
};

export default updateMicroFeaturesAfterPayment;
