import logger from '@core/logger';
import type {
  PaymentDataRequest,
  PaymentFlowResponse,
} from '@core/payment/payProcess/types/paymentFlow';

import type {
  PaymentFlowMethods,
  PaymentProcessStepData,
} from '../types/paymentProcess';
import {setReady} from '../../common/utils/setPaymentProcessingStatus';

export enum PaymentFlowStatus {
  ABORTED = 'paymentFlowAbort',
}

/**
 * Return function that execute payment flow methods one by one
 */
const getProcessPaymentFlow = (paymentFlow: PaymentFlowMethods) => {
  const paymentFlowSequence = Array.isArray(paymentFlow)
    ? paymentFlow
    : [...paymentFlow.beforePay, ...paymentFlow.pay, ...paymentFlow.afterPay];

  if (!paymentFlowSequence.length) {
    logger.sendError('[getProcessPaymentFlow]: No process payment data flow');
  }

  /**
   * Run payment method
   * All methods receive paymentData from previous method and return it to next
   * @param {Object} data - payment data
   * @returns {Promise<Object>}
   */
  const processPaymentFlow = async (
    data: PaymentProcessStepData,
  ): Promise<PaymentProcessStepData> => {
    const currentStep = paymentFlowSequence.shift();

    if (!currentStep) {
      return data;
    }

    try {
      const result = await currentStep(data);
      const {flowStatus} = result;

      // Skip next payment flows
      if (flowStatus === PaymentFlowStatus.ABORTED) {
        setReady();

        return result;
      }

      return processPaymentFlow(result);
    } catch (error) {
      logger.sendError(
        `[processPaymentFlow]: Process payment flow error ${error}`,
      );
      throw error;
    }
  };

  return async (
    data: PaymentDataRequest,
  ): Promise<PaymentFlowResponse | PaymentFlowStatus> => {
    const result = await processPaymentFlow({
      paymentData: data,
    });

    const {flowStatus, paymentResult, paymentData} = result;

    if (typeof flowStatus === 'string') {
      return flowStatus;
    }

    const {
      via,
      prevVia,
      title,
      method,
      returnPath,
      scenario,
      replaceRedirectToPostMessage,
      cancelRemarketingSubscription,
    } = paymentData;
    return {
      ...paymentResult,
      via,
      title,
      prevVia,
      method,
      scenario,
      returnPath,
      replaceRedirectToPostMessage,
      cancelRemarketingSubscription,
    };
  };
};

export default getProcessPaymentFlow;
