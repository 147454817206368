/**
 * Name of popup container.
 */
export const POPUP_ID = 'popup';

/**
 * Name of popup actions container.
 */
export const POPUP_ACTIONS_ID = 'popupFrameActions';

/**
 * Name of popup scrollable view container.
 */
export const POPUP_SCROLLABLE_VIEW_ID = 'popupScrollableView';
