import lcfirst from './lcfirst';
import ucfirst from './ucfirst';

/**
 * Convert string to Camel Case from Kebab Case, Snake Case or mix of them
 * @example
 *     Test-simple_string'.toCamelCase(); // returns: testSimpleString
 *
 * @param {string} str
 * @returns {string} string in Camel Case format
 */
const toCamelCase = (str) =>
  lcfirst(
    str.replace(/([-_][^\-|_]*)/g, ($1) => ucfirst($1.replace(/[-_]/, ''))),
  );

export default toCamelCase;
