import type {FC, ReactElement, ReactNode} from 'react';
import React, {isValidElement, cloneElement} from 'react';
import cn from 'classnames';

import type {IconProps} from '@core/ui/components/icon/Icon';

import {Icon} from '../icon';
import css from './MenuItemContent.css';

export interface MenuItemContentProps {
  children: ReactNode;
  className?: string;
  icon?: ReactElement<IconProps> | string;
  showIcon?: boolean;
  cut?: boolean;
  inverse?: boolean;
  showCheck?: boolean;
}

/**
 * @class MenuItemContent
 * @classdesc Content of select, created to simplify design of items with icon
 */
const MenuItemContent: FC<MenuItemContentProps> = ({
  children,
  icon,
  className,
  showIcon = true,
  showCheck = false,
  cut = false,
  inverse,
}) => (
  <div className={cn(css.content, className)}>
    {icon && (
      <div className={cn(css.icon, !showIcon && css.hidden)}>
        {isValidElement(icon) ? (
          cloneElement<IconProps>(icon, {inverse})
        ) : (
          <Icon type={icon} standard inverse={inverse} />
        )}
      </div>
    )}
    <div className={cut ? css.cutedValue : css.value}>{children}</div>
    {showCheck && (
      <div className={css.check} data-test="menuItemContentCheck">
        <Icon type="check" standard inverse={inverse} />
      </div>
    )}
  </div>
);

export default MenuItemContent;
