import type {Operation, NextLink, FetchResult} from '@apollo/client/core';
import {ApolloLink} from '@apollo/client/core';
import type {OperationDefinitionNode} from 'graphql';

import {getCookie} from '@core/utils/cookie';

import formatMessage from './formatMessage';
import logging from './logging';

const loggerLink = new ApolloLink((operation: Operation, forward: NextLink) => {
  if (!Number(getCookie('remoteDevtoolsAllowed'))) {
    return forward(operation);
  }

  const startTime = new Date().getTime();

  return forward(operation).map((result: FetchResult) => {
    const operationType = (
      operation.query.definitions[0] as OperationDefinitionNode
    ).operation;
    const elapsed = new Date().getTime() - startTime;

    const group = formatMessage(operationType, operation, elapsed);

    logging.groupCollapsed(...group);

    logging.log('------');

    logging.log(`INIT ${operation.operationName}`, operation);
    logging.log(`RESULT ${operation.operationName} (in ${elapsed}ms)`, result);

    logging.log('------');

    logging.groupEnd(...group);
    return result;
  });
});

export default loggerLink;
