import once from 'lodash/once';

import {getClientInstance} from '@core/graphql/client';

import type {PhotoUploadSubscription} from '../graphql/subscriptions/photoUpload';
import PHOTO_UPLOAD_SUBSCRIPTION from '../graphql/subscriptions/photoUpload.gql';

const getPhotoUploadSubscription = once(() =>
  getClientInstance().subscribe<PhotoUploadSubscription>({
    query: PHOTO_UPLOAD_SUBSCRIPTION,
  }),
);

export default getPhotoUploadSubscription;
