import {useMemo, useRef, useEffect, useState, useCallback} from 'react';
import {useQuery, useReactiveVar} from '@apollo/client';
import {useLocation} from 'react-router-dom';

import getHistory from '@core/application/utils/getHistory';
import type {PaymentBottomNoticeQuery} from '@core/payment/banners/bottomNotice/graphql/queries/paymentBottomNotice';
import {getAllHashedLinksList} from '@core/application/utils/hashedStaticLinks';
import EMPTY_ROUTES_FOR_REDIRECT from '@core/application/constants/emptyRoutesForRedirect';
import useSiteName from '@core/application/utils/useSiteName';
import FULLSCREEN_ROUTES from '@core/application/constants/fullscreenRoutes';
import routeVar from '@core/graphql/vars/routeVar';
import isInRouteList from '@core/utils/routing/isInRouteList';
import {isPayUrl} from '@core/utils/url';
import ROUTES from '@core/application/constants/routes';
import type {AlertBannerEnum} from '@core/types';

import PAYMENT_BOTTOM_NOTICE_QUERY from '../graphql/queries/paymentBottomNotice.gql';
import usePaymentStatus from '../../../common/utils/usePaymentStatus';
import usePaymentPhoneNumber from '../../../common/utils/usePaymentPhoneNumber';
import generatePayUrl from '../../../common/utils/generatePayUrl';

export const DISABLED_ROUTES = [
  '/forbidden',
  '/notFound',
  '/pay',
  '/trusted',
  '/staticPage',
  ROUTES.DESCRIPTOR,
  ...getAllHashedLinksList(),
  ...FULLSCREEN_ROUTES,
  ...EMPTY_ROUTES_FOR_REDIRECT,
];

type PaymentBottomNoticeResult = {
  kind: AlertBannerEnum;
  siteName: string;
  phoneNumber: string;
  handleCloseClick: () => void;
  handleActionClick: () => void;
} | null;

/**
 * Implements logic of hiding or showing notice on certain routes
 * and data fetching.
 */
const usePaymentBottomNotice = (): PaymentBottomNoticeResult => {
  const [hidden, setHidden] = useState(false);
  const {current, previous} = useReactiveVar(routeVar);

  const {pathname} = useLocation();

  const disabled = useMemo(
    () => isInRouteList(DISABLED_ROUTES, pathname),
    [pathname],
  );

  const {data, loading, refetch} = useQuery<PaymentBottomNoticeQuery>(
    PAYMENT_BOTTOM_NOTICE_QUERY,
    {
      skip: disabled,
    },
  );

  const {loading: accountStatusLoading, ...accountStatus} = usePaymentStatus();
  const {siteName, loading: siteNameLoading} = useSiteName();
  const {phoneNumber, loading: phoneNumberLoading} = usePaymentPhoneNumber();

  const handleCloseClick = useCallback(() => {
    setHidden(true);
  }, []);

  const handleActionClick = useCallback(() => {
    if (!data?.payment) {
      return;
    }

    const payUrl = generatePayUrl({
      accountStatus,
      urlParams: {
        via: data.payment.banner.alertBanner.via,
      },
    });

    getHistory().push(payUrl);
  }, [accountStatus, data]);

  const needToReFetch = useRef(true);

  /**
   * There is a case, when we have already fetched data, but it can be
   * not actual since it changes during working on payment page internally, on
   * backend. So we force network request everytime when we leave payment page.
   */
  useEffect(() => {
    const isPrevPayUrl = isPayUrl(previous);
    const isCurrPayUrl = isPayUrl(current);

    // Avoid double-fetch if query is under "loading".
    if (isPrevPayUrl && !isCurrPayUrl && needToReFetch.current && !loading) {
      refetch();
      needToReFetch.current = false;
      return;
    }

    if (!isPrevPayUrl && isCurrPayUrl) {
      needToReFetch.current = true;
    }
  }, [loading, refetch, current, previous]);

  const allowed = useMemo(
    () =>
      // There are routes where is disallowed.
      !disabled &&
      // Or when data is fetching
      !loading &&
      // Or the case when "alertBanner" is "null". It means that nothing is allowed.
      Boolean(data?.payment?.banner?.alertBanner),
    [disabled, data, loading],
  );

  // No placeholder required. Nothing bad if widget goes rendered after 1s.
  if (
    !allowed ||
    accountStatusLoading ||
    siteNameLoading ||
    phoneNumberLoading ||
    hidden
  ) {
    return null;
  }

  return {
    kind: data.payment.banner.alertBanner.kind,
    siteName,
    phoneNumber,
    handleCloseClick,
    handleActionClick,
  };
};

export default usePaymentBottomNotice;
