import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import {
  settingsPropType,
  errorPropType,
} from '@core/mediaUpload/constants/propTypes';

/**
 * Abstract component, used by photo/video components.
 * @abstract
 * @see VideoUploadPopup, PhotoUploadPopup
 */
const MediaUploadPopupDisplayer = ({
  layoutComponent: Layout,
  loaderComponent: Loader,
  uploadErrorsInlineComponent: UploadErrorsInline,
  openErrorsPopup,
  settings,
  onFileSelect,
  errors,
  clearErrors,
  filesCount,
  uploadRenderer,
  webcamRenderer,
  previewRenderer,
  motivation,
  requirements,
  pmaCheckbox,
  webcamRequirements,
  spacedAbove,
  isUploading,
  ...props
}) => {
  const [isWebcamCapture, setIsWebcamCapture] = useState(
    settings.isWebcamCapture,
  );
  const [preview, setPreview] = useState(null);

  const handleCaptureClick = useCallback(() => {
    setIsWebcamCapture(true);
    setPreview(null);
    clearErrors();
  }, [setIsWebcamCapture, clearErrors]);
  const handleBackClick = useCallback(() => {
    setIsWebcamCapture(false);
    clearErrors();
  }, [setIsWebcamCapture, clearErrors]);
  const handlePreviewSubmit = useCallback(
    () => onFileSelect([preview]),
    [onFileSelect, preview],
  );
  const handlePreviewCancel = useCallback(() => {
    setPreview(null);
    clearErrors();
  }, [clearErrors]);
  const handleMoreErrorsClick = useCallback(() => {
    openErrorsPopup({
      settings,
      errors,
      onFileSelect,
      onCaptureClick: handleCaptureClick,
      uploadRenderer,
    });
  }, [
    openErrorsPopup,
    settings,
    errors,
    onFileSelect,
    handleCaptureClick,
    uploadRenderer,
  ]);

  const showPreview = isWebcamCapture && !!preview;
  const showWebcam = isWebcamCapture && !preview;
  const showUpload = !isWebcamCapture;
  const showBackButton = showWebcam || showPreview;
  const showRequirements = !(errors && errors.length);

  return (
    <Loader active={isUploading} data-test="mediaUploadPopup">
      <Layout
        showBackButton={showBackButton}
        onBackClick={handleBackClick}
        spacedAbove={spacedAbove || (showUpload && !motivation)}
        // Pieces of layout that construct media upload based on all possible modifications
        requirements={showRequirements && !isWebcamCapture && requirements}
        webcamRequirements={
          showRequirements && isWebcamCapture && webcamRequirements
        }
        pmaCheckbox={showUpload && pmaCheckbox}
        error={
          errors &&
          errors.length > 0 && (
            <UploadErrorsInline
              errors={errors}
              filesCount={filesCount}
              onMoreErrorsClick={handleMoreErrorsClick}
            />
          )
        }
        motivation={showUpload && motivation}
        preview={
          showPreview &&
          previewRenderer({
            blob: preview,
            onSubmit: handlePreviewSubmit,
            onCancel: handlePreviewCancel,
          })
        }
        webcam={showWebcam && webcamRenderer({onCapture: setPreview})}
        upload={
          showUpload &&
          uploadRenderer({
            onFileSelect,
            onCaptureClick: handleCaptureClick,
          })
        }
        {...props}
      />
    </Loader>
  );
};

MediaUploadPopupDisplayer.propTypes /* remove-proptypes */ = {
  layoutComponent: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.func,
  ]).isRequired,
  loaderComponent: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.func,
  ]).isRequired,
  uploadErrorsInlineComponent: PropTypes.func.isRequired,
  openErrorsPopup: PropTypes.func.isRequired,
  settings: settingsPropType.isRequired,
  onFileSelect: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(errorPropType),
  clearErrors: PropTypes.func.isRequired,
  filesCount: PropTypes.number,
  uploadRenderer: PropTypes.func.isRequired,
  webcamRenderer: PropTypes.func.isRequired,
  previewRenderer: PropTypes.func.isRequired,
  motivation: PropTypes.node,
  requirements: PropTypes.node,
  pmaCheckbox: PropTypes.node,
  webcamRequirements: PropTypes.node,
  spacedAbove: PropTypes.bool,
  isUploading: PropTypes.bool,
};

export default MediaUploadPopupDisplayer;
