const SOCKET_EVENT_NAME = 'phoenixInteraction';

/**
 * Socket event
 * @param {String} interactionName
 * @param {Object} data
 */
export const dispatch = (interactionName, data = {}) => {
  window.dispatchEvent(
    new CustomEvent(SOCKET_EVENT_NAME, {
      detail: {
        data,
        interactionName,
      },
    }),
  );
};

/**
 * @param {[String]} names
 * @param {Function} handle
 * @return {Function}
 */
export const listenToNames = (names, handle) => {
  const filteredHandle = ({detail: {interactionName, data}}) => {
    if (!names.includes(interactionName)) {
      return;
    }

    handle(data);
  };

  window.addEventListener(SOCKET_EVENT_NAME, filteredHandle, false);

  return filteredHandle;
};

/**
 * @param {Function} handle
 */
export const remove = (handle) => {
  window.removeEventListener(SOCKET_EVENT_NAME, handle, false);
};
