import EXTRA_DAY_VIA from '@core/extraEvent/constants/vias';

const SPIN_EXTRA_DAY = 'spin_extraDay';

/**
 * @const via for redirect top change card details from message popup
 * @type {string}
 */
export const POPUP_MESSAGES_CHANGE_CARD_VIA = 'msngr_1click_changeCard';
/**
 * @const via for redirect to messages PP from My Profile
 * @type {string}
 */
export const BUY_MESSAGES_BANNER_VIA = 'myProfile_paid_messages';

/**
 * @const via for redirect from locked profiles by search limit
 * @type {string}
 */
export const VIEW_PROFILE_CLOSED_VIA = 'view_profile_closed';

/**
 * @const {Object} - vias for app funnel
 */
export const PAY_APP_FUNNEL_VIAS = {
  FIRST_VIA: 'ftcard_1st',
  SECOND_VIA: 'ftcard_2st',
  NEO_BANK_FIRST_VIA: 'ftcard_1st_special',
  NEO_BANK_SECOND_VIA: 'ftcard_2st_lifetime',
  FT_CARD_TRIAL: 'ftcard_trial',
  FT_CARD_PP: 'ftcard_pp',
};

/**
 * @const Default via for redirecting from banners
 * @type {string}
 */
export const DEFAULT_VIA = 'app_index';
export const SEARCH_LIMIT = 'search_limit';
export const ADD_FAVOR_VIA = 'add_favor';
export const ADD_LIKE_VIA = 'add_like';
export const LIKE_READ_VIA = 'like_read';
export const UNKNOWN_VIA = 'unknown';
export const DIRECT_VIA = 'direct_link';
export const TOOLBAR_TOOLTIP_UPGRADE_BUTTON = 'toolbar_tooltip_upgrade_button';
export const TOOLBAR_UPGRADE_BUTTON_VIA = 'toolbar_upgrade_button';
export const TOOLBAR_TOOLTIP_UPGRADE_BUTTON_VIA =
  'toolbar_tooltip_upgrade_button';
export const GENEROUS_GIFT_VIA = 'generous_gift';
export const ACCOUNT_VIA = 'account';
export const BUY_3DAY_GET_1WEEK_POPUP = 'buy3DayGet1WeekPopup';
export const REMARKETING_POPUP_VIA = 'remarketing_popup';
export const EXTRA_DAY_VIAS = [EXTRA_DAY_VIA, SPIN_EXTRA_DAY];
export const PAID_FUNNEL_VIA = 'paidfunnel_m';
export const MICRO_FEATURES_PEDAL = 'microFeaturesPedal';
export const EXTRA_DAY_POPUP = 'extraDayPopup';
export const TELEGRAM_CONVERSATION_VIA = 'telegramConversation';
export const QUIZ_FUNNEL = 'quizfunnel';
export const CREDIT = 'credit';

/**
 * @const {Array}
 */
export const VIA_WITH_SELECTED_PACKAGE = [
  BUY_3DAY_GET_1WEEK_POPUP,
  ...EXTRA_DAY_VIAS,
];

/**
 * @const via for redirecting from search widget with video preview
 * @type {string}
 */
export const MICROFEATURES_VIA = 'microFeatures';

/**
 * Via for redirect to PP on pma photo request
 * @type {string}
 */
export const VIA_PMA_PHOTO_REQUEST = 'ph_request';

/**
 * used on 5 free messages popup after registration and in messenger
 * @type {string}
 */
export const VIA_5_FREE_VERIFY = 'free5_verify';

/**
 * Via for redirect to PP on pma photo request for confirm age
 * @type {string}
 */
export const VIA_PMA_PHOTO_REQUEST_AGE = 'pma_photo_request_age';

/**
 * Via for redirect to PP from free messages end notification for free user
 * @type {string}
 */
export const VIA_NOTIF_FREE_MSG_END = 'notif_FreeMssgEnd';

/**
 * Via for redirect to PP from free messages end notification for paid user in case when messages ended
 * @type {string}
 */
export const VIA_NOTIF_PAID_MSG_END = 'notif_PaidMssgEnd';
