import memoize from 'lodash/memoize';

import logger from '@core/logger';
import {PayButtonEnum} from '@core/types';

import checkTestWalletPayLogic from '../../../payProcess/utils/checkTestWalletPayLogic';
import {WALLET_METHODS} from '../../../common/constants/paymentMethods';
import trackSessionRequest from '../../../utils/trackSessionRequest';
import API_VERSION_PARAMS from '../constants/apiVersionParams';
import {TEST_METHOD_SETTINGS} from '../constants/googlePay';

/**
 * Return google pay client
 * @param {object} settings
 * @returns {Promise<null|Window.google.payments.api.PaymentsClient>}
 */
const getGooglePayPaymentsClient = async (settings = {}) => {
  /**
   * It is here to not to add to all places of use on the BB payment page
   */
  let gPaySettings = settings;

  const isTestLogic = checkTestWalletPayLogic(WALLET_METHODS.GOOGLE_PAY);

  if (isTestLogic) {
    gPaySettings = TEST_METHOD_SETTINGS;
  }

  /**
   * Data for check if google api is allowed
   * @type {object}
   */
  const isReadyToPayParams = {
    ...API_VERSION_PARAMS,
    /* Describes the visitor's readiness to pay with one or more payment methods
     * specified in allowedPaymentMethods
     */
    existingPaymentMethodRequired: true,
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: gPaySettings.allowedAuthMethods,
          allowedCardNetworks: gPaySettings.allowedCardNetworks,
        },
      },
    ],
  };

  try {
    if (window.google) {
      const paymentsClient = new window.google.payments.api.PaymentsClient({
        environment: gPaySettings.environment,
      });

      const {result, paymentMethodPresent} =
        await paymentsClient.isReadyToPay(isReadyToPayParams);

      if (result && (paymentMethodPresent || isTestLogic)) {
        trackSessionRequest(PayButtonEnum.googlePay);
        return paymentsClient;
      }
    }

    return null;
  } catch (err) {
    logger.sendError(
      `Cant check ready status for google pay api: ${JSON.stringify(err)}`,
    );

    return null;
  }
};

export default memoize(getGooglePayPaymentsClient);
