import type {ApolloClient, ApolloError} from '@apollo/client';

export type Client = ApolloClient<object>;

export enum ErrorBoundarySize {
  ONLY_ICON = 'onlyIcon',
  SMALL = 'small',
  NORMAL = 'normal',
}

export type ErrorBoundaryProps = {
  error?: ApolloError;
  errorSize?: ErrorBoundarySize;
  title?: string;
  text?: string;
  fullHeight?: boolean;
};
