import TYPES, {
  NOT_INDEPENDENT_MSG_TYPES,
} from '@core/extraEvent/constants/extraEventTypes';

import {MSG} from '../constants/socketEventNames';

const getAllExtraEvents = (types) =>
  Object.values(types).map((type) =>
    NOT_INDEPENDENT_MSG_TYPES.includes(type) ? MSG + type : type,
  );

export default {
  ExtraEventSubscription: {
    incomingEvents: getAllExtraEvents(TYPES),

    fromServer: ({type, processId, isComboPedalSecondPart}) => ({
      data: {
        extraEvents: {
          type,
          processId: processId ?? null,
          isComboPedalSecondPart: isComboPedalSecondPart ?? null,
          __typename: 'ExtraEvents',
        },
      },
    }),
  },
};
