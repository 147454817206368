/**
 * Listing of all routes in terms of application.
 *
 * !!! Attention this file parsed at:
 * @see /scripts/generateRoutesList.js
 * Be carefully if you change file structure
 */
const ROUTES = {
  INDEX: '/',
  FORBIDDEN: '/forbidden',
  ACCOUNT: '/account',
  CHAT: '/chat',
  DESCRIPTOR: '/d/',
  ACCOUNT_REPORTED: '/account/reported',
  ACCOUNT_BLOCKED: '/account/blocked',
  ACCOUNT_UNSUBSCRIBE: '/account/unsubscribe',
  FAVORITES: '/favorites',
  NEWS_FEED: '/newsFeed',
  NEWS_FEED_FILTER: '/newsFeed/:filter',
  NEWS_FEED_ALL: '/newsFeed/all',
  PAY: '/pay',
  GET_THE_APP: '/getTheApp',
  RETRY_PERMISSION: '/pay/retryPermission',
  ROOMS_MODERATOR: '/rooms/moderator',
  PROFILE_MENU: '/profile/menu',
  PROFILE: '/profile',
  CANCEL: '/cancel',
  PWA_NEXT_STEP: '/pwaNextStep',
  SEARCH: '/search',
  CONTACT_US: '/support/contactUs',
  USER: '/user/view/id/:id',
  MASS_BLOCKED: '/user/massBlocked',
  PASSWORD_RECOVERY: '/site/passwordrecovery',
  STATIC_PAGE: '/staticPage/:page',
  EMAIL_UPDATE: '/account/emailUpdate',
  MESSENGER: '/messenger',
  CONTACT_US_LEGACY: '/site/contactUs',
  PARTNER_NETWORK: '/partnerNetwork',
};

export const FAST_PP_ROUTES = {
  FAST_PP_ROUTES_USA: '/pay/membershipFU',
  FAST_PP_ROUTES_GBR: '/pay/membershipFG',
  FAST_PP_ROUTES_FRA: '/pay/membershipFF',
};

/**
 * Used by /scripts/generateRoutesList.js
 */
export const EXACT_ROUTES = [
  ROUTES.INDEX,
  ROUTES.PROFILE_MENU,
  ROUTES.ACCOUNT_BLOCKED,
  ROUTES.ACCOUNT_REPORTED,
  ROUTES.ACCOUNT,
  ROUTES.NEWS_FEED,
  ROUTES.MESSENGER,
  ROUTES.CONTACT_US_LEGACY,
];

/**
 * Used by /scripts/generateRoutesList.js
 */
export const ROUTES_LIST = Object.values(ROUTES);

export default ROUTES;
