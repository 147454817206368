import type {ApolloError} from '@apollo/client';
import {useQuery} from '@apollo/client';

import type {SiteIdQuery} from '../graphql/queries/siteId';
import SITE_ID_QUERY from '../graphql/queries/siteId.gql';

type UseSiteIdResult = {
  siteId: string | null;
  loading: boolean;
  error: ApolloError | null;
};

const useSiteId = (): UseSiteIdResult => {
  const {data, loading, error} = useQuery<SiteIdQuery>(SITE_ID_QUERY);

  return {
    siteId: data?.site.siteId,
    loading,
    error,
  };
};

export default useSiteId;
