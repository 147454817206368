/* eslint-disable no-console */
type ConsoleMethod = (...args: any[]) => void;

const bindToConsole = (
  consoleMethod: ConsoleMethod | undefined,
  polyfill: ConsoleMethod,
): ConsoleMethod => {
  return consoleMethod ? consoleMethod.bind(console) : polyfill;
};

const logging = (() => {
  let prefix = '';

  const consoleLog: ConsoleMethod = (...args) => {
    console.log(prefix, ...args);
  };

  const consoleError: ConsoleMethod = (...args) => {
    console.error(prefix, ...args);
  };

  const consoleGroup: ConsoleMethod = (...args) => {
    consoleLog(...args);
    prefix += '> ';
  };

  const consoleGroupEnd: ConsoleMethod = () => {
    prefix = prefix.slice(0, -2);
  };

  return {
    log: consoleLog,
    error: consoleError,
    group: bindToConsole(console.group, consoleGroup),
    groupCollapsed: bindToConsole(console.groupCollapsed, consoleGroup),
    groupEnd: bindToConsole(console.groupEnd, consoleGroupEnd),
  };
})();

export default logging;
