import createDefaults from '../utils/createDefaults';
import {mergeArrayField as merge} from '../utils/mergeArrayField';
import storiesMaxLimitVar from '../vars/storiesMaxLimitVar';

export default {
  Query: {
    fields: createDefaults({
      stories: {__typename: 'Stories'},
    }),
  },
  Stories: {
    keyFields: [],
    fields: {
      list: {
        keyArgs: false,
        merge,
      },
      maxLimit: {
        read() {
          return storiesMaxLimitVar();
        },
        merge(prevMaxLimit, newMaxLimit) {
          return storiesMaxLimitVar(newMaxLimit);
        },
      },
    },
  },
  StoryItem: {
    fields: {
      parts: {merge: false},
    },
  },
};
