import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import {createLocation, createPath} from 'history';

import logger from '@core/logger';
import PopupService from '@core/popup/utils/PopupService';
import PopupSourceEvent from '@core/popup/constants/PopupSourceEvent';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import trackRedirectTo from '@core/tracking/babcia/utils/trackRedirectTo';
import {
  PAID_FUNNEL_VIA,
  REMARKETING_POPUP_VIA,
} from '@core/payment/common/constants/vias';
import isPaymentUrl from '@core/utils/url/isPayUrl';
import {sessionStorage} from '@core/utils/storage/storage';
import createStorageMap from '@core/utils/storage/createStorageMap';
import isPaySuccessUrl from '@core/utils/url/isPaySuccessUrl';

import type {
  BeforePushListener,
  CustomHistory,
  CustomLocation,
  CustomPath,
  HistoryListener,
} from '../types';
import {Action} from '../constants/history';
import applyHistoryIndexer from './applyHistoryIndexer';
import getBootstrapParam from './getBootstrapParam';
import {IN_APP_NAME} from '../constants/bootstrapParams';
import InAppBrowserName from '../constants/inAppBrowserName';
import ROUTES, {FAST_PP_ROUTES} from '../constants/routes';

/**
 * Helper to simplify work with sessionStorage
 */
const storage = createStorageMap<{
  backInfo: {pathname: string; index: number};
  forwardInfo: {forwardTo: number; index: number};
}>(
  sessionStorage,
  [
    /**
     * Storage for details of the history entry to which we want to return after payment.
     */
    'backInfo',
    /**
     * Storage for details of the history entry to which we want to forward instead of payment.
     */
    'forwardInfo',
  ],
  'history.',
);

/**
 * Moves info for custom forward navigation from sessionStorage to history state.
 */
const moveForwardInfo = ({history, originalReplace}, reloaded = false) => {
  if (!storage.forwardInfo) return;

  const {forwardTo, index} = storage.forwardInfo;
  storage.forwardInfo = null;
  if (history.location.index === index) {
    originalReplace({...history.location, forwardTo});
    history.action = Action.POP;
  } else {
    logger.sendWarning(
      `[historyMiddleware] Incorrect backTo navigation detected (${
        reloaded ? 'with' : 'without'
      } reload)`,
    );
  }
};

const handleUnload = () => {};

/**
 * Does passed action and resolves after actual history change.
 * @param listen - `history.listen` function.
 * @param action - function that leads to history change.
 */
const waitForChange = (listen, action) =>
  new Promise<void>((resolve) => {
    const removeListener = listen(() => {
      removeListener();
      resolve();
    });
    action();
  });

/**
 * Compares two locations
 * @param {Object} location1
 * @param {Object} location2
 * @returns {Boolean}
 */
const isEqualLocations = (location1, location2) =>
  createPath(location1) === createPath(location2) &&
  isEqual(location1.state, location2.state);

/**
 * This function calls before history.push() and allows:
 *   - modify location before navigation
 *   - cancel navigation
 *   - do some side effects
 *
 * @param {Object} nextLocation
 * @param {Object} history
 * @param {Function} originalReplace - original browserHistory.replace
 * @param {Function} skipNextChange
 * @param {Function[]} beforePushListeners
 * @returns {Object|null} new location or null when need to cancel navigation
 */
const beforePush = async ({
  nextLocation,
  history,
  originalReplace,
  skipNextChange,
  beforePushListeners,
}) => {
  PopupService.closePopup(true, PopupSourceEvent.BY_ROUTING);

  let location = nextLocation;

  // Prevent navigation to /search when you are already on /search.
  // Useful for /search/livecam too.
  if (
    location.pathname.startsWith('/search') &&
    !location.search &&
    location.pathname === history.location.pathname
  ) {
    return null;
  }

  if (isEqualLocations(location, history.location)) {
    // do not create the same history entry
    return null;
  }

  for (let i = 0; i < beforePushListeners.length; i++) {
    // eslint-disable-next-line no-await-in-loop
    const result = await beforePushListeners[i](location);

    if (result === false) {
      return null;
    }
  }

  const isPayment = isPaymentUrl(history.location.pathname);
  const nextIsPayment = isPaymentUrl(location.pathname);
  if (isPayment && !nextIsPayment) {
    if (storage.backInfo) {
      const {pathname, index} = storage.backInfo;
      storage.backInfo = null;

      /**
       * We may have incorrect indexes if `history.length` reached its limit.
       * Skip custom return logic in this case, because it may not work properly.
       */
      const unsafeBack = [50, 100].includes(history.length);
      if (!unsafeBack) {
        // Compare only pathname because PP doesn't use other url parts while redirect:
        if (pathname === location.pathname) {
          const nextIndex = index - history.currentIndex;

          if (isPaySuccessUrl(history.location.pathname) && nextIndex <= 0) {
            history.replace(location);
            return null;
          }

          /**
           * history.location.index could be incorrect because of navigations inside iframe on a page,
           * so use history.currentIndex instead.
           */
          history.go(nextIndex);
          return null;
        }
        location = {...location, backTo: index};
      }
    }
    // else we don't need the custom return logic.
  }

  if (!isPayment && nextIsPayment) {
    /**
     * Fix bug in case, if the user go to the PP and become paid.
     * Handler for 'unload' event need for prevent move page in bfcache!
     *
     * @see https://web.dev/bfcache
     */
    window.addEventListener('unload', handleUnload);
    // Some browsers not call 'unload', that's why need 'beforeunload' handler.
    window.addEventListener('beforeunload', handleUnload);
  }

  if (history.location.forwardTo) {
    // remove custom forward setting when pushing next page
    skipNextChange();
    originalReplace(omit(history.location, ['forwardTo']));
  }

  /**
   * Stub for experiment with fast payment page, will be removed after experiment
   */
  if (Object.values(FAST_PP_ROUTES).includes(nextLocation.pathname)) {
    const via = new URLSearchParams(nextLocation.search).get('via');

    /**
     * For 'Fast PP' we exclude list of via with one pre-checked package,
     * because we have more than one package
     * @responsible Mark Malinovskiy
     */
    if ([REMARKETING_POPUP_VIA, PAID_FUNNEL_VIA].includes(via)) {
      window.location.href = `${ROUTES.PAY}/${PAYMENT_ACTIONS.MEMBERSHIP}${
        nextLocation.search
      }`;
      return null;
    }

    window.location.href = nextLocation.pathname + nextLocation.search;
    return null;
  }

  return location;
};

type AfterChangeOptions = {
  history: CustomHistory;
  originalListen: CustomHistory['listen'];
  originalReplace: CustomHistory['replace'];
  skipNextChange: () => void;
  notifyListeners: () => void;
  fromLocation: CustomLocation;
};

/**
 * Calls after browser location change, but before processing this change by react router.
 * Is used to skip some changes, do additional navigations and side effects.
 */
const afterChange = async ({
  history,
  originalListen,
  originalReplace,
  skipNextChange,
  notifyListeners,
  fromLocation,
}: AfterChangeOptions): Promise<void> => {
  const {index: fromIndex, backTo, forwardTo, pathname} = fromLocation;
  const currentIndex = history.location.index;
  const delta = currentIndex - fromIndex;
  const isBack = delta < 0;
  const isForward = delta > 0 && history.action === Action.POP;

  /**
   * 'backTo' means an entry index we want back to instead of going to one step back.
   * We set it only for entry we are pushing after payment {@see beforePush}.
   * 'forwardTo' means an entry index we want forward to instead of going to one step forward.
   * We set it only after custom back with 'backTo' {@see moveForwardInfo} and remove it when pushing next entry.
   * Example of history we have when returning from payment
   * when return page differs from page we went to payment from:
   *   search(index: 1), pay1(index: 2), pay2(index: 3), chat/with/id(index: 4, backTo: 1)
   * and after back it will be:
   *   search(index: 1, forwardTo: 4), pay1(index: 2), pay2(index: 3), chat/with/id(index: 4, backTo: 1)
   * So we can skip payment steps on back/forward navigation:
   */
  if (isBack && backTo && currentIndex > backTo) {
    storage.forwardInfo = {forwardTo: fromIndex, index: backTo};
    skipNextChange();
    await waitForChange(originalListen, () =>
      history.go(backTo - currentIndex),
    );
    skipNextChange();
    moveForwardInfo({history, originalReplace});
    // do not return to notify listeners
  } else if (isForward && forwardTo && currentIndex < forwardTo) {
    history.go(forwardTo - currentIndex);
    // popstate after go will notify listeners
    return;
  }

  const fromPayment = isPaymentUrl(pathname);
  const toPayment = isPaymentUrl(history.location.pathname);
  if (!fromPayment && toPayment) {
    if (isBack && !backTo) {
      /**
       * We are going to payment page using back button without "Skip payment pages" logic above.
       * Redirect to index in this case.
       */
      history.replace('/');
      return;
    }

    if (!isBack) {
      storage.backInfo = {pathname, index: fromIndex};
    }

    trackRedirectTo({
      nextLocation: history.location,
      currentPathname: pathname,
    });

    /**
     * Reload when going to payment page.
     * It's important for PCI DSS and some tracking codes.
     */
    window.location.reload();

    return;
  }

  /**
   * Reload page when user has come from PP in inApp browsers
   * (site content can be cached with requests that were canceled when user goes to pp with reload page without
   * waiting for full page load)
   */
  if (
    getBootstrapParam(IN_APP_NAME) !== InAppBrowserName.NORMAL_BROWSER &&
    fromPayment &&
    !toPayment
  ) {
    trackRedirectTo({
      nextLocation: history.location,
      currentPathname: pathname,
    });
    window.location.reload();

    return;
  }

  notifyListeners();
};

/**
 * Microsoft Edge breaks previous history entry when removing
 * iframe with navigations inside (like when pay with 3d secure):
 * the entry has it's original state, but url of next page.
 * So we save original url into state for each push and replace
 * and compare it with current location's url. And then fix
 * broken entry's url if we have a difference.
 */
const fixEdgeUrl = (history: CustomHistory, skipNextChange?: () => void) => {
  // originalUrl from history.state. It was saved on push/replace.
  const {originalUrl} = history.location;

  // save originalUrl in case when it's normal navigation:
  if (!originalUrl) {
    history.replace({
      ...history.location,
      originalUrl: createPath(history.location),
    });
    return;
  }

  if (history.action !== Action.POP) {
    return;
  }

  const currentUrl = createPath(history.location);
  if (originalUrl !== currentUrl) {
    skipNextChange?.();
    history.replace(originalUrl, history.location.state);
    history.action = Action.POP;

    logger.sendInfo(
      `[historyMiddleware] Edge url fixed "${currentUrl}" -> "${originalUrl}".`,
    );
  }
};

/**
 * Replaces some history methods to change behaviour in some cases:
 *   - Skip payment entries of history while going back/forward.
 *   - Change on-site back button behaviour when we are going to
 *     payment page, or we have no ability to go back.
 */
const applyMiddleware = (history: CustomHistory) => {
  const customLocationProps = ['backTo', 'forwardTo', 'originalUrl'];

  // WARNING: it changes history by reference
  applyHistoryIndexer(history, customLocationProps);

  const {
    push: originalPush,
    replace: originalReplace,
    listen: originalListen,
    go: originalGo,
  } = history;

  // Some actions after reload:
  fixEdgeUrl(history);
  moveForwardInfo({history, originalReplace}, true);
  if (!isPaymentUrl(history.location.pathname) && storage.backInfo) {
    storage.backInfo = null;
  }

  /**
   * Is used to ignore some events caused by internal changes.
   */
  let skipCounter = 0;
  const skipNextChange = () => {
    skipCounter++;
  };

  const buildLocation = (path: CustomPath, state?: unknown) =>
    createLocation(path, state, null, history.location);

  /**
   * Is used to keep custom location props on external replace and
   * restore method compatibility after applyHistoryIndexer changes.
   */
  history.replace = (path: CustomPath, state?: unknown) => {
    const location = buildLocation(path, state);
    // skip replace to equal location to avoid useless re-render
    if (!isEqualLocations(location, history.location)) {
      originalReplace({
        ...location,
        ...pick(history.location, customLocationProps),
        /**
         * @see fixEdgeUrl for this 'originalUrl' usage
         */
        originalUrl: createPath(location),
      });
    }
  };

  let pushAllowed = true;

  let handleDocumentClick = () => {};
  /**
   * When users have device with bad performance and do several clicks in a row on different links,
   * they get a bug with the rejecting of redirection because of the check that we have in the wrapper of history.push.
   * This listener allows push in history after click.
   */
  document.addEventListener('click', () => {
    handleDocumentClick();
  });

  /**
   * Listeners to be called before history push.
   */
  const beforePushListeners: BeforePushListener[] = [];

  /**
   * Custom `history.push` method for ability to cancel navigation.
   */
  history.push = async (path: CustomPath, state?: unknown) => {
    if (!path) return;

    if (!pushAllowed) {
      /**
       * If you see this error it means we are trying to do next push while handling previous.
       * It can be multiple listeners on the same element or its parent that both do history.push().
       * Do not track to sentry, because:
       *   1. We are automatically fix the unwanted behaviour in most cases.
       *   2. It's difficult to reproduce such bug (sentry logs does not help a lot).
       */
      // eslint-disable-next-line no-console
      console.warn(
        `[historyMiddleware] history.push('${
          typeof path === 'string' ? path : path.pathname
        }') has been rejected due to negative impact on browser back/forward navigation.`,
      );
      // Prevent multiple history.push due to negative impact on some browsers back/forward behavior.
      return;
    }

    pushAllowed = false;
    try {
      const location = await beforePush({
        history,
        originalReplace,
        nextLocation: buildLocation(path, state),
        skipNextChange,
        beforePushListeners,
      });
      if (location) {
        /**
         * @see fixEdgeUrl for this 'originalUrl' usage
         */
        location.originalUrl = createPath(location);
        originalPush(location);
      }
    } finally {
      // Do not catch to avoid problems with silent errors debugging.

      /**
       * If we have a bug with two event listeners added like
       * element.addEventListener('click', () => history.push('...'));
       * and immediately-resolving beforePush, the first callback
       * will be resolved earlier than second will be called.
       * setTimeout is used to avoid duplicates in this case too.
       */
      await new Promise<void>((resolve) => {
        handleDocumentClick = () => {
          resolve();
        };
        setTimeout(() => {
          resolve();
        }, 0);
      });
      pushAllowed = true;
    }
  };

  /**
   * Listeners subscribed to location change.
   */
  const listeners: HistoryListener[] = [];

  let fromLocation = history.location;
  /**
   * Notify listeners about location change.
   */
  const notifyListeners = () => {
    fromLocation = history.location;
    listeners.forEach((listener) => listener(history.location, history.action));
  };

  /**
   * Custom method for ability to skip handling of some url changes.
   * Returns unsubscribe function.
   */
  history.listen = (listener) => {
    listeners.push(listener);
    return () => {
      const pos = listeners.indexOf(listener);
      if (pos >= 0) {
        listeners.splice(pos, 1);
      }
    };
  };

  originalListen(() => {
    if (skipCounter > 0) {
      skipCounter--;
      return;
    }

    fixEdgeUrl(history, skipNextChange);

    afterChange({
      history,
      originalListen,
      originalReplace,
      skipNextChange,
      fromLocation,
      notifyListeners,
    });
  });

  /**
   * Custom method for ability to change on-site back button behaviour.
   */
  history.go = (n) => {
    // `history.location.safeBack` added by {@see applyHistoryIndexer}.
    if (n === -1 && history.location.safeBack === false) {
      history.replace('/');
    } else {
      originalGo(n);
    }
  };

  history.goBack = () => history.go(-1);

  /**
   * Allows to add event listener before `history.push(...)` actually change url.
   */
  history.beforePush = (listener) => {
    beforePushListeners.push(listener);
  };
};

export default applyMiddleware;
