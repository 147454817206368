import type {ComponentType} from 'react';

import type {PaymentProcessStep} from '../../types/paymentProcess';
import ThreeDSecureProcessor from '../../utils/ThreeDSecureProcessor';
import type ThreeDSecureAbortPopupProps from '../../../widgets/threeDSecure/types/threeDSecureAbortPopupProps';

type ProcessThreeDSecureAnswerProps = {
  PaymentPendingPopupLayout: ComponentType;
  ThreeDSecureAbortPopup: ComponentType<ThreeDSecureAbortPopupProps>;
};

const baseProcessThreeDSecureAnswer =
  ({
    PaymentPendingPopupLayout,
    ThreeDSecureAbortPopup,
  }: ProcessThreeDSecureAnswerProps): PaymentProcessStep =>
  async (data) => {
    const {paymentResult, paymentData} = data;
    const {acsUrl, orderId, iframeUrl} = paymentResult;
    const {via} = paymentData;

    if (acsUrl) {
      const processor = new ThreeDSecureProcessor(
        via,
        orderId,
        PaymentPendingPopupLayout,
        ThreeDSecureAbortPopup,
      ).openThreeDSecurePopup(iframeUrl);

      const result = await processor.getPromise();

      if (typeof result === 'string') {
        return {
          ...data,
          flowStatus: result,
        };
      }

      const {via: resultVia, ...rest} = result;
      return {
        ...data,
        paymentData: {
          ...data.paymentData,
          via: resultVia,
        },
        paymentResult: {
          ...data.paymentResult,
          ...rest,
        },
      };
    }

    return data;
  };

export default baseProcessThreeDSecureAnswer;
