/**
 * Payment long form fields testing cookie
 * @example
 *         localStorage.setItem('longFormFields' JSON.stringify({anonymousAutoFillFields: [
 *             'address-level1',
 *             'address-level2',
 *             'country',
 *           ], longFormFields: [
 *             {
 *               isRequired: true,
 *               longFormField: 'name',
 *             },
 *             {
 *               isRequired: false,
 *               longFormField: 'postal_code',
 *             },
 *           ]});
 */
export const LONG_FORM_FIELDS = 'longFormFields';

export const IS_FAST_PAYMENT_PAGE = 'isFastPaymentPage';

// React payment emulate disable interaction for 3ds flow
export const DISABLE_USER_PAYMENT_PROCESSED_INTERACTION =
  'disabledUserPaymentProcessedInteraction';

export const LAST_VIEWED_VIDEO_ID = 'lastViewedVideoId';

export const LAST_FULLY_VIEWED_VIDEO_ID = 'lastFullyViewedVideoId';

export const IS_ALLOWED_ANONYMOUS_CHECKOUT = 'isAllowedAnonymousCheckout';

export const SHOW_REDIRECT_URL = 'showRedirectUrl';
