import debounce from 'lodash/debounce';

import RESIZE_DELAY from '@core/application/constants/resizeDelay';
import getBoundingClientRect from '@core/application/utils/getBoundingClientRect';

import type {CreatePopper} from '../types';
import setStyles from './setStyles';
import getRelativeParent from './getRelativeParent';
import getAvailablePlacement from './getAvailablePlacement';
import getAvailableSize from './getAvailableSize';
import computeFloatingPosition from './computeFloatingPosition';

/**
 * Monitors elements position to place `floatingEl` and `arrowEl` next to the `referenceEl`.
 */
const createPopper: CreatePopper = (
  referenceEl,
  floatingEl,
  arrowEl,
  {placement, positionFixed, onUpdate},
) => {
  let isTicking = false;

  const updatePosition = () => {
    const availableSize = getAvailableSize(referenceEl, positionFixed);
    const floatingRect = getBoundingClientRect(floatingEl);
    const referenceRect = getBoundingClientRect(referenceEl);
    const arrowRect = arrowEl ? getBoundingClientRect(arrowEl) : null;
    const relativeParentRect = positionFixed
      ? null
      : getBoundingClientRect(getRelativeParent(referenceEl));

    const {canEnterOnCenter, placement: availablePlacement} =
      getAvailablePlacement({
        floatingRect,
        referenceRect,
        availableSize,
        placement,
      });
    const position = computeFloatingPosition({
      floatingRect,
      referenceRect,
      arrowRect,
      relativeParentRect,
      placement: availablePlacement,
      positionFixed,
      canEnterOnCenter,
      availableSize,
    });

    onUpdate(availablePlacement);
    setStyles({floatingEl, arrowEl, position});
  };

  const updatePositionThrottled = () => {
    if (!isTicking) {
      window.requestAnimationFrame(() => {
        updatePosition();
        isTicking = false;
      });

      isTicking = true;
    }
  };

  updatePosition();

  // To update position after CSS variables `--appWidth` and `--appHeight` change.
  const updatePositionDebounced = debounce(updatePosition, RESIZE_DELAY);

  const onResize = () => {
    updatePositionThrottled();
    updatePositionDebounced();
  };

  window.addEventListener('scroll', updatePositionThrottled, {passive: true});
  window.addEventListener('resize', onResize, {passive: true});

  return {
    update: updatePosition,
    destroy: () => {
      window.removeEventListener('scroll', updatePositionThrottled);
      window.removeEventListener('resize', onResize);
    },
  };
};

export default createPopper;
