export default {
  MotivationBanner: {
    fields: {
      /**
       * Yes MotivationBanner has "enable" flag - but some banners have additional states
       * for example "Success info" and we must show banner with this state but "enabled" flag already === false.
       * For these cases we add client field status.
       *
       * @see motivationBannerStatus.js
       */
      status: (status = '') => status,
    },
  },
};
