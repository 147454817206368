import type {DocumentNode} from 'graphql';

import {getClientInstance} from '@core/graphql/client';

import DELETE_PHOTO_MUTATION from '../graphql/mutations/deletePhoto.gql';
import type {
  DeletePhotoMutation,
  DeletePhotoMutationVariables,
} from '../graphql/mutations/deletePhoto';
import {removePhotoFromCache} from './index';

type Query = {
  query: DocumentNode;
};

const deletePhoto = (photoId: string, refetchQueries: Query[] = []) => {
  return getClientInstance().mutate<
    DeletePhotoMutation,
    DeletePhotoMutationVariables
  >({
    mutation: DELETE_PHOTO_MUTATION,
    variables: {photoId},
    refetchQueries,
    update: (cache, response) => {
      const {success, pendingDelete} =
        response.data.user.myUser.photo.deletePhoto.result;

      if (success) {
        removePhotoFromCache({id: photoId, pendingDelete});
      }
    },
  });
};

export default deletePhoto;
