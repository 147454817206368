import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';

import {PROTECT_TYPES} from '@core/massBlocked/constants/protectTypes';
import componentPropType from '@core/propTypes/component';
import getCookie from '@core/utils/cookie/getCookie';

import CAPTCHA_QUERY from '../graphql/queries/captcha.gql';

/**
 * This is a test key from google. If you use it, then the verification always passes.
 * https://developers.google.com/recaptcha/docs/faq
 * We add this one so that we can test the functional on the stage without outputting to live.
 * We cannot test without it on the stage because the domains on the stage contain the word phoenix and
 * we should not show the relation between phoenix and all of our sites.
 */
const MOCK_TEST_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

const Captcha = ({
  onChange,
  protectType = PROTECT_TYPES.ON_PAGE_PROTECT_TYPE,
  error,
  layout: Layout,
}) => {
  const recaptchaRef = useRef();

  useEffect(() => {
    // Need reset captcha if we have error
    if (error) {
      recaptchaRef.current && recaptchaRef.current.reset();
    }
  }, [error]);

  const {
    data: captchaData,
    loading: captchaLoading,
    error: captchaError,
  } = useQuery(CAPTCHA_QUERY, {
    variables: {
      protectType,
    },
  });

  if (captchaLoading || captchaError) {
    return null;
  }

  const {
    site: {
      captcha: {publicKey, reason},
    },
  } = captchaData;

  return (
    <Layout
      reason={reason}
      error={error}
      ref={recaptchaRef}
      publicKey={getCookie('setTestRecaptchaKey') ? MOCK_TEST_KEY : publicKey}
      onChange={onChange}
    />
  );
};

Captcha.propTypes /* remove-proptypes */ = {
  onChange: PropTypes.func,
  protectType: PropTypes.oneOf(Object.values(PROTECT_TYPES)),
  error: PropTypes.string,
  layout: componentPropType.isRequired,
};

export default Captcha;
