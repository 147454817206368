import type {FC, HTMLAttributes} from 'react';
import React, {useEffect, useState} from 'react';
import cn from 'classnames';

import type {FormikFieldError} from '@core/types/formik';

import type {CSSModule} from '../../types';
import baseCss from './Label.css';

export interface LabelProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  flexibleWidth?: boolean;
  large?: boolean;
  inverse?: boolean;
  error?: FormikFieldError;
  focused?: boolean;
  hasValue?: boolean;
  withLeftIcon?: boolean;
  withRightIcon?: boolean;
  animated?: boolean;
  inline?: boolean;
  small?: boolean;
  attachedToField?: boolean;
}

const DEFAULT_CSS: CSSModule = {};

const Label: FC<
  // `LabelProps` without `css` inside to make it more suitable for `@phoenix/ui`.
  LabelProps & {css?: CSSModule}
> = ({
  css = DEFAULT_CSS,
  className,
  inverse,
  inline,
  small = false,
  focused,
  error,
  animated = false,
  hasValue = false,
  withLeftIcon = false,
  withRightIcon = false,
  flexibleWidth = false,
  attachedToField,
  large,
  ...props
}) => {
  /* Flag is used to prevent blur animation on mount of Label component */
  const [touched, setTouched] = useState(focused);

  useEffect(() => {
    if (!touched && focused) {
      setTouched(true);
    }
  }, [focused, touched]);

  return (
    <div
      className={cn(
        baseCss.label,
        css.label,
        inverse && baseCss.inverse,
        inline && baseCss.inline,
        inline && css.inline,
        small && !animated && baseCss.small, // prevent apply small when animated is true
        small && !animated && css.small,
        flexibleWidth && baseCss.flexibleWidth,
        error && baseCss.error,
        error && css.error,
        focused && baseCss.focused,
        focused && css.focused,
        hasValue && baseCss.hasValue,
        hasValue && css.hasValue,
        animated && baseCss.animated,
        animated && css.animated,
        touched && css.touched,
        large && css.large,
        /**
         * if label has icon need move text on icon width
         * needed for correctly animation display
         */
        withLeftIcon && [css.withLeftIcon, baseCss.withLeftIcon],
        withRightIcon && [css.withRightIcon, baseCss.withRightIcon],
        attachedToField && css.attachedToField,
        className,
      )}
      {...props}
    />
  );
};

export default Label;
