import getConvertVideoSubscription from '@core/user/profile/current/utils/getConvertVideoSubscription';

/**
 * Waits for video convert interaction and updates video isConverted/error data as promise.
 *
 * @param {Object} video - Video data
 * @returns {Promise}
 */
export default function waitVideoConvert(video) {
  if (video.isConverted) {
    return Promise.resolve(video);
  }
  return new Promise((resolve, reject) => {
    const subscription = getConvertVideoSubscription().subscribe(
      ({
        data: {
          video: {id, duplicateVideoId, url, previewUrl, isConverted, error},
        },
      }) => {
        // When uploading from IPad the same video after its deletion,
        // video changes its video.id to previously deleted one.
        // Original uploaded video id is preserved by duplicateVideoId.
        // Server does not recognize duplicate video on upload
        // but recognizes duplicate after convert.
        if (video.id !== id && video.id !== duplicateVideoId) {
          return;
        }

        subscription.unsubscribe();

        if (error) {
          reject(new Error(error));
          return;
        }
        resolve({...video, id, isConverted, url, previewUrl});
      },
    );
  });
}
