import getCurrentUserId from '@core/user/profile/current/utils/getCurrentUserId';

const ROOM_UNBAN = 'roomUnBan';
const ROOM_BAN = 'roomBan';
const MARK_AS_SCAMMER = 'markAsScammer';
const DELETE_MESSAGE = 'roomMessageDelete';

const BAN_REASON = 'quick ban';

const TYPE_NAMES = {
  MODERATOR: 'Moderator',
};

/**
 * Send to server request to unban user
 */
export default {
  UnbanUserMutation: {
    toServer: ({id, userId}) => ({method: ROOM_UNBAN, params: {id, userId}}),

    fromServer(response, error) {
      const isSuccess = !error && response.status === 'success';

      return {
        data: {
          moderator: {
            unbanUser: {
              result: isSuccess,
              errors: {
                general: isSuccess ? null : response || error || null,
                arguments: null,
                __typename: 'MutationPayloadErrors',
              },
              __typename: 'ModeratorUnbanUser',
            },
            __typename: TYPE_NAMES.MODERATOR,
          },
        },
      };
    },
  },

  BanUserMutation: {
    async toServer({period, userId}) {
      const currentUserId = await getCurrentUserId();

      return {
        method: ROOM_BAN,
        params: {
          period,
          userId,
          moderatorId: currentUserId,
          reason: BAN_REASON,
        },
      };
    },

    fromServer(response, error) {
      const isSuccess = !error && !response.error;

      return {
        data: {
          moderator: {
            banUser: {
              result: isSuccess,
              errors: {
                general: isSuccess ? null : response || error || null,
                arguments: null,
                __typename: 'MutationPayloadErrors',
              },
              __typename: 'ModeratorBanUser',
            },
            __typename: TYPE_NAMES.MODERATOR,
          },
        },
      };
    },
  },

  MarkAsScammerMutation: {
    async toServer({userId}) {
      const currentUserId = await getCurrentUserId();

      return {
        method: MARK_AS_SCAMMER,
        params: {
          userId,
          moderatorId: currentUserId,
        },
      };
    },

    fromServer(response, error) {
      const isSuccess = !error && response.status === 'success';

      return {
        data: {
          moderator: {
            markScammer: {
              result: isSuccess,
              errors: {
                general: isSuccess ? null : response || error,
                arguments: null,
                __typename: 'MutationPayloadErrors',
              },
              __typename: 'ModeratorMarkScammer',
            },
            __typename: TYPE_NAMES.MODERATOR,
          },
        },
      };
    },
  },

  DeleteRoomMessageMutation: {
    toServer({fromUserId, messageId, roomId}) {
      return {
        method: DELETE_MESSAGE,
        params: {
          fromUserId,
          messageId,
          roomId,
        },
      };
    },

    fromServer(response, error) {
      /**
       * By some reason server always return result as "false"
       */

      return {
        data: {
          moderator: {
            deleteRoomMessage: {
              result: response,
              errors: {
                general: error,
                arguments: null,
                __typename: 'MutationPayloadErrors',
              },
              __typename: 'ModeratorDeleteRoomMessage',
            },
            __typename: TYPE_NAMES.MODERATOR,
          },
        },
      };
    },
  },
};
