import {useQuery} from '@apollo/client';

import useAdditionalTerms from '@core/payment/widgets/billingPolicy/utils/useAdditionalTerms';

import usePaymentParams from '../../../common/utils/usePaymentParams';
import PAYMENT_ADDITIONAL_PACKAGE_QUERY from '../graphql/queries/paymentAdditionalPackage.gql';

const usePaymentAdditionalPackage = () => {
  const {action, via, prevVia, source} = usePaymentParams();
  const {
    isAdditionalTermsNeeded,
    autoRenewalAgreeXSale,
    loading: additionalTermsLoading,
    error: additionalTermsError,
  } = useAdditionalTerms();

  const {
    data: additionalPackageData,
    loading: additionalPackageLoading,
    error: additionalPackageError,
  } = useQuery(PAYMENT_ADDITIONAL_PACKAGE_QUERY, {
    variables: {action, via, prevVia, source},
  });

  const loading = additionalPackageLoading || additionalTermsLoading;
  const error = additionalPackageError || additionalTermsError;

  if (loading || error || !additionalPackageData.payment.paymentFooter) {
    return {
      additionalPackages: [],
      isAdditionalPackageFirst: false,
      showAdditionalPackage: false,
      loading,
    };
  }

  const {
    payment: {
      paymentFooter: {blockDataMap: {additionalPackages} = {}},
    },
  } = additionalPackageData;

  return {
    additionalPackages,
    isAdditionalPackageFirst: isAdditionalTermsNeeded && autoRenewalAgreeXSale,
    showAdditionalPackage: Boolean(additionalPackages?.length),
    loading,
  };
};

export default usePaymentAdditionalPackage;
