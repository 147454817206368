import {Event, LoadProgress} from '../constants/babciaDataTypes';
import sendUBEventToBabcia from './sendUBEventToBabcia';

const trackPageLoadCompletion = (components: string[] = []): void =>
  sendUBEventToBabcia({
    event: Event.LOAD,
    value: LoadProgress.COMPLETE,
    debugInfo: components.join(', '),
  });

export default trackPageLoadCompletion;
