import type {FC, ReactNode} from 'react';
import React from 'react';
import {ApolloProvider} from '@apollo/client';

import {getClientInstance} from '../client';

/**
 * GraphQL provider with additional error handling, that can be
 * set inside '@apollo/client/link/error'
 * @see client.ts
 */
const GraphQLProvider: FC<{children: ReactNode}> = (props) => {
  return <ApolloProvider {...props} client={getClientInstance()} />;
};

export default GraphQLProvider;
