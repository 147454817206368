import {mergeArrayFieldUniq as merge} from '../utils/mergeArrayField';

export default {
  MicroFeatures: {
    // make this type accessible for invalidation.
    keyFields: [],
    fields: {
      microFeatureList: {
        merge,
      },
    },
  },
  MicroFeatureList: {
    keyFields: ['featureName'],
  },
};
