import PropTypes from 'prop-types';

import useActivePackage from '../../package/utils/useActivePackage';
import usePaymentPackageDescriptionRule from '../utils/usePaymentPackageDescriptionRule';
import setParamsToDescriptionText from '../utils/setParamsToDescriptionText';

/**
 * Component for render active package description text
 * @param children - render-prop
 * @param {Boolean} repeatPeriodInWeeks
 */
const PaymentPackageDescription = ({children, repeatPeriodInWeeks}) => {
  const {activePackage} = useActivePackage();

  const {loading, strictLegalMode, packageDescriptionRule} =
    usePaymentPackageDescriptionRule();

  if (loading || !packageDescriptionRule) {
    return null;
  }

  const {packagePricingDetails, repeatPackage, interval, quantity} =
    activePackage;

  const packageDescriptionsText = packageDescriptionRule.translateMessages.map(
    (translateMessage) => {
      return setParamsToDescriptionText({
        translateMessage,
        packagePricingDetails,
        repeatPackage,
        interval: interval || 0,
        repeatPeriodInWeeks,
        quantity,
      });
    },
  );

  return children({strictLegalMode, packageDescriptionsText});
};

PaymentPackageDescription.propTypes /* remove-proptypes */ = {
  children: PropTypes.func.isRequired,
  repeatPeriodInWeeks: PropTypes.bool,
};

export default PaymentPackageDescription;
