enum MicrofeaturesBannerAppearance {
  /**
   * Banner with shadow and spacing from each other
   */
  IN_CARD_WITHOUT_BORDER = 'inCardWithoutBorder',

  /**
   * Banner without shadow and border
   */
  FLAT_WITHOUT_BORDER = 'flatWithoutBorder',

  /**
   * Banner without shadow and border, icon is not round
   */
  FLAT_WITHOUT_BORDER_AND_NOT_ROUND_ICON = 'flatWithoutBorderAndNotRoundIcon',

  /**
   * Full width banner
   */
  FULL_SIZE_BANNER = 'fullSizeBanner',

  /**
   * Default modification
   */
  WITH_ROUND_ICON = 'withRoundIcon',
}

export default MicrofeaturesBannerAppearance;
