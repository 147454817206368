import {getClientInstance} from '@core/graphql/client';

import {updatePrimaryPhotoInCache} from './index';
import PRIMARY_PHOTO_MUTATION from '../graphql/mutations/primaryPhoto.gql';
import type {
  PrimaryPhotoMutation,
  PrimaryPhotoMutationVariables,
} from '../graphql/mutations/primaryPhoto';

const setPrimaryPhoto = (photoId: string) => {
  getClientInstance().mutate<
    PrimaryPhotoMutation,
    PrimaryPhotoMutationVariables
  >({
    mutation: PRIMARY_PHOTO_MUTATION,
    variables: {
      photoId,
    },
    update: (cache, response) => {
      const {result} = response.data.user.myUser.photo.changePrimary;
      if (!result) return;
      updatePrimaryPhotoInCache({id: photoId});
    },
    optimisticResponse: () => ({
      user: {
        myUser: {
          photo: {
            changePrimary: {
              result: true,
              __typename: 'BooleanMutationResult',
            },
            __typename: 'MyUserPhotoType',
          },
          __typename: 'MyUserMutation',
        },
        __typename: 'UserMutation',
      },
    }),
  });
};

export default setPrimaryPhoto;
