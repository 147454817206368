import isUndefined from 'lodash/isUndefined';

import type {
  URIObject,
  URIFromWindowObject,
  SearchStringOperationParam,
  SearchStringOperationResult,
} from './types/uri';
import {SearchParamsMethods} from './types/uri';
import {PROTOCOL_SEPARATOR, FALLBACK_URL} from './constants/uri';
import getUri from './utils/getUri';
import invokeSearchParamsMethod from './utils/invokeSearchParamsMethod';
import parseOrigin from './utils/parseOrigin';
import operateSearchString from './utils/operateSearchString';

const URI = (
  uri?: string | object | undefined | URIFromWindowObject,
): URIObject => {
  const uriObject: URIObject = {} as URIObject;

  /**
   * Need only for correct work of URL class
   * We use FallBack URL in SSR
   */
  const defaultUrl =
    typeof window !== 'undefined'
      ? window?.location?.origin || FALLBACK_URL
      : FALLBACK_URL;

  let inputURI;
  let withoutUri;
  let url;

  const setURI = (
    newUri?: string | object | undefined | URIFromWindowObject,
  ) => {
    // Need for parse url from window object or string
    inputURI = getUri(newUri);

    /**
     * By default, when we don't have any path in URI we use current page URL
     */
    withoutUri = isUndefined(newUri);

    /**
     * Create instance of URL class
     * We operate only with inputURI
     * defaultUrl need for fallback when we don't have full url
     */
    url = new URL(inputURI, defaultUrl);
  };

  /**
   * Native URL class add / on start of all path's
   * We need to remove it if it's not needed
   * @param {String} str - url string
   */
  const handleSlash = (str: string): string => {
    return inputURI?.startsWith?.('/') || withoutUri ? str : str.slice(1);
  };

  /**
   * Check if search param exists
   * @param {String} key - key of search param
   * @returns {Boolean}
   * @example
   * const uri = URI('http://example.org:8080/foo/?query=1#hash');
   * // check if search exists
   * uri.hasSearch('query'); // returns true
   */
  const hasSearch = (key: string): boolean => url.searchParams.has(key);

  /**
   * Set search params
   * @param {Any} name
   * @param {Any} param
   * @returns {Object} - updated URI object
   * @example
   * const uri = URI('http://example.org:8080/foo/?query=1#hash');
   * // set search
   * uri.setSearch('test', '2'); // returns updated URI object
   * uri.setSearch('test', '3'); // returns updated URI object
   * uri.setSearch({bar: '3'}); // returns updated URI object
   * uri.setSearch('foo', ['4', '5']); // returns updated URI object
   * uri.toString(); // returns string "http://example.org:8080/foo/?query=1&test=3&bar=3&foo=4&foo=5#hash"
   */
  const setSearch = (
    name: string | Record<string, any>,
    param?: string,
  ): URIObject => {
    invokeSearchParamsMethod(SearchParamsMethods.SET, url, name, param);
    return uriObject;
  };

  /**
   * Add search params
   * @param {Any} name
   * @param {Any} param
   * @returns {Object} - updated URI object
   * @example
   * const uri = URI('http://example.org:8080/foo/?query=1#hash');
   * // add search
   * uri.addSearch('test', '2'); // returns updated URI object
   * uri.addSearch('test', '3'); // returns updated URI object
   * uri.addSearch({bar: '3'}); // returns updated URI object
   * uri.addSearch('foo', ['4', '5']); // returns updated URI object
   * uri.toString(); // returns string "http://example.org:8080/foo/?query=1&test=2&test=3&bar=3&foo=4&foo=5#hash"
   */
  const addSearch = (
    name: string | Record<string, any>,
    param?: string,
  ): URIObject => {
    invokeSearchParamsMethod(SearchParamsMethods.APPEND, url, name, param);
    return uriObject;
  };

  /**
   * Remove search param
   * @param {String} name - key of search param
   * @returns {Object} - updated URI object
   * @example
   * const uri = URI('http://example.org:8080/foo/?query=1#hash');
   * // remove search
   * uri.removeSearch('query'); // returns updated URI object
   * uri.removeSearch('nonexistent'); // returns updated URI object
   * uri.toString(); // returns string "http://example.org:8080/foo/#hash"
   */
  const removeSearch = (name: string): URIObject => {
    url.searchParams.delete(name);
    return uriObject;
  };

  /**
   * Get search params from url
   * Same as search method but without ? at start
   * @param {Any} param
   * @returns {String | Object}
   * @example
   * const uri = URI('http://example.org:8080/foo/?query=1#hash');
   * // get search
   * uri.query(); // returns string 'query=1'
   * uri.query(true); // returns object {query: '1'}
   * uri.query({newQuery: '2'}); // returns updated URI object
   * uri.query(); // returns string 'newQuery=2'
   */
  const query = <T = SearchStringOperationResult>(
    param?: SearchStringOperationParam,
  ): T => {
    return operateSearchString(param, url, uriObject) as T;
  };

  /**
   * Return hostname with port
   * Host + Port
   * @example
   * const uri = URI('http://example.org:8080/foo/hello.html');
   * // get host
   * uri.host(); // returns string "example.org:8080"
   */
  const host = (): string => url.host;

  /**
   * Return hostname without port
   * Host
   * @example
   * const uri = URI('http://example.org:8080/foo/hello.html');
   * // get hostname
   * uri.hostname(); // returns string 'example.org'
   */
  const hostname = (): string => url.hostname;

  /**
   * Return full url (href)
   * @returns {String}
   * @example
   * const uri = URI('http://example.org:8080/foo/hello.html');
   * // get full url
   * uri.toString(); // returns string 'http://example.org:8080/foo/hello.html'
   */
  const toString = (): string => {
    // Get updatedUrl from URL class
    const updatedUrl = url.toString();

    /**
     * In case when we have only path URI class add default url at start
     * Wee need to remove it
     */
    if (!withoutUri && !inputURI.includes(url.hostname)) {
      const replaced = updatedUrl.replace(defaultUrl, '');
      return handleSlash(replaced);
    }

    return updatedUrl;
  };

  /**
   * Set origin of url
   * @param {String} newOrigin - new origin to set
   * @returns {Object} - updated URI object
   * @example
   * const uri = URI('http://example.org:8080/foo/hello.html');
   * // set origin
   * uri.setOrigin('https://newexample.org:9090'); // returns updated URI object
   * uri.toString(); // returns string 'https://newexample.org:9090/foo/hello.html'
   */
  const setOrigin = (newOrigin: string): URIObject => {
    setURI(newOrigin + uriObject.resource());
    return uriObject;
  };

  /**
   * Return origin of url
   * Protocol + Host + Port
   * @returns {String}
   * @example
   * const uri = URI('http://example.org:8080/foo/hello.html');
   * // get origin
   * uri.origin(); // returns string 'http://example.org:8080'
   */
  const origin = (newOrigin?: string): string | URIObject =>
    // eslint-disable-next-line no-undefined
    newOrigin !== undefined ? setOrigin(newOrigin) : parseOrigin(inputURI);

  /**
   * Return path of url
   * @returns {String}
   * @example
   * const uri = URI('http://example.org:8080/foo/hello');
   * // get path
   * uri.path(); // returns string '/foo/hello'
   * URI('path').path(); // returns string 'path'
   * URI('/path/id').path(); // returns string '/path/id'
   */
  const path = (): string => {
    // If we have full url we need return path with "/" at start
    if (origin()) {
      return url.pathname;
    }

    // In other cases we remove "/" from start if path not start with "/"
    return handleSlash(url.pathname);
  };

  /**
   * Return resource of url
   * Path + Search + Hash
   * @example
   * const uri = URI('http://example.org:8080/foo/?query=1#hash');
   * // get resource
   * uri.resource(); // returns string "/foo/?query=1#hash"
   */
  const resource = (): string => {
    return `${path()}${url.search}${url.hash}`;
  };

  /**
   * Return port of url
   * @returns {String}
   * @example
   * const uri = URI('http://example.org:8080/foo/hello.html');
   * // get port
   * uri.port(); // returns string '8080'
   */
  const port = (): string => url.port;

  /**
   * Return protocol of url
   * @example
   * const uri = URI('http://example.org/foo/hello.html');
   * // get protocol
   * uri.scheme(); // returns string "http"
   */
  const scheme = (): string => {
    return url.protocol.replace(':', '');
  };

  /**
   * Return username from url
   * @example
   * const url = URI('http://user:pass@example.org/foo/hello.html');
   * // get username
   * url.username(); // returns string "user"
   */
  const username = (): string => url.username;

  /**
   * Return username from url
   * @example
   * const url = URI('http://user:pass@example.org/foo/hello.html');
   * // get password
   * url.password(); // returns string "pass"
   */
  const password = (): string => url.password;

  /**
   * Same as query method but with ? at start
   * @param {Any} param
   * @returns {String | Object}
   * @example
   * const uri = URI('http://example.org:8080/foo/?query=1#hash');
   * // get search
   * uri.search(); // returns string '?query=1'
   * uri.search(true); // returns object {query: '1'}
   * uri.search({newQuery: '2'}); // returns updated URI object
   * uri.search(); // returns string '?newQuery=2'
   * uri.search('newQuery=3'); // returns updated URI object
   * uri.search(); // returns string '?newQuery=3'
   * uri.search(false); // returns updated URI object
   * uri.search(); // returns object {}
   * @param param
   */
  const search = <T = SearchStringOperationResult>(
    param?: SearchStringOperationParam,
  ): T => {
    return operateSearchString(param, url, uriObject, true) as T;
  };

  // Set URI object
  setURI(uri);

  uriObject.hasSearch = hasSearch;
  uriObject.setSearch = setSearch;
  uriObject.addSearch = addSearch;
  uriObject.removeSearch = removeSearch;
  uriObject.query = query;
  uriObject.resource = resource;
  uriObject.host = host;
  uriObject.origin = origin;
  uriObject.path = path;
  uriObject.hostname = hostname;
  uriObject.port = port;
  uriObject.scheme = scheme;
  uriObject.toString = toString;
  uriObject.pathname = path;
  uriObject.search = search;
  uriObject.username = username;
  uriObject.password = password;

  return uriObject;
};

const buildUri = ({
  hostname,
  protocol,
  port,
  path,
  query,
}: {
  hostname: string;
  protocol: string;
  port?: string;
  path: string;
  query: string;
}): string => {
  let url = `${protocol}${PROTOCOL_SEPARATOR}${hostname}`;
  if (port) {
    url += `:${port}`;
  }

  if (!path.startsWith('/')) {
    url += `/${path}`;
  } else {
    url += path.replace(/\/{2,}/g, '/');
  }

  if (query) {
    url += `?${query}`;
  }
  return url;
};

const buildQuery = (params: Record<string, string>): string => {
  return new URLSearchParams(params).toString();
};

export default URI;
export {buildUri, buildQuery};
