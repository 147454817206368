import type {PaymentMethods} from '@core/payment/common/types/paymentMethods';

import {SEPA_METHODS} from '../../../common/constants/paymentMethods';

const SEPA_METHOD_LIST = Object.values(Object.assign(SEPA_METHODS));

const isSepaPaymentType = (method: PaymentMethods) =>
  SEPA_METHOD_LIST.includes(method);

export default isSepaPaymentType;
