import type {ChangeEvent, FC, ReactNode} from 'react';
import React, {useState} from 'react';
import cn from 'classnames';

import {TextDanger, Text} from '@core/typography';
import useEventCallback from '@core/utils/react/useEventCallback';
import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';
import isDeviceWithTouchScreen from '@core/utils/device/isDeviceWithTouchScreen';

import type {CSSModule} from '../../types';
import VerticalAlign from '../../constants/VerticalAlign';
import InputIconRipple from '../inputIconRipple';
import {SpacingLarge} from '../spacing';
import Icon from '../icon/Icon';
import baseCss from './Checkbox.css';

type Value = string | number;

export type ChangeCustomEventHandler = (
  event?: ChangeEvent<HTMLInputElement>,
  value?: Value,
  checked?: boolean,
) => void;

export interface CheckboxProps {
  children?: ReactNode;
  /**
   * Sometimes we just need to render checkbox with no binded value.
   * So value should not be required to avoid lines of code like: <Checkbox value="" />
   * @see ChataholicPackBanner.js
   */
  value?: Value;
  onChange?: ChangeCustomEventHandler;
  name?: string;
  trackingName?: string;
  className?: string;
  withMinSize?: boolean;
  checked?: boolean;
  disabled?: boolean;
  /**
   * Is native input[type=checkbox] render needed.
   * Pass `false` if you just need the component that looks like checkbox,
   * but do not need it to change the state or trigger additional click event
   * when you are trying to check it.
   */
  withNativeCheckbox?: boolean;
  inverse?: boolean;
  inline?: boolean;
  danger?: boolean;
  small?: boolean;
  smallest?: boolean;
  muted?: boolean;
  error?: string | ReactNode;
  'data-test'?: string;
  /**
   * If need show checkbox as image
   */
  background?: ReactNode | (({checked}: {checked: boolean}) => ReactNode);
  fullWidthBackground?: boolean;
  verticalAlign?: VerticalAlign;
}

const skipTouchEvents = !(
  isDeviceWithTouchScreen || window.IS_INTEGRATION_TEST_ENVIRONMENT
);

const DEFAULT_CSS: CSSModule = {};

/**
 * Simple checkbox component
 */
const Checkbox: FC<
  // `CheckboxProps` without `css` inside to make it more suitable for `@phoenix/ui`.
  CheckboxProps & {
    /**
     * Styles are passed as props and mixed with own styles.
     */
    css?: CSSModule;
  }
> = ({
  className,
  css = DEFAULT_CSS,
  withMinSize = true,
  disabled = false,
  withNativeCheckbox = true,
  inverse = false,
  inline = false,
  small = false,
  danger = false,
  smallest = false,
  muted,
  error,
  name,
  trackingName = name,
  children,
  value = '',
  background = null,
  fullWidthBackground = false,
  verticalAlign = VerticalAlign.TOP,
  onChange,
  checked = false,
  'data-test': dataTest,
}) => {
  const [focused, setFocused] = useState(false);
  const [hover, setHover] = useState(false);
  const [animated, setAnimated] = useState(false);

  const handleBlur = useEventCallback(() => setFocused(false));

  const handleChange = useEventCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (disabled) return;

      if (onChange) onChange(event, value, !checked);

      setAnimated(true);
      handleBlur();
    },
  );

  const handleFocus = useEventCallback(
    () => skipTouchEvents && setFocused(true),
  );

  const handleMouseEnter = useEventCallback(
    () => skipTouchEvents && setHover(true),
  );

  const handleMouseLeave = useEventCallback(() => setHover(false));

  const handleAnimationEnd = useEventCallback(() => setAnimated(false));

  const classNames = cn(
    checked && cn(baseCss.active, css.active),
    disabled && baseCss.disabled,
    inverse && cn(baseCss.inverse, css.inverse),
    small && baseCss.small,
    smallest && baseCss.smallest,
    muted && baseCss.muted,
    children && baseCss.margin,
    baseCss.wrap,
  );

  return (
    <>
      <AddBabciaUBTracking
        trackingName={
          withNativeCheckbox && !disabled && trackingName
            ? `${trackingName}Checkbox`
            : null
        }
      >
        <label
          className={cn(
            className,
            baseCss.checkbox,
            withMinSize && baseCss.withMinSize,
            baseCss[verticalAlign],
            background && baseCss.background,
            inline && baseCss.inline,
            fullWidthBackground && baseCss.fullWidthBackground,
          )}
        >
          <div className={classNames}>
            {withNativeCheckbox && (
              <input
                className={baseCss.input}
                type="checkbox"
                name={name}
                value={value}
                checked={checked}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                data-test={dataTest}
              />
            )}

            {background &&
              (typeof background === 'function'
                ? background({checked})
                : background)}

            <div className={background && baseCss.position}>
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onAnimationEnd={handleAnimationEnd}
                className={baseCss.iconContainer}
              >
                <InputIconRipple
                  focused={focused}
                  hover={hover}
                  animated={animated}
                  active={checked && !muted}
                  inverse={inverse}
                />
                <div className={cn(baseCss.icon, css.icon)}>
                  <Icon
                    type={`checkbox-${checked ? 'on' : 'off'}`}
                    inherit
                    danger={danger}
                  />
                </div>
              </div>
            </div>
          </div>
          {children && (
            <div className={baseCss.labelWrapper}>
              <Text
                type={Text.TYPE.LABEL}
                inverse={inverse}
                className={baseCss.label}
                small={small}
              >
                {children}
              </Text>
            </div>
          )}
        </label>
      </AddBabciaUBTracking>
      {Boolean(error) && (
        <SpacingLarge adaptive={false} onlyLeft>
          <TextDanger className={baseCss.error} small>
            {error}
          </TextDanger>
        </SpacingLarge>
      )}
    </>
  );
};

export default Checkbox;
