import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import {
  CURRENT_USER_ID,
  IN_APP_NAME,
} from '@core/application/constants/bootstrapParams';
import InAppBrowserName from '@core/application/constants/inAppBrowserName';
import getFragmentKey from '@core/application/utils/getFragmentKey';

/**
 * Need to add url parameter for all urls in inAppBrowser
 * @returns {Object}
 */
const getFragmentTokens = () => {
  const inAppBrowserName = getBootstrapParam(IN_APP_NAME);

  if (inAppBrowserName === InAppBrowserName.NORMAL_BROWSER) {
    return {};
  }

  const fragmentKey = getFragmentKey();

  return {
    ...(fragmentKey && {fragmentKey}),
    fragmentBrowser: inAppBrowserName,
    fragmentToken: getBootstrapParam(CURRENT_USER_ID),
  };
};

export default getFragmentTokens;
