import {getClientInstance} from '@core/graphql/client';
import logger from '@core/logger';

import TRACK_MESSAGE_DELIVERY_MUTATION from '../graphql/mutations/trackMessageDelivery.gql';

/**
 * Tracking the time between request and response for a message.
 * You should always send seconds.
 */
const trackMessengerRequest = {
  startRequestTime: null,

  setStartRequestTime() {
    this.startRequestTime = new Date().getTime();
  },

  track() {
    const currentRequestTime = new Date().getTime();

    if (this.startRequestTime) {
      getClientInstance().mutate({
        mutation: TRACK_MESSAGE_DELIVERY_MUTATION,
        variables: {
          time: Math.round((currentRequestTime - this.startRequestTime) / 1000),
        },
      });
    } else {
      logger.sendError('[trackMessengerRequest] Track without set start time');
    }
  },
};

export default trackMessengerRequest;
