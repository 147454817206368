import type {FC, ReactNode} from 'react';
import React from 'react';

import css from '../styles/PaymentPendingPopupWrapp.css';

const PaymentPendingPopupWrapp: FC<{children: ReactNode}> = ({children}) => (
  <div className={css.wrapper}>{children}</div>
);

export default PaymentPendingPopupWrapp;
