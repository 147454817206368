import type {PaymentProcessStep} from '../../types/paymentProcess';
import {
  setReady,
  setFailed,
  setSuccess,
} from '../../../common/utils/setPaymentProcessingStatus';

/**
 * Set processing status that indicate start of payment process
 */
const reportEndPayment: PaymentProcessStep = async (data) => {
  const {paymentResult, paymentData} = data;
  const {replaceRedirectToPostMessage} = paymentData;
  const {isAllowedRedirect, status} = paymentResult;

  if (status && replaceRedirectToPostMessage) {
    return data;
  }

  if (isAllowedRedirect || replaceRedirectToPostMessage) {
    setReady();
    return data;
  }

  if (status) {
    setSuccess();
  } else {
    setFailed();
  }

  return data;
};

export default reportEndPayment;
