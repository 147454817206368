/**
 * Chat page features
 */
enum ChatPageAppearance {
  WITH_TOOLBAR = 'withToolbar',

  WITH_TOOLBAR_AND_USER_ACTIONS = 'withToolbarAndUserActions',

  LAYERED = 'layered',
}

export default ChatPageAppearance;
