import find from 'lodash/find';

import {getClientInstance} from '@core/graphql/client';

import CURRENT_USER_PHOTOS_QUERY from '../graphql/queries/currentUserPhotos.gql';
import CURRENT_USER_VIDEOS_QUERY from '../graphql/queries/currentUserVideos.gql';
import type {
  CurrentUserPhotosQuery,
  CurrentUserPhotosQueryVariables,
} from '../graphql/queries/currentUserPhotos';
import type {
  CurrentUserVideosQuery,
  CurrentUserVideosQueryVariables,
} from '../graphql/queries/currentUserVideos';

type Photo = CurrentUserPhotosQuery['myUser']['profile']['photos'];
type Updater = (photos: Photo) => Photo;

export const removeVideoFromCache = ({id}: {id: string}) => {
  const client = getClientInstance();
  const data = client.readQuery<CurrentUserVideosQuery>({
    query: CURRENT_USER_VIDEOS_QUERY,
  });

  if (!data) {
    return;
  }

  const allVideos = data.myUser.profile.videos.allVideos.filter(
    (video) => video.id !== id,
  );
  client.writeQuery<CurrentUserVideosQuery, CurrentUserVideosQueryVariables>({
    query: CURRENT_USER_VIDEOS_QUERY,
    data: {
      ...data,
      myUser: {
        ...data.myUser,
        profile: {
          ...data.myUser.profile,
          videos: {
            ...data.myUser.profile.videos,
            allVideos,
            count: allVideos.length,
          },
        },
      },
    },
  });
};

const updatePhotosCache = (updater: Updater) => {
  if (!updater) {
    return;
  }

  const client = getClientInstance();
  const data = client.readQuery<
    CurrentUserPhotosQuery,
    CurrentUserPhotosQueryVariables
  >({query: CURRENT_USER_PHOTOS_QUERY});

  if (!data) {
    return;
  }

  const {photos} = data.myUser.profile;
  const photosData = updater(photos);

  if (!photosData) {
    return;
  }

  client.writeQuery<CurrentUserPhotosQuery, CurrentUserPhotosQueryVariables>({
    query: CURRENT_USER_PHOTOS_QUERY,
    data: {
      ...data,
      myUser: {
        ...data.myUser,
        profile: {
          ...data.myUser.profile,
          photos: {
            ...data.myUser.profile.photos,
            ...photosData,
          },
        },
      },
    },
  });
};

export const removePhotoFromCache = ({
  id,
  pendingDelete,
}: {
  id: string;
  pendingDelete?: boolean;
}) => {
  updatePhotosCache(({allPhotos, primaryPhoto}) => {
    const updatedAllPhotos = allPhotos.filter((photo) => photo.id !== id);
    return pendingDelete
      ? {
          allPhotos: allPhotos.map((photo) => {
            return photo.id === id ? {...photo, pendingDelete} : photo;
          }),
          primaryPhoto:
            (primaryPhoto && primaryPhoto.id) === id
              ? {
                  ...primaryPhoto,
                  pendingDelete,
                }
              : primaryPhoto,
          count: updatedAllPhotos.length,
        }
      : {
          allPhotos: updatedAllPhotos,
          primaryPhoto,
          count: updatedAllPhotos.length,
        };
  });
};

export const updatePrimaryPhotoInCache = ({id}: {id: string}) => {
  updatePhotosCache(({allPhotos, primaryPhoto}) => {
    if (primaryPhoto.id !== id) {
      return null;
    }

    return {
      allPhotos,
      primaryPhoto: allPhotos.find((photo) => photo.id === id),
    };
  });
};

export const updateVideo = ({
  id,
  previewUrl,
  isConverted,
  error,
}: {
  id: string;
  previewUrl: string;
  isConverted: boolean;
  error: string;
}) => {
  const client = getClientInstance();
  const data = client.readQuery<CurrentUserVideosQuery>({
    query: CURRENT_USER_VIDEOS_QUERY,
  });

  if (!data) {
    return;
  }

  const {allVideos} = data.myUser.profile.videos;

  if (!find(allVideos, {id})) {
    return;
  }

  /**
   * Just optimal value for setTimeout
   * in order to just work on IOS.
   * Not necessarily accurate.
   */
  const TIME_TO_UPDATE_ON_IOS = 500;

  /**
   * We have to use setTimeout to fix a bug with update apollo cache.
   * If we use client.writeQuery without setTimeout we getting an old data.
   * More information about this you can see by reference below.
   * @see https://jira.togethernetworks.com/browse/TN-178922
   */
  setTimeout(() => {
    client.writeQuery<CurrentUserVideosQuery, CurrentUserVideosQueryVariables>({
      query: CURRENT_USER_VIDEOS_QUERY,
      data: {
        ...data,
        myUser: {
          ...data.myUser,
          profile: {
            ...data.myUser.profile,
            videos: {
              ...data.myUser.profile.videos,
              allVideos: allVideos.map((item) =>
                item.id === id
                  ? {
                      ...item,
                      previewUrl,
                      isConverted,
                      error,
                    }
                  : item,
              ),
            },
          },
        },
      },
    });
  }, TIME_TO_UPDATE_ON_IOS);
};
