/**
 * Used to separate theme  from feature in po file name
 * @type {string}
 */
export const THEME_DELIMITER = '_';

/**
 * Default theme for merge translation
 * @type {string}
 */
export const THEME_FALLBACK = 'material';
