/**
 * Card form caption for pay request data
 * @see payment/payProcess/utils/getFormName
 * @type {string}
 */
export const CARD_FORM_NAME = 'CreditCardPaymentForm';

export const CARD_WALLET_FORM_NAME = 'CardWalletPaymentForm';

export const IBAN_ONE_CLICK_FORM_NAME = 'IbanOneClickPaymentForm';

/**
 * Used to create caption for pay request data
 * @see payment/payProcess/utils/getFormName
 * @type {string}
 */
export const FORM_NAME_SUFFIX = 'PaymentForm';

export default {
  CARD_FORM_NAME,
  FORM_NAME_SUFFIX,
  CARD_WALLET_FORM_NAME,
  IBAN_ONE_CLICK_FORM_NAME,
};
