const getOverflowParent = (element: HTMLElement): HTMLElement | null => {
  let current: HTMLElement | null = element.parentElement;

  while (current && current !== document.documentElement) {
    const {overflow, overflowY, overflowX} = window.getComputedStyle(current);

    if (
      /auto|scroll|overlay|hidden|clip/.test(overflow + overflowY + overflowX)
    ) {
      return current;
    }

    current = current.parentElement;
  }

  return null;
};

export default getOverflowParent;
