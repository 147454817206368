import {mergeArrayField as merge} from '../utils/mergeArrayField';

/**
 * Indicates if we need to update search results cache key ({usersSearchParams:null} -> {usersSearchParams:{...}}).
 */
let initialUpdate = false;

const searchResultsWriter =
  (isArray: boolean = false) =>
  (oldResults, results, {args: {usersSearchParams}, mergeObjects}) => {
    if (!oldResults && !usersSearchParams) {
      // Search results are loaded without search params, schedule cache key update.
      initialUpdate = true;
    }

    if (oldResults) {
      return isArray
        ? merge(oldResults, results)
        : mergeObjects(oldResults, results);
    }

    return results;
  };

const readSearchResults = (
  results,
  {args, fieldName, readField, storeFieldName, toReference, cache},
) => {
  if (initialUpdate && !results && args.usersSearchParams) {
    /*
      usersSearchParams is changed for first time, so this is variables change caused by initial search params load.
      We already have search results (requested without search params),
      so reuse them to avoid additional search results loading (with search params).
    */

    initialUpdate = false;

    // Read results loaded with params=null.
    const options = {fieldName, args: {...args, usersSearchParams: null}};
    const initialResults = readField({
      ...options,
      from: toReference({__typename: 'Search'}),
    });

    // Write them to requested storage key (which contain loaded params).
    const search = toReference(
      {
        __typename: 'Search',
        [storeFieldName]: initialResults,
      },
      true,
    );

    // Just in case cleanup initial cache, we don't need it anymore.
    cache.evict({...options, id: 'Search:{}', broadcast: false});

    // Read written results instead of returning `initialResults` for correct `fetchMore` work.
    return readField({fieldName, args, from: search});
  }

  return results;
};

export default {
  Search: {
    /**
     * Search is singleton.
     * Useful for invalidation {@see invalidateSearchResults}
     */
    keyFields: [],
    fields: {
      users: {
        keyArgs: ['usersSearchParams'],
        merge: searchResultsWriter(),
        read: readSearchResults,
      },
      livecam: {
        keyArgs: ['placement', 'usersSearchParams'],
        merge: searchResultsWriter(true),
        read: readSearchResults,
      },
    },
  },
  Users: {
    fields: {
      resultingUsers: {
        merge,
      },
      completionUsers: {
        merge,
      },
    },
  },
  SettingsSearch: {
    keyFields: [],
    fields: {
      banners: {
        merge(_, banners, {args, toReference}) {
          /**
           * Use environment variable as an id for motivation banners cache normalization.
           * Do not replace with `Banners: {keyFields: []}`, because it leads to inappropriate results.
           * For example, it will use WEB data after viewport change from MOB to WEB and back.
           * Normalization is also useful for cache invalidation {@see SmartSave}.
           */
          return toReference({...banners, id: args.environment}, true);
        },
      },
    },
  },
};
