enum ConfirmationActionsAppearance {
  /**
   * Displays every-time one button and have icons on them
   * that adapts, based on photo or video relation.
   */
  WITHOUT_ICONS = 'withoutIcons',

  /**
   * The same as default ones, but with icons.
   */
  WITH_ICONS = 'withIcons',

  /**
   * View of resend action like link in the confirmation popup
   */
  WITH_RESEND_LINK_AND_ICONS = 'withResendLinkAndIcons',
}

export default ConfirmationActionsAppearance;
