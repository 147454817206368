import type {FC} from 'react';
import React, {Fragment, useMemo} from 'react';
import cn from 'classnames';

import type {TagProps} from '../tag/Tag';
import Separator from '../separator';
import TagType from '../../constants/TagType';
import css from './Steps.css';

interface StepItemProps {
  last?: boolean;
  currentIndex: number;
  type?: TagType;
  tagComponent: FC<TagProps>;
}

const StepItem: FC<StepItemProps> = ({
  currentIndex,
  last,
  type,
  tagComponent: Tag,
}) => {
  const icon = useMemo(() => {
    if (type === TagType.DANGER) return 'cross';
    if (type === TagType.SUCCESS) return 'check';
    return null;
  }, [type]);

  return (
    <Fragment>
      <Tag className={css.tag} type={type} icon={icon}>
        {!icon && currentIndex}
      </Tag>
      {!last && <Separator className={css.separator} />}
    </Fragment>
  );
};

export interface StepsProps {
  className?: string;
  current: number;
  total: number;
  fullWidth?: boolean;
  highlightCurrentStep?: boolean;
  'data-test'?: string;
}

const Steps: FC<
  // `StepsProps` without `tagComponent` inside to make it more suitable for `@phoenix/ui`.
  StepsProps & Pick<StepItemProps, 'tagComponent'>
> = ({
  current,
  total,
  className,
  fullWidth = true,
  highlightCurrentStep = true,
  'data-test': dataTest = 'steps',
  ...props
}) => {
  const steps = useMemo(() => {
    const items = [];

    for (let i = 1; i <= total; i++) {
      let type = TagType.TRANSLUCENT;
      let last = false;

      if (current === i) {
        type = highlightCurrentStep ? TagType.PRIMARY : TagType.SUCCESS;
      } else if (current > i) {
        type = TagType.SUCCESS;
      }

      if (total === i) last = true;

      items.push(
        <StepItem
          key={i}
          currentIndex={i}
          last={last}
          type={type}
          {...props}
        />,
      );
    }

    return items;
  }, [total, current, props, highlightCurrentStep]);

  return (
    <div
      data-test={dataTest}
      className={cn(className, css.list, fullWidth && css.fullWidth)}
    >
      {steps}
    </div>
  );
};

export default Steps;
