/**
 * @const {string}
 */
export const FALLBACK_LOCALE = 'en';

/**
 * List of available locales
 * @see loadTranslations.js
 */
export default {
  CS: 'cs',
  DA: 'da',
  DE: 'de',
  EN: 'en',
  EN_AU: 'en_au',
  EN_CA: 'en_ca',
  EN_GB: 'en_gb',
  ES: 'es',
  ES_AR: 'es_ar',
  ES_MX: 'es_mx',
  ES_US: 'es_us',
  FI: 'fi',
  FR: 'fr',
  HU: 'hu',
  HR: 'hr',
  ID: 'id',
  IT: 'it',
  JA: 'ja',
  KO: 'ko',
  MS: 'ms',
  EL: 'el',
  NL: 'nl',
  NL_BE: 'nl_be',
  NO: 'no',
  PL: 'pl',
  PT: 'pt',
  PT_BR: 'pt_br',
  RO: 'ro',
  RU: 'ru',
  SL: 'sl',
  SV_SE: 'sv_se',
  TH: 'th',
  TR: 'tr',
  ZH: 'zh',
  ZH_HK: 'zh_hk',
  ZH_SG: 'zh_sg',
  ZH_TW: 'zh_tw',
  AR_DZ: 'ar_dz',
  AR_EG: 'ar_eg',
  VI: 'vi',
};
