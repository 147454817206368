// Inserts a passed code into the body as a html
const processCode = async (code: string): Promise<void> => {
  // createContextualFragment to make <script> tags executable
  const fragment = document.createRange().createContextualFragment(code);

  const loadingPromises = Array.prototype.map.call(
    fragment.querySelectorAll('script[src]'),
    (script: HTMLScriptElement) =>
      new Promise<void>((resolve, reject) => {
        script.addEventListener('load', () => resolve(), {once: true});

        script.addEventListener(
          'error',
          () => {
            reject(
              new Error(
                `[processCode]: Failed to load tracking code script: ${
                  script.src
                }.`,
              ),
            );
          },
          {once: true},
        );
      }),
  );

  document.body.appendChild(fragment);

  await Promise.all(loadingPromises);
};

type TrackingCode = {
  code: string;
};

// Adds tracking codes to the document to execute them.
const applyTrackingCodes = async (codes: TrackingCode[]): Promise<void> => {
  // Add tracking codes sequentially, as they may contain scripts that depend on previous ones.
  for (let i = 0; i < codes.length; i++) {
    // eslint-disable-next-line no-await-in-loop
    await processCode(codes[i].code);
  }
};

export default applyTrackingCodes;
