let preventSelection = false;

function removeSelection() {
  if (window.getSelection) {
    window.getSelection().removeAllRanges();
  } else if (document.selection && document.selection.clear) {
    document.selection.clear();
  }
}

function onMouseMove() {
  if (preventSelection) {
    removeSelection();
  }
}

function onMouseDown(event = window.event) {
  const sender = event.target || event.srcElement;
  preventSelection = !sender.tagName.match(/INPUT|TEXTAREA/i);
}

function killCtrlA(event) {
  const sender = event.target || window.event;

  if (sender.tagName.match(/INPUT|TEXTAREA/i)) {
    return;
  }

  const key = event.keyCode || event.which;
  if (event.ctrlKey && key === 'A'.charCodeAt(0)) {
    removeSelection();

    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }
  }
}

function onMouseUp() {
  if (preventSelection) {
    removeSelection();
  }

  preventSelection = false;
}

const disableCopyText = {
  on: () => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('keydown', killCtrlA);
    document.addEventListener('keyup', killCtrlA);
  },

  off: () => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mousedown', onMouseDown);
    document.removeEventListener('mouseup', onMouseUp);
    document.removeEventListener('keydown', killCtrlA);
    document.removeEventListener('keyup', killCtrlA);
  },
};

export default disableCopyText;
