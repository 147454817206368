import uniq from 'lodash/uniq';

const getTypeNames = (
  // The `obj` parameter can be any object being written into the cache.
  // Its exact structure is unknown, but this does not affect the function's outcome.
  obj: Record<string, any>,
): string[] => {
  return uniq(
    Object.keys(obj).reduce((filteredTypeNames: string[], key: string) => {
      const value = obj[key];

      if (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        value !== null
      ) {
        return [...filteredTypeNames, ...getTypeNames(value)];
      }

      if (key === '__typename' && value !== 'Query') {
        filteredTypeNames.push(value);
      }

      return filteredTypeNames;
    }, []),
  );
};

export default getTypeNames;
