import type {FC, ReactElement} from 'react';
import React, {useCallback, useEffect, useRef} from 'react';

import centerViewOnActiveElement from '@core/utils/dom';
import isDeviceWithTouchScreen from '@core/utils/device/isDeviceWithTouchScreen';

import type {TextareaResizeEvent} from './types';
import type {BaseInputProps, BaseInputRenderProps} from './BaseInput';
import TextareaAutosize from './TextareaAutosize';
import baseCss from './Textarea.css';

/**
 * Textarea dom element type
 */
const TEXT_AREA_TYPE = 'textarea';

/**
 * There is delay between document resize and native scroll end
 * after resize.
 * Couldn't find any information about length of this delay
 * Left 500ms because it's pretty match enough to end native scrolling after resize
 *
 * @todo replace by real timeout or bind to event
 */
const SCROLL_ON_RESIZE_DELAY = 500;

/**
 * Scrolls to textarea when keyboard is shown
 */
const centerViewOnWindowResize = () => {
  setTimeout(() => {
    centerViewOnActiveElement(TEXT_AREA_TYPE);
  }, SCROLL_ON_RESIZE_DELAY);
};

const onTextAreaResize = (prevHeight: number, event: TextareaResizeEvent) => {
  // Fix bug with ios, when user dont see text, because keyboard overlay it.
  const srcElement = event.target;
  const {lineHeight} = document.defaultView.getComputedStyle(srcElement);

  // Fix bug on IPad, in case when user entered first letter and current hop into top
  const isFirstSymbol = srcElement.value.length === 1;

  if (prevHeight <= srcElement.clientHeight && !isFirstSymbol) {
    window.scrollTo(0, window.pageYOffset + parseInt(lineHeight, 10));
  } else {
    // center view because when we delete lines in textarea our cursor can be overlaid by a header
    centerViewOnActiveElement(TEXT_AREA_TYPE);
  }
};

export interface TextareaProps extends BaseInputProps {
  rows?: number;
  centerView?: boolean;
}

const Textarea: FC<
  // `TextareaProps` does not include some props to make it more suitable for `@phoenix/ui`.
  TextareaProps & {inputComponent: FC<BaseInputProps>}
> = ({inputComponent: BaseInput, rows, centerView = true, ...props}) => {
  const prevHeightRef = useRef(0);

  const handleResize = useCallback(
    (e: TextareaResizeEvent) => {
      onTextAreaResize(prevHeightRef.current, e);
      prevHeightRef.current = e.target.clientHeight;
    },
    [prevHeightRef],
  );

  useEffect(() => {
    centerView &&
      isDeviceWithTouchScreen &&
      window.addEventListener('resize', centerViewOnWindowResize);

    return () => {
      centerView &&
        isDeviceWithTouchScreen &&
        window.removeEventListener('resize', centerViewOnWindowResize);
    };
  }, [centerView]);

  return (
    <BaseInput {...props}>
      {(textareaProps: BaseInputRenderProps): ReactElement => (
        <TextareaAutosize
          className={baseCss.textarea}
          rows={rows}
          onResize={isDeviceWithTouchScreen ? handleResize : null}
          {...textareaProps}
        />
      )}
    </BaseInput>
  );
};

export default Textarea;
