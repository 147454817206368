import {getClientInstance} from '@core/graphql/client';
import type {CountryType} from '@core/types';

import CURRENT_USER_COUNTRY_QUERY from '../graphql/queries/currentUserCountry.gql';
import type {CurrentUserCountryQuery} from '../graphql/queries/currentUserCountry';

const currentUserCountry = (): CountryType => {
  return getClientInstance().readQuery<CurrentUserCountryQuery>({
    query: CURRENT_USER_COUNTRY_QUERY,
  }).myUser.profile.location.country;
};

export default currentUserCountry;
