import trackRedirectTo from '@core/tracking/babcia/utils/trackRedirectTo';

/**
 * Utility function used to, avoiding any router, make redirects.
 * Useful for replace pathname and reload page.
 *
 * @param {String} url
 * @returns {void}
 */
const replaceLocation = (url) => {
  trackRedirectTo({nextPathname: url});

  window.location.replace(url);
};

export default replaceLocation;
