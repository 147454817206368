import getAnimationTime from '@core/utils/animation/utils/getAnimationTime';

/**
 * WARNING.
 *
 * After opening the site in a new tab, the correct/updated values in:
 * - document.documentElement.clientHeight
 * - document.documentElement.clientWidth
 * appear after 500 milliseconds, so change the value of this constant very carefully.
 *
 * Time in milliseconds
 */
const RESIZE_DELAY = getAnimationTime({delay: 500});

export default RESIZE_DELAY;
