/**
 * Need for calculate item index from virtual in infinity slider.
 * Virtual index can be more or less than critical indexes of items array
 * @param {Number} itemsCount
 * @param {Number} virtualIndex
 * @see GalleryCarousel.js
 * @return {Number} item index in items array
 * @example get after last element in infinity slider
 * const items = [1,2,3];
 * getItemIndex(items.length, 3)
 * // return 1
 */
const getItemIndex = (itemsCount, virtualIndex) => {
  const itemIndex = virtualIndex % itemsCount;
  return itemIndex < 0 ? itemsCount + itemIndex : itemIndex;
};

export default getItemIndex;
