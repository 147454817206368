import {mergeArrayFieldUniq} from '../utils/mergeArrayField';

export default {
  DisplaySettings: {
    keyFields: [],
  },
  PopupInfo: {
    keyFields: [],
  },
  Remarketing: {
    keyFields: [],
    fields: {
      banner: {
        keyArgs: false,
        merge(prevBanners, fetchedBanners) {
          return mergeArrayFieldUniq(fetchedBanners, prevBanners);
        },
      },
    },
  },
  Banner: {
    keyFields: ['zoneId'],
  },
};
