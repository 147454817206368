import type {RefObject} from 'react';
import {useEffect} from 'react';
import debounce from 'lodash/debounce';

import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import isKeyboardOpen from '@core/utils/device/isKeyboardOpen';
import getSizeBasedOnZoom from '@core/application/utils/getSizeBasedOnZoom';
import {IN_APP_NAME} from '@core/application/constants/bootstrapParams';
import InAppBrowserName from '@core/application/constants/inAppBrowserName';
import getBoundingClientRect from '@core/application/utils/getBoundingClientRect';
import getUserAgentParser from '@core/utils/getUserAgentParser';

import isVisualViewportOnIOSAvailable from './isVisualViewportOnIOSAvailable';

const IS_CHROME_BROWSER = getUserAgentParser().getBrowser().name === 'Chrome';

const initialHeight = window.innerHeight;

/**
 * During initialization in the Chrome browser, when a new tab is opened,
 * there are cases when document.documentElement.clientHeight is calculated incorrectly
 * and does not include the size of system toolbars.
 * In Chrome browser need to skip this param for calculation correct keyboard position.
 */
const initialClientHeight = IS_CHROME_BROWSER
  ? null
  : document.documentElement.clientHeight;

const UPDATE_CURSOR_VISIBILITY_DELAY = 50; // Optimal time to update cursor visibility
const OPEN_KEYBOARD_DELAY = 500; // Keyboard opening animation duration
const ALL_ANIMATIONS_DELAY = OPEN_KEYBOARD_DELAY * 3; // All animations and transitions should be finished

const INTERVAL_TIME = 100;

const CLASS_NAME = 'hideCaret';
export const TAG_NAME = ['INPUT', 'TEXTAREA'];

const getActiveInput = (): HTMLInputElement | null => {
  const activeElement = document.activeElement as HTMLElement;
  return activeElement && TAG_NAME.includes(activeElement.tagName)
    ? (activeElement as HTMLInputElement)
    : null;
};

type UseElementPositionAboveKeyboardParams = {
  ref: RefObject<HTMLDivElement>; // Ref to the payment button container
  updateCursorVisibility?: boolean;
  withCheckCaretOverlap?: boolean;
};

/**
 * Hook for correct element position when keyboard is opened
 */
const useElementPositionAboveKeyboard = ({
  ref,
  updateCursorVisibility = false,
  withCheckCaretOverlap = true,
}: UseElementPositionAboveKeyboardParams) => {
  useEffect(() => {
    if (!isVisualViewportOnIOSAvailable() || !ref.current) {
      return;
    }

    const isInAppBrowser =
      getBootstrapParam(IN_APP_NAME) !== InAppBrowserName.NORMAL_BROWSER;

    let isOpen = false;
    let isTouched = false;

    let keyboardCheckInterval: Interval;
    let updateKeyboardPositionTimeout: Timeout;
    let updateVisualViewportTimeout: Timeout;

    let prevScroll = window.scrollY;
    let prevVisualViewportHeight = window.visualViewport.height;
    let prevInnerHeight = window.innerHeight;
    let prevClientHeight = document.documentElement.clientHeight;
    let prevVisualViewportPageTop = window.visualViewport.pageTop;
    let prevVisualViewportOffsetTop = window.visualViewport.offsetTop;

    const updatePrevData = () => {
      prevScroll = window.scrollY;
      prevVisualViewportHeight = window.visualViewport.height;
      prevInnerHeight = window.innerHeight;
      prevVisualViewportPageTop = window.visualViewport.pageTop;
      prevVisualViewportOffsetTop = window.visualViewport.offsetTop;
      prevClientHeight = document.documentElement.clientHeight;
    };

    const setBottom = (value: number) => {
      if (!ref.current) {
        return;
      }

      const y = value ? value * -1 : 0;

      ref.current.style.transform = `translate3d(0px, ${getSizeBasedOnZoom(y)}px, 0px)`;
    };

    const getBottomPosition = () => {
      const currentHeight = isInAppBrowser
        ? document.documentElement.clientHeight
        : Math.max(
            window.innerHeight,
            document.documentElement.clientHeight,
            initialClientHeight,
            initialHeight,
          );

      const offsetTop = Math.max(window.visualViewport.offsetTop, 0);

      const bottomPosition =
        currentHeight - window.visualViewport.height - offsetTop;

      return Math.max(isTouched ? 0 : bottomPosition, 0);
    };

    /**
     * Function to manage the `hideCaret` class on active input elements
     * when they are overlapped by a referenced component, specifically for iOS 15>=.
     *
     * This is an attempt to fix a known bug on iOS where the caret appears above
     * other elements (commonly referred to as "caret overlapping fixed element").
     * The solution involves making the caret transparent when it is overlapped
     * by another element.
     *
     * For more details, refer to the following links:
     * - https://github.com/ckeditor/ckeditor5/issues/8913
     * - https://stackoverflow.com/questions/19245658/text-insertion-cursor-caret-appears-above-other-elements-in-mobile-browser
     * - https://jira.togethernetworks.com/browse/FE-37679
     */
    const checkCaretOverlap = (): void => {
      const activeInput = getActiveInput();
      if (!activeInput || !isKeyboardOpen() || !ref.current) return;

      const actionsRect = getBoundingClientRect(ref.current);
      const inputRect = getBoundingClientRect(activeInput);

      const isOverlap =
        actionsRect.top < inputRect.bottom &&
        actionsRect.bottom > inputRect.top;

      if (isOverlap) {
        activeInput.classList.add(CLASS_NAME);
      } else {
        activeInput.classList.remove(CLASS_NAME);
      }
    };

    /**
     * Debounced function to restore textarea opacity after a delay
     */
    const debouncedRestoreOpacity = debounce(() => {
      const activeElement = document.activeElement as HTMLElement;
      activeElement.style.opacity = '1';
    }, UPDATE_CURSOR_VISIBILITY_DELAY);

    const updateKeyboardPosition = () => {
      clearTimeout(updateKeyboardPositionTimeout);
      clearTimeout(updateVisualViewportTimeout);

      /**
       * Hide button only when scrollY is different from previous value
       */
      if (
        Math.abs(window.scrollY - prevScroll) > 5 ||
        (isTouched && window.scrollY !== prevScroll) // is touched and scrolling in progress
      ) {
        ref.current.style.opacity = '0';
      }

      /**
       * Set correct bottom position after delay
       */
      updateKeyboardPositionTimeout = setTimeout(() => {
        setBottom(getBottomPosition());
        if (withCheckCaretOverlap) {
          checkCaretOverlap();
        }

        ref.current.style.opacity = isTouched ? '0' : '';

        if (!isTouched && updateCursorVisibility) {
          const activeElement = document.activeElement as HTMLElement;
          // Temporarily adjust opacity to force reflow and fix cursor visibility issue
          activeElement.style.opacity = '0.99';
          debouncedRestoreOpacity();
        }
      }, OPEN_KEYBOARD_DELAY);

      updatePrevData();
    };

    const handleTouch = (event: Event) => {
      clearTimeout(updateVisualViewportTimeout);

      isTouched = event.type === 'touchstart';

      if (!isTouched) {
        updateVisualViewportTimeout = setTimeout(() => {
          ref.current.style.opacity = '';
          window.scrollBy(0, 1);
        }, ALL_ANIMATIONS_DELAY);
      }
    };

    const resetKeyboardPosition = () => {
      clearTimeout(updateKeyboardPositionTimeout);
      clearTimeout(updateVisualViewportTimeout);

      isOpen = false;

      /**
       * setTimeout need for correct pointing target of click before transform block on ios.
       * Without setTimeout ios trigger touchend event after setBottom with another coordinates
       * this means that the click event will not be triggered
       */
      setTimeout(() => {
        setBottom(0);
      });

      ref.current.style.opacity = '';

      /**
       * Trigger recalculate window.visualViewport
       */
      updateVisualViewportTimeout = setTimeout(() => {
        window.scrollBy(0, 1);
      }, ALL_ANIMATIONS_DELAY);
    };

    const handleFocusOut = (event: Event) => {
      if (!TAG_NAME.includes((event.target as HTMLElement).tagName)) {
        return;
      }

      resetKeyboardPosition();
    };

    const handleFocusIn = (event: Event) => {
      if (
        keyboardCheckInterval ||
        !TAG_NAME.includes((event.target as HTMLElement).tagName)
      ) {
        return;
      }

      updateKeyboardPosition();

      /**
       * Init update keyboard position interval
       */
      keyboardCheckInterval = setInterval(() => {
        if (
          !TAG_NAME.includes(document.activeElement.tagName) ||
          !isKeyboardOpen()
        ) {
          if (isOpen) {
            resetKeyboardPosition();
          }

          updatePrevData();
          return;
        }

        /**
         * Keyboard position should be updated if:
         *  - scroll is changed
         *  - visualViewport height is changed
         *  - touch state is changed
         */
        const needUpdateKeyboardPosition =
          prevScroll !== window.scrollY ||
          isTouched ||
          prevInnerHeight !== window.innerHeight ||
          prevVisualViewportHeight !== window.visualViewport.height ||
          prevVisualViewportPageTop !== window.visualViewport.pageTop ||
          prevVisualViewportOffsetTop !== window.visualViewport.offsetTop ||
          prevClientHeight !== document.documentElement.clientHeight;

        if (needUpdateKeyboardPosition) {
          isOpen = true;

          updateKeyboardPosition();
        }
      }, INTERVAL_TIME);
    };

    window.addEventListener('touchend', handleTouch, {passive: true});
    window.addEventListener('touchstart', handleTouch, {passive: true});
    document.addEventListener('focusout', handleFocusOut, {passive: true});
    document.addEventListener('focusin', handleFocusIn, {passive: true});

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(keyboardCheckInterval);
      clearTimeout(updateKeyboardPositionTimeout);
      clearTimeout(updateVisualViewportTimeout);

      setBottom(0);

      window.removeEventListener('touchend', handleTouch);
      window.removeEventListener('touchstart', handleTouch);
      document.removeEventListener('focusout', handleFocusOut);
      document.removeEventListener('focusin', handleFocusIn);

      if (withCheckCaretOverlap) {
        const activeInput = getActiveInput();
        if (activeInput) {
          activeInput.classList.remove(CLASS_NAME);
        }
      }

      debouncedRestoreOpacity.cancel();
    };
  }, [ref, updateCursorVisibility, withCheckCaretOverlap]);
};

export default useElementPositionAboveKeyboard;
