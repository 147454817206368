import {TRACKING_ID} from '../constants/babcia';
import babciaDebugLog from './babciaDebugLog';
import {Type, Category} from '../constants/babciaDataTypes';

type FormatDataParams = {
  event: string;
  category?: Category;
  label: string | number;
  value?: number;
  type?: Type;
  userId?: string;
};

type FormattedData = {
  type: Type;
  category: Category;
  label: string;
  event: string;
  value: string;
  userIdentities?: string[];
};

const formatData = ({
  event,
  category = Category.TIMING,
  label,
  value,
  type = Type.SESSION,
  userId,
}: FormatDataParams): FormattedData => {
  const result: FormattedData = {
    type,
    category,
    label: String(label),
    event,
    value: String(value || new Date().toISOString()),
  };

  if (userId) {
    result.userIdentities = [userId];
  }

  return result;
};

/**
 * Function to send performance events to the Babcia
 * @param data - The data to push
 */
const sendPerformanceEventToBabcia = (data: FormatDataParams): void => {
  if (window[TRACKING_ID.PERFORMANCE]) {
    const formatted = formatData(data);

    window[TRACKING_ID.PERFORMANCE].push(formatted);

    babciaDebugLog(formatted);
  }
};

export default sendPerformanceEventToBabcia;
