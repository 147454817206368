import type {PackageDataFragment} from '@core/payment/widgets/package/graphql/fragments/package';

export type PurchasedPackage = Partial<PackageDataFragment> & {
  paymentMethod: string;
  method?: string;
};

export type CachedPurchasedPackage = PurchasedPackage | null;

/**
 * Cache payment package
 * Used for cache purchased package and get data after payment.
 */
let purchasedPackage: CachedPurchasedPackage = null;

const cachedPurchasedPackage = {
  /**
   * Get saved purchased package.
   */
  get(): CachedPurchasedPackage {
    return purchasedPackage;
  },

  /**
   * Set saved purchased package.
   */
  set(paymentPackage: CachedPurchasedPackage) {
    purchasedPackage = paymentPackage;
  },

  clear() {
    this.set(null);
  },
};

export default cachedPurchasedPackage;
