/**
 * Dictionary of service names for front-end tracking
 * Params for debug, allowed request, backend options
 */

export const CTR = {
  METHOD: 'ctrTracking',
  TYPES: {
    SEARCH_PHOTO: 'search_photo_view',
  },
} as const;

export const RM_POPUP = {
  METHOD: 'rmPopupTracking',
  TYPES: {},
};

export const MEDIA_UPLOAD_ERROR = {
  METHOD: 'mediaUploadErrorTracking',
  TYPES: {},
};

export const TRACK_PAYMENT_PENDING_PAGE_STEP = {
  METHOD: 'trackPaymentPendingPageStep',
  TYPES: {},
};
