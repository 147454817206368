import once from 'lodash/once';

import {getClientInstance} from '@core/graphql/client';

import type {DisapprovePhotoSubscription} from '../graphql/subscriptions/disapprovePhoto';
import PHOTO_DISAPPROVE_SUBSCRIPTION from '../graphql/subscriptions/disapprovePhoto.gql';

const getDisapprovePhotoSubscription = once(() =>
  getClientInstance().subscribe<DisapprovePhotoSubscription>({
    query: PHOTO_DISAPPROVE_SUBSCRIPTION,
  }),
);

export default getDisapprovePhotoSubscription;
