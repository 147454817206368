import type {FC} from 'react';
import React from 'react';
import cn from 'classnames';

import type {CSSModule} from '../../types';
import ProgressType from '../../constants/ProgressType';
import baseCss from './BarProgress.css';

export interface BarProgressProps {
  percentage?: number;
  className?: string;
  barClassName?: string;
  type?: ProgressType;
  inverse?: boolean;
}

const DEFAULT_CSS: CSSModule = {};

const BarProgress: FC<
  // `BarProgressProps` without `css` inside to make it more suitable for `@phoenix/ui`.
  BarProgressProps & {
    // Styles are passed as props and mixed with own styles. Also is not required since we can use no extra styles
    css?: CSSModule;
  }
> = ({
  percentage = 0,
  type = ProgressType.SUCCESS,
  className,
  barClassName,
  inverse,
  css = DEFAULT_CSS,
}) => (
  <div
    className={cn(
      baseCss.wrapper,
      css.wrapper,
      inverse && baseCss.inverse,
      className,
    )}
  >
    <div
      className={cn(baseCss.bar, barClassName, baseCss[type])}
      style={{width: `${percentage}%`}}
    />
  </div>
);

export default BarProgress;
