import type {UAParserInstance} from 'ua-parser-js';
import UserAgentParser from 'ua-parser-js';

let userAgentParser: UAParserInstance;

const getUserAgentParser = (): UAParserInstance => {
  if (SERVER_ENVIRONMENT) {
    const mock = new Proxy({}, {get: () => () => mock});

    // hard cast to UAParserInstance to hide proxying of it in SSR/test environments
    return mock as UAParserInstance;
  }

  userAgentParser =
    userAgentParser || new UserAgentParser(window.navigator.userAgent);

  return userAgentParser;
};

export default getUserAgentParser;
