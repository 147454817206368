import isObject from 'lodash/isObject';

import URI from '@core/utils/url';
import getHistory from '@core/application/utils/getHistory';
import clearUrl from '@core/utils/url/clearUrl';

import type {PaymentProcessStep} from '../../types/paymentProcess';
import {PaymentFlowStatus} from '../../utils/getProcessPaymentFlow';

const goToNextStepOnDecline: PaymentProcessStep = async (data) => {
  const {paymentResult} = data;
  const {failPageLogic, redirectUrl, urlParams} = paymentResult;

  if (failPageLogic && redirectUrl) {
    let url = redirectUrl;

    if (isObject(urlParams)) {
      const uri = URI(url);

      url = uri.addSearch(urlParams).toString();
    }

    getHistory().push(clearUrl(url));

    return {...data, flowStatus: PaymentFlowStatus.ABORTED};
  }

  return data;
};

export default goToNextStepOnDecline;
