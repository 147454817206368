/**
 * Indicate place where we must show captcha
 */
export const PROTECT_TYPES = {
  VIEW_PROFILE_BAN: 'viewProfile',
  IP_IS_BANNED: 'registration',
  PASSWORD_RECOVERY: 'passwordRecovery',
  /**
   * Protect type without reason (s.u. dnsmpi popup)
   * @type {string}
   */
  ON_PAGE_PROTECT_TYPE: 'onPage',
};
