import React from 'react';
import PropTypes from 'prop-types';

import PAYMENT_METHODS from '../../../common/constants/paymentMethods';
import getFormattedPrice from '../../../common/utils/getFormattedPrice';
import usePaymentAdditionalPackage from '../../additionalPackage/utils/usePaymentAdditionalPackage';
import useActivePackage from '../../package/utils/useActivePackage';

const PaymentDetails = ({layout: PaymentDetailsLayout, ...props}) => {
  const {activePackage} = useActivePackage();
  const {additionalPackages} = usePaymentAdditionalPackage();

  const isPayGarden =
    activePackage?.paymentMethod === PAYMENT_METHODS.PAYGARDEN;

  if (!activePackage || !additionalPackages || isPayGarden) {
    return null;
  }

  const {
    packageName,
    packagePricingDetails: {packagePriceData, packageDiscountPriceData},
  } = activePackage;

  const {fullPrice} = packageDiscountPriceData || packagePriceData;

  return (
    <PaymentDetailsLayout
      price={getFormattedPrice(fullPrice)}
      packageName={packageName}
      mutedText
      {...props}
    />
  );
};

PaymentDetails.propTypes /* remove-proptypes */ = {
  layout: PropTypes.func,
};

export default PaymentDetails;
