import React from 'react';
import PropTypes from 'prop-types';
import isBoolean from 'lodash/isBoolean';
import {Field, getIn} from 'formik';

import componentPropType from '@core/propTypes/component';

/**
 * Wrapper around checkbox widget to make it working without
 * passing additional props with 'Formik' forms
 */
const FormCheckbox = ({
  validate,
  name,
  error,
  checkboxComponent: Checkbox,
  onChange: onChangeProps,
  ...props
}) => (
  <Field name={name} validate={validate}>
    {({field, form}) => {
      const {value, onChange: onChangeOther, ...other} = field;
      return (
        // eslint-disable-next-line local-rules/tracking-name -- `name` passed within `...other`
        <Checkbox
          {...other}
          {...props}
          onChange={onChangeProps || onChangeOther}
          value={value}
          checked={isBoolean(value) ? value : value === 'true'}
          error={
            error ||
            (getIn(form.touched, name) &&
              form.errors &&
              getIn(form.errors, name))
          }
        />
      );
    }}
  </Field>
);

FormCheckbox.propTypes /* remove-proptypes */ = {
  validate: PropTypes.func,
  name: PropTypes.string.isRequired,
  checkboxComponent: componentPropType.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func,
};

export default FormCheckbox;
