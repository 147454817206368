/**
 * Structure of websocket push action when user deletes conversation in messenger
 */
export default {
  DeleteConversationMutation: {
    toServer: (params) => ({method: 'deleteConversation', params}),

    fromServer: (ids = [], error = null) => ({
      data: {
        deleteConversation: {
          ids,
          error,
          __typename: 'DeleteConversationMutationResult',
        },
      },
    }),
  },
};
