import type {FC, ComponentType} from 'react';
import React, {Fragment} from 'react';
import cn from 'classnames';
import isFunction from 'lodash/isFunction';

import type {ButtonProps} from '../button/Button';
import {Icon} from '../icon';
import type {PopoverProps} from './Popover';
import Popover from './Popover';
import css from './ClosablePopover.css';

export type ClosablePopoverProps = PopoverProps & {
  buttonComponent: ComponentType<ButtonProps>;
  onCloseClick?: () => void;
};

/**
 * @class ClosablePopover
 * @classdesc ClosablePopover helper class to add widgets on page
 */
const ClosablePopover: FC<ClosablePopoverProps> = ({
  buttonComponent: Button,
  children,
  content,
  className,
  trackingName,
  onCloseClick,
  ...props
}) => (
  <Popover
    {...props}
    trackingName={trackingName}
    spaced={false}
    className={cn(css.popover, className)}
    content={({inverse}) => (
      <Fragment>
        <Button
          round
          icon={<Icon muted type="cross" inverse={inverse} />}
          inverse={inverse}
          className={css.close}
          onClick={onCloseClick}
          trackingName="close"
          data-close-popper
        />
        {isFunction(content) ? content({inverse}) : content}
      </Fragment>
    )}
  >
    {children}
  </Popover>
);

export default ClosablePopover;
