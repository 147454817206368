import routeVar from '@core/graphql/vars/routeVar';
import isInRouteList from '@core/utils/routing/isInRouteList';

import DISABLED_ROUTES from '../../mini/constants/disabledRoutes';

/**
 * Check if route is disabled for mini messenger
 * if argument is empty get current route
 * @param route
 * @return {boolean}
 */
const isRouteDisabledForMiniMessenger = (route) =>
  isInRouteList(DISABLED_ROUTES, route || routeVar().current);

export default isRouteDisabledForMiniMessenger;
