import {MSG} from '../constants/socketEventNames';

export default {
  StatusApproveSubscription: {
    incomingEvents: `${MSG}adminApproveTextAttributechat_up_line`,

    fromServer: ({status}) => ({
      data: {
        disapprove: {
          // Just convert 'declined' and 'approved' word to boolean.
          textStatus: status === 'declined',
          __typename: 'DisapproveStatuses',
        },
      },
    }),
  },
};
