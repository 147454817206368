import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import memoize from 'lodash/memoize';

import {decodeLink, isHashed} from '@core/application/utils/hashedStaticLinks';

const STORY_ROUTE = '/story';

type CreateRouteNameParams = string | null;

const createRouteName = memoize(
  (value: CreateRouteNameParams): string | null => {
    if (!value) {
      return null;
    }

    let url: string;

    if (value === '/') {
      url = 'index';

      // Check if url is absolute or relative
    } else if (/^https?:\/\//i.test(value)) {
      url = new URL(value).hostname;
      /**
       * Check url length for detect story view page.
       * Story ID has 32 characters, '/story' path has 6 characters,
       * so if the total number of characters is more than 38, then this is the story view page.
       */
    } else if (
      value.startsWith(STORY_ROUTE) &&
      value.length > 32 + STORY_ROUTE.length
    ) {
      url = 'storyView';
    } else {
      const link = decodeLink(value);
      const str = isHashed(link) ? link : value;

      // We only take the first two parts of the url for tracking.
      const indexSearch = str.indexOf('?');

      url = str
        .slice(0, indexSearch < 0 ? str.length : indexSearch)
        .split('/')
        .filter(Boolean)
        .slice(0, 2)
        .join('_');
    }

    return upperFirst(camelCase(url));
  },
);

export default createRouteName;
