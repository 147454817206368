import type {ApolloError} from '@apollo/client';
import {useQuery} from '@apollo/client';

import usePaymentParams from '../../../common/utils/usePaymentParams';
import PAYMENT_DNSMPI_QUERY from '../graphql/queries/paymentDNSMPI.gql';
import type {
  PaymentDnsmpiQuery,
  PaymentDnsmpiQueryVariables,
} from '../graphql/queries/paymentDNSMPI';

type UsePaymentDNSMPIResult = {
  dnsmpiLink: string;
  error?: ApolloError;
  loading: boolean;
};

const usePaymentDNSMPI = (): UsePaymentDNSMPIResult => {
  const {action, via, prevVia, source} = usePaymentParams();

  const {data, error, loading} = useQuery<
    PaymentDnsmpiQuery,
    PaymentDnsmpiQueryVariables
  >(PAYMENT_DNSMPI_QUERY, {
    variables: {
      action,
      via,
      prevVia,
      source,
    },
  });

  if (loading || error) {
    return {
      dnsmpiLink: null,
      error,
      loading,
    };
  }

  return {
    dnsmpiLink: data?.payment?.paymentFooter?.blockDataMap?.dnsmpiLink || null,
    error,
    loading,
  };
};

export default usePaymentDNSMPI;
