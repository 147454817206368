import {useEffect} from 'react';

import getUserAgentParser from '@core/utils/getUserAgentParser';

/**
 * Dirty hack for fix issue in chrome: https://bugs.chromium.org/p/chromium/issues/detail?id=916563. Fix from:
 * https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome
 */
export default function useVideoPreviewDurationFix(ref) {
  return useEffect(() => {
    if (getUserAgentParser().getBrowser().name !== 'Chrome' || !ref.current) {
      return () => {};
    }

    const media = ref.current;

    const handleTimeUpdate = () => {
      media.removeEventListener('timeupdate', handleTimeUpdate);
      // setting player currentTime back to 0 can be buggy too, set it first to .1 sec
      media.currentTime = 0.1;
      media.currentTime = 0;
      // media.duration should now have its correct value, return it...
    };

    const handleLoadedMetadata = () => {
      media.removeEventListener('loadedmetadata', handleLoadedMetadata);
      media.currentTime = Number.MAX_SAFE_INTEGER;
      // listen to time position change
      media.addEventListener('timeupdate', handleTimeUpdate);
    };

    media.addEventListener('loadedmetadata', handleLoadedMetadata);

    return () => {
      media.removeEventListener('loadedmetadata', handleLoadedMetadata);
      media.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [ref]);
}
