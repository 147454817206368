import PROCESSING_STATUS from '@core/payment/common/constants/processingStatus';

import createDefaults from '../utils/createDefaults';

export default {
  Query: {
    fields: createDefaults({
      payment: {__typename: 'Payment'},
    }),
  },
  Payment: {
    keyFields: [],
    fields: createDefaults({
      isAdditionalTermsChecked: false,
      isAdditionalPackageRepeated: null,
      isOneClickUsed: false,
      // @see processingStatus.js
      processingStatus: PROCESSING_STATUS.INITIAL,
      pageEnterTime: null,
      payAnswer: {__typename: 'PayAnswer'},
    }),
  },
  PayAnswer: {
    fields: createDefaults({
      status: null,
      failPageLogic: null,
      failReason: null,
      isEmptyFormAfterDecline: null,
      declineMessageType: null,
      declineScreenTemplate: null,
      boughtPackageTitle: null,
      orderId: null,
    }),
  },
  AlertBanner: {
    keyFields: [],
  },
  Card: {
    keyFields: [],
  },
  LongFormFields: {
    keyFields: ['anonymousAutoFillFields', 'longFormFields'],
  },
  TemplateSettings: {
    keyFields: [],
  },
  BannerData: {
    keyFields: [],
  },
};
