import {MSG} from '../constants/socketEventNames';

export default {
  ChangeDebtBalanceSubscription: {
    incomingEvents: [`${MSG}amountOfDebtChanged`],

    fromServer: ({debtBalance}) => ({
      data: {
        credits: {
          debtBalance,
          __typename: 'Credits',
        },
      },
    }),
  },
};
