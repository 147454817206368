import {useMemo} from 'react';
import type {ApolloError} from '@apollo/client';
import {useQuery} from '@apollo/client';

import type {AltMethodsScenario, Method} from '@core/types/graphql';
import {AltMethodScenarioEnum} from '@core/types/graphql';

import usePaymentParams from '../../../common/utils/usePaymentParams';
import ALT_METHOD_SCENARIO_QUERY from '../graphql/queries/altMethodScenarioQuery.gql';
import type {
  AltMethodScenarioQuery,
  AltMethodScenarioQueryVariables,
} from '../graphql/queries/altMethodScenarioQuery';

type UseAltOneClickResult = {
  loading: boolean;
  isAltOneClick: boolean;
  error?: ApolloError;
};

const useAltOneClick = (paymentMethod: Method): UseAltOneClickResult => {
  const {action, prevVia, via} = usePaymentParams();

  const {data, loading, error} = useQuery<
    AltMethodScenarioQuery,
    AltMethodScenarioQueryVariables
  >(ALT_METHOD_SCENARIO_QUERY, {
    variables: {
      via,
      action,
      prevVia,
    },
    skip: !paymentMethod,
  });

  const isAltOneClick = useMemo(() => {
    const altMethodsScenario: AltMethodsScenario =
      data?.payment?.packagesData?.altMethodsScenario?.find(
        ({method}) => method === paymentMethod,
      );

    return altMethodsScenario?.scenario === AltMethodScenarioEnum.oneClick;
  }, [data, paymentMethod]);

  return {
    loading,
    isAltOneClick,
    error,
  };
};

export default useAltOneClick;
