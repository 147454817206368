/**
 * Query limit in counters
 * @type {number}
 * TODO: Limit must be 31, but core sends to us not uniq activities. Change to 31
 * @see removeRepeatingActivities in parseForProvider
 */
export const LIMIT_FETCH_ACTIVITIES = 45;

/**
 * Max number of activities in counters
 * @type {number}
 */
export const MAX_SHOW_ACTIVITIES = 30;
