import once from 'lodash/once';

import {getClientInstance} from '@core/graphql/client';

import type {ConvertedVideoSubscription} from '../graphql/subscriptions/convertedVideo';
import CONVERTED_VIDEO_SUBSCRIPTION from '../graphql/subscriptions/convertedVideo.gql';

const getConvertVideoSubscription = once(() =>
  getClientInstance().subscribe<ConvertedVideoSubscription>({
    query: CONVERTED_VIDEO_SUBSCRIPTION,
  }),
);

export default getConvertVideoSubscription;
