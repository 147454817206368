import type {FC} from 'react';
import React from 'react';

import SpacingSize from '../../constants/SpacingSize';
import type {GroupProps} from './Group';
import Group from './Group';

const InlineGroup: FC<GroupProps> = (props) => (
  <Group inline space={SpacingSize.SHORTEST} {...props} />
);

export default InlineGroup;
