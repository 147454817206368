import trackRedirectTo from '@core/tracking/babcia/utils/trackRedirectTo';

/**
 * Utility function used to, avoiding any router, make redirects.
 * Usefull for opening some links as blank page
 *
 * Example use:
 *
 * gotoUrl(url, true);
 * gotoUrl(url, true, '_blank');
 * gotoUrl(url, true, 'windowName');
 * gotoUrl(url, true, 'windowName', 'left=100,top=100,width=320,height=320');
 *
 * @param {String} url
 * @param {Boolean} blank
 * @returns {Object||null}
 */
const gotoUrl = (url, blank = false, ...windowOpenOptions) => {
  if (blank) {
    const options = [url];

    if (windowOpenOptions?.length) {
      options.push(...windowOpenOptions);
    }

    // Browser may block opening new windows if it causes not by a user's action
    const newTab = window.open(...options);

    if (newTab) {
      trackRedirectTo({nextPathname: url});
    }

    return newTab;
  }

  trackRedirectTo({nextPathname: url});

  window.location.href = url;

  return null;
};

export default gotoUrl;
