import isMiniMessengerAllowed from './isMiniMessengerAllowed';
import isRouteDisabledForMiniMessenger from './isRouteDisabledForMiniMessenger';

/**
 * Checks if the Mini Messenger can be opened for the given route.
 *
 * @param {string} [route] - The route to check.
 * @returns {Promise<boolean>} - Returns a Promise that resolves to a boolean indicating if the Mini Messenger can be opened.
 */
const canOpenMiniMessenger = async (route) => {
  if (isRouteDisabledForMiniMessenger(route)) {
    return false;
  }

  return isMiniMessengerAllowed();
};

export default canOpenMiniMessenger;
