/**
 * Type of tag (or arrow, since arrow is a tag in a nutshell)
 * @see Tag.tsx
 */
enum TagType {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  ACCENT = 'accent',
  SUCCESS = 'success',
  DANGER = 'danger',
  TRANSLUCENT = 'translucent', // Not used in arrow
}

export default TagType;
