import {createContext} from 'react';

import {getFilteredActivitiesShape} from '../utils/parseForProvider';

/**
 * Create context for activity functionality
 * @see https://reactjs.org/docs/context.html
 */
const Context = createContext({
  ...getFilteredActivitiesShape(),
  loading: true,
  dateFormat: null,
});

export default Context;
