import ACTIVITIES_QUERY_TYPES from '@core/activity/constants/activitiesQueryTypes';

/**
 * Used to merge separated old and new activities together.
 * @param {Object} activities
 * @param {Array} activities.allActivities
 * @param {Array} newActivities
 * @returns {Object} result in format "{allActivities: []}"
 */
const mergeActivities = (activities, newActivities) => {
  if (!newActivities || !activities.allActivities) {
    return activities;
  }

  const newAllActivities = [
    ...activities.allActivities,
    ...newActivities.filter(
      (newActivity) =>
        !activities.allActivities.some((item) => item.id === newActivity.id),
    ),
  ].map((item) => {
    const activity = {
      ...item,
      direction: item.direction || ACTIVITIES_QUERY_TYPES.INCOME,
    };

    const newActivity = newActivities.find(
      (newItem) => newItem.id === activity.id,
    );
    // Just attach new activities to centralized store with duplicates removing
    return newActivity ? {...activity, ...newActivity} : activity;
  });

  // Create a copy of prev result because it calls error 'Cannot assign to read only property'
  return {...activities, allActivities: newAllActivities};
};

export default mergeActivities;
