const getMoneyBackDescriptor = (data) => {
  if (!data) {
    return '';
  }

  const {descriptorData, hideDescriptor} = data;

  if (hideDescriptor || !descriptorData) {
    return '';
  }

  const companyName = descriptorData.companyName
    ? ` ${descriptorData.companyName}`
    : '';

  return `${descriptorData.descriptor}${companyName}`;
};

export default getMoneyBackDescriptor;
