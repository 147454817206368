import {createContext} from 'react';

import {Category, EventType} from '../constants/babciaDataTypes';

type BabciaScopedContextState = {
  getElementIndex: (element: unknown, objectName: string) => number;
  setElement: (element: unknown, objectName: string) => void;
  deleteElement: (element?: unknown, objectName?: string) => void;
  trackingLabel: string;
  trackingIndex?: string;
  pathname?: string;
  context: string;
  category: Category;
  conceptId: string;
  eventType: EventType;
  skip?: boolean;
};

/**
 * Create context for Babcia
 */
const BabciaScopedContext = createContext<BabciaScopedContextState>({
  getElementIndex: () => 0,
  setElement: () => {},
  deleteElement: () => {},
  trackingLabel: '',
  trackingIndex: '',
  pathname: '',
  context: '',
  category: Category.PAGE,
  conceptId: '',
  eventType: EventType.MAIN,
  skip: false,
});

export default BabciaScopedContext;
