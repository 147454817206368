import {offsetLimitPagination} from '@apollo/client/utilities';

import {mergeArrayField as merge} from '../utils/mergeArrayField';

/**
 * TODO: Move this logic to server:
 *    ticketsLeft.female=null for gay events
 *    ticketsLeft.male=null for lesbian events
 */
const Event = {
  fields: {
    ticketsLeft: {
      read(data, {readField}) {
        const eventOrientation = readField('eventOrientations');

        if (eventOrientation === 'GAY') {
          return {...data, female: null};
        }

        if (eventOrientation === 'LESBIAN') {
          return {...data, male: null};
        }

        return data;
      },
    },
  },
};

export default {
  SpeedDating: {
    keyFields: [],
    fields: {
      photoAlbums: offsetLimitPagination(),
      videos: offsetLimitPagination(),
      successStories: offsetLimitPagination(),
      reviews: offsetLimitPagination(),
      searchEvents: {
        keyArgs: ['params'],
        merge,
      },
    },
  },
  SDEvent: Event,
  BasketEvent: Event,

  // To avoid dangerous cache update
  BasketEvents: {keyFields: false},
  BasketTotalPrice: {keyFields: false},
  Currency: {keyFields: false},
};
