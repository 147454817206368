enum MediaPreviewAppearance {
  /**
   * Naughty blurred media preview cap with scattered content around play button
   */
  BLURRED_WITH_SCATTERED_CONTENT = 'blurredWithScatteredContent',

  /**
   * Naughty blurred media preview cap without button and with centered content under preview icon
   */
  BLURRED_WITHOUT_BUTTON = 'blurredWithoutButton',
}

export default MediaPreviewAppearance;
