import showFailFormVar from '@core/graphql/vars/showFailFormVar';

import type {PaymentProcessStep} from '../../types/paymentProcess';

/**
 * This method fixes bug in FE-39703
 * Fail form changes placement and closes itself by useEffect logic
 *   useEffect(() => {
 *     return () => {
 *       showFailFormVar(false);
 *   };
 *  }, []);
 * @TODO: pass declineScreenTemplate from pay/pay response on RM popup to url.
 * Fail form placement should not be changed.
 */
const closeFailForm: PaymentProcessStep = async (data) => {
  showFailFormVar(false);

  return data;
};

export default closeFailForm;
