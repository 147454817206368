import {UNKNOWN_VIA} from '../constants/vias';
import PAYMENT_ACTIONS from '../constants/paymentActions';
import generatePayUrl from './generatePayUrl';

type GeneratePayUrlForCoinsParams = {
  via?: string;
  stage?: string;
  returnPath?: string | null;
};

/**
 * Generate a payment URL for coins.
 */
const generatePayUrlForCoins = ({
  via = UNKNOWN_VIA,
  returnPath = null,
  stage = PAYMENT_ACTIONS.CREDIT,
}: GeneratePayUrlForCoinsParams): string => {
  return generatePayUrl({
    stage,
    accountStatus: {
      isPaid: true,
      notBoughtAccountFeatures: [],
    },
    urlParams: {
      via,
      returnPath,
      skipStepOnBack: Boolean(returnPath),
    },
  });
};

export default generatePayUrlForCoins;
