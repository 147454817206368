/**
 * Map of actions with history.
 * If we call replace() function in history change listener callback will pass argument action === 'replace'
 */
export enum Action {
  REPLACE = 'REPLACE',
  PUSH = 'PUSH',
  POP = 'POP',
}

/**
 * Constant to write value in history state to indicate that current page is unknown.
 * Use to set current page in
 * @see getHistory
 * @see LegacyRouter
 * @see parsePathname
 *
 * We redirect on the same page with
 * @see NotFound
 */
export const UNKNOWN_PAGE_STATE_KEY = 'unknownPage';

/**
 * Use to write current page in apollo cache route info and legacy router
 * @see LegacyRouter
 * @see getHistory
 */
export const UNKNOWN_PAGE_NAME = 'notFound';
