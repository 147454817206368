import {useEffect, useMemo} from 'react';
import {useQuery, useReactiveVar} from '@apollo/client';
import isUndefined from 'lodash/isUndefined';

import isOneClickUsedVar from '@core/graphql/vars/isOneClickUsedVar';
import isOneClickAllowedVar from '@core/graphql/vars/isOneClickAllowedVar';
import paymentBinsInfoShare$ from '@core/payment/forms/card/utils/paymentBinsInfoShare';
import activeMethodTabVar from '@core/graphql/vars/activeMethodTabVar';

import usePaymentParams from '../../../common/utils/usePaymentParams';
import ONE_CLICK_PARAMS_QUERY from '../graphql/queries/oneClickParams.gql';
import PAYMENT_METHODS from '../../../common/constants/paymentMethods';
import PaymentOneClickFlows from '../../../common/constants/paymentOneclickFlows';
import isCardPaymentType from './isCardPaymentType';

export const setOneClickUsed = (isUsed) => {
  isOneClickUsedVar(isUsed);
  if (!isUsed) {
    paymentBinsInfoShare$.setDataAndNotify({});
  }
};

/**
 * Hook for check if oneClick is allowed and get additional oneClick params for displaying card form or oneClick card information
 * @param {Boolean} skip
 * @returns isOneClickAllowed - is allowed one click features
 * @returns oneClickCardId - unique card number id
 * @returns oneClickCardNumber - one click card number ('5111********1118')
 * @returns setOneClickUsed - method for change isOneClickUsed flag
 * @returns isOneClickUsed - flag for notice about shown/hidden card form
 * @returns {{oneClickCardNumber: string, isOneClickAllowed: boolean, setOneClickUsed: ((function(*): void)|*), oneClickCardId: string, loading: boolean, isOneClickUsed: boolean}}
 */
const useOneClick = ({skip = false} = {}) => {
  const {via, source} = usePaymentParams();
  const isCardMethod = isCardPaymentType(
    useReactiveVar(activeMethodTabVar) || PAYMENT_METHODS.CARD,
  );
  const isOneClickUsed = useReactiveVar(isOneClickUsedVar) && isCardMethod;

  const {data, loading} = useQuery(ONE_CLICK_PARAMS_QUERY, {
    variables: {source, via},
    skip,
  });

  const isOneClickAllowed = data?.payment?.isOneClickAllowed && isCardMethod;

  useEffect(() => {
    if (!loading && !isUndefined(isOneClickAllowed)) {
      isOneClickAllowedVar(isOneClickAllowed);
    }
  }, [isOneClickAllowed, loading]);

  const oneClickCardId = data?.payment?.card?.cardId;
  const bin = Number(data?.payment.card?.bin);
  const oneClickCardNumber = data?.payment?.card?.number;
  const oneClickFlow = isOneClickAllowed
    ? data?.payment.card?.oneClickFlow || PaymentOneClickFlows.SIMPLE
    : null;
  const maskedCardHolderName = data?.payment.card?.maskedCardHolderName;

  useEffect(() => {
    if (!data?.payment.card) {
      return;
    }

    if (isOneClickUsed) {
      const {debitType, financialServicePlatform, cardTypeLogo} =
        data.payment.card;
      paymentBinsInfoShare$.setDataAndNotify({
        bin,
        cardTypeLogo,
        debitType,
        financialServicePlatform,
      });
    }
  }, [isOneClickUsed, data, bin]);

  return useMemo(() => {
    return {
      loading,
      oneClickCardNumber,
      bin,
      oneClickCardId,
      isOneClickAllowed,
      isOneClickUsed,
      setOneClickUsed,
      oneClickFlow,
      maskedCardHolderName,
    };
  }, [
    loading,
    oneClickCardNumber,
    bin,
    oneClickCardId,
    isOneClickAllowed,
    isOneClickUsed,
    oneClickFlow,
    maskedCardHolderName,
  ]);
};

export default useOneClick;
