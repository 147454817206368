import {useState, useEffect} from 'react';
import type {ApolloError} from '@apollo/client';
import {useQuery} from '@apollo/client';

import {getCookie} from '@core/utils/cookie';

import PAYMENT_METHODS from '../../../common/constants/paymentMethods';
import useAltMethodsSettings from '../../../common/utils/useAltMethodsSettings';
import WALLET_PAY_USER_DATA_QUERY from '../../../common/graphql/queries/walletPayUserData.gql';
import type {WalletPayUserDataQuery} from '../../../common/graphql/queries/walletPayUserData';
import type {GooglePaySettingsFragment} from '../../../common/graphql/fragments/googlePaySettings';
import {GOOGLE_PAY_TEST_ENVIRONMENT} from '../../../common/constants/walletVisualTestCookies';
import useActivePackage from '../../package/utils/useActivePackage';
import {TEST_METHOD_SETTINGS} from '../constants/googlePay';
import getGooglePayPaymentsClient from './getGooglePayPaymentsClient';

type UseGooglePaySettingsProps = {
  loading: boolean;
  error: ApolloError | null;
  settings: GooglePaySettingsFragment;
  country: string;
  siteName: string;
  userId: string;
  canMakeGooglePayPayments: boolean;
};

/**
 * Fetch Google Pay settings
 */
export default function useGooglePaySettings(): UseGooglePaySettingsProps {
  const {activePackage} = useActivePackage();
  const [googlePayClient, setGooglePayClient] = useState(null);
  const {
    settings: altMethodSetting,
    loading,
    error,
  } = useAltMethodsSettings({
    paymentMethod: PAYMENT_METHODS.GOOGLE_PAY,
  });

  const {
    data: userData,
    loading: userDataLoading,
    error: userDataError,
  } = useQuery<WalletPayUserDataQuery>(WALLET_PAY_USER_DATA_QUERY);

  let country = null;
  let name = null;
  let id = null;
  const gPayTestViewButton = getCookie(GOOGLE_PAY_TEST_ENVIRONMENT);
  const settings = gPayTestViewButton ? TEST_METHOD_SETTINGS : altMethodSetting;

  if (userData) {
    ({
      myUser: {
        profile: {
          id,
          location: {country},
        },
      },
      site: {name},
    } = userData);
  }

  useEffect(() => {
    if (settings) {
      getGooglePayPaymentsClient(settings).then((client) =>
        setGooglePayClient(client || false),
      );
    }
  }, [settings]);

  return {
    loading:
      loading ||
      userDataLoading ||
      Boolean(settings && googlePayClient === null),
    error: error || userDataError,
    settings,
    country,
    siteName: name,
    userId: id,
    canMakeGooglePayPayments: Boolean(
      (settings && googlePayClient && activePackage?.tokenPrice) ||
        gPayTestViewButton,
    ),
  };
}
