import isEqual from 'lodash/isEqual';
import first from 'lodash/first';

import BaseTracker from '@core/tracking/base/BaseTracker';

import TRACK_PAYMENT_PAGE_DESCRIPTOR from '../graphql/mutations/trackPaymentPageDescriptor.gql';

/**
 * Payment descriptor track types
 * @type {{TRANSACTION: string, DISPLAY: string}}
 */
const PAYMENT_DESCRIPTOR_TRACK_TYPES = {
  DISPLAY: 'trackPaymentPageDescriptor', // when descriptor displaying on page
  TRANSACTION: 'trackPaymentPageDescriptorTransaction', // after success transaction
};

const cachedTrackers = {};

/**
 * Convert data to tracking friendly format for backend
 * @param {Array} dataList
 * @return {Promise}
 */
const prepareData = async (dataList) => first(dataList);

/**
 * Create and cache tracker for different track types
 * @param trackName
 * @return {*|BaseTracker}
 */
const getTracker = (trackName) => {
  const tracker = cachedTrackers[trackName];

  if (!tracker) {
    const newTracker = new BaseTracker(
      TRACK_PAYMENT_PAGE_DESCRIPTOR,
      prepareData,
      trackName,
    );

    cachedTrackers[trackName] = newTracker;

    return newTracker;
  }

  return tracker;
};

const trackData = ({
  descriptor = null,
  descriptorText = null,
  orderId,
} = {}) => {
  const trackName = orderId
    ? PAYMENT_DESCRIPTOR_TRACK_TYPES.TRANSACTION
    : PAYMENT_DESCRIPTOR_TRACK_TYPES.DISPLAY;

  const tracker = getTracker(trackName);

  if (tracker) {
    tracker.track(
      {descriptor, descriptorText, method: trackName, orderId},
      true,
    );
  }
};

/**
 * Function for track descriptor only once with same parameters
 * @return {(function(*): void)|*}
 */
const createTrackPaymentDescriptor = () => {
  const calledParams = [];

  return (params) => {
    if (!calledParams.some((cachedParams) => isEqual(cachedParams, params))) {
      calledParams.push(params);
      trackData(params);
    }
  };
};

const trackPaymentDescriptor = createTrackPaymentDescriptor();

export default trackPaymentDescriptor;
