import type {ReactNode} from 'react';
import React, {forwardRef} from 'react';
import isFunction from 'lodash/isFunction';
import cn from 'classnames';

import type {CSSModule} from '../../types';
import ProgressType from '../../constants/ProgressType';
import baseCss from './CommonProgress.css';

export interface CommonProgressRenderProps {
  inverse: boolean;
  strokeWidth: number;
  percentage: number;
  rotate: boolean;
  startingPoint: number;
  linearProgression: boolean;
}

export interface CommonProgressProps {
  inverse?: boolean;
  strokeWidth?: number;
  percentage?: number;
  /**
   * Rotate or not. Actual only for "cirlce" progress on "material" theme.
   */
  rotate?: boolean;
  /**
   * Determines start position on the circle in degrees (0 - 360deg). Actial for "circle" progress on "material" theme.
   */
  startingPoint?: number;
  linearProgression?: boolean;
  inherit?: boolean;
  content: (renderProps: CommonProgressRenderProps) => ReactNode;
  children?: ReactNode | ((percentage: number) => ReactNode);
  round?: boolean;
  withOverlay?: boolean;
  withPercentage?: boolean;
  fixedSize?: boolean;
  css: CSSModule;
  /**
   * Progress color modification
   */
  type?: ProgressType;
  className?: string;
  clockwise?: boolean;
}

const CommonProgress = forwardRef<HTMLDivElement, CommonProgressProps>(
  (
    {
      percentage = 0,
      strokeWidth = 2,
      inverse,
      inherit,
      content,
      children,
      rotate = true,
      startingPoint = 90,
      withOverlay = true,
      withPercentage = true,
      fixedSize = true,
      round = false,
      linearProgression = false,
      css,
      type = ProgressType.PRIMARY,
      className,
      clockwise = true,
    },
    ref,
  ) => (
    <div
      ref={ref}
      className={cn(
        className,
        baseCss.progress,
        css[type],
        baseCss[type],
        withOverlay && cn(baseCss.withOverlay, css.withOverlay),
        inverse && cn(baseCss.inverse, css.inverse),
        inherit && cn(baseCss.inherit, css.inherit),
        round && baseCss.round,
      )}
    >
      <div className={cn(baseCss.container, fixedSize && baseCss.fixedSize)}>
        {content({
          inverse,
          strokeWidth,
          percentage: clockwise ? percentage : -percentage,
          rotate,
          startingPoint,
          linearProgression,
        })}
      </div>
      {children ? (
        <div className={cn(baseCss.percentage, css.percentage)}>
          {isFunction(children) ? children(percentage) : children}
        </div>
      ) : (
        withPercentage && (
          <div className={cn(baseCss.percentage, css.percentage)}>
            {`${percentage}%`}
          </div>
        )
      )}
    </div>
  ),
);

CommonProgress.displayName = 'Progress';

export default CommonProgress;
