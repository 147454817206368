import {useLocation} from 'react-router-dom';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';

/**
 * Routes with app layout scrollable
 */
const APP_LAYOUT_SCROLLABLE_ROUTES: string[] = ['/chat/with', '/rooms/join'];

/**
 * Srollable layout for messenger/rooms.
 */
const useIsAppLayoutScrollable = (): boolean => {
  const {pathname} = useLocation();
  const isPhone = usePhoneBreakpoint();

  return (
    isPhone &&
    APP_LAYOUT_SCROLLABLE_ROUTES.some((route) => pathname.startsWith(route))
  );
};

export default useIsAppLayoutScrollable;
