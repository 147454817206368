import upperFirst from 'lodash/upperFirst';
import isUndefined from 'lodash/isUndefined';

import generateUniqueId from '@core/utils/id/generateUniqueId';
import logger from '@core/logger';
import {
  ACTIVITY_TYPES,
  MOTIVATION_TYPES,
} from '@core/activity/constants/notificationTypes';

import {MSG} from '../constants/socketEventNames';

/**
 * Creates flat map with list of all available event types inside interaction service.
 * @example
 *
 * // Input
 * {
 *   FOO_TYPE: 'fooType',
 *   BAR_TYPE: 'barType',
 *   BAZ_TYPE: 'bazType',
 * }
 *
 * // Output
 * [
 *   'msgType',
 *   'msgbarType',
 *   'msgbazType',
 * ]
 *
 * @param {Object} types
 * @returns {Array}
 */
const getAllIncomingEvents = (types) =>
  Object.values(types).map((type) => MSG + type);

/**
 * For handling 'newsFeed' activities, where we should distinguish them and some activities show, and other avoid to show.
 * For e.g., known list of possible types that arrive from socket:
 * 1) "newsFeed"
 * 2) "newsFeedOnUserFirstLogin" - avoid to show
 * 3) "newsFeedOnLivecamModelStatusOnline" - must be shown.
 * 4) "newsFeedOnYouMayLike" - must be shown
 * 5) "newsFeedOnLoginComplete" - must be shown
 * 6) "newsFeedOnUserInfoUpdated" - must be shown
 *
 * @param {Object} data Interaction payload
 * @returns {string}
 */
const getType = (data) => {
  if (data.params && data.params.subType) {
    return `${data.type}${upperFirst(data.params.subType)}`;
  }
  return data.type;
};

/**
 * Need to get real msg type from response data
 * @param data
 * @returns {string|*}
 */
const getMsgType = (data) => {
  return data.videoAction || data.msgType || data.type;
};

const checkRequiredFields = (response) => {
  ['gender', 'login'].forEach((field) => {
    if (isUndefined(response[field])) {
      logger.sendError(
        `Required field ${field} not found for activity ${JSON.stringify(
          response,
        )}`,
      );
    }
  });
};

export default {
  InteractionActivitySubscription: {
    /**
     * Create array of all possible event types for
     * 1. Standard activity types
     * 2. Motivation activity types
     *
     * Be aware that only standard activity types will be added in activity lists
     * All other activities will be just shown inside sticked notification
     * (interactive widgets with limited time of showing)
     *
     * @see notificationTypes.js
     */
    incomingEvents: getAllIncomingEvents({
      ...ACTIVITY_TYPES,
      ...MOTIVATION_TYPES,
    }),

    async fromServer(response) {
      checkRequiredFields(response);
      // bindProfileId is solution for livecam since there fromId is the model's login
      const userId = response.bindProfileId || response.fromId;

      return {
        data: {
          activity: {
            // There are some events that aren't stored in backend. Just generate random ID
            id: response.activityId || generateUniqueId(),
            isNew: true,
            notify: response.notify,
            eventId: response.eventId || null, // Only for SPD
            messageId: response.messageId || null,
            timestamp: response.timestamp || Math.floor(Date.now() / 1000),
            type: getType(response),
            // In most of cases, in interaction, if there is no 'msgType' is becomes identical to 'type'
            msgType: getMsgType(response),
            // Only 'privateVideo' have this specific flag, indicating what action is coming from server
            videoAction: response.videoAction || null,
            fragmentId: response.fragmentId || null,
            text: response.text || null,
            userId,
            __typename: 'Activity',
          },
        },
      };
    },
  },
};
