import type {GenderTypeEnum} from '@core/types';
import {SexualOrientationAttribute} from '@core/types';

import isMale from './isMale';

const isGay = (
  gender: GenderTypeEnum | number,
  sexualOrientation: SexualOrientationAttribute,
) =>
  isMale(gender) && sexualOrientation === SexualOrientationAttribute.HOMOSEXUAL;

export default isGay;
