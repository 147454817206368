import once from 'lodash/once';

import BaseTracker from '@core/tracking/base/BaseTracker';
import {TRACK_PAYMENT_PENDING_PAGE_STEP} from '@core/tracking/constants/identities';

import TRACK_PAYMENT_PENDING_PAGE_STEP_MUTATION from './graphql/mutations/trackPaymentPendingPageStep.gql';
import prepareData from './prepareData';
import type {TrackPaymentPendingPageStepMutationVariables} from './graphql/mutations/trackPaymentPendingPageStep';

type TrackPaymentPendingPageStepParams = {
  orderId: string;
  step: string;
};

// Tracks for payment pending page after external pay (by websocket api)
const trackPaymentPendingPageStep = ({
  orderId,
  step,
}: TrackPaymentPendingPageStepParams): void => {
  const initializeTracker = once(() => {
    const trackPaymentPendingPageStepTracker = new BaseTracker<
      TrackPaymentPendingPageStepMutationVariables,
      TrackPaymentPendingPageStepParams
    >(
      TRACK_PAYMENT_PENDING_PAGE_STEP_MUTATION,
      prepareData,
      TRACK_PAYMENT_PENDING_PAGE_STEP.METHOD,
    );

    trackPaymentPendingPageStepTracker.track({orderId, step}, true);
  });

  initializeTracker();
};

export default trackPaymentPendingPageStep;
