import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

export const booleanResult = `result errors { general arguments { argument errors } }`;

/**
 * Creates mutation represented as string according to provided formResult
 * formResult - like {login: 'newLogin', race: 'newRace', eyeColor: 'newEyeColor'}
 *
 * For examples:
 * @see src/packages/dating/user/profile/current/utils/__tests__/createMutation.test.js
 */
const createMutation = <TVariables extends Record<string, unknown>>(
  {login, chatUpLine, birthday, ...attributes}: TVariables,
  extraAttributes: string = '',
  extraFragment: string = '',
): string => {
  const mutationAttributes = [
    login && '$login: String!, ',
    !isNil(chatUpLine) && '$chatUpLine: String!, ',
    birthday && '$birthday: String!, ',
    !isEmpty(attributes) && '$attributes: InputUserAttributes!, ',
    extraAttributes,
  ]
    .filter(Boolean)
    .join('');

  const mutationFragments = [
    login && `login(login: $login) {${booleanResult}}`,
    birthday && `birthday(birthday: $birthday) {${booleanResult}}`,
    !isNil(chatUpLine) &&
      `chatUpLine(chatUpLine: $chatUpLine) {${booleanResult}}`,
    !isEmpty(attributes) &&
      `attributes (attributes: $attributes) { ${booleanResult} }`,
  ]
    .filter(Boolean)
    .join('');

  const mutationBody = mutationFragments.length
    ? `user { myUser { ${mutationFragments} } }`
    : '';

  return `mutation CurrentUserMutation(${mutationAttributes}) { ${mutationBody}${extraFragment} }`;
};

export default createMutation;
