import uniq from 'lodash/uniq';

/**
 * Some cunning logic for validate "National identification number" in Brazil
 * @see https://gist.github.com/zipmegabyte/8286613  for details
 */

/**
 * @param {Array} arr
 * @return {Number}
 */
export const buildDigit = (arr) => {
  const isCpf = arr.length < 11;
  const digit =
    arr
      .map((val, idx) => val * ((!isCpf ? idx % 8 : idx) + 2))
      .reduce((total, current) => total + current) % 11;

  if (digit < 2 && isCpf) return 0;

  return 11 - digit;
};

/**
 * @param {String} cpf - Abbreviation ID system for Brazil
 * @return {Boolean}
 */
const isValidNationalIdentityNumber = (cpf) => {
  if (!cpf) {
    return true;
  }

  const number = cpf.replace(/\D/g, '');

  if (uniq(number.trim().split('')).length === 1) return false;

  if ([11, 14].indexOf(number.length) < 0) return false;

  const arr = number.split('').reverse().slice(2);

  arr.unshift(buildDigit(arr));
  arr.unshift(buildDigit(arr));

  return number === arr.reverse().join('');
};

export default isValidNationalIdentityNumber;
