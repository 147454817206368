import type {FC, HTMLAttributes, ReactNode, ReactElement} from 'react';
import React from 'react';
import cn from 'classnames';

import type {CSSModule} from '../../types';
import IconSubstrateSize from '../../constants/IconSubstrateSize';
import IconType from '../../constants/IconType';
import Icon from './Icon';
import baseCss from './IconSubstrate.css';

export interface IconSubstrateProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  iconClassName?: string;
  icon?: ReactElement | string;
  round?: boolean;
  inline?: boolean;
  children?: ReactNode;
  saturated?: boolean;
  inverse?: boolean;
  type?: IconType;
  size?: IconSubstrateSize;
}

const DEFAULT_CSS: CSSModule = {};

/**
 * Renders icon by name or custom children with round substrate under it.
 * Direct port of sizes and colors from designers templates.
 */
const IconSubstrate: FC<
  // `IconSubstrateProps` without `css` inside to make it more suitable for `@phoenix/ui`.
  IconSubstrateProps & {css?: CSSModule}
> = ({
  css = DEFAULT_CSS,
  icon,
  type = IconType.PRIMARY,
  size = IconSubstrateSize.SMALL,
  round = true,
  inline = false,
  className,
  children,
  iconClassName,
  saturated,
  inverse,
  ...otherProps
}) => (
  <div
    className={cn(
      baseCss.icon,
      css[size],
      baseCss[type],
      css[type],
      round && baseCss.round,
      saturated && baseCss.saturated,
      inverse && baseCss.inverse,
      inline && baseCss.inline,
      className,
    )}
    {...otherProps}
  >
    <div className={baseCss.wrap}>
      {icon &&
        (typeof icon === 'string' ? (
          <Icon type={icon} className={iconClassName} inherit />
        ) : (
          icon
        ))}
      {children}
    </div>
  </div>
);

export default IconSubstrate;
