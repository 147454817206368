import {getClientInstance} from '@core/graphql/client';

import MESSENGER_QUERY from '../graphql/queries/messenger.gql';
import updateRecipientCache from './updateRecipientCache';

/**
 * @param deletedUserIds - Array of recipient ids to remove from cache
 */
export default function removeRecipientsInCache(deletedUserIds) {
  const client = getClientInstance();

  const messengerData = client.readQuery({query: MESSENGER_QUERY});
  if (!messengerData) {
    return;
  }

  const recipients = messengerData.messenger.initialData.recipients.filter(
    (recipient) => !deletedUserIds.includes(recipient.id),
  );
  const recipientsLength = recipients.length;

  if (
    recipientsLength === messengerData.messenger.initialData.recipients.length
  ) {
    return;
  }

  client.writeQuery({
    query: MESSENGER_QUERY,
    data: {
      ...messengerData,
      messenger: {
        ...messengerData.messenger,
        initialData: {
          ...messengerData.messenger.initialData,
          recipients,
        },
      },
    },
  });

  // remove messages cache
  deletedUserIds.forEach((id) => {
    updateRecipientCache(id, {
      messages: [],
      unreadMessageCount: 0,
    });
  });
}
