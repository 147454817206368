import type {FC} from 'react';
import React from 'react';

import type {HeadingProps} from './Heading';
import Heading, {HeadingAlign, HeadingLevel} from './Heading';

const H1: FC<Omit<HeadingProps, 'level'>> & {ALIGN: typeof HeadingAlign} = (
  props,
) => <Heading {...props} level={HeadingLevel.H1} />;

H1.ALIGN = HeadingAlign;

export default H1;
