import {useQuery} from '@apollo/client';
import get from 'lodash/get';

import PAYMENT_TEMPLATE_SETTINGS_QUERY from '../graphql/queries/paymentTemplateSettings.gql';
import useRestApiPaymentTemplateSettings from './useRestApiPaymentTemplateSettings';
import usePaymentParams from './usePaymentParams';

/**
 * @desc Hook notices about position components on the payment page
 *       (in footer if is not allowed or under card form if is allowed)
 * @return {Object}
 */
const useIsComplianceFriendlyAllowed = () => {
  const {action, via} = usePaymentParams();

  const {
    isComplianceFriendly,
    isAllowed,
    loading: restApiTemplateSettingsLoading,
  } = useRestApiPaymentTemplateSettings();

  const skip = isAllowed || restApiTemplateSettingsLoading;

  const {data, loading: templateSettingsLoading} = useQuery(
    PAYMENT_TEMPLATE_SETTINGS_QUERY,
    {
      variables: {action, via},
      skip,
    },
  );

  const loading = restApiTemplateSettingsLoading || templateSettingsLoading;

  if (isAllowed) {
    return {
      loading,
      isComplianceFriendly,
    };
  }

  return {
    isComplianceFriendly: get(
      data,
      'payment.templateSettings.templateSettingsData.isComplianceFriendly',
      false,
    ),
    loading,
  };
};

export default useIsComplianceFriendlyAllowed;
