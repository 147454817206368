import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

type ErrorArgument = {
  argument?: string;
  errors?: string[];
};

export type ErrorObject = {
  arguments?: ErrorArgument[];
  general?: string[];
};

export type NormalizedErrors = {
  general?: string;
  [key: string]: string;
};

/**
 * TODO: FE-4367 Make general errors handling for each mutated field
 *
 *
 * Returns normalized errors object or null in case if no errors occurred.
 */
const normalizeMutationError = (
  errors: ErrorObject,
): NormalizedErrors | null => {
  const result: NormalizedErrors = {};

  if (errors.arguments && errors.arguments.length) {
    errors.arguments.forEach((error: ErrorArgument) => {
      result[error.argument] = error.errors[0]; // eslint-disable-line prefer-destructuring
    });
  }

  if (errors.general && errors.general.length) {
    result.general = errors.general[0]; // eslint-disable-line prefer-destructuring
  }

  if (isEmpty(result)) return null;
  return result;
};

/**
 * TODO: FE-4367 Make general errors handling for each mutated field
 *
 *
 * Helper function to retrieve errors in readable format
 * for usage inside forms (display validation, for e.g.).
 * Due to making better UX on frontend - we display only first error from arrived
 * array from backend.
 * * As result we receive:
 *
 *
 * Example of input data:
 * ```
 * {
 *   arguments: [
 *     {argument: 'field1', errors: ['foo error']},
 *     {argument: 'field2', errors: ['bar error']}
 *   ],
 *   general: ['bar error'],
 * }
 * ```
 * The result will be:
 * ```
 *   {
 *     field1: 'foo error',
 *     field2: 'bar error',
 *     general: 'baz error',
 *   }
 * ```
 *
 * * Receive array of errors in case when called mutation consisting of several fields.
 *
 *
 * Example of input data:
 * ```
 * [
 *   {
 *     arguments: [
 *       {argument: 'field1', errors: ['foo error']},
 *       {argument: 'field2', errors: ['bar error']}
 *     ],
 *     general: ['bar error1'],
 *   },
 *   {
 *     arguments: [
 *       {argument: 'field3', errors: ['car error']},
 *       {argument: 'field4', errors: ['mar error']}
 *     ],
 *     general: ['bar error2'],
 *   },
 * ```
 * The result will be:
 * ```
 *   {
 *     field1: 'foo error',
 *     field2: 'bar error',
 *     field3: 'car error',
 *     field4: 'mar error',
 *     general: 'baz error2',
 *   }
 * ```
 */
const normalizeMutationErrors = (
  errors: ErrorObject | ErrorObject[],
): NormalizedErrors | null => {
  const result = isArray(errors)
    ? errors.reduce(
        (reducer: NormalizedErrors, error) =>
          Object.assign(reducer, normalizeMutationError(error)),
        {},
      )
    : normalizeMutationError(errors);

  return isEmpty(result) ? null : result;
};

export default normalizeMutationErrors;
