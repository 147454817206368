enum MountName {
  // Main navigation tabs on page
  NAVIGATION_TABS = 'NAVIGATION_TABS',

  /**
   * Appears when main photo on page becomes loaded.
   * See `handlePhotoLoad` usage in {@see LikeGallerySwipeableLayout}.
   * See `handlePhotoLoad` usage in {@see DiscreetProfileLayout}.
   */
  MAIN_PHOTO = 'MAIN_PHOTO',

  // Is used on simple pages page like /trusted and other.
  PAGE_LAYOUT = 'PAGE_LAYOUT',

  /**
   * Is used on themes without CHAT_ACTION in user widget.
   * @see SearchUserWidgetRoundedWithoutActionsLayout
   */
  SEARCH_USER_INFO = 'SEARCH_USER_INFO',

  /**
   * Is used on themes without CHAT_ACTION on target profile.
   * @see ShortInfoLayout
   */
  TARGET_USER_INFO = 'TARGET_USER_INFO',

  // Is used for /chat/recipients page.
  RECIPIENTS = 'RECIPIENTS',

  /**
   * Is used for /chat/with/*
   * @see ChatHeaderLayout
   */
  CHAT_HEADER = 'CHAT_HEADER',

  /**
   * Is used for /rooms/join/*
   * @see RoomChatHeaderLayout
   */
  ROOM_HEADER = 'ROOM_HEADER',

  /**
   * Is used for /chat/with/*
   * @see MessagesList, RoomMessagesList
   */
  MESSAGE_LIST = 'MESSAGE_LIST',

  // @see ActivityList
  ACTIVITY_LIST = 'ACTIVITY_LIST',

  // @see SearchForm
  SEARCH_FORM = 'SEARCH_FORM',

  // SearchWidgetLayout
  SEARCH_USER_WIDGET = 'SEARCH_USER_WIDGET',

  /**
   * To track search load completion when there are no results found.
   * @see SearchWidgetsDisplayer
   */
  NO_RESULTS = 'NO_RESULTS',

  // @see MicrofeaturesBannerLayout
  MICROFEATURES_WIDGET = 'MICROFEATURES_WIDGET',

  /**
   * Is used for /search, /user/view/id/*
   * @see ChatAction
   */
  CHAT_ACTION = 'CHAT_ACTION',

  /**
   * Is used for /account/reported
   * @see UnReportActionLayout
   */
  UNREPORT_ACTION = 'UNREPORT_ACTION',

  /**
   * Is used for /account/blocked
   * @see UnblockActionLayout
   */
  UNBLOCK_ACTION = 'UNBLOCK_ACTION',

  /**
   * Is used for /favorites
   * @see FavoriteActionLayout
   */
  FAVORITE_ACTION = 'FAVORITE_ACTION',

  /**
   * Is used for /search/livecam
   * @see SearchWebcamAction
   */
  WEBCAM_ACTION = 'WEBCAM_ACTION',

  // Is used for /freeCoins
  REWARD_TASKS = 'REWARD_TASKS',

  // Is used for /wrongdevice
  STORE_ACTIONS = 'STORE_ACTIONS',

  // Is used for /account
  MEMBERSHIP_DETAILS = 'MEMBERSHIP_DETAILS',

  // Is used for /profile/*
  BASIC_INFO = 'BASIC_INFO',

  // Is used for /profile
  PROFILE_SECTION = 'PROFILE_SECTION',

  /**
   * Is used for /profile/menu
   * @see ProfileMenuItem, ProfileMenuTileItem
   */
  PROFILE_MENU_ITEM = 'PROFILE_MENU_ITEM',

  /**
   * Is used for /story/*
   * @see getStoryPartSlideForTypeFactory
   */
  USER_STORY = 'USER_STORY',

  /**
   * Is used for /pay/*
   * @see PaymentPagePayButton
   */
  PAY_BUTTON = 'PAY_BUTTON',

  /**
   * Is used for /pay/* with two steps
   * @see PaymentPageContinueButton
   */
  CONTINUE_PAY_BUTTON = 'CONTINUE_PAY_BUTTON',
}

export default MountName;
