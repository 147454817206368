import {readFromLocalStorage} from '@core/utils/storage';

import {MINI_MESSENGER_VISIBLE_RECIPIENT_KEY} from '../constants/storageKeys';

/**
 * Mini messenger is opened only if visible recipient is exist
 * @returns {boolean}
 */
const isMiniMessengerOpened = () => {
  const data = readFromLocalStorage(MINI_MESSENGER_VISIBLE_RECIPIENT_KEY);
  return Boolean(data);
};

export default isMiniMessengerOpened;
