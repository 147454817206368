import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import {animate, motion, useMotionValue, useDragControls} from 'framer-motion';

import getAnimationTime from '@core/utils/animation/utils/getAnimationTime';
import {SwipeDirection} from '@core/tracking/babcia/constants/babciaDataTypes';
import useBabciaSwipeTrack from '@core/tracking/babcia/utils/useBabciaSwipeTrack';
import BabciaScopedProvider from '@core/tracking/babcia/containers/BabciaScopedProvider';
import usePrevious from '@core/utils/react/usePrevious';
import getUserAgentParser from '@core/utils/getUserAgentParser';

import GallerySlideContent from './GallerySlideContent';
import getItemIndex from '../utils/getItemIndex';
import {GALLERY_DIRECTION} from '../constants/galleryOptions';
import css from '../styles/GalleryCarousel.css';

const APPLY_SEEKING_FIX = ['iOS', 'Mac OS'].includes(
  getUserAgentParser().getOS().name,
);

const RANGE = [-1, 0, 1];

const RANGE_WITHOUT_PREV_SLIDE = [0, 1];

const RANGE_WITHOUT_NEXT_SLIDE = [-1, 0];

const TRANSITION_OPTIONS = {
  type: 'spring',
  bounce: 0,
  duration: getAnimationTime({duration: 0.4}),
};

const START_TRANSITION_OPTIONS = {
  duration: 0.01,
};

const SWIPE_DIRECTION = {
  prev: -1,
  center: 0,
  next: 1,
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const SWIPE_CONFIDENCE_THRESHOLD = 10000;

/**
 *
 * @param {Object} dragData
 * @param {Number} sliderSize
 * @return {number} - prev (-1) | current (0) | next (1)
 */
const getSwipeDirection = (dragData, sliderSize) => {
  const {velocity, offset} = dragData;
  const swipeStrength = Math.abs(offset) * velocity;

  if (swipeStrength > SWIPE_CONFIDENCE_THRESHOLD || offset > sliderSize / 3) {
    return SWIPE_DIRECTION.prev;
  }

  if (swipeStrength < -SWIPE_CONFIDENCE_THRESHOLD || offset < -sliderSize / 3) {
    return SWIPE_DIRECTION.next;
  }

  return SWIPE_DIRECTION.center;
};

/**
 * @param {Number} virtualIndex
 * @param {Number} sliderSize  width or height in pixels
 * @return {number} get all slides offset value based on current slide virtualIndex.
 * prev current and next slides positioned in right place by virtualIndex + RANGE value.
 * X and Y need for motion.div drag encapsulated logic
 */
const calculateNewAxisValue = (virtualIndex, sliderSize = 0) =>
  -virtualIndex * sliderSize;

const useAnimateSlide = ({
  carouselObserver$,
  itemsCount,
  startIndex,
  sliderRef,
  onSlide,
  isVertical,
}) => {
  /**
   * Virtual index need for infinity slider, that value can be more or less than
   * min or max indexes of items array. For calculate item index used getItemIndex
   */
  const [currentSlideVirtualIndex, setCurrentSlideVirtualIndex] =
    useState(startIndex);

  /**
   * Used for ResizeObserver callback for calculate new slide position when slider size is changed.
   * When used currentSlideVirtualIndex in useEffect it triggered unmount callback when currentSlideVirtualIndex is changed,
   * useRef variable used for prevent unmount callback and remount ResizeObserver.
   */
  const currentSlideVirtualIndexRef = useRef(startIndex);

  currentSlideVirtualIndexRef.current = currentSlideVirtualIndex;

  const prevStartIndex = usePrevious(startIndex);

  const currentItemIndex = useMemo(
    () => getItemIndex(itemsCount, currentSlideVirtualIndex),
    [itemsCount, currentSlideVirtualIndex],
  );

  const axisValue = useMotionValue(
    calculateNewAxisValue(
      currentSlideVirtualIndex,
      isVertical
        ? sliderRef.current?.clientHeight
        : sliderRef.current?.clientWidth,
    ),
  );

  /**
   * Call this to execute swipe animation to new or current (if swipe not enough) slide after drag end
   * or when virtual index will be changed
   */
  const animateSwipeToSlide = useCallback(
    (virtualIndex, transitionOptions = TRANSITION_OPTIONS) =>
      animate(
        axisValue,
        calculateNewAxisValue(
          virtualIndex,
          isVertical
            ? sliderRef.current?.clientHeight
            : sliderRef.current?.clientWidth,
        ),
        transitionOptions,
      ),
    [sliderRef, axisValue, isVertical],
  );

  /**
   * Calculate new position after change slider size
   */
  useEffect(() => {
    const onResize = debounce(() => {
      animateSwipeToSlide(currentSlideVirtualIndexRef.current, {duration: 0});
    }, 50);

    const resizeObserver = new ResizeObserver(onResize);

    resizeObserver.observe(sliderRef.current);

    return () => {
      onResize.cancel();
      resizeObserver.disconnect();
    };
  }, [sliderRef, animateSwipeToSlide]);

  /**
   * Slider on mount animate swipe from first slide to slide with startIndex.
   * It makes invisible this animation.
   *
   * startIndex need for handle navigation 2 sliders on page
   * @see TargetUserProfile
   */
  useEffect(() => {
    if (startIndex !== prevStartIndex) {
      animateSwipeToSlide(startIndex, START_TRANSITION_OPTIONS);
      setCurrentSlideVirtualIndex(startIndex);
    }
  }, [
    isVertical,
    startIndex,
    prevStartIndex,
    animateSwipeToSlide,
    setCurrentSlideVirtualIndex,
  ]);

  /**
   * navigate to n slides forward or backward (if the value is negative)
   * @param {Number} direction - slides count.
   */
  const navigate = useCallback(
    (direction) => {
      const newItemIndex = currentSlideVirtualIndex + direction;
      setCurrentSlideVirtualIndex(newItemIndex);

      const promise = animateSwipeToSlide(newItemIndex);

      const itemIndex = getItemIndex(itemsCount, newItemIndex);
      direction && onSlide?.(itemIndex);

      return promise;
    },
    [currentSlideVirtualIndex, itemsCount, animateSwipeToSlide, onSlide],
  );

  /**
   * Listener for navigating to a slide by a specified index
   */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (carouselObserver$) {
      const listener = carouselObserver$.subscribe((slideIndex) => {
        setCurrentSlideVirtualIndex(slideIndex);
        animateSwipeToSlide(slideIndex, START_TRANSITION_OPTIONS);
      });

      return () => {
        listener.unsubscribe();
      };
    }
  }, [carouselObserver$, animateSwipeToSlide]);

  return {
    virtualIndex: currentSlideVirtualIndex,
    itemIndex: currentItemIndex,
    axisValue,
    animateSwipeToSlide,
    navigate,
  };
};

const GalleryCarouselInner = ({
  items,
  // eslint-disable-next-line react/prop-types
  carouselObserver$,
  startIndex = 0,
  showNav,
  disableSwipe,
  className,
  slidesContainerClassName,
  fullSize = true,
  renderItem,
  renderLeftNav,
  renderRightNav,
  renderHeader,
  renderBottom,
  overflow = true,
  onSlide,
  onMount,
  direction = GALLERY_DIRECTION.HORIZONTAL,
  disableSwipeToNext,
  isZoomed = false,
}) => {
  const trackSwipe = useBabciaSwipeTrack();
  const isVertical = GALLERY_DIRECTION.VERTICAL === direction;
  const sliderRef = useRef(null);

  const isOneItem = items.length === 1;
  const isShowNav = showNav && !isOneItem;

  const [dragListener, setDragListener] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const draggingRef = useRef(isDragging);

  draggingRef.current = isDragging;

  const controls = useDragControls();

  const stop = useCallback(
    (event) => {
      // Stop dragging slides by call stop method of private member controls object
      controls.componentControls.forEach((entry) => {
        entry.stop(event, {offset: {}, velocity: {}});
      });
    },
    [controls.componentControls],
  );

  useEffect(() => {
    onMount?.(sliderRef.current);
  }, [onMount]);

  const itemsCount = items.length;

  const {virtualIndex, itemIndex, axisValue, navigate} = useAnimateSlide({
    itemsCount,
    startIndex,
    sliderRef,
    onSlide,
    isVertical,
    carouselObserver$,
  });

  // Disable swipe to next slide from last slide
  const isDisableSwipeToNext =
    disableSwipeToNext && itemIndex === itemsCount - 1;
  // Disable swipe to prev slide from first slide
  const isDisableSwipeToPrev = isVertical && virtualIndex === 0;

  const navigatePrev = useCallback(() => {
    navigate(SWIPE_DIRECTION.prev);
  }, [navigate]);

  const navigateNext = useCallback(() => {
    navigate(SWIPE_DIRECTION.next);
  }, [navigate]);

  const handleTouchStart = useCallback(
    (event) => {
      if (event.touches.length > 1) {
        setDragListener(false);

        if (!draggingRef.current) {
          stop(event);
        }
      }
    },
    [stop],
  );

  const seekingHandlers = useRef([]);

  const handlePointerDown = useCallback(() => {
    const videos = Array.from(sliderRef.current.querySelectorAll('video'));
    seekingHandlers.current = videos.map((video) => [
      video,
      video.addEventListener('seeking', (event) => {
        seekingHandlers.current?.forEach(([v, handler]) =>
          v.removeEventListener('seeking', handler),
        );
        stop(event);
      }),
    ]);
  }, [stop]);

  const handlePointerUp = useCallback(() => {
    seekingHandlers.current?.forEach(([video, handler]) =>
      video.removeEventListener('seeking', handler),
    );
  }, []);

  const handleTouchEnd = useCallback((event) => {
    if (event.touches.length === 0) {
      setDragListener(true);
    }
  }, []);

  const handleDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDragEnd = useCallback(
    (e, dragData) => {
      let swipeDirection = getSwipeDirection(
        {
          offset: isVertical ? dragData.offset.y : dragData.offset.x,
          velocity: isVertical ? dragData.velocity.y : dragData.velocity.x,
        },
        isVertical
          ? sliderRef.current?.clientHeight
          : sliderRef.current?.clientWidth,
      );

      // Disable swipe to prev slide from first slide in vertical orientation
      const swipeToPrevFromFirst = isDisableSwipeToPrev && swipeDirection < 0;
      // Disable swipe to first slide from last slide
      const swipeToFirstFromLast = isDisableSwipeToNext && swipeDirection > 0;

      if (swipeToPrevFromFirst || swipeToFirstFromLast) {
        swipeDirection = SWIPE_DIRECTION.center;
      }

      setIsDragging(false);

      navigate(swipeDirection);

      if (swipeDirection === SWIPE_DIRECTION.prev) {
        trackSwipe(isVertical ? SwipeDirection.BOTTOM : SwipeDirection.RIGHT);
      } else if (swipeDirection === SWIPE_DIRECTION.next) {
        trackSwipe(isVertical ? SwipeDirection.TOP : SwipeDirection.LEFT);
      }
    },
    [
      navigate,
      trackSwipe,
      isVertical,
      isDisableSwipeToPrev,
      isDisableSwipeToNext,
    ],
  );

  let sliderRange = RANGE;
  if (isDisableSwipeToNext) {
    sliderRange = RANGE_WITHOUT_NEXT_SLIDE;
  } else if (isDisableSwipeToPrev) {
    sliderRange = RANGE_WITHOUT_PREV_SLIDE;
  }

  return (
    <>
      {renderHeader?.(itemIndex)}
      <div className={cn(css.container, overflow && css.overflow, className)}>
        <div className={cn(css.galleryCarousel, fullSize && css.fullSize)}>
          {isShowNav && (
            <Fragment>
              {renderLeftNav?.(navigatePrev)}
              {renderRightNav?.(navigateNext)}
            </Fragment>
          )}
          <div
            ref={sliderRef}
            className={cn(css.slidesContainer, slidesContainerClassName)}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onPointerDown={APPLY_SEEKING_FIX ? handlePointerDown : null}
            onPointerUp={APPLY_SEEKING_FIX ? handlePointerUp : null}
          >
            {sliderRange.map((range) => {
              const slideVirtualIndex = virtualIndex + range;

              return (
                <motion.div
                  key={slideVirtualIndex}
                  className={cn(isDragging && css.isDragging, css.slide)}
                  style={
                    isVertical
                      ? {
                          y: axisValue,
                          top: `${slideVirtualIndex * 100}%`,
                          bottom: `${slideVirtualIndex * 100}%`,
                        }
                      : {
                          x: axisValue,
                          left: `${slideVirtualIndex * 100}%`,
                          right: `${slideVirtualIndex * 100}%`,
                        }
                  }
                  drag={
                    !(disableSwipe || isOneItem) && (isVertical ? 'y' : 'x')
                  }
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  dragListener={!isZoomed && dragListener}
                  dragMomentum={false}
                  tabIndex="-1"
                  data-test={`gallerySlide_${slideVirtualIndex}`}
                  dragControls={controls}
                >
                  <GallerySlideContent
                    renderItem={renderItem}
                    items={items}
                    slideVirtualIndex={slideVirtualIndex}
                    isActive={range === SWIPE_DIRECTION.center}
                  />
                </motion.div>
              );
            })}
          </div>
        </div>
      </div>
      {renderBottom?.(items[itemIndex])}
    </>
  );
};

GalleryCarouselInner.propTypes /* remove-proptypes */ = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  startIndex: PropTypes.number,
  showNav: PropTypes.bool,
  disableSwipeToNext: PropTypes.bool,
  className: PropTypes.string,
  slidesContainerClassName: PropTypes.string,
  fullSize: PropTypes.bool,
  disableSwipe: PropTypes.bool,
  renderItem: PropTypes.func.isRequired,
  renderLeftNav: PropTypes.func,
  renderRightNav: PropTypes.func,
  renderHeader: PropTypes.func,
  renderBottom: PropTypes.func,
  onSlide: PropTypes.func,
  onMount: PropTypes.func,
  overflow: PropTypes.bool,
  isZoomed: PropTypes.bool,
  direction: PropTypes.oneOf(Object.values(GALLERY_DIRECTION)),
};

const GalleryCarousel = ({trackingContext = 'galleryCarousel', ...props}) => (
  <BabciaScopedProvider context={trackingContext}>
    <GalleryCarouselInner {...props} />
  </BabciaScopedProvider>
);

GalleryCarousel.propTypes /* remove-proptypes */ = {
  trackingContext: PropTypes.string,
};

export default GalleryCarousel;
