import logger from '@core/logger';
import {getClientInstance} from '@core/graphql/client';

import type {ActiveSplitQuery} from './graphql/queries/activeSplit';
import ACTIVE_SPLIT_QUERY from './graphql/queries/activeSplit.gql';

/**
 * @desc Helper to check whether the user is in the active split group or not
 */
const getActiveSplitGroup = async (
  id: string,
  group: number,
): Promise<boolean> => {
  try {
    const {data} = await getClientInstance().query<ActiveSplitQuery>({
      query: ACTIVE_SPLIT_QUERY,
    });
    const {userFeatures: {activeSplit: {splitId, splitGroup} = {}} = {}} = data;

    return id === splitId && group === splitGroup;
  } catch (e) {
    logger.sendWarning(`[getActiveSplitGroup] GraphQL error is ${e}`);
    return false;
  }
};

export default getActiveSplitGroup;
