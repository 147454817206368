import type {BrowserDetailsProps} from '@core/payment/payProcess/types/browserDetails';

/**
 * Return browser details to add in payment request
 */
const getBrowserDetails = (): BrowserDetailsProps => {
  return {
    colorDepth: window.screen.colorDepth,
    userAgent: window.navigator.userAgent,
    javaScriptEnabled: true,
    javaEnabled: window.navigator.javaEnabled(),
    screenHeight: window.innerHeight,
    screenWidth: window.innerWidth,
    tz: new Date().getTimezoneOffset(),
    language: window.navigator.language,
  };
};

export default getBrowserDetails;
