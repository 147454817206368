import {getClientInstance} from '@core/graphql/client';

const resetPaymentDiscountCache = (broadcast = true) => {
  getClientInstance().cache.evict({
    id: 'Payment:{}',
    fieldName: 'discountData',
    broadcast,
  });

  getClientInstance().cache.evict({
    id: 'Payment:{}',
    fieldName: 'discountData',
    broadcast,
  });
};

export default resetPaymentDiscountCache;
