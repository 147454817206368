/**
 * Type of overlay. Fully fill all area or use gradient
 * @see Overlay.tsx
 */
enum OverlayType {
  FILL = 'fill',
  GRADIENT_FROM_BOTTOM = 'gradientFromBottom',
  GRADIENT_FROM_TOP = 'gradientFromTop',

  /**
   * Special type, used only for photo with information on top and on bottom
   */
  GRADIENT_TOP_AND_BOTTOM = 'gradientTopAndBottom',
}

export default OverlayType;
