/**
 * Need to fetch info about coins
 * @const {string}
 */
const CREDIT = 'credit';

/**
 * Microfeatures payment if it`s enough coins to buy it
 * @const {string}
 */
const EXCHANGE_CREDIT = 'exchangeCredit';

/**
 * Used to buy messages
 * @const {string}
 */
const MESSAGE = 'message';

/**
 * Need to fetch info about credit (coins)
 * @const {string}
 */
const COINS = 'coins';

/**
 * @see src/packages/speedDating
 * @const {string}
 */
const DATING_COURSE = 'datingCourse';

/**
 * @const {string}
 */
const IDEAL = 'ideal';

/**
 * @const {string}
 */
const FEATURES = 'features';

/**
 * @const {string}
 */
const KONBINI = 'konbini';

/**
 * @const {string}
 */
const MEMBERSHIP = 'membership';

/**
 * @const {string}
 */
const MINI_MEMBERSHIP = 'miniMembership';

/**
 * @const {string}
 */
const MICRO_FEATURES = 'microFeatures';

/**
 * @const {string}
 */
const PACKAGE = 'package';

/**
 * @const {string}
 */
const POPUP_MEMBERSHIP = 'popupMembership';

/**
 * @const {string}
 */
const RESULT = 'result';

/**
 * @const {string}
 */
const REMARKETING_OFFER = 'remarketingOffer';

/**
 * @const {string}
 */
const SEPA = 'sepa';

/**
 * @const {string}
 */
const IFRAME_PP = 'iframePP';

/**
 * @const {string}
 */
const SOFORT = 'sofort';

/**
 * @see src/packages/speedDating
 * @const {string}
 */
const SPEED_DATING = 'speedDating';

/**
 * @const {string}
 */
const VERIFICATION = 'verification';

/**
 * @const {string}
 */
const VERIFICATION_SUCCESS = 'verificationSuccess';

/**
 * @const {string}
 */
const TRUST_PAY = 'trustPay';

/**
 * @const {string}
 */
const APP_FUNNEL = 'appFunnel';

/**
 * @const {string}
 */
const SUCCESS = 'success';

/**
 * @const {string}
 */
const MULTI_SELECT_MICRO_FEATURES = 'multiSelectMicroFeatures';

export const LEGACY_BACKBONE_ACTIONS = [
  PACKAGE,
  SEPA,
  IDEAL,
  KONBINI,
  TRUST_PAY,
];

export default {
  CREDIT,
  EXCHANGE_CREDIT,
  MESSAGE,
  COINS,
  DATING_COURSE,
  IDEAL,
  FEATURES,
  KONBINI,
  MEMBERSHIP,
  MICRO_FEATURES,
  VERIFICATION,
  VERIFICATION_SUCCESS,
  RESULT,
  PACKAGE,
  MINI_MEMBERSHIP,
  POPUP_MEMBERSHIP,
  REMARKETING_OFFER,
  MULTI_SELECT_MICRO_FEATURES,
  SEPA,
  SOFORT,
  SPEED_DATING,
  APP_FUNNEL,
  TRUST_PAY,
  SUCCESS,
  IFRAME_PP,
};
