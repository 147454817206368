/**
 * Name of header container.
 * Wraps inside header and toolbar in cases of sticked header
 * @const {string}
 */
export const HEADER_ID = 'headerContainer';

/**
 * Name of toolbar container
 * @see useCSSHeaderHeight.js
 * @type {string}
 */
export const TOOLBAR_ID = 'toolbarWrapper';

/**
 * Name of animated toolbar container
 * @type {string}
 */
export const ANIMATED_TOOLBAR_ID = 'аnimatedHeader';

/**
 * For case when header and toolbar are floating, we should count spacings too.
 * Wrapper for toolbar, system notifications and header with top position
 * @see useCSSTopWrapperHeight.js
 * @type {string}
 */
export const TOP_WRAPPER = 'stickedTopWrapper';
