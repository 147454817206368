import {useState, useCallback} from 'react';

import wait from '@core/utils/timer/wait';

/**
 * How long message should be displayed
 * @const {Number}
 */
export const SUCCESS_WAIT_TIMEOUT = 3000;

/**
 * Just for avoiding overwriting entire object
 * and invalidate hooks and rendering outside of this module.
 */
const result = {};

/**
 * Separated module for encapsulating logic about persisting
 * of success message in UI.
 *
 * Note that "allowToShowSuccessMessage" is an ASYNC function,
 * so you can wait until it finishes if you want to make some operations
 * exactly after animation
 *
 * @returns {Object} result
 * @returns {Boolean} result.showSuccessMessage
 * @returns {Function} result.allowToShowSuccessMessage
 */
const useSuccessMessageDisplayer = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const allowToShowSuccessMessage = useCallback(async () => {
    setShowSuccessMessage(true);
    await wait(SUCCESS_WAIT_TIMEOUT);
    setShowSuccessMessage(false);
  }, [setShowSuccessMessage]);

  result.showSuccessMessage = showSuccessMessage;
  result.allowToShowSuccessMessage = allowToShowSuccessMessage;

  return result;
};

export default useSuccessMessageDisplayer;
