/* eslint-disable */

/**
 * @todo Remove eslint-disable and fix all linting errors
 */
export interface CookieOptions {
  expires?: number | Date | string;
  path?: string;
  domain?: string;
}

const setCookie = (name: string, value: string, options: CookieOptions = {}): void => {
  let expires = options.expires;

  if (typeof expires === 'number' && expires) {
    const d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }
  if (expires instanceof Date && expires.toUTCString) {
    options.expires = expires.toUTCString();
  }
  if (options.path === undefined) {
    options.path = '/';
  }

  value = encodeURIComponent(value);

  let updatedCookie = `${name}=${value}`;

  for (const propName in options) {
    updatedCookie += `; ${propName}`;
    const propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += `=${propValue}`;
    }
  }

  document.cookie = updatedCookie;
};

export default setCookie;
