/**
 * IMPORTANT
 *
 * Be aware that such routes take part in routes map generation
 * during build step. Modify it carefully.
 *
 * @see scripts/generateRoutesList.js
 */

/**
 * Confirmation that can't be skipped. Used by default for all WEB registered users.
 * MOB registered users skip this step and have "auto-confirm" logic
 */
export const NON_SKIPPABLE_CONFIRMATION = '/confirmation';

/**
 * Remarketing offer popup.
 */
export const REMARKETING_OFFER = '/remarketingOffer';

/**
 * Funnel resale remarketing popup
 */
export const FUNNEL_RESALE = '/funnel/resale';

/**
 * Funnel confirm where for email confirmation users can get free n-th coins
 */
export const FUNNEL_CONFIRM_WITH_REWARD = '/funnel/confirmWithReward';

/**
 * Funnel confirm with photo upload
 */
export const FUNNEL_CONFIRM_WITH_PHOTO_UPLOAD =
  '/funnel/confirmWithPhotoUpload';

/**
 * "Open funel" logic. Confirmation popup with possibility to close it.
 */
export const SKIPPABLE_CONFIRMATION = '/funnel/confirm';

/**
 * Funnel popup where we allow to user upload their own photos and (for WEB) videos
 * @todo Should be unified together in one type of popup between WEB and MOB
 */
export const FUNNEL_PHOTO_UPLOAD = '/funnel/photoUpload';

/**
 * Currently, only MOB funnel popup displayed as second step.
 */
export const FUNNEL_STATUS_UPLOAD = '/funnel/statusUpload';

/**
 * Currently, only WEB funnel popup displayed as second step. Allows to user
 * fill some info about yourself and displays some checkboxes related to PMA and 'ForeignComm' features
 */
export const FUNNEL_INFO_UPLOAD = '/funnel/infoUpload';

/**
 * Paid funnels types:
 *
 * default - Common paid funnel created by Billing Unit
 * rm - Remarketing paid funnel. Availalbe only for cross-registered users with hard logic in configs
 * foreign - Paid funnel that is used by marketing team. We can access only with 'utm_sub'
 * param in URL during registration.
 */
export const PAID_FUNNEL = '/funnel/paid';

/**
 * Special funnel step that offers to the user to select preferred content
 * The search result depends on the user's preferences (relies on content level)
 */
export const FUNNEL_PREFERRED_CONTENT = '/funnel/preferredContent';

/**
 * Url which hasn't own popup
 * In some cases we can get redirect from backend on just /funnel - in that case we should go to the first step of funnels stack
 * @see src/packages/dating/funnel/utils/buildFunnelStack.js
 * @see src/packages/dating/application/containers/RouteWithPopupDisplayer.js
 */
export const FUNNEL = '/funnel';

/**
 * In app motivation popup.
 * @see inAppMotivation/utils/openInAppMotivationPopup.js
 */
export const FUNNEL_IN_APP_MOTIVATION = '/funnel/inAppMotivation';

/**
 * List of routes that displays popup on top of common content.
 * Created as 'map' between machine name and real route.
 * @see RouteWithPopupDisplayer.js
 */
const ROUTES_WITH_POPUP = [
  // Confirmation
  NON_SKIPPABLE_CONFIRMATION,
  SKIPPABLE_CONFIRMATION,
  REMARKETING_OFFER,
  // Paid funnels
  PAID_FUNNEL,
  FUNNEL_CONFIRM_WITH_REWARD,
  // Funnels
  FUNNEL_PHOTO_UPLOAD,
  FUNNEL_RESALE,
  FUNNEL_CONFIRM_WITH_PHOTO_UPLOAD,
  FUNNEL_INFO_UPLOAD,
  FUNNEL_PREFERRED_CONTENT,
  FUNNEL_STATUS_UPLOAD,
  FUNNEL_IN_APP_MOTIVATION,
];

export default ROUTES_WITH_POPUP;
