/**
 * List of all disapprove reasons for text content (screenName ans status)
 */
export default {
  PERSONAL: 'personal',
  LINKS: 'links',
  OFFENSIVE: 'offensive',
  AGE_MISMATHING: 'ageMismathing',
  DRUGS: 'drugs',
  ESCORT: 'escort',
  GENDER_DIFFERENCE: 'genderDifference',
  MEANINGLESS: 'meaningless',
  CURRENCY: 'currency',
};
