import React from 'react';
import {Route} from 'react-router-dom';
import Favico from 'react-favico-js';

import getFormattedCounter from '@core/utils/string/getFormattedCounter';

import ActivityConsumer from './ActivityConsumer';

/**
 * Max number that can be showed on favicon. Any number greather - will be displayed as '9+'
 */
const MAX_SHOW_COUNT = 9;

/**
 * @param {Array} activities
 * @param {string} path
 * @returns {number|string}
 */
const getCounter = (activities, path) => {
  // On payment page we avoid disaplying anything
  if (path.startsWith('/pay') || !activities.length) {
    return 0;
  }

  return getFormattedCounter(activities.length, MAX_SHOW_COUNT);
};

/**
 * Get appearance-releated settings for favicon widget.
 * @returns {Object}
 */
const getFaviconSettings = () => {
  const {body} = document;
  const settings = {
    animation: 'fade',
  };

  const bgColor = getComputedStyle(body).getPropertyValue('--skinAccent');
  const fontFamily =
    getComputedStyle(body).getPropertyValue('--themeFontFamily');

  // CSS3 variable have many whitespaces. Must be trimmed.
  if (bgColor) settings.bgColor = bgColor.trim();
  if (fontFamily) settings.fontFamily = fontFamily.trim();

  return settings;
};

/**
 * Component as a service. Renders nothing inside DOM. Recieves activities from
 * GraphQL and mirrors changes into favicon.
 */
const FaviconWithActivity = () => (
  <ActivityConsumer>
    {({all}) => (
      <Route>
        {({location}) => (
          <Favico
            counter={getCounter(all, location.pathname)}
            {...getFaviconSettings()}
          />
        )}
      </Route>
    )}
  </ActivityConsumer>
);

export default FaviconWithActivity;
