import type {FC, ReactNode} from 'react';
import React from 'react';
import cn from 'classnames';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import type {CSSModule} from '../../types';
import {Icon} from '../icon';
import IconSize from '../../constants/IconSize';
import TagType from '../../constants/TagType';
import baseCss from './Tag.css';

export interface TagProps {
  children?: ReactNode;
  className?: string;
  trackingName?: string;
  type?: TagType;
  icon?: string;
  large?: boolean;
  inverse?: boolean;
  largeRadius?: boolean;
}

const DEFAULT_CSS: CSSModule = {};

/**
 * Simple tag with text
 */
const Tag: FC<
  // `TagProps` without `css` inside to make it more suitable for `@phoenix/ui`.
  TagProps & {css?: CSSModule}
> = ({
  css = DEFAULT_CSS,
  children,
  className,
  trackingName,
  type = TagType.DEFAULT,
  large,
  largeRadius,
  icon,
  inverse,
  ...props
}) => {
  const content = (
    <div
      className={cn(
        baseCss.tag,
        css.tag,
        large && [css.large, baseCss.large],
        type && [css[type], baseCss[type]],
        inverse && [css.inverse, baseCss.inverse],
        largeRadius && [css.largeRadius, baseCss.largeRadius],
        icon &&
          (children
            ? [baseCss.withIcon, css.withIcon]
            : [css.onlyIcon, baseCss.onlyIcon]),
        className,
      )}
      {...props}
    >
      {icon && (
        <Icon
          className={cn(baseCss.icon, css.icon)}
          type={icon}
          size={large ? IconSize.SIZE_24 : IconSize.SIZE_12}
        />
      )}
      {children}
    </div>
  );

  return trackingName ? (
    <AddBabciaUBTracking trackingName={trackingName}>
      {content}
    </AddBabciaUBTracking>
  ) : (
    content
  );
};

export default Tag;
