import {useEffect, useRef} from 'react';
import {BehaviorSubject} from 'rxjs';

// Stream to handle changes of mounted components.
export const mountedComponents = new BehaviorSubject<Record<string, number>>(
  {},
);

const add = (name: string) => {
  const mountedCounts = mountedComponents.value;
  if (!mountedCounts[name]) {
    mountedCounts[name] = 1;
    // Trigger `next` only for first mounted component.
    mountedComponents.next(mountedCounts);
  } else {
    mountedCounts[name]++;
  }
};

const remove = (name: string) => {
  const mountedCounts = mountedComponents.value;
  mountedCounts[name]--;
  if (mountedCounts[name] === 0) {
    // Trigger `next` only for last unmounted component.
    mountedComponents.next(mountedCounts);
  }
};

// Is used for components which we need to know when become mounted or unmounted.
export const useMountTracking = (name?: string): void => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (name) {
      add(name);
      return () => remove(name);
    }
  }, [name]);
};

/**
 * Returns the callback to register component as mounted, unregisters it on unmount.
 * @param name - component name
 */
export const useLoadTracking = (name: string): (() => void) => {
  const state = useRef<{
    onLoad?: () => void;
    onUnmount?: () => void;
    disabled?: boolean;
  }>({}).current;

  if (!state.onLoad) {
    state.onLoad = () => {
      if (!state.disabled && !state.onUnmount) {
        add(name);
        state.onUnmount = () => remove(name);
      }
    };
  }

  useEffect(
    () => () => {
      state.disabled = true;
      state.onUnmount?.();
    },
    [state],
  );

  return state.onLoad;
};
