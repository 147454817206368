import type {ReactNode} from 'react';

import createTranslationsMap from '@core/translations/createTranslationsMap';

import PhotoSize from './PhotoSize';
import PhotoLevel from './PhotoLevel';

type TextType = {DEFAULT: ReactNode; [PhotoLevel.BLURRED]?: ReactNode};

export type LevelTextsType = {
  [PhotoSize.NORMAL]: TextType;
  [PhotoSize.NONE]: {DEFAULT: null};
  DEFAULT: TextType;
};

export const LEVEL_TEXTS: LevelTextsType = {
  [PhotoSize.NORMAL]: createTranslationsMap((t) => ({
    DEFAULT: t('popupNaughtyMode', 'text.description'),
  })),
  [PhotoSize.NONE]: {
    DEFAULT: null,
  },
  DEFAULT: createTranslationsMap((t) => ({
    [PhotoLevel.BLURRED]: t('userPhotoWidget', 'Naughty photo'),
    DEFAULT: t('userPhotoWidget', 'Explicit nudity'),
  })),
};

/**
 * Used for clean product restrictions logic (@see withCleanProductRestrictions in useNaughtyMode.js)
 */
export const LEVEL_TEXTS_CLEAN: LevelTextsType = {
  [PhotoSize.NORMAL]: createTranslationsMap((t) => ({
    DEFAULT: t('popupNaughtyMode', 'text.description'),
  })),
  [PhotoSize.NONE]: {
    DEFAULT: null,
  },
  DEFAULT: createTranslationsMap((t) => ({
    [PhotoLevel.BLURRED]: t('userPhotoWidget', 'Flirty photo'),
    DEFAULT: t('userPhotoWidget', 'Saucy'),
  })),
};

export default LEVEL_TEXTS;
