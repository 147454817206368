import RTL_LOCALES from './constants/rtlLocales';

/**
 * Text directions list
 * @type {{RTL: string, LTR: string}}
 */
const DIRECTION = {
  RTL: 'rtl',
  LTR: 'ltr',
};

/**
 * Set the text direction corresponding to the locale
 * @param {String} locale
 */
const setTextDirection = (locale) => {
  if (!window) return;

  document.documentElement.setAttribute(
    'dir',
    RTL_LOCALES.includes(locale) ? DIRECTION.RTL : DIRECTION.LTR,
  );
};

export default setTextDirection;
