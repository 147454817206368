import {parseToRgb} from 'polished';

/**
 * @const {Number}
 */
const TOLERANCE_THRESHOLD = 191.25; // Like 75% from "255"

/**
 * Checks if we should use 'inversion' of colors for child elements based on passed
 * HTML node and rule where to look. In few words we extract color and try to determinate
 * if is 'dark' or 'light'.
 *
 * @param {HTMLElement|Null} element
 * @returns {Boolean|Null} Nothing is returned in case if no element was passed
 */
const shouldUseInverseColorScheme = (element) => {
  if (!element) return null;

  const {red, green, blue} = parseToRgb(
    getComputedStyle(element).getPropertyValue('background-color'),
  );

  /**
   * HSP (Highly Sensitive Perceived brightness) equation
   * @see http://alienryderflex.com/hsp.html
   */
  const hsp = Math.sqrt(
    0.299 * (red * red) + 0.587 * (green * green) + 0.114 * (blue * blue),
  );

  // Using the HSP value, determine whether the color is light or dark
  return hsp < TOLERANCE_THRESHOLD;
};

export default shouldUseInverseColorScheme;
