import {useEffect} from 'react';
import {useReactiveVar} from '@apollo/client';

import restApiPaymentTemplateSettingsVar, {
  defaultValues,
} from '@core/graphql/vars/restApiPaymentTemplateSettingsVar';

import usePaymentParams from './usePaymentParams';

const REST_API_CHECK_RESPONSE_INTERVAL = 50;

/**
 * Used for backbone pp.
 * Cache action, via and template settings, because window.PP_RESP will be removed when payment model was init.
 * Work with actions: membership, package, features, credit, microFeatures, multiSelectMicroFeatures.
 * @see getPayRequestAccelerationScript.js
 */
export const useCacheRestApiPaymentTemplateSettings = () => {
  const cacheTemplateSettings = () => {
    if (!window.PP_RESP) {
      return;
    }

    const {action, via, resp} = window.PP_RESP;

    const template = resp?.data?.templateSettings?.template;

    const motivationTemplate = resp?.data?.motivationTemplate;

    const isComplianceFriendly =
      resp?.data?.templateSettings?.termsLayout === 'complianceFriendly';

    restApiPaymentTemplateSettingsVar({
      action,
      via,
      template,
      motivationTemplate,
      isComplianceFriendly,
      loading: false,
    });
  };

  useEffect(() => {
    let interval;

    // For case when /pay request is still loading when App.js is mounted
    if (window.PP_RESP?.loading) {
      restApiPaymentTemplateSettingsVar({...defaultValues, loading: true});

      interval = setInterval(() => {
        if (window.PP_RESP && !window.PP_RESP.loading) {
          cacheTemplateSettings();
          clearInterval(interval);
        }
      }, REST_API_CHECK_RESPONSE_INTERVAL);
    } else if (window.PP_RESP) {
      cacheTemplateSettings();
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  return null;
};

/**
 * Hook for get payment template settings from preloaded rest api.
 * @see getPayRequestAccelerationScript.js
 * @return {{template: (string|null), motivationTemplate: (string|null), isComplianceFriendly: boolean, isAllowed: boolean, loading: boolean}}
 */
const useRestApiPaymentTemplateSettings = () => {
  const {action, via} = usePaymentParams();

  const {
    action: preloadedAction,
    via: preloadedVia,
    template,
    motivationTemplate,
    isComplianceFriendly,
    loading,
  } = useReactiveVar(restApiPaymentTemplateSettingsVar);

  const isAllowed = action === preloadedAction && preloadedVia === via;

  return {
    motivationTemplate,
    template,
    isComplianceFriendly: Boolean(isComplianceFriendly),
    isAllowed,
    loading,
  };
};

export default useRestApiPaymentTemplateSettings;
