import getLocale from '@core/translations/utils/getLocale';

import RTL_LOCALES from './constants/rtlLocales';

/**
 * @returns {bool}
 */
const isRtlDirection = () => RTL_LOCALES.includes(getLocale());

export default isRtlDirection;
