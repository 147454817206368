enum UIKit {
  /**
   * "Material Design" UI Kit. Uses underlined inputs, 3px radiuses and other things
   * common to basic Google material UI styleguide.
   */
  MATERIAL = 'material',

  /**
   * "Fresh" UI Kit. Uses increased roundings compared to "material" theme.
   * Uses gentle color kits, tabs as buttons, buttons with outlines
   */
  FRESH = 'fresh',

  /**
   * "Maneko" UI Kit. Flat buttons, light mix of "fresh" kit with some items from "material" kit.
   */
  MANEKO = 'maneko',

  /**
   * "Force" UI Kit. Based on "material" kit but with unique widgets for:
   * - Bar (No background, only border)
   * - Inputs (like "fresh" ones but with background)
   * - Tabs (Button-like appearance)
   * - Divider (With background but without borders)
   */
  FORCE = 'force',

  /**
   * "Glass" UI kit. Based on "force" kit but with some changes:
   * - Hardcoded second level "Card" that adds blur and disables any shadows.
   * - Accordions, bars and banners are "spaced"
   */
  GLASS = 'glass',

  /**
   * "Loveis" UI kit. Based on "force" kit but with some changes:
   * Hardcoded border in separator, param light in cards is always ignored and few others not hardcoded modifications
   */
  LOVEIS = 'loveis',

  /**
   * "Moon" UI kit. Mostly, the same as "material"
   * except badge appearance, gradiented button and other minor changes
   */
  MOON = 'moon',

  /**
   * "Sky" UI kit. Something in the middle between "material" and "glass" theme.
   */
  SKY = 'sky',
}

export default UIKit;
