import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';

import getEmptyLastMessage from './getEmptyLastMessage';
import MESSENGER_QUERY from '../graphql/queries/messenger.gql';
import GET_RECIPIENT_QUERY from '../graphql/queries/recipient.gql';
import removeRecipientsInCache from './removeRecipientsInCache';
import updateRecipientsInCache from './updateRecipientsInCache';

const aborts = {};

/**
 * Load data for new recipient from server and update MESSENGER_QUERY.
 * Initialized in
 * @see startMessengerListeners
 * @param client
 * @param recipientId
 * @param withAbortPreviousRequest
 * @return Promise
 */
const getRecipientById = async (
  client,
  recipientId,
  withAbortPreviousRequest = true,
) => {
  let cancelled = false;

  if (withAbortPreviousRequest) {
    /**
     * Sometimes this function may be called when previous request is in progress for this contact.
     * It means the result data may be outdated, so we shouldn't use it. We also couldn't make
     * new request now, because apollo will resolve it together with previous request, using old data.
     * So we do new request only when previous request completes.
     */
    const abortPreviousRequest = aborts[recipientId];

    if (abortPreviousRequest) {
      abortPreviousRequest();
      return;
    }

    aborts[recipientId] = () => {
      cancelled = true;
    };
  }

  const recipientPromise = client.query({
    query: GET_RECIPIENT_QUERY,
    variables: {recipientId},
    // no-cache to avoid getting data from cache when new message coming from deleted contact
    fetchPolicy: 'no-cache',
  });
  const messengerPromise = client.query({query: MESSENGER_QUERY});

  const [
    {
      data: {
        messenger: {recipient},
      },
    },
  ] = await Promise.all([recipientPromise, messengerPromise]);

  if (withAbortPreviousRequest) {
    delete aborts[recipientId];

    if (cancelled) {
      // Previous request completed. Do new request to fetch actual data.
      await getRecipientById(client, recipientId);
      return;
    }
  }

  /**
   * If income recipient without "user" property (it means user deleted) -
   * delete ACTIVE_RECIPIENT from the storage
   */
  if (isEmpty(recipient.user)) {
    removeRecipientsInCache([recipientId]);
    throw new Error(`User ${recipientId} is deleted`);
  }

  updateRecipientsInCache({
    client,
    updater: (recipientsFromCache) => {
      const recipients = reject(recipientsFromCache, {
        id: recipientId,
      });

      const newRecipient = {
        ...recipient,
        /**
         * There is no lastMessage in answer from server for new recipient,
         * but it is marked as required in current scheme,
         * so using getEmptyLastMessage to provide default values for lastMessage
         */
        lastMessage: {
          ...getEmptyLastMessage(recipientId),
          ...recipient.lastMessage,
        },
      };

      return [
        /**
         * Just in case add new contact at top of the list.
         * It could be helpful for contacts without 'lastMessage'.
         * Contacts with 'lastMessage' will be sorted properly in {@see Recipients}.
         */
        newRecipient,
        ...recipients,
      ];
    },
  });
};

export default getRecipientById;
