import LOCALES from './locales';

/**
 * List of available dayjs.js locales
 * Dayjs hasn't some locales, so we use similar:
 * @see https://github.com/iamkun/dayjs/tree/dev/src/locale
 */
export default {
  [LOCALES.CS]: 'cs',
  [LOCALES.DA]: 'da',
  [LOCALES.DE]: 'de',
  [LOCALES.EN]: 'en',
  [LOCALES.EN_AU]: 'en-au',
  [LOCALES.EN_CA]: 'en-ca',
  [LOCALES.EN_GB]: 'en-gb',
  [LOCALES.ES]: 'es',
  /**
   * @todo There are no ES_AR in dayjs.js
   */
  [LOCALES.ES_AR]: 'es',
  [LOCALES.ES_MX]: 'es',
  [LOCALES.ES_US]: 'es-us',
  [LOCALES.FI]: 'fi',
  [LOCALES.FR]: 'fr',
  [LOCALES.HU]: 'hu',
  [LOCALES.HR]: 'hr',
  [LOCALES.ID]: 'id',
  [LOCALES.IT]: 'it',
  [LOCALES.JA]: 'ja',
  [LOCALES.KO]: 'ko',
  [LOCALES.MS]: 'ms',
  [LOCALES.EL]: 'el',
  [LOCALES.NL]: 'nl',
  [LOCALES.NL_BE]: 'nl-be',
  /**
   * @todo There are no NO locale in dayjs.js
   */
  [LOCALES.NO]: 'nb',
  [LOCALES.PL]: 'pl',
  [LOCALES.PT]: 'pt',
  [LOCALES.PT_BR]: 'pt-br',
  [LOCALES.RO]: 'ro',
  [LOCALES.RU]: 'ru',
  [LOCALES.SL]: 'sl',
  [LOCALES.SV_SE]: 'sv',
  [LOCALES.TH]: 'th',
  [LOCALES.TR]: 'tr',
  [LOCALES.ZH]: 'zh',
  [LOCALES.ZH_HK]: 'zh-hk',
  [LOCALES.ZH_SG]: 'zh-tw',
  [LOCALES.ZH_TW]: 'zh-tw',
  [LOCALES.AR_DZ]: 'ar-dz',
  [LOCALES.AR_EG]: 'ar',
  [LOCALES.VI]: 'vi',
};
