import {animate} from 'popmotion';

import getAnimationTime from '@core/utils/animation/utils/getAnimationTime';

import getDefaultScrollableElement from './getDefaultScrollableElement';

const SCROLL_INTO_VIEW_ANIMATION_DURATION = getAnimationTime({duration: 500});
const defaultScrollableElement = getDefaultScrollableElement();

type ScrollArgs = {
  currentElem: Element; // element to scroll into view
  scrollableElem?: Element; // element which need to be scrolled
  partialViewContent?: boolean; // show part of content
  duration?: number;
};

const scrollIntoView = ({
  currentElem,
  scrollableElem = defaultScrollableElement,
  partialViewContent = false,
  duration = SCROLL_INTO_VIEW_ANIMATION_DURATION,
}: ScrollArgs) => {
  const itemTopOffset = currentElem.getBoundingClientRect().top;
  const isParentDocument = scrollableElem.isSameNode(defaultScrollableElement);

  const headerHeight = parseFloat(
    getComputedStyle(document.documentElement).getPropertyValue(
      '--headerHeight',
    ),
  );

  const parentTopOffset = isParentDocument
    ? headerHeight
    : scrollableElem.getBoundingClientRect().top;

  const parentBottomOffset = scrollableElem.getBoundingClientRect().bottom;

  let scrollTop = partialViewContent
    ? Math.max(
        scrollableElem.scrollTop,
        scrollableElem.scrollTop +
          itemTopOffset -
          parentBottomOffset +
          currentElem.clientHeight / 3,
      )
    : scrollableElem.scrollTop + itemTopOffset - parentTopOffset;

  // Prevent iOS 17 "overscroll"
  const scrollTopMax =
    defaultScrollableElement.offsetHeight -
    defaultScrollableElement.clientHeight;
  if (isParentDocument && scrollTop > scrollTopMax) {
    scrollTop = Math.max(0, scrollTopMax);
  }

  // Check on test environment to avoid jump for screenshot
  if (window.IS_INTEGRATION_TEST_ENVIRONMENT) {
    scrollableElem.scrollTop = scrollTop;
  } else {
    animate({
      from: {scrollTop: scrollableElem.scrollTop},
      to: {scrollTop},
      duration,
      onUpdate: ({scrollTop: top}) => scrollableElem.scrollTo(0, top),
    });
  }
};

export default scrollIntoView;
