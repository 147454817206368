import type {CurrentUserIdQuery} from '@core/user/profile/current/graphql/queries/currentUserId';
import CURRENT_USER_ID_QUERY from '@core/user/profile/current/graphql/queries/currentUserId.gql';

import {getClientInstance} from '../client';

/**
 * User id must be appended to initial record id, in order to relate the record to a specific user.
 */
const getStorageKey = async (key: string): Promise<string> => {
  const client = getClientInstance();
  const {data} = await client.query<CurrentUserIdQuery>({
    query: CURRENT_USER_ID_QUERY,
  });

  return `${key}:${data.myUser.profile.id}`;
};

export default getStorageKey;
