import isPayUrl from '../url/isPayUrl';
import ImagePreset from './constants/ImagePreset';
import getCachedPhotoUrl from './getCachedPhotoUrl';

const BASE_URL = '/photo/show';

type CreateImageUrlParams = {
  id: string;
  updatedOn: string;
  imageFormat: string;
  cdnDomain?: string;
  photoUrlExtension?: string;
  formatName?: string;
};

/**
 * Helper method for generating correct links for images from CDN (or not)
 * Is the only way to create correct link to a photo, so, please,
 * don't set directly on production photos without using this helper method.
 */
const createImageUrl = ({
  id,
  updatedOn,
  imageFormat,
  cdnDomain,
  photoUrlExtension,
  formatName,
}: CreateImageUrlParams): string => {
  if (isPayUrl(window.location.pathname)) {
    const url = getCachedPhotoUrl(id);

    if (url) {
      return url;
    }
  }

  if (photoUrlExtension) {
    const hash = window.btoa(
      JSON.stringify({
        formatName: formatName || ImagePreset.FULL_SIZE,
        updatedOn,
        imageFormat,
        id,
      }),
    );

    return `${cdnDomain || ''}${BASE_URL}/id/${hash}${photoUrlExtension}`;
  }

  const hash = window.btoa(
    JSON.stringify({
      formatName: formatName || ImagePreset.FULL_SIZE,
      updatedOn,
      imageFormat,
    }),
  );

  return `${cdnDomain || ''}${BASE_URL}/id/${id}?hash=${hash}`;
};
export default createImageUrl;
