import {MSG} from '../constants/socketEventNames';

const fromServer = ({total: count, time}) => ({
  data: {
    globalFreeMessage: {
      count,
      time,
      __typename: 'GlobalFreeMessage',
    },
  },
});

export default {
  GlobalFreeMessageCountIncreaseSubscription: {
    incomingEvents: [`${MSG}globalFreeMessageCountIncrease`],
    fromServer,
  },

  GlobalFreeMessageCountDecreaseSubscription: {
    incomingEvents: [`${MSG}globalFreeMessageCountDecrease`],
    fromServer,
  },
};
