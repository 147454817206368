import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import type {Client} from '@core/graphql/types';

import type {
  TargetUserSendFormQuery,
  TargetUserSendFormQueryVariables,
} from '../graphql/queries/targetUserSendForm';
import TARGET_USER_SEND_FORM_QUERY from '../graphql/queries/targetUserSendForm.gql';

type UpdateRecipientMessagePermissionsOptions = {
  client: Client;
  userId: string;
  reason?: string;
  canSendNext?: boolean;
  needSendFirst?: boolean;
  onlyInCache?: boolean;
};

/**
 * Replaces canSend in messagePermissions with canSendNext flag
 */
const updateRecipientMessagePermissions = ({
  client,
  userId,
  canSendNext,
  reason,
  needSendFirst,
  onlyInCache = false,
}: UpdateRecipientMessagePermissionsOptions): Promise<void> =>
  client
    .query<TargetUserSendFormQuery, TargetUserSendFormQueryVariables>({
      query: TARGET_USER_SEND_FORM_QUERY,
      variables: {userId},
      fetchPolicy: onlyInCache ? 'cache-only' : 'cache-first',
    })
    .then(({data: cachedData}) => {
      const data = cloneDeep(cachedData);
      const {
        user: {messagePermissions},
      } = data;

      // possible value undefined or {} after call invalidateCacheByTypename(getClientInstance(), 'UserData', 'messagePermissions')
      // when onlyInCache - true, that's why check on isEmpty;
      if (isEmpty(messagePermissions)) {
        return;
      }

      if (!isNil(needSendFirst)) {
        messagePermissions.needSendFirst = needSendFirst;
      }

      if (!isNil(canSendNext)) messagePermissions.canSend = canSendNext;

      if (!isNil(reason)) messagePermissions.reason = reason;

      client.writeQuery<
        TargetUserSendFormQuery,
        TargetUserSendFormQueryVariables
      >({
        query: TARGET_USER_SEND_FORM_QUERY,
        variables: {userId},
        data,
      });
    });

export default updateRecipientMessagePermissions;
