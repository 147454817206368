import includes from 'lodash/includes';

import ROUTES from '@core/application/constants/routes';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import {IN_APP_NAME} from '@core/application/constants/bootstrapParams';
import logger from '@core/logger';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';

const extractAction = (redirectUrl: string): string => {
  if (redirectUrl.includes('/pay/result/success')) {
    return 'success';
  }

  if (redirectUrl.includes(ROUTES.GET_THE_APP)) {
    return 'getTheApp';
  }

  const action = (/\/pay\/([A-Za-z]*)/.exec(redirectUrl)?.[1] ?? '').split(
    '/',
  )[0];

  if (
    redirectUrl.startsWith(ROUTES.PAY) &&
    !includes(PAYMENT_ACTIONS, action)
  ) {
    logger.sendWarning(
      `[extractAction] Unknown action="${action}" from server in inAppBrowserName="${getBootstrapParam(IN_APP_NAME)}"`,
    );
  }

  return action;
};

export default extractAction;
