import omit from 'lodash/omit';
import pick from 'lodash/pick';
import flow from 'lodash/flow';
import type {Location} from 'history';

import {sessionStorage} from '@core/utils/storage/storage';
import createStorageMap from '@core/utils/storage/createStorageMap';

import type {CustomHistory, CustomLocation} from '../types';
import {Action} from '../constants/history';

const createLocationConverters = (keys: string[]) => ({
  toIndexed: ({state = {}, ...location}: Location) =>
    ({
      ...location,
      ...pick(state, keys),
    }) as CustomLocation,
  fromIndexed: (location: CustomLocation) =>
    ({
      ...omit(location, keys),
      state: pick(location, keys),
    }) as Location,
});

const storage = createStorageMap<{currentIndex: number; inaccuracy: number}>(
  sessionStorage,
  ['currentIndex', 'inaccuracy'],
  'history.',
);

/**
 * Updates some history methods and props to enhance history by adding an index to each location.
 * WARNING: modifies history by reference
 *
 * @param history - it actually accepts normal `History` and converts it to `CustomHistory`.
 * @param customLocationProps - props to pick from location and save to history state.
 */
export default function applyHistoryIndexer(
  history: CustomHistory,
  customLocationProps: string[] = [],
) {
  const convertLocation = createLocationConverters([
    'state',
    'index',
    'safeBack',
    ...customLocationProps,
  ]);
  const {push, replace} = history;

  let maxHistoryLength = history.length;

  storage.inaccuracy = storage.inaccuracy || 0;
  const getLengthBasedIndex = () => history.length - storage.inaccuracy;
  const checkInaccuracy = () => {
    /**
     * In some Chrome versions on iOS history.length increases by push
     * even when you do it after back.
     * Check this problem and save the difference to calculate new index properly.
     */
    if (storage.currentIndex !== getLengthBasedIndex()) {
      const {length} = history;
      storage.inaccuracy = length - storage.currentIndex;

      // Do not log if `history.length` reached its limit.
      if (![50, 100].includes(length)) {
        // Do not track to sentry because it doesn't help to find real navigation problems.
        console.info(
          `[historyMiddleware] Incorrect history.length behaviour detected. history.length: ${length}`,
        );
      }
    }
  };

  const savedIndex = storage.currentIndex || -1;
  const safeBack: {safeBack?: boolean} = {};
  history.location = convertLocation.toIndexed(history.location);
  storage.currentIndex = history.location.index;
  if (storage.currentIndex) {
    if (getLengthBasedIndex() < storage.currentIndex) {
      // It's possible in some Chrome versions on iOS
      checkInaccuracy();
    }
  } else {
    storage.currentIndex = getLengthBasedIndex();
    if (storage.currentIndex - savedIndex !== 1) {
      safeBack.safeBack = false;
    }
  }

  const fixCurrentIndex = () => {
    const diff = history.length - maxHistoryLength;
    maxHistoryLength = history.length;
    if (diff > 0) {
      /**
       * It means history changed by some navigations inside iframe on the current page.
       * We are detecting this change to set next index properly.
       */
      storage.currentIndex += diff;
      /**
       * We can also save updated index to the current entry's state,
       * but skip for now to avoid logic complications or additional re-renders on PP.
       */
    }
  };

  history.listen(() => {
    maxHistoryLength = history.length;
    history.location = convertLocation.toIndexed(history.location);
    if (history.action === Action.PUSH) {
      checkInaccuracy();
    } else {
      storage.currentIndex = history.location.index;
    }
  });

  history.push = flow(
    (location: Location) => {
      fixCurrentIndex();
      storage.currentIndex++;
      return {...location, index: storage.currentIndex};
    },
    convertLocation.fromIndexed,
    push,
  );

  const customReplace = flow(
    (location: Location) => {
      fixCurrentIndex();
      return {
        ...location,
        ...pick(history.location, ['safeBack']),
        index: storage.currentIndex,
      };
    },
    convertLocation.fromIndexed,
    replace,
  );
  history.replace = customReplace;

  if (!history.location.index) {
    // Do not use history.replace, because it can be modified by reference
    customReplace({...history.location, ...safeBack});
    history.action = Action.PUSH;
  }

  /**
   * currentIndex can be more accurate than history.location.index when
   * iframe with navigations is(was) used on the current page.
   */
  Object.defineProperty(history, 'currentIndex', {
    get() {
      fixCurrentIndex();
      return storage.currentIndex;
    },
  });
}
