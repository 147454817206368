enum SearchWidgetAppearance {
  /**
   * All info is included in one card component.
   * Search list is like a list of "cards"
   */
  ALL_IN_CARD = 'allInCard',

  /**
   * The same as "allInCard", except counters and info placement on main photo
   * and actions, that are inverse, and are placed on blurred photo.
   */
  ALL_IN_CARD_WITH_BLURRED_ACTIONS = 'allInCardWithBlurredActions',

  /**
   * Search list is like a list of "cards", but photo
   * is round in center. Also actions are round.
   */
  ALL_IN_CARD_WITH_ROUND_PHOTO = 'allInCardWithRoundPhoto',

  /**
   * Actions are separated and floats under user photo
   */
  FLOATING_BUTTONS = 'floatingButtons',

  /**
   * User photo is round, all tags and counters and actions are placed above photo.
   * Age, login and online status is below photo.
   */
  ROUNDED = 'rounded',

  /**
   * The same as "rounded" type except that online information is displayed
   * as text instead of badge.
   */
  ROUNDED_WITH_ONLINE_AS_TEXT = 'roundedWithOnlineAsText',

  /**
   * User photo is round, there is not actions and in general user widget
   * is much more minimalistic comparing to other types of widgets.
   */
  ROUNDED_WITHOUT_ACTIONS = 'roundedWithoutActions',

  /**
   * Flat widget without any wrapper, with tags designed like "hashtags"
   * and custom user actions with unified "flirt" button that unifies message and sticker send.
   */
  FLAT_WITH_HASHTAGS_AND_FLIRT_ACTION = 'flatWithHashtagsAndFlirtAction',

  /**
   * Fullscreen widget that ignores width and heights. It's everytime 100%.
   * Closely relayed with 'searchPage' modification.
   */
  FULLSCREEN = 'fullscreen',
}

export default SearchWidgetAppearance;
