/**
 * Routes where different header parts displayed
 */
import FULLSCREEN_ROUTES from '@core/application/constants/fullscreenRoutes';
import EMPTY_ROUTES_FOR_REDIRECT from '@core/application/constants/emptyRoutesForRedirect';
import ROUTES from '@core/application/constants/routes';

export const TOOLBAR_EXCLUDE_ROUTES = [
  ROUTES.PAY,
  ROUTES.GET_THE_APP,
  ROUTES.DESCRIPTOR,
  ROUTES.FORBIDDEN,
  ...FULLSCREEN_ROUTES,
  ...EMPTY_ROUTES_FOR_REDIRECT,
];

export default TOOLBAR_EXCLUDE_ROUTES;
