/**
 * Returns date in seconds
 * @param {Date} date
 * @return {number}
 */
const getTimeInSeconds = (date = new Date()) => {
  return Math.floor(date.getTime() / 1000);
};

export default getTimeInSeconds;
