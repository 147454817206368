import type {Observable} from 'rxjs';
import {interval, of} from 'rxjs';
import {startWith, timeoutWith, switchMap, filter, first} from 'rxjs/operators';

import {DECLINE_VIA} from '@core/payment/common/constants/declineVia';
import type {Action} from '@core/types/graphql';

import PAYMENT_STATUSES from '../constants/paymentStatuses';
import type {Check3dsStatusResult} from './check3dsStatus';
import {check3dsStatusObservable} from './check3dsStatus';

const CHECK_3DS_STATUS_INTERVAL = 30000;
export const CHECK_3DS_STATUS_TIMEOUT = 240000;

const createThreeDSStatusCheckObservable = (
  action: Action,
  orderId: string,
): Observable<Check3dsStatusResult> =>
  interval(CHECK_3DS_STATUS_INTERVAL).pipe(
    startWith(0),
    switchMap(() => check3dsStatusObservable(action, orderId)),
    filter(
      ({status}) =>
        status === PAYMENT_STATUSES.SUCCESS || status === PAYMENT_STATUSES.FAIL,
    ),
    first(),
    timeoutWith(
      CHECK_3DS_STATUS_TIMEOUT,
      of<Check3dsStatusResult>({
        status: PAYMENT_STATUSES.FAIL,
        failPageLogic: DECLINE_VIA,
      }),
    ),
  );

export default createThreeDSStatusCheckObservable;
