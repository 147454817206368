import type {ApolloError} from '@apollo/client';
import {useQuery} from '@apollo/client';

import type {CleanProductRestrictionsQuery} from '../graphql/queries/cleanProductRestrictions';
import CLEAN_PRODUCT_RESTRICTIONS_QUERY from '../graphql/queries/cleanProductRestrictions.gql';

type CleanProductRestrictions = {
  withCleanProductRestrictions: boolean;
  loading: boolean;
  error?: ApolloError;
};

const useCleanProductRestrictions = (): CleanProductRestrictions => {
  const {data, loading, error} = useQuery<CleanProductRestrictionsQuery>(
    CLEAN_PRODUCT_RESTRICTIONS_QUERY,
  );

  return {
    withCleanProductRestrictions:
      data?.userFeatures?.withCleanProductRestrictions || false,
    loading,
    error,
  };
};

export default useCleanProductRestrictions;
