/**
 * It needs to determinate if user can use one click to pay for something on payment pages
 * @type {string}
 */
const PAYMENT_PAGE = 'paymentPage';

/**
 * It needs to determinate if user can use one click to pay for something on payment popup
 * @type {string}
 */
const POPUP = 'popup';

export default {
  PAYMENT_PAGE,
  POPUP,
};
