import type {FC} from 'react';

import usePhoneBreakpoint from './usePhoneBreakpoint';
import type {BreakpointProps} from './types';

const PhoneBreakpoint: FC<BreakpointProps> = ({children}) => {
  const isMatched = usePhoneBreakpoint();

  return children(isMatched);
};
export default PhoneBreakpoint;
