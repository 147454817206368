/**
 * WARNING! Non-refactored code.
 * This code is used mainly for supporting strange urls on old routes with strange routes like
 * where we must extract params
 */

import without from 'lodash/without';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import result from 'lodash/result';

import {
  UNKNOWN_PAGE_STATE_KEY,
  UNKNOWN_PAGE_NAME,
} from '@core/application/constants/history';

/**
 * Detect, that string url params in format:
 * param1:value1&param2:value2
 * or for single attribute:
 * param:value
 *
 * @param {string} query -  params as string
 * @returns {boolean}
 */
const isStringOptions = (query: string) =>
  query.indexOf('&') >= 0 || query.split(/[:=]/).length === 2;

const convertToPrimitiveIfNeeded = (
  value: string | boolean,
): string | boolean => {
  if (isString(value)) {
    if (value.match(/^true|false$/)) {
      return value === 'true';
    }

    if (value === 'null') {
      return null;
    }
  }

  return value;
};

/**
 * Parse url params in format:
 * param1:value1&param2:value2
 * or
 * param1=value1&param2=value2
 *
 * And returns an object, example:
 * {
 *     param1: 'value1',
 *     param2: 'value1'
 * }
 *
 * @param {string} query -  params as string
 * @returns {object}
 */
const parseQueryStringOptions = (query: string) => {
  const options = {};

  query.split('&').forEach((option) => {
    const param = option.split(/[:=]/);

    if (param.length !== 2) return;

    let paramName = param[0];

    /**
     * Remove hash from param value
     */
    const encodedParamValue = param[1].split('#')[0];

    const paramValue = decodeURIComponent(encodedParamValue);

    const openIndex = paramName.indexOf('[');

    if (openIndex > -1) {
      paramName = paramName.substr(0, openIndex);

      options[paramName] = options[paramName] || [];
      if (Array.isArray(options[paramName])) {
        options[paramName].push(paramValue);
      }

      return;
    }

    options[paramName] = convertToPrimitiveIfNeeded(paramValue);
  });

  return options;
};

export type ParsePathnameInput = {
  pathname: string;
  search: string;
  state?: Record<string, any>;
};

export type ParsedPathname = {
  controller: string;
  action: string;
  options?: Record<string, string>;
};

/**
 * Parse current application pathname in user readable params.
 *
 * @examples
 *
 *   Input:
 *   1) search/gender:2&ageFrom:18&ageTo:19
 *   2) search/foo/bar/1
 *   3) {pathname: '/foo', search: '?bar=1'}
 *
 *   Output:
 *   1)
 *     {
 *       action: 'index',
 *       controller: 'search',
 *       options: {
 *         gender: '2',
 *         ageFrom: '18',
 *         ageTo: '19',
 *       }
 *     }
 *
 *   2)
 *     {
 *       action: 'foo',
 *       controller: 'search',
 *       options: {
 *         bar: '1',
 *       }
 *     }
 *
 *   3)
 *     {
 *       action: 'index',
 *       controller: 'foo',
 *       options: {
 *         bar: '1',
 *       }
 *     }
 */
const parsePathname = (input: ParsePathnameInput | string): ParsedPathname => {
  let url;

  const params = {
    controller: 'index',
    action: 'index',
    options: {},
  };

  if (!input) {
    return params;
  }

  // Check if url is string or object
  if (isObject(input)) {
    // Check history state
    const {state} = input;
    /**
     * Replace url to indicate unknown page as notFound controller
     * Use for hide some logic on 404 page, mini messaged for example
     */
    if (result(state, UNKNOWN_PAGE_STATE_KEY)) {
      url = `/${UNKNOWN_PAGE_NAME}/index${input.search}`;
    } else {
      url = `${input.pathname}${input.search}`;
    }
  } else {
    url = input;
  }

  // Set controller name if it exists
  const controller = /\/(.*?)(($|\/)|\?)/.exec(url)[1];

  if (controller) {
    params.controller = controller;
  }

  // Remove first part from pathname
  const path = /\/.*?[/?](.+)/.exec(url);

  params.options = (typeof input === 'object' && input.state) || {};

  if (!path) {
    return params;
  }

  const values = without(path[1].split('?')[0].split('/'), '');
  if (!values.length) {
    return params;
  }

  if (!isStringOptions(values[0])) {
    params.action = values.shift();
  }

  for (let i = 0; i < values.length; i++) {
    if (isStringOptions(values[i])) {
      params.options = {
        ...params.options,
        ...parseQueryStringOptions(values[i]),
      };
    } else {
      params.options[values[i]] = values[++i] || null;
    }
  }

  // Parse query string options
  const queryString = url.split('?')[1];
  if (queryString) {
    params.options = {
      ...params.options,
      ...parseQueryStringOptions(queryString),
    };
  }

  return params;
};

export default parsePathname;
