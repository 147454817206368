import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';

import isPayUrl from '@core/utils/url/isPayUrl';
import parsePathname from '@core/utils/url/parsePathname';

import PaymentParamsProvider from './PaymentParamsProvider';
import PAYMENT_SOURCES from '../constants/paymentSources';

/**
 * Parses url to provide payment params for {@see usePaymentParams}.
 * This provider is required only on some PP pages, BUT IS USED EVERYWHERE,
 * because it's difficult to provide params for components rendered by
 * {@see PopupService.openPopup} and {@see useToolbar} in another way.
 */
const LocationPaymentParamsProvider = ({children}) => {
  const location = useLocation();
  const params = useMemo(() => {
    if (!isPayUrl(location.pathname)) {
      return null;
    }

    const {action, options} = parsePathname(location);
    return {...options, action, source: PAYMENT_SOURCES.PAYMENT_PAGE};
  }, [location]);

  return (
    <PaymentParamsProvider params={params}>{children}</PaymentParamsProvider>
  );
};

LocationPaymentParamsProvider.propTypes /* remove-proptypes */ = {
  children: PropTypes.node,
};

export default LocationPaymentParamsProvider;
