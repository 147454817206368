import {ACTIVITY_TYPES} from '@core/activity/constants/notificationTypes'; // @todo When video chat being removed from activities - we should move constant to videoChat workspace
import generateUniqueId from '@core/utils/id/generateUniqueId';

import {MSG} from '../constants/socketEventNames';

/**
 * All interactions local resolvers related to
 * video chats functionality
 */
export default {
  VideoCallSubscription: {
    incomingEvents: MSG + ACTIVITY_TYPES.PRIVATE_VIDEO,

    fromServer: (response) => ({
      data: {
        videoCall: {
          id: response.activityId || generateUniqueId(),
          userId: response.fromId,
          action: response.videoAction,
          /**
           * We can't lay on action id, it can be duplicated, so we use timestamp.
           */
          timestamp: response.timestamp,
          params: {
            toVideoId: response.params.toVideoId,
            fromVideoId: response.params.fromVideoId,
            __typename: 'VideoCallParams',
          },
          __typename: 'VideoCall',
        },
      },
    }),
  },

  VideoCallResponseMutation: {
    toServer({videoAction, userId, ...otherVariables}) {
      return {
        method: 'privateVideo',
        params: {toUserId: userId, videoAction, ...otherVariables},
      };
    },

    fromServer(response, error) {
      const isSuccess = Boolean(!error && response && !response.error);

      /**
       * We can get url to trusted page or to PP from interaction.
       * @type {string||null}
       */
      const redirectUrl = (isSuccess && response.upgradeUrl) || null;

      return {
        data: {
          privateVideo: {
            result: isSuccess,
            redirectUrl,
            error: response.error || null,
            message:
              isSuccess && response.messageId
                ? {
                    id: response.messageId,
                    type: response.msgType,
                    text: response.text,
                    timestamp: response.time,
                    __typename: 'Message',
                  }
                : null,
            __typename: 'PrivateVideo',
          },
        },
      };
    },
  },
};
