import {PopperPlacement} from '../../../constants';
import {Y_AXIS_POSITIONS, X_AXIS_POSITIONS} from '../constants/popperAxis';
import getPositionSide from './getPositionSide';
import type {
  GetAvailablePlacementOptions,
  GetAvailablePlacementResult,
} from '../types';

const SPECULAR_POSITIONS = {
  [PopperPlacement.TOP]: PopperPlacement.BOTTOM,
  [PopperPlacement.TOP_START]: PopperPlacement.BOTTOM_START,
  [PopperPlacement.TOP_END]: PopperPlacement.BOTTOM_END,
  [PopperPlacement.LEFT]: PopperPlacement.RIGHT,
  [PopperPlacement.LEFT_START]: PopperPlacement.RIGHT_START,
  [PopperPlacement.LEFT_END]: PopperPlacement.RIGHT_END,
};

Object.entries(SPECULAR_POSITIONS).forEach(([key, value]) => {
  SPECULAR_POSITIONS[value] = key;
});

const getPositionsForPlacement = (
  placement: PopperPlacement,
): PopperPlacement[] => {
  const specularPlacement = SPECULAR_POSITIONS[placement];

  const axisPlacements = Y_AXIS_POSITIONS.includes(placement)
    ? Y_AXIS_POSITIONS
    : X_AXIS_POSITIONS;

  return [
    placement,
    specularPlacement,
    ...axisPlacements.filter(
      (item) => item !== placement && item !== specularPlacement,
    ),
  ];
};

const getAvailablePlacement = ({
  floatingRect,
  referenceRect,
  availableSize,
  placement: defaultPlacement,
}: GetAvailablePlacementOptions): GetAvailablePlacementResult => {
  const {offsetTop, offsetLeft, availableWidth, availableHeight} =
    availableSize;

  const positions = getPositionsForPlacement(defaultPlacement);

  let canEnterOnTop = referenceRect.top - floatingRect.height >= offsetTop;
  let canEnterOnBottom =
    availableHeight >= referenceRect.bottom + floatingRect.height - offsetTop;

  let canEnterOnLeft = referenceRect.left - floatingRect.width >= offsetLeft;
  let canEnterOnRight =
    availableWidth >= referenceRect.right + floatingRect.width - offsetLeft;

  for (let i = 0; i < positions.length; i++) {
    const placement = positions[i];
    const {isOnTop, isOnBottom, isOnLeft, isOnRight} =
      getPositionSide(placement);

    switch (placement) {
      case PopperPlacement.TOP_END:
      case PopperPlacement.BOTTOM_END:
        canEnterOnLeft = referenceRect.right - floatingRect.width >= offsetLeft;
        canEnterOnRight = availableWidth >= referenceRect.right - offsetLeft;

        break;
      case PopperPlacement.TOP_START:
      case PopperPlacement.BOTTOM_START:
        canEnterOnLeft = referenceRect.left >= offsetLeft;
        canEnterOnRight =
          availableWidth >=
          referenceRect.left + floatingRect.width - offsetLeft;

        break;
      case PopperPlacement.TOP:
      case PopperPlacement.BOTTOM:
        if (referenceRect.width >= floatingRect.width) {
          const halfDiff = (referenceRect.width - floatingRect.width) / 2;

          canEnterOnLeft = referenceRect.left + halfDiff >= offsetLeft;
          canEnterOnRight =
            availableWidth >= referenceRect.right - halfDiff - offsetLeft;
        } else {
          const halfDiff = (floatingRect.width - referenceRect.width) / 2;

          canEnterOnLeft = referenceRect.left - halfDiff >= offsetLeft;
          canEnterOnRight = availableWidth >= referenceRect.right + halfDiff;
        }

        break;
      case PopperPlacement.LEFT_END:
      case PopperPlacement.RIGHT_END:
        canEnterOnTop = referenceRect.bottom - floatingRect.height >= offsetTop;
        canEnterOnBottom = availableHeight >= referenceRect.bottom - offsetTop;

        break;
      case PopperPlacement.LEFT_START:
      case PopperPlacement.RIGHT_START:
        canEnterOnTop = referenceRect.top >= offsetTop;
        canEnterOnBottom =
          availableHeight >=
          referenceRect.top + floatingRect.height - offsetTop;

        break;
      case PopperPlacement.LEFT:
      case PopperPlacement.RIGHT:
        if (referenceRect.height >= floatingRect.height) {
          const halfDiff = (referenceRect.height - floatingRect.height) / 2;

          canEnterOnTop = referenceRect.top + halfDiff >= offsetTop;
          canEnterOnBottom =
            availableHeight >= referenceRect.bottom - halfDiff - offsetTop;
        } else {
          const halfDiff = (floatingRect.height - referenceRect.height) / 2;

          canEnterOnTop = referenceRect.top - halfDiff >= offsetTop;
          canEnterOnBottom =
            availableHeight >= referenceRect.bottom + halfDiff - offsetTop;
        }

        break;
      default:
        canEnterOnTop = false;
        canEnterOnBottom = false;
        canEnterOnLeft = false;
        canEnterOnRight = false;
    }

    const canEnterOnBottomPlacement =
      isOnBottom && canEnterOnBottom && canEnterOnLeft && canEnterOnRight;

    const canEnterOnTopPlacement =
      isOnTop && canEnterOnTop && canEnterOnLeft && canEnterOnRight;

    const canEnterOnLeftPlacement =
      isOnLeft && canEnterOnLeft && canEnterOnTop && canEnterOnBottom;

    const canEnterOnRightPlacement =
      isOnRight && canEnterOnRight && canEnterOnTop && canEnterOnBottom;

    if (
      canEnterOnBottomPlacement ||
      canEnterOnTopPlacement ||
      canEnterOnLeftPlacement ||
      canEnterOnRightPlacement
    ) {
      return {canEnterOnCenter: false, placement};
    }
  }

  if (
    Y_AXIS_POSITIONS.includes(defaultPlacement) &&
    availableWidth >= floatingRect.width &&
    availableWidth >= referenceRect.right &&
    referenceRect.left >= 0
  ) {
    if (canEnterOnTop) {
      return {canEnterOnCenter: true, placement: PopperPlacement.TOP};
    }
    if (canEnterOnBottom) {
      return {canEnterOnCenter: true, placement: PopperPlacement.BOTTOM};
    }
  } else if (
    X_AXIS_POSITIONS.includes(defaultPlacement) &&
    availableHeight >= floatingRect.height &&
    availableHeight >= referenceRect.bottom &&
    referenceRect.top >= 0
  ) {
    if (canEnterOnRight) {
      return {canEnterOnCenter: true, placement: PopperPlacement.RIGHT};
    }
    if (canEnterOnLeft) {
      return {canEnterOnCenter: true, placement: PopperPlacement.LEFT};
    }
  }

  return {canEnterOnCenter: false, placement: defaultPlacement};
};

export default getAvailablePlacement;
