import type {FC} from 'react';
import React from 'react';

import type {TextAlign, TextProps} from './Text';
import Text from './Text';

const TextMuted: FC<Omit<TextProps, 'type'>> & {ALIGN: typeof TextAlign} = (
  props,
) => <Text {...props} type={Text.TYPE.MUTED} />;

TextMuted.ALIGN = Text.ALIGN;

export default TextMuted;
