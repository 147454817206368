import useActivePackage from '../../widgets/package/utils/useActivePackage';
import ALLOWED_SEPA_NAMES from '../constants/allowedSepaNames';

const useIsSepaGuaranteesAllowed = (): boolean => {
  const {activePackage} = useActivePackage();

  return (
    Boolean(activePackage) &&
    ALLOWED_SEPA_NAMES.includes(activePackage.paymentMethod)
  );
};

export default useIsSepaGuaranteesAllowed;
