import {Subject} from 'rxjs';

import isMobileViewport from '@core/responsive/isMobileViewport';
import removeActivityInCache from '@core/activity/utils/removeActivityInCache';
import {ACTIVITY_TYPES} from '@core/activity/constants/notificationTypes';
import getHistory from '@core/application/utils/getHistory';

import getActiveRecipientId from '../../common/utils/getActiveRecipientId';
import isMiniMessengerOpened from '../../common/utils/isMiniMessengerOpened';
import removeRecipientInCache from '../../common/utils/removeRecipientsInCache';
import MESSENGER_QUERY from '../../common/graphql/queries/messenger.gql';
import DELETE_CONVERSATION from '../../common/graphql/mutations/deleteConversation.gql';

const deletedRecipientIds = {};

/**
 * Using to remove recipient form deletedRecipientIds
 * when we receive or send message
 * @param recipientId
 */
export const unmarkRecipientAsDeleted = (recipientId) => {
  delete deletedRecipientIds[recipientId];
};

export const removeDeletedRecipientsInCache = () => {
  const ids = Object.keys(deletedRecipientIds);
  if (ids.length) {
    removeRecipientInCache(ids);
  }
};

/**
 * Is used to activate new recipient (or redirect to contact list) before active contact deletion.
 * @param {ApolloClient} client
 * @param {string[]} excludeIds - contact ids which will be deleted
 * @param {Function} openMessengerWith
 */
const changeActiveRecipientIfNeeded = async ({
  client,
  excludeIds,
  openMessengerWith,
}) => {
  const history = getHistory();
  const activeRecipientId = await getActiveRecipientId();

  // Change contact only if we have open chat with active recipient
  if (
    (history.location.pathname.startsWith('/chat/with/') ||
      isMiniMessengerOpened()) &&
    excludeIds.includes(activeRecipientId)
  ) {
    let userId = null;
    if (!isMobileViewport()) {
      const messengerData = client.readQuery({query: MESSENGER_QUERY});
      const recipients = messengerData.messenger.initialData.recipients.filter(
        (recipient) => !excludeIds.includes(recipient.id),
      );
      if (recipients.length) {
        userId = recipients[0].id;
      }
    }
    await openMessengerWith({userId, needGTMTrack: true, replace: true});
  }
};

/**
 * Is used to subscribe to chat deletions.
 */
export const chatDeletionStream = new Subject();

/**
 * Deletes conversation with specified users.
 * @param {ApolloClient} client - ApolloClient instance.
 * @param {array} ids - user ids to delete chat with.
 * @param {Function} openMessengerWith
 */
export const deleteChat = async ({client, ids, openMessengerWith}) => {
  const result = await client.mutate({
    mutation: DELETE_CONVERSATION,
    variables: {userIds: ids},
  });

  const {
    data: {deleteConversation},
  } = result;

  if (deleteConversation.error) {
    return result;
  }

  /**
   * Switch contact before cache deletion to avoid unwanted operations
   * in {@see MessengerDataInner.tryUpdateActiveRecipient}.
   */
  await changeActiveRecipientIfNeeded({
    client,
    excludeIds: ids,
    openMessengerWith,
  });

  const deleteIds = deleteConversation.ids;
  chatDeletionStream.next(deleteIds);
  removeActivityInCache(
    ({userId, type}) =>
      type === ACTIVITY_TYPES.MAIL && deleteIds.includes(userId),
  );

  /**
   * Remember deleted ids to clear recipients in cache after leave page
   */
  deleteIds.forEach((id) => {
    deletedRecipientIds[id] = true;
  });

  removeRecipientInCache(deleteIds);

  return result;
};
