/**
 * Script id
 * @const {String}
 */
export const GOOGLE_API_DOM_ID = 'googleApi';

/**
 * Google pay api link
 * @const {String}
 */
export const GOOGLE_PAY_SERVICE_LINK = 'https://pay.google.com/gp/p/js/pay.js';

/**
 * Google pay settings for visual test button by cookie
 * @see walletVisualTestCookies.js::GOOGLE_PAY_TEST_ENVIRONMENT
 * @type {Object}
 */
export const TEST_METHOD_SETTINGS = {
  allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
  allowedCardNetworks: [
    'AMEX',
    'DISCOVER',
    'INTERAC',
    'JCB',
    'MASTERCARD',
    'VISA',
  ],
  environment: 'TEST',
  gateway: 'shstartpay',
  gatewayMerchantId: 'ubqw916uvbu',
  merchantId: 'BCR2DN6TQ6AIJZKE',
  merchantName: window.location.host,
};
