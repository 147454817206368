import {PRIVATE_ALBUM} from '@core/coins/buyCoins/constants/vias';
import {SEARCH_LIMIT} from '@core/payment/common/constants/vias';
import {getClientInstance} from '@core/graphql/client';
import {USER_PROFILE_ROUTE} from '@core/utils/url/createUrlFor';
import SHOW_ALL_PRIVATE_PHOTOS_MUTATION from '@core/coins/graphql/mutations/showAllPrivatePhotos.gql';
import type {
  ShowAllPrivatePhotosMutation,
  ShowAllPrivatePhotosMutationVariables,
} from '@core/coins/graphql/mutations/showAllPrivatePhotos';
import SHOW_ALL_PROFILES_IN_SEARCH_MUTATION from '@core/coins/graphql/mutations/showAllProfilesInSearch.gql';
import type {
  ShowAllProfilesInSearchMutation,
  ShowAllProfilesInSearchMutationVariables,
} from '@core/coins/graphql/mutations/showAllProfilesInSearch';
import SHOW_ALL_PROFILES_IN_SEARCH_QUERY from '@core/coins/graphql/queries/showAllProfilesInSearch.gql';
import type {ShowAllProfilesInSearchQuery} from '@core/coins/graphql/queries/showAllProfilesInSearch';
import TARGET_USER_PHOTOS_FRAGMENT from '@core/user/profile/target/graphql/fragments/targetUserPhotos.gql';
import type {TargetUserPhotosFragment} from '@core/user/profile/target/graphql/fragments/targetUserPhotos';
import normalizeMutationErrors from '@core/graphql/utils/normalizeMutationErrors';
import logger from '@core/logger';
import PAYMENT_MODEL_QUERY from '@core/payment/common/graphql/queries/paymentModel.gql';
import type {PaymentModelQuery} from '@core/payment/common/graphql/queries/paymentModel';
import PAYMENT_MODELS from '@core/payment/common/constants/paymentModels';

type SearchParams = {
  via?: string;
  returnPath?: string;
};

const activateFeaturesAfterSuccessByVia = async (): Promise<void> => {
  /**
   * Get search params need for pars current url
   * After payment model query url may change.
   */
  const searchParams: SearchParams = Object.fromEntries([
    ...new URLSearchParams(window.location.search),
  ]) as SearchParams;
  const {via = '', returnPath = ''} = searchParams;

  if (!via || ![PRIVATE_ALBUM, SEARCH_LIMIT].includes(via)) {
    return;
  }

  const client = getClientInstance();

  const {
    data: {
      payment: {model},
    },
  } = await client.query<PaymentModelQuery>({
    query: PAYMENT_MODEL_QUERY,
  });

  if (model !== PAYMENT_MODELS.COINS) {
    return;
  }

  if (via === PRIVATE_ALBUM && returnPath) {
    const userId = returnPath.replace(USER_PROFILE_ROUTE, '');

    if (userId) {
      client.mutate<
        ShowAllPrivatePhotosMutation,
        ShowAllPrivatePhotosMutationVariables
      >({
        mutation: SHOW_ALL_PRIVATE_PHOTOS_MUTATION,
        variables: {userId},
        update: (cache, {data: resultData}) => {
          const {result, errors} =
            resultData?.credits?.showAllPrivatePhotos || {};

          if (result) {
            const fragmentOptions = {
              fragment: TARGET_USER_PHOTOS_FRAGMENT,
              fragmentName: 'TargetUserPhotos',
              id: `Profile:${userId}`,
            };
            const fragmentData: TargetUserPhotosFragment =
              cache.readFragment<TargetUserPhotosFragment>(fragmentOptions);

            if (fragmentData) {
              cache.writeFragment<TargetUserPhotosFragment>({
                ...fragmentOptions,
                data: {
                  photos: {
                    ...fragmentData.photos,
                    allPhotos: fragmentData.photos.allPhotos.map((photo) => {
                      if (photo?.privateAttributes?.isPrivate) {
                        return {
                          ...photo,
                          privateAttributes: {
                            ...photo.privateAttributes,
                            isRequested: true,
                          },
                        };
                      }

                      return photo;
                    }),
                  },
                },
              });
            }

            return;
          }

          const normalizedErrors = errors && normalizeMutationErrors(errors);

          if (normalizedErrors) {
            logger.sendError(
              '[activateFeaturesAfterSuccessByVia] showAllPrivatePhotos mutation error',
              normalizedErrors,
            );
          }
        },
      });
    }
  }

  if (via === SEARCH_LIMIT) {
    client
      .mutate<
        ShowAllProfilesInSearchMutation,
        ShowAllProfilesInSearchMutationVariables
      >({
        mutation: SHOW_ALL_PROFILES_IN_SEARCH_MUTATION,
        update: (cache, {data}) => {
          const {result, errors} = data?.credits?.showAllProfilesInSearch || {};

          if (result) {
            const showAllProfilesData: ShowAllProfilesInSearchQuery =
              cache.readQuery<ShowAllProfilesInSearchQuery>({
                query: SHOW_ALL_PROFILES_IN_SEARCH_QUERY,
              });

            if (showAllProfilesData) {
              cache.writeQuery<ShowAllProfilesInSearchQuery>({
                query: SHOW_ALL_PROFILES_IN_SEARCH_QUERY,
                data: {
                  ...showAllProfilesData,
                  credits: {
                    ...showAllProfilesData.credits,
                    showAllProfilesInSearchAllowed: true,
                  },
                },
              });
            }
          }

          const normalizedErrors = errors && normalizeMutationErrors(errors);

          if (normalizedErrors) {
            logger.sendError(
              '[activateFeaturesAfterSuccessByVia] showAllProfilesInSearch mutation error',
              normalizedErrors,
            );
          }
        },
      })
      .catch(() => {
        logger.sendError(
          '[activateFeaturesAfterSuccessByVia] showAllProfilesInSearch mutation error',
          'An error occurred while showing all profiles in search',
        );
      });
  }
};

export default activateFeaturesAfterSuccessByVia;
