import TYPES from '@core/upsellPopup/constants/upsellTypes';

import {MSG} from '../constants/socketEventNames';

export default {
  UpsellSubscription: {
    incomingEvents: Object.values(TYPES).map((type) => MSG + type),

    fromServer: ({type}) => ({
      data: {
        upsell: {
          type,
          __typename: 'Upsell',
        },
      },
    }),
  },
};
