import omitBy from 'lodash/omitBy';
import {gql} from '@apollo/client';

import URI from '@core/utils/url';
import logger from '@core/logger';
import {FAST_PP_ROUTES} from '@core/application/constants/routes';
import {getClientInstance} from '@core/graphql/client';
import {localStorage} from '@core/utils/storage/storage';
import {PAY_MEMBERSHIP_URL} from '@core/utils/url/isPayMembershipUrl';
import isPayUrl from '@core/utils/url/isPayUrl';

import {UNKNOWN_VIA} from '../constants/vias';
import PAYMENT_ACTIONS from '../constants/paymentActions';
import {IS_FAST_PAYMENT_PAGE} from '../constants/localStorageKeys';
import isEnabledReactPaymentPage from './isEnabledReactPaymentPage';
import getFragmentTokens from './getFragmentTokens';

export type AccountStatus = {
  isPaid: boolean;
  notBoughtAccountFeatures?: Array<string> | null;
};

export type UrlParams = {
  via?: string;
  viaProfileId?: string;
  prevVia?: string;
  viaMethod?: string;
  returnPath?: string;
  skipStepOnBack?: boolean;
  successReturnPath?: string;
  userId?: string;
  packageId?: string;
  stockId?: string;
  orderId?: string;
};

type GeneratePayUrlParams = {
  stage?: string;
  skipPaidStage?: boolean;
  urlParams?: UrlParams;
  accountStatus?: AccountStatus;
};

export const getUserPaymentStage = ({
  accountStatus,
  skipPaidStage,
}: {
  accountStatus: AccountStatus;
  skipPaidStage?: boolean;
}): string | null => {
  if (!accountStatus) {
    return null;
  }
  const nonPaidStage = PAYMENT_ACTIONS.MEMBERSHIP;
  const paidStage = PAYMENT_ACTIONS.FEATURES;
  const {isPaid, notBoughtAccountFeatures} = accountStatus;

  if (skipPaidStage) {
    return isPaid ? null : nonPaidStage;
  }
  // If user is paid and haven't any available features - is fully paid user
  if (isPaid && notBoughtAccountFeatures && !notBoughtAccountFeatures.length) {
    return null;
  }

  if (isPaid && notBoughtAccountFeatures && notBoughtAccountFeatures.length) {
    return paidStage;
  }

  return nonPaidStage;
};

/**
 * Function for generating payment url by applying stage 'features, microFeatures'
 * or check user account status and return membership or features url
 */
const generatePayUrl = ({
  stage,
  urlParams,
  accountStatus,
  skipPaidStage,
}: GeneratePayUrlParams): string | null => {
  if (!stage && !accountStatus) {
    logger.sendError(
      '[generatePayUrl] There is empty stage or accountStatus to define stage for redirecting user on pp!!!',
    );
    return null;
  }

  const paymentStage =
    stage || getUserPaymentStage({accountStatus, skipPaidStage});

  // If user is fully paid we return null
  if (!paymentStage) {
    return null;
  }

  // Add token with userId to url, for automatic login when switch from inApp to default browser
  const fragmentTokens = getFragmentTokens();
  const params = {
    ...urlParams,
    ...fragmentTokens,
  };

  if (isEnabledReactPaymentPage()) {
    if (!params.stockId) {
      params.stockId = params.packageId;
      delete params.packageId;
    }

    /**
     * ATTENTION! Use ONLY if getBootstrapParam(ENABLE_REACT_PAY) is enabled
     * Fix for adding a redirectPath in payUrl for which a redirectPath is not implemented
     * TODO: Remove after the redirectPath is implemented in all necessary places
     */
    if (!params.returnPath && !isPayUrl(window.location.pathname)) {
      params.returnPath = window.location.pathname;
    }
  }

  if (!params.via) {
    params.via = UNKNOWN_VIA;
  }

  const payUrl = URI(
    paymentStage === PAYMENT_ACTIONS.SUCCESS
      ? '/pay/result/success'
      : `/pay/${paymentStage}`,
  ).setSearch(omitBy(params, (item) => !item));

  /**
   * Stub for experiment with fast payment page, will be removed after experiment
   * Data is preloaded in app data query
   * TODO: move to separated query and generate type to use instead of `any`.
   */
  const data = getClientInstance().readQuery<any>({
    query: gql`
      query {
        payment {
          isFastPaymentPage
        }
        myUser {
          id
          profile {
            id
            location {
              country
            }
          }
        }
      }
    `,
  });

  if (
    data?.payment?.isFastPaymentPage ||
    localStorage.getItem(IS_FAST_PAYMENT_PAGE)
  ) {
    const replaceValue = {
      USA: FAST_PP_ROUTES.FAST_PP_ROUTES_USA,
      GBR: FAST_PP_ROUTES.FAST_PP_ROUTES_GBR,
      FRA: FAST_PP_ROUTES.FAST_PP_ROUTES_FRA,
    }[data?.myUser?.profile?.location?.country];

    if (replaceValue) {
      return payUrl.toString().replace(PAY_MEMBERSHIP_URL, replaceValue);
    }
  }

  return payUrl.toString();
};

export default generatePayUrl;
