/**
 * The position of the arrows determines where the arrows will appear at the top near the counter
 * or on the sides of the gallery.
 * These arrows are only in galleries if there are two or more photos or videos.
 */
enum ArrowPositionAppearance {
  /**
   * Arrow position on the sides
   */
  ON_SIDES = 'onSides',

  /**
   * Position of the arrows near the counter
   */
  ON_TOP = 'onTop',
}

export default ArrowPositionAppearance;
