import t from '@core/translations/translate';

import MediaError from '../../MediaError';

/**
 * Validate video duration
 *
 * @param {object} file
 * @param {number} minLenght
 * @returns {Promise<unknown>}
 */
const validateVideoDuration = ({file, minLenght}) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.src = window.URL.createObjectURL(file);

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);

      const seconds = video.duration;

      if (!!seconds && seconds < minLenght) {
        reject(
          new Error(
            t('uploadButtonMessages', 'error.limit_videos', {
              '{minimumVideoDuration}': minLenght,
            }),
          ),
        );
      } else {
        resolve();
      }
    };

    video.onerror = ({target: {error}}) => {
      window.URL.revokeObjectURL(video.src);

      const mediaError = new MediaError(error);

      /**
       * We *ALLOW* users to upload video with uninstalled codecs,
       * because our API converts allowed formats to mp4.
       */
      if (mediaError.unsupportedCodec()) {
        resolve();
      } else {
        reject(new Error(mediaError.message));
      }
    };
  });
};

export default validateVideoDuration;
