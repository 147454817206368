/**
 * DO NOT TRANSFORM THE BREAKPOINT CONSTANT INTO AN ENUM!!!!!!!!
 * Because TypeScript numeric enums have bidirectional mapping.
 * https://refine.dev/blog/typescript-enum/#typescript-string-enums-have-unidirectional-mapping
 */
export const BREAKPOINT = {
  DESKTOP: 1025,
  TABLET: 1024,
  PHONE: 767,
  SMALL_TABLET: 711,
  SMALL_PHONE: 479,
  COMPACT_PHONE: 359,
};

/**
 * !WARNING: IT'S IMPORTANT FOR MEDIA QUERIES TO BE IN PIXEL-DESCENDING ORDER!
 * See MEDIA_QUERY usage in {@see ResponsiveProvider}.
 */
const MEDIA_QUERY = {
  // We don't need DESKTOP here: just used `true` when all others are `false` (see ResponsiveProvider).

  TABLET: `(max-width:${BREAKPOINT.TABLET}px)`,
  PHONE: `(max-width:${BREAKPOINT.PHONE}px)`,
  /**
   * Used for non-standard tablets, where resolution of screen
   * is smaller them phone breakpoint/ Example Samsung S6 tab etc.
   * @see RemarketingOfferPopup.js
   */
  SMALL_TABLET: `(max-width:${BREAKPOINT.SMALL_TABLET}px)`,
  SMALL_PHONE: `(max-width:${BREAKPOINT.SMALL_PHONE}px)`,
  /**
   * Need for better adaptation of content for small screens
   * like Iphone SE, Samsung A51.
   * Where sometimes we must put more visual data on screen
   */
  COMPACT_PHONE: `(max-width:${BREAKPOINT.COMPACT_PHONE}px)`,
};

export default MEDIA_QUERY;
