import {
  decodeLink,
  formatParams,
  getAllHashedLinksList,
} from '@core/application/utils/hashedStaticLinks';
import {gotoUrl, parsePathname} from '@core/utils/url';

import {DNSMPI_SELECTOR} from '../constants/selectors';

/**
 * Static pages are comes with a ready layout, so need to find specific elements
 * and add event listeners to it.
 */
const getCommonEvents = ({openDnsmpiPopup}) => {
  return [
    {
      selector: DNSMPI_SELECTOR,
      callback: ({event}) => {
        event.preventDefault();

        openDnsmpiPopup();
      },
      eventType: 'click',
    },
  ];
};

/**
 * Get selector for all hashed links on static page
 * ('a[href="/FnZS9kbnNtcGluYXVnaHR5ZGF0ZS5jb20="], a[href="/c3RhdGljUGFnZS9kbnNtcGluYXVnaHR5ZGF0ZS5jb20="]')
 * @return {string}
 */
const getHashedPagesSelector = (externalDomain) =>
  getAllHashedLinksList(externalDomain)
    .map((link) => `a[href="${link}"]`)
    .join(', ');

const staticPageEvent = {
  selector: 'a[href^="/staticPage/"]',
  callback: ({event, history}) => {
    event.preventDefault();
    event.stopPropagation();

    const target = event.target.getAttribute('target');
    const href = event.target.getAttribute('href');

    if (target) {
      gotoUrl(href, true);
    } else {
      history.push(href);
    }
  },
  eventType: 'click',
};

const getPageEvents = ({openDnsmpiPopup, isCopyEnabled = false}) => {
  const events = [
    staticPageEvent,
    {
      ...staticPageEvent,
      selector: getHashedPagesSelector(),
    },
    ...getCommonEvents({openDnsmpiPopup}),
  ];

  if (!isCopyEnabled) {
    events.push({
      selector: document,
      callback: ({event}) => {
        event.preventDefault();
      },
      eventType: 'contextmenu',
    });
  }
  return events;
};

const getStaticHashedPopupEvent = (handleLinkClick, externalDomain) => {
  return {
    selector: '',
    callback: ({event}) => {
      event.preventDefault();
      event.stopPropagation();

      const {pathname} = event.currentTarget;
      const decodedLink = decodeLink(pathname, externalDomain);
      const pathnameParsed = parsePathname(decodedLink);

      handleLinkClick(
        `${pathnameParsed.action}${formatParams(pathnameParsed.options)}`,
      );
    },
    eventType: 'click',
  };
};

const getPopupEvents = ({openDnsmpiPopup, handleLinkClick, externalDomain}) => {
  return [
    {
      selector: 'a[href^="/staticPage/"]',
      callback: ({event}) => {
        event.preventDefault();
        event.stopPropagation();

        const {search, pathname} = event.currentTarget;

        handleLinkClick(`${pathname.replace(/\/?staticPage\//, '')}${search}`);
      },
      eventType: 'click',
    },
    {
      ...getStaticHashedPopupEvent(handleLinkClick, externalDomain),
      selector: getHashedPagesSelector(externalDomain),
    },
    ...getCommonEvents({openDnsmpiPopup}),
  ];
};

/**
 * @param {boolean} isPopup
 * @param {boolean} isCopyEnabled
 * @param {function} linkClickHandler
 * @returns {array}
 */
const getStaticPageEvents = ({
  isPopup,
  isCopyEnabled = false,
  handleLinkClick,
  openDnsmpiPopup = () => {},
  externalDomain,
}) => {
  return isPopup
    ? getPopupEvents({openDnsmpiPopup, handleLinkClick, externalDomain})
    : getPageEvents({openDnsmpiPopup, isCopyEnabled});
};

export default getStaticPageEvents;
