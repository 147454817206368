import {useEffect} from 'react';
import debounce from 'lodash/debounce';

import {HEADER_ID} from '../constants/header';
import DEBOUNCE_DELAY from '../constants/delayKey';
import setHeaderHeightVariable from './setHeaderHeightVariable';

/* eslint-disable consistent-return */
const useCSSHeaderHeight = () =>
  useEffect(() => {
    const header = document.getElementById(HEADER_ID);

    /**
     * There is a case when mutation was never called during appliction bootstrap.
     * for example on fullscreen routes such as 1-1 video chats, video rooms, etc.
     */
    if (header) {
      setHeaderHeightVariable(header.clientHeight);

      const config = {childList: true, subtree: true};
      const callback = debounce(() => {
        setHeaderHeightVariable(
          // Find the site header again in order to get the latest values.
          document.getElementById(HEADER_ID).clientHeight,
        );
      }, DEBOUNCE_DELAY);

      const mutationObserver = new MutationObserver(callback);
      mutationObserver.observe(header, config);

      return () => {
        mutationObserver.disconnect();
        callback.cancel();

        setHeaderHeightVariable(0);
      };
    }
  }, []);

export default useCSSHeaderHeight;
