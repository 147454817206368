import {TRUSTED_STATUSES, STATUS_ID_MAP} from '../constants/status';

/**
 * Due to security reasons, the verification status field has been shortened and the status enum has been converted
 * to a numeric field so these functions convert the status to a human-readable form
 * @see {trusted: {sv}} type in graphql schema
 * @param {int} statusId
 * @returns {TRUSTED_STATUSES}
 */
const getTrustedStatusById = (statusId) =>
  STATUS_ID_MAP[statusId] || TRUSTED_STATUSES.NON_VERIFIED;

export default getTrustedStatusById;
