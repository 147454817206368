import t from '@core/translations/translate';
import trackMediaUploadError from '@core/tracking/mediaUploadError/trackMediaUploadError';

/**
 *
 * @param {object} file
 * @param {number} width
 * @param {number} height
 * @param {number} maxSizeInMb
 * @param {boolean} allowedExtensionsString
 * @returns {Promise<unknown>}
 */

const validateImageSize = ({
  file,
  width,
  height,
  maxSizeInMb,
  allowedExtensionsString,
}) => {
  return new Promise((resolve, reject) => {
    const img = new window.Image();

    img.onload = () => {
      window.URL.revokeObjectURL(img.src);

      if (img.width >= width && img.height >= height) {
        resolve();
        return;
      }

      trackMediaUploadError({
        errorType: 'uploadPhotoResolutionLimit',
      });

      reject(
        new Error(
          t('popupSendPhoto', 'text.description', {
            '{width}': width,
            '{height}': height,
            '{allowedExtensions}': allowedExtensionsString,
            '{maximumPhotoSize}': maxSizeInMb,
          }),
        ),
      );
    };

    img.onerror = () => {
      window.URL.revokeObjectURL(img.src);

      trackMediaUploadError({
        errorType: 'uploadPhotoInDisabledFormat',
        format: 'on_load_photo',
      });
      reject(
        new Error(
          t('uploadButtonMessages', 'error.invalid_extension_photo', {
            '{allowedExtensions}': allowedExtensionsString,
          }),
        ),
      );
    };

    img.src = window.URL.createObjectURL(file);
  });
};

export default validateImageSize;
