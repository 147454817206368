import t from '@core/translations/translate';
import logger from '@core/logger';
import {getClientInstance} from '@core/graphql/client';
import VIDEO_UPLOAD_SETTINGS_QUERY from '@core/mediaUpload/graphql/queries/videoUploadSettings.gql';

import truncate from './filenameTruncate';
import getRandomFileName from './getRandomFileName';

/**
 * Translates server errors to translated messages for video errors
 *
 * @param {String} error - Error from server
 * @param {String|null} fileName - File name
 * @returns Translated message
 */
export default async function getVideoErrorMessage(
  error,
  fileName = getRandomFileName('webm'),
) {
  const {
    data: {
      userFeatures: {
        userVideo: {
          settings: {limit},
        },
      },
    },
  } = await getClientInstance().query({query: VIDEO_UPLOAD_SETTINGS_QUERY});
  const truncatedFileName = truncate(fileName);

  switch (error) {
    case 'maximum user videos reached_video':
      return t('uploadButtonMessages', 'maximum user videos reached_video', {
        '{maximumVideoAmount}': limit,
      });
    case 'maximum user videos reached':
      return t('uploadButtonMessages', 'maximum user videos reached', {
        '{maximumVideoAmount}': limit,
      });
    case 'uploaded by another user':
    case 'uploadedByAnotherUser':
      return t('uploadButtonMessages', 'uploaded by another user');
    case 'can not link to user':
      return t('uploadButtonMessages', 'can not link to user');
    case 'incorrect_duration':
      return t('uploadButtonMessages', 'text.incorrect_duration');
    case 'uploaded by another user_video':
      return t('uploadButtonMessages', 'uploaded by another user_video', {
        '{file}': truncatedFileName,
      });
    case 'uploaded_by_another_user':
      return t('uploadButtonMessages', 'uploaded_by_another_user', {
        '{fileName}': truncatedFileName,
      });
    case 'video already uploaded':
      return t('uploadButtonMessages', 'video already uploaded');
    case 'video declined by policy':
    case 'videoDeclinedByPolicy':
      return t('uploadButtonMessages', 'video declined by policy');
    case 'video already uploaded_video':
      return t('uploadButtonMessages', 'video already uploaded_video');
    // For upload of stories
    case 'error.stories_upload_limit_exceeded':
      return t('uploadButtonMessages', 'error.stories_upload_limit_exceeded');
    default:
      logger.sendWarning(
        `[getVideoErrorMessage] LOST TRANSLATE: (feature: uploadButtonMessages, key: ${error})`,
      );
  }

  return error;
}
