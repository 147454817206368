import type {FC} from 'react';
import React from 'react';
import cn from 'classnames';

import css from './Placeholder.css';

export interface PlaceholderProps {
  inverse?: boolean;
  inline?: boolean;
  className?: string;
}

/**
 * Abstract placeholder widget
 */
const Placeholder: FC<PlaceholderProps> = ({className, inverse, inline}) => (
  <div
    className={cn(
      css.placeholder,
      // Disable animation on placeholders for avoiding unstable tests
      window &&
        !window.IS_INTEGRATION_TEST_ENVIRONMENT &&
        css.animatedPlaceholder,
      inverse && css.inverse,
      inline && css.inline,
      className,
    )}
  />
);

export default Placeholder;
