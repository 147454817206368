import logger from '@core/logger';
import type {PaymentProcessStep} from '@core/payment/payProcess/types/paymentProcess';
import processPaymentData from '@core/payment/payProcess/utils/processPaymentData';

import PaymentScenario from '../../../common/constants/paymentScenario';
import type {PaymentDataResponseOrStatus} from '../../types/paymentFlow';
import tryPay from '../../utils/tryPay';

const payByMethodFromRemarketingPopup: PaymentProcessStep = async (data) => {
  const {paymentData} = data;
  let result: PaymentDataResponseOrStatus;

  const paymentDataForPay = {
    ...paymentData,
    scenario: PaymentScenario.ONECLICK,
  };

  try {
    result = await tryPay(paymentDataForPay);
  } catch (error) {
    logger.sendError(`[payByMethodFromRemarketingPopup] pay error ${error}`);
    result = {...paymentDataForPay, status: false};
  }

  return processPaymentData(data, result);
};

export default payByMethodFromRemarketingPopup;
