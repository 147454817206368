import {getClientInstance} from '@core/graphql/client';
import CACHED_URL_FRAGMENT from '@core/user/photo/graphql/fragments/cachedUrl.gql';
import type {CachedUrlFragment} from '@core/user/photo/graphql/fragments/cachedUrl';

const setCachedPhotoUrl = (id: string, url: string): void => {
  if (window.IS_INTEGRATION_TEST_ENVIRONMENT) {
    return;
  }

  const fragment = {
    fragment: CACHED_URL_FRAGMENT,
    id: `PhotoType:${id}`,
  };

  const client = getClientInstance();
  const data = client.readFragment<CachedUrlFragment>(fragment);

  if (data && !data.cachedUrl) {
    client.writeFragment<CachedUrlFragment>({
      ...fragment,
      broadcast: false,
      data: {...data, cachedUrl: url},
    });
  }
};

export default setCachedPhotoUrl;
