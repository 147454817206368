import PAYMENT_ACTIONS from '../../../common/constants/paymentActions';

/**
 * Check current action to show renewal checkbox
 * @param {String} action
 * @returns {Boolean}
 */
const isAutoRenewalAgreeAction = (action) =>
  [PAYMENT_ACTIONS.MEMBERSHIP, PAYMENT_ACTIONS.FEATURES].includes(action);

export default isAutoRenewalAgreeAction;
