import last from 'lodash/last';

import getVideoErrorMessage from '@core/mediaUpload/utils/getVideoErrorMessage';

import {MSG} from '../constants/socketEventNames';

export default {
  DeclinedGroupVideoSubscription: {
    incomingEvents: [
      `${MSG}declinedGroupVideo`,
      `${MSG}groupVideoUploadFailed`,
    ],

    async fromServer({group, errors, userId}) {
      const error = errors
        ? await getVideoErrorMessage(last(errors.errors))
        : null;

      return {
        data: {
          declineVideoGroup: {
            /**
             * Need for exclude system notifications, which will come after removing group
             * @see StoriesDeclineGroupVideoDataSource
             */
            userId,
            group,
            error,
            __typename: 'declineVideoGroup',
          },
        },
      };
    },
  },
};
