import type EventName from '@core/gtm/constants/EventName';

type WindowWithDataLayer = Window & {
  dataLayer: Record<'event', EventName>[];
};

declare const window: WindowWithDataLayer;

type GtmArgs = {
  eventName: EventName;
};

const sendToGTM = ({eventName}: GtmArgs) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
  });
};

export default sendToGTM;
