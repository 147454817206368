import {RM_POPUP} from '@core/tracking/constants/identities';

export default {
  TrackRmPopupMutation: {
    toServer: (params) => ({method: RM_POPUP.METHOD, params}),

    fromServer: (response, error) => ({
      data: {
        trackRmPopup: {
          error: error || null,
          __typename: 'RmPopupMutationResult',
        },
      },
    }),
  },
};
