import type {ReactElement, ReactNode} from 'react';
import React from 'react';

import type {CSSModule} from '@core/ui/types';

import Popper from './Popper';
import PopperPlacement from '../../constants/PopperPlacement';
import PopperTrigger from '../../constants/PopperTrigger';
import baseCss from './Tooltip.css';

export interface TooltipProps {
  children: ReactElement;
  content: ReactNode;
  active?: boolean;
  error?: boolean;
  placement?: PopperPlacement;
  trigger?: PopperTrigger;
  trackingName?: string;
  spaced?: boolean;
  className?: string;
  usePortal?: boolean;
}

/**
 * @class Tooltip
 * @classdesc Tooltip helper class to add widgets on page
 */
const Tooltip: React.FC<TooltipProps & {css: CSSModule}> = ({
  children,
  css,
  trackingName,
  placement = PopperPlacement.BOTTOM,
  trigger = PopperTrigger.HOVER,
  error = false,
  spaced = true,
  usePortal = true,
  ...props
}) => (
  <Popper
    baseCss={baseCss}
    css={css}
    trackingName={trackingName && `${trackingName}Tooltip`}
    placement={placement}
    trigger={trigger}
    error={error}
    spaced={spaced}
    usePortal={usePortal}
    {...props}
  >
    {children}
  </Popper>
);

export default Tooltip;
