import type {FC, ReactElement} from 'react';
import React, {Children, cloneElement} from 'react';

import type SpacingSize from '../../constants/SpacingSize';
import {Group} from '../group';
import type {RadioProps} from './Radio';

export interface RadioGroupProps {
  children: ReactElement<RadioProps>[];
  onChange?: RadioProps['onChange'];
  name: string;
  checkedValue?: string | number;
  className?: string;
  itemWrapperClassName?: string;
  horizontal?: boolean;
  inline?: boolean;
  space?: SpacingSize;
}

/**
 * Groupping component for radio buttons that bubbles change event up
 * @see Group
 */
const RadioGroup: FC<RadioGroupProps> = ({
  children,
  className,
  itemWrapperClassName,
  name,
  horizontal = false,
  inline = false,
  onChange,
  checkedValue,
  space,
}) => (
  <Group
    inline={inline}
    horizontal={horizontal}
    className={className}
    itemWrapperClassName={itemWrapperClassName}
    space={space}
    data-test="radioGroup"
  >
    {Children.map(children, (child) =>
      cloneElement(child, {
        checked: String(child.props.value) === String(checkedValue),
        onChange,
        name,
      }),
    )}
  </Group>
);

export default RadioGroup;
