import type {FC} from 'react';
import React from 'react';

import type {TitleProps, TitleAlign} from './Title';
import Title from './Title';

const Title2: FC<Omit<TitleProps, 'level'>> & {ALIGN: typeof TitleAlign} = (
  props,
) => <Title {...props} level={2} />;

Title2.ALIGN = Title.ALIGN;

export default Title2;
