import {MSG} from '../constants/socketEventNames';

export default {
  VideoMutedSubscription: {
    incomingEvents: `${MSG}UserVideoMuted`,

    fromServer: ({videoId}) => ({
      data: {videoMute: {videoId, __typename: 'VideoMute'}},
    }),
  },
};
