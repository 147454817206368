import type {FC} from 'react';
import React from 'react';
import cn from 'classnames';

import type {PlaceholderProps} from './Placeholder';
import Placeholder from './Placeholder';
import css from './PlaceholderCircle.css';

export interface PlaceholderCircleProps extends PlaceholderProps {
  /**
   * Numbers mean sizes in pixels.
   * For e.g. 40 is 40px, 80 is 80px.
   * For listing of sizes see Circle.css
   */
  size?: 20 | 40 | 60 | 80 | 120;
  /**
   * Means, that width and height will be equal. Can't be used together with "size" prop (@see PlaceholderBlock.tsx)
   */
  square?: boolean;
}

/**
 * Cirlce placeholder used to illustrate possible images
 */
const Circle: FC<PlaceholderCircleProps> = ({
  size,
  className,
  square,
  ...props
}) => {
  const placeholder = (
    <Placeholder
      {...props}
      className={cn(className, css.circle, size && css[`circle${size}`])}
    />
  );

  if (square) {
    return <div className={css.square}>{placeholder}</div>;
  }

  return placeholder;
};

export default Circle;
