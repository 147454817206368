/* eslint-disable class-methods-use-this */

import {Subject, EMPTY} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import without from 'lodash/without';

import validatePhoto from './validation/validatePhoto';
import validateVideo from './validation/validateVideo';
import uploadPhoto from './upload/uploadPhoto';
import uploadVideo from './upload/uploadVideo';
import putVideoInCacheSubscription from './putVideoInCacheSubscription';
import waitVideoConvert from './waitVideoConvert';

/**
 * Upload service. Used for photo/video upload.
 */
class UploadService {
  /**
   * Currently uploading files. Used for duplicated file upload validation.
   */
  filesInProgress = [];

  /**
   * Add file in progress.
   *
   * @param {Object} props
   * @param {Blob} props.file - File that is uploading
   * @param {Promise} props.promise - Upload promise returned by uploadPhoto/uploadVideo
   */
  addFileInProgress({file, promise}) {
    this.filesInProgress.push(file);
    promise.finally(() => {
      this.filesInProgress = without(this.filesInProgress, file);
    });
  }

  /**
   * Upload photo
   *
   * @param {Object} props
   * @param {Blob} props.file - Photo file to upload
   * @param {Object} props.settings - Upload settings. @see settingsPropType
   * @returns {Promise} Uploading promise.
   */
  uploadPhoto({file, settings}) {
    const {attachToUser, via, country, notifySuccess} = settings;
    return validatePhoto({file})
      .then(() =>
        uploadPhoto({file, attachToUser, via, country, notifySuccess}),
      )
      .then((result) => ({file, settings, result}))
      .catch((error) => ({file, settings, error}));
  }

  /**
   * Upload photos multiple
   *
   * @param {Object} props
   * @param {Blob[]} props.files - Photos to be uploaded
   * @param {Object} ...props - Other props for uploadPhoto method
   * @returns {Promise} Uploading promise
   */
  uploadPhotosMultplie({files, ...props}) {
    return Promise.all(files.map((file) => this.uploadPhoto({file, ...props})));
  }

  /**
   * Validate video
   *
   * @param {Object} props
   * @param {Blob} props.file - Video file to be validated
   * @param {Object} props.settings - Upload settings. @see settingsPropType
   * @returns {Promise}
   */
  validateVideo({file, settings}) {
    const {attachToUser} = settings;
    return validateVideo({
      file,
      attachToUser,
      filesInProgress: this.filesInProgress,
    });
  }

  /**
   * Upload video
   *
   * @param {Object} props
   * @param {Blob} props.file - Video file
   * @param {Object} props.settings - Upload settings. @see settingsPropType.
   * @returns {Promise}
   */
  uploadVideo({file, settings, skipConvert = false}) {
    const {attachToUser, via, progress$} = {
      progress$: this.createProgressObservable(),
      ...settings,
    };
    const promise = uploadVideo({file, attachToUser, via, progress$})
      .then((result) => {
        if (skipConvert) return result;
        return waitVideoConvert(result);
      })
      .then((result) => ({file, settings, result}))
      .catch((error) => ({file, settings, error}));

    if (attachToUser) {
      putVideoInCacheSubscription({promise, progress$, via});
    }

    this.addFileInProgress({file, promise});

    return promise;
  }

  /**
   * Create observable to watch upload progress.
   *
   * @returns {Observable}
   */
  createProgressObservable() {
    return new Subject().pipe(
      map(({loaded, total}) => loaded / total),
      catchError(() => EMPTY),
    );
  }
}

export default new UploadService();
