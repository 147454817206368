/**
 * Create package price with amount and currency
 * @param amount
 * @param suffix
 * @param prefix
 * @return {string}
 */
const getPriceWithCurrency = (amount, {suffix, prefix} = {}) => {
  return `${prefix || ''}${amount}${suffix || ''}`;
};

export default getPriceWithCurrency;
