/**
 * Send to server request "getPopunderTargetUrl" to receive URL for "popunder".
 */
export default {
  PopunderTargetUrlMutation: {
    toServer: (params) => ({method: 'getPopunderTargetUrl', params}),

    fromServer: (response) => ({
      data: {
        userFeatures: {
          popunderTargetUrl: {
            result: response?.targetUrl || null,
            __typename: 'PopunderMutationResult',
          },
          __typename: 'UserFeaturesPayload',
        },
      },
    }),
  },
};
