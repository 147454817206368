enum SplitBasedAppearance {
  /**
   * Special flag if functionality is based on split flag instead of frontend.
   * It's an edge between "multitheme" and split design. But since it's
   * before all appearance-related things - it's part of "multitheme".
   */
  SPLIT_BASED = 'splitBased',

  /**
   * The case when split wasn't published and we need to fallback to some functionality.
   * @see getThemeFeature.js
   */
  SPLIT_FALLBACK = 'splitFallback',
}

export default SplitBasedAppearance;
