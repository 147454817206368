import {getCookie} from '@core/utils/cookie';

const COOKIE_NAME = 'pub_rmBootstrapPopup';

// I'm not sure about this type. Feel free to change it if needed.
type RmBootstrapPopupParam = string | null;

const getRmBootstrapPopup = (paramName: string): RmBootstrapPopupParam => {
  const params = JSON.parse(getCookie(COOKIE_NAME) || '{}');

  return params[paramName] || null;
};

export const getRmOfferConceptNameCookie = (): RmBootstrapPopupParam =>
  getRmBootstrapPopup('rmOfferConceptName');

export const getPaidFunnelConceptCookie = (): RmBootstrapPopupParam =>
  getRmBootstrapPopup('paidFunnelConceptName');
