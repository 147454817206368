import type {FC, HTMLAttributes, ReactNode} from 'react';
import React from 'react';
import cn from 'classnames';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import css from './Text.css';

export enum TextAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum TextType {
  NORMAL = 'normal',
  DISABLED = 'disabled',
  MUTED = 'muted',
  LABEL = 'label',
  PLACEHOLDER = 'placeholder',
  PRIMARY = 'primary',
  DANGER = 'danger',
  ACCENT = 'accent',
  SUCCESS = 'success',
  WARNING = 'warning',
  INHERIT = 'inherit', // get text color from parent
}

enum TextTransform {
  UPPERCASE = 'uppercase',
  LOWERCASE = 'lowercase',
  CAPITALIZE = 'capitalize',
}

enum TextDecoration {
  LINE_THROUGH = 'lineThrough',
  UNDERLINE = 'underline',
}

export type TextProps = HTMLAttributes<HTMLDivElement> & {
  // Need for correct work of React popper. Arrived from Popover.
  innerRef?: () => void;
  align?: TextAlign;
  type?: TextType;
  textDecoration?: string;
  textTransform?: string;
  className?: string;
  children?: ReactNode;
  ellipsis?: boolean;
  wordBreak?: boolean;
  inverse?: boolean;
  spaced?: boolean;
  inline?: boolean;
  small?: boolean;
  bold?: boolean;
  trackingName?: string;
};

type TextStaticProps = {
  TYPE: typeof TextType;
  ALIGN: typeof TextAlign;
  TRANSFORM: typeof TextTransform;
  DECORATION: typeof TextDecoration;
};

/**
 * Main text widget component. Can be inline, spaced or used as paragraphs.
 */
const Text: FC<TextProps> & TextStaticProps = ({
  align,
  children,
  className,
  bold = false,
  small = false,
  ellipsis,
  type = TextType.NORMAL,
  inline = false,
  spaced = false,
  inverse = false,
  textTransform,
  textDecoration,
  innerRef,
  wordBreak = false,
  trackingName,
  ...props
}) => {
  const content = (
    <div
      ref={innerRef}
      className={cn(
        css.text,
        css[type],
        align && css[align],
        bold && css.bold,
        small && css.small,
        ellipsis && css.ellipsis,
        inline && css.inline,
        spaced && css.spaced,
        inverse && css.inverse,
        wordBreak && css.wordBreak,
        className,
        textTransform && css[textTransform],
        textDecoration && css[textDecoration],
      )}
      {...props}
    >
      {children}
    </div>
  );

  return trackingName ? (
    <AddBabciaUBTracking trackingName={trackingName}>
      {content}
    </AddBabciaUBTracking>
  ) : (
    content
  );
};

Text.TYPE = TextType;
Text.ALIGN = TextAlign;
Text.TRANSFORM = TextTransform;
Text.DECORATION = TextDecoration;

export default Text;
