import {useQuery} from '@apollo/client';

import PAYMENT_PHONE_NUMBER_QUERY from '../graphql/queries/paymentPhoneNumber.gql';

/**
 * Utility hook for retrieving payment phone.
 * Useful for avoiding unneeded network requests in bigger queries when
 * most of data is already in cache.
 */
const usePaymentPhoneNumber = () => {
  const {data, loading, error} = useQuery(PAYMENT_PHONE_NUMBER_QUERY);

  return {
    phoneNumber: data?.payment?.phoneNumber,
    loading,
    error,
  };
};

export default usePaymentPhoneNumber;
