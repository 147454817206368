/**
 * Remove domain from url
 * @param {String} url
 * @returns {String}
 */
const clearUrl = (url) => {
  return url ? url.replace(new RegExp(`^${window.location.origin}`), '') : '';
};

export default clearUrl;
