import createDefaults from '../utils/createDefaults';
import routeVar from '../vars/routeVar';

export default {
  Query: {
    fields: createDefaults({
      route: {__typename: 'Route'},
    }),
  },
  Route: {
    fields: {
      current: () => routeVar().current,
      previous: () => routeVar().previous,
    },
  },
};
