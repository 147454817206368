import get from 'lodash/get';

import setStyleNode from '@core/application/utils/setStyleNode';

import {ANIMATED_TOOLBAR_ID} from '../constants/header';

const HEADER_HEIGHT_ID = 'headerHeight';

/**
 * @param {Number} headerHeight
 */
const setHeaderHeightVariable = (headerHeight) => {
  const animatedToolbarHeight = get(
    document.getElementById(ANIMATED_TOOLBAR_ID),
    'clientHeight',
    0,
  );

  const height = animatedToolbarHeight + headerHeight;

  const styles = `:root {--headerHeight: ${height}px; }`;

  setStyleNode({id: HEADER_HEIGHT_ID, styles});
};

export default setHeaderHeightVariable;
