export const DISAPPROVE_TYPES = {
  DISAPPROVE_PHOTO: 'disapprovePhoto',
  DISAPPROVE_VIDEO: 'disapproveVideo',
  DISAPPROVE_SCREENNAME: 'disapproveScreenname',
  DISAPPROVE_STATUS: 'disapproveStatus',
  DECLINE_VIDEO_GROUP: 'declineVideoGroup',
} as const;

const NOTIFICATION_TYPES = {
  LOST_CONNECTION: 'lostConnection',
  SUCCESS_PAYMENT: 'successPayment',
  APPROVE_PHOTO: 'approvePhoto',
  BROWSER_CHANGE: 'browserChange',
  STATUS_UPDATE: 'statusUpdate',
  PHOTO_UPLOAD: 'photoUpload',
  APPROVE_VIDEO: 'approveVideo',
  CONVERT_VIDEO_SUCCESS: 'convertVideoSuccess',
  CONVERT_VIDEO_ERROR: 'convertVideoError',
  PHOTO_REQUEST_IS_SUBMITTED: 'photoRequestIsSubmitted',
  VIDEO_MUTE: 'videoMute',
  CHANGE_EMAIL: 'changeEmail',
  CHANGE_PASSWORD: 'changePassword',
  CHANGE_TRUSTED: 'changeTrusted',
  VIDEO_LIMIT: 'videoLimit',
  RESUBSCRIBE: 'resubscribe',
  AGE_VERIFICATION: 'ageVerification',
  PERSONAL_IDENTITY: 'personalIdentity',
  PENDING_DELETE: 'pendingDelete',
  MATCH: 'match',
  SEND_GIFT: 'sentGift',
  SENT_MESSAGES_SUCCESS: 'sentMessagesSuccess',
  UNSENT_MESSAGES: 'unsentMessages',
  SEARCH_LIMIT: 'searchLimit',
  ...DISAPPROVE_TYPES,
} as const;

export default NOTIFICATION_TYPES;
