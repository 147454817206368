import getVideoErrorMessage from '@core/mediaUpload/utils/getVideoErrorMessage';

import {MSG} from '../constants/socketEventNames';

export default {
  ConvertedVideoSubscription: {
    incomingEvents: `${MSG}UserVideoConvertedNotice`,

    fromServer: async ({
      videoId,
      via,
      avatar,
      isVideoConverted,
      message,
      duplicateVideoId,
      duration,
      videoUrl,
      group,
      partOfGroup,
    }) => ({
      data: {
        video: {
          id: videoId,
          duplicateVideoId,
          url: videoUrl,
          previewUrl: avatar,
          isConverted: isVideoConverted,
          error: message && (await getVideoErrorMessage(message)),
          duration,
          group,
          partOfGroup,
          __typename: 'VideoType',
        },
        via,
      },
    }),
  },
};
