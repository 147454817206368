import {MSG} from '../constants/socketEventNames';

export default {
  RemarketingReloadSubscription: {
    incomingEvents: [`${MSG}remarketingReload`],

    fromServer: () => ({
      data: {
        needRemarketingReload: true,
        __typename: 'RemarketingReload',
      },
    }),
  },
};
