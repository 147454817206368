/**
 * STATIC_PAGES and STATIC_POPUP_TEST_ID can't be enum because they are backend mapping, with some logic with their keys
 * They are not dictionaries, they are mappings
 */

export const POLICY_AGAINST_HUMAN_TRAFFICKING_POPUP_NAME =
  'Policy Against Human Trafficking';
export const MERCHANT_POPUP_NAME = 'Merchant Info';
export const CANCELLATION_POLICY_POPUP_NAME = 'Cancellation Policy';
export const ANTI_SLAVERY_POLICY_POPUP_NAME = 'Anti-Slavery Policy';
export const CONSENT_AGREEMENT_POPUP_NAME = 'Consent Agreement';

/**
 * Base path for static pages
 * @type {string}
 */
export const PAGE_NAME = 'staticPage';

const STATIC_PAGES = {
  TERMS: 'terms',
  RULES_OF_DRAW_SHORT: 'rulesOfDrawShort',
  RULES_OF_DRAW: 'rulesOfDraw',
  PRIVACY_POLICY: 'privacyPolicy',
  [CONSENT_AGREEMENT_POPUP_NAME]: 'consentAgreement',
  [ANTI_SLAVERY_POLICY_POPUP_NAME]: 'antiSlaveryPolicy',
  [CANCELLATION_POLICY_POPUP_NAME]: 'cancellationPolicy',
  [POLICY_AGAINST_HUMAN_TRAFFICKING_POPUP_NAME]:
    'policyAgainstHumanTrafficking',
  REFUND_POLICY: 'refundPolicy',
  CREDENTIALS_POLICY: 'credentialsPolicy',
  VERIFIED_BY_VISA: 'verifiedByVisa',
  MASTER_CARD_SECURE_CODE: 'masterCardSecureCode',
  ADDITIONAL_PACKAGES_CONDITIONS: 'additionalPackageConditions',
  ABOUT_US: 'aboutUs',
  IM_PRINT: 'imprint',
  LEGAL_INFO: 'legalInfo',
  MER_CHAT_INFO: 'merchantInfo',
  ABOUT_COMPANY: 'aboutCompany',
  CONTACT_US: 'contactUs',
  DNSMPI: 'dnsmpi',
  DIGITAL_SERVICE_ACT: 'digitalServiceAct',
  DMCA: 'dmca',
  COMPLIANCE: 'requirementsComplianceStatement',
  STATEMENT: 'disclaimer',
  BILLING_POLICY: 'billingPolicy',
  SAFETY_TIPS: 'safetyTips',
  SAFE_DATING: 'termsSafeDating',
  CHAT_RULES: 'chatrules',
  XSALE_TERMS: 'xsaleTerms',
  NOTICE: 'termsNotice',
  SUBSCRIPTION_PACKAGES: 'termsSubscriptionPackages',
  SATISFACTION_POLICY: 'satisfactionPolicy',
  Imprint: 'imprint',
  /**
   * Popups name that received from footer query.
   * Pages that received from url. Example: terms?html=notice
   */
  'Terms & Conditions': 'terms',
  'Privacy Policy': 'privacyPolicy',
  'About us': 'aboutUs',
  'Safety Tips': 'safetyTips',
  'How to Meet Safely Online': 'termsSafeDating',
  'Legal Info': 'legalInfo',
  'Community guideline': 'communityGuideline',
  'Age verification': 'ageVerificationInfo',
  [MERCHANT_POPUP_NAME]: 'merchantInfo',
  privacypolicy: 'privacyPolicy',
  billingpolicy: 'billingPolicy',
  2257: 'disclaimer',
  safedating: 'termsSafeDating',
  notice: 'termsNotice',
  subscription_packages: 'termsSubscriptionPackages',
} as const;

/**
 * Keys must be the same as value in the STATIC_PAGES
 * @see: StaticPagePopupHelper.js
 */
export const STATIC_POPUP_TEST_ID = {
  terms: 'termsOfUsePopUp',
  dnsmpi: 'DNSMPIPopUp',
  billingPolicy: 'billingPolicyPopUp',
  requirementsComplianceStatement: 'complianceStatementPopUp',
  disclaimer: 'complianceStatementPopUp',
};

export default STATIC_PAGES;
