import MEDIA_REASONS from '../constants/mediaReasons';

const ORDER = [
  MEDIA_REASONS.ESCORT_SERVICE,
  MEDIA_REASONS.GENDER_MISMATCH,
  MEDIA_REASONS.VIOLENCE_PROFILE,
  MEDIA_REASONS.VIOLENCE_MESSAGES,
  MEDIA_REASONS.SEXUAL,
  MEDIA_REASONS.SPAM,
  MEDIA_REASONS.CELEBRITIES,
  MEDIA_REASONS.UNDER18,
  MEDIA_REASONS.PERSONAL_DETAILS,
  MEDIA_REASONS.PERSONAL_DETAILS_JOINED,
  MEDIA_REASONS.ADVERTISING,
  MEDIA_REASONS.SCAM,
  MEDIA_REASONS.CORRESPONDENCE,
  MEDIA_REASONS.NO_PEOPLE,
  MEDIA_REASONS.OFFCENSE,
  MEDIA_REASONS.GROUP_PHOTO,
  MEDIA_REASONS.GROUP_VIDEO,
  MEDIA_REASONS.GROUP_MEDIA,
];

const findIndex = ({value}) => {
  const index = ORDER.indexOf(value);

  return index < 0 ? ORDER.length : index;
};

const getSortedDisapproveList = (reasons) =>
  [...reasons].sort((a, b) => findIndex(a) - findIndex(b));

export default getSortedDisapproveList;
