/**
 * All trigger methods for popover and popper
 * @see Popper.tsx
 * @see Tooltip.tsx
 * @see Popover.tsx
 */

enum PopperTrigger {
  NONE = 'none',
  CLICK = 'click',
  HOVER = 'hover',
  /**
   * Hover with click fallback for touchevents
   * we can't use the 'hover' trigger on devices without a mouse that's why we force click trigger
   */
  HOVER_CLICK = 'hover_click',
}

export default PopperTrigger;
