enum PackageTypes {
  MEMBERSHIP = 'membership', // Package type to buy membership
  FEATURES = 'features', // Package type to buy features
  TRIAL = 'trial', // Package type to buy trial package
  PREMIUM = 'premium', // Package type to buy premium membership
  CLASSIC = 'classic', // Package type to buy classic membership
  BASIC = 'basic', // Package type to buy basic membership
}

export default PackageTypes;
