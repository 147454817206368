/**
 * Structure of websocket push action when we send inventory track
 */
export default {
  TrackInventoryMutation: {
    toServer: ({data: actions}) => ({
      method: 'inventoryTracking',
      params: {actions},
    }),

    fromServer: (response, error) => ({
      data: {
        trackInventory: {
          error: error || null,
          __typename: 'MutationPayload',
        },
      },
    }),
  },
};
