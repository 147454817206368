export enum Type {
  EVENT = 'event',
  SESSION = 'session',
}

export enum Category {
  PAGE = 'Page',
  POPUP = 'Pop-up',
  NOTIFICATION = 'Notification',
  WEB_VITALS = 'Web Vitals',
  TIMING = 'timing',
}

export enum Event {
  BOOTSTRAP = 'Bootstrap',
  DISPLAY = 'Display',
  CLICK = 'Click',
  REDIRECT = 'Redirect',
  REPLACE = 'Replace',
  LOAD = 'Load',
  CLOSE = 'Close',
  VISIBILITY_CHANGE = 'VisibilityChange',
  SWIPE = 'Swipe',
}

export enum PageVisibility {
  VISIBLE = 'Visible',
  HIDDEN = 'Hidden',
}

export enum SwipeDirection {
  RIGHT = 'Right',
  LEFT = 'Left',
  TOP = 'Top',
  BOTTOM = 'Bottom',
}

export enum LoadProgress {
  START = 'Start',
  COMPLETE = 'Complete',
}

export enum EventType {
  MAIN = '0',
  TEST = '1',
}

export enum EventAttributes {
  NONE = 'None',
}
