import {createContext} from 'react';

export const defaultContext = {
  rulesMap: {},
};

const AccessErrorContext = createContext(defaultContext);

export default AccessErrorContext;

export const {Provider, Consumer} = AccessErrorContext;
