import t from '@core/translations/translate';

export const TABS = {
  PHOTO: 'photo',
  VIDEO: 'video',
};

export const TABS_TRANSLATION = {
  get [TABS.PHOTO]() {
    return t('stories', 'tab.photo');
  },
  get [TABS.VIDEO]() {
    return t('stories', 'tab.video');
  },
};

export const VIA_USER_STORIES = 'user_stories';
export const CAPTURE_CAMERA = 'camera';
