import {string, ValidationError} from 'yup';
import CreditCards from 'creditcards';

import t from '@core/translations/translate';

import {CARD_NUMBER} from '../../card/constants/fieldNames';

export const CVV_MIN_LENGTH = 3;

const securityNumber = () => {
  let validation = string()
    .required(t('paymentPage', 'text.security_number_is_required'))
    .onlyNumbers(t('paymentPage', 'text.invalid_security_number_selected'));

  validation = validation.test('cvc', function cvc(value) {
    const cardNumber = this.parent[CARD_NUMBER];
    const typeName = CreditCards.card.type(cardNumber);
    const typeInfo = typeName
      ? CreditCards.card.types.find(({name}) => name === typeName)
      : null;

    return (
      CreditCards.cvc.isValid(value, typeName) ||
      new ValidationError(
        t('paymentPage', 'text.security_number_should_be_3-4_characters_long', {
          '{length}': typeInfo ? typeInfo.cvcLength : CVV_MIN_LENGTH,
        }),
        value,
        this.path,
        this.type,
      )
    );
  });

  return validation;
};

export default securityNumber;
