enum ActivityBookmarkAppearance {
  /**
   * Means, that no bookmark will be displayed on such theme.
   */
  NONE = 'none',

  AS_PRIMARY_BUTTON = 'asButton',

  AS_PAY_BUTTON = 'asPayButton',

  /**
   * Adds logo inside popup
   */
  AS_PAY_BUTTON_AND_AI_ASSISTANT = 'asPayButtonWithAIAssistant',

  AS_FLOATING_SQUARE_WIDGET = 'floatingSquareWidget',

  /**
   * Yes, change totally the logic of activity button into a simple chat redirect.
   */
  AS_FLOATING_SQUARE_CHAT_WIDGET = 'floatingSquareChatWidget',

  /**
   * The same as AS_FLOATING_SQUARE_CHAT_WIDGET but has notification popup
   */
  AS_FLOATING_SQUARE_WITH_CUSTOM_NOTIFICATION_POPUP = 'floatingSquareWithCustomNotificationPopup',

  /**
   * Same story as above, but adds into popup heading with icon and text.
   */
  AS_FLOATING_SQUARE_WIDGET_AND_HEADING = 'floatingSquareWidgetAndHeading',
}

export default ActivityBookmarkAppearance;
