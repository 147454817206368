/**
 * Routes, where mini messenger must be hidden
 * @type {Array}
 */
import FULLSCREEN_ROUTES from '@core/application/constants/fullscreenRoutes';
import EMPTY_ROUTES_FOR_REDIRECT from '@core/application/constants/emptyRoutesForRedirect';
import ROUTES from '@core/application/constants/routes';

const DISABLED_ROUTES: string[] = [
  '/chat',
  '/site',
  '/pay',
  '/notFound',
  '/trusted',
  '/personalIdentity',
  '/messenger',
  '/user/report',
  '/forbidden',
  '/cancel',
  ROUTES.DESCRIPTOR,
  ...FULLSCREEN_ROUTES,
  ...EMPTY_ROUTES_FOR_REDIRECT,
];

export default DISABLED_ROUTES;
