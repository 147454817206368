import {createContext} from 'react';

import type PopupContextType from '../types/PopupContext';

const defaultContext: PopupContextType = {
  close: () => {},
  isOpen: () => false,
  open: () => {},
  openBackboneView: () => {},
  list: [],
  options: {},
};

const PopupContext = createContext<PopupContextType>(defaultContext);

export default PopupContext;

export const {Provider, Consumer} = PopupContext;
