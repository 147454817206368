import {MSG} from '../constants/socketEventNames';

export default {
  GlobalSearchTrialEndedNotificationSubscription: {
    incomingEvents: [`${MSG}globalSearchTrialEndedNotification`],
    fromServer: ({type}) => ({
      data: {
        globalSearchTrialEndedNotification: {
          type,
          __typename: 'GlobalSearchTrialEndedNotification',
        },
      },
    }),
  },
};
