import type {FieldMergeFunction} from '@apollo/client';

const searchResultsWriter: FieldMergeFunction = (
  existing,
  incoming,
  {mergeObjects},
) => {
  if (existing) {
    return mergeObjects(existing, incoming);
  }

  return incoming;
};

export default {
  WorldWideSearch: {
    keyFields: [],
    fields: {
      users: {
        keyArgs: ['usersSearchParams'],
        merge: searchResultsWriter,
      },
    },
  },
};
