import includes from 'lodash/includes';

import METHODS_WITH_DESCRIPTOR from '../constants/methodsWithDescriptor';

/**
 * @param {String} method (@see paymentMethods.js)
 * @return {Boolean}
 */
const allowedDescriptorForMethod = (method) => {
  return includes(METHODS_WITH_DESCRIPTOR, method);
};

export default allowedDescriptorForMethod;
