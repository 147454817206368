import type {Observable} from 'rxjs';
import {from} from 'rxjs';
import {map, filter} from 'rxjs/operators';

import getConvertVideoSubscription from '@core/user/profile/current/utils/getConvertVideoSubscription';

import NOTIFICATION_TYPES from '../constants/notificationTypes';
import type {CommonNotification} from '../types';

const VIA_USER_STORIES = 'user_stories';

export type VideoConvertNotification = CommonNotification & {error?: string};

/**
 * Returns an observable that emits normalized results based on the convert video subscription.
 */
const videoConvertDataSource = (): Observable<VideoConvertNotification> =>
  // 'from' is used to convert zen-observable into RxJS compatible observable
  from(getConvertVideoSubscription()).pipe(
    filter(
      ({
        data: {
          video: {error},
          via,
        },
      }) => !error || (via as string) === VIA_USER_STORIES,
    ),
    map(
      ({
        data: {
          video: {error},
        },
      }) => ({
        type: error
          ? NOTIFICATION_TYPES.CONVERT_VIDEO_ERROR
          : NOTIFICATION_TYPES.CONVERT_VIDEO_SUCCESS,
        error,
      }),
    ),
  );

export default videoConvertDataSource;
