/**
 * Spacing direction
 * @see Spacing.tsx
 */
enum SpacingDirection {
  BOTH = 'both', // Add spacing for all sized
  VERTICAL = 'vertical', // Only on top and bottom
  HORIZONTAL = 'horizontal', // Onle left and right
}

export default SpacingDirection;
