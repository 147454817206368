import getPhotoUrlParams from '@core/user/photo/utils/getPhotoUrlParams';

import {MSG} from '../constants/socketEventNames';

export default {
  /**
   * Key - has to have the same name as in graphql file
   *
   *  For example we have some gql subscription
   *  subscription InteractionPrimaryPhotoSubscription {
   *     ...PrimaryPhoto
   *  }
   *
   *  How to use:
   *  @see https://www.apollographql.com/docs/react/advanced/subscriptions.html
   *
   *  With Apollo client
   *
   *  getClientInstance().subscribe({
   *    query: PRIMARY_PHOTO_SUBSCRIPTION
   *  }).subscribe(({data}) => {
   *    ...
   *  });
   *
   * With <Subscription> component
   *
   * <Subscription
   *   subscription={COMMENTS_SUBSCRIPTION}
   *   variables={{ repoFullName }}>
   *     {({ data, loading }) => (
   *       ...
   *     )}
   *  </Subscription>
   *
   * With subscribeToMore function in <Query> components
   *
   *  <Query query={PRIMARY_PHOTO_QUERY}>
   *    {({ subscribeToMore, ...result }) => (
   *      <PrimaryPhoto
   *        {...result}
   *        subscribeToNewPrimaryPhoto={() =>
   *          subscribeToMore({
   *            document: PRIMARY_PHOTO_SUBSCRIPTION,
   *            updateQuery: (prev, { subscriptionData }) => {
   *              if (!subscriptionData.data) return prev;
   *              return Object.assign({}, prev, subscriptionData.data);
   *            }
   *          })
   *        }
   *      />
   *    )}
   *  </Query>
   */
  InteractionPrimaryPhotoSubscription: {
    /**
     * If this is incoming interaction you must add field incomingEvents with list of interaction events
     * value - @type {String | Array<String>} name of events which trigger @core/websocket/interaction.js
     */
    incomingEvents: `${MSG}changePrimaryPhotoNotice`,

    fromServer: (response) => ({
      data: {
        id: response.id,
        level: null,
        updatedOn: response.id
          ? getPhotoUrlParams(response.photoUrl).updatedOn
          : '',
        pendingDelete: response.pendingDeletePrimaryPhoto,
        privateAttributes: response.privateAttributes,
        __typename: 'PhotoType',
      },
    }),
  },
};
