import {PopperPlacement} from '../../../constants';
import {Y_AXIS_POSITIONS} from '../constants/popperAxis';
import getPositionSide from './getPositionSide';
import type {ComputeFloatingPositionOptions, FloatingPosition} from '../types';

function computeArrowOffset(
  referenceSize: number,
  floatingSize: number,
  halfArrowSize: number,
  placement: PopperPlacement,
): number {
  const {isOnCenter, isOnEnd} = getPositionSide(placement);

  const size = Math.min(referenceSize, floatingSize);

  if (isOnCenter || referenceSize > floatingSize) {
    return (floatingSize - halfArrowSize) / 2;
  }

  if (isOnEnd) {
    return floatingSize - (size + halfArrowSize) / 2;
  }

  return (size - halfArrowSize) / 2;
}

function computeBasePosition(
  placement: PopperPlacement,
  referenceRect: DOMRect,
  floatingRect: DOMRect,
  relativeParentRect: DOMRect,
  positionFixed: boolean,
): {top: number; left: number} {
  let top = 0;
  let left = 0;

  const {isOnTop, isOnBottom, isOnLeft, isOnCenter, isOnEnd} =
    getPositionSide(placement);

  if (isOnTop || isOnBottom) {
    top = referenceRect.bottom;
    left = referenceRect.left;

    if (isOnTop) {
      top = referenceRect.top - floatingRect.height;
    }

    if (!positionFixed) {
      top -= relativeParentRect.top;
      left -= relativeParentRect.left;
    }

    if (isOnCenter) {
      left += (referenceRect.width - floatingRect.width) / 2;
    } else if (isOnEnd) {
      left -= floatingRect.width - referenceRect.width;
    }

    return {top, left};
  }
  top = referenceRect.top;
  left = referenceRect.right;

  if (isOnLeft) {
    left = referenceRect.left - floatingRect.width;
  }

  if (!positionFixed) {
    top -= relativeParentRect.top;
    left -= relativeParentRect.left;
  }

  if (isOnCenter) {
    top += (referenceRect.height - floatingRect.height) / 2;
  } else if (isOnEnd) {
    top += referenceRect.height - floatingRect.height;
  }

  return {top, left};
}

const computeFloatingPosition = ({
  floatingRect,
  referenceRect,
  arrowRect,
  relativeParentRect,
  placement,
  positionFixed,
  canEnterOnCenter,
  availableSize,
}: ComputeFloatingPositionOptions): FloatingPosition => {
  let arrowTop: number | undefined;
  let arrowLeft: number | undefined;

  let {top, left} = computeBasePosition(
    placement,
    referenceRect,
    floatingRect,
    relativeParentRect,
    positionFixed,
  );

  const halfArrowWidth = (arrowRect?.width || 0) / 2;
  const halfArrowHeight = (arrowRect?.height || 0) / 2;

  if (canEnterOnCenter) {
    const {availableWidth, availableHeight} = availableSize;

    if (
      placement === PopperPlacement.TOP ||
      placement === PopperPlacement.BOTTOM
    ) {
      left = (availableWidth - floatingRect.width) / 2;
      if (!positionFixed) {
        left -= relativeParentRect.left;
      }

      if (arrowRect) {
        arrowLeft =
          referenceRect.left +
          (referenceRect.width - halfArrowWidth) / 2 -
          left;
        if (!positionFixed) {
          arrowLeft -= relativeParentRect.left;
        }
      }
    } else {
      top = (availableHeight - floatingRect.height) / 2;
      if (!positionFixed) {
        top -= relativeParentRect.top;
      }

      if (arrowRect) {
        arrowTop =
          referenceRect.top +
          (referenceRect.height - halfArrowHeight) / 2 -
          top;
        if (!positionFixed) {
          arrowTop -= relativeParentRect.top;
        }
      }
    }
  } else if (arrowRect) {
    if (Y_AXIS_POSITIONS.includes(placement)) {
      arrowLeft = computeArrowOffset(
        referenceRect.width,
        floatingRect.width,
        halfArrowWidth,
        placement,
      );
    } else {
      arrowTop = computeArrowOffset(
        referenceRect.height,
        floatingRect.height,
        halfArrowHeight,
        placement,
      );
    }
  }

  return {
    top,
    left,
    arrowTop,
    arrowLeft,
  };
};

export default computeFloatingPosition;
