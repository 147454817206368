/**
 * Max scale option for ImageGallery
 * @type {number}
 */
export const MAX_SCALE = 2;

/**
 * Min scale option for ImageGallery
 * @type {number}
 */
export const MIN_SCALE = 1;

/**
 * Zoom duration in ms option for ImageGallery
 * @type {number}
 */
export const ZOOM_DURATION_MS = 100;

/**
 * Gallery direction
 * @type {{VERTICAL: string, HORIZONTAL: string}}
 */
export const GALLERY_DIRECTION = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};
