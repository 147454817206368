import type {ApolloCache} from '@apollo/client';

import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';

/**
 * Invalidate cache for renewing search banners and flirtcast banner.
 */
const clearSearchBannersCache = (client: {cache: ApolloCache<object>}) => {
  // Invalidate caches when entering payment page
  // Clear banners cache
  invalidateCacheByTypename(client, 'Banners');
  invalidateCacheByTypename(client, 'Remarketing', 'banner');

  // Clear flirtcast data
  invalidateCacheByTypename(client, 'MotivationBanner');
  invalidateCacheByTypename(client, 'Flirtcast');

  invalidateCacheByTypename(client, 'UserFeatures', 'extraPaymentBanner');
  invalidateCacheByTypename(client, 'ExtraPaymentBanner');
  invalidateCacheByTypename(client, 'Notifications');
};

export default clearSearchBannersCache;
