import reject from 'lodash/reject';

import updateActivities from './updateActivities';

/**
 * Remove activities from cash if
 * @param {Object | Function} predicate
 */
const removeActivityInCache = (predicate) => {
  if (!predicate) {
    return;
  }
  updateActivities((allActivities) => reject(allActivities, predicate));
};

export default removeActivityInCache;
