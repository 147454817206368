import type {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  FocusEvent,
  FocusEventHandler,
  HTMLAttributes,
  ReactElement,
  ReactNode,
} from 'react';
import React, {useState} from 'react';

import useEventCallback from '@core/utils/react/useEventCallback';

import IconPosition from '../../constants/IconPosition';
import InputBorder from '../../constants/InputBorder';
import type {InputFrameProps} from './InputFrame';
import type {HTMLElementWithValue} from './types';

export interface BaseInputRenderProps {
  name: string;
  value: string;
  placeholder: string;
  onFocus: FocusEventHandler;
  onBlur: FocusEventHandler;
  onChange: ChangeEventHandler;
  tabIndex: number;
}

export interface BaseInputProps
  extends Omit<HTMLAttributes<HTMLElement>, 'prefix' | 'children'> {
  children: (props: BaseInputRenderProps) => ReactElement;
  className?: string;
  labelClassName?: string;
  errorClassName?: string;
  largeTextLabel?: boolean;
  disabled?: boolean;
  animatedLabel?: boolean;
  inlineLabel?: boolean;
  editable?: boolean; // Like `disabled`, but used for imitate input-appearance but without edit option.
  name?: string;
  value?: string;
  placeholder?: string;
  label?: string;
  description?: string;
  error?: string | false; // Because if we use formik `setFieldTouched` it sets error as `false`.
  showError?: boolean;
  success?: string;
  inverse?: boolean;
  onChange?: (
    event?: ChangeEvent<HTMLElementWithValue>,
    value?: string,
  ) => void;
  onFocus?: FocusEventHandler<HTMLElementWithValue>;
  onBlur?: FocusEventHandler<HTMLElementWithValue>;
  icon?: ReactNode;
  cvv?: boolean;
  iconPosition?: IconPosition;
  prefix?: ReactNode;
  suffix?: ReactNode;
  spacedSuffix?: boolean;
  border?: InputBorder;
  unspaced?: boolean;
  withTriangle?: boolean;
  tabIndex?: number;
  interactingAllowed?: boolean;
  primary?: boolean;
}

/**
 * Abstract component to handle input and textarea behaviour inside it. Works as 'render-prop'.
 */
const BaseInput: FC<
  // `BaseInputProps` does not include `frameComponent` to make it more suitable for `@phoenix/ui`.
  BaseInputProps & {frameComponent: FC<InputFrameProps>}
> = ({
  frameComponent: Frame,
  children,
  className,
  labelClassName,
  errorClassName,
  label,
  description,
  onChange,
  onFocus,
  onBlur,
  error,
  success,
  icon,
  suffix,
  spacedSuffix,
  prefix,
  name,
  value,
  placeholder,
  interactingAllowed,
  disabled = false,
  editable = true,
  inverse = false,
  iconPosition = IconPosition.LEFT,
  cvv = false,
  border = InputBorder.DEFAULT,
  unspaced = false,
  withTriangle = false,
  animatedLabel = true,
  inlineLabel = false,
  tabIndex = 0,
  showError = true,
  largeTextLabel = false,
  primary = false,
  ...props
}) => {
  const [hasFocus, setHasFocus] = useState(false);

  const handleChange = useEventCallback(
    (event: ChangeEvent<HTMLElementWithValue>) => {
      if (disabled) return;
      onChange?.(event, event.target.value);
    },
  );

  const handleFocus = useEventCallback(
    (event: FocusEvent<HTMLElementWithValue>) => {
      if (disabled) return;
      setHasFocus(true);
      onFocus?.(event);
    },
  );

  const handleBlur = useEventCallback(
    (event: FocusEvent<HTMLElementWithValue>) => {
      if (disabled) return;
      setHasFocus(false);
      onBlur?.(event);
    },
  );

  return (
    <Frame
      className={className}
      labelClassName={labelClassName}
      largeTextLabel={largeTextLabel}
      errorClassName={errorClassName}
      disabled={disabled}
      editable={editable}
      label={label}
      description={description}
      hasValue={
        // Focused input and input with value are visually the same.
        Boolean(value) || hasFocus
      }
      animatedLabel={animatedLabel}
      inlineLabel={inlineLabel}
      error={error}
      showError={showError}
      success={success}
      inverse={inverse}
      unspaced={unspaced}
      border={border}
      icon={icon}
      cvv={cvv}
      iconPosition={iconPosition}
      suffix={suffix}
      spacedSuffix={spacedSuffix}
      prefix={prefix}
      withTriangle={withTriangle}
      focused={hasFocus}
      primary={primary}
      interactingAllowed={interactingAllowed}
    >
      {children({
        ...props,
        name,
        value,
        placeholder,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onChange: handleChange,
        tabIndex,
      })}
    </Frame>
  );
};

export default BaseInput;
