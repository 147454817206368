export enum IDEAL_METHODS {
  TRUST_PAY_IDEAL = 'trustPayIDeal',
  NUVEI_IDEAL = 'nuveiIdeal',
}

export enum WALLET_METHODS {
  GOOGLE_PAY = 'googlePay',
  APPLE_PAY = 'applePay',
  PAY_PAL = 'paypal',
  PAY_PAL_V2 = 'paypalv2',
}

export enum SEPA_METHODS {
  SEPA = 'sepa',
  NOVAL_NET_SEPA = 'novalNetSepa',
  VENDO_SEPA = 'vendoSepa',
  TRUST_PAY_SEPA = 'trustPaySepa',
  COMM_DOO_SEPA = 'commDooSepa',
  MICRO_PAYMENT_SEPA = 'micropaymentSepa',
  SALT_EDGE_AIS = 'saltEdgeAis',
}

export const WITH_SECOND_STEP_ALT_METHODS = [
  SEPA_METHODS.NOVAL_NET_SEPA,
  SEPA_METHODS.VENDO_SEPA,
  SEPA_METHODS.TRUST_PAY_SEPA,
  SEPA_METHODS.SALT_EDGE_AIS,
  SEPA_METHODS.COMM_DOO_SEPA,
  IDEAL_METHODS.TRUST_PAY_IDEAL,
  SEPA_METHODS.MICRO_PAYMENT_SEPA,
];

export const SEPA_METHODS_WITH_REDIRECT_AFTER_DECLINE = [
  SEPA_METHODS.NOVAL_NET_SEPA,
  SEPA_METHODS.VENDO_SEPA,
  SEPA_METHODS.MICRO_PAYMENT_SEPA,
];

export const SEPA_ALLOWED_AUTOFILL_ACCESS_METHODS_LIST = [
  SEPA_METHODS.NOVAL_NET_SEPA,
  SEPA_METHODS.VENDO_SEPA,
];

export enum BASIC_METHODS {
  BCCARD = 'bccard',
  CARD = 'card',
  MIDAS = 'midas',
  CARTEBANCAIRE = 'carteBancaire',
  CARTEPREPAGATE = 'cartePrepagate',
  CCBILL = 'ccbill',
  IDEAL = 'iDeal',
  JCB = 'jcb',
  KLARNA = 'klarna',
  RAKUTEN = 'rakuten',
  KONBINI = 'konbini',
  PAYGARDEN = 'paygarden',
  SOFORT = 'sofort',
  EPS = 'eps',
  MY_BANK = 'mybank',
  TRUSTLY = 'trustly',
  NUVEI_MY_BANK = 'nuveiMyBank',
  /**
   * Split Replacement for Sofort method for customizing payment tabs
   */
  SEPA_TRANSFER = 'sepaTransfer',
  /**
   * Replacement for Sofort method for customizing payment tabs
   *
   * @see https://jira.togethernetworks.com/browse/FE-36594
   */
  SOFORT_UBERWEISUNG = 'sofortUberweisung',
  /**
   * Replacement for SEPA methods for customizing payment tabs
   *
   * @see https://jira.togethernetworks.com/browse/FE-36594
   */
  PAY_BY_BANK = 'paybybank',
}

export const METHODS_WITHOUT_TAB = [...Object.values(WALLET_METHODS)];

export const EXTERNAL_METHODS_IN_ANOTHER_WINDOW = [
  WALLET_METHODS.PAY_PAL,
  WALLET_METHODS.PAY_PAL_V2,
  BASIC_METHODS.CCBILL,
  SEPA_METHODS.SALT_EDGE_AIS,
];

const PAYMENT_METHODS = {
  ...WALLET_METHODS,
  ...SEPA_METHODS,
  ...IDEAL_METHODS,
  ...BASIC_METHODS,
};

/**
 * TODO: Make refactoring TS type.
 * Using string because string using in activeMethodTabVar
 * @see @core/graphql/vars/activeMethodTabVar
 */
export const EXTERNAL_METHODS_IN_ANOTHER_WINDOW_WITH_FORM: string[] = [
  SEPA_METHODS.SALT_EDGE_AIS,
];

export default PAYMENT_METHODS;
