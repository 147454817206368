import {getClientInstance} from '@core/graphql/client';
import getPhotoUrlParams from '@core/user/photo/utils/getPhotoUrlParams';
import CURRENT_USER_PHOTOS_QUERY from '@core/user/profile/current/graphql/queries/currentUserPhotos.gql';

const putPhotoInCache = async ({id, normal, createdOn}) => {
  const client = getClientInstance();
  const {data} = await client.query({query: CURRENT_USER_PHOTOS_QUERY});
  const {photos} = data.myUser.profile;

  if (photos.allPhotos.find((photo) => photo.id === id)) {
    return;
  }

  const count = photos.count + 1;
  const allPhotos = [
    ...(photos.allPhotos || []),
    {
      id,
      level: 0,
      updatedOn: getPhotoUrlParams(normal).updatedOn,
      createdOn,
      pendingDelete: false,
      censored: false,
      privateAttributes: null,
      __typename: 'PhotoType',
    },
  ];

  client.writeQuery({
    query: CURRENT_USER_PHOTOS_QUERY,
    data: {
      ...data,
      myUser: {
        ...data.myUser,
        profile: {
          ...data.myUser.profile,
          photos: {
            ...data.myUser.profile.photos,
            allPhotos,
            count,
          },
        },
      },
      disapprove: {
        ...data.disapprove,
        statuses: {
          ...data.disapprove.statuses,
          // when photo is loaded disapprove no need
          photo: false,
        },
      },
    },
  });
};

export default putPhotoInCache;
