import {useMemo} from 'react';
import {useRouteMatch} from 'react-router-dom';

/**
 * @param {String} path
 * @param {String} activeTab
 * @param {String} name
 * @returns {Boolean}
 */
const useIsActiveTab = (path, activeTab, name, route) => {
  const match = useRouteMatch({
    path: path || route[name],
  });

  return useMemo(() => {
    if (activeTab === name) {
      return true;
    }

    if (!match || !match.isExact) {
      return false;
    }

    return Boolean(match.url);
  }, [match, activeTab, name]);
};

export default useIsActiveTab;
