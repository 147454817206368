import type {FC} from 'react';
import React from 'react';
import cn from 'classnames';

import {Icon} from '@core/ui';
import {IconSize, SpacingSize} from '@core/ui/constants';
import {ucfirst} from '@core/utils/string';

import type {ListProps} from './List';
import {Text} from '../text';
import css from './ListItem.css';

/**
 * Single typographic list item
 */
const ListItem: FC<ListProps> = ({
  icon = 'bullet',
  spacingSize,
  children,
  bulleted,
  bulletSpacing = SpacingSize.NORMAL,
  muted,
  small,
  ...props
}) => (
  <li className={cn(css.item, css[spacingSize])}>
    <Text
      {...props}
      type={muted ? Text.TYPE.MUTED : Text.TYPE.NORMAL}
      small={small}
    >
      {bulleted && (
        <span className={css.bullet}>
          {typeof icon === 'string' ? (
            <Icon
              type="bullet"
              active
              size={small ? IconSize.SIZE_12 : IconSize.SIZE_16}
            />
          ) : (
            icon
          )}
        </span>
      )}
      <span
        className={cn(
          css.content,
          bulletSpacing && css[`horizontal${ucfirst(bulletSpacing)}`],
        )}
      >
        {children}
      </span>
    </Text>
  </li>
);

export default ListItem;
