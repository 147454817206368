import React, {forwardRef} from 'react';

import useDeferredComponents from '@core/utils/react/useDeferredComponents';

/**
 * Deferred ReCaptcha component loader
 * @returns {Object} {{loading: Boolean, data: Object}}
 * @see useDeferredComponents
 */
export default function useReCaptcha() {
  return useDeferredComponents(
    () => import('react-google-recaptcha'),
    window.IS_INTEGRATION_TEST_ENVIRONMENT
      ? {
          default: forwardRef(() => (
            <div data-test="captcha">CAPTCHA_MOCK</div>
          )),
        }
      : null,
  );
}
