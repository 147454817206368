/**
 * Common constants for customizing photo upload and video upload popups (media, in general).
 */
enum MediaUploadAppearance {
  /**
   * Displays motivation like user default photo
   * with possibility to change it to "uniform" motivation.
   */
  WITH_PHOTO_MOTIVATION = 'withPhotoMotivation',

  /**
   * Displays motivation as tile of upload buttons
   */
  WITH_TILE_MOTIVATION = 'withTileMotivation',

  /**
   * Displays motivation as notice.
   */
  WITH_MOTIVATION_AS_NOTICE = 'withMotivationAsNotice',

  /**
   * Displays motivation with large photo upload motivation and logo above it.
   */
  WITH_LARGE_PHOTO_MOTIVATION_AND_LOGO = 'withLargePhotoMotivationAndLogo',

  /**
   * Displays notice, wrapped in "highlighted" container together with actions
   */
  WITH_HIGHLIGHTED_MOTIVATION_AS_NOTICE = 'withHighlightedMotivationAsNotice',

  /**
   * Just a notice over upload actions, with explicit "Upload" icon.
   */
  WITH_MOTIVATION_AS_UPLOAD_NOTICE = 'withMotivationAsUploadNotice',

  /**
   * Full size user motivation.
   */
  WITH_FULL_SIZE_PHOTO_MOTIVATION = 'withFullSizePhotoMotivation',

  /**
   * Blurred and full size user motivation.
   */
  WITH_BLURRED_PHOTO_MOTIVATION = 'withBlurredPhotoMotivation',

  /**
   * Displays motivation with photo on background of popup
   */
  WITH_PHOTO_ON_BACKGROUND_AS_MOTIVATION = 'withPhotoOnBackgroundAsMotivation',

  /**
   * Displays motivation with 2 random user photos and notice icon
   */
  WITH_RANDOM_USERS_AND_NOTICE_ICON_AS_MOTIVATION = 'withRandomUsersAndNoticeIconAsMotivation',

  /**
   * Displays motivation with user photo and upload icon
   */
  WITH_LARGE_PHOTO_MOTIVATION_AND_UPLOAD_ICON = 'withLargePhotoMotivationAndUploadIcon',

  /**
   * Displays motivation with loader filled with user profile completion
   */
  WITH_PROFILE_PROGRESSION_AS_MOTIVATION = 'withProfileProgressAsMotivation',

  /**
   * Displays motivation with user photo and gallery styled list
   */
  WITH_GALLERY_LIST_AS_MOTIVATION = 'withGalleryListAsMotivation',

  /**
   * Displays motivation with inverse background on top and upload icon
   */
  WITH_INVERSE_BACKGROUND_AND_UPLOAD_ACTION_MOTIVATION = 'withInverseBackgroundAndUploadActionMotivation',

  /**
   * Displays motivation with 3 icons in one line
   */
  WITH_HORIZONTAL_ICONS_AS_MOTIVATION = 'withHorizontalIconsAsMotivation',
}

export default MediaUploadAppearance;
