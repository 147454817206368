import type {Theme} from '@core/theming/types';

import getParameter from './getParameter';

/**
 * Separate receipt of parameters for convenient mock functions
 *
 * For integration tests we use global flag.
 * @see tests/test-utils/theming.js (setTheme)
 */
const getTheme = () => getParameter<Theme>('theme');

export default getTheme;
