import {useState} from 'react';

import {getClientInstance} from '@core/graphql/client';

import CONFIRMATION_EMAIL_MUTATION from '../graphql/mutations/confirmationEmail.gql';

const useResendConfirmEmail = () => {
  const [resendingEmail, setResendingEmail] = useState(false);
  const resendEmail = async ({email, action, client = getClientInstance()}) => {
    setResendingEmail(true);

    await client.mutate({
      // Design and functionality doesn't provide any possible validation.
      mutation: CONFIRMATION_EMAIL_MUTATION,
      variables: {email, action, userId: null},
    });

    setResendingEmail(false);
  };

  return {resendingEmail, resendEmail};
};

export default useResendConfirmEmail;
