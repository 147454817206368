import {useEffect} from 'react';

import logger from '@core/logger';

import PROCESSING_STATUS from '../../../common/constants/processingStatus';
import {
  getInitPaymentVariables,
  setInitPaymentVariables,
} from '../../../common/utils/paymentInitVariables';

const usePrefetchPaymentDataError = ({
  source,
  action,
  processingStatus,
  via,
  prevVia,
  failVia,
  loading,
}) => {
  useEffect(() => {
    const paymentInitVariables = getInitPaymentVariables();

    if (
      paymentInitVariables &&
      paymentInitVariables.action === action &&
      paymentInitVariables.via === via &&
      paymentInitVariables.prevVia === prevVia &&
      paymentInitVariables.source === source &&
      processingStatus === PROCESSING_STATUS.LOADING &&
      !failVia
    ) {
      logger.sendWarning(
        '[usePrefetchPaymentDataError] Something went wrong. PaymentBaseDataQuery should prefetch packages for this case',
      );
    }
  }, [source, action, failVia, prevVia, via, processingStatus, loading]);

  useEffect(() => {
    return () => {
      setInitPaymentVariables(null);
    };
  }, [action, via, prevVia]);
};

export default usePrefetchPaymentDataError;
