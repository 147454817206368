import omit from 'lodash/omit';

import {getClientInstance} from '@core/graphql/client';
import CSRF_TOKEN_QUERY from '@core/graphql/graphql/queries/csrfToken.gql';
import type {CsrfTokenQuery} from '@core/graphql/graphql/queries/csrfToken';

import type {PaymentProcessStep} from '../../types/paymentProcess';

const updateCSRFToken: PaymentProcessStep = async (data) => {
  const {paymentResult} = data;

  const csrfToken = paymentResult?.csrfToken?.value;

  if (csrfToken) {
    getClientInstance().writeQuery<CsrfTokenQuery>({
      query: CSRF_TOKEN_QUERY,
      data: {
        site: {
          csrfToken,
          __typename: 'Site',
        },
      },
    });

    return {
      ...data,
      paymentResult: omit(paymentResult, 'csrfToken'),
    };
  }

  return data;
};

export default updateCSRFToken;
