import {useState, useEffect, useCallback} from 'react';
import type {DocumentNode} from 'graphql';

/**
 * Storage of keys we need to invalidate.
 */
const keys = new Set<DocumentNode>();

/**
 * Map of updaters to force update mounted component when reset required.
 */
const forceUpdaters = new Map<DocumentNode, () => void>();

/**
 * @deprecated Use `cache.evict` instead.
 *
 * Set invalidation attribute for specified `key`.
 * Update related component to complete invalidation.
 * Returns `true` if related component is currently mounted.
 */
export const invalidate = (key: DocumentNode): boolean => {
  keys.add(key);

  if (forceUpdaters.has(key)) {
    forceUpdaters.get(key)();
    return true;
  }
  return false;
};

/**
 * @deprecated Use `cache.evict` instead.
 *
 * Is used to refetch the query if invalidation required.
 * * WARNING: usage of the same key at the several places at the same time is unsupported.
 *
 * Example use:
 *   * Inside component:
 *   ```
 *     const [needReset, onReset] = useInvalidation(SOME_QUERY);
 *     const {loading, data, refetch} = useQuery(SOME_QUERY, {notifyOnNetworkStatusChange: true});
 *     if (loading) onReset();
 *     if (needReset && !loading) refetch();
 *   ```
 *
 *   * And to invalidate this query from another place:
 *     ```
 *     invalidate(SOME_QUERY);
 *     ```
 *
 * @param key - The key associated with the query. The query itself can be used as a key.
 */
export const useInvalidation = (key: DocumentNode): [boolean, () => void] => {
  // this state is used to trigger update for invalidation
  const [, update] = useState(false);

  useEffect(() => {
    forceUpdaters.set(key, () => update((value) => !value));

    return () => {
      forceUpdaters.delete(key);
    };
  }, [key]);

  const onReset = useCallback(() => {
    keys.delete(key);
  }, [key]);

  return [keys.has(key), onReset];
};
