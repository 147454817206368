export const ERROR_MESSAGES = {
  FORCED_STOP_PLUGIN: 'Forced stop plugin',
  WEBCAM_NOT_STARTED: 'Webcam not started',
  PLUGIN_LOADING_ERROR: 'Plugin for flash webcam not loaded',
  FLASH_NOT_INSTALL: 'Flash not install',
  CONTAINER_ELEMENT_NOT_FOUND: 'Could not locate container DOM element',
  GET_USER_MEDIA_NOT_SUPPORTED: 'getUserMedia not supported',
};

/**
 * List of possible generated errors
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
 * @constant
 */
export const PERMISSION_DENIED_ERRORS = [
  'PermissionDeniedError',
  'PermissionDismissedError',
  'NotAllowedError',
  'NotReadableError',
  'NotFoundError',
  'InternalError',
  'TrackStartError',
];

export const ERROR_TYPES = {
  PERMISSION_DENIED_ERROR_TYPE: 'PERMISSION',
  FLASH_ENABLED_ERROR_TYPE: 'FLASH',
  CAMERA_NOT_FOUND_ERROR_TYPE: 'CAMERA',
};
