import type {FC, HTMLAttributes} from 'react';
import React from 'react';
import cn from 'classnames';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import OverlayType from '../../constants/OverlayType';
import OverlaySaturation from '../../constants/OverlaySaturation';
import type OverlayPosition from '../../constants/OverlayPosition';
import css from './Overlay.css';

export type OverlayProps = {
  saturation?: OverlaySaturation;
  type?: OverlayType;
  position?: OverlayPosition;
  inverse?: boolean;
  // Avoid or use absolute positioning of overlay by itself. Useful when content is dynamical and it must be overlapped
  fullSize?: boolean;
  trackingName?: string;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Wrapper, that adds self-opaque overlay absolutely positioned
 */
const Overlay: FC<OverlayProps> = ({
  children,
  saturation = OverlaySaturation.MEDIUM,
  type = OverlayType.FILL,
  position,
  inverse,
  fullSize = true,
  className,
  trackingName,
  ...props
}) => {
  const content = (
    <div
      className={cn(
        css.overlay,
        inverse && css.inverse,
        fullSize && css.fullSize,
        css[saturation],
        css[type],
        css[position],
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );

  return trackingName ? (
    <AddBabciaUBTracking trackingName={trackingName}>
      {content}
    </AddBabciaUBTracking>
  ) : (
    content
  );
};

export default Overlay;
