/**
 * Send to server request webCamTransition to receive new url webcam provider
 */
export default {
  WebCamRegistrationMutation: {
    toServer: ({url}) => ({method: 'webCamTransition', params: {url}}),

    fromServer(id, response) {
      const isSuccess = response && response.status === 'success';
      return {
        data: {
          webCamRegistrationLink: {
            url: isSuccess ? response.url : null,
            __typename: 'WebCamTransitionLink',
          },
        },
      };
    },
  },
};
