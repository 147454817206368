/**
 * It is routes which need show like full screen, without any tools just with preloader (Loader.js)
 * Need to add to DISABLED_ROUTES in the different places (@see disabled routers in header.js,  footer.js, ...)
 */
const EMPTY_ROUTES_FOR_REDIRECT = [
  '/account/emailUpdate',
  '/pay/retryPermission',
  '/debtorInvoice',
];

export default EMPTY_ROUTES_FOR_REDIRECT;
