import BASE_ROUTES from '@core/application/constants/routes';

/**
 * Route which redirect from PWA to RM target
 * @param {string} url
 * @returns {boolean}
 */
const isPwaNextStepUrl = (url = '') =>
  Boolean(url) && url.startsWith(BASE_ROUTES.PWA_NEXT_STEP);

export default isPwaNextStepUrl;
