import type {FC} from 'react';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import getCookie from '@core/utils/cookie/getCookie';
import {localStorage} from '@core/utils/storage/storage';
import ROUTES from '@core/application/constants/routes';

const TAB_KEY = Date.now().toString();

// Export for tests
export const ACTIVE_TAB_STORAGE_KEY = 'activeTabKey';

/**
 * Key to reload search page after focus tab when return from user view page
 * Search page not reload if on user view page was no route changes.
 */
const RELOAD_SEARCH_PAGE_STORAGE_KEY = 'reloadSearchPageKey';

const USER_VIEW_ROUTE = '/user/view/id/';

const INIT_PATHNAME = window.location.pathname;

const isUserViewPage = INIT_PATHNAME.startsWith(USER_VIEW_ROUTE);

/**
 * Key to skip page reload after focus tab
 * Export for tests
 */
export const SKIP_PAGE_RELOAD_AFTER_FOCUS_TAB = 'skipPageReloadAfterFocusTab';

const setActiveTab = (pathname: string): void => {
  localStorage.setItem(ACTIVE_TAB_STORAGE_KEY, {
    key: TAB_KEY,
    pathname,
  });
};

const skip = localStorage.getItem('skipActualization', false);

const onVisibilityChange = () => {
  if (!document.hidden) {
    const tabData = localStorage.getItem<{key: string; pathname: string}>(
      ACTIVE_TAB_STORAGE_KEY,
    );

    const {pathname} = window.location;

    if (!tabData) {
      setActiveTab(pathname);
      return;
    }

    if (getCookie('ccbillTest')) {
      console.log(
        'SKIP_PAGE_RELOAD_AFTER_FOCUS_TAB >>>>',
        localStorage.getItem(SKIP_PAGE_RELOAD_AFTER_FOCUS_TAB),
      );
      setActiveTab(pathname);
      return;
    }

    if (localStorage.getItem(SKIP_PAGE_RELOAD_AFTER_FOCUS_TAB)) {
      localStorage.removeItem(SKIP_PAGE_RELOAD_AFTER_FOCUS_TAB);
      setActiveTab(pathname);
      return;
    }

    const {key, pathname: tabPathname} = tabData;

    if (key !== TAB_KEY) {
      const isUserViewPrevTab = tabPathname.startsWith(USER_VIEW_ROUTE);
      const isSearchCurrentTab = pathname.startsWith(ROUTES.SEARCH);
      const isNeedReloadSearchPage = localStorage.getItem(
        RELOAD_SEARCH_PAGE_STORAGE_KEY,
      );

      /**
       * Not reload page if user return to search page from profile page
       * without any route changes on profile page
       */
      if (isSearchCurrentTab && isUserViewPrevTab && !isNeedReloadSearchPage) {
        setActiveTab(pathname);
        return;
      }

      /**
       * Clear reload search page flag if user return to search page
       */
      if (isSearchCurrentTab && isNeedReloadSearchPage) {
        localStorage.removeItem(RELOAD_SEARCH_PAGE_STORAGE_KEY);
      }

      if (skip) {
        console.warn('PageDataActualization: reload skipped');
      } else {
        window.location.reload();
      }
    }
  }
};

/**
 * Component ensures that the page data is always up-to-date.
 * Reload page each time when user go to another tab and return back.
 */
const PageDataActualization: FC = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!document.hidden) {
      setActiveTab(pathname);
    }

    /**
     * Reload search page if user go to another route from user view page
     */
    if (isUserViewPage && pathname !== INIT_PATHNAME) {
      localStorage.setItem(RELOAD_SEARCH_PAGE_STORAGE_KEY, true);
    }
  }, [pathname]);

  return null;
};

export default PageDataActualization;
