import {createUrlFor} from '@core/utils/url';
import getHistory from '@core/application/utils/getHistory';

import isMiniMessengerAllowed from './isMiniMessengerAllowed';
import isRouteDisabledForMiniMessenger from './isRouteDisabledForMiniMessenger';
import updateMiniMessengerVisibleRecipient from './updateMiniMessengerVisibleRecipient';

/**
 * Is used to open chat with specified user or return to all chats (if userId is null).
 * @param {?string} userId - user id
 * @param {?boolean} replace - replace current page
 * @param {?boolean} skipMiniMessenger - skip mini messenger opening
 */
const openMessengerWith = async ({
  userId,
  replace = false,
  skipMiniMessenger = false,
}) => {
  const miniMessengerAllowed = await isMiniMessengerAllowed();
  const canOpenMiniMessenger =
    miniMessengerAllowed &&
    !skipMiniMessenger &&
    !isRouteDisabledForMiniMessenger();

  // Need update visible recipient for mini messenger every time got to chat page
  if (miniMessengerAllowed) {
    await updateMiniMessengerVisibleRecipient(userId, canOpenMiniMessenger);
  }

  if (!canOpenMiniMessenger) {
    const history = getHistory();
    const url = createUrlFor.chatWith(userId);
    if (replace) {
      if (history.location.pathname !== url) {
        history.replace(url);
      }
    } else {
      history.push(url);
    }
  }
};

export default openMessengerWith;
