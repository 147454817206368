import PAYMENT_METHODS from '../constants/paymentMethods';
import PAYMENT_ACTIONS from '../constants/paymentActions';

/**
 * @param {string} method
 * @return {string}
 */
const getActionByMethod = (method) => {
  switch (method) {
    case PAYMENT_METHODS.CARD:
      return PAYMENT_ACTIONS.MEMBERSHIP;
    case PAYMENT_METHODS.TRUST_PAY_SEPA:
      return PAYMENT_ACTIONS.TRUST_PAY;
    case PAYMENT_METHODS.COMM_DOO_SEPA:
    case PAYMENT_METHODS.MICRO_PAYMENT_SEPA:
    case PAYMENT_METHODS.NOVAL_NET_SEPA:
    case PAYMENT_METHODS.VENDO_SEPA:
      return PAYMENT_ACTIONS.SEPA;
    case PAYMENT_METHODS.TRUST_PAY_IDEAL:
    case PAYMENT_METHODS.NUVEI_IDEAL:
    case PAYMENT_METHODS.NUVEI_MY_BANK:
      return PAYMENT_ACTIONS.IDEAL;
    case PAYMENT_METHODS.KONBINI:
      return PAYMENT_ACTIONS.KONBINI;
    case PAYMENT_METHODS.SOFORT:
      return PAYMENT_ACTIONS.SOFORT;
    default:
      return '';
  }
};

export default getActionByMethod;
