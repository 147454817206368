import type {MouseEvent} from 'react';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';

import useEventCallback from '@core/utils/react/useEventCallback';

import css from './Ripple.css';

const ANIMATION_SPEED = 750;

interface RippleProps {
  inverse?: boolean;
}

export interface RippleElement {
  animate: (event: MouseEvent) => void;
}

/**
 * Ripple container component. Used for buttons.
 */
const Ripple = forwardRef<RippleElement, RippleProps>(
  ({inverse = false}, ref) => {
    const containerRef = useRef<HTMLDivElement>();
    const rippleRef = useRef<HTMLDivElement>();
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
    const [animating, setAnimating] = useState(false);

    useEffect(
      () => () => {
        clearTimeout(timeoutRef.current);
      },
      [],
    );

    const animate = useEventCallback((e: MouseEvent) => {
      if (animating) {
        return;
      }

      const container = containerRef.current;
      const boundingRect = container.getBoundingClientRect();

      const d = Math.max(container.clientWidth, container.clientHeight);
      const ripple = rippleRef.current;
      ripple.style.height = `${d}px`;
      ripple.style.width = `${d}px`;
      ripple.style.top = `${Math.round(e.clientY - boundingRect.top - d / 2)}px`;
      ripple.style.left = `${Math.round(
        e.clientX - boundingRect.left - d / 2,
      )}px`;

      setAnimating(true);
      timeoutRef.current = setTimeout(() => {
        setAnimating(false);
        ripple.style.cssText = null;
      }, ANIMATION_SPEED);
    });

    useImperativeHandle(ref, () => ({animate}), [animate]);

    return (
      <div ref={containerRef} className={css.container}>
        <div
          className={cn(
            css.ripple,
            inverse && css.inverse,
            animating && css.animating,
          )}
          ref={rippleRef}
        />
      </div>
    );
  },
);

export default Ripple;
