/**
 * Default animation duration in seconds
 * export used for unit test
 */
export const DEFAULT_DURATION = 0.3;

/**
 * WARNING!! this function only returns either 'duration' or 'delay'
 */
const getAnimationTime = ({
  duration = DEFAULT_DURATION,
  delay,
}: {duration?: number; delay?: number} = {}): number => {
  if (window.IS_INTEGRATION_TEST_ENVIRONMENT) {
    return 0;
  }

  return delay || duration;
};

export default getAnimationTime;
