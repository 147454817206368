import isNull from 'lodash/isNull';

import isMobileViewport from './isMobileViewport';

let mobileViewport = null;

/**
 * It is necessary in order to remember which payment system the user got to initially, since when changing the screen
 * orientation, the web payment for the mob was not shown, since there are other feature models and in general the
 * logic can be very different from the pp that he saw initially
 */
const isMobileViewportCached = (): boolean => {
  if (!isNull(mobileViewport)) {
    return mobileViewport;
  }

  mobileViewport = isMobileViewport();

  return mobileViewport;
};

export default isMobileViewportCached;
