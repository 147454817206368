import type {
  MutableRefObject,
  ReactElement,
  MouseEvent,
  MouseEventHandler,
  RefObject,
  Ref,
  ForwardedRef,
  ReactNode,
  TouchEventHandler,
} from 'react';
import React, {PureComponent, Fragment, cloneElement, createRef} from 'react';
import {createPortal} from 'react-dom';
import isFunction from 'lodash/isFunction';
import pick from 'lodash/pick';

import isReactComponent from '@core/utils/react/isReactComponent';
import BabciaScopedProvider from '@core/tracking/babcia/containers/BabciaScopedProvider';
import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';
import shouldUseInverseColorScheme from '@core/utils/styles/shouldUseInverseColorScheme';

import createPopper from './utils/createPopper';
import type {PopperInstance} from './types';
import type {WidgetBaseProps} from './Widget';
import Widget from './Widget';
import type {PopperPlacement} from '../../constants';

export type ReferenceElementProps = {
  active: boolean;
  ref: Ref<HTMLDivElement>;
  onMouseEnter: MouseEventHandler<HTMLDivElement>;
  onMouseLeave: MouseEventHandler<HTMLDivElement>;
  onMouseDown: MouseEventHandler<HTMLDivElement>;
  onTouchStart: TouchEventHandler<HTMLDivElement>;
  onClick: MouseEventHandler<HTMLDivElement>;
};

export type PopperWrapperProps = WidgetBaseProps & {
  active?: boolean;
  children:
    | ReactNode
    | ((props: {
        active?: boolean;
        inverse: boolean;
        hide?: () => void;
      }) => ReactNode);
  reference: ReactElement | ((props: ReferenceElementProps) => ReactElement);
  arrowRef?: MutableRefObject<HTMLDivElement>;
  positionFixed?: boolean;
  onReferenceClick: MouseEventHandler<HTMLElement>;
  /** Observe content popover and update its position after content change */
  observeContentMutations?: boolean;
  /** Is arrow position update needed */
  updateArrowPosition?: boolean;
  parentRef?: RefObject<HTMLElement> | ForwardedRef<HTMLDivElement>;
  useReferenceWidth?: boolean;
  usePortal?: boolean;
  hidePopover?: () => void;
  trackingName?: string;
  scrollableRef?: RefObject<HTMLDivElement>;
};

interface PopperWrapperState {
  active: boolean;
  placement: PopperPlacement;
  useInverseColor: boolean;
}

/**
 * Wrapper around PopperJS to use it only for visible poppers and show/hide them with animation.
 */
export default class PopperWrapper extends PureComponent<
  PopperWrapperProps,
  PopperWrapperState
> {
  hideTimeout: Timeout = null;

  /** position updater instance */
  popper: PopperInstance | null = null;

  arrowRef: MutableRefObject<HTMLDivElement> = createRef();

  mutationObserver: MutationObserver;

  reference: HTMLElement | null;

  content: HTMLElement | null;

  contentInner: HTMLElement | null;

  constructor(props: PopperWrapperProps) {
    super(props);
    this.state = {
      active: props.active,
      placement: props.placement,
      useInverseColor: false,
    };
    this.reference = null; // reference element ref
    this.content = null; // content element ref
    this.contentInner = null;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.active && this.props.active) {
      this.show();
      return;
    }

    if (prevProps.active && !this.props.active) {
      this.hide();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.hideTimeout);
    this.destroyPopper();
  }

  referenceRef = (reference: HTMLElement) => {
    if (this.reference !== reference) {
      // The popper content will be attached to this HTMLElement
      this.reference = reference;
      this.reInitPopperInstance();
    }
  };

  contentRef = (content: HTMLElement) => {
    if (this.content !== content) {
      // HTMLElement of the popper content.
      this.content = content;
      this.reInitPopperInstance();
    }
  };

  contentInnerRef = (contentInner: HTMLDivElement) => {
    if (this.contentInner !== contentInner) {
      this.contentInner = contentInner;
      this.setState({
        useInverseColor: shouldUseInverseColorScheme(this.contentInner),
      });
    }
  };

  updatePlacement = (placement) => {
    if (placement !== this.state.placement) {
      this.setState({placement});
    }
  };

  handleReferenceClick = (...args: [MouseEvent<HTMLElement>]) => {
    this.props.onReferenceClick?.(...args);
    const {reference} = this.props;
    if (!isFunction(reference)) {
      reference.props?.onClick?.(...args);
    }
  };

  /**
   * Calls when reference or content element changed
   */
  reInitPopperInstance() {
    /**
     * We don't have 'content' here when popper is hidden,
     * free resources and remove useless event listeners.
     * In case of 'reference' change it could be useful too.
     * Because we need to create another instance with correct reference element.
     */
    this.destroyPopper();

    if (this.reference && this.content) {
      const {
        parentRef,
        updateArrowPosition = true,
        placement,
        positionFixed,
        usePortal,
      } = this.props;

      // Create new instance with actual references when popper becomes visible
      this.popper = createPopper(
        // @ts-expect-error -- TODO[TS] fix PopperWrapper to use only forwarded ref
        parentRef?.current || this.reference,
        this.content,
        updateArrowPosition && this.arrowRef.current,
        {
          placement,
          positionFixed: usePortal || positionFixed,
          onUpdate: this.updatePlacement,
        },
      );

      if (this.props.observeContentMutations) {
        this.mutationObserver = new MutationObserver(this.popper.update);
        this.mutationObserver.observe(this.content, {
          childList: true,
          subtree: true,
        });
      }
    }
  }

  destroyPopper() {
    this.popper?.destroy();
    this.mutationObserver?.disconnect();
    this.popper = null;
  }

  show() {
    clearTimeout(this.hideTimeout);
    this.setState({active: true});
    if (this.popper?.update) {
      // fix popover position when popover render in another popover
      setTimeout(this.popper?.update);
    }
  }

  hide() {
    const duration =
      parseFloat(window.getComputedStyle(this.content).transitionDuration) *
      1000;

    this.hideTimeout = setTimeout(
      () => this.setState({active: false}),
      duration,
    );
  }

  render() {
    const {
      reference,
      children,
      usePortal,
      onMouseEnter,
      onMouseLeave,
      onMouseDown,
      onTouchStart,
      active,
      positionFixed,
      useReferenceWidth,
      parentRef,
      hidePopover,
      trackingName,
      onReferenceClick,
    } = this.props;

    const {useInverseColor} = this.state;

    /**
     * For show animation: insert hidden content into the DOM and then make it visible.
     * For hide animation: make content hidden and then remove it from the DOM.
     */
    const isContentVisible = active && this.state.active;

    let content = null;

    // Render content only when popper is active and during hide animation
    if (active || this.state.active) {
      content = (
        <Widget
          {...pick(this.props, [
            'css',
            'baseCss',
            'className',
            'spaced',
            'showArrow',
            'fixedHeight',
            'fullHeight',
            'fullWidth',
            'beyondBorders',
            'error',
            'placement',
            'data-test',
            'onClick',
            'onMouseEnter',
            'onMouseLeave',
            'onMouseDown',
            'onTouchStart',
            'scrollableRef',
          ])}
          active={isContentVisible}
          inverse={useInverseColor}
          fixed={usePortal || positionFixed}
          ref={this.contentRef}
          arrowRef={this.arrowRef}
          contentRef={this.contentInnerRef}
          placement={this.state.placement}
          {...(useReferenceWidth && {
            style: {
              width: this.reference.clientWidth,
            },
          })}
        >
          {isFunction(children)
            ? children({
                active: isContentVisible,
                inverse: useInverseColor,
                hide: hidePopover,
              })
            : children}
        </Widget>
      );
    }

    const isComponent = !isFunction(reference) && isReactComponent(reference);
    const referenceTrackingName =
      trackingName &&
      onReferenceClick &&
      // To keep original reference element's `trackingName` if specified:
      (!isComponent || !reference.props?.trackingName) &&
      `${trackingName}Trigger`;

    /**
     * @todo
     * Those handlers will overwrite all exising ones on reference
     */
    const refProps = {
      onMouseEnter,
      onMouseLeave,
      onMouseDown,
      onTouchStart,
      // @todo We should discard this logic and use only 'ref'
      [isComponent ? 'innerRef' : 'ref']: this.referenceRef,
      onClick: this.handleReferenceClick,
      ...(isComponent &&
        referenceTrackingName && {trackingName: referenceTrackingName}),
    };

    let resultingReference = isFunction(reference)
      ? reference({
          ...refProps,
          // TS doesn't see that `refProps` already has `ref` key, so passing it explicitly.
          ref: this.referenceRef,
          active: isContentVisible,
        })
      : cloneElement(reference, refProps);

    if (trackingName) {
      if (!isComponent && referenceTrackingName) {
        resultingReference = (
          <AddBabciaUBTracking trackingName={referenceTrackingName}>
            {resultingReference}
          </AddBabciaUBTracking>
        );
      }

      if (content) {
        content = (
          <BabciaScopedProvider context={trackingName}>
            {content}
          </BabciaScopedProvider>
        );
      }
    }

    if (content && usePortal) {
      content = createPortal(content, document.querySelector('#popper'));
      // @ts-expect-error -- TODO[TS] fix PopperWrapper to use only forwarded ref
    } else if (parentRef?.current) {
      // If passed - we attach popper near to parent
      // @ts-expect-error -- TODO[TS] fix PopperWrapper to use only forwarded ref
      content = createPortal(content, parentRef.current);
    }

    /**
     * We can use children as node or as render-prop for more manual control.
     * Be aware that 'active' and other non-DOM props are passed ONLY using render-prop pattern.
     * It was done for avoiding such errors:
     *
     * "Warning: Received `false` for a non-boolean attribute `foo`.
     * If you want to write it to the DOM, pass a string instead: foo="false" or foo={value.toString()}."
     */
    return (
      <Fragment>
        {resultingReference}
        {content}
      </Fragment>
    );
  }
}
