import type {FC} from 'react';
import React from 'react';

import t from '@core/translations';

import css from '../styles/ThreeDSecurePopup.css';

const THREE_D_SECURE_URL = '/api/v1/pay/threeDSecureIframe';

type ThreeDSecurePopupProps = {
  iframeUrl?: string;
};

const ThreeDSecurePopup: FC<ThreeDSecurePopupProps> = ({
  iframeUrl = THREE_D_SECURE_URL,
}) => (
  <>
    <div className={css.header}>
      <h3>{t('paymentPage', 'title.payment_page')}</h3>
    </div>
    <iframe
      src={iframeUrl}
      name="ExternalForm"
      title="ExternalForm"
      className={css.iframe}
    />
  </>
);

export default ThreeDSecurePopup;
