/**
 * List of icons which should be mirrored for rtl text direction
 */
export const ICON_LTR_MIRRORED_LIST: string[] = [
  'arrow-right',
  'arrow-left',
  'left',
  'login',
  'return',
  'right',
  'send',
  'help',
  'help',
];
