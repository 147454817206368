import wrapEmoji from '@core/utils/textEditor/wrapEmoji';

/**
 * Checks if a message is valid after wrap emoji and trimming.
 */
const isValidMessage = (message: string): boolean => {
  const processedText = wrapEmoji(message);

  return Boolean(processedText.trim());
};

export default isValidMessage;
