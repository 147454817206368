enum Benefits {
  SPECIAL_OFFER_5 = 'special-offer-5',
  SPECIAL_OFFER_32 = 'special-offer-32',
  SPECIAL_OFFER_20 = 'special-offer-20',
  SPECIAL_OFFER_40 = 'special-offer-40',
  SEARCH = 'search',
  DETAILS = 'details',
  PLATFORMS = 'platforms',
  EMAIL = 'email',
  LIKED = 'liked',
  ALL_PHOTOS = 'all-photos',
  SHARE_PHOTO = 'share-photo',
  UNLIMITED_LIKE_GALLERY = 'unlimited_like_gallery',
  SAFETY = 'safety',
  PREMIUM_SUPPORT = 'premium-support',
  INCOGNITO = 'incognito',
  CLEANING = 'cleaning',
  HOT_PHOTOS = 'hot-photos',
  UNLIMITED_CHATS = 'unlimited-chats',
  USER_VIDEOS = 'user-videos',
  PHOTO_AND_VIDEOS = 'photo-and-videos',
  SAFE_SYSTEM = 'safe-system',
  DELETE_CHAT = 'delete-chat',
  EXTRA_BOOST = 'extra-boost',
  TOP_MESSENGER = 'top-messenger',
  TOP_SEARCH = 'top-search',
  GET_RECEIPT = 'get-receipt',
  X5_PEOPLE = 'x5-people',
  X3_MORE_LIKES = 'x3-more-likes',
  PRIORITY_MAN_1D_TRIAL = 'priority-man-1d-trial',
  MORE_LIKES_1D_TRIAL = 'more-likes-1d-trial',
  INCOGNITO_1D_TRIAL = 'incognito-1d-trial',
  SUPER_BOOST_1D_TRIAL = 'super-boost-1d-trial',
  FLIRT_ALARM_1D_TRIAL = 'flirt-alarm-1d-trial',
  FIRST_TO_TALK_1D_TRIAL = 'first-to-talk-1d-trial',
  TOP_IN_SEARCH = 'top-in-search',
  ENHANCED_ACCESS_CONTROL = 'enhanced_access_control',
  FASTER_CONNECTIONS = 'faster_connections',
  MATCHES_IN_CHATS = 'matches_in_chats',
  UNLIMITED_MESSAGES = 'unlimited_messages',
  ENDLESS_MATCHES = 'endless_matches',
  REVEAL_YOU_VISITORS = 'reveal_your_visitors',
  REVEAL_INCOMMING_LIKES = 'reveal_incoming_likes',
  SEND_PRIVATE_PICKS = 'send_private_pics_and_videos',
  CUSTOM_SEARCH_FILTERS = 'custom_search_filters',
  WATCH_TOP_STORIES = 'watch_top_stories',
  NO_ADVERTISING = 'no_advertising',
  READ_MESSAGES = 'read_messages',
  UNLIMITED_SEARCH = 'unlimited_search',
  MARK_AS_READ = 'mark_as_read',
  COINS_BALANCE_BOOST_500 = '500_coins_balance_boost',
  EXTRA_COINS_PAYMENT = 'extra_coins_payment',
  COINS_MONTHLY_200 = '200_coins_monthly',
}

export default Benefits;
