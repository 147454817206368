/**
 * Processing status constants.
 * @see setPaymentProcessingStatus for descriptions
 */

const INITIAL = 'initial';
const LOADING = 'loading';
const READY = 'ready';
const PROCESSING = 'processing';
const FAILED = 'failed';
const SUCCESS = 'success';

/**
 * Available statuses transitions
 * @type {{initial: string[], ready: (string)[], success: (string)[], processing: (string)[], failed: (string)[], loading: string[]}}
 */
export const TRANSITIONS = {
  [INITIAL]: [LOADING, READY],
  [LOADING]: [READY],
  [READY]: [PROCESSING, LOADING, INITIAL],
  [PROCESSING]: [FAILED, SUCCESS, READY],
  [FAILED]: [READY, PROCESSING, LOADING],
  [SUCCESS]: [READY, LOADING],
};

export default {INITIAL, LOADING, READY, PROCESSING, FAILED, SUCCESS};
