import {MSG} from '../constants/socketEventNames';

export default {
  PhotoUploadSubscription: {
    incomingEvents: `${MSG}photoUploaded`,

    fromServer: ({photoId, via, fileName}) => ({
      data: {
        photoUpload: {photoId, via, fileName, __typename: 'PhotoUpload'},
      },
    }),
  },
};
