import {getClientInstance} from '@core/graphql/client';
import {setInitPaymentVariables} from '@core/payment/common/utils/paymentInitVariables';

const resetPaymentPackagesCache = (broadcast = true) => {
  setInitPaymentVariables(null);

  getClientInstance().cache.evict({
    id: 'Payment:{}',
    fieldName: 'packagesData',
    broadcast,
  });
};

export default resetPaymentPackagesCache;
