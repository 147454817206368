import {MSG} from '../constants/socketEventNames';

export default {
  InteractionResentMessage: {
    incomingEvents: [`${MSG}resentMessages`],
    fromServer: ({recipients, allUnsentMessagesCount}) => ({
      data: {
        recipients: recipients.map((recipient) => ({
          ...recipient,
          messages: recipient.messages.map((message) => ({
            ...message,
            __typename: 'Message',
          })),
          __typename: 'Recipient',
        })),
        allUnsentMessagesCount,
      },
    }),
  },
};
