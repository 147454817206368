import {getClientInstance} from '@core/graphql/client';

import type {RecipientFragment} from '../graphql/fragments/recipient';
import type {RecipientWithMessagesFragment} from '../graphql/fragments/recipientMessages';
import RECIPIENT_FRAGMENT from '../graphql/fragments/recipient.gql';
import RECIPIENT_WITH_MESSAGES_FRAGMENT from '../graphql/fragments/recipientMessages.gql';

type Recipient = RecipientFragment | RecipientWithMessagesFragment;
type RecipientWithMessages = Omit<Recipient, 'messages'> & {
  messages?: RecipientWithMessagesFragment['messages'];
};

type UpdaterFunction = (
  recipient: RecipientWithMessages,
) => RecipientWithMessages;
type Updater = UpdaterFunction | object;

/**
 * Updates apollo cache for specified Recipient
 */
export default function updateRecipientCache(
  recipientId: string,
  updater: Updater,
): void {
  const client = getClientInstance();
  let fragment = {
    fragment: RECIPIENT_WITH_MESSAGES_FRAGMENT,
    fragmentName: 'RecipientWithMessages',
    id: `Recipient:${recipientId}`,
  };

  let recipient: Recipient =
    client.readFragment<RecipientWithMessagesFragment>(fragment);
  if (!recipient) {
    /**
     * We have one case {@see startMessengerListeners} when we need to read
     * 'recipient' even when 'messages' was not requested.
     */
    fragment = {
      fragment: RECIPIENT_FRAGMENT,
      fragmentName: 'Recipient',
      id: `Recipient:${recipientId}`,
    };
    recipient = client.readFragment<RecipientFragment>(fragment);
  }

  // If recipient doesn't exist, won't use updater and writeFragment
  if (recipient) {
    const data = typeof updater === 'function' ? updater(recipient) : updater;

    if (data) {
      client.writeFragment<RecipientWithMessagesFragment | RecipientFragment>({
        ...fragment,
        data: {...recipient, ...data},
      });
    }
  }
}
