import getCookie from '../../../utils/cookie/getCookie';
import {
  PAY_REMARKETING_OFFER_TEMPLATE,
  PAY_TEMPLATE,
} from '../constants/cookies';

const templateFromCookie = getCookie(PAY_TEMPLATE);

const RMTemplateFromCookie = getCookie(PAY_REMARKETING_OFFER_TEMPLATE);

/**
 * @param {string} template
 * @param {boolean} isRemarketingOffer
 * @return {string}
 */
const getPaymentTemplate = (template, isRemarketingOffer = false) => {
  if (isRemarketingOffer) {
    return RMTemplateFromCookie || template;
  }

  return templateFromCookie || template;
};

export default getPaymentTemplate;
