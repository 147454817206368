const isFewWords = (value) => value.indexOf(' ') > 0;

/**
 * Only chars, space and '-'
 */
export const isOnlyChars = (value) => /^[a-zA-Z\s-]+$/.test(value);

/**
 * Validation for double name:
 * - the line did not start with '-'
 * - the line did not end with '-'
 * - the word in the line did not start or end with '-'
 * - there were not several characters in a row
 */
export const isValidDoubleName = (value) =>
  !/(^-.)|(.-$)|(--+)|(.-\s)|(\s-)/.test(value);

/**
 * Validates card holder
 * @param {String} value Card holder name
 * @returns {Boolean}
 */
export default function isCardHolderValid(value) {
  return Boolean(
    value &&
      isOnlyChars(value) &&
      isFewWords(value) &&
      isValidDoubleName(value),
  );
}
