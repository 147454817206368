import type {ReactNode, FC} from 'react';
import React, {useEffect} from 'react';
import {useReactiveVar} from '@apollo/client';
import {useLocation} from 'react-router-dom';

import accessErrorVar from '@core/graphql/vars/accessErrorVar';

import {Provider as AccessErrorProvider} from './AccessErrorContext';
import getErrorRedirect from '../utils/getErrorRedirect';
import type {ACLCodesMap, ACLErrorRenderer} from '../types';

type AccessErrorProps = {
  rulesMap: ACLCodesMap;
  children: ReactNode;
};

/**
 * General access error handler component.
 * Used to perform side effect actions based on received error
 * from cache (generated by '@apollo/client/link/error' and passed by '@apollo/client/link/state' inside cache)
 * or generated from access rules inside routing.
 * @see client.ts
 */
const AccessError: FC<AccessErrorProps> = ({rulesMap, children}) => {
  let Action: ACLErrorRenderer = null;

  const location = useLocation();

  const {accessError, accessErrorData} = useReactiveVar(accessErrorVar);

  useEffect(() => {
    if (accessError) {
      accessErrorVar({accessError: null, accessErrorData: {}});
    }
  }, [accessError]);

  if (accessError) {
    Action = getErrorRedirect(accessError, rulesMap);
  }

  return (
    <AccessErrorProvider value={{rulesMap}}>
      {children}
      {Boolean(Action) && (
        <Action location={location} errorData={accessErrorData} />
      )}
    </AccessErrorProvider>
  );
};

export default AccessError;
