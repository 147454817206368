import isNaN from 'lodash/isNaN';

export enum RoundType {
  ROUND = 'round',
  FLOOR = 'floor',
  CEIL = 'ceil',
}

/**
 * Decimal adjustment of a number.
 * @example decimalAdjust(3.444) => 3.45
 * @example https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/ceil
 * @param val - Some number, for example 3.444
 * @param exponent - The exponent (the 10 logarithm of the adjustment base)
 * @param type - The type of adjustment.
 */
const decimalAdjust = (
  val: string | number,
  exponent: number = -2,
  type: RoundType = RoundType.CEIL,
): number => {
  const exp = +exponent;
  let value: number | string[] = +val;

  // If the exp is undefined or zero...
  if (typeof exponent === 'undefined' || exp === 0) {
    return Math[type](value);
  }

  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }

  // Shift
  value = value.toString().split('e');
  value = Math[type](+`${value[0]}e${value[1] ? +value[1] - exp : -exp}`);
  // Shift back
  value = value.toString().split('e');

  return +`${value[0]}e${value[1] ? +value[1] + exp : exp}`;
};

export default decimalAdjust;
