import type {Action, Source} from '@core/types/graphql';
import {ViaEnum} from '@core/types/graphql';

import type PackageTypes from '../../widgets/package/constants/packageTypes';

export type InitialPaymentParams = {
  originalAction?: Action | null;
  action: Action | null;
  stockId?: string | null;
  packageType?: PackageTypes | null;
  prevVia?: ViaEnum | null;
  via: ViaEnum | null;
  viaMethod?: string | null;
  returnPath?: string | null;
  successReturnPath?: string | null;
  skipStepOnBack?: boolean | null;
  withOpenedCardForm?: boolean | null;
  viaProfileId?: string | null;
  orderId?: string | null;
  tryLaterStep?: boolean | null;
  source?: Source | null;
  threeDSecureComplete?: boolean | null;
  is3dSecureSuccess?: boolean | null;
};

const INITIAL_PAYMENT_PARAMS: InitialPaymentParams = {
  originalAction: null,
  action: null,
  stockId: null,
  packageType: null,
  prevVia: null,
  via: ViaEnum.unknown,
  viaMethod: null,
  returnPath: null,
  successReturnPath: null,
  skipStepOnBack: null,
  withOpenedCardForm: null,
  viaProfileId: null,
  orderId: null,
  tryLaterStep: null,
  source: null,
  threeDSecureComplete: null,
  is3dSecureSuccess: null,
};

export default INITIAL_PAYMENT_PARAMS;
