import getHistory from '@core/application/utils/getHistory';

import {PaymentFlowStatus} from '../../utils/getProcessPaymentFlow';
import type {PaymentProcessStep} from '../../types/paymentProcess';

/**
 * Redirect to external 3DS page
 */
const externalThreeDSRedirect: PaymentProcessStep = async (data) => {
  const {paymentResult} = data;
  const redirectUrl = paymentResult['3dsRedirectUrl'];

  if (redirectUrl) {
    getHistory().push(redirectUrl);
    return {...data, flowStatus: PaymentFlowStatus.ABORTED};
  }

  return data;
};

export default externalThreeDSRedirect;
