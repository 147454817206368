import CommunicationRestriction from '../constants/CommunicationRestriction';
import updateRecipientCache from '../../common/utils/updateRecipientCache';

/**
 * Removes message from cache.
 * @see sendMessage.js
 * @param {string} recipientId
 * @param {string} messageId
 * @param {?string} error
 */
const removeMessageFromCache = (recipientId, messageId, error = null) =>
  updateRecipientCache(recipientId, (cacheRecipient) => {
    const {messages, user, lastMessage} = cacheRecipient;
    if (!messages || !messages.length) return cacheRecipient;

    const nextMessages = messages.filter(({id}) => id !== messageId);
    const recipient = {messages: nextMessages};

    // recipient has blocked current user, communication restricted and we need to show cap
    if (error === CommunicationRestriction.BLOCKED_USER) {
      recipient.user = {
        ...user,
        blockUser: {...user.blockUser, heMe: true},
      };
    }

    if (messageId === lastMessage.id) {
      const message = nextMessages[nextMessages.length - 1];
      recipient.lastMessage = {
        ...lastMessage,
        id: message ? message.id : recipientId,
        type: message ? message.type : '',
        text: message ? message.text : '',
        isSending: false,
      };
    }

    return recipient;
  });

export default removeMessageFromCache;
