import type {ReactNode, FC} from 'react';

import generatePayUrl from '../../../common/utils/generatePayUrl';
import usePaymentStatus from '../../../common/utils/usePaymentStatus';

type PaymentUrlProps = {
  via: string;
  id?: string;
  children: (props: {paymentUrl: string}) => ReactNode;
};

/**
 * Render-prop component to create something with paymentUrl
 */
const PaymentUrl: FC<PaymentUrlProps> = ({via, id, children}) => {
  const {loading, ...accountStatus} = usePaymentStatus();

  if (loading) {
    return null;
  }

  const paymentUrl = generatePayUrl({
    accountStatus,
    urlParams: {via, viaProfileId: id},
  });

  return children({paymentUrl});
};

export default PaymentUrl;
