/**
 * Step list for track pending page
 */
enum STEPS {
  APPEARED = 'appeared',
  DISAPPEARED = 'disappeared',
  DISPLAYED = 'displayed',
  DELIVERED = 'paymentCompleteDelivered',
}

export default STEPS;
