import once from 'lodash/once';

import type {EventsInput} from '@core/types/graphql';

import BaseTracker from '../base/BaseTracker';
import prepareData from '../base/prepareData';
import TRACK_MEDIA_UPLOAD_ERROR_MUTATION from './graphql/mutations/trackMediaUploadError.gql';
import type {TrackMediaUploadErrorMutationVariables} from './graphql/mutations/trackMediaUploadError';
import {MEDIA_UPLOAD_ERROR} from '../constants/identities';

// Media uploading errors track (by websocket api)
const trackMediaUploadError = (data: EventsInput): void => {
  const initializeTracker = once(() => {
    const mediaUploadErrorTracker = new BaseTracker<
      TrackMediaUploadErrorMutationVariables,
      EventsInput
    >(
      TRACK_MEDIA_UPLOAD_ERROR_MUTATION,
      prepareData,
      MEDIA_UPLOAD_ERROR.METHOD,
    );

    mediaUploadErrorTracker.checkAndTrack(data, true);
  });

  initializeTracker();
};

export default trackMediaUploadError;
