/**
 * Process text by regexp mask, to remove inappropriate signs
 */
const processTextMask = (inputValue: string): string => {
  if (!inputValue) {
    return inputValue;
  }

  let data = inputValue;
  let regExp = new RegExp('\\n +', 'g');

  if (regExp.test(data)) {
    data = data.replace(regExp, '\n');
  }

  regExp = new RegExp('\\n{3,}', 'g');

  if (regExp.test(data)) {
    data = data.replace(regExp, '\n\n');
  }

  return data;
};

export default processTextMask;
