/**
 * Type of barProgress. Used to set color modification of progress.
 * @see BarProgress.tsx
 */
enum ProgressType {
  DEFAULT = 'default', // no specific color modification, just default theme progress appearance
  DANGER = 'danger',
  SUCCESS = 'success',
  PRIMARY = 'primary',
  ACCENT = 'accent',
  WARNING = 'warning',
  HEADER_COLOR = 'headerColor',
  LIGHT = 'light',
}

export default ProgressType;
