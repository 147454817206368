import type {ReactNode, ComponentType, ReactElement} from 'react';
import React, {Children, cloneElement, createRef} from 'react';
import without from 'lodash/without';
import pickBy from 'lodash/pickBy';

import type {InputFrameProps} from '../input/InputFrame';
import type {MenuComponent} from '../menu/Menu';
import type {SelectProps, SelectState, SelectValue} from './Select';
import Select from './Select';
import SelectItemContent from './SelectItemContent';
import SelectInputContent from './SelectInputContent';
import css from './Select.css';

type MultiSelectEvent = (MouseEvent | TouchEvent) & {
  // eslint-disable-next-line no-use-before-define
  multipleSelect: MultipleSelect;
};

type SelectTagComponent = ComponentType<{
  className: string;
  children: ReactNode;
}>;

export type MultipleSelectProps = SelectProps & {
  tagComponent: SelectTagComponent;
  frameComponent: ComponentType<InputFrameProps>;
  menuComponent: MenuComponent;
};

type MultipleSelectState = SelectState & {
  value: string[];
};

export default class MultipleSelect extends Select<
  MultipleSelectProps,
  MultipleSelectState
> {
  static defaultProps = {
    ...Select.defaultProps,
    fullWidth: false,
    usePortal: true,
    hideByScroll: true,
    useReferenceWidth: true,
    multiple: true,
  };

  constructor(props: MultipleSelectProps) {
    super(props);

    this.popoverRef = createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.active && this.state.active) {
      this.toggleListeners(true);
    } else if (prevState.active && !this.state.active) {
      this.toggleListeners();
    }

    super.componentDidUpdate(prevProps, prevState);
  }

  componentWillUnmount() {
    this.toggleListeners();
  }

  handlePreventOutsideClose = (event: MultiSelectEvent) => {
    event.multipleSelect = this;
  };

  handleOutsideClose = (event: MultiSelectEvent) => {
    if (event.multipleSelect !== this) {
      this.handleActiveChange(false);
    }
  };

  toggleListeners = (add = false) => {
    const key = add ? 'addEventListener' : 'removeEventListener';

    ['mousedown', 'touchstart'].forEach((event) => {
      // @ts-expect-error suppress nested Refs error
      this.popoverRef.current?.scrollableRef?.current?.[key](
        event,
        this.handlePreventOutsideClose,
      );
      this.wrapperRef.current?.[key](event, this.handlePreventOutsideClose);

      document[key](event, this.handleOutsideClose);
    });
  };

  /**
   * @overriden
   */
  handleActiveChange = (active: boolean) => {
    this.setState({active, focused: active});
  };

  /**
   * @overriden
   */
  // @ts-expect-error suppress inheritance of Class error
  getValue = (value: SelectValue): SelectValue | SelectValue[] => {
    /**
     * To avoid massive disabling of Eslint rules we
     * cast them previously into string
     */
    const currentValue = String(value);
    const defaultValue = String(this.props.children[0].props.value);
    const defaultKey = String(this.props.children[0].props.children);

    // If user selects default value - reset all other options
    if (currentValue === defaultValue) {
      return [currentValue];
    }

    // If user selects default value using the keyboard,
    // not a value `null` comes but his key. Reset all other options
    if (currentValue === defaultKey) {
      return [defaultValue];
    }

    let result = this.state.value.map((str) => String(str));

    if (result.filter((i) => i === currentValue).length) {
      result = without(result, currentValue);
      // If user un-selects all options - we must select default one
      if (!result.length) {
        return [defaultValue];
      }
    } else {
      result = without(result, defaultValue);
      result.push(currentValue);
    }

    return result;
  };

  /**
   * @override
   */
  static parseValueInChildren(properties: {children: ReactNode[]}) {
    return properties.children
      .filter((child: ReactElement) => child.props.active)
      .map((child: ReactElement) => child.props.value);
  }

  /**
   * @overriden
   */
  renderMenuContent = () => {
    // Detect active element
    return Children.map(this.props.children, (child: ReactElement) => {
      const active = Boolean(
        this.state.value.filter(
          (value) => String(value) === String(child.props.value),
        ).length,
      );

      return cloneElement(child, {
        active,
        children: (
          // Wrap children for set icon
          <SelectItemContent {...child.props} showCheck={active}>
            {child.props.children}
          </SelectItemContent>
        ),
      });
    });
  };

  /**
   * @override
   */
  // @ts-expect-error suppress empty method override error
  handleMouseDown() {}

  /**
   * @override
   */
  // @ts-expect-error suppress empty method override error
  handleKeyDown() {}

  renderContainer() {
    const dataProps = pickBy(this.props, (val, key) => key.startsWith('data-'));

    return (
      <SelectInputContent
        active={this.state.active}
        inverse={this.props.inverse}
        {...dataProps}
      >
        {this.renderValue()}
      </SelectInputContent>
    );
  }

  /**
   * @overriden
   */
  renderValue() {
    const {value} = this.state;
    const {tagComponent: Tag} = this.props;
    const valueLength = value.length;

    if (valueLength === 1) {
      return Children.map(this.props.children, (child) => {
        if (String(child.props.value) !== String(value[0])) {
          return null;
        }

        return (
          <div className={css.value}>
            <SelectItemContent cut {...child.props}>
              {child.props.children}
            </SelectItemContent>
          </div>
        );
      });
    }

    return [
      valueLength > 1 && (
        <Tag className={css.tag} key="counter">
          {value.length}
        </Tag>
      ),
      <div className={css.cutedValue} key="value">
        {value
          .map(
            (key) =>
              this.values.find((item) => String(item.value) === String(key))
                .name,
          )
          .join(', ')}
      </div>,
    ];
  }
}
