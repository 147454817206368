import intersection from 'lodash/intersection';
import type {FetchResult} from '@apollo/client';

import {getClientInstance} from '@core/graphql/client';
import removeTypename from '@core/graphql/utils/removeTypename';

import clearChatUpLineInCache from './clearChatUpLineInCache';
import DISAPPROVE_QUERY from '../graphql/queries/disapprove.gql';
import type {DisapproveQuery} from '../graphql/queries/disapprove';
import type DisapproveResult from '../../current/types/disapproveResult';

const updateDisapproveCache = ({
  data: result,
}: FetchResult<DisapproveResult>) => {
  const disapprove = result?.disapprove;

  const client = getClientInstance();

  const data = client.readQuery<DisapproveQuery>({
    query: DISAPPROVE_QUERY,
  });
  /**
   * Choose only target fields for disapprove
   */
  const update = {};
  intersection(
    Object.keys(removeTypename(data.disapprove.statuses)),
    Object.keys(disapprove),
  ).forEach((item) => {
    update[item] = disapprove[item];
  });

  if (disapprove.textStatus) {
    clearChatUpLineInCache(client);
  }

  /**
   * Just optimal value for setTimeout
   * in order to just work on IOS or have time to update the status.
   * Not necessarily accurate.
   */
  const TIME_TO_UPDATE = 500;

  /**
   * We have to use setTimeout to fix a bug with update apollo cache.
   * If we use client.writeQuery without setTimeout we getting an old data.
   * More information about this you can see by reference below.
   * @see https://jira.togethernetworks.com/browse/TN-178922 and FE-5277
   */
  setTimeout(() => {
    client.writeQuery<DisapproveQuery>({
      query: DISAPPROVE_QUERY,
      data: {
        ...data,
        disapprove: {
          ...data.disapprove,
          statuses: {
            ...data.disapprove.statuses,
            ...update,
          },
        },
      },
    });
  }, TIME_TO_UPDATE);
};

export default updateDisapproveCache;
