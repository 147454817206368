/**
 * Types of payment button tracking values
 */
enum PAY_BUTTON_TRACKING {
  APPLE_PAY = 'applePay',
  GOOGLE_PAY = 'googlePay',
  PAYPAL = 'paypal',
  DEFAULT = 'default',
}

export default PAY_BUTTON_TRACKING;
