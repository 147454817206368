import {OpenProfileSource} from '@core/types';

/**
 * From where user was redirected to target profile
 */
const getOpenProfileSource = (pathname: string) => {
  if (pathname) {
    if (pathname.includes('search')) {
      return OpenProfileSource.fromSearch;
    }
    if (pathname.includes('chat')) {
      return OpenProfileSource.fromChat;
    }
  }
  return null;
};

export default getOpenProfileSource;
