enum BottomNoticeType {
  /** Displayed if during buying user gets a payment decline */
  DECLINE_ALERT = 'declineAlert',
  /**  Displayed if user visited payment page and returned back without buying anything. */
  MEMBERSHIP_ALERT = 'membershipAlert',
  /**  Displayed if user buy membership and returned to the site without buying features. */
  FEATURES_ALERT = 'featuresAlert',
}

export default BottomNoticeType;
