/**
 * Available photo levels.
 */
enum PhotoLevel {
  NORMAL = 0,
  BLURRED = 1,
  HIGHLY_BLURRED = 2,

  /**
   * Social level behaves like "normal" but has different photoLevel value.
   * Used for something between normal and sexy levels.
   * Doesn't have naughty mode overlay.
   */
  SOCIAL = 3,

  /**
   * Needed to blur some sexy photos.
   * Does have naughty mode small overlay.
   */
  SASSY = 4,
}

export default PhotoLevel;
