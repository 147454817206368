import URI from '@core/utils/url';
import getHistory from '@core/application/utils/getHistory';
import activePackageVar from '@core/graphql/vars/activePackageVar';
import isCardPaymentType from '@core/payment/forms/card/utils/isCardPaymentType';

/**
 * @desc Get active package from cache and set package id in URL.
 * Used for emulate steps on mobile payment page
 */
const addActivePackageIdInUrl = ({saveHistory = true} = {}) => {
  const activePackage = activePackageVar();
  const stockId = activePackage?.stockId;

  if (!stockId) {
    return null;
  }

  const {
    location: {pathname, search},
    push,
    replace,
  } = getHistory();

  let url = URI(`${pathname}${search}`)
    .removeSearch('viaMethod')
    .setSearch({stockId});

  if (!isCardPaymentType(activePackage.paymentMethod)) {
    url.setSearch('viaMethod', activePackage.paymentMethod);
  }

  url = url.toString();

  return saveHistory ? push(url) : replace(url);
};

export default addActivePackageIdInUrl;
