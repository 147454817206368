import type {Observable} from 'rxjs';
import {from, merge} from 'rxjs';
import {map, filter} from 'rxjs/operators';

import getApproveScreennameSubscription from '@core/user/profile/current/utils/getApproveScreennameSubscription';
import getApproveStatusSubscription from '@core/user/profile/current/utils/getApproveStatusSubscription';

import {DISAPPROVE_TYPES} from '../constants/notificationTypes';
import type {CommonNotification} from '../types';

/**
 * Data source for disapprove interactions.
 */
const disapproveDataSource = (): Observable<CommonNotification> =>
  merge(
    // 'from' is used to convert zen-observable into RxJS compatible observable
    from(getApproveScreennameSubscription()),
    from(getApproveStatusSubscription()),
  ).pipe(
    map(({data: {disapprove}}) => {
      if ('screenname' in disapprove && disapprove.screenname) {
        return {type: DISAPPROVE_TYPES.DISAPPROVE_SCREENNAME};
      }
      if ('textStatus' in disapprove && disapprove.textStatus) {
        return {type: DISAPPROVE_TYPES.DISAPPROVE_STATUS};
      }
      return null;
    }),
    filter<CommonNotification>(Boolean),
  );

export default disapproveDataSource;
