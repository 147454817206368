enum PaymentPageAppearance {
  /**
   * Page has toolbar. Vertical list of packages.
   */
  WITH_TOOLBAR = 'withToolbar',

  /**
   * Page has layered content.
   */
  LAYERED = 'layered',

  /**
   * Page has centered minimized packages.
   */
  WITH_CENTERED_PACKAGES = 'withCenteredPackages',
}

export default PaymentPageAppearance;
