import {MSG} from '../constants/socketEventNames';

export default {
  MicrofeatureAssignedSubscription: {
    incomingEvents: [`${MSG}microfeatureAssignedToUser`],

    fromServer: ({featureName, activeFrom, expiredAt}) => ({
      data: {
        microFeatures: {
          microFeatureList: [
            {
              featureName,
              activeFrom,
              expiredAt,
              __typename: 'MicroFeatureList',
            },
          ],
          __typename: 'MicroFeatures',
        },
      },
    }),
  },
};
