import type {Observable} from 'rxjs';
import {forkJoin, of} from 'rxjs';
import {map} from 'rxjs/operators';
import compact from 'lodash/compact';
import type {DocumentNode} from 'graphql';

import type {ACLRule} from '@core/application/types';
import type {Client} from '@core/graphql/types';

const resolveQuery = (client: Client, query: DocumentNode) => {
  // We don't know data type here, because it can be any query from `src/packages/*/application/accessRules/*`.
  const data = client.readQuery<unknown>({query});
  if (data) {
    return of({data});
  }

  // failed to resolve synchronously
  return client.query<unknown>({query});
};

/**
 * Observable is used to allow possibility to cancel pending requests.
 * The main problem: we are on one route, we change route when not all ACL
 * rules are resolved, resolved rules on unmounted component call 'setState',
 * 'setState' calls memory leak on unmounted component.
 *
 * Inspired from:
 * https://medium.com/@benlesh/promise-cancellation-is-dead-long-live-promise-cancellation-c6601f1f5082
 */
const getErrorsObservable = (
  rules: ACLRule[],
  client: Client,
): Observable<string[]> =>
  forkJoin(rules.map((rule) => resolveQuery(client, rule.query))).pipe(
    map((responses) =>
      compact(
        responses.map(({data}, index) => {
          const rule = rules[index];
          return rule.resolver(data) ? false : rule.errorCode;
        }),
      ),
    ),
  );

export default getErrorsObservable;
