import {MSG} from '../constants/socketEventNames';

export default {
  FreeViewsRemainingSubscription: {
    incomingEvents: [
      `${MSG}freeViewMessageIncrease`,
      `${MSG}freeViewMessageDecrease`,
    ],

    fromServer: ({remainingCount}) => ({
      data: {
        freeViews: {
          remainingCount,
          __typename: 'FreeViews',
        },
      },
    }),
  },
};
