/* global Backbone */
/**
 * Very strange things.
 * Using to proxy event from rendered view outside
 * See example below
 *
 *
 var popup = window.app.popup.open(PopupUploadVideoView, {
            model: this.model
        });
 this.listenTo(popup, 'buttonAcceptClick', this.onButtonAcceptClick);
 * @constructor
 */
function LegacyPopupProxyView() {
  this.setPopupView = (view) => {
    this.removeProxy();
    this.view = view;
    this.startPopupViewListeners();
  };
}

LegacyPopupProxyView.prototype = {
  startPopupViewListeners() {
    /**
     * ATTENTION!! Unfortunately we can't listen remove event and stop listening view because there is same
     * async functionality which work after popup close and remove view from region but trigger event.
     * I know that it is a memory leak but anther way doesn't exist.
     */
    this.listenTo(this.view, 'all', this.proxyEvent);
  },

  proxyEvent(...args) {
    this.trigger(...args);
  },

  removeProxy() {
    this.view && this.stopListening(this.view);
    delete this.view;
  },
  ...Backbone.Events,
};

export default LegacyPopupProxyView;
