import find from 'lodash/find';

import {getClientInstance} from '@core/graphql/client';
import ALT_METHODS_SCENARIO from '@core/payment/common/graphql/queries/altMethodsScenario.gql';

/**
 * Get alt method scenario form server
 * @param {String} paymentMethod
 * @param {String} action
 * @param {String} via
 * @return {Promise<String | PaymentScenario>}
 */
const getMethodScenario = async (action, via, paymentMethod) => {
  const {
    data: {
      payment: {
        packagesData: {altMethodsScenario},
      },
    },
  } = await getClientInstance().query({
    query: ALT_METHODS_SCENARIO,
    variables: {action, via},
  });

  const result = find(altMethodsScenario, {method: paymentMethod});
  return result && result.scenario;
};

export default getMethodScenario;
