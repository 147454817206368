import {getCookie} from '@core/utils/cookie';

import getParameter from './getParameter';

/**
 * Return theme name from site config or from split (Cookie)
 */
const getThemeName = (): string | null =>
  getCookie('pub_theme') || getParameter('themeName');

export default getThemeName;
