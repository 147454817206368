import uniqueId from 'lodash/uniqueId';

/**
 * Exported for comparing ID's on client.
 * @see markAsRead.js
 */
export const GENERATED_PREFIX = 'generated_';

/**
 * Separated utility, used to generate client side ID's for activities and similar entities,
 * since we have ones, that haven't any ID that arrives from server.
 */
const generateUniqueId = (): string => uniqueId(GENERATED_PREFIX);

export default generateUniqueId;
