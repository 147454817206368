import type {MutableRefObject} from 'react';
import React, {forwardRef} from 'react';

import type {CommonTabProps} from './types';
import useTabWrapper from './useTabProps';

export interface TabProps extends CommonTabProps {
  value: string | number;
  /**
   * Needed for correct work of React popper. Arrived from Popover.
   */
  innerRef?: MutableRefObject<HTMLButtonElement>;
}

/**
 * Static link with tab appearance
 */
const Tab = forwardRef<HTMLButtonElement, TabProps>(
  ({innerRef, ...props}, ref) => {
    const {wrap, ...rest} = useTabWrapper(props);

    return wrap(<button ref={innerRef || ref} type="button" {...rest} />);
  },
);

Tab.displayName = 'Tab';

export default Tab;
