import {CTR} from '@core/tracking/constants/identities';

/**
 * Structure of websocket push action when we send track about CTR functionality
 */
export default {
  TrackCTRMutation: {
    toServer: (params) => ({method: CTR.METHOD, params}),

    fromServer: (response, error) => ({
      data: {
        trackCTR: {
          error: error || null,
          __typename: 'CtrMutationResult',
        },
      },
    }),
  },
};
