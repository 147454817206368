import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import type {PaymentDataRequest} from '@core/payment/payProcess/types/paymentFlow';

import {SEPA_METHODS} from '../../common/constants/paymentMethods';
import PaymentScenario from '../../common/constants/paymentScenario';

const parsePayParamsBeforePayment = (
  paymentData: Partial<PaymentDataRequest>,
): Partial<PaymentDataRequest> => {
  const {
    isInstantRepeat,
    hidePaymentForm,
    country,
    currencyCode,
    method,
    numberHouse,
    oneClickFlow,
    packageId,
    scenario,
    selectedAdditionalPackages,
    stockId,
    userId,
  } = paymentData;

  if (country) {
    paymentData.country_code = country;
    Reflect.deleteProperty(paymentData, 'country');
  }

  // TODO[BB_removed]: Think about add userId here after removing Backbone payment or find out to BU why it's needed
  if (userId) {
    paymentData.user_id = userId;
    Reflect.deleteProperty(paymentData, 'userId');
  } else {
    Reflect.deleteProperty(paymentData, 'userId');
  }

  if (currencyCode) {
    paymentData.currency_code = currencyCode;
    Reflect.deleteProperty(paymentData, 'currencyCode');
  }

  paymentData.package_id = stockId;
  paymentData.product_id = packageId;

  Reflect.deleteProperty(paymentData, 'stockId');
  Reflect.deleteProperty(paymentData, 'packageId');

  if (isEmpty(selectedAdditionalPackages)) {
    Reflect.deleteProperty(paymentData, 'selectedAdditionalPackages');
  }

  if (isEmpty(scenario)) {
    Reflect.deleteProperty(paymentData, 'scenario');
  }

  if (
    isEmpty(oneClickFlow) ||
    (scenario !== PaymentScenario.ONECLICK && oneClickFlow)
  ) {
    Reflect.deleteProperty(paymentData, 'oneClickFlow');
  }

  // TODO[BB_removed]: Check if 'hidePaymentForm' is a number in all places and Backbone is removed, then remove it too
  if (!isUndefined(hidePaymentForm)) {
    paymentData.hidePaymentForm = Number(hidePaymentForm);
  }

  // TODO[BB_removed]: Think about converting 'isInstantRepeat' when it's initialized after removing Backbone
  if (!isNil(isInstantRepeat)) {
    paymentData.isInstantRepeat = Number(isInstantRepeat);
  } else {
    Reflect.deleteProperty(paymentData, 'isInstantRepeat');
  }

  if (
    // @ts-expect-error --> TODO[TS] TS2345: Problem in shchema.gql, sometimes method is string, sometimes is enum
    Object.values(SEPA_METHODS).includes(method)
  ) {
    /**
     * Assigned value numberHouse to houseNumber for correct validation on backend
     * and remove temp value numberHouse
     * TODO[BB_removed]: Think about unification of 'numberHouse' and 'houseNumber' after removing Backbone
     */
    if (!isUndefined(numberHouse)) {
      paymentData.houseNumber = numberHouse;
    }
  }

  return paymentData;
};

export default parsePayParamsBeforePayment;
