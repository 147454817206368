import type {Cache, ApolloCache} from '@apollo/client';

type InvalidationOptions = {filter?: (key: string) => boolean} & Omit<
  Cache.EvictOptions,
  'id'
>;

/**
 * Utility function for invalidating part of data inside cache and instantly
 * re-fetch it if it has any appearance on frontend.
 * WARNING: It doesn't work anymore for "typenames" without an id!!!
 */
const invalidateCacheByTypename = (
  {cache}: {cache: ApolloCache<object>},
  typename: string,
  fieldNameOrOptions: string | InvalidationOptions = {},
): void => {
  const {filter = () => true, ...options} =
    typeof fieldNameOrOptions === 'string'
      ? {fieldName: fieldNameOrOptions}
      : fieldNameOrOptions;

  const cacheRegexp = new RegExp(`^${typename}(:|$)`);

  /**
   * Get all normalized cache ids (they start from typename)
   * and remove the matching ones.
   */
  Object.keys(
    // @ts-expect-error I know the `data` is private and may break after Apollo Client update, but it works for now.
    cache.data.data,
  ).forEach((key) => {
    if (cacheRegexp.test(key) && filter(key)) {
      cache.evict({id: key, ...options});
    }
  });
};

export default invalidateCacheByTypename;
