import type {FC, HTMLAttributes, MutableRefObject} from 'react';
import React from 'react';
import cn from 'classnames';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import IconSize from '../../constants/IconSize';
import {ICON_LTR_MIRRORED_LIST} from '../../constants/iconRtlMirroredList';
import css from './Icon.css';

export interface IconProps extends HTMLAttributes<HTMLElement> {
  type: string;
  trackingName?: string;
  inverse?: boolean;
  size?: IconSize;
  standard?: boolean;
  active?: boolean;
  primary?: boolean;
  success?: boolean;
  danger?: boolean;
  warning?: boolean;
  accent?: boolean;
  inherit?: boolean;
  muted?: boolean;
  disabled?: boolean;
  // Ref is required for usage inside tooltip or popover, since it goes set in shadow.
  innerRef?: MutableRefObject<HTMLElement> | ((el: HTMLElement | null) => void);
}

const Icon: FC<IconProps> = ({
  className,
  trackingName,
  type,
  inverse = false,
  size = IconSize.SIZE_24,
  standard = false,
  active = false,
  primary = false, // Alias on "active" as more standard in terms of different icon types.
  success,
  danger = false,
  warning = false,
  accent = false,
  inherit = false,
  muted = false,
  disabled = false,
  innerRef,
  ...props
}) => {
  const content = (
    <i
      className={cn(
        css.default,
        inverse && css.inverse,
        standard && css.standard,
        (active || primary) && css.active,
        success && css.success,
        danger && css.danger,
        warning && css.warning,
        accent && css.accent,
        inherit && css.inherit,
        css[size],
        muted && css.muted,
        disabled && css.disabled,
        (props.onClick || props.onMouseEnter) && css.clickable,
        'icon',
        `icon__${type}`,
        ICON_LTR_MIRRORED_LIST.includes(type) && css.mirrored,
        className,
      )}
      ref={innerRef}
      {...props}
    />
  );

  return trackingName ? (
    <AddBabciaUBTracking trackingName={trackingName}>
      {content}
    </AddBabciaUBTracking>
  ) : (
    content
  );
};

export default Icon;
