/**
 * Disable Apollo inMemoryCache persist for PP reloading
 */
export const PAY_CACHE_PERSIST_DISABLED = 'pub_payCachePersistDisabled';

/**
 * Payment template testing cookie
 */
export const PAY_TEMPLATE = 'pub_payTemplate';

/**
 * Payment remarketingOffer template testing cookie
 */
export const PAY_REMARKETING_OFFER_TEMPLATE = 'pub_payRemarketingOfferTemplate';

/**
 * React payment template testing cookie
 */
export const PAY_MOTIVATION_TEMPLATE = 'pub_payMotivationTemplate';

/**
 * Increased font size for the elderly
 */
export const ADULT_BIG_FONT_SIZE = 'pub_adultBigFontSize';
