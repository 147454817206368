import includes from 'lodash/includes';

import ACTIONS_WITH_DESCRIPTOR from '../constants/actionsWithDescriptor';

/**
 * @param {String} action (@see paymentActions.js)
 * @return {Boolean}
 */
const allowedDescriptorForAction = (action) => {
  return includes(ACTIONS_WITH_DESCRIPTOR, action);
};

export default allowedDescriptorForAction;
