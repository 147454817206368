const getRelativeParent = (element: HTMLElement): HTMLElement => {
  let current: HTMLElement | null = element.parentElement;

  while (current && current !== document.documentElement) {
    const {position} = window.getComputedStyle(current);

    if (['relative', 'absolute', 'fixed', 'sticky'].includes(position)) {
      return current;
    }

    current = current.parentElement;
  }

  return document.documentElement;
};

export default getRelativeParent;
