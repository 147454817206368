import isNil from 'lodash/isNil';

import canOpenMiniMessenger from './canOpenMiniMessenger';
import isMiniMessengerAllowed from './isMiniMessengerAllowed';
import isMiniMessengerOpened from './isMiniMessengerOpened';

export default class MessengerAutoStarter {
  autoStarAvailable = null;

  needGTMTrack = false;

  constructor(needGTMTrack) {
    this.needGTMTrack = needGTMTrack;
  }

  /**
   * Open messenger only if we didn't have conversation with this user
   * Open mini messenger only once in session
   * @param {String} recipientId - recipient model
   * @param {Function} openMessengerWith
   */
  async tryStart(recipientId, openMessengerWith) {
    const available = await this.isAvailable();
    if (available) {
      const isMiniMessengerVisible = isMiniMessengerOpened();

      if (!isMiniMessengerVisible) {
        const canOpen = await canOpenMiniMessenger();
        if (canOpen) {
          this.autoStarAvailable = false;

          openMessengerWith({
            userId: recipientId,
            needGTMTrack: this.needGTMTrack,
          });
        }
      }
    }
  }

  /**
   * Allowed only for mini messenger
   * @return {any}
   */
  isAvailable() {
    return isNil(this.autoStarAvailable)
      ? isMiniMessengerAllowed().then((allowed) => {
          this.autoStarAvailable = allowed;
          return this.autoStarAvailable;
        })
      : Promise.resolve(this.autoStarAvailable);
  }
}
