import React, {useCallback, useEffect, useMemo, createRef, useRef} from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

import useSiteId from '@core/application/utils/useSiteId';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import scrollIntoView from '@core/utils/scroll/scrollIntoView';
import useWalletAllowedMethods from '@core/payment/common/utils/useWalletAllowedMethods';

import getPriceWithCurrency from '../../../common/utils/getPriceWithCurrency';
import selectedAdditionalPackagesVar from '../../package/graphql/vars/selectedAdditionalPackagesVar';
import useActivePackage from '../../package/utils/useActivePackage';
import useSelectedAdditionalPackages from '../../package/utils/useSelectedAdditionalPackages';
import usePaymentAdditionalPackage from '../utils/usePaymentAdditionalPackage';
/**
 * Component for render checkbox with additional package description
 */
const PaymentAdditionalPackage = ({
  danger = false,
  spacingSize,
  layout: Layout,
  normalizeText,
  usePaymentButtonTitle,
  paymentButtonTitle: paymentButtonTitleFromProps,
  multiSelect = true,
  onPageBackground,
  isXSaleTerms = false,
  muted = true,
}) => {
  const isPhone = usePhoneBreakpoint();
  const {activePackage} = useActivePackage();
  const {additionalPackages} = usePaymentAdditionalPackage();
  const {methods} = useWalletAllowedMethods();
  const {siteId} = useSiteId();
  const paymentButtonTitleFromServer = usePaymentButtonTitle({
    walletMethods: methods,
    skip: Boolean(paymentButtonTitleFromProps),
  });
  const paymentButtonTitle =
    paymentButtonTitleFromProps || paymentButtonTitleFromServer;

  const {packageList, setPackage} = useSelectedAdditionalPackages();

  /**
   * Flag for check if is already init additional packages.
   */
  const isInitAdditionalPackagesRef = useRef(false);

  // Footer text data for additional package
  const packageDataList = useMemo(() => {
    if (!activePackage) {
      return null;
    }

    return compact(
      activePackage.additionalPackageList
        // Filter out activation fee
        .filter(({withCheckbox}) => withCheckbox !== false)
        .map((packageItem, index) => {
          const packageWithText = find(
            additionalPackages,
            {
              packageId: packageItem.packageId,
            },
            multiSelect ? 0 : index,
          );

          if (packageWithText && packageWithText.translateMessage) {
            const {dayInterval, externalDomain, repeatPrice, targetSiteId} =
              packageItem;

            const {currency, formatted} = repeatPrice || {};
            const amount = formatted?.shortPrice || formatted?.amount;

            const price = getPriceWithCurrency(amount, {
              suffix: currency?.suffix,
              prefix: currency?.prefix,
            });

            const normalizedText = normalizeText(
              packageWithText.translateMessage,
              {
                footerPayButtonText: paymentButtonTitle,
                dayInterval,
                externalDomain,
                currency: currency && (currency.prefix || currency.suffix),
                amount,
                siteId,
                placeholders: {
                  '{PLACEHOLDER_PRICE}': price,
                },
                isXSaleTerms,
              },
            );

            if (isNull(packageList)) {
              return null;
            }

            const inPackageList = packageList.includes(
              packageItem.additionalPackageType,
            );

            const isCheckedAsCheckbox = packageItem.isXsaleCheckBoxLogicInverted
              ? !inPackageList
              : inPackageList;
            const isCheckedAsRadio = packageList.includes(
              packageItem.additionalPackageType,
            );

            return {
              externalDomain,
              siteId: targetSiteId,
              isChecked: multiSelect ? isCheckedAsCheckbox : isCheckedAsRadio,
              additionalPackageText: normalizedText,
              price,
              additionalPackageType: packageItem.additionalPackageType,
              ref: createRef(),
            };
          }

          return null;
        }),
    );
  }, [
    activePackage,
    additionalPackages,
    normalizeText,
    paymentButtonTitle,
    packageList,
    multiSelect,
    siteId,
    isXSaleTerms,
  ]);

  const handleCheckboxChange = useCallback(
    ({checked, additionalPackageType, ref}) => {
      if (!multiSelect) {
        selectedAdditionalPackagesVar(
          additionalPackageType ? [additionalPackageType] : [],
        );
      } else {
        const {isXsaleCheckBoxLogicInverted} =
          find(activePackage.additionalPackageList, {
            additionalPackageType,
          }) || {};

        setPackage(
          additionalPackageType,
          isXsaleCheckBoxLogicInverted ? !checked : checked,
        );
      }

      if (!isPhone && ref.current) {
        scrollIntoView({currentElem: ref.current});
      }
    },
    [activePackage, isPhone, multiSelect, setPackage],
  );

  // Set once default additional package checkbox state when component is init
  useEffect(() => {
    if (
      !isInitAdditionalPackagesRef.current &&
      activePackage &&
      isNull(packageList)
    ) {
      const {additionalPackageList} = activePackage;
      let selectedPackages;

      if (multiSelect) {
        selectedPackages = additionalPackageList
          .filter((packageItem) =>
            packageItem.isXsaleCheckBoxLogicInverted
              ? !packageItem.isCheckedByDefault
              : packageItem.isCheckedByDefault,
          )
          .map((packageItem) => packageItem.additionalPackageType);
      } else {
        /**
         * For oneStepClear/oneStepClearFeatures should always select the first package.
         * TODO should move this logic to server after https://jira.togethernetworks.com/browse/BU-187225
         * At the moment the backend is unable to preset a specific package due to spike
         */
        selectedPackages =
          additionalPackageList?.length &&
          additionalPackageList[0].isCheckedByDefault
            ? [additionalPackageList[0].additionalPackageType]
            : [];
      }

      selectedAdditionalPackagesVar(selectedPackages);
      /**
       * This flag need to prevent setting additional packages after state reset after successful payment.
       * The state is reset, but the component is not unmounted and reacts to packageList changes.
       * @see resetSelectedAdditionalPackages.js
       */
      isInitAdditionalPackagesRef.current = true;
    }
  }, [activePackage, multiSelect, packageList]);

  if (isEmpty(packageDataList)) {
    return null;
  }

  return (
    <Layout
      danger={danger}
      muted={muted}
      handleCheckboxChange={handleCheckboxChange}
      onPageBackground={onPageBackground}
      spacingSize={spacingSize}
      isPhone={isPhone}
      packageDataList={packageDataList}
    />
  );
};

PaymentAdditionalPackage.propTypes /* remove-proptypes */ = {
  danger: PropTypes.bool,
  spacingSize: PropTypes.objectOf(PropTypes.any),
  layout: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.func,
  ]).isRequired,
  normalizeText: PropTypes.func,
  usePaymentButtonTitle: PropTypes.func.isRequired,
  paymentButtonTitle: PropTypes.string,
  multiSelect: PropTypes.bool,
  muted: PropTypes.bool,
  onPageBackground: PropTypes.bool,
  isXSaleTerms: PropTypes.bool,
};

export default PaymentAdditionalPackage;
