import PAYMENT_ACTIONS from '../constants/paymentActions';
import usePaymentParams from './usePaymentParams';

const ACTIONS_MAPPER = {
  [PAYMENT_ACTIONS.PACKAGE]: PAYMENT_ACTIONS.MEMBERSHIP,
  [PAYMENT_ACTIONS.IDEAL]: PAYMENT_ACTIONS.MEMBERSHIP,
  [PAYMENT_ACTIONS.TRUST_PAY]: PAYMENT_ACTIONS.MEMBERSHIP,
  [PAYMENT_ACTIONS.SEPA]: PAYMENT_ACTIONS.MEMBERSHIP,
};

/**
 * By default, we use 'action' payment parameter from current url,
 * but not all url actions are similar to correct payment 'action'.
 * @return {String}
 */
const useTemplateAction = () => {
  const {action} = usePaymentParams();

  return ACTIONS_MAPPER[action] || action;
};

export default useTemplateAction;
