import {MSG} from '../constants/socketEventNames';

const fromServer = ({isAvailable, period, bannerType}) => ({
  data: {
    freeMessageNotification: {
      isAvailable,
      period,
      bannerType,
      __typename: 'FreeMessageNotification',
    },
  },
});

export default {
  FreeMessageNotifSubscription: {
    incomingEvents: [`${MSG}freeMessageNotif`],
    fromServer,
  },
};
