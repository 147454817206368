import URI from '@core/utils/url';

import {PRESET} from '../../photo/constants';
import PreviewPresetSize from '../constants/previewPresetSize';

const createVideoPreviewUrl = (url: string, preset: PRESET) => {
  if (!preset || preset === PRESET.NONE) {
    return url;
  }

  return URI(url).query({size: PreviewPresetSize[preset]}).toString();
};

export default createVideoPreviewUrl;
