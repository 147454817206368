import {useMemo, useEffect} from 'react';

/**
 * Creates url for Blob media (photo, video)
 *
 * @param {Blob} blob - Photo/video in Blob format.
 * @returns {String} Url for img/video tags
 */
export default function useBlobUrl(blob) {
  const url = useMemo(() => window.URL.createObjectURL(blob), [blob]);
  useEffect(() => () => window.URL.revokeObjectURL(url), [url]);
  return url;
}
