import type {FC} from 'react';
import React from 'react';

import type {HeadingProps} from './Heading';
import Heading, {HeadingAlign, HeadingLevel} from './Heading';

const H2: FC<Omit<HeadingProps, 'level'>> & {ALIGN: typeof HeadingAlign} = (
  props,
) => <Heading {...props} level={HeadingLevel.H2} />;

H2.ALIGN = HeadingAlign;

export default H2;
