import {createContext} from 'react';

/**
 * @type {{children: Node, options: Node, open(): Function, remove(): Function, isHidden: boolean}}
 */
export const defaultContext = {
  /**
   * Tears off / draws a toolbar
   * @type {function}
   */
  open() {},

  /**
   * Remove / delete a toolbar
   * @type {function}
   */
  remove() {},

  /**
   * Components that will be rendered in the sub toolbar region
   * @type {Node}
   */
  children: null,

  /**
   * Components options that will be rendered in the sub toolbar region
   * @type {Node}
   */
  options: {},

  /**
   * Hidden or not hidden element (for compatibility with Backbone.js app)
   * remove after moving to the react
   * @type {boolean}
   */
  isHidden: true,
};

export const ToolbarContext = createContext(defaultContext);

export const {Provider, Consumer} = ToolbarContext;
