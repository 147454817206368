import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';

import {getClientInstance} from '@core/graphql/client';

import WEB_SOCKET_STATUS_SUBSCRIPTION from '../graphql/subscriptions/interactionWebSocketConnectionStatus.gql';

let observable;

const CONNECT_TIMEOUT = 5000;

/**
 * Fires events when interactions reconnect after being frozen on
 * mobile devices.
 * 1. Freeze/Resume events are not supported by IOs.
 * 2. Android may drop connection under hidden status because of slowing
 *    down page.
 * So hidden/visible statuses are used.
 *
 * @returns {Observable}
 */
export default function getReconnectAfterResumeObservable() {
  if (!observable) {
    observable = new Observable((observer) => {
      let subscription = null;

      window.document.addEventListener('visibilitychange', () => {
        if (window.document.visibilityState === 'hidden') {
          if (subscription) {
            subscription.unsubscribe();
          }

          subscription = getClientInstance()
            .subscribe({
              query: WEB_SOCKET_STATUS_SUBSCRIPTION,
            })
            .subscribe(({data: {webSocketConnected}}) => {
              if (webSocketConnected) {
                observer.next();
              }
            });
        } else if (window.document.visibilityState === 'visible') {
          setTimeout(() => {
            if (subscription) {
              subscription.unsubscribe();
            }
          }, CONNECT_TIMEOUT);
        }
      });
    }).pipe(share());
  }

  return observable;
}
