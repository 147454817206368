export const DEFAULT = 'default';
export const PMA_REPLY = 'pmareply';
export const FLIRTCAST = 'flirtcast';
export const PROMO = 'promo';
export const SEX_ALARM = 'sex_alarm';
export const FLIRT_ALARM = 'flirt_alarm';

export const PMA_REPLY_ICON = 'bullet';
export const FLIRTCAST_ICON = 'bullet';
export const SEX_ALARM_ICON = 'bullet';
export const FLIRT_ALARM_ICON = 'bullet';
export const PROMO_ICON = 'bullet';
export const NOT_READ_ICON = 'check';
export const READ_ICON = 'check-double';
