import {MSG} from '../constants/socketEventNames';

export default {
  OpenCoinsConfirmationSubscription: {
    incomingEvents: [`${MSG}needConfirmEmail`],
    fromServer: () => ({
      data: {
        credits: {
          openConfirmPopup: true,
        },
      },
    }),
  },

  ShowExtraCoinsPopupSubscription: {
    incomingEvents: [`${MSG}showExtraCoinsPopup`],
    fromServer: () => ({
      data: {
        credits: {
          openExtraCoinsPopup: new Date().getTime(),
        },
      },
    }),
  },

  ShowSpecialOfferPopupSubscription: {
    incomingEvents: [`${MSG}spenderShowSpecialOffer`],
    fromServer: () => ({
      data: {
        credits: {
          openSpecialOfferPopup: {
            type: 'weekSpeak',
            timestamp: new Date().getTime(),
          },
        },
      },
    }),
  },

  ShowBuyCoinsMotivationBannerSubscription: {
    incomingEvents: [`${MSG}showBuyCoinsMotivationBanner`],
    fromServer: () => ({
      data: {
        showBuyCoinsMotivationBanner: true,
      },
    }),
  },
};
