import type {
  ReactElement,
  MouseEventHandler,
  MutableRefObject,
  FocusEvent,
  FocusEventHandler,
  TouchEventHandler,
  ComponentType,
  ReactNode,
} from 'react';
import React, {PureComponent, cloneElement, createRef, Children} from 'react';
import differenceWith from 'lodash/differenceWith';
import pickBy from 'lodash/pickBy';
import cn from 'classnames';

import logger from '@core/logger';
import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';
import isDeviceWithTouchScreen from '@core/utils/device/isDeviceWithTouchScreen';
import type {FormikFieldError} from '@core/types/formik';
import type {InputFrameProps} from '@core/ui/components/input/InputFrame';
import type {MenuComponent} from '@core/ui/components/menu/Menu';

import SelectNative from './Native';
import Triangle from '../triangle';
import Direction from '../../constants/Direction';
import PopperPlacement from '../../constants/PopperPlacement';
import css from './Select.css';
import InputBorder from '../../constants/InputBorder';
import type {NativeSelectValue} from './Native';
import PopperTrigger from '../../constants/PopperTrigger';

/**
 * Compare 2 arrays of components by specific prop key
 * @todo If this function still be used in other place - move it to @core/utils
 * @param {Array} arr1
 * @param {Array} arr2
 * @param {string} key - by what key comparsion will be persormed
 * @returns {boolean}
 */
const isEqualComponentsByPropsKey = (
  arr1: ReactElement[],
  arr2: ReactElement[],
  key: string,
): boolean => {
  // If arrays size isn't equal - they are not equal at all
  if (arr1.length !== arr2.length) {
    return false;
  }

  return !differenceWith(
    arr1,
    arr2,
    (comp1: ReactElement, comp2: ReactElement) => {
      return comp1.props[key] === comp2.props[key];
    },
  ).length;
};

/**
 * Scroll to active item in select
 * @todo If this function still be used in other place - move it to @core/utils
 * @param {Element} active
 * @param {Element} menu
 */
const scrollToActive = (active, menu) => {
  if (!active || !menu) {
    return;
  }

  const parent = menu.parentElement;
  const endOfVisibleArea = parent.clientHeight + parent.scrollTop;
  const elementPosition = active.clientHeight + active.offsetTop;

  if (endOfVisibleArea < elementPosition) {
    // If element is under visible area - scroll to the bottom of the element.
    parent.scrollTop = elementPosition - parent.clientHeight;
  } else if (parent.scrollTop > active.offsetTop) {
    // If element is over visible area - scroll to the top of element.
    parent.scrollTop = active.offsetTop;
  }
};

type Value = string | number;

export type SelectValue = Value | Value[];

export type SelectProps = {
  className?: string;
  children: ReactElement[];
  disabled?: boolean;
  name?: string;
  value?: SelectValue;
  label?: string;
  description?: string;
  error?: FormikFieldError;
  success?: string;
  wrapperClassName?: string;
  valueClassName?: string;
  trackingName?: string;
  inverse?: boolean;
  withBlurChangeTrigger?: boolean;
  border?: InputBorder;
  multiple?: boolean;
  nowrap?: boolean;
  onChange?: (
    event: FocusEvent<HTMLSelectElement> & {isDbChangeTrigger: boolean},
    value: SelectValue,
    name: string,
  ) => void;
  onFocus?: FocusEventHandler<HTMLSelectElement>;
  onBlur?: FocusEventHandler<HTMLSelectElement>;
  onTouchStart?: TouchEventHandler<HTMLSelectElement>;
  tabIndex?: number;
  /**
   * If fullWidth is false the width of the select menu more than the select label, because width of menu
   * depends on content width.
   * If fullWidth is true the width of the select menu is equal to width the select label
   */
  fullWidth?: boolean;
  usePortal?: boolean;
  hideByScroll?: boolean;
  useReferenceWidth?: boolean;
  showTriangle?: boolean;
  showPlaceholder?: boolean;
  showIcon?: boolean;
  /** Modifier used to prevent the popper from being positioned outside the boundary */
  boundariesRef?: MutableRefObject<string>;
  parseSelectedValue?: (value: NativeSelectValue) => Partial<NativeSelectValue>;
};

type BaseSelectProps = SelectProps & {
  frameComponent: ComponentType<InputFrameProps>;
  menuComponent: MenuComponent;
};

export type SelectState = {
  value: SelectValue | null;
  active: boolean | null;
  focused: boolean;
};

export default class Select<
  P extends BaseSelectProps,
  S extends SelectState,
> extends PureComponent<P, S> {
  static parseValueInChildren({children}: SelectProps): Value | null {
    // Assign active value inside state
    const active = children.filter(({props}) => props.active);

    return active.length ? active[0].props.value : null;
  }

  static defaultProps = {
    disabled: false,
    value: null,
    inverse: false,
    border: InputBorder.DEFAULT,
    tabIndex: 0,
    multiple: false,
    nowrap: false,
    fullWidth: true,
    usePortal: false,
    hideByScroll: false,
    useReferenceWidth: false,
    showTriangle: true,
    showPlaceholder: false,
    showIcon: false,
    parseSelectedValue: (value) => value,
  };

  native = createRef<HTMLSelectElement>();

  activeItem = createRef();

  menu = createRef<HTMLDivElement>();

  wrapperRef = createRef<HTMLDivElement>();

  popoverRef =
    createRef<
      MutableRefObject<{scrollableRef: MutableRefObject<HTMLElement>}>
    >();

  activeIndex: number;

  safeFocus: boolean;

  values: NativeSelectValue[] = [];

  disabledValues: SelectValue[] = [];

  hiddenValues: SelectValue[] = [];

  constructor(props) {
    super(props);

    /**
     * Here is this.constructor due to the need of calling their own constructors by this component and all the inheritors
     *
     * Also the props.value is ignored here and we get the value to set to the state from children
     * It needs for the component to work autonomously when there are no props passed from a parent
     * The start value can be empty string in cases when user must choose value by himself in the first time
     * @see SearchAdditionalLocationPopup
     */
    // @ts-expect-error not possible to add type to runtime constructor call in normal way
    const value = this.constructor.parseValueInChildren(props);

    // @ts-expect-error wired types error - marks this.state as ReadOnly in Pure component
    this.state = {
      value,
      active: false,
      focused: false,
    };

    this.initializeData();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    /**
     * If new content of select is passed from parent component
     * e.g. new set of items - we need to reinitialize all internal data and update
     * state.
     */
    if (
      !isEqualComponentsByPropsKey(
        this.props.children,
        prevProps.children,
        'value',
      )
    ) {
      this.initializeData();

      /**
       * Yes, I know about additional re-render of component.
       * But it can be performed only in ONE case - when children of select will change fully.
       * So, in most of cases this statement will not be called never during all component lifecycle
       */
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        // @ts-expect-error not possible to add type to runtime constructor call in normal way
        value: this.constructor.parseValueInChildren(this.props),
        focused: false,
      });
    }

    scrollToActive(this.activeItem.current, this.menu.current);
  }

  static getDerivedStateFromProps({value}, state) {
    // ability to use as a controlled component
    return value !== null && String(value) !== String(state.value)
      ? {value}
      : null;
  }

  getTrackingName = () => {
    const {name, trackingName = name, multiple, disabled} = this.props;

    if (!trackingName || disabled) {
      return null;
    }

    return `${trackingName}${multiple ? 'Multiple' : ''}Select`;
  };

  /**
   * @param {Object} e
   * Needed to prevent issue with data changing when we switched between Selects on IOS
   * @see usePreventIOSSelectError
   */
  preventIOSSelectError: FocusEventHandler<HTMLSelectElement> = (e) => {
    const {onChange} = this.props;
    const event = {...e, isDbChangeTrigger: true};

    onChange && onChange(event, event.target.value, event.target.name);
  };

  /**
   * @param {string|number} value
   * @returns {array|string}
   */
  getValue = (value: Value): Value | Value[] => value;

  /**
   * @param {string|number} value
   */
  handleChange = (value) => this.handleNativeChange({target: {value}});

  /**
   * @param {Object} event
   */
  handleNativeChange = (event) => {
    const {onChange, disabled, name} = this.props;

    if (disabled) return;

    const value = this.getValue(event.target.value);

    if (this.props.value === null) {
      // setState only if this component is uncontrolled
      this.setState({value}, () => {
        this.activeIndex = this.values.findIndex(
          (item) => item.value === value,
        );
      });
    }

    onChange && onChange(event, value, name);
  };

  /**
   * @param {boolean} active
   */
  handleActiveChange = (active: boolean) => {
    // There is a need to set 'focused' to true, because the select should lose its focus only after a blur event
    // But here it should stay focused
    // Also, after the custom select is activated, set the native one focused in order to be able to use a keyboard
    this.setState(
      {active, focused: true},
      () =>
        this.state.active && this.native.current && this.native.current.focus(),
    );
  };

  /**
   * @param {Object} event
   */
  handleFocus = (event) => {
    const {onFocus, disabled} = this.props;
    if (disabled) return;
    this.setState({focused: true});
    if (onFocus) onFocus(event);
  };

  /**
   * @param {Object} event
   */
  handleBlur = (event) => {
    if (this.safeFocus) {
      // After clicking on a tab, when the select is open, it loses focus
      this.native.current.focus();
      this.safeFocus = false;
      return;
    }

    const {onBlur, disabled, withBlurChangeTrigger} = this.props;

    if (disabled) return;
    this.setState({
      focused: false,
      active: false,
    });

    if (withBlurChangeTrigger) {
      this.preventIOSSelectError(event);
    }

    if (onBlur) onBlur(event);
  };

  handleKeyDown = (event) => {
    const isEnter = event.key === 'Enter';
    const isEscape = event.key === 'Escape';
    const isArrowDown = event.key === 'ArrowDown';
    const isArrowUp = event.key === 'ArrowUp';
    const isTab = event.key === 'Tab';

    if (isEnter || isEscape) {
      event.preventDefault();
    }

    if (isArrowDown && !this.props.multiple) {
      event.preventDefault();

      if (this.activeIndex < this.props.children.length - 1) {
        this.handleNativeChange({
          target: {
            value: this.getValue(this.values[++this.activeIndex]?.value),
          },
        });
      }
    }

    if (isArrowUp && !this.props.multiple) {
      event.preventDefault();

      if (this.activeIndex > 0) {
        this.handleNativeChange({
          target: {value: this.getValue(this.values[--this.activeIndex].value)},
        });
      }
    }

    // Toggle on Enter
    if (isEnter) {
      this.handleActiveChange(!this.state.active);
    }

    // Hide on Escape
    if (isEscape) {
      this.handleActiveChange(false);
    }

    if (isTab) {
      this.safeFocus = this.state.active;
      this.handleActiveChange(false);
    }
  };

  handleClick = (event) => {
    // If the event has happened in a nested popper there is no need to handle it here because it's the select's content
    if (event.nativeEvent.isNestedPopper) {
      return;
    }

    // Toggle on a click
    this.handleActiveChange(!this.state.active);
  };

  handleMouseDown: MouseEventHandler<HTMLDivElement> = (event) => {
    /**
     * In order to prevent a native select blur event (loss of focus) there is a need to do preventDefault
     * Because when the CUSTOM select or its content is clicked (moseDown), the NATIVE one will lose its focus
     *
     * Also there is a need to check whether the custom select is focused no not
     * in order to prevent the default behaviour only if the event is fired on the same select element
     *
     * You might ask: why 'focused' but not 'active'?
     * Checking 'active' prop instead of 'focused' doesn't fit because the select may have a focus when it's not active
     * and after a click it would lose it and get it at once again
     */
    if (this.state.focused) {
      event.preventDefault();
    }
  };

  updateValues = () => {
    // Create internal object of pairs 'value : markup'
    // is used to display correct value inside input
    this.values = [];
    this.props.children.forEach(({props: {value, children}}) => {
      this.values.push({
        name: children,
        value,
      });
    });
  };

  initializeData() {
    this.activeIndex = 0;
    // Used as data source for native select
    this.disabledValues = [];
    this.hiddenValues = [];

    this.props.children.forEach(
      ({props: {value, disabled, hidden, active}}, index) => {
        if (active) {
          this.activeIndex = index;
        }

        if (disabled) {
          this.disabledValues.push(value);
        }

        if (hidden) {
          this.hiddenValues.push(value);
        }
      },
    );
  }

  /**
   * @param {Object} data
   * @param {Boolean} data.inverse
   */
  renderMenuContent = ({inverse}) => {
    const {value} = this.state;
    const {children} = this.props;

    return Children.map(children, (child) => {
      const active = String(child.props.value) === String(value);

      return cloneElement(child, {
        active,
        inverse,
        ref: active ? this.activeItem : null,
      });
    });
  };

  renderValue(): ReactNode {
    const value = this.props.parseSelectedValue(
      this.values.find(
        (item) => String(item.value) === String(this.state.value),
      ),
    );
    const placeholder =
      this.props.showPlaceholder &&
      String(this.values[0].value) === String(this.state.value);

    /**
     * Sometimes available values change and selected value on backend remains.
     * We should track such situations and inform somebody for quick fix of this mistake.
     * !!Exception when the start value is empty string. Sometimes user must choose value by himself in the first time
     * @see SearchAdditionalLocationPopup
     */
    if (!value && this.state.value !== '') {
      logger.sendWarning(
        `[Select] Selected value "${this.state.value}" for select "${
          this.props.name
        }" is out of range of available values: "${JSON.stringify(
          this.values.map((entry) => entry.value),
        )}". Maybe backend returned wrong "active" value.`,
      );
    }

    return (
      <div
        className={cn(
          css.value,
          this.props.valueClassName,
          placeholder && css.placeholder,
        )}
        data-test="selectValue"
      >
        {value?.name ?? null}
      </div>
    );
  }

  renderContainer() {
    const {value, active} = this.state;
    const {
      inverse,
      showTriangle,
      wrapperClassName,
      onTouchStart,
      multiple,
      name,
    } = this.props;

    /**
     * Get all possible 'data-attribute' props.
     * Needed to testing purposes.
     */
    const dataProps = pickBy(this.props, (val, key) => key.startsWith('data-'));

    return (
      <div
        className={cn(wrapperClassName, css.container)}
        data-test="selectContainer"
      >
        <SelectNative
          name={name}
          disabled={this.props.disabled}
          selectedValue={value}
          disabledValues={this.disabledValues}
          hiddenValues={this.hiddenValues}
          values={this.values}
          className={cn(
            css.input,
            !multiple && isDeviceWithTouchScreen && css.clickable,
          )}
          onChange={this.handleNativeChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onTouchStart={onTouchStart}
          innerRef={this.native}
          tabIndex={this.props.tabIndex}
          multiple={this.props.multiple}
          {...dataProps}
        />
        {this.renderValue()}
        {showTriangle && (
          <Triangle
            direction={active ? Direction.TOP : Direction.BOTTOM}
            inverse={inverse}
          />
        )}
      </div>
    );
  }

  render() {
    const {
      name,
      value: propsValue,
      multiple,
      nowrap,
      fullWidth,
      hideByScroll,
      useReferenceWidth,
      usePortal,
      frameComponent: Frame,
      menuComponent: Menu,
      boundariesRef,
      showIcon,
      wrapperClassName,
      valueClassName,
      trackingName,
      withBlurChangeTrigger,
      onChange,
      onFocus,
      onBlur,
      onTouchStart,
      tabIndex,
      ...rest
    } = this.props;
    /**
     * Should update this.values before use it in renderValue() method
     */
    this.updateValues();

    const {active, value} = this.state;

    return (
      // @ts-expect-error typescript can not resolve type of props of passed component in Class component
      <Frame
        {...rest}
        focused={this.state.focused}
        animatedLabel={false}
        showIcon={
          showIcon ||
          (multiple && value && Array.isArray(value) && value.length === 1)
        }
        clickable={!isDeviceWithTouchScreen}
      >
        <AddBabciaUBTracking trackingName={this.getTrackingName()}>
          {!multiple && isDeviceWithTouchScreen ? (
            /**
             * If touch events are available in browser we don't
             * need to show custom dropdown, because default selects
             * for these devices are and looks better.
             */
            this.renderContainer()
          ) : (
            // eslint-disable-next-line local-rules/tracking-wrapper
            <div
              onKeyDown={this.handleKeyDown}
              onClick={this.handleClick}
              onMouseDown={this.handleMouseDown}
              tabIndex={-1}
              role="button"
              data-test="selectWrapper"
              ref={this.wrapperRef}
            >
              {/* @ts-expect-error typescript can not resolve type of props of passed component in Class component */}
              <Menu
                content={this.renderMenuContent}
                placement={PopperPlacement.BOTTOM}
                showArrow={false}
                positionFixed={false}
                usePortal={usePortal}
                fullWidth={fullWidth}
                hideByScroll={hideByScroll}
                fixedHeight
                beyondBorders
                useReferenceWidth={useReferenceWidth}
                onChange={this.handleChange}
                onActiveChange={this.handleActiveChange}
                closeByInsideClick={!multiple}
                boundariesRef={boundariesRef}
                active={active}
                nowrap={nowrap}
                trigger={PopperTrigger.NONE}
                ref={this.menu}
                // @see MultipleSelect.js
                popoverRef={this.popoverRef}
                parentRef={this.wrapperRef}
                className={css.menu}
              >
                {this.renderContainer()}
              </Menu>
            </div>
          )}
        </AddBabciaUBTracking>
      </Frame>
    );
  }
}
