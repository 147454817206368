export default {
  AIAssistantGenerateBioMutation: {
    toServer: (params) => ({method: 'bioGenerationV2', params}),

    fromServer: (response, error) => ({
      data: {
        userFeatures: {
          bioGenerationV2: error
            ? null
            : {
                // Normalize reason name
                reason:
                  response.status === 'limitReached'
                    ? 'LIMIT_REACHED'
                    : response.status,
                limit: response.limit,
                left: response.left,
                historyLen: response.historyLen,
                variant: response.variant,
                __typename: 'BioGenerationV2',
              },
          __typename: 'UserFeatures',
        },
      },
    }),
  },
};
