import {mergeArrayFieldUniq as merge} from '../utils/mergeArrayField';

export default {
  Favorites: {
    keyFields: [],
    fields: {
      users: {
        keyArgs: false,
        merge,
      },
    },
  },
};
