import last from 'lodash/last';

import URI from '@core/utils/url';

const PHOTO_BASE_LINK = '/photo/show';
const DELIMITER = '/';

/**
 * @param {String} hash
 * @return {Object} Decoded data
 */
const getParamsFromHash = (hash: string): Record<string, any> => {
  if (!hash) return {};
  return JSON.parse(atob(decodeURIComponent(hash)));
};

/**
 * Helper for parsing photo params from URL string.
 *
 * Used only inside old Backbone appliction. After removing of old app
 * can be removed.
 *
 * @param {string} url
 * @returns {Object}
 */
const getPhotoUrlParams = (
  url: string,
): {
  id?: string;
  [key: string]: any;
} => {
  const uri = URI(url);
  const pathParams = uri
    .path()
    .replace(PHOTO_BASE_LINK + DELIMITER, '')
    .split(DELIMITER);
  const queryResult = uri.query(true) as Record<string, any>;
  let hash = queryResult?.hash;
  if (hash) {
    return {
      id: pathParams[1],
      ...getParamsFromHash(hash),
    };
  }

  const str: string = last(pathParams);

  const match = str.match(/(.+)(\.\w+)$/);
  if (match) {
    [, hash] = match;
  }

  return getParamsFromHash(hash);
};

export default getPhotoUrlParams;
