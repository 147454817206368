import omit from 'lodash/omit';
import isString from 'lodash/isString';

import googlePayMaskedCardVar from '@core/graphql/vars/googlePayMaskedCardVar';
import type {PaymentProcessStepData} from '@core/payment/payProcess/types/paymentProcess';

import type {WALLET_METHODS} from '../../../common/constants/paymentMethods';
import {openGooglePayCvvForm} from '../../../forms/googlePay/utils/useIsGooglePayCvvForm';
import {TEST_CARD} from '../../constants/testPayLogic';
import type {PaymentDataResponse} from '../../types/paymentFlow';
import checkTestWalletPayLogic from '../../utils/checkTestWalletPayLogic';
import getPayMethodWithBackboneModel from '../../utils/getPayMethodWithBackboneModel';
import {PaymentFlowStatus} from '../../utils/getProcessPaymentFlow';
import getFormName from '../../utils/getFormName';
import payByMethod from '../../utils/payByMethod';
import {addPaymentDataInner} from './addPaymentData';

const TEST_TOKEN = 'testWalletToken';

export type RealPay = (
  paymentData: PaymentProcessStepData,
) => Promise<PaymentProcessStepData>;

/**
 * Test payment for wallet methods
 * @deprecated Use testWalletPay instead
 */
const testWalletPayLegacy =
  (method: string) =>
  async (data: PaymentProcessStepData): Promise<PaymentProcessStepData> => {
    if (checkTestWalletPayLogic(method, true) && !googlePayMaskedCardVar()) {
      openGooglePayCvvForm(TEST_CARD);

      return {...data, flowStatus: PaymentFlowStatus.ABORTED};
    }

    const {paymentData} = data;
    const {
      action,
      via,
      prevVia,
      activePackage: {stockId, packageId, tokenPrice: {currencyCode} = {}},
      country,
      siteName,
      urlParams,
      ...props
    } = paymentData;

    const pay = getPayMethodWithBackboneModel({
      method,
      action,
      // @ts-expect-error --> Ignore this legacy code
      formName: getFormName({method}),
    });

    let result;

    const previousVia = prevVia || via;

    try {
      result = await pay({
        stockId,
        packageId,
        country,
        currency_code: currencyCode,
        domain: siteName,
        prevVia: previousVia,
        hidePaymentForm: 0,
        walletToken: TEST_TOKEN,
        ...omit(props, 'settings'),
      });
    } catch (error) {
      return error;
    }

    return {
      ...data,
      paymentData: {...paymentData, prevVia: previousVia, method},
      paymentResult: {
        urlParams,
        ...result,
      },
    };
  };

/**
 * Test payment for wallet methods
 */
const testWalletPay =
  (method: WALLET_METHODS) =>
  async (data: PaymentProcessStepData): Promise<PaymentProcessStepData> => {
    if (checkTestWalletPayLogic(method, true) && !googlePayMaskedCardVar()) {
      openGooglePayCvvForm(TEST_CARD);

      return {...data, flowStatus: PaymentFlowStatus.ABORTED};
    }

    const {paymentData} = data;
    const {action, via, prevVia, activePackage, siteName, ...props} =
      paymentData;

    let result: string | PaymentDataResponse;

    /**
     * @TODO: Think about add 'addPaymentData' method to 'getGooglePayFlow' util,
     *        after removing Backbone.
     */
    const addPaymentDataResult = await addPaymentDataInner({
      action,
      activePackage,
      altMethodsSettings: props.settings,
      method,
      prevVia,
      via,
    });

    try {
      result = await payByMethod({
        ...addPaymentDataResult,
        ...omit(props, 'settings'),
        // TODO[BB_removed]: Think about move it in 'addPaymentData' util, if need for all payment methods
        currencyCode: activePackage.tokenPrice?.currencyCode,
        domain: siteName,
        hidePaymentForm: 0,
        method,
        walletToken: TEST_TOKEN,
      });
    } catch (error) {
      return error;
    }

    if (typeof result === 'string') {
      return {...data, flowStatus: result as PaymentFlowStatus};
    }

    return {
      ...data,
      paymentData: {...paymentData, method},
      paymentResult: result,
    };
  };

/**
 * Enhance payment result with some paymentData credentials (via, etc...)
 */
const modifyPaymentResult =
  (realPay: RealPay) =>
  async (data: PaymentProcessStepData): Promise<PaymentProcessStepData> => {
    const result = await realPay(data);

    const {paymentData} = result;
    const {prevVia, via} = paymentData;

    /**
     * Skip result modification if flow message returned as result
     */
    if (isString(result.flowStatus)) {
      return result;
    }

    return {
      ...result,
      paymentData: {
        ...paymentData,
        prevVia: prevVia || via,
      },
    };
  };

/**
 * Return pay function for wallet method
 * @see getApplePayFlow
 */
const getWalletPay = (
  walletMethod: WALLET_METHODS,
  realPay: RealPay,
  isFetchApi: boolean,
): ((data: PaymentProcessStepData) => Promise<PaymentProcessStepData>) => {
  if (checkTestWalletPayLogic(walletMethod)) {
    return isFetchApi
      ? testWalletPay(walletMethod)
      : testWalletPayLegacy(walletMethod);
  }

  return modifyPaymentResult(realPay);
};

export default getWalletPay;
