import {MSG} from '../constants/socketEventNames';

export default {
  AmountPartGroupVideoSubscription: {
    incomingEvents: [`${MSG}amountPartGroupVideo`],

    fromServer: ({group, count}) => ({
      data: {
        amountPartVideoGroup: {
          group,
          count,
          __typename: 'amountPartGroupVideo',
        },
      },
    }),
  },
};
