/* global Backbone */
import type {ReactNode} from 'react';
import React, {Component} from 'react';
import isArray from 'lodash/isArray';
import isUndefined from 'lodash/isUndefined';
import isEqual from 'lodash/isEqual';
import defaults from 'lodash/defaults';
import last from 'lodash/last';
import omit from 'lodash/omit';
import remove from 'lodash/remove';

import RenderBackbone from '@core/utils/backbone/RenderBackbone';
import generateUniqueId from '@core/utils/id/generateUniqueId';
import isPopupOpenAndAnimatedVar from '@core/graphql/vars/isPopupOpenAndAnimatedVar';
import isPopupOpenVar from '@core/graphql/vars/isPopupOpenVar';

import PopupPriority from '../constants/PopupPriority';
import PopupSourceEvent from '../constants/PopupSourceEvent';
import POPUP_STATE_CHANGE_EVENT from '../constants/popupStateChangeEvent';
import PopupService from '../utils/PopupService';
import {Provider} from './PopupContext';
import LegacyPopupProxyView from './LegacyPopupProxyView';
import popupQueue from './popupQueue';
import type {PopupOptions} from '../types/PopupOptions';
import type PopupData from '../types/PopupData';
import type BackboneView from '../types/BackboneView';
import type PopupComponent from '../types/PopupComponent';

const ESCAPE_KEY_CODE = 27;

type GlobalPopup = {
  closePopup: () => void;
  isOpen: () => boolean;
  open: (view: PopupComponent) => void;
  setTitle: (title: string) => void;
  setPopupProps: (props: PopupOptions) => void;
  openBackboneView: (view: BackboneView) => void;
  off: () => void;
  [key: string]: any;
} | null;

class PopupProvider extends Component<{children: ReactNode}> {
  static broadcastEvent = ({
    hasQueue,
    opened,
    closeEvent,
  }: {
    hasQueue: boolean;
    opened: boolean;
    closeEvent?: string;
  }) =>
    document.dispatchEvent(
      new CustomEvent(POPUP_STATE_CHANGE_EVENT, {
        detail: {hasQueue, opened, closeEvent},
      }),
    );

  globalPopup: GlobalPopup = null;

  scrollableElement: HTMLElement | null = null;

  constructor(props: {children: ReactNode}) {
    super(props);

    /**
     * PopupService - singleton class instance, that provides popup functionality for all application.
     * @see PopupService.js
     */
    PopupService.setProxy({
      close: this.close,
      open: this.open,
      setPopupProps: this.setPopupProps,
      openBackboneView: this.openBackboneView,
    });
  }

  componentDidMount() {
    /**
     * For Backbone.js application only
     * off method is needed because of error in RenderBackbone willUnmount method.
     * For some reasons when you navigate to one of the pages, like chat or profile during removing all elements
     * in loop it founds object window.app.popup, and during running stopListening method of Backbone,
     * it tries to call off method on window.app.popup, that it doesn't have.
     * Should be removed when RenderBackbone will not be used.
     * Use for wrap Backbone.Events for legacy popup
     */
    function LegacyPopupApiWrapper() {}

    LegacyPopupApiWrapper.prototype = {
      // @ts-expect-error TODO: remove after Backbone will be removed
      ...Backbone.Events,
      closePopup: this.close,
      isOpen: this.isOpen,
      open: this.openBackboneView,
      setTitle: this.setTitle,
      setPopupProps: this.setPopupProps,
      openBackboneView: this.openBackboneView,
      off: () => {},
    };

    this.globalPopup = new LegacyPopupApiWrapper();

    window.app.popup = this.globalPopup;
  }

  componentDidUpdate() {
    this.onUpdate(popupQueue.length > 0);
  }

  componentWillUnmount() {
    this.onUpdate(false);
  }

  setPopupProps = (props: PopupOptions) => {
    if (!popupQueue.length) return;

    const options = this.getOptions();
    Object.assign(options, props);
    this.forceUpdate();
  };

  setTitle = (title: string) => this.setPopupProps({title});

  getPopupScrollPosition = () => this.scrollableElement?.scrollTop || 0;

  saveScrollableElement = (scrollableElement: HTMLElement) => {
    this.scrollableElement = scrollableElement;
  };

  openBackboneView = (View: BackboneView, options: PopupOptions = {}) => {
    /**
     * Set the same options like in opened popup
     * Without this additional options when compare on isPopupEqual always return false
     */
    const popupOptions = {
      showCloseButton: true,
      disabledCloseButton: false,
      canCloseByEscButton: false,
      ...options,
      ...this.applyPopupCSSModifiers(options.cssModifiers),
    };

    /**
     * In backbone project we've cases when 2 popups open at the same time.
     * Until we have in our project window.app.popup, we should not render popups with the same views and options
     *
     * Pay attention on places where are called window.app.antiscamPhotoPopup.processError
     */
    if (this.isOpen()) {
      const popup = last(popupQueue);
      if (popup.backboneView) {
        const isPopupEqual =
          View === popup.backboneView &&
          isEqual(omit(popup.options, 'model'), omit(popupOptions, 'model'));
        if (isPopupEqual) {
          return;
        }
      }
    }
    // eslint-disable-next-line
    return this.prepareBackbonePopup(View, popupOptions);
  };

  /**
   * Return backbone View proxy to listen events
   */
  prepareBackbonePopup = (View: BackboneView, options: PopupOptions) => {
    const legacyPopupProxyView = new LegacyPopupProxyView();
    this.open(
      () => (
        <RenderBackbone
          view={View}
          // eslint-disable-next-line
          onViewUpdate={legacyPopupProxyView.setPopupView}
          options={{
            ...options,
            popup: this.globalPopup,
          }}
        />
      ),
      {
        ...options,
      },
      View,
    );
    return legacyPopupProxyView;
  };

  open = (
    children: PopupComponent,
    options: PopupOptions = {},
    backboneView = null,
  ) => {
    const popupOptions = {
      showCloseButton: true,
      disabledCloseButton: false,
      closeByNavigation: true,
      canCloseByEscButton: false,
      key: generateUniqueId(),
      ...defaults(options, {priority: PopupPriority.HIGH}),
      ...this.applyPopupCSSModifiers(options.cssModifiers),
    };

    const popupData: PopupData = {
      children,
      options: popupOptions,
      backboneView,
    };
    const prevPopup = last(popupQueue);

    // No open the same popup
    if (
      prevPopup &&
      isEqual(omit(prevPopup, 'options.key'), omit(popupData, 'options.key'))
    ) {
      return;
    }

    const replacePreviousPopup =
      prevPopup &&
      popupOptions.groupKey &&
      prevPopup.options.groupKey === popupOptions.groupKey;

    /**
     * Check if previous popup is LOW priority, or former not exist (it assumes that show it because queue is empty)
     */
    const prevPopupIsLow: boolean =
      prevPopup?.options?.priority === PopupPriority.LOW ||
      isUndefined(prevPopup?.options?.priority);

    /**
     * Broadcast for HIGH priority, popup is middle priority and previous is low,
     * if popup with low or middle priority we trigger event only for empty queue
     */
    const broadcast: boolean =
      popupOptions.priority === PopupPriority.HIGH ||
      ![PopupPriority.LOW, PopupPriority.MIDDLE].includes(
        popupOptions.priority,
      ) ||
      (popupOptions.priority === PopupPriority.MIDDLE && prevPopupIsLow);

    if (broadcast) {
      PopupProvider.broadcastEvent({
        hasQueue: true,
        opened: true,
      });
    }

    const scrollPosition = this.getPopupScrollPosition();

    if (scrollPosition) {
      prevPopup && (prevPopup.options.scrollPosition = scrollPosition);
    }

    /**
     * LOW indicates that the popup has a low priority and should be added
     * to the end of the popup queue
     */
    if (popupOptions.priority === PopupPriority.LOW) {
      if (replacePreviousPopup) {
        popupQueue.pop();
      }

      popupQueue.unshift(popupData);
    }

    if (popupOptions.priority === PopupPriority.MIDDLE) {
      if (replacePreviousPopup) {
        popupQueue.pop();
      }

      /**
       * If popup is middle priority and former popup is low, it will be added
       * to the start of the popup queue
       */
      if (prevPopupIsLow) {
        popupQueue.push(popupData);
      } else {
        const position = popupQueue.findIndex(
          (popup) => popup.options.priority === PopupPriority.HIGH,
        );

        /**
         * In other case we check if queue has high priority popups and adds new popup after them,
         * or before other middle, or low, if queue doesn't have any middle
         */
        if (position === -1) {
          popupQueue.push(popupData);
        } else {
          popupQueue.splice(position, 0, popupData);
        }
      }
    }

    /**
     * HIGH indicates that the popup has a high priority and should be added
     * to the start of the popup queue
     */
    if (popupOptions.priority === PopupPriority.HIGH) {
      if (replacePreviousPopup) {
        popupQueue.pop();
      }

      popupQueue.push(popupData);
    }

    /**
     * Need for legacy popup on backbone
     */
    this.globalPopup.trigger('open');

    this.forceUpdate();
  };

  applyPopupCSSModifiers = (cssModifiers: string[]) => {
    const props = {};
    isArray(cssModifiers) &&
      cssModifiers.forEach((className) => {
        props[className] = true;
      });
    return props;
  };

  close = (
    removeQueue = false,
    sourceEventType: PopupSourceEvent = PopupSourceEvent.EMPTY,
  ): GlobalPopup => {
    const {onCloseClick} = this.getOptions();

    /**
     * If 'onCloseClick' returns 'true' it means that we should
     * block any further actions. Used for situations, when we need to
     * overwrite default behavior
     */
    if (
      sourceEventType !== PopupSourceEvent.BY_ROUTING &&
      onCloseClick?.(sourceEventType)
    ) {
      return this.globalPopup;
    }

    if (this.isOpen()) {
      const prevActivePopup = last(popupQueue);

      const closedPopups = remove(
        popupQueue,
        ({options}, index, queue) =>
          (removeQueue || index === queue.length - 1) &&
          (options.closeByNavigation ||
            sourceEventType !== PopupSourceEvent.BY_ROUTING),
      );

      if (!closedPopups.length) {
        return this.globalPopup;
      }

      /**
       * Need reverse array to call close event in right order when we use forEach
       * First of all, we close the popup that was shown to the user last
       */
      closedPopups.reverse();

      closedPopups.forEach(({options: {onClose}}) => {
        onClose && onClose(sourceEventType);
      });

      this.forceUpdate(() => {
        if (!this.isOpen()) {
          isPopupOpenVar(false);
          isPopupOpenAndAnimatedVar(false);
        }

        closedPopups.forEach((_, index) => {
          /**
           * Sending close event for each popup
           */
          PopupProvider.broadcastEvent({
            hasQueue: this.isOpen() || index !== closedPopups.length - 1,
            closeEvent: sourceEventType,
            opened: false,
          });
        });

        const activePopup = last(popupQueue);
        if (
          activePopup &&
          // Trigger event only if active popup changed
          activePopup !== prevActivePopup
        ) {
          PopupProvider.broadcastEvent({
            hasQueue: true,
            opened: true,
          });
        }
      });
    }

    this.globalPopup.trigger('close');

    return this.globalPopup;
  };

  /**
   * Close popup on ESC button press
   * @param keyCode
   */
  handleKeyUp = ({keyCode}): void => {
    if (!this.isOpen()) {
      return;
    }

    const options = this.getOptions();
    if (
      keyCode === ESCAPE_KEY_CODE &&
      (options.showCloseButton ||
        (!options.disabledCloseButton && options.canCloseByEscButton))
    ) {
      this.close(false, PopupSourceEvent.BY_ESCAPE);
    }
  };

  onUpdate = (isPopupOpen: boolean): void => {
    const prevIsPopupOpen = isPopupOpenVar();
    if (isPopupOpen && !prevIsPopupOpen) {
      document.addEventListener('keyup', this.handleKeyUp);
      isPopupOpenVar(true);
    } else if (!isPopupOpen && prevIsPopupOpen) {
      document.removeEventListener('keyup', this.handleKeyUp);
      /**
       * isPopupOpenVar(false) should be called in {@see close} method.
       */
    }
  };

  /**
   * Should be function until backbone popups are alive
   * @returns {boolean}
   */
  isOpen = () => popupQueue.length > 0;

  /**
   * Returns active popup options
   */
  getOptions = (): PopupOptions => {
    return this.isOpen() ? last(popupQueue).options : {};
  };

  render() {
    return (
      <Provider
        value={{
          queue: popupQueue.length,
          close: this.close,
          isOpen: this.isOpen,
          open: this.open,
          setTitle: this.setTitle,
          setPopupProps: this.setPopupProps,
          openBackboneView: this.openBackboneView,
          scrollableRefCallback: this.saveScrollableElement,
          list: popupQueue,
          /**
           * @deprecated
           */
          options: this.getOptions(),
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export default PopupProvider;
