import isNil from 'lodash/isNil';

import {getClientInstance} from '@core/graphql/client';

import MESSENGER_QUERY from '../graphql/queries/messenger.gql';
import updateRecipientMessagePermissions from './updateRecipientMessagePermissions';

/**
 * Update canSend permission for recipients
 * @param {Function} updater Function which return value is used for canSend property.
 * @param {boolean} onlyInCache Update permissions only for users from cache.
 * Sometimes needs to exclude the fetching data if user doesn't have a cache.
 * @see deleteMessages
 */
export default function updateRecipientsMessagePermissions(
  updater,
  onlyInCache = false,
) {
  const client = getClientInstance();
  client
    .readQuery({query: MESSENGER_QUERY})
    .messenger.initialData.recipients.forEach((recipient) => {
      const canSend = updater(recipient);
      if (!isNil(canSend)) {
        updateRecipientMessagePermissions({
          client,
          userId: recipient.id,
          canSendNext: canSend,
          onlyInCache,
        });
      }
    });
}
