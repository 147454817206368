/**
 * Upload sources. Used to display motivation/disapprove reasons/buttons.
 */

export const DEFAULT = 'default';
export const MESSENGER = 'messenger';
export const PERSONAL_IDENTITY = 'personalIdentity';
export const STORIES = 'stories';
export const FUNNEL = 'funnel';
export const FUNNEL_PMA_NEXT_STEP = 'funnelPmaNextStep';
export const PHOTO_RESTRICTIONS = 'photoRestrictions';
export const REQUIRED_PHOTO = 'requiredPhoto';
export const LIKE = 'like';
