enum AdminMessageType {
  WELCOME_LIST = 'welcomeDesign1',
  WELCOME_SLIDER = 'welcomeDesign2',
  PROMO_DISCOUNT = 'promoDiscount',
  VIRTUAL_ASSISTANT_WELCOME = 'virtualAssistantWelcome',
  PROMO_UPGRADE_MEMBERSHIP = 'promoUpgradeDesign1',
  PROMO_UPGRADE_MEMBERSHIP_PACKAGES = 'promoUpgradeDesign2',
  PROMO_UPGRADE_FEATURES = 'featureDesign1',
  PROMO_UPGRADE_FEATURES_PACKAGES = 'featureDesign2',
  PROMO_DISCOUNT_PERIODIC = 'promoDiscountPeriodic',
  PROMO_PHOTO_UPLOAD = 'photoUpload',
  PROMO_UNLIMITED_MESSAGE = 'adminPromoUnlimitedMessage',
  PROMO_CONFIRM_MOTIVATION = 'confirmMotivation',
  PROMO_ORDER_PROCESSED = 'payment',
  PROMO_FAILURE_MESSAGE = 'failure',
  PROMO_PHOTO_DISAPPROVE = 'disapprove',
  PROMO_PHOTO_DISAPPROVE_ACCORDION = 'disapproveShort',
  CHANGE_DISTANCE = 'changeDistance',
  EXTENDED_DISTANCE_FREE_TRIAL = 'extendedDistanceFreeTrial',
  CHANGE_LOCATION = 'changeLocation',
  EXTENDED_LOCATION_FREE_TRIAL = 'extendedLocationFreeTrial',
  RM_CROSS_IMB = 'rmCrossImb',
  EXTRA_COINS = 'extraCoins',
  PROMO_TASK_CENTER = 'promoTaskCenter',
  PROMO_TELEGRAM_BOT_INVITE = 'promoTelegramBotInvite',
}

export default AdminMessageType;
