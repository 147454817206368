import {getClientInstance} from '@core/graphql/client';
import logger from '@core/logger';

import RECIPIENT_FRAGMENT from '../graphql/fragments/recipient.gql';
import type {RecipientFragment} from '../graphql/fragments/recipient';

/**
 * Get recipient from cache by recipientId
 */
const getRecipientFromCache = (recipientId: string) => {
  const recipientFragment = {
    fragment: RECIPIENT_FRAGMENT,
    fragmentName: 'Recipient',
    id: `Recipient:${recipientId}`,
  };

  const recipient =
    getClientInstance().readFragment<RecipientFragment>(recipientFragment);
  if (!recipient) {
    logger.sendInfo(
      `[getRecipientFromCache] Fragment isn't found by recipient: ${recipientId}`,
    );
  }

  return recipient;
};

export default getRecipientFromCache;
