import showFailFormVar from '@core/graphql/vars/showFailFormVar';

import type {PaymentProcessStep} from '../../types/paymentProcess';

const addFailFormData: PaymentProcessStep = async (data) => {
  const {paymentResult, paymentData} = data;
  const {replaceRedirectToPostMessage} = paymentData;
  const {status, withPendingPopup} = paymentResult;

  // Don`t show decline message on iframe PP
  if (replaceRedirectToPostMessage) {
    return data;
  }

  if (status) {
    showFailFormVar(false);
  } else if (!withPendingPopup) {
    showFailFormVar(true);
  }

  return data;
};

export default addFailFormData;
