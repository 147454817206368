import {getClientInstance} from '@core/graphql/client';

import BLOCK_USER_HE_ME from '../../profile/target/graphql/fragments/blockUserHeMe.gql';
import type {BlockedHeMeFragment} from '../../profile/target/graphql/fragments/blockUserHeMe';

/**
 * Update blockUser heMe in Apollo cache
 */
const updateBlockUserHeMeInCache = (userId: string, isBlocking: boolean) => {
  getClientInstance().writeFragment<BlockedHeMeFragment>({
    id: `UserData:${userId}`,
    fragment: BLOCK_USER_HE_ME,
    data: {
      blockUser: {
        heMe: isBlocking,
        __typename: 'Blocked',
      },
      __typename: 'UserData',
    },
  });
};

export default updateBlockUserHeMeInCache;
