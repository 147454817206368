import {useState, useEffect, useCallback} from 'react';
import flatten from 'lodash/flatten';

import PopupService from '@core/popup/utils/PopupService';
import {IMAGES, VIDEOS, MIXED} from '@core/mediaUpload/constants/accept';
import {MESSENGER} from '@core/mediaUpload/constants/sources';
import UploadService from '@core/mediaUpload/utils/UploadService';
import t from '@core/translations/translate';

import {CLOSE_AFTER_SUCCESS} from './uploadFilesSource';

/**
 * Upload photos/videos hook
 *
 * @param {Object} props
 * @param {Object} props.settings - Upload settings. @see settingsPropType
 * @param {Object} props.uploadRenderer - Upload renderer. @see PhotoUploadPopup
 * @param {Function} props.openErrorsPopup - @see openErrorsPopup.js
 *
 * @returns {Object} {isUploading, files, setFiles, errors, clearErrors}
 */
export default function useUploadFilesMultiple({
  settings,
  uploadRenderer,
  openErrorsPopup,
  needCloseAfterSuccess = true,
}) {
  const [files, setFiles] = useState(settings.files);
  const [isUploading, setIsUploading] = useState(false);
  const [errors, setErrors] = useState([]);
  const clearErrors = useCallback(() => setErrors([]), []);

  useEffect(() => {
    let mounted = true;
    if (files.length) {
      clearErrors();
      setIsUploading(true);

      let photos = [];
      let videos = [];
      let invalidFormatFiles = [];
      switch (settings.accept) {
        case IMAGES:
          photos = files;
          break;
        case VIDEOS:
          videos = files;
          break;
        case MIXED:
        default:
          photos = files.filter((file) => file.type.startsWith('image'));
          videos = files.filter((file) => file.type.startsWith('video'));

          // Need to make validation if files have incorrect extensions
          invalidFormatFiles = files.filter(
            (file) =>
              !file.type.startsWith('image') && !file.type.startsWith('video'),
          );
          break;
      }

      const promises = [];

      if (photos.length) {
        promises.push(
          UploadService.uploadPhotosMultplie({files: photos, settings}).then(
            (data) => data,
          ),
        );
      }

      if (videos.length) {
        promises.push(
          Promise.all(
            videos.map((file) => {
              return UploadService.validateVideo({file, settings})
                .then(() => {
                  const promise = UploadService.uploadVideo({file, settings});
                  promise.then((result) => {
                    if (result.error && settings.source !== MESSENGER) {
                      if (mounted) {
                        setErrors((errs) => [...errs, result]);
                      } else {
                        openErrorsPopup({
                          errors: [result],
                          settings,
                          uploadRenderer,
                        });
                      }
                    }
                  });
                  /**
                   * Call onUpload prop to allow subscriptions to video upload promise.
                   * @see SenderActions for example.
                   */
                  settings.onUpload && settings.onUpload(promise);
                  return {file};
                })
                .catch((error) => ({file, error}));
            }),
          ).then((data) => data),
        );
      }

      if (invalidFormatFiles.length) {
        invalidFormatFiles.forEach((file) =>
          promises.push({
            file,
            error: new Error(
              `${t('uploadButtonMessages', 'text.upload_errors')}: ${
                file.name
              }`,
            ),
          }),
        );
      }

      Promise.all(promises).then((data) => {
        const flatted = flatten(data);
        const results = flatted.filter(({result}) => result);
        const errs = flatted.filter(({error}) => error);

        if (settings.onSuccess && results.length) {
          /**
           * Call onSuccess prop for uploaded photos.
           * @see SenderActions for example.
           */
          settings.onSuccess(results);
        }

        if (mounted) {
          setIsUploading(false);
          setErrors(errs);
          if (!errs.length && needCloseAfterSuccess) {
            PopupService.closePopup(false, CLOSE_AFTER_SUCCESS);
            /**
             * To handle video upload errors properly.
             * See UploadService.uploadVideo({file, settings}) above.
             * Useful for integration test.
             */
            mounted = false;
          }
        } else if (errs.length) {
          openErrorsPopup({errors: errs, settings, uploadRenderer});
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [
    files,
    clearErrors,
    settings,
    uploadRenderer,
    openErrorsPopup,
    needCloseAfterSuccess,
  ]);

  return {isUploading, files, setFiles, errors, clearErrors};
}
