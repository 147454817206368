import URI from './uri';

const STOCK_ID = 'stockId';

/**
 * @returns {boolean}
 */
const isPayUrlWithStockId = () => URI(window.location).hasSearch(STOCK_ID);

export default isPayUrlWithStockId;
