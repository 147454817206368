import {getClientInstance} from '@core/graphql/client';

import DELIVERY_MESSAGE_MUTATION from '../graphql/mutations/deliveryMessage.gql';
import type {
  DeliveryMessageMutation,
  DeliveryMessageMutationVariables,
} from '../graphql/mutations/deliveryMessage';

/**
 * Used for traditional sites. When approve photo message is adult in admin panel by the interaction mediaFilteredByContentLevel
 * showed popup @see src/packages/dating/messenger/common/containers/InappropriateContentSendPopup.js. Here we can delivery
 * approved photo message with adult content repeated and then recipient approved show adult photo always or remove forever.
 */
const deliveryMessage = (messageId: string) =>
  getClientInstance().mutate<
    DeliveryMessageMutation,
    DeliveryMessageMutationVariables
  >({
    mutation: DELIVERY_MESSAGE_MUTATION,
    variables: {
      messageId,
    },
  });

export default deliveryMessage;
