import {createBrowserHistory} from 'history';

import enhanceHistoryLength from './enhanceHistoryLength';
import handleRouteChange from './handleRouteChange';
import applyMiddleware from './historyMiddleware';

const history = createBrowserHistory();
enhanceHistoryLength(history);

if (window.top === window) {
  // Adds history hooks for advanced navigation control.
  applyMiddleware(history);
}
// else it's iframe, and we don't need the custom navigation logic.

// save initial route
handleRouteChange(history.location);

history.listen(handleRouteChange);

/**
 * Helper method, to return history as singleton.
 * Used for 'react' and 'backbone' routers together.
 *
 * NOTE: Unavailable for SSR! The whole file is excluded using `'null-loader'` in `webpack.config.babel.js`.
 *
 * @see createLegacyRouter.js, Router.js
 */
const getHistory = () => history;

export default getHistory;
