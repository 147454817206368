import t from '@core/translations/translate';
import logger from '@core/logger';

import truncate from './filenameTruncate';
import getRandomFileName from './getRandomFileName';

/**
 * Translates server errors to translated messages for photo errors
 *
 * @param {String} error - Error from server
 * @param {String|null} fileName - File name
 * @returns Translated message
 */
export default function getPhotoErrorMessage(
  error,
  fileName = getRandomFileName('jpg'),
) {
  const truncatedFileName = truncate(fileName);

  switch (error) {
    case 'document_has_uploaded':
      return t('uploadButtonMessages', 'document_has_uploaded', {
        '{fileName}': truncatedFileName,
      });
    case 'uploaded_by_another_user':
      return t('uploadButtonMessages', 'uploaded_by_another_user', {
        '{fileName}': truncatedFileName,
      });
    case "can't save photo":
      return t('uploadButtonMessages', 'error.cant_save_photo');
    // For upload of stories
    case 'error.stories_upload_limit_exceeded':
      return t('uploadButtonMessages', 'error.stories_upload_limit_exceeded');
    default:
      logger.sendWarning(
        `[getPhotoErrorMessage] LOST TRANSLATE: (feature: uploadButtonMessages, key: ${error})`,
      );
  }

  return error;
}
