import memoize from 'lodash/memoize';

import {PopperPlacement} from '@core/ui/constants';

import type {PositionSide} from '../types';

const getPositionSide = memoize(
  (placement: PopperPlacement): PositionSide => ({
    isOnTop: [
      PopperPlacement.TOP,
      PopperPlacement.TOP_START,
      PopperPlacement.TOP_END,
    ].includes(placement),
    isOnBottom: [
      PopperPlacement.BOTTOM,
      PopperPlacement.BOTTOM_START,
      PopperPlacement.BOTTOM_END,
    ].includes(placement),
    isOnLeft: [
      PopperPlacement.LEFT,
      PopperPlacement.LEFT_START,
      PopperPlacement.LEFT_END,
    ].includes(placement),
    isOnRight: [
      PopperPlacement.RIGHT,
      PopperPlacement.RIGHT_START,
      PopperPlacement.RIGHT_END,
    ].includes(placement),
    isOnCenter: [
      PopperPlacement.TOP,
      PopperPlacement.BOTTOM,
      PopperPlacement.RIGHT,
      PopperPlacement.LEFT,
    ].includes(placement),
    isOnEnd: [
      PopperPlacement.TOP_END,
      PopperPlacement.BOTTOM_END,
      PopperPlacement.RIGHT_END,
      PopperPlacement.LEFT_END,
    ].includes(placement),
  }),
);

export default getPositionSide;
