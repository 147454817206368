import getSizeBasedOnZoom from '@core/application/utils/getSizeBasedOnZoom';

import type {SetStylesOptions} from '../types';

const setStyles = ({floatingEl, arrowEl, position}: SetStylesOptions): void => {
  const {arrowLeft, arrowTop, top, left} = position;

  floatingEl.style.transform = `translate3d(${getSizeBasedOnZoom(left)}px, ${getSizeBasedOnZoom(top)}px, 0)`;

  if (arrowLeft) {
    arrowEl.style.left = `${getSizeBasedOnZoom(arrowLeft)}px`;
  } else if (arrowTop) {
    arrowEl.style.top = `${getSizeBasedOnZoom(arrowTop)}px`;
  }
};

export default setStyles;
