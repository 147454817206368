import {MSG} from '../constants/socketEventNames';

export default {
  StoriesDeclineSubscription: {
    incomingEvents: [
      `${MSG}declinePhotoUserStories`,
      `${MSG}declineVideoUserStories`,
    ],

    fromServer: ({type, group, fragmentId}) => ({
      data: {
        storyPart: {
          /**
           * Decline group story only after decline
           * TODO In this case 'group' returns like string because of server problems
           */
          id: !parseInt(group, 10) ? fragmentId : null,
          isVideo: type === 'declineVideoUserStories',
          __typename: 'StoryPartType',
        },
      },
    }),
  },

  StoryPartAddedSubscription: {
    incomingEvents: `${MSG}userStoryFragmentConfirmed`,

    fromServer: ({userId, storyId, storyPart}) => ({
      data: {
        storyPartAdded: {
          userId,
          storyId,
          storyPart: {
            ...storyPart,
            __typename: 'StoryPartType',
          },
          __typename: 'StoryPartAdded',
        },
      },
    }),
  },
};
