import type {ReactEventHandler, MouseEventHandler, FC} from 'react';
import React, {useRef, useLayoutEffect} from 'react';
import cn from 'classnames';

import type PhotoLevel from '@core/user/photo/constants/PhotoLevel';

import type ImageScaling from '../../constants/ImageScaling';
import css from './BlurredImage.css';

/**
 * We must deny for lazy users to use 'right click' for saving photos
 */
const handleContextMenuClick: MouseEventHandler = (e) => {
  e.preventDefault();

  return false;
};

/**
 * The minimum width of the phone,
 * it will be relevant if you place the phone in a horizontal position
 * and the photo will be full screen as in the gallery.
 */
const DOUBLE_BLUR_IMAGE_SIZE = 320;

export interface BlurredImageProps {
  url: string;
  level: PhotoLevel;
  scaling?: ImageScaling;
  className?: string;
  onLoad?: ReactEventHandler<HTMLImageElement>;
}

const BlurredImage: FC<BlurredImageProps> = ({
  url,
  level,
  scaling,
  className,
  onLoad,
}) => {
  const imgRef = useRef<HTMLImageElement>();

  useLayoutEffect(() => {
    const {clientWidth, clientHeight} = imgRef.current;
    const size = Math.max(clientWidth, clientHeight);

    if (size >= DOUBLE_BLUR_IMAGE_SIZE) {
      // 'classList.add' used for prevent visible change blur level
      imgRef.current.classList.add(css.doubleBlur);
    }
  }, []);

  return (
    <img
      ref={imgRef}
      alt=""
      className={cn(
        css.image,
        css[`blur${level}`],
        scaling && css[scaling],
        className,
      )}
      onContextMenu={handleContextMenuClick}
      src={url}
      onLoad={onLoad}
    />
  );
};

export default BlurredImage;
