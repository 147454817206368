import type {ACLRule} from '../types';
import type {AuthorizedQuery} from '../graphql/queries/authorized';
import AUTHORIZED_QUERY from '../graphql/queries/authorized.gql';

/**
 * Authorized access rule.
 */
const AuthenticatedRule: ACLRule = {
  errorCode: 'USER_IS_GUEST',
  query: AUTHORIZED_QUERY,
  resolver: (data: AuthorizedQuery) => data.isAuthorizedZone,
};

export default AuthenticatedRule;
