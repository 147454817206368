import {useReactiveVar} from '@apollo/client';

import activePackageVar from '@core/graphql/vars/activePackageVar';

/**
 * Get active package from cache
 * Its default value null
 * @return {object}
 */
const useActivePackage = () => {
  const activePackage = useReactiveVar(activePackageVar);

  return {activePackage, setActivePackage: activePackageVar};
};
export default useActivePackage;
