import {
  FUNNEL,
  DEFAULT,
  PHOTO_RESTRICTIONS,
  LIKE,
  REQUIRED_PHOTO,
  FUNNEL_PMA_NEXT_STEP,
} from './sources';

/**
 * Sources with motivation block. @see MediaUploadMotivation
 */
export default [
  FUNNEL,
  FUNNEL_PMA_NEXT_STEP,
  DEFAULT,
  PHOTO_RESTRICTIONS,
  LIKE,
  REQUIRED_PHOTO,
];
