/**
 * Type of Badge. Used to set color modification of badges.
 * @see Badge.tsx
 */
enum BadgeType {
  DANGER = 'danger',
  SUCCESS = 'success',
  PRIMARY = 'primary',
  ACCENT = 'accent',
  WARNING = 'warning',
}

export default BadgeType;
