type LinkOptions = {
  /** Is used to prevent duplicates. */
  id: string;
  /** Link `href` attribute. */
  url: string;
  /** `rel` attribute. */
  rel: string;
};

/**
 * Adds `<link>` element to the `head`.
 */
const addLinkNode = ({id, url, rel}: LinkOptions): void => {
  const existedLinkNode = document.getElementById(id);

  // No add the same node
  if (existedLinkNode) {
    return;
  }

  const link = document.createElement('link');
  link.rel = rel;
  link.href = url;
  link.id = id;
  document.head.appendChild(link);
};

export default addLinkNode;
