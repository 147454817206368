import type {Observable, FetchResult} from '@apollo/client';

import type {Client} from '@core/graphql/types';
import isMobileViewport from '@core/responsive/isMobileViewport';

import invalidateCacheByTypename from '../../../graphql/utils/invalidateCacheByTypename';
import getConvertVideoSubscription from '../current/utils/getConvertVideoSubscription';
import getDisapprovePhotoSubscription from '../current/utils/getDisapprovePhotoSubscription';
import getDisapproveVideoSubscription from '../current/utils/getDisapproveVideoSubscription';
import getApproveScreennameSubscription from '../current/utils/getApproveScreennameSubscription';
import getApproveStatusSubscription from '../current/utils/getApproveStatusSubscription';
import updateDisapproveCache from '../menu/utils/updateDisapproveCache';
import {
  removePhotoFromCache,
  removeVideoFromCache,
  updateVideo,
} from '../current/utils';
import DISAPPROVE_QUERY from '../menu/graphql/queries/disapprove.gql';
import type {DisapproveQuery} from '../menu/graphql/queries/disapprove';
import type DisapproveResult from '../current/types/disapproveResult';

let startedListeners = false;

/**
 * Starts subscriptions for any disapprove change
 */
const startDisapproveListeners = async (client: Client) => {
  if (!startedListeners) {
    await client.query<DisapproveQuery>({query: DISAPPROVE_QUERY});

    getDisapprovePhotoSubscription().subscribe(({data: {disapprove}}) => {
      if (disapprove.photo && disapprove.id) {
        removePhotoFromCache({id: disapprove.id});
      } else {
        // Invalidate setting of photo motivation to prevent show motivation in media upload popup
        invalidateCacheByTypename(
          client,
          'UserFeatures',
          'photoUploadMotivationSettings',
        );
        const isPhone = isMobileViewport();

        if (isPhone) {
          invalidateCacheByTypename(
            client,
            'UserFeatures',
            'firstMessageGeneration',
          );
        }
      }
    });

    getDisapproveVideoSubscription().subscribe(({data: {disapprove}}) => {
      if (disapprove.video && disapprove.id) {
        removeVideoFromCache({id: disapprove.id});
      }
    });

    getConvertVideoSubscription().subscribe(({data: {video}}) => {
      updateVideo(video);
    });

    const subscriptions = [
      getDisapprovePhotoSubscription(),
      getDisapproveVideoSubscription(),
      getApproveScreennameSubscription(),
      getApproveStatusSubscription(),
    ] as Observable<FetchResult<DisapproveResult>>[];

    subscriptions.forEach((subscription) => {
      subscription.subscribe((update) => {
        updateDisapproveCache(update);
      });
    });
  }

  startedListeners = true;
};

export default startDisapproveListeners;
