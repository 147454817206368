import type {FC} from 'react';
import React, {useEffect, useState} from 'react';
import Expire from 'react-expire';
import cn from 'classnames';
import uniqueId from 'lodash/uniqueId';

import css from '../styles/BabciaNotifications.css';

const generateKey = () => uniqueId('bab');

type NotificationData = {
  category: string;
  event: string;
  value: string;
  debugInfo?: string;
};

type NotificationDataWithKey = NotificationData & {key: string};

declare global {
  interface Window {
    babciaNotify: (data: NotificationData) => void;
  }
}

/**
 * Displays load tracking logs directly on the page
 * to see how the tracking moment matches actually visible content at the moment.
 */
const BabciaNotifications: FC = () => {
  const [notifications, setNotifications] = useState<NotificationDataWithKey[]>(
    [],
  );

  useEffect(() => {
    /**
     * Is used in
     * @see babciaDebugLog
     */
    window.babciaNotify = (data) => {
      setNotifications((messages) => [
        ...messages,
        {key: generateKey(), ...data},
      ]);
    };
  }, []);

  if (!notifications.length) {
    return null;
  }

  return (
    <div className={css.main}>
      {notifications.map((data) => {
        const {key, category, event, value, debugInfo} = data;

        return (
          <Expire
            key={key}
            until={500}
            onExpire={() => {
              setNotifications((messages) =>
                messages.filter((message) => message !== data),
              );
            }}
          >
            <div className={cn(css.item, css[category], css[value])}>
              {[category, event, value, debugInfo].filter(Boolean).join(' ')}
            </div>
          </Expire>
        );
      })}
    </div>
  );
};

export default BabciaNotifications;
