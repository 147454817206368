/**
 * Vertical alignment for items
 * @see Group.tsx
 */
enum VerticalAlign {
  CENTER = 'verticalCenter',
  TOP = 'verticalTop',
  BOTTOM = 'verticalBottom',
}

export default VerticalAlign;
