import {useMemo, useEffect} from 'react';

import {useLocalStorage} from '@core/utils/storage';

import {ACTIVE_HEADER_TAB} from '../constants/storageKeys';

/**
 * Common tab logic
 */
const useMenuTab = ({
  location,
  tabListResolver,
  activityHref,
  tabListData,
  getBindedTabByRoute,
  route: ROUTE,
}) => {
  const [storedRouteName, setStoredRouteName] =
    useLocalStorage(ACTIVE_HEADER_TAB);

  const tabList = useMemo(
    () => tabListResolver({...tabListData, activityHref}),
    [tabListData, tabListResolver, activityHref],
  );

  let activeTabName;

  const tab = useMemo(
    () =>
      tabList.find(
        ({name, link}) => (link || ROUTE[name]) === location.pathname,
      ),
    [tabList, ROUTE, location.pathname],
  );

  if (tab) {
    /**
     * We have to write a current active tab to the session,
     * because when a navigation has been happen with reloading
     * we will have an old tab name in a session
     */
    activeTabName = tab.name;
  } else {
    activeTabName = getBindedTabByRoute(location.pathname);
  }

  useEffect(() => {
    if (activeTabName) {
      setStoredRouteName(activeTabName);
    }
  }, [activeTabName, setStoredRouteName]);

  return {
    tabList,
    activeTabName,
    storedRouteName,
  };
};

export default useMenuTab;
