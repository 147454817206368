/* global SERVER_ENVIRONMENT */

import some from 'lodash/some';

import isApiEncoded from '@core/application/utils/isApiEncoded';

/**
 * @type {string[]}
 */
export const DENIED_ENCODE_URLS = [
  '/api/v1/pay',
  '/api/v1/module/payment',
  '/api/v1/photo/upload',
  '/api/v1/video/upload',
  '/api/v1/personalIdentity/uploadFile',
];

/**
 * Encodes /api/<url> to /api/<base64> for some sites. (s.u. graphql requests)
 * @param {string} uri
 * @return {string}
 */
export const getAbsoluteUrl = (uri) => {
  let hostname;
  let origin;
  let action = uri;

  if (SERVER_ENVIRONMENT && global.context.backendUrl) {
    origin = global.context.backendUrl;
  } else {
    ({hostname, origin} = window.location);

    if (isApiEncoded()) {
      action = window.btoa(`${action}${hostname}`);
    }
  }

  return `${origin}/api/${action}`;
};

/**
 * Encodes /api/<url> to /api/<base64> for some sites. (s.u. rest api)
 * @param {string} uri
 * @returns {string}
 */
export const getRelativeUrl = (uri) => {
  if (!isApiEncoded()) {
    return uri;
  }

  if (some(DENIED_ENCODE_URLS, (prefix) => uri.indexOf(prefix) === 0)) {
    return uri;
  }

  const matches = uri.match(/^\/api\/([^?]+)(\?.*)?/);

  if (!matches) {
    return uri;
  }

  const {hostname} = window.location;

  return `/api/${window.btoa(matches[1] + hostname)}${matches[2] || ''}`;
};
