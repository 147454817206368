import type {
  ReactNode,
  MutableRefObject,
  HTMLAttributes,
  RefObject,
} from 'react';
import React, {forwardRef} from 'react';
import cn from 'classnames';

import {toCamelCase} from '@core/utils/string';
import type {CSSModule} from '@core/ui/types';
import type {PopperPlacement} from '@core/ui/constants';

import commonCss from './Widget.css';

export interface WidgetBaseProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  baseCss?: CSSModule;
  css?: CSSModule;
  placement?: PopperPlacement;
  spaced?: boolean;
  showArrow?: boolean;
  /** Used for selects, where there is a finite height of it */
  fixedHeight?: boolean;
  /** Used for selects, where there is a finite height of it */
  fullHeight?: boolean;
  fullWidth?: boolean;
  /** See Popover.css for more information */
  beyondBorders?: boolean;
  /** Apply 'error' appearance to tooltip */
  error?: boolean;
  scrollableRef?: RefObject<HTMLDivElement>;
  'data-test'?: string;
}

export interface WidgetProps extends WidgetBaseProps {
  children?: ReactNode;
  /** Used to detect if is inverse or not the background of popper */
  contentRef?: (element: HTMLDivElement) => void;
  /** Is used to get arrow element for createPopper.js to calculate arrow position */
  arrowRef?: MutableRefObject<HTMLDivElement>;
  active: boolean;
  fixed?: boolean;
  inverse?: boolean;
}

/**
 * Popper layout
 */
const Widget = forwardRef<HTMLDivElement, WidgetProps>(
  (
    {
      contentRef,
      scrollableRef,
      arrowRef,
      children,
      baseCss,
      css,
      fullWidth,
      error,
      active,
      beyondBorders,
      placement,
      showArrow = true,
      spaced = true,
      className,
      fixed,
      fixedHeight = false,
      fullHeight,
      inverse,
      'data-test': dataTest,
      ...props
    },
    ref,
  ) => (
    <div
      className={cn(
        commonCss.container,
        fullWidth && baseCss.fullWidth,
        error && cn(baseCss.error, css.error),
        active && commonCss.active,
        fixed && commonCss.fixed,
        beyondBorders && [baseCss.beyondBorders, css.beyondBorders],
        placement && [
          baseCss[toCamelCase(placement)],
          css[toCamelCase(placement)],
        ],
        fixedHeight ? baseCss.fixedHeight : fullHeight && baseCss.fullHeight,
      )}
      {...props}
      ref={ref}
    >
      <div
        className={cn(
          baseCss.content,
          css.content,
          inverse && baseCss.inverse,
          baseCss.spacedOutside,
          css.spacedOutside,
          spaced && baseCss.spaced,
          className,
        )}
        ref={contentRef}
      >
        <div ref={scrollableRef} className={baseCss.wrap} data-test={dataTest}>
          {children}
        </div>
        {showArrow && (
          <div ref={arrowRef} className={cn(baseCss.arrow, css.arrow)} />
        )}
      </div>
    </div>
  ),
);

export default Widget;
