import {useReactiveVar} from '@apollo/client';

import isSearchFormOpenVar from '@core/graphql/vars/isSearchFormOpenVar';

/**
 * Hooks for announce about hide/show search form
 * @see ActivityBookmark
 */
const useIsSearchFormOpen = (): boolean => useReactiveVar(isSearchFormOpenVar);

export default useIsSearchFormOpen;
