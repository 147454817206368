import {useEffect} from 'react';
import debounce from 'lodash/debounce';
import {useHistory} from 'react-router-dom';

import getUserAgentParser from '@core/utils/getUserAgentParser';
import isDeviceWithTouchScreen from '@core/utils/device/isDeviceWithTouchScreen';

import RESIZE_DELAY from '../constants/resizeDelay';
import getSizeBasedOnZoom from '../utils/getSizeBasedOnZoom';
import setStyleNode from '../utils/setStyleNode';

const OS = getUserAgentParser().getOS();

const OS_NAME = OS.name;

const OS_VERSION = OS.version && Number(OS.version.split('.')[0]);

const IS_IOS = OS_NAME === 'iOS';

const IS_ANDROID = OS_NAME === 'Android';

/**
 * Time in milliseconds
 */
const ORIENTATION_CHANGE_DELAY = 350;

/**
 * Style node id
 */
const APP_HEIGHT_ID = 'appHeight';

/**
 * Style node id
 */
const APP_WIDTH_ID = 'appWidth';

export const ROUTES_WITH_MIN_HEIGHT = [
  /**
   * Because on the mobSite userPhoto is displayed in full screen
   * The page has scroll
   * If you scroll the page, the bottom navbar disappears and the user's photo is enlarged
   */
  '/likeGallery',
  '/discreetProfiles',
];

const isRouteWithMinHeight = () =>
  ROUTES_WITH_MIN_HEIGHT.some((name) => window.location.pathname === name);

/**
 * In Short version of Iframe PP we have only wallet action to pay
 * so we must set only default height to prevent scroll inside iframe
 */
const isShortIframePP = () =>
  window.location.pathname === '/pay/iframePP' &&
  window.location.search.includes('offerShort=true');

/**
 * The value of "clientHeight" changes depending on whether there is bottom navbar on the page or not
 * After first visit or reloading the page, bottom navbar is always visible
 * which means that the screen height is minimal
 * and in at this moment save minimum height of page
 */
let defaultWidth = document.documentElement.clientWidth;
let defaultHeight = document.documentElement.clientHeight;

const setAppHeightStyleNode = (height: number) => {
  const styles = `:root {--appHeight: ${getSizeBasedOnZoom(height)}px}`;

  setStyleNode({id: APP_HEIGHT_ID, styles});
};

const setAppHeight = () => {
  const {clientHeight, clientWidth} = document.documentElement;
  const {innerWidth, innerHeight, outerHeight} = window;
  const isLandscape = innerHeight < innerWidth;

  /**
   * ATTENTION!!!!!!!!!!!!!!!!
   * Update 'defaultHeight' and 'defaultWidth' need only in cases:
   * - when user reload page in landscape orientation and after that go to portrait orientation
   * - when we open site in new tab on ios, because by default we set height without toolbars
   *
   * In other cases update 'defaultHeight' and 'defaultWidth' not need!
   */
  if (defaultHeight < defaultWidth && innerHeight > innerWidth) {
    defaultHeight = clientHeight;
    defaultWidth = clientWidth;
  } else if (IS_IOS && !isLandscape && defaultHeight > clientHeight) {
    defaultHeight = clientHeight;
  }

  let height = innerHeight;

  /**
   * Set max height as screen height for preventing:
   *  - broken scroll on chatWith page on IOS.
   *  - and appHeight after zoom page
   */
  if (isDeviceWithTouchScreen) {
    height = Math.max(innerHeight, defaultHeight, defaultWidth);

    /**
     * Set correct app height for android devices when keyboard is open.
     * maxHeight for correct work on Samsung Internet browser on Android.
     */
    const maxHeight = Math.max(clientHeight, innerHeight);
    if (IS_ANDROID && defaultHeight > maxHeight) {
      height = maxHeight;
    }

    if (isRouteWithMinHeight()) {
      height = isLandscape ? outerHeight : defaultHeight;
    }

    if (isShortIframePP()) {
      height = defaultHeight;
    }
  }

  setAppHeightStyleNode(height);
};

const setAppWidth = () => {
  const width = window.innerWidth;
  const styles = `:root {--appWidth: ${getSizeBasedOnZoom(width)}px}`;

  setStyleNode({id: APP_WIDTH_ID, styles});
};

export const setAppSize = () => {
  setAppHeight();
  setAppWidth();
};

/* Fixed iOS Safari orientation change animation duration.
 * The window gets the correct size approximately 350ms after the animation ends.
 */
const handleOrientationchange = debounce(setAppSize, ORIENTATION_CHANGE_DELAY);

const handleResizeDebounced = debounce(setAppSize, RESIZE_DELAY);

const handleResize = () => {
  /*
   * Relevant for Android devices - 'setAppSize()'
   * Fixed a bug where a space would appear under the input block(relevant for both open and closed keyboards)
   * if the browser menu disappeared while scrolling.
   */
  IS_ANDROID && setAppSize();
  handleResizeDebounced();
};

/*
 * Wrapper for set and update css vars '--appHeight' and '--appWidth'
 */
const CSSAppSizeDetector = () => {
  const history = useHistory();

  useEffect(() => {
    /**
     * On some pages we have different behavior for variable '--appHeight'
     * Listen history for correctly update variable '--appHeight'
     */
    return history.listen(() => {
      setAppSize();

      /**
       * On IOS 18+ version navbar/toolbar can hide when user is scrolling page.
       * If navbar/toolbar was hide before change route and was shown after change route
       * but resize event not fired for update appHeight
       */
      if (IS_IOS && OS_VERSION >= 18) {
        setAppHeightStyleNode(document.documentElement.clientHeight);
      }
    });
  }, [history]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleOrientationchange);

    setAppSize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchagne', handleOrientationchange);
    };
  }, []);

  return null;
};

export default CSSAppSizeDetector;
