import {useReactiveVar} from '@apollo/client';
import isNull from 'lodash/isNull';
import without from 'lodash/without';
import {useCallback} from 'react';

import selectedAdditionalPackagesVar from '../graphql/vars/selectedAdditionalPackagesVar';

/**
 * Hook for set and get flags if additional packages are checked, it is using as visual flags, on it flags are based
 * additional packages request param there is no point to keep the package for visual state
 * @return {Object}
 */
const useSelectedAdditionalPackages = () => {
  const packageList = useReactiveVar(selectedAdditionalPackagesVar);

  const setPackage = useCallback(
    (packageType, isSelected) => {
      if (isSelected) {
        if (isNull(packageList) || !packageList.includes(packageType)) {
          selectedAdditionalPackagesVar(
            [].concat(packageList || [], [packageType]),
          );
        }
      } else {
        selectedAdditionalPackagesVar(without(packageList, packageType));
      }
    },
    [packageList],
  );

  return {packageList, setPackage};
};
export default useSelectedAdditionalPackages;
