/**
 * Icon size in PX. Names are equal to size of icon by itself.
 * @see Icon.tsx
 */
enum IconSize {
  SIZE_12 = 'size12',
  SIZE_16 = 'size16',
  SIZE_24 = 'size24',
  SIZE_36 = 'size36',
  SIZE_48 = 'size48',
  SIZE_72 = 'size72',
  SIZE_96 = 'size96',
}

export default IconSize;
