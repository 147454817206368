import type {History} from 'history';

import routeVar from '@core/graphql/vars/routeVar';

const initBoomerang = (history: History) => {
  function hookHistoryBoomerang() {
    // @see ./boomerang.d.ts
    if (window.BOOMR && window.BOOMR.version) {
      if (window.BOOMR.plugins && window.BOOMR.plugins.History) {
        /**
         * @see https://developer.akamai.com/tools/boomerang/#React
         *
         *  The second parameter to BOOMR.plugins.History.hook() is
         *  the hadRouteChange variable, which tells the History
         *  plugin that a React route change had already occurred
         *  prior to when this instrumentation is starting.
         *  If hadRouteChange is true, the History plugin will
         *  send performance information about that missed route
         *  change right away. If not, it waits for the first route-change.
         */
        window.BOOMR.plugins.History.hook(
          history,
          Boolean(routeVar().previous),
        );
      }
      return true;
    }
    return false;
  }

  const isHistoryHooked = hookHistoryBoomerang();

  if (!isHistoryHooked) {
    document.addEventListener('onBoomerangLoaded', hookHistoryBoomerang);
  }
};

export default initBoomerang;
