import type {ReactNode, FC} from 'react';
import React, {useEffect} from 'react';
import {Router as ReactRouter} from 'react-router-dom';

import getHistory from '../utils/getHistory';
import initBoomerang from '../utils/initBoomerang';

type RouterProps = {
  children: ReactNode;
};

const Router: FC<RouterProps> = ({children}) => {
  useEffect(() => {
    initBoomerang(getHistory());
  }, []);

  return <ReactRouter history={getHistory()}>{children}</ReactRouter>;
};

export default Router;
