import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';

import getHistory from '@core/application/utils/getHistory';
import {decodeLink, isHashed} from '@core/application/utils/hashedStaticLinks';

import MountName from '../constants/mountName';
import {mountedComponents} from './mountedComponents';
import trackPageLoadCompletion from './trackPageLoadCompletion';

type MountedCounts = Record<string, number>;

type Rule = [RegExp, ...string[][]];

const {
  ACTIVITY_LIST,
  CHAT_ACTION,
  UNBLOCK_ACTION,
  UNREPORT_ACTION,
  WEBCAM_ACTION,
  FAVORITE_ACTION,
  CHAT_HEADER,
  ROOM_HEADER,
  MAIN_PHOTO,
  NAVIGATION_TABS,
  MESSAGE_LIST,
  RECIPIENTS,
  SEARCH_FORM,
  SEARCH_USER_WIDGET,
  SEARCH_USER_INFO,
  NO_RESULTS,
  MICROFEATURES_WIDGET,
  BASIC_INFO,
  PROFILE_SECTION,
  REWARD_TASKS,
  STORE_ACTIONS,
  USER_STORY,
  TARGET_USER_INFO,
  PROFILE_MENU_ITEM,
  MEMBERSHIP_DETAILS,
  PAGE_LAYOUT,
  PAY_BUTTON,
  CONTINUE_PAY_BUTTON,
} = MountName;

/**
 * These rules describe which elements for which page should be mounted/loaded to track 'Page Load Complete'.
 * First rule item is regexp used to match page url.
 * If matched part is changed after url change - it will be additional 'Load Complete' track.
 * Rest of rule items - lists of element names.
 *   Mounted elements should match one of these lists to track completion on matching page.
 */
const RULES: Rule[] = [
  [/^\/account(?!\/)/, [PAGE_LAYOUT, MEMBERSHIP_DETAILS]],
  [
    /^\/account\/blocked/,
    [SEARCH_USER_WIDGET, UNBLOCK_ACTION],
    [SEARCH_USER_INFO],
    [NO_RESULTS],
  ],
  [
    /^\/account\/reported/,
    [SEARCH_USER_WIDGET, UNREPORT_ACTION],
    [SEARCH_USER_INFO],
    [NO_RESULTS],
  ],
  [/^\/chat\/recipients/, [NAVIGATION_TABS, RECIPIENTS]],
  [/^\/pay\/verificationSucces/, [PAGE_LAYOUT]],
  [/^\/pay\/result\/success/, [PAGE_LAYOUT]],
  [/^\/pay/, [PAY_BUTTON], [CONTINUE_PAY_BUTTON]],
  // .+ to track load completion when switching to another chat (like /chat/with/1 -> /chat/with/2).
  [/^\/chat\/with\/.+/, [CHAT_HEADER, MESSAGE_LIST]],
  [
    /^\/favorites/,
    [SEARCH_USER_WIDGET, FAVORITE_ACTION],
    [SEARCH_USER_INFO],
    [NO_RESULTS],
  ],
  [/^\/freeCoins/, [REWARD_TASKS]],
  [
    /^\/likeGallery\/\w+/, // /likeGallery/fullMatches, /likeGallery/likedYou
    [NAVIGATION_TABS, SEARCH_USER_WIDGET, CHAT_ACTION],
    [NAVIGATION_TABS, SEARCH_USER_INFO],
    [NAVIGATION_TABS, NO_RESULTS],
  ],
  [
    /^\/likeGallery/,
    [NAVIGATION_TABS, MAIN_PHOTO],
    [NAVIGATION_TABS, NO_RESULTS],
  ],
  [
    /^\/discreetProfiles\/\w+/, // /likeGallery/opened
    [NAVIGATION_TABS, SEARCH_USER_WIDGET, CHAT_ACTION],
    [NAVIGATION_TABS, NO_RESULTS],
  ],
  [
    /^\/discreetProfiles/,
    [NAVIGATION_TABS, MAIN_PHOTO],
    [NAVIGATION_TABS, NO_RESULTS],
  ],
  [
    /^\/livechat/,
    [NAVIGATION_TABS, SEARCH_USER_WIDGET, WEBCAM_ACTION],
    [NAVIGATION_TABS, SEARCH_USER_INFO],
    [NAVIGATION_TABS, NO_RESULTS],
  ],
  [/^\/microfeatures/, [MICROFEATURES_WIDGET]],
  // .+ to track activity tab switches.
  [/^\/newsFeed\/.+/, [ACTIVITY_LIST]],
  [/^\/myActivity\/.+/, [ACTIVITY_LIST]],
  [/^\/profile\/menu/, [BASIC_INFO, PROFILE_MENU_ITEM]],
  [/^\/profile/, [BASIC_INFO, PROFILE_SECTION]],
  [/^\/rooms(?!\/)/, [NAVIGATION_TABS]],
  [/^\/rooms\/join\/.+/, [ROOM_HEADER, MESSAGE_LIST]],
  [
    /^\/search\/livecam\?.+/,
    [SEARCH_FORM, SEARCH_USER_WIDGET, WEBCAM_ACTION],
    [SEARCH_FORM, SEARCH_USER_INFO],
    [SEARCH_FORM, NO_RESULTS],
  ],
  [
    /^\/search\?.+/, // .+ to track load completion on back/forward navigation after search with new params.
    [SEARCH_FORM, SEARCH_USER_WIDGET, CHAT_ACTION],
    [SEARCH_FORM, SEARCH_USER_INFO],
    [SEARCH_FORM, NO_RESULTS],
  ],
  [/^\/story/, [USER_STORY]],
  // .+ to track load completion when switching to another profile.
  [
    /^\/user\/view\/id\/.+/,
    [BASIC_INFO, CHAT_ACTION],
    [BASIC_INFO, TARGET_USER_INFO],
  ],
  [/^\/wrongdevice/, [STORE_ACTIONS]],
  [
    // /account/remove
    // /account/unsubscribe
    // /cancel
    // /forbidden
    // /funnelQuiz
    // /getTheApp
    // /notFound
    // /partnerNetwork
    // /personalIdentity
    // /pmaservice
    // /reels
    // /rooms/moderator
    // /site/passwordrecovery
    // /staticPage/*
    // /support/contactUs
    // /support/feedback
    // /trusted
    // /user/massBlocked
    // and possibly other pages
    /^\/.+/,
    [PAGE_LAYOUT],
  ],
];

/**
 * Is used to track 'Page Load Complete' when specific elements on specific route become mounted/loaded.
 */
const startPageLoadObserver = (): void => {
  const history = getHistory();

  /**
   * Returns an unique identifier of the page when it's loaded or false otherwise.
   * It's important to use different value for each page we need to track.
   */
  const getLoadedPage = (
    mountedCounts: MountedCounts,
  ): [string, string[]] | false => {
    const link = decodeLink(history.location.pathname);
    const {pathname, search, hash} = isHashed(link)
      ? new URL(window.location.origin + link)
      : history.location;

    for (let i = 0; i < RULES.length; i++) {
      const [rule, ...componentLists] = RULES[i];
      const matches = `${pathname}${search}${hash}`.match(rule);
      if (matches) {
        let components: string[];
        // If all required components for this url are currently mounted - use matched url part
        // as a unique identifier to track 'Page Load Completion' only when needed.
        return (
          componentLists.some((componentNames) => {
            components = componentNames;
            return componentNames.every((name) => mountedCounts[name]);
            // `components` are used as an additional `debugInfo` for testing.
          }) && [matches[0], components]
        );
      }
    }

    return false;
  };

  // Trigger update to re-check mounted components when url changes.
  history.listen(() => {
    mountedComponents.next(mountedComponents.value);
  });

  mountedComponents
    .pipe(
      /**
       * Components are not unmounted yet when route changes,
       * so there was unwanted premature completion track in some cases:
       *   1. Events order when switching from /support/contactUs to /support/feedback:
       *     - ! unwanted premature completion track
       *     - old PAGE_LAYOUT unmount
       *     - new PAGE_LAYOUT mount
       *     - intended completion track
       * We have tried to fix this by delaying update in `history.listen()` above,
       * but then found slightly different case of this problem:
       *   2. Events order when switching from /account to /pmaservice:
       *     - MEMBERSHIP_DETAILS unmount
       *     - ! unwanted premature completion track
       *     - old PAGE_LAYOUT unmount
       *     - new PAGE_LAYOUT mount
       *     - intended completion track
       * `debounceTime` is used to fix such problems.
       */
      debounceTime(1),
      map(getLoadedPage),
      distinctUntilChanged((a, b) => a?.[0] === b?.[0]),
      filter(Boolean),
      map(([, components]) => components),
    )
    .subscribe(trackPageLoadCompletion);
};

export default startPageLoadObserver;
