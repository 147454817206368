import React, {
  Children,
  cloneElement,
  type ChangeEvent,
  type FC,
  type ReactElement,
  type ReactNode,
} from 'react';
import isNil from 'lodash/isNil';

import type VerticalAlign from '../../constants/VerticalAlign';
import type SpacingSize from '../../constants/SpacingSize';
import {Group} from '../group';
import type {CheckboxProps, ChangeCustomEventHandler} from './Checkbox';

type Value = string | number;

interface CustomChangeEvent {
  target: {
    name: string;
    value: Value[];
  };
}

export interface CheckboxGroupProps {
  children: ReactNode;
  onChange?: (e: CustomChangeEvent, values: Value[]) => void;
  name: string;
  // List of checked values
  value: Value[];
  className?: string;
  itemWrapperClassName?: string;
  horizontal?: boolean;
  verticalAlign?: VerticalAlign;
  itemWithFlexibleWidth?: boolean;
  inline?: boolean;
  space?: SpacingSize;
}

/**
 * Normalize data. Arrived value can be number (string) or array
 */
const normalizeValues = (values: Value[]) => {
  if (!Array.isArray(values)) {
    return isNil(values) ? [] : [values];
  }
  return values;
};

const DEFAULT_VALUE: Value[] = [];

/**
 * Groupping component for checkboxes that bubbles change event up
 * @see Group
 */
const CheckboxGroup: FC<CheckboxGroupProps> = ({
  children,
  className,
  itemWrapperClassName,
  name,
  horizontal = false,
  verticalAlign,
  inline = false,
  value = DEFAULT_VALUE,
  space,
  itemWithFlexibleWidth,
  onChange,
}) => {
  const handleChange: ChangeCustomEventHandler = (_e, newValue) => {
    // Need to copy array, not re-assign and mutate state
    const values = normalizeValues(value).slice(0);

    if (values.includes(newValue)) {
      values.splice(values.indexOf(newValue), 1);
    } else {
      values.push(newValue);
    }

    onChange?.(
      // custom event target is used to simplify usage with Formik
      {target: {name, value: values}},
      values,
    );
  };
  const normalizedValue = normalizeValues(value);

  return (
    <Group
      inline={inline}
      horizontal={horizontal}
      verticalAlign={verticalAlign}
      className={className}
      itemWrapperClassName={itemWrapperClassName}
      itemWithFlexibleWidth={itemWithFlexibleWidth}
      space={space}
    >
      {Children.map(children, (child: ReactElement<CheckboxProps>) =>
        cloneElement(child, {
          checked: normalizedValue.includes(child.props.value),
          onChange: (
            e: ChangeEvent<HTMLInputElement>,
            v: Value,
            checked: boolean,
          ) => {
            handleChange(e, v);
            child.props.onChange?.(e, v, checked);
          },
          name,
        }),
      )}
    </Group>
  );
};

export default CheckboxGroup;
