import normalizeMutationErrors from '@core/graphql/utils/normalizeMutationErrors';
import logger from '@core/logger';
import {getClientInstance} from '@core/graphql/client';

import TRACK_PAY_CLICK_MUTATION from '../../payProcess/graphql/mutations/trackPayClick.gql';
import normalizeVariablesForPayTrack from './normalizeVariablesForPayTrack';

/**
 * @param {Object} paymentData
 * @return {Promise}
 */
const trackPaymentClick = async (paymentData = {}) => {
  const client = getClientInstance();

  return client
    .mutate({
      mutation: TRACK_PAY_CLICK_MUTATION,
      variables: normalizeVariablesForPayTrack(paymentData),
    })
    .then((response) => {
      const errors = normalizeMutationErrors(
        response.data.payment.payClick.errors,
      );

      if (errors) {
        logger.sendError(
          `[trackPaymentClick] Track payment click mutation error ${
            errors.general
          }`,
        );
      }
    });
};

export default trackPaymentClick;
