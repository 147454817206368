import isObject from 'lodash/isObject';

import type {SearchParamsMethods} from '../types/uri';

const invokeSearchParamsMethodWithString = (
  method: SearchParamsMethods,
  urlObject: URL,
  key: string,
  value: string,
) => {
  urlObject.searchParams[method](key, value);
};

const invokeSearchParamsMethodWithObject = (
  method: SearchParamsMethods,
  urlObject: URL,
  paramsObject: Record<string | number, any>,
) => {
  Object.keys(paramsObject).forEach((key) => {
    const value = paramsObject[key];
    if (Array.isArray(value)) {
      value.forEach((item) => {
        urlObject.searchParams[method](key, item);
      });
    } else {
      urlObject.searchParams[method](key, value);
    }
  });
};

const invokeSearchParamsMethodWithParamsAsArray = (
  method: SearchParamsMethods,
  urlObject: URL,
  key: string,
  value: string[],
) => {
  value.forEach((item) => {
    urlObject.searchParams[method](key, item);
  });
};

const invokeSearchParamsMethod = (
  method: SearchParamsMethods,
  urlObject: URL,
  name: any,
  param?: any,
) => {
  if (Array.isArray(param)) {
    invokeSearchParamsMethodWithParamsAsArray(method, urlObject, name, param);
  } else if (isObject(name)) {
    invokeSearchParamsMethodWithObject(method, urlObject, name);
  } else if (typeof name === 'string' && typeof param === 'string') {
    invokeSearchParamsMethodWithString(method, urlObject, name, param);
  }
};

export default invokeSearchParamsMethod;
