/**
 * It clears off spaces and trim it (big inputs: chat up line, messenger input field)
 */
const clearText = (text: string): string => {
  if (!text) {
    return text;
  }

  return text.trim().replace(/ {2,}/g, ' ');
};

export default clearText;
