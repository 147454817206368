/**
 * Cookie for testing
 */
export const DECLINE_SCREEN_TEMPLATE_COOKIE = 'declineScreenTemplate';

/**
 * Types of decline message when a payment process fails.
 */
enum DeclineMessageType {
  /**
   * Show when maximum transaction amount for used payment method.
   * Received from 'declineMessageType' field.
   */
  BLOCKED_DECLINE = 'blockedDecline',
  TRY_ANOTHER_CARD_DECLINE = 'tryAnotherCard',

  /**
   * Internal Decline Message types.
   * Received from 'declineScreenTemplate' field.
   */
  TEMPLATE_BANK_CARD_ERROR = 'bankCardError',
  TEMPLATE_PAYMENT_PROCESSING_ERROR = 'paymentProcessingError',
  TEMPLATE_CARD_TRANSACTIONS_RESTRICTED = 'cardTransactionsRestricted',
  TEMPLATE_3DS_VERIFICATION_REQUIRED = '3DSVerificationRequired',
  TEMPLATE_CVV_CODE_ENTER_FAILED = 'cvvCodeEnterFailed',
  TEMPLATE_INVALID_CVV_CODE = 'invalidCvvCode',
  TEMPLATE_INVALID_CARD_EXP_DATE = 'invalidCardExpDate',
  TEMPLATE_INVALID_CARD_NUMBER = 'invalidCardNumber',
  TEMPLATE_INSUFFICIENT_FUNDS = 'insufficientFunds',

  /**
   * Message type to notice that SEPA payment limit is reached
   * via: SEPA_OFF
   */
  SEPA_ATTEMPT_LIMIT_REACHED = 'sepaAttemptLimitReached',
  /**
   * Message type for NOVAL_NET_SEPA method
   */
  INCORRECT_BANK_DETAILS = 'incorrectBankDetails',
  DEFAULT = 'default',
}

export default DeclineMessageType;
