import type {FC} from 'react';
import React, {
  Fragment,
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react';
import cn from 'classnames';
import isNaN from 'lodash/isNaN';
import {motion, useMotionValue} from 'framer-motion';

import {Text} from '@core/typography';

import type {CSSModule} from '../../types';
import type {LabelProps} from '../label/Label';
import baseCss from './Slider.css';

interface OnChangeHandler {
  (
    event: {target: {value: number; name: string}},
    value?: number,
    name?: string,
  ): void;
}

export interface SliderProps {
  label?: string;
  from?: number;
  to: number;

  /**
   * All props below can be separated into 2 groups
   * - regular name without suffix is used for standard slider with 1 draggable bullet
   * - props with suffix "from" and "to" are used for range slider (2 draggable bullets)
   *
   * It's done in such way instead of using arrays as props since react can't compare complex
   * things like arrays and objects and on every re-render they are not equal.
   */
  value?: number;
  valueFrom?: number;
  valueTo?: number;
  name?: string;
  nameFrom?: string;
  limit?: number;
  nameTo?: string;
  onChange?: OnChangeHandler;
  onChangeFrom?: OnChangeHandler;
  onChangeTo?: OnChangeHandler;
  showValue?: boolean;
}

/**
 * Draggable slider UI widget for simplifying selecting
 * value instead of default select widget.
 */
const Slider: FC<
  // `SeparatorProps` without some props inside to make it more suitable for `@phoenix/ui`.
  SliderProps & {css: CSSModule; labelComponent: FC<LabelProps>}
> = ({
  label,
  labelComponent: Label,
  css,
  from = 0,
  to,
  onChange: outerOnChange,
  onChangeFrom,
  onChangeTo,
  value: outerValue,
  valueFrom,
  valueTo,
  name: outerName,
  nameFrom,
  nameTo,
  showValue = true,
  limit,
}) => {
  const sliderRef = useRef(null);
  const dragConstraintsRef = useRef({});

  const segmentFrom = useMotionValue(0);
  const segmentTo = useMotionValue(0);
  const segmentWidth = useMotionValue(0);

  const limitFrom = useMotionValue(0);
  const limitWidth = useMotionValue(0);

  // Simplify working with outside values, making them in any case array
  const value = useMemo(
    () => (outerValue ? [outerValue] : [valueFrom, valueTo]),
    [outerValue, valueFrom, valueTo],
  );

  const isRangeSlider = value.length !== 1;

  const name = useMemo(
    () => (outerName ? [outerName] : [nameFrom, nameTo]),
    [outerName, nameFrom, nameTo],
  );

  const onChange = useMemo(
    () => (outerOnChange ? [outerOnChange] : [onChangeFrom, onChangeTo]),
    [outerOnChange, onChangeFrom, onChangeTo],
  );

  // Used only for displaying them near draggable bullets
  const [internalValue, setInternalValue] = useState(value);

  /**
   * Set distance (length) corrected by slider width
   */
  const getDistanceByValue = useCallback(
    (amount: number) => {
      return (sliderRef.current.clientWidth / (to - from)) * (amount - from);
    },
    [from, to],
  );

  /**
   * Get real value to be sent to server
   */
  const getValueFromDistance = useCallback(
    (distance: number) => {
      const result =
        Math.round(distance / (sliderRef.current.clientWidth / (to - from))) +
        from;
      return isNaN(result) ? null : result;
    },
    [from, to],
  );

  const updateDragConstraints = useCallback(
    (start: number, end: number) => {
      if (!isRangeSlider) {
        dragConstraintsRef.current = [
          {left: 0, right: sliderRef.current.clientWidth},
        ];
        return;
      }

      dragConstraintsRef.current = [
        {left: 0, right: end},
        {left: start, right: sliderRef.current.clientWidth},
      ];
    },
    [isRangeSlider],
  );

  /**
   * Set value that will trigger "onChange" callback.
   */
  const handleDragEnd = useCallback(() => {
    onChange.forEach((handle, index) => {
      handle?.(
        {
          target: {
            value: internalValue[index],
            name: name[index],
          },
        },
        internalValue[index],
        name[index],
      );
    });
  }, [internalValue, name, onChange]);

  /**
   * Resize handler for correcting visual part of slider.
   */
  const handleResize = useCallback(() => {
    const start = getDistanceByValue(internalValue[0]);
    const end = getDistanceByValue(internalValue[1]);

    segmentFrom.set(start);
    segmentTo.set(end);
    segmentWidth.set(!isRangeSlider ? start : end - start);

    if (!isRangeSlider) {
      const limitStart = getDistanceByValue(limit);
      const limitBarWidth = start - limitStart;

      limitFrom.set(limitStart);
      limitWidth.set(limitBarWidth);
    }
  }, [
    getDistanceByValue,
    internalValue,
    limitFrom,
    limitWidth,
    segmentFrom,
    segmentTo,
    segmentWidth,
    isRangeSlider,
    limit,
  ]);

  /**
   * Handle screen resize for correcting selected values
   */
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  /**
   * Update active bar width and real value that will be
   * sent via 'onChange' callback.
   */
  useEffect(() => {
    const update = () => {
      const start = segmentFrom.get();
      const end = segmentTo.get();
      const limitStart = limitFrom.get();

      segmentWidth.set(isRangeSlider ? end - start : start);
      if (!isRangeSlider) {
        limitWidth.set(start - limitStart);
      }

      updateDragConstraints(start, end);

      setInternalValue([
        getValueFromDistance(start),
        getValueFromDistance(end),
      ]);
    };

    // Be aware that 'onChange' is triggered on mount too. Library specific bug :(
    const unsubscribeStart = segmentFrom.on('change', () => update());
    const unsubscribeEnd = segmentTo.on('change', () => update());
    const unsubscribeLimitStart = limitFrom.on('change', () => update());

    return () => {
      unsubscribeStart();
      unsubscribeEnd();
      unsubscribeLimitStart();
    };
  }, [
    segmentFrom,
    segmentTo,
    limitFrom,
    limitWidth,
    segmentWidth,
    updateDragConstraints,
    getValueFromDistance,
    isRangeSlider,
  ]);

  /**
   * Initialize slider data.
   * Since we rely on DOM data we should do it only after mount
   */
  useEffect(() => {
    const start = getDistanceByValue(value[0]);
    const end = getDistanceByValue(value[1]);

    segmentFrom.set(start);
    segmentTo.set(end);

    if (!isRangeSlider) {
      limitFrom.set(getDistanceByValue(limit));
    }

    updateDragConstraints(start, end);
  }, [
    value,
    isRangeSlider,
    getDistanceByValue,
    segmentFrom,
    segmentTo,
    limitFrom,
    limit,
    updateDragConstraints,
    getValueFromDistance,
  ]);

  return (
    <Fragment>
      {label && <Label small>{label}</Label>}
      <div className={cn(baseCss.slider, isRangeSlider && baseCss.rangeSlider)}>
        <div className={baseCss.wrap}>
          <div className={baseCss.bar} />
          <motion.div
            className={baseCss.activeBar}
            style={{
              x: !isRangeSlider ? 0 : segmentFrom,
              width: segmentWidth,
            }}
          />
          {!isRangeSlider && limit && internalValue[0] > limit ? (
            <motion.div
              className={baseCss.limitBar}
              style={{
                x: limitFrom,
                width: limitWidth,
              }}
            />
          ) : null}
          <div className={baseCss.sliderBounds} ref={sliderRef}>
            {value.map((_, index) => (
              <Fragment
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <motion.div
                  drag="x"
                  dragConstraints={dragConstraintsRef.current[index]}
                  dragElastic={0}
                  dragMomentum={false}
                  onDragEnd={handleDragEnd}
                  transformTemplate={(origin) =>
                    `translateX(${origin.x}) translateZ(0px)`
                  }
                  className={baseCss.track}
                  style={{
                    x: index === 0 ? segmentFrom : segmentTo,
                  }}
                >
                  <div className={cn(baseCss.bullet, css.bullet)} />
                  {showValue && (
                    <Text className={baseCss.value}>
                      {internalValue[index]}
                    </Text>
                  )}
                  {name[index] && (
                    <input
                      type="hidden"
                      className={baseCss.hidden}
                      value={internalValue[index]}
                      name={name[index]}
                    />
                  )}
                </motion.div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Slider;
