import t from '@core/translations/translate';

/**
 * @param {Number} months
 * @returns {String}
 */
const getPackagePeriodInWeeks = (months) => {
  switch (months) {
    case 1:
      return t('paymentPage', 'text.four');
    case 3:
      return t('paymentPage', 'text.twelve');
    case 6:
      return t('paymentPage', 'text.twenty_four');
    case 12:
      return t('paymentPage', 'text.forty_eight');
    default:
      return '';
  }
};

export default getPackagePeriodInWeeks;
