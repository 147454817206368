import React, {useEffect, useRef, useCallback, useState} from 'react';
import PropTypes from 'prop-types';

import dayjs from '@core/date/utils/dayjs';
import WebCamHelper from '@core/mediaUpload/utils/WebCamHelper';

import useUploadSettings from '../utils/useUploadSettings';
import useRecordingTime from '../utils/useRecordingTime';
import getMediaRecorderOptions from '../utils/getMediaRecorderOptions';

const formatTime = (seconds) => dayjs.unix(seconds).format('mm:ss');

const WebcamVideoCaptureDisplayer = ({
  onCapture,
  errorBoundaryComponent: ErrorBoundary,
  webcamVideoCaptureLayoutComponent: WebcamVideoCaptureLayout,
}) => {
  const webCamHelperRef = useRef();
  const mediaRecorderRef = useRef();
  const containerRef = useRef();
  const [error, setError] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const handleStartRecording = useCallback(() => {
    const mediaRecorder = new MediaRecorder(
      webCamHelperRef.current.stream,
      getMediaRecorderOptions(),
    );
    mediaRecorder.ondataavailable = (event) => {
      /**
       * https://github.com/streamproc/MediaStreamRecorder/issues/93
       */
      if (!event.data || !event.data.size || event.data.size < 500) {
        return;
      }
      onCapture(event.data);
    };
    mediaRecorder.start();
    mediaRecorderRef.current = mediaRecorder;
    setIsRecording(true);
  }, [onCapture]);

  const handleStopRecording = useCallback(() => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  }, []);

  useEffect(() => {
    const webCamHelper = new WebCamHelper({
      container: containerRef.current,
      audio: true,
      video: true,
    });
    webCamHelper
      .start()
      .then(() => setIsReady(true))
      .catch(setError);
    webCamHelperRef.current = webCamHelper;
    return () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.ondataavailable = null;
      }
      webCamHelper.destroy();
    };
  }, []);

  const recordingTime = useRecordingTime(isRecording);
  const {
    minLenght,
    maxLenght,
    loading,
    error: settingsError,
  } = useUploadSettings();
  const percentage = Math.min(1, recordingTime / maxLenght) * 100;
  const time = `${formatTime(recordingTime)} / ${formatTime(maxLenght)}`;

  useEffect(() => {
    if (isRecording && recordingTime >= maxLenght) {
      handleStopRecording();
    }
  }, [isRecording, recordingTime, maxLenght, handleStopRecording]);

  if (settingsError) {
    return <ErrorBoundary error={settingsError} />;
  }

  return (
    <WebcamVideoCaptureLayout
      ref={containerRef}
      isReady={isReady && !error && !loading}
      isRecording={isRecording}
      percentage={percentage}
      time={time}
      isReadyForStop={recordingTime >= minLenght}
      error={error && WebCamHelper.getErrorType(error)}
      onStartRecording={handleStartRecording}
      onStopRecording={handleStopRecording}
    />
  );
};

WebcamVideoCaptureDisplayer.propTypes /* remove-proptypes */ = {
  onCapture: PropTypes.func.isRequired,
  errorBoundaryComponent: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.func,
  ]).isRequired,
  webcamVideoCaptureLayoutComponent: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.func,
  ]).isRequired,
};

export default WebcamVideoCaptureDisplayer;
