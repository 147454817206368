import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';

import ACTIVITIES_QUERY_TYPES from '../constants/activitiesQueryTypes';
import useActivityConsumer from '../utils/useActivityConsumer';

/**
 * Just a wrapped as a hook for backward compatibility in
 * relatively old components.
 */
const ActivityConsumer = ({
  queryType = ACTIVITIES_QUERY_TYPES.LIMITED_ACTIVITIES_FOR_COUNTERS,
  children = null,
}) => {
  const props = useActivityConsumer(queryType);

  if (isFunction(children)) return children(props);
  return children;
};

ActivityConsumer.propTypes /* remove-proptypes */ = {
  queryType: PropTypes.oneOf(Object.values(ACTIVITIES_QUERY_TYPES)),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default ActivityConsumer;
