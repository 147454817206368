import includes from 'lodash/includes';

import {getClientInstance} from '@core/graphql/client';
import t from '@core/translations/translate';
import trackMediaUploadError from '@core/tracking/mediaUploadError/trackMediaUploadError';

import VIDEO_UPLOAD_SETTINGS_QUERY from '../../graphql/queries/videoUploadSettings.gql';
import BYTES_IN_MB from '../../constants/bytesInMb';
import validateVideoDuration from './utils/validateVideoDuration';
import getUserVideoCount from './utils/getUserVideoCount';
import getFileExtension from '../getFileExtension';
import getAllowedExtensionsAsString from '../getAllowedExtensionsAsString';

/**
 * Validate video before upload. AKA frontend validation.
 * @param {Object} props
 * @param {Blob} props.file - Video file
 * @param {Boolean} props.attachToUser - Should it be attached to profile.
 * @param {File[]} props.filesInProgress - Files being uploading currently. To validate similar files.
 * @returns {Promise} Validation promise which is rejected with validation error if file is invalid.
 */
export default async function validateVideo({
  file,
  attachToUser = false,
  filesInProgress = [],
}) {
  const {
    data: {
      userFeatures: {
        userVideo: {
          settings: {allowedExtensions, maxSize, minLenght, limit},
        },
      },
    },
  } = await getClientInstance().query({query: VIDEO_UPLOAD_SETTINGS_QUERY});

  const extension = getFileExtension(file.name);
  if (extension && !includes(allowedExtensions, extension)) {
    trackMediaUploadError({
      errorType: 'uploadVideoInDisabledFormat',
      format: extension,
    });
    throw new Error(
      t('uploadButtonMessages', 'error.invalid_extension_video', {
        '{allowedExtensions}': getAllowedExtensionsAsString({
          extensions: allowedExtensions,
          upperCase: true,
        }),
      }),
    );
  }
  if (file.size > maxSize) {
    trackMediaUploadError({
      errorType: 'uploadVideoMaxSize',
    });
    throw new Error(
      t('uploadButtonMessages', 'error.video_too_large', {
        '{maximumVideoSize}': maxSize / BYTES_IN_MB,
      }),
    );
  }

  if (attachToUser && (await getUserVideoCount()) >= limit) {
    trackMediaUploadError({
      errorType: 'videoCountUploadsLimit',
    });
    throw new Error(
      t('uploadButtonMessages', 'maximum user videos reached', {
        '{maximumVideoAmount}': limit,
      }),
    );
  }

  if (filesInProgress.find(({size}) => file.size === size)) {
    throw new Error(t('uploadButtonMessages', 'text.video_is_uploading_now'));
  }

  await validateVideoDuration({file, minLenght});
}
