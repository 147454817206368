export default {
  TrackPaymentPageDescriptorMutation: {
    toServer: ({descriptor, descriptorText, method, orderId}) => {
      const params = {descriptor, descriptorText};

      if (orderId) {
        params.orderId = orderId;
      }

      return {
        method,
        params,
      };
    },

    fromServer: (response, error) => ({
      data: {
        trackPaymentPageDescriptor: {
          error: error || null,
          __typename: 'TrackPaymentPageDescriptorMutationResult',
        },
      },
    }),
  },
};
