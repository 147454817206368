import {BehaviorSubject} from 'rxjs';

const paymentInfoBin$ = new BehaviorSubject({});

/**
 * @desc Keeps and shares bins info with other components
 * @const {Object}
 */
const paymentBinsInfoShare = {
  setDataAndNotify({bin, cardTypeLogo, debitType, financialServicePlatform}) {
    // compare last value with new
    if (paymentInfoBin$.getValue()?.bin !== bin) {
      paymentInfoBin$.next({
        bin,
        cardTypeLogo,
        debitType,
        financialServicePlatform,
      });
    }
  },
  subscribe(fn) {
    return paymentInfoBin$.subscribe(fn);
  },
};

export default paymentBinsInfoShare;
