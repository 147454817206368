import type {Location} from 'history';
import get from 'lodash/get';

import routeVar from '@core/graphql/vars/routeVar';
import {sessionStorage} from '@core/utils/storage/storage';
import PopupSourceEvent from '@core/popup/constants/PopupSourceEvent';
import PopupService from '@core/popup/utils/PopupService';
import getUserAgentParser from '@core/utils/getUserAgentParser';

import {
  Action,
  UNKNOWN_PAGE_NAME,
  UNKNOWN_PAGE_STATE_KEY,
} from '../constants/history';
import {decodeLink, isHashed} from './hashedStaticLinks';

const isIOS = getUserAgentParser().getOS().name === 'iOS';

// Used storage because when comeback from other domain (when use paypal in inapp browser)
// It reloads page and not saved previous rout
const CURRENT_ROUTE_STORAGE_KEY = 'currentRoute';

// Min timeout for recalculate scroll y after page repaint
const SCROLL_TO_TOP_TIMEOUT = 25;

// Max calls count for safe using recursion
const MAX_SCROLL_TO_TOP_CALLS_COUNT = 5;

/**
 * User can scroll page for a little, like 30px, and navigation to next page.
 * In that case browser save scroll position if we have top navigation panel.
 * (Only for safari with top navigation bar, its old devices or custom user setting for safari on new devices)
 * Here we fix that bug with scroll.
 * Navigation appear every time because we scroll to -1 for Y.
 * On ios we can't scroll to 0 because it didn't scroll to top of the page.
 * For ios possible scrollTo 1px, it will scroll to top of the page but with gap in 1px
 * --------------------------------------------------------------------------
 * How it works:
 * 1. On route change we call window.scrollTo(0, -1)
 * 2. Now window.scrollY is -1
 * 3. Ios rerender navigation and now to scrollY ads top browser navigation bar height.
 *    navigation sometimes repaint if scrollY equal 0, so we also need trigger scrollTo(0, -1)
 * 4. On step 2 we call scrollTo(0, -1) in setTimeout, that will trigger scroll after repaint top navigation panel
 * 5. After that manipulations scrollY will be 0
 * --------------------------------------------------------------------------
 * @param callsCount - max recursion calls
 */
const fixIosScroll = (callsCount = 0) => {
  if (callsCount === MAX_SCROLL_TO_TOP_CALLS_COUNT) return;

  if (window.scrollY === -1) {
    setTimeout(() => fixIosScroll(callsCount + 1), SCROLL_TO_TOP_TIMEOUT);
  } else {
    window.scrollTo(0, -1);
  }
};

/**
 * Called on each history change.
 */
export default function handleRouteChange(
  {pathname, search, hash, state}: Location,
  action?: Action,
) {
  let nextUrl = `${pathname}${search}${hash}`;
  const decodedUrl = decodeLink(pathname);

  if (isHashed(decodedUrl)) {
    nextUrl = decodedUrl;
  }

  const route = {...routeVar()};
  if (action !== Action.REPLACE) {
    route.previous = sessionStorage.getItem(CURRENT_ROUTE_STORAGE_KEY);
  }
  if (action === Action.PUSH) {
    /**
     * Scroll reset in async way lets ability to save scroll position
     * before component will be unmounted {@see Recipients.componentWillUnmount}.
     * In other case scroll reset happens before componentWillUnmount.
     * y: -1 instead of 0 need for correct scroll to top and fix header with
     * fixed on top position. If set y:0, and scroll page on 10-20px down, next page
     * will be scrolled down as a prev page.
     */
    setTimeout(() => window.scrollTo(0, -1));

    if (isIOS) {
      setTimeout(fixIosScroll);
    }
  }

  if (action === Action.POP) {
    PopupService.closePopup(true, PopupSourceEvent.BY_ROUTING);
  }

  const isUnknownPage = get(state, UNKNOWN_PAGE_STATE_KEY);
  if (isUnknownPage) {
    route.current = `/${UNKNOWN_PAGE_NAME}${search}${hash}`;
  } else {
    route.current = nextUrl;
  }

  routeVar(route);
  sessionStorage.setItem(CURRENT_ROUTE_STORAGE_KEY, route.current);
}
