import {getClientInstance} from '@core/graphql/client';

import PROFILE_VIDEO_UPLOAD_DISABLED_QUERY from '../graphql/queries/profileVideoUploadDisabled.gql';
import type {ProfileVideoUploadDisabledQuery} from '../graphql/queries/profileVideoUploadDisabled';

/**
 * Check if profile video is disabled
 */
const isProfileVideoUploadDisabled = async (): Promise<boolean> => {
  const {data} =
    await getClientInstance().query<ProfileVideoUploadDisabledQuery>({
      query: PROFILE_VIDEO_UPLOAD_DISABLED_QUERY,
    });

  return !data.userFeatures.isProfileVideoUploadAllow;
};

export default isProfileVideoUploadDisabled;
