let paused = false;

/**
 * Controller for temporarily disabling click tracking.
 * Controller state switching doesn't generate state update and doesn't resend display or another tracks.
 */
const babciaTrackingEventsController = {
  get paused() {
    return paused;
  },

  pause() {
    paused = true;
  },

  resume() {
    paused = false;
  },
};

export default babciaTrackingEventsController;
