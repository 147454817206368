import {getClientInstance} from '@core/graphql/client';
import PAYMENT_BANNER_OPEN_IN_NEW_TAB_QUERY from '@core/payment/common/graphql/queries/paymentBannerOpenInNewTab.gql';
import GLOBAL_FREE_MESSAGES_QUERY from '@core/messenger/common/graphql/queries/globalFreeMessages.gql';

/**
 * Check if open pp in new window is allowed
 * @param {string| 'cache-first', 'network-only', 'cache-only'} fetchPolicy
 * @returns {Promise<boolean|*>}
 */
export default async (fetchPolicy = 'network-only') => {
  const client = getClientInstance();
  const {data} = await client.query({
    query: PAYMENT_BANNER_OPEN_IN_NEW_TAB_QUERY,
    fetchPolicy,
  });
  const globalFreeMessagesData = client.readQuery({
    query: GLOBAL_FREE_MESSAGES_QUERY,
  });
  const {available, count} =
    globalFreeMessagesData?.messenger?.initialData?.globalFreeMessages ?? {};

  if (available) {
    /*
      Rule for Hybrid BM only:
      PP should not open in new tab if there is no free messages left
    */
    return data?.userFeatures?.paymentBanner?.isOpenInNewWindow && count > 0;
  }

  return data?.userFeatures?.paymentBanner?.isOpenInNewWindow;
};
