import InAppBrowserName from './inAppBrowserName';

export const IN_APP_NAME = 'inAppBrowserName';
export const CURRENT_USER_ID = 'currentUserId';
export const ENABLE_REACT_PAY = 'enableReactPay';

/**
 * With pub_ prefix to don't set httponly param in nginx (front can't see cookie with httponly)
 */
export const COOKIE_NAME = 'pub_cookieBootstrapParams';

export const DEFAULT_PARAMS = {
  [IN_APP_NAME]: InAppBrowserName.NORMAL_BROWSER,
  [CURRENT_USER_ID]: null,
  [ENABLE_REACT_PAY]: true,
};
