import stripTags from '../string/stripTags';
import {fixEOL} from './lineBreak';

/**
 * Prepare message before send.
 * The server strips html tags, we strip them too to avoid display mismatches.
 * Is used for chat and status profile field.
 *
 * @param {?string} text
 * @return {string}
 */
const prepareMessage = (text) => fixEOL(stripTags(text || ''));

export default prepareMessage;
