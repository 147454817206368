enum HeaderAppearance {
  /**
   * Header is sticked to top of screen
   * All menu items are icons. In case if menu item is active is highlighted
   * with bar on bottom of it.
   *
   * Note that such property changes only MOB environment header. WEB header remain default one.
   */
  REGULAR = 'regular',

  /**
   * Header is sticked to bottom of screen. Items have no vertical separators.
   * All items are icons except of profile link - is an user avatar.
   * If menu item is active - icon being highlighted, or, if is avatar - highlighted
   * border will be added.
   *
   * Note that such property changes only MOB environment header. WEB header remain default one.
   */
  WITH_USER_PHOTO = 'withUserPhoto',

  /**
   * The same as "withUserPhoto" but with shorter list of items.
   * In particular, "like gallery" can be overwritten with "webcam" button if functionality is available
   * so we get "shorter" list. :)
   */
  WITH_USER_PHOTO_AND_SHORT_LIST = 'withUserPhotoAndShortList',

  /**
   * The same withUserPhotoAndShortList but includes disapprove icon
   */
  WITH_USER_PHOTO_AND_SHORT_LIST_AND_DISAPPROVE = 'withUserPhotoAndShortListAndDisapprove',

  /**
   * The same withUserPhotoAndShortList but includes exclude toolbar routes
   */
  WITH_USER_PHOTO_AND_SHORT_LIST_AND_EXCLUDE_ROUTES = 'withUserPhotoAndShortListAndExcludeRoutes',

  /**
   * Items don't have separators. Active item highlighted only with color.
   *
   * Note that such property changes only MOB environment header. WEB header remain default one.
   */
  BOUNDLESS = 'Boundless',

  /**
   * Plain header with active item highlighted with bright color
   */
  WITH_HIGHLIGHTED_ACTIVE = 'withHighlightedActive',

  /**
   * The same as above but with overwritten "search" icon (was changed to "home").
   */
  WITH_HIGHLIGHTED_ACTIVE_AND_HOME = 'withHighlightedActiveAndHome',

  /**
   * Plain header that highlights active item with only 1px bar on bottom.
   */
  WITH_UNDERLINED_ACTIVE = 'withUnderlinedActive',

  /**
   * Plain header that highlights active item with title
   */
  WITH_TEXT_ON_ACTIVE_ITEM = 'withTextOnActiveItem',
}

export default HeaderAppearance;
