import toArray from 'lodash/toArray';
import isUndefined from 'lodash/isUndefined';

/**
 * @see https://emojipedia.org/zero-width-joiner/
 * @param {string} symbol
 * @returns {boolean}
 */
const isZeroWidthJoiner = (symbol) => /\u200d/g.test(symbol);

/**
 * @param {string} message
 * @param {number} maxLength
 * @returns {string}
 */
const trimMessageToMaxLength = (message, maxLength) => {
  const messageAsArray = toArray(message);
  let trimmedMessage = message.substring(0, maxLength);

  /**
   * Do not use spread operator [...] to convert string to array
   * it splits composite emoji (for ex. emoji man with skin tone) and adds not as one array element, but as two
   */
  let trimmedMessageAsArray = toArray(trimmedMessage);
  let trimmedLastSymbol =
    trimmedMessageAsArray[trimmedMessageAsArray.length - 1];
  const trimmedLastSymbolLength = trimmedLastSymbol.length;
  const trimmedMessageAsArrayLength = trimmedMessageAsArray.length;
  const messageAsArrayLength = messageAsArray.length;
  const lengthDiff = messageAsArrayLength - trimmedMessageAsArrayLength;
  const decodedLastSymbol =
    messageAsArray[messageAsArrayLength - 1 - lengthDiff];

  // symbol before last
  const trimmedPenultSymbol =
    trimmedMessageAsArray[trimmedMessageAsArray.length - 2];
  const decodedPenultSymbol = messageAsArray[messageAsArray.length - 2];

  /**
   * If last symbol is zero width joiner delete them and emoji in front of it.
   * Also there is a case when js .substring method splitting the last emoji consisting of 2 characters or more.
   * E.g. leave in the field remaining at 3 characters insert the "witch emoji" with a dark skin color.
   * This emoji will turn light skinned.
   * To prevent such a bug, use the check trimmedMessageAsArray.length > messageAsArray.length
   */
  if (
    (isZeroWidthJoiner(trimmedLastSymbol) && trimmedLastSymbolLength === 1) ||
    trimmedMessageAsArrayLength > messageAsArrayLength ||
    (trimmedLastSymbol !== decodedLastSymbol &&
      trimmedPenultSymbol !== decodedPenultSymbol)
  ) {
    trimmedMessageAsArray = trimmedMessageAsArray.slice(0, -2);
    trimmedMessage = trimmedMessageAsArray.join('');
    trimmedLastSymbol = trimmedMessage[trimmedMessage - 1];
  }
  /**
   * for correct trimming message at the end when remaining is 0 and insert emoji in the middle of the message
   */
  if (
    !isUndefined(trimmedLastSymbol) &&
    trimmedLastSymbol !== decodedLastSymbol &&
    decodedLastSymbol.length > 1
  ) {
    trimmedMessageAsArray = trimmedMessageAsArray.slice(0, -1);
    trimmedMessage = trimmedMessageAsArray.join('');
  }

  return trimmedMessage;
};

export default trimMessageToMaxLength;
