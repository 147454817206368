/**
 * For decline pages
 */
export const DECLINE_VIA = 'inpage_decline';

/**
 * For decline pages from CRM letters
 */
export const REPEAT_DECLINE_VIA = 'repeat_inpage_decline';

export const SEPA_OFF = 'sepa_off';

export const INPAGE_DECLINE_VIAS = [REPEAT_DECLINE_VIA, DECLINE_VIA];

/**
 * The following set of 'via' causes an error message
 */
export default [SEPA_OFF, REPEAT_DECLINE_VIA, DECLINE_VIA];
