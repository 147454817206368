import 'core-js/stable';

/**
 * Polyfills for Explorer support. Because webpack doesn't polyfill them.
 * @see https://github.com/zloirock/core-js/issues/354
 * @see https://github.com/roderickhsiao/react-in-viewport#polyfill
 */
import 'events-polyfill';
import 'element-matches';
import 'intersection-observer';

/**
 Necessary for the correct operation of rxjs when rxjs is imported and used before apollo client.
 This is related to `Symbol.observable`, specifically that rxjs, if the value is not present, uses
 the default value '@@observable' and considers it during subscription (rxjs/_esm5/internal/util/subscribeTo.js).
 Meanwhile, ApolloClient, specifically zen-observable-ts/module.js, sets the value for `Symbol.observable` if it's absent.
 This creates a situation where different instances are created, leading to an error.
 @see rxjs/_esm5/internal/util/subscribeTo.js
 @see rxjs/src/internal/symbol/observable.ts
 @see zen-observable-ts/module.js
 */
if (typeof Symbol === 'function') {
  // @ts-expect-error -- it is readonly in rxjs, but `zen-observable-ts/module.js` sets it the same way.
  Symbol.observable = Symbol('observable');
}
