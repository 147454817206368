import omit from 'lodash/omit';

/**
 * Just helper method for removing unneeded typename from object
 */
const removeTypename = <T extends {__typename?: string}>(data: T) =>
  omit(data, ['__typename']);

type OmitTypenameRecursive<T> = T extends (infer ElementType)[]
  ? OmitTypenameRecursive<ElementType>[]
  : T extends object
    ? Omit<{[K in keyof T]: OmitTypenameRecursive<T[K]>}, '__typename'>
    : T;

/**
 * The same as above, but recursive.
 */
export const removeTypenameRecursively = <T>(
  data: T,
): OmitTypenameRecursive<T> => {
  if (!data) {
    return data as OmitTypenameRecursive<T>;
  }
  if (Array.isArray(data)) {
    return data.map(removeTypenameRecursively) as OmitTypenameRecursive<T>;
  }
  if (typeof data === 'object') {
    const o = removeTypename(data);
    Object.keys(o).forEach((key) => {
      o[key] = removeTypenameRecursively(o[key]);
    });
    return o as OmitTypenameRecursive<T>;
  }
  return data as OmitTypenameRecursive<T>;
};

export default removeTypename;
