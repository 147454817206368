import {getClientInstance} from '@core/graphql/client';
import VIDEO_FRAGMENT from '@core/user/profile/current/graphql/fragments/video.gql';

/**
 * Updates apollo cache data of VideoType
 * @param {string} id - video id
 * @param {object} data
 */
export default function updateVideoCache({id, ...data}) {
  const client = getClientInstance();
  const fragment = {
    fragment: VIDEO_FRAGMENT,
    id: `VideoType:${id}`,
  };
  const video = client.readFragment(fragment);
  client.writeFragment({
    ...fragment,
    data: {...video, ...data},
  });
}
