import t from '@core/translations/translate';

import getPackagePeriodRepeatText from './getPackagePeriodRepeatText';
import getPackagePeriodInWeeks from './getPackagePeriodInWeeks';

/**
 * Set active package currency, amount, amount_repeat to package description text
 * @param {String} translateMessage - description text
 * @param {Object} packagePricingDetails - package params
 * @param {Number} interval - selected package interval
 * @param {Object} repeatPackage - repeat pacakge data
 * @param {Boolean} repeatPeriodInWeeks - show repeat period in weeks (for template 'oneStepClear')
 * @param {Number} quantity - selected package quantity
 * @return {String}
 */
const setParamsToDescriptionText = ({
  translateMessage,
  packagePricingDetails,
  interval,
  repeatPackage,
  repeatPeriodInWeeks,
  quantity,
}) => {
  const {packagePriceData, packageDiscountPriceData} = packagePricingDetails;

  const amountRepeat = repeatPackage?.price?.formatted;

  const {formatted, currency} = packagePriceData.fullPrice;
  const {formatted: formatedDiscount} =
    packageDiscountPriceData?.fullPrice ?? {};
  const discountedPriceAmount =
    formatedDiscount?.shortPrice ?? formatedDiscount?.amount;

  let periodRepeat = '';

  if (repeatPackage) {
    periodRepeat = repeatPeriodInWeeks
      ? t('paymentPage', 'text.weeks_period', {
          '{n}': getPackagePeriodInWeeks(repeatPackage.bits),
        })
      : getPackagePeriodRepeatText(repeatPackage.type, repeatPackage.bits);
  }

  return translateMessage
    .replace(/{currency}/g, currency.prefix || currency.suffix)
    .replace(
      /{amount_repeat}/,
      amountRepeat?.shortPrice || amountRepeat?.amount || '',
    )
    .replace(/{period_repeat}/, periodRepeat)
    .replace(
      /{amount}/,
      discountedPriceAmount || formatted?.shortPrice || formatted?.amount,
    )
    .replace(/{dayInterval}/, String(interval))
    .replace(/{weekInterval}/, getPackagePeriodInWeeks(interval / 28))
    .replace(/{quantity}/, quantity);
};

export default setParamsToDescriptionText;
