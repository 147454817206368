import createTranslationsMap from '@core/translations/createTranslationsMap';
import logger from '@core/logger';

/**
 * @param {String} key
 * @param {Object|Null} params
 * @return {String}
 */
const getPayButtonTranslate = (key, params = {}) => {
  const PAYMENT_BUTTON_TRANSLATES = createTranslationsMap((t) => ({
    'text.continue': t('paymentPage', 'text.continue'),
    'text.processing': t('paymentPage', 'text.processing'),
    'text.get_it_now': t('remarketingPopup', 'text.get_it_now'),
    'button.pay_now': t('paymentPage', 'button.pay_now'),
    'button.order_now_for_payment': t(
      'paymentPage',
      'button.order_now_for_payment',
    ),
    'button.add_to_subscription': t(
      'paymentPage',
      'button.add_to_subscription',
    ),
    'button.i_accept_and_pay': t('paymentPage', 'button.i_accept_and_pay'),
    'button.microIncognito': t('paymentPage', 'button.microIncognito'),
    'button.flirtAlarm': t('paymentPage', 'button.flirtAlarm'),
    'button.sexAlarm': t('paymentPage', 'button.sexAlarm'),
    'button.topInChat': t('paymentPage', 'button.topInChat'),
    'button.superBoost': t('paymentPage', 'button.superBoost'),
    'button.priorityMan': t('paymentPage', 'button.priorityMan'),
    'button.moreLikes': t('paymentPage', 'button.moreLikes'),
    'button.freeCommunication': t('paymentPage', 'button.freeCommunication'),
    'button.get_it': t('remarketingPopup', 'button.get_it'),
    'button.agree_and_continue': t('paymentPage', 'button.agree_and_continue'),
    'button.get_free_access': t('paymentPage', 'button.get_free_access'),
    'button.continue_with_card': t('paymentPage', 'button.continue_with_card'),
    'button.pay_with_paypal': t('paymentPage', 'button.pay_with_paypal'),
    'button.pay_with_card': t('paymentPage', 'button.pay_with_card'),
    'button.make_payment': t('funnelPaid', 'button.make_payment', params),
    'text.continue_with_trial': t(
      'paymentPage',
      'text.continue_with_trial',
      params,
    ),
    'text.continue_with_special_offer': t(
      'paymentPage',
      'text.continue_with_special_offer',
    ),
    'button.get_special_offer_with_card': t(
      'paymentPage',
      'button.get_special_offer_with_card',
    ),
    'button.pay_price_with_card': t(
      'paymentPage',
      'button.pay_price_with_card',
      params,
    ),
    'button.continue_with_ideal': t(
      'paymentPage',
      'button.continue_with_ideal',
    ),
    'button.continue_with_bancontact': t(
      'paymentPage',
      'button.continue_with_bancontact',
    ),
    'button.continue_with_eps': t('paymentPage', 'button.continue_with_eps'),
    'button.continue_with_sepa': t('paymentPage', 'button.continue_with_sepa'),
  }));

  const buttonTranslate = PAYMENT_BUTTON_TRANSLATES[key];

  if (!buttonTranslate) {
    logger.sendWarning(
      `[getPayButtonTranslate] LOST TRANSLATE: (feature: paymentPage, key: ${key})`,
    );

    return key;
  }

  return buttonTranslate;
};

export default getPayButtonTranslate;
