import type {FC} from 'react';
import {useMemo, useEffect} from 'react';

import useRedirectToDefaultPage from './useRedirectToDefaultPage';

declare const SERVER_ENVIRONMENT: boolean;

/**
 * useMemo instead of useEffect for SSR.
 * It allows to handle this redirect instead of rendering an empty page.
 */
const useCustomEffect = SERVER_ENVIRONMENT ? useMemo : useEffect;

/**
 * Redirect to default page
 */
const RedirectToDefaultPage: FC = () => {
  const redirectToDefaultPage = useRedirectToDefaultPage({replace: true});
  useCustomEffect(() => {
    redirectToDefaultPage();
  }, [redirectToDefaultPage]);

  return null;
};

export default RedirectToDefaultPage;
