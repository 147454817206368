import {MSG} from '../constants/socketEventNames';

export default {
  ScreennameApproveSubscription: {
    incomingEvents: `${MSG}adminApproveTextAttributescreenname`,

    fromServer: ({status}) => ({
      data: {
        disapprove: {
          // Just convert 'declined' and 'approved' word to boolean.
          screenname: status === 'declined',
          __typename: 'DisapproveStatuses',
        },
      },
    }),
  },
};
