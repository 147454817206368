import type {FC, ReactElement, ReactNode} from 'react';
import React, {cloneElement, useMemo} from 'react';
import cn from 'classnames';

import isDeviceWithTouchScreen from '@core/utils/device/isDeviceWithTouchScreen';
import type {FormikFieldError} from '@core/types/formik';

import type {CSSModule} from '../../types';
import type {LabelProps} from '../label/Label';
import type {ErrorMessageProps} from './ErrorMessage';
import IconPosition from '../../constants/IconPosition';
import InputBorder from '../../constants/InputBorder';
import {Icon} from '../icon';
import Triangle from '../triangle/Triangle';
import baseCss from './InputFrame.css';

const renderIcon = (icon: ReactNode, css: CSSModule) => (
  <span className={cn(css.icon, baseCss.icon)}>
    {typeof icon === 'string' ? <Icon type={icon} /> : icon}
  </span>
);

export interface InputFrameProps {
  children: ReactElement;
  className?: string;
  disabled?: boolean;
  clickable?: boolean;
  editable?: boolean;
  label?: string;
  labelClassName?: string;
  errorClassName?: string;
  inlineLabel?: boolean;
  description?: string;
  error?: FormikFieldError;
  success?: string;
  inverse?: boolean;
  icon?: ReactNode;
  cvv?: boolean;
  iconPosition?: IconPosition;
  border?: InputBorder;
  prefix?: ReactNode;
  suffix?: ReactNode;
  spacedSuffix?: boolean;
  unspaced?: boolean;
  focused?: boolean;
  primary?: boolean;
  hasValue?: boolean;
  animatedLabel?: boolean;
  // if we had icon in multipleSelect we must hide it for showing counter
  showIcon?: boolean;
  withTriangle?: boolean;
  interactingAllowed?: boolean;
  showError?: boolean;
  largeTextLabel?: boolean;
  labelInContainer?: boolean;
}

const DEFAULT_CSS: CSSModule = {};

/**
 * @class InputFrame
 * @classdesc Just a 'dumb' component to render wrapper around input, textarea or select
 */
const InputFrame: FC<
  // `InputFrameProps` does not include some props to make it more suitable for `@phoenix/ui`.
  InputFrameProps & {
    css: CSSModule;
    labelComponent: FC<LabelProps>;
    errorMessage: FC<ErrorMessageProps>;
  }
> = ({
  css = DEFAULT_CSS,
  labelComponent: Label,
  errorMessage: ErrorMessage,
  children,
  className,
  labelClassName,
  errorClassName,
  inverse = false,
  disabled = false,
  clickable = false,
  editable = true,
  label,
  showIcon = true,
  description,
  error,
  showError = true,
  success,
  unspaced = false,
  focused = false,
  primary = false,
  hasValue = false,
  animatedLabel = false,
  icon,
  cvv = false,
  iconPosition = IconPosition.LEFT,
  suffix,
  spacedSuffix = true,
  prefix,
  withTriangle = false,
  interactingAllowed = true,
  inlineLabel = false,
  labelInContainer = false,
  border = InputBorder.DEFAULT,
  largeTextLabel = false,
}) => {
  const classNames = cn(
    baseCss.input,
    css.input,
    inlineLabel && [baseCss.inlineLabel, css.inlineLabel],
    animatedLabel && !inlineLabel && [baseCss.animated, css.animated],
    hasValue && [baseCss.hasValue, css.hasValue],
    error && [baseCss.error, css.error],
    success && baseCss.success,
    disabled && baseCss.disabled,
    !interactingAllowed && baseCss.nonInteractive,
    focused && [baseCss.focused, css.focused],
    primary && [baseCss.primary, css.primary],
    inverse && [baseCss.inverse, css.inverse],
    border && [baseCss[border], css[border]],
    unspaced && [baseCss.unspaced, css.unspaced],
    className,
  );

  const labelComponent = useMemo(() => {
    return (
      label && (
        <Label
          withLeftIcon={!!icon && iconPosition === IconPosition.LEFT}
          withRightIcon={!!icon && iconPosition === IconPosition.RIGHT}
          animated={!inlineLabel && animatedLabel}
          inline={inlineLabel}
          small={!inlineLabel} // Can't be small in any case, since it look like a strange thing :)
          hasValue={hasValue}
          focused={focused}
          error={error}
          inverse={inverse}
          large={largeTextLabel}
          className={cn(baseCss.label, css.label, labelClassName)}
        >
          {label}
        </Label>
      )
    );
  }, [
    Label,
    animatedLabel,
    css.label,
    error,
    focused,
    hasValue,
    icon,
    iconPosition,
    inlineLabel,
    inverse,
    label,
    labelClassName,
    largeTextLabel,
  ]);

  return (
    <div className={classNames} data-test="inputFrame">
      {!labelInContainer && labelComponent}
      <div
        className={cn(
          baseCss.container,
          isDeviceWithTouchScreen && baseCss.hoverable,
          css.container,
          label && !inlineLabel && animatedLabel && css.withAnimatedLabel,
          Boolean(icon) &&
            iconPosition === IconPosition.LEFT &&
            css.withLeftIcon,
          Boolean(icon) &&
            iconPosition === IconPosition.RIGHT &&
            css.withRightIcon,
        )}
      >
        {labelInContainer && labelComponent}
        {showIcon &&
          icon &&
          iconPosition === IconPosition.LEFT &&
          renderIcon(icon, css)}
        {prefix && (
          <div className={cn(baseCss.prefix, css.prefix)}>{prefix}</div>
        )}
        {cloneElement(children, {
          className: cn(
            children.props.className,
            baseCss.value,
            css.value,
            cvv && baseCss.cvv,
            clickable && baseCss.clickable,
          ),
          disabled: !editable || disabled,
        })}
        {suffix && (
          <div className={cn(spacedSuffix && css.suffix)}>{suffix}</div>
        )}
        {icon && iconPosition === IconPosition.RIGHT && renderIcon(icon, css)}
        {withTriangle && <Triangle />}
      </div>
      <div
        data-test="success"
        className={cn(css.successText, baseCss.successText)}
      >
        {success}
      </div>
      {showError && (
        <ErrorMessage
          error={error}
          className={cn(css.errorText, errorClassName)}
        />
      )}
      {description && (
        <div className={cn(css.description, baseCss.description)}>
          {description}
        </div>
      )}
    </div>
  );
};

export default InputFrame;
