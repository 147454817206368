import memoize from 'memoize-one';
import sortBy from 'lodash/sortBy';

import type {Photo} from '@core/user/photo/types';

/**
 * Correct displaying of photos, set primary photo first in list
 */
const getSortedUserPhotos = memoize(
  (primaryPhotoId: string, allPhotos: Photo[]): Photo[] =>
    sortBy(allPhotos, (photo) => photo.id !== primaryPhotoId),
);

export default getSortedUserPhotos;
