import {createContext} from 'react';

/**
 * Is used to provide/use scrollableRef.
 * Do not use ScrollableRefContext.Provider directly, use {@see ScrollableElement} instead.
 * If you need scrollableRef.current during render - use {@see ScrollableElementContext}
 * (it will rerender when element becomes available).
 */
const ScrollableRefContext = createContext(null);

export default ScrollableRefContext;
