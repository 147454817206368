import type {StoryMessageType} from '@core/stories/constants/storyMessageTypes';
import STORY_MESSAGE_TYPES from '@core/stories/constants/storyMessageTypes';

/**
 * Types of messages
 *
 * Available types:
 *   CHAT - standard message
 *   IMB_IMAGE - messenger image media type
 *   PMA_PHOTO - messenger pma image media type
 *   IMB_VIDEO - messenger video media type
 *
 *   Deprecated inbox types, which are not relevant now.
 *   Left only for correct work with data base types.
 *   TODO: Remove them, when message types will be updated in the data base.
 *
 *   MAIL_IMAGE - inbox image media (deprecated)
 *   MAIL_VIDEO - inbox video media (deprecated)
 *
 *   MULTIFLIRT - multi flirt
 *   VALENTINE_CARD - valentine's card
 *   OUTGOING - outgoing message
 *   INCOMING - incoming message
 *
 *   VIDEO_CHAT_INVITE - message about invitation to video call
 *   VIDEO_CHAT_REJECT - call was rejected by recipient
 *   VIDEO_CHAT_STOP - call was stopped by me
 *   VIDEO_CHAT_STOP_BY_TIMEOUT - call was rejected by timeout (no answer)
 *
 * Placed as separated module due to need to use it inside view, and model
 * Also, we have helper media types that unite another types in one. They are:
 *
 *   ALL_TYPES - listing of all possible types, in one variable
 *   IMAGE_TYPES - only images
 *   VIDEO_TYPES - only videos
 *   MEDIA_TYPES - all media types (video and images)
 *
 */
enum MessageType {
  CHAT = 'chat',
  PRIVATE_CHAT = 'privateChat',
  STICKER = 'sticker',
  GIFT = 'gift',
  IMB_IMAGE = 'imbImage',
  PMA_PHOTO = 'pmaPhoto',
  IMB_VIDEO = 'imbVideo',
  MAIL_IMAGE = 'mailImage',
  MAIL_VIDEO = 'mailVideo',
  MULTIFLIRT = 'multiFlirt',
  VALENTINE_CARD = 'valentineCard',
  PMA_REPLY = 'smartIcebreaker',
  FLIRTCAST = 'flirtcast',
  VIDEO_CHAT_INVITE = 'internalVideoInvite',
  VIDEO_CHAT_REJECT = 'internalVideoReject',
  VIDEO_CHAT_STOP = 'internalVideoStop',
  VIDEO_CHAT_STOP_BY_TIMEOUT = 'internalVideoStopByTimeout',
  VIDEO_CHAT_END = 'internalVideoEnd',
  PROMO_ADMIN_ON_LOGIN_COMPLETE = 'promo_admin.onLoginComplete',
  PRIVATE_PHOTO_REQUEST = 'privatePhotoRequest',
  PMA_PHOTO_RESPONSE = 'pmaPhotoReply',
  PMA_REPLY_WINK = 'mail_sent_pmareply_wink',
  PMA_REPLY_FAVOURITE = 'mail_sent_pmareply_favourite',
  PMA_REPLY_MAIL = 'mail_sent_pmareply_mail',
  PMA_REPLY_VIEW = 'mail_sent_pmareply_profile_view',
  PMA_REPLY_FLIRTCAST = 'mail_sent_pmareply_flirtcast',
  PMA_REPLY_LIKE = 'mail_sent_pmareply_user_like',
  TEXT_TEMPLATE = 'textTemplate',
  FIRST_MESSAGE_GENERATED = 'generatedFirstMessage',
  VIRTUAL_ASSISTANT_WELCOME = 'virtualAssistantWelcome',
}

export const PMA_TYPES: MessageType[] = [
  MessageType.PMA_REPLY_WINK,
  MessageType.PMA_REPLY_FAVOURITE,
  MessageType.PMA_REPLY_MAIL,
  MessageType.PMA_REPLY_VIEW,
  MessageType.PMA_REPLY_FLIRTCAST,
  MessageType.PMA_REPLY,
  MessageType.PMA_REPLY_LIKE,
];

export const IMAGE_TYPES: MessageType[] = [
  MessageType.IMB_IMAGE,
  MessageType.MAIL_IMAGE,
  MessageType.PMA_PHOTO,
  MessageType.PMA_PHOTO_RESPONSE,
];

export const VIDEO_TYPES: MessageType[] = [
  MessageType.IMB_VIDEO,
  MessageType.MAIL_VIDEO,
];

export const MEDIA_TYPES: MessageType[] = [...IMAGE_TYPES, ...VIDEO_TYPES];

export const VIDEO_CHAT_TYPES: MessageType[] = [
  MessageType.VIDEO_CHAT_INVITE,
  MessageType.VIDEO_CHAT_REJECT,
  MessageType.VIDEO_CHAT_STOP,
  MessageType.VIDEO_CHAT_STOP_BY_TIMEOUT,
  MessageType.VIDEO_CHAT_END,
];

export const ALL_TYPES: (MessageType | StoryMessageType)[] = [
  MessageType.CHAT,
  MessageType.TEXT_TEMPLATE,
  MessageType.MULTIFLIRT,
  MessageType.VALENTINE_CARD,
  MessageType.PMA_REPLY,
  MessageType.FLIRTCAST,
  ...MEDIA_TYPES,
  ...VIDEO_CHAT_TYPES,
  ...STORY_MESSAGE_TYPES,
];

export default MessageType;
