import t from './translate';

/**
 * Helper function to create translation object with getters like:
 * {
 *    get hairColor() {
 *      return t('profileBase', 'text.hair_color');
 *    },
 *    get eyeColor() {
 *      return t('profileBase', 'text.eye_color');
 *    }
 * }
 * @param {function} configFunction
 * @return {object} with translation getters
 */
const createTranslationsMap = (configFunction) => {
  const config = configFunction(
    (...args) =>
      () =>
        t(...args),
  );

  const getters = {};
  const props = {};
  Object.keys(config).forEach((key) => {
    const get = config[key];
    if (typeof get === 'function') {
      // use function as getter, configurable param need for redefine property. @see mergeTranslationMaps
      props[key] = {get, configurable: true};
    } else {
      // bypass custom props
      getters[key] = get;
    }
  });

  Object.defineProperties(getters, props);

  return getters;
};

export default createTranslationsMap;
