enum TargetUserPageAppearance {
  /**
   * Default list of items, with additional info in toolbar (sub-header).
   */
  AS_LIST_WITH_TOOLBAR = 'asListWithToolbar',

  /**
   * Like above, but adds blurred actions with user photo below it
   * Shoult be used in sync with search widget motification "allInCardWithBlurredActions"
   * @see seachWidgetAllInCardWithBlurredActionsComponents.js
   */
  AS_LIST_WITH_TOOLBAR_AND_BLURRED_ACTIONS = 'asListWithToolbarAndBlurredActions',

  /**
   * Designed as content that is "layered" above user info.
   * Content is separated in two tabs (media and info)
   */
  AS_LAYERED_WITH_TABS = 'asLayeredWithTabs',

  /**
   * Designed as content that is "layered" above user info.
   */
  AS_LAYERED_WITH_MEDIA = 'asLayeredWithMedia',

  /**
   * List of info items, with additional info in toolbar (sub-header)
   */
  AS_LIST_WITH_TOOLBAR_AND_PLAIN_MAIN_PHOTO = 'asListWithToolbarAndPlainMainPhoto',

  /**
   * Designed as content that is "layered" above user gallery.
   */
  AS_LAYERED_WITH_GALLERY = 'asLayeredWithGallery',

  /**
   * List of media items with load more counter and stories integrated into avatar
   */
  WITH_INTEGRATED_STORIES_AND_TOOLBAR = 'withIntegratedStoriesAndToolbar',
}

export default TargetUserPageAppearance;
