import {useQuery} from '@apollo/client';
import type {QueryResult} from '@apollo/client';

import type {ActiveSplitQuery} from './graphql/queries/activeSplit';
import ACTIVE_SPLIT_QUERY from './graphql/queries/activeSplit.gql';

type UseActiveSplitGroupResult = {
  result: boolean;
  loading: boolean;
};

/**
 * Hook to check whether the user is in the active split group or not
 */
const useActiveSplitGroup = (
  id: string,
  group: number,
): UseActiveSplitGroupResult => {
  const {data, loading}: QueryResult =
    useQuery<ActiveSplitQuery>(ACTIVE_SPLIT_QUERY);
  const {splitId, splitGroup} = data?.userFeatures?.activeSplit ?? {};

  return {
    result: id === splitId && group === splitGroup,
    loading,
  };
};

export default useActiveSplitGroup;
