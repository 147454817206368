import fetch from 'unfetch';

const spaCancelPayment = (): void => {
  fetch('/api/v1/pay/spaCancelPayment', {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
};

export default spaCancelPayment;
