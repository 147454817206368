/* global SERVER_ENVIRONMENT */

import memoizeOne from 'memoize-one';
import snakeCase from 'lodash/snakeCase';
import toUpper from 'lodash/toUpper';

import {getCookie} from '@core/utils/cookie';
import getParameter from '@core/application/utils/getParameter';

import LOCALES, {FALLBACK_LOCALE} from '../constants/locales';
import LOCALE_COOKIE_NAME from '../constants/localeCookieName';
import ENTRY_POINTS from '../../../../../constants/entryPoints';

/**
 * Retrieve locale for using correct translations.
 * @returns {string}
 */
const getLocale = memoizeOne(() => {
  /**
   * At the moment there is only english language on the server side
   * and also SpeedDating which uses SSR-ed pages
   * TODO: Need to update logic if new languages appear on SSR
   */
  if (
    SERVER_ENVIRONMENT ||
    getParameter('entry') === ENTRY_POINTS.SPEED_DATING
  ) {
    return FALLBACK_LOCALE;
  }

  const locale = getCookie(LOCALE_COOKIE_NAME);
  if (locale) return locale;

  // If there is no cookie, we try to get locale from browser settings
  const browserLocale = toUpper(
    window.navigator.userLanguage || window.navigator.language,
  );

  return (
    // Try to get exact match
    LOCALES[browserLocale] ||
    // Or if is a language variant from one language family - convert to our format
    LOCALES[snakeCase(browserLocale)] ||
    // Of if we don't have any variant supported, fallback to language family
    LOCALES[browserLocale.split('-')[0]] ||
    // If nothing found - just use English.
    FALLBACK_LOCALE
  );
});

export default getLocale;
