/**
 * Security logo list
 */
export default {
  AMEX: 'amEx',
  AMIPCI: 'amipci',
  BANCONTACT: 'bancontact',
  BCCARD: 'bccard',
  CARD: 'card',
  CARTASI: 'cartasi',
  CARTEBANCAIRE: 'carteBancaire',
  COMODO: 'comodo',
  DCI: 'dci',
  DEUTSCHEQUALITAT: 'deutschequalitat',
  DISCOVER: 'discover',
  DIRECTDEBIT: 'directDebit',
  ELECTRONICCASH: 'electroniccash',
  ELO: 'elo',
  HIPERCARD: 'hipercard',
  JCB: 'jcb',
  KLARNA: 'klarna',
  LETSENCRYPT: 'letsEncrypt',
  LOCK: 'lock',
  MASTERCARD: 'masterCard',
  MASTERCARDCOMPLIANCE: 'masterCardCompliance',
  MONEYBACK: 'moneyBack',
  MYBANK: 'mybank',
  POSTEPAY: 'postepay',
  PCICERTIFIED: 'pciCertified',
  SEPALASTSCHRIFT: 'sepalastschrift',
  TRUSTLY: 'trustly',
  VISA: 'visa',
  VISACARDCOMPLIANCE: 'visaCardCompliance',
  LAWSON: 'lawson',
  SEICOMART: 'seicomart',
  MINISTOP: 'ministop',
  FAMILYMART: 'familymart',
};
