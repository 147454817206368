import type {ExactKeys} from '@core/types/utils';
import type {StorageWrapper} from '@core/utils/storage/storage';

/**
 * Creates an object with getters and setters to simplify work with custom storage instances.
 *
 * Example:
 * ```typescript
 *   const storage = createStorageMap<{counter: number}>(sessionStorage, ['counter'], 'test');
 *   storage.counter = 5; // sets value and writes it to `sessionStorage.testcounter`.
 *   storage.counter = 5; // setting the same value again has no effect.
 *   storage.counter = null; // deletes the value from `sessionStorage.testcounter`.
 * ```
 */
export default function createStorageMap<T extends Record<string, unknown>>(
  storageInstance: StorageWrapper,
  keys: ExactKeys<T>,
  prefix: string = '',
): T {
  const cache: Partial<T> = {};
  const props: PropertyDescriptorMap = {};

  keys.forEach((prop) => {
    const storageKey = `${prefix}${prop}`;
    props[prop] = {
      get() {
        if (prop in cache) {
          return cache[prop];
        }
        cache[prop] = storageInstance.getItem(storageKey);
        return cache[prop];
      },
      set(value) {
        if (cache[prop] === value) return;
        cache[prop] = value;
        storageInstance.setItem(storageKey, value);
      },
      enumerable: true,
      configurable: true,
    };
  });

  return Object.create({}, props);
}
