import flatten from 'lodash/flatten';
import type {DocumentNode} from 'graphql';
import type {QueryOptions} from '@apollo/client';
import {useApolloClient} from '@apollo/client';
import {useRef, useState, useEffect} from 'react';

export type DocumentNodeOrOptions = DocumentNode | QueryOptions;

const getOptions = (queryOrOptions) =>
  queryOrOptions.query ? queryOrOptions : {query: queryOrOptions};

/**
 * Performs query/queries once only with single state change when they become loaded.
 * Does not react on params change.
 * Useful to execute few large queries instead of many small.
 */
const usePreloadQueries = (queries: DocumentNodeOrOptions[]): boolean => {
  const client = useApolloClient();

  const queriesToLoad = useRef([]);
  if (!queriesToLoad.current.length) {
    // Read queries to return `loading=false` on first render if possible.
    queriesToLoad.current = queries
      .map(getOptions)
      .filter((options) => !client.readQuery<unknown>(options));
  }

  const [loading, setLoading] = useState<boolean>(
    queriesToLoad.current.length > 0,
  );

  useEffect(() => {
    if (queriesToLoad.current.length) {
      Promise.all(
        queriesToLoad.current.map((options) =>
          client
            .query({errorPolicy: 'all', ...options})
            .then(({errors}) => errors || null)
            // to set `loading=false` even if network request failed
            .catch(() => null),
        ),
      ).then((results) => {
        const errors = flatten(results.filter(Boolean));
        /**
         * Do not set `loading=false` in case of ACL errors to prevent unneeded child components render.
         * Such error should be handled by `onError` link {@see createClient}.
         */
        if (!errors.some((error) => error.extensions?.category === 'ACL')) {
          setLoading(false);
        }
      });
    }
  }, [client]);

  return loading;
};

export default usePreloadQueries;
