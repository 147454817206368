import type {FC} from 'react';
import React from 'react';
import {Redirect} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import {PROTECT_TYPES} from '@core/massBlocked/constants/protectTypes';

type RedirectToCaptchaProps = {
  protectType: string;
  location?: {
    pathname: string;
  };
};

/**
 * Wrapper component around redirect.
 * Redirects to captcha route.
 */
const RedirectToCaptcha: FC<RedirectToCaptchaProps> = ({
  protectType,
  location,
}) => {
  let redirect = '';

  if (protectType === PROTECT_TYPES.VIEW_PROFILE_BAN && !isEmpty(location)) {
    redirect = `/?redirect=${location.pathname}`;
  }

  return (
    <Redirect to={`/user/massBlocked/protect/${protectType}${redirect}`} />
  );
};

export default RedirectToCaptcha;
