import type {FC, MouseEventHandler, MutableRefObject} from 'react';
import React, {type ReactNode} from 'react';
import cn from 'classnames';

import Triangle from '../triangle';
import Direction from '../../constants/Direction';
import css from './SelectInputContent.css';

export type SelectInputContentProps = {
  children: ReactNode;
  className?: string;
  active: boolean;
  inverse: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  /**
   * Ref is required for usage inside tooltip or popover, since it goes set in shadow.
   */
  innerRef?: MutableRefObject<HTMLDivElement>;
};

const SelectInputContent: FC<SelectInputContentProps> = ({
  children,
  active,
  className,
  inverse,
  innerRef,
  ...props
}) => {
  return (
    <div
      {...props}
      ref={innerRef}
      role="button"
      tabIndex={0}
      className={cn(className, css.wrapper)}
    >
      {children}
      <Triangle
        inverse={inverse}
        direction={active ? Direction.TOP : Direction.BOTTOM}
      />
    </div>
  );
};

export default SelectInputContent;
