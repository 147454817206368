import createTranslationsMap from '@core/translations/createTranslationsMap';

import TEXT_REASONS from './textReasons';
import MEDIA_REASONS from './mediaReasons';

export default createTranslationsMap((t) => ({
  // Text reasons
  [TEXT_REASONS.PERSONAL]: t('disapproveReason', 'text.personal'),
  [TEXT_REASONS.LINKS]: t('disapproveReason', 'text.links'),
  [TEXT_REASONS.OFFENSIVE]: t('disapproveReason', 'text.offensive'),
  [TEXT_REASONS.AGE_MISMATHING]: t('disapproveReason', 'text.ageMismathing'),
  [TEXT_REASONS.DRUGS]: t('disapproveReason', 'text.drugs'),
  [TEXT_REASONS.ESCORT]: t('disapproveReason', 'text.escort'),
  [TEXT_REASONS.GENDER_DIFFERENCE]: t(
    'disapproveReason',
    'text.genderDifference',
  ),
  [TEXT_REASONS.MEANINGLESS]: t('disapproveReason', 'text.meaningless'),
  [TEXT_REASONS.CURRENCY]: t('disapproveReason', 'text.currency'),

  // Media reasons
  [MEDIA_REASONS.ADVERTISING]: t('disapproveReason', 'text.advertising'),
  [MEDIA_REASONS.CELEBRITIES]: t('disapproveReason', 'text.celebrities'),
  [MEDIA_REASONS.CORRESPONDENCE]: t('disapproveReason', 'text.correspondence'),
  [MEDIA_REASONS.ESCORT_SERVICE]: t('disapproveReason', 'text.escortService'),
  [MEDIA_REASONS.GENDER_MISMATCH]: t('disapproveReason', 'text.genderMismatch'),
  [MEDIA_REASONS.GROUP_VIDEO]: t('disapproveReason', 'text.groupVideos'),
  [MEDIA_REASONS.GROUP_PHOTO]: t('disapproveReason', 'text.groupPhotos'),
  [MEDIA_REASONS.GROUP_MEDIA]: t('disapproveReason', 'text.groupMedia'),
  [MEDIA_REASONS.NO_PEOPLE]: t('disapproveReason', 'text.noPeople'),
  [MEDIA_REASONS.OFFCENSE]: t('disapproveReason', 'text.offense'),
  [MEDIA_REASONS.PERSONAL_DETAILS]: t(
    'disapproveReason',
    'text.personalDetails',
  ),
  [MEDIA_REASONS.PERSONAL_DETAILS_JOINED]: t(
    'disapproveReason',
    'text.personalDetailsJoined',
  ),
  [MEDIA_REASONS.SCAM]: t('disapproveReason', 'text.scam'),
  [MEDIA_REASONS.SEXUAL]: t('disapproveReason', 'text.sexual'),
  [MEDIA_REASONS.SPAM]: t('disapproveReason', 'text.spam'),
  [MEDIA_REASONS.VIOLENCE_PROFILE]: t(
    'disapproveReason',
    'text.violenceProfile',
  ),
  [MEDIA_REASONS.VIOLENCE_MESSAGES]: t(
    'disapproveReason',
    'text.violenceMessages',
  ),
  [MEDIA_REASONS.UNDER18]: t('disapproveReason', 'text.under_18'),
}));
