enum ChatMessageAppearance {
  /**
   * Message with info on top position and photo with overlay
   */
  REGULAR_MESSAGE = 'regularMessage',

  /**
   * Message with increased horizontal spacing
   */
  REGULAR_MESSAGE_WITH_INCREASED_SPACING = 'regularMessageWithIncreasedSpacing',

  /**
   * Message with info on bottom position and photo with blur
   */
  BLURRED_WITH_INFO_ON_BOTTOM = 'blurredWithInfoOnBottom',

  /**
   * The same as regular message, but text upgrade widget is "light" instead of "success" one.
   */
  REGULAR_MESSAGE_WITH_LIGHT_UPGRADE = 'regularMessageWithLightUpgrade',

  /**
   * The same as regular message, but user widget must be blurred and without any action.
   */
  REGULAR_MESSAGE_WITH_VIEW_PERMISSION = 'regularMessageWithViewPermission',
}

export default ChatMessageAppearance;
