import type {FC, HTMLAttributes, ReactNode} from 'react';
import React from 'react';
import cn from 'classnames';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import SpacingSize from '../../constants/SpacingSize';
import type Align from '../../constants/Align';
import css from './Row.css';

export interface RowProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
  trackingName?: string;
  space?: SpacingSize;
  adaptive?: boolean;
  reverse?: boolean;
  align?: Align;
  horizontal?: boolean;
  flexibleWidth?: boolean;
  equalWidth?: boolean;
  noShrink?: boolean;
}

/**
 * Widget for composing forms in terms of separation of fields (blocks)
 */
const Row: FC<RowProps> = ({
  children,
  className,
  trackingName,
  space = SpacingSize.NORMAL,
  align,
  adaptive = true,
  horizontal = false,
  reverse = false,
  flexibleWidth = true,
  equalWidth,
  noShrink,
  ...props
}) => {
  const content = (
    <div
      className={cn(
        css.row,
        adaptive && css.adaptive,
        css[space],
        className,
        flexibleWidth && css.flexibleWidth,
        equalWidth && css.equalWidth,
        noShrink && css.noShrink,
        horizontal && css.horizontal,
        reverse && css.reverse,
        align && css[align],
      )}
      {...props}
    >
      {children}
    </div>
  );

  return trackingName ? (
    <AddBabciaUBTracking trackingName={trackingName}>
      {content}
    </AddBabciaUBTracking>
  ) : (
    content
  );
};

export default Row;
