import {useQuery} from '@apollo/client';

import DATE_FORMAT_QUERY from '@core/date/graphql/queries/dateFormat.gql';

import ALL_ACTIVITIES_QUERY from '../graphql/queries/allActivities.gql';
import ENABLED_ACTIVITY_TYPES_QUERY from '../graphql/queries/enabledActivityTypes.gql';

/**
 * Return data props for ActivityProvider
 * @see ActivityProvider
 * @see @phoenix/activity/containers/ActivityProvider.js
 * @param allowedToStart
 */
const useDataForActivityProvider = (allowedToStart) => {
  // Get cached custom mapping activities
  const {
    data: {allActivities} = {},
    loading: activitiesLoading,
    error: activitiesError,
    client,
  } = useQuery(ALL_ACTIVITIES_QUERY, {
    // Without this flag 'fetchMore' doesn't change 'loading' state
    notifyOnNetworkStatusChange: true,
    skip: !allowedToStart,
  });

  const {
    data: {userFeatures} = {},
    loading: enabledLoading,
    error: enabledError,
  } = useQuery(ENABLED_ACTIVITY_TYPES_QUERY, {skip: !allowedToStart});

  const {
    data: {site} = {},
    loading: dateFormatLoading,
    error: dateFormatError,
  } = useQuery(DATE_FORMAT_QUERY, {skip: !allowedToStart});

  const loading = activitiesLoading || enabledLoading || dateFormatLoading;

  return {
    client,
    loading,
    error: activitiesError || enabledError || dateFormatError,
    /**
     * Provider can be in 'disabled' mode (if user isn't confirmed or anonymous) or it can be
     * in 'loading' state. We should avoid making requests in this case.
     */
    requestAllowed: allowedToStart && !loading,
    dateFormat: site && site.dateFormat,
    enabledTypes: userFeatures && userFeatures.enabledActivityTypes,
    activities: allActivities,
  };
};

export default useDataForActivityProvider;
