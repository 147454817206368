import React, {Suspense, lazy, forwardRef} from 'react';
import type {Map as LeafletMap} from 'leaflet';

import type {MapProps} from './types';

const MapComponent = lazy(
  () => import(/* webpackChunkName: 'leafletMapComponent' */ './Map'),
);

const LazyMap = forwardRef<LeafletMap, MapProps>((props, ref) => {
  return (
    <Suspense fallback={null}>
      <MapComponent {...props} ref={ref} />
    </Suspense>
  );
});
export default LazyMap;
