import type {Observable} from 'rxjs';
import {from} from 'rxjs';
import {map} from 'rxjs/operators';

import getDisapprovePhotoSubscription from '@core/user/profile/current/utils/getDisapprovePhotoSubscription';

import NOTIFICATION_TYPES from '../constants/notificationTypes';
import type {CommonNotification} from '../types';

const photoApproveAndDisapproveDataSource =
  (): Observable<CommonNotification> =>
    // 'from' is used to convert zen-observable into RxJS compatible observable
    from(getDisapprovePhotoSubscription()).pipe(
      map(({data: {disapprove}}) => ({
        type: disapprove.photo
          ? NOTIFICATION_TYPES.DISAPPROVE_PHOTO
          : NOTIFICATION_TYPES.APPROVE_PHOTO,
      })),
    );

export default photoApproveAndDisapproveDataSource;
