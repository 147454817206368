import {useContext} from 'react';

import useEventCallback from '../../../utils/react/useEventCallback';
import type {SwipeDirection} from '../constants/babciaDataTypes';
import {Event} from '../constants/babciaDataTypes';
import BabciaScopedContext from '../containers/BabciaScopedContext';
import formatTrackingName from './formatTrackingName';
import sendUBEventToBabcia from './sendUBEventToBabcia';

const useBabciaSwipeTrack = (): ((swipeDirection: SwipeDirection) => void) => {
  const {trackingLabel, context, category, conceptId, eventType} =
    useContext(BabciaScopedContext);

  return useEventCallback((swipeDirection) => {
    if (!swipeDirection) {
      return;
    }

    sendUBEventToBabcia({
      category,
      context,
      conceptId,
      eventType,
      event: Event.SWIPE,
      value: swipeDirection,
      label: formatTrackingName(trackingLabel),
    });
  });
};

export default useBabciaSwipeTrack;
