enum RecipientsAppearance {
  /**
   * Recipients list with tabs in toolbar on mob and filter on web
   */
  WITH_TABS_OR_FILTER = 'withTabsOrFilter',

  /**
   * Recipients list with carousel and tabs on mob and filter on web
   */
  WITH_CAROUSEL_AND_TABS = 'withCarouselAndTabs',
}

export default RecipientsAppearance;
