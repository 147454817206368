import {getClientInstance} from '@core/graphql/client';

import PHOTO_UPLOAD_DISABLED_QUERY from '../graphql/queries/photoUploadDisabled.gql';

/**
 * Check if upload is disabled
 * @return {Promise<Boolean>}
 */
export default async function isPhotoUploadDisabled() {
  const {data} = await getClientInstance().query({
    query: PHOTO_UPLOAD_DISABLED_QUERY,
    fetchPolicy: 'network-only',
  });
  return data.userFeatures.isPhotoUploadDisabled;
}
