import type {Observable} from 'rxjs';
import {from} from 'rxjs';

import {getClientInstance} from '@core/graphql/client';
import parsePathname from '@core/utils/url/parsePathname';
import {DECLINE_VIA} from '@core/payment/common/constants/declineVia';
import type {Action} from '@core/types/graphql';

import LAST_3DS_PAYMENT_STATUS_QUERY from '../graphql/queries/last3dsPaymentStatus.gql';
import type {
  Last3dsPaymentStatusQuery,
  Last3dsPaymentStatusQueryVariables,
} from '../graphql/queries/last3dsPaymentStatus';
import PAYMENT_STATUSES from '../constants/paymentStatuses';

export type Check3dsStatusResult = {
  status: string;
  redirectUrl?: string;
  failPageLogic?: string;
};

const check3dsStatus = async (
  action: Action,
  orderId: string,
): Promise<Check3dsStatusResult> => {
  const {
    data: {
      payment: {
        last3dsPaymentStatus: status,
        redirectUrl: {afterPaymentSuccess, afterPaymentFail},
      },
    },
  } = await getClientInstance().query<
    Last3dsPaymentStatusQuery,
    Last3dsPaymentStatusQueryVariables
  >({
    query: LAST_3DS_PAYMENT_STATUS_QUERY,
    variables: {action, orderId},
    fetchPolicy: 'network-only',
  });

  if (status === PAYMENT_STATUSES.SUCCESS) {
    return {
      status,
      redirectUrl: afterPaymentSuccess,
    };
  }

  if (status === PAYMENT_STATUSES.FAIL) {
    const failPathname = parsePathname(afterPaymentFail);
    const failPageLogic = failPathname.options?.via || DECLINE_VIA;

    return {
      status,
      redirectUrl: afterPaymentFail,
      failPageLogic,
    };
  }

  return {status};
};

export const check3dsStatusObservable = (
  action: Action,
  orderId: string,
): Observable<Check3dsStatusResult> =>
  from<Check3dsStatusResult>(check3dsStatus(action, orderId));

export default check3dsStatus;
