import {useMemo} from 'react';
import isNull from 'lodash/isNull';
import {useReactiveVar} from '@apollo/client';

import {WALLET_METHODS} from '@core/payment/common/constants/paymentMethods';
import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import useActivePackage from '@core/payment/widgets/package/utils/useActivePackage';
import isOneClickAllowedVar from '@core/graphql/vars/isOneClickAllowedVar';
import isOneClickUsedVar from '@core/graphql/vars/isOneClickUsedVar';
import isCardPaymentType from '@core/payment/forms/card/utils/isCardPaymentType';

import useApplePaySettings from '../../widgets/applePay/utils/useApplePaySettings';
import useGooglePaySettings from '../../widgets/googlePay/utils/useGooglePaySettings';
import usePaypalSettings from '../../widgets/paypal/utils/usePaypalSettings';
import useIsGooglePayCvvForm from '../../forms/googlePay/utils/useIsGooglePayCvvForm';
import useAltOneClick from '../../forms/common/utils/useAltOneClick';
import PAYMENT_ACTIONS from '../constants/paymentActions';
import PAYMENT_SOURCES from '../constants/paymentSources';

const useWalletAllowedMethods = () => {
  const {activePackage} = useActivePackage();
  const {action, source} = usePaymentParams();
  const isOneClickUsed = useReactiveVar(isOneClickUsedVar);
  const isCardOneClickAllowed = useReactiveVar(isOneClickAllowedVar);
  const isGooglePayCvvForm = useIsGooglePayCvvForm();

  const {isAltOneClick, loading: loadingAltOneClick} = useAltOneClick(
    activePackage?.method,
  );

  const {loading: applePayLoading, canMakeApplePayPayments} =
    useApplePaySettings();

  const {loading: googlePayLoading, canMakeGooglePayPayments} =
    useGooglePaySettings();

  const {
    loading: paypalLoading,
    isAllowed: isAllowedPaypalPayment,
    paymentMethod: paypalPaymentMethod,
    isOneClickAllowed: isPaypalOneClickAllowed,
  } = usePaypalSettings();

  const loading =
    paypalLoading || applePayLoading || googlePayLoading || loadingAltOneClick;

  const isOneClickAllowed = isCardOneClickAllowed || isPaypalOneClickAllowed;
  const isCardPaymentMethod = isCardPaymentType(activePackage?.method);

  return useMemo(() => {
    const methods = [];

    if (
      loading ||
      isGooglePayCvvForm ||
      (isCardPaymentMethod &&
        PAYMENT_SOURCES.PAYMENT_PAGE === source &&
        /**
         * When one click allowed and payment form hidden
         */
        ((isOneClickAllowed && isOneClickUsed) ||
          /**
           * When one click allowed but payment form not initialized (1st step on two steps payment page).
           * isOneClickUsedVar - initialized inside PaymentCardFormBase
           */
          (isOneClickAllowed && isNull(isOneClickUsed)) ||
          /**
           * When one click data is loading and not initialized on payment page
           */
          isNull(isOneClickAllowed))) ||
      isAltOneClick
    ) {
      return {loading, methods};
    }

    if (isAllowedPaypalPayment && action !== PAYMENT_ACTIONS.MICRO_FEATURES) {
      methods.push(paypalPaymentMethod);
    }

    if (canMakeApplePayPayments) {
      methods.push(WALLET_METHODS.APPLE_PAY);
    }

    if (canMakeGooglePayPayments) {
      methods.push(WALLET_METHODS.GOOGLE_PAY);
    }

    return {loading, methods};
  }, [
    source,
    action,
    isOneClickAllowed,
    loading,
    isAltOneClick,
    isCardPaymentMethod,
    isOneClickUsed,
    isGooglePayCvvForm,
    isAllowedPaypalPayment,
    canMakeApplePayPayments,
    canMakeGooglePayPayments,
    paypalPaymentMethod,
  ]);
};

export default useWalletAllowedMethods;
