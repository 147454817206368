import type {HTMLAttributes, Ref, ReactNode} from 'react';
import React, {forwardRef} from 'react';
import cn from 'classnames';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import SpacingSize from '../../constants/SpacingSize';
import SpacingDirection from '../../constants/SpacingDirection';
import css from './Spacing.css';

export interface SpacingProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  size?: SpacingSize;
  direction?: SpacingDirection;
  innerRef?: Ref<HTMLDivElement>;
  className?: string;
  trackingName?: string;
  oneSidedMargin?: boolean;
  onlyTop?: boolean;
  onlyBottom?: boolean;
  onlyLeft?: boolean;
  onlyRight?: boolean;
  withoutTop?: boolean;
  withoutBottom?: boolean;
  withoutLeft?: boolean;
  withoutRight?: boolean;
  /**
   * Apply adaptive spacings for different devices e.g. 40px on WEB will be 20px
   * for avoiding writing massive ternary operators in JSX
   */
  adaptive?: boolean;
  /**
   * Since it can be displayed in flexbox
   * but in most of the cases it must fill all the area
   */
  stretch?: boolean;
}

/**
 * @class Spacing
 * @classdesc Wrapping block that adds spaces from all (or only vertical or horizontal) sides
 */
const Spacing = forwardRef<HTMLDivElement, SpacingProps>(
  (
    {
      children,
      className,
      trackingName,
      size = SpacingSize.NORMAL,
      direction = SpacingDirection.BOTH,
      oneSidedMargin,
      onlyTop,
      onlyBottom,
      onlyLeft,
      onlyRight,
      withoutTop,
      withoutBottom,
      withoutLeft,
      withoutRight,
      adaptive = true,
      stretch = true,
      innerRef,
      ...props
    },
    ref,
  ) => {
    const content = (
      <div
        {...props}
        ref={ref || innerRef}
        className={cn(
          size && css[size],
          css[direction],
          adaptive && css.adaptive,
          stretch && css.stretch,
          oneSidedMargin && css.oneSided,
          [onlyBottom, onlyLeft, onlyRight, withoutTop].some(Boolean) &&
            css.withoutTop,
          [onlyTop, onlyLeft, onlyRight, withoutBottom].some(Boolean) &&
            css.withoutBottom,
          [onlyBottom, onlyTop, onlyRight, withoutLeft].some(Boolean) &&
            css.withoutLeft,
          [onlyBottom, onlyLeft, onlyTop, withoutRight].some(Boolean) &&
            css.withoutRight,
          className,
          css.spacing,
        )}
      >
        {children}
      </div>
    );

    return trackingName ? (
      <AddBabciaUBTracking trackingName={trackingName}>
        {content}
      </AddBabciaUBTracking>
    ) : (
      content
    );
  },
);

export default Spacing;
