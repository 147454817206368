import {useQuery} from '@apollo/client';

import PAYMENT_MODEL_QUERY from '../graphql/queries/paymentModel.gql';

/**
 * Global approach for making payments in terms of entire product. Possible values:
 * - coins
 * - membership
 * @return {{model: String|Null, loading: Boolean}}
 */
const usePaymentModel = () => {
  const {loading, data} = useQuery(PAYMENT_MODEL_QUERY);

  return {
    model: data?.payment?.model || null,
    loading,
  };
};

export default usePaymentModel;
