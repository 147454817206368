import {PopperPlacement} from '../../../constants';

export const Y_AXIS_POSITIONS = [
  PopperPlacement.TOP,
  PopperPlacement.TOP_START,
  PopperPlacement.TOP_END,

  PopperPlacement.BOTTOM,
  PopperPlacement.BOTTOM_START,
  PopperPlacement.BOTTOM_END,
];

export const X_AXIS_POSITIONS = [
  PopperPlacement.RIGHT,
  PopperPlacement.RIGHT_START,
  PopperPlacement.RIGHT_END,

  PopperPlacement.LEFT,
  PopperPlacement.LEFT_START,
  PopperPlacement.LEFT_END,
];
