import {ACTIVITY_TYPES} from '@core/activity/constants/notificationTypes';

export const TYPE = {
  PMA_VIEW: 'pma_view',
  PMA_LIKE: 'pma_like',
  PMAREPLY_MATCH: 'pmaMatch',
  PMAREPLY_VIEW: 'pmaView',
  PMAREPLY_LIKE: 'pmaAddUserLike',
  VIEW: ACTIVITY_TYPES.VIEW,
  FAVOURITE: ACTIVITY_TYPES.FAVOURITE,
  MATCH: ACTIVITY_TYPES.MATCH,
  VIEW_MATCH: ACTIVITY_TYPES.VIEW_MATCH,
  LIKE: ACTIVITY_TYPES.ADD_USER_LIKE,
};

export const ICONS = {
  [TYPE.LIKE]: 'heart',
  [TYPE.PMA_LIKE]: 'heart',
  [TYPE.PMAREPLY_LIKE]: 'heart',
  [TYPE.FAVOURITE]: 'favorite',
  [TYPE.MATCH]: 'heart-match',
  [TYPE.PMAREPLY_MATCH]: 'heart-match',
  [TYPE.VIEW]: 'eye',
  [TYPE.VIEW_MATCH]: 'profile-heart',
  [TYPE.PMA_VIEW]: 'eye',
  [TYPE.PMAREPLY_VIEW]: 'eye',
};

export const SUBSCRIBE_TYPES = [
  ACTIVITY_TYPES.VIEW,
  ACTIVITY_TYPES.ADD_USER_LIKE,
  ACTIVITY_TYPES.FAVOURITE,
  ACTIVITY_TYPES.PMAREPLY_FAVOURITE,
  ACTIVITY_TYPES.PMAREPLY_VIEW,
  ACTIVITY_TYPES.MATCH,
  ACTIVITY_TYPES.VIEW_MATCH,
];

export const TRANSFORMED_LAST_ACTIVITY_TYPES = {
  [ACTIVITY_TYPES.VIEW]: TYPE.VIEW,
  [ACTIVITY_TYPES.PMAREPLY_VIEW]: TYPE.VIEW,
  [ACTIVITY_TYPES.ADD_USER_LIKE]: TYPE.LIKE,
  [ACTIVITY_TYPES.FAVOURITE]: TYPE.FAVOURITE,
  [ACTIVITY_TYPES.PMAREPLY_FAVOURITE]: TYPE.FAVOURITE,
  [ACTIVITY_TYPES.MATCH]: TYPE.MATCH,
  [ACTIVITY_TYPES.VIEW_MATCH]: TYPE.VIEW_MATCH,
};
