/**
 * User does not have enough amount of coins
 * The same as:
 * @see dating/credits/constants/declineReason
 */
export const NO_ENOUGH_COINS = 'notEnoughCoins';

/**
 * User should be trusted for sending messages
 */
export const SHOULD_BE_TRUSTED = 'shouldBeTrusted';

/**
 * User sends messages with timeout restriction
 */
export const FREE_MESSAGES_TIMEOUT = 'freeMessagesTimeout';

/**
 * For unsent logic
 * User send message without delivery. All messages will be delivery after pay for debt
 */
export const DEBT_CREDITS_USING = 'debtCreditsUsing';

/**
 * For unsent logic
 * User has reached the limit of debt and need to pay
 */
export const DEBT_LIMIT_REACHED = 'debtLimitReached';

/**
 * For coins logic
 * Need to skip go to PP if no enough coins for sent for special cases
 */
export const SKIP_GO_TO_PP = 'needShowSpecialOffer';

/**
 * User has not uploaded photo or confirmed email yet
 */
export const FREE_MESSAGES_MOTIVATION = 'freeMessageMotivation';

export const FREE_MESSAGE_NOT_ENOUGH_MESSAGES = 'freeMessageNotEnoughMessages';

export const NO_PHOTO = 'NO_PHOTO';

/**
 * For scam protection
 * User should be verified for sending messages
 */
export const MASS_MAILING_PROTECTION_TIMEOUT =
  'MASS_MAILING_PROTECTION_TIMEOUT';
