/**
 * Available image size presets. Only they should be used in components
 * Don't confuse with photo presets (@see src/packages/core/user/photo/constants/PhotoPreset.ts)
 */
enum ImagePreset {
  NONE = 'none',
  FULL_SIZE = 'normal1000',
  MEDIUM = 'normal240',
}
export default ImagePreset;
