export type SearchStringOperationParam =
  | boolean
  | string
  | Record<string, any>
  | string[]
  | undefined;

export type SearchStringOperationResult =
  | string
  | Record<string, string | string[]>;

export type URIObject = {
  hasSearch: (name: string) => boolean;
  setSearch: (name: string | Record<string, any>, param?: string) => URIObject;
  addSearch: (name: string | Record<string, any>, param?: string) => URIObject;
  removeSearch: (name: string) => URIObject;
  query: <T = SearchStringOperationResult>(
    param?: SearchStringOperationParam,
  ) => T;
  resource: () => string | URIObject;
  host: () => string | URIObject;
  toString: () => string;
  origin: (newOrigin?: string) => string | URIObject;
  path: () => string;
  hostname: () => string;
  port: () => string;
  scheme: () => string;
  username: () => string;
  password: () => string;
  pathname: () => string;
  search: <T = SearchStringOperationResult>(
    param?: SearchStringOperationParam,
  ) => T;
};

export type URIFromWindowObject = {
  location?: {
    href?: string;
  };
  href?: string;
};

export enum SearchParamsMethods {
  SET = 'set',
  APPEND = 'append',
}
