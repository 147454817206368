import {getClientInstance} from '@core/graphql/client';
import CURRENT_USER_VIDEOS_QUERY from '@core/user/profile/current/graphql/queries/currentUserVideos.gql';

const getUserVideoCount = async () => {
  const {
    data: {
      myUser: {
        profile: {
          videos: {count},
        },
      },
    },
  } = await getClientInstance().query({query: CURRENT_USER_VIDEOS_QUERY});
  return count;
};

export default getUserVideoCount;
