import type {FC, ReactNode, MutableRefObject} from 'react';
import React, {useEffect, useState, useRef} from 'react';
import cn from 'classnames';
import {motion, AnimatePresence} from 'framer-motion';

import useEventCallback from '@core/utils/react/useEventCallback';
import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';
import scrollIntoView from '@core/utils/scroll/scrollIntoView';
import getAnimationTime from '@core/utils/animation/utils/getAnimationTime';

import type {CSSModule} from '../../types';
import SpacingSize from '../../constants/SpacingSize';
import {Icon} from '../icon';
import AccordionContent from './AccordionContent';
import baseCss from './Accordion.css';

export interface AccordionProps {
  // Pass to make controlled component
  active?: boolean;
  // If 'Accordion' is active by default
  defaultActive?: boolean;
  children: ReactNode;
  title: ReactNode;
  className?: string;
  trackingName?: string;
  unbordered?: boolean;
  withBackground?: boolean;
  // Force set top spacing because of it's reset in first accordion on some themes
  withTop?: boolean;
  withoutTop?: boolean;
  withoutBottom?: boolean;
  withoutLeft?: boolean;
  withoutRight?: boolean;
  actionClassName?: string;
  titleClassName?: string;
  icon?: string | ReactNode;
  spacedIcon?: boolean;
  arrow?: ReactNode;
  showArrow?: boolean;
  onClick?: () => void;
  withContentWrapper?: boolean; // Use custom content, e.g. no spacings will be applied to children
  withScrollIntoView?: boolean;
  'data-test'?: string;
  /**
   * Scroll into 1/3 of content view
   * Useful, ex. for disapprove reasons, when need show part of accordion content
   */
  partialViewContent?: boolean;
  scrollableRef?: MutableRefObject<HTMLDivElement>;
  spacingSize?: SpacingSize;
  actionSpacing?: SpacingSize;
  withBorder?: boolean;
  withoutTopBorder?: boolean;
  spacedHeading?: boolean;
  inverse?: boolean;
  /**
   * {@see AccordionGroup} passes this prop using cloneElement.
   * Unused here, but required for {@see AccordionGroup} usage in {@see ProfileInfoWithAccordions}.
   */
  closeCurrentItem?: () => void;
}

const ANIMATION_SPEED = getAnimationTime();

const TRANSITION = {duration: ANIMATION_SPEED};

const ARROW_ANIMATION_PROPS = {
  variants: {
    up: {
      rotate: -180,
    },
    down: {
      rotate: 0,
    },
  },
  transition: TRANSITION,
  initial: 'down',
};

const CONTENT_ANIMATION_PROPS = {
  variants: {
    open: {
      opacity: 1,
      height: 'auto',
    },
    exit: {
      opacity: 0,
      height: 0,
    },
    initial: {
      opacity: 0,
      height: 0,
    },
  },
  initial: 'initial',
  exit: 'exit',
  animate: 'open',
  transition: TRANSITION,
};

/**
 * Just a universal 'accordion' component.
 *
 * Be aware that when accordion is closed - content is unmounted. Is an optimization for cases when:
 * 1. You have massive calculations inside, but user don't see them. They can lead to slowing browser
 * 2. The case, when you need to fetch some data on content appear (@see SearchFormSwitchableWrapper.js)
 */
const Accordion: FC<
  // `AccordionProps` without `css` inside to make it more suitable for `@phoenix/ui`.
  AccordionProps & {
    css: CSSModule;
  }
> = ({
  css,
  active: activeFromProps = null,
  defaultActive = false,
  icon,
  title,
  arrow,
  scrollableRef,
  className,
  actionClassName,
  actionSpacing = SpacingSize.NORMAL,
  titleClassName,
  'data-test': dataTest,
  onClick,
  trackingName,
  withContentWrapper = true,
  withScrollIntoView = true,
  partialViewContent = false,
  showArrow = true,
  spacingSize = SpacingSize.NORMAL,
  withBorder = false,
  withoutTopBorder = false,
  withTop = false,
  spacedHeading = true,
  spacedIcon = true,
  unbordered,
  withBackground = true,
  withoutTop,
  withoutBottom,
  withoutLeft,
  withoutRight,
  inverse,
  children,
}) => {
  const ref = useRef<HTMLDivElement>();
  const [activeState, setActive] = useState(defaultActive);
  const active = activeFromProps ?? activeState;

  const handleClick = useEventCallback(() => {
    onClick?.();
    if (activeFromProps === null) {
      setActive(!active);
    }
  });

  const handleRest = useEventCallback((definition: string) => {
    if (!withScrollIntoView || definition === 'exit') {
      return;
    }

    if (scrollableRef?.current) {
      scrollIntoView({
        currentElem: ref.current,
        partialViewContent,
        scrollableElem: scrollableRef.current,
      });
    } else {
      scrollIntoView({
        currentElem: ref.current,
        partialViewContent,
      });
    }
  });

  const initialActive = useRef(active).current;

  useEffect(() => {
    if (initialActive && !window.IS_INTEGRATION_TEST_ENVIRONMENT) {
      setTimeout(handleRest, ANIMATION_SPEED * 1000);
    }
  }, [initialActive, handleRest]);

  return (
    <div
      className={cn(
        baseCss.accordion,
        css.accordion,
        spacingSize && css[spacingSize],
        unbordered && css.unbordered,
        withBackground && css.withBackground,
        withBorder && css.withBorder,
        withoutTopBorder && css.withoutTopBorder,
        withTop && css.withTop,
        withoutTop && css.withoutTop,
        withoutBottom && css.withoutBottom,
        withoutLeft && css.withoutLeft,
        withoutRight && css.withoutRight,
        className,
      )}
      data-test={dataTest}
      ref={ref}
    >
      <AddBabciaUBTracking
        trackingName={trackingName || (activeFromProps === null && 'accordion')}
      >
        <div
          // Can't use "button" tag since is possible that some nested buttons are rendered
          // @see SearchFormHeadingWithPreselectedCategoriesLayout.js
          role="button"
          tabIndex={0}
          className={cn(
            baseCss.action,
            css.action,
            showArrow && baseCss.withArrow,
            actionClassName,
            actionSpacing && baseCss[actionSpacing],
          )}
          onClick={handleClick}
          data-test="accordionButton"
        >
          <div className={baseCss.heading}>
            <div
              className={cn(
                baseCss.headingWrap,
                spacedHeading && css.spacedHeading,
              )}
            >
              {icon && (
                <div className={baseCss.icon}>
                  {typeof icon === 'string' ? (
                    <Icon type={icon} standard data-test="accordionTitleIcon" />
                  ) : (
                    icon
                  )}
                </div>
              )}
              <div className={cn(baseCss.title, titleClassName)}>{title}</div>
            </div>
            {showArrow &&
              (arrow || (
                <motion.div
                  {...ARROW_ANIMATION_PROPS}
                  animate={active ? 'up' : 'down'}
                >
                  <Icon
                    standard
                    type="bottom"
                    data-test="accordionArrow"
                    inverse={inverse}
                  />
                </motion.div>
              ))}
          </div>
        </div>
      </AddBabciaUBTracking>
      <AnimatePresence>
        {active && (
          <motion.div
            onAnimationComplete={handleRest}
            {...CONTENT_ANIMATION_PROPS}
          >
            {withContentWrapper ? (
              <AccordionContent spaced={spacedIcon && Boolean(icon)}>
                {children}
              </AccordionContent>
            ) : (
              children
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Accordion;
