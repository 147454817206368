import findIndex from 'lodash/findIndex';
import pickBy from 'lodash/pickBy';
import defaults from 'lodash/defaults';
import omit from 'lodash/omit';

/**
 * Add user info from interaction event to already loaded user list.
 * Exported for test purposes only.
 * @param {object} newUser
 * @param {array} users (cloned with [...users] for ability to modify using .splice())
 * @returns {array} of merged unique users
 */
const mergeUsers = (newUser, [...users]) => {
  let user = newUser;
  const pos = findIndex(users, ({id}) => id === user.id);
  if (pos >= 0) {
    // remove old user info from this list to avoid duplicates
    const [prevUser] = users.splice(pos, 1);

    user = defaults({}, user, omit(prevUser, 'webcamPlacements'));

    /**
     * With interaction event contains only webcamPlacements required to display this event.
     * Other webcamPlacements of GraphQL query are filled with null
     * {@see formatWebcamPlacements} to avoid useless user info requests.
     * If we then receive another event (with other webcamPlacements) we
     * should merge them to avoid loosing placements for the first one.
     */
    if (user.webcamPlacements && prevUser.webcamPlacements) {
      user.webcamPlacements = {
        ...prevUser.webcamPlacements,
        ...pickBy(user.webcamPlacements, Boolean),
      };
    }
  }
  return [user, ...users];
};

export default mergeUsers;
