import type {ChangeEvent, FC, HTMLAttributes, ReactNode} from 'react';
import React, {useState} from 'react';
import cn from 'classnames';

import useEventCallback from '@core/utils/react/useEventCallback';
import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';
import isDeviceWithTouchScreen from '@core/utils/device/isDeviceWithTouchScreen';

import type {CSSModule} from '../../types';
import VerticalAlign from '../../constants/VerticalAlign';
import type SpacingSize from '../../constants/SpacingSize';
import InputIconRipple from '../inputIconRipple';
import Label from '../label';
import Icon from '../icon/Icon';
import baseCss from './Radio.css';

export interface RadioProps extends HTMLAttributes<HTMLInputElement> {
  children?: ReactNode;
  background?: ReactNode | ((props: {checked: boolean}) => ReactNode);
  value: string | number;
  verticalAlign?: VerticalAlign;
  spacingSize?: SpacingSize;
  onChange?: (
    event?: ChangeEvent<HTMLInputElement>,
    value?: string | number,
    /** Always true as radio button should be active always */
    checked?: boolean,
  ) => void;
  name?: string;
  trackingName?: string;
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  inverse?: boolean;
  flexibleWidth?: boolean;
  flexibleLabelWidth?: boolean;
  'data-test'?: string;
}

const skipTouchEvents = !(
  isDeviceWithTouchScreen || window.IS_INTEGRATION_TEST_ENVIRONMENT
);

const DEFAULT_CSS: CSSModule = {};

/**
 * Simple radio button component
 */
const Radio: FC<
  // `RadioProps` without `css` inside to make it more suitable for `@phoenix/ui`.
  RadioProps & {
    /**
     * Styles are passed as props and mixed with own styles.
     */
    css?: CSSModule;
  }
> = ({
  children,
  className,
  css = DEFAULT_CSS,
  disabled = false,
  inverse = false,
  onChange,
  name,
  trackingName = name,
  background,
  value,
  checked = false,
  verticalAlign = VerticalAlign.TOP,
  spacingSize,
  flexibleLabelWidth = false,
  flexibleWidth = false,
  'data-test': dataTest,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const [hover, setHover] = useState(false);
  const [animated, setAnimated] = useState(false);

  const handleBlur = useEventCallback(() => {
    setFocused(false);
  });

  const handleAddAnimation = useEventCallback(() => {
    setAnimated(true);
    handleBlur();
  });

  const handleAnimationEnd = useEventCallback(() => {
    setAnimated(false);
    handleBlur();
  });

  const handleChange = useEventCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (disabled || checked) return;
      // Because we can't un-check radio button
      onChange?.(event, value, true);
      handleAddAnimation();
    },
  );

  const handleFocus = useEventCallback(
    () => skipTouchEvents && setFocused(true),
  );

  const handleMouseLeave = useEventCallback(() => setHover(false));

  const handleMouseEnter = useEventCallback(
    () => skipTouchEvents && setHover(true),
  );

  const classNames = cn(
    checked && cn(baseCss.active, css.active),
    disabled && baseCss.disabled,
    inverse && cn(baseCss.inverse, css.inverse),
    flexibleWidth && baseCss.flexibleWidth,
    baseCss.wrap,
    children && baseCss.margin,
    spacingSize && baseCss[spacingSize],
  );

  return (
    <AddBabciaUBTracking
      trackingName={!disabled && trackingName ? `${trackingName}Radio` : null}
    >
      <label
        className={cn(
          className,
          baseCss.radio,
          baseCss[verticalAlign],
          background && baseCss.background,
        )}
      >
        <div className={classNames}>
          <input
            {...props}
            className={baseCss.input}
            type="radio"
            name={name}
            value={value}
            defaultChecked={checked}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            data-test={dataTest}
          />
          {background &&
            (typeof background === 'function'
              ? background({checked})
              : background)}
          <div className={background && baseCss.position}>
            {/* eslint-disable-next-line local-rules/tracking-wrapper */}
            <div
              role="presentation"
              onClick={handleAddAnimation}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onAnimationEnd={handleAnimationEnd}
              className={baseCss.container}
            >
              <InputIconRipple
                focused={focused}
                hover={hover}
                active={checked}
                animated={animated}
                inverse={inverse}
              />
              <div className={cn(baseCss.icon, css.icon)}>
                <Icon type={`radio-${checked ? 'on' : 'off'}`} />
              </div>
            </div>
          </div>
        </div>
        {children && (
          <Label flexibleWidth={flexibleLabelWidth} inverse={inverse}>
            {children}
          </Label>
        )}
      </label>
    </AddBabciaUBTracking>
  );
};

export default Radio;
