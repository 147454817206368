import type {FC} from 'react';
import React from 'react';

import type {TextProps, TextAlign} from './Text';
import Text from './Text';

const TextDisabled: FC<Omit<TextProps, 'type'>> & {ALIGN: typeof TextAlign} = (
  props,
) => <Text {...props} type={Text.TYPE.DISABLED} />;

TextDisabled.ALIGN = Text.ALIGN;

export default TextDisabled;
