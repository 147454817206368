import isInRouteList from '@core/utils/routing/isInRouteList';
import ROUTES from '@core/application/constants/routes';

import clearUrl from './clearUrl';

const isDescriptorUrl = (url: string) => {
  const pathName: string = clearUrl(url);

  return isInRouteList([ROUTES.DESCRIPTOR], pathName);
};

export default isDescriptorUrl;
