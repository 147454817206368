enum CurrentUserPageAppearance {
  /**
   * Default list of items, with additional info in toolbar (sub-header).
   */
  AS_LIST_WITH_TOOLBAR = 'asListWithToolbar',

  /**
   * Designed as content that is "layered" above user info.
   * Content is separated in two tabs (media and info)
   */
  AS_LAYERED_WITH_TABS = 'asLayeredWithTabs',

  /**
   * Designed as content that is "layered" above user info.
   * List of media items with load more counter.
   */
  AS_LAYERED_WITH_MEDIA_LOAD_MORE = 'asLayeredWithMediaLoadMore',
}

export default CurrentUserPageAppearance;
