import {getClientInstance} from '@core/graphql/client';
import CACHED_URL_FRAGMENT from '@core/user/photo/graphql/fragments/cachedUrl.gql';
import type {CachedUrlFragment} from '@core/user/photo/graphql/fragments/cachedUrl';

// Retrieves the cached photo URL for a given photo ID.
const getCachedPhotoUrl = (id: string): string | null => {
  const fragment = {
    fragment: CACHED_URL_FRAGMENT,
    id: `PhotoType:${id}`,
  };

  const data = getClientInstance().readFragment<CachedUrlFragment>(fragment);
  return data?.cachedUrl ?? null;
};
export default getCachedPhotoUrl;
