import logger from '@core/logger';
import {getClientInstance} from '@core/graphql/client';

import ALL_ACTIVITIES_QUERY from '../graphql/queries/allActivities.gql';

/**
 * Common util function to update activities list
 * @param {Function} updater
 * @returns {boolean} flag, indicating if activities was updated or not.
 */
const updateActivities = (updater) => {
  if (!updater) {
    return false;
  }

  const client = getClientInstance();
  const data = client.readQuery({query: ALL_ACTIVITIES_QUERY});
  if (!data) {
    logger.sendWarning('[updateActivities] AllActivitiesQuery read failed');
    return false;
  }

  const updatedActivities = updater(data.allActivities);

  // Skip update cache if nothing changed
  if (updatedActivities === false) return false;

  client.writeQuery({
    query: ALL_ACTIVITIES_QUERY,
    data: {...data, allActivities: updatedActivities},
    overwrite: true,
  });

  return Boolean(updatedActivities);
};

export default updateActivities;
