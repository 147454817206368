import once from 'lodash/once';

import {getClientInstance} from '@core/graphql/client';

import type {ScreennameApproveSubscription} from '../graphql/subscriptions/screennameApprove';
import SCREENNAME_APPROVE_SUBSCRIPTION from '../graphql/subscriptions/screennameApprove.gql';

const getApproveScreennameSubscription = once(() =>
  getClientInstance().subscribe<ScreennameApproveSubscription>({
    query: SCREENNAME_APPROVE_SUBSCRIPTION,
  }),
);

export default getApproveScreennameSubscription;
