import {useEffect, useMemo, useState, useRef} from 'react';
import {useQuery} from '@apollo/client';
import {useLocation} from 'react-router-dom';
import isNull from 'lodash/isNull';

import isPaySuccessUrl from '@core/utils/url/isPaySuccessUrl';
import CURRENT_USER_ID_QUERY from '@core/user/profile/current/graphql/queries/currentUserId.gql';
import paymentDescriptorDataVar from '@core/graphql/vars/paymentDescriptorDataVar';

import parseHostname from '../../../../../../../util/parseHostname';
import PaymentPageSuccessOrders from '../../../payProcess/utils/PaymentPageSuccessOrders';
import usePaymentParams from '../../../common/utils/usePaymentParams';
import usePaymentProcessingStatus from '../../../common/utils/usePaymentProcessingStatus';
import PROCESSING_STATUS from '../../../common/constants/processingStatus';
import isCardPaymentType from '../../../forms/card/utils/isCardPaymentType';
import useOneClick from '../../../forms/card/utils/useOneClick';
import useCurrentCardBinInfo from '../../../forms/card/utils/useCurrentCardBinInfo';
import useActivePackage from '../../package/utils/useActivePackage';
import PAYMENT_HIDE_DESCRIPTOR_QUERY from '../graphql/queries/paymentHideDescriptor.gql';
import allowedDescriptorForAction from './allowedDescriptorForAction';
import allowedDescriptorForMethod from './allowedDescriptorForMethod';
import getCachedPaymentDescriptor from './getCachedPaymentDescriptor';

/**
 * @desc Hook request or re-request descriptor data related to passed variables
 * @param {Object} params
 * @param {String} params.source - is payment page or not (@see paymentSources.js)
 * @return {Object} obj
 * @return {Boolean} obj.loading
 * @return {String|Undefined} obj.error
 * @return {Object} obj.data
 * @return {Object} obj.data.descriptorData
 * @return {String} obj.data.descriptorData.descriptor
 * @return {String} obj.data.descriptorData.companyName
 * @return {Boolean} obj.data.hideDescriptor
 */
export const usePaymentDescriptor = () => {
  const {pathname} = useLocation();
  const {activePackage} = useActivePackage();
  const {data: userIdData, loading: userIdLoading} = useQuery(
    CURRENT_USER_ID_QUERY,
  );

  // Set stockId when package is exist (remember stockId to avoid re-render when package is changed)
  const [cachedStockId, setCachedStockId] = useState();
  const cachedBinRef = useRef();

  const activePaymentMethod = activePackage?.paymentMethod || '';

  const {action, via, prevVia, orderId, source} = usePaymentParams();
  const cardBin = useCurrentCardBinInfo()?.bin;
  if (cardBin) {
    cachedBinRef.current = cardBin;
  }
  const bin = cardBin || cachedBinRef.current;
  const processingStatus = usePaymentProcessingStatus();

  const {
    loading: oneClickLoading,
    oneClickCardId,
    isOneClickUsed,
    isOneClickAllowed,
    bin: oneClickBin,
  } = useOneClick();
  const [paymentDescriptor, setPaymentDescriptor] = useState({
    data: null,
    loading: true,
    error: null,
  });

  const isSuccessPage = useMemo(() => isPaySuccessUrl(pathname), [pathname]);

  /**
   * @desc Skip request if:
   * - no set active package with stockId
   * - loading another requests
   * - disallowed action for descriptor
   * - disallowed method for descriptor
   * - no card type
   */
  const skipRequestIfNeed = useMemo(() => {
    if (oneClickLoading || processingStatus === PROCESSING_STATUS.LOADING) {
      return true;
    }

    if (isSuccessPage) {
      return false;
    }

    return (
      !cachedStockId ||
      !allowedDescriptorForAction(action) ||
      !allowedDescriptorForMethod(activePaymentMethod) ||
      !isCardPaymentType(activePaymentMethod)
    );
  }, [
    action,
    activePaymentMethod,
    cachedStockId,
    oneClickLoading,
    isSuccessPage,
    processingStatus,
  ]);

  /**
   * isOneClickUsed flag is init in PaymentCardFormBase component, when component is not mounted isOneClickUsed is equal null.
   * Case with two-step payment page. On fist step form is hidden and isOneClickUsed = null.
   * Cant provide null param to request. In this case need use default isOneClickAllowed value.
   */
  const isOneClickUsedValue = isNull(isOneClickUsed)
    ? isOneClickAllowed
    : isOneClickUsed;

  useEffect(() => {
    if (skipRequestIfNeed) {
      return;
    }

    getCachedPaymentDescriptor({
      userId: userIdData?.myUser?.id,
      via,
      stockId: cachedStockId,
      orderId,
      // One click is used if one click allowed and no bin(means no input card number)
      isOneClickUsed: isOneClickUsedValue,
      source,
      bin: isOneClickUsedValue ? oneClickBin : bin,
      cardId: oneClickCardId,
    }).then((data) => setPaymentDescriptor({...data, loading: false}));
  }, [
    userIdData,
    bin,
    cachedStockId,
    isOneClickUsedValue,
    oneClickBin,
    oneClickCardId,
    orderId,
    skipRequestIfNeed,
    source,
    via,
  ]);

  const {
    data: hideDescriptorData,
    loading: hideDescriptorLoading,
    error: hideDescriptorError,
  } = useQuery(PAYMENT_HIDE_DESCRIPTOR_QUERY, {
    variables: {
      action,
      prevVia,
      via,
      orderIds: PaymentPageSuccessOrders.getIds(),
    },
    // Request when need
    skip: skipRequestIfNeed,
  });

  // Remember stockId to avoid repeat request for descriptor (server need first remembered stockId)
  useEffect(() => {
    if (activePackage && !cachedStockId) {
      setCachedStockId(activePackage.stockId);
    }
  }, [activePackage, cachedStockId]);

  const originalDescriptorData = paymentDescriptor.data?.payment?.descriptor;

  const descriptorData = useMemo(() => {
    return (
      originalDescriptorData || {
        descriptor: parseHostname(window.location.hostname).sitename,
        companyName: null,
      }
    );
  }, [originalDescriptorData]);

  const loading =
    userIdLoading ||
    oneClickLoading ||
    hideDescriptorLoading ||
    paymentDescriptor.loading ||
    (!isSuccessPage && processingStatus === PROCESSING_STATUS.LOADING);

  const hideDescriptor = Boolean(hideDescriptorData?.payment?.hideDescriptor);

  /**
   * Caching payment descriptor data.
   * Used for track after payment
   * @see getPaymentDescriptorTrackData.js
   */
  useEffect(() => {
    if (!loading) {
      paymentDescriptorDataVar({...originalDescriptorData, hideDescriptor});
    }
  }, [loading, originalDescriptorData, hideDescriptor]);

  if (skipRequestIfNeed) {
    return {
      skipRequest: skipRequestIfNeed,
      originalDescriptorData,
      loading: false,
      data: {},
      error: null,
    };
  }

  return {
    loading,
    skipRequest: false,
    originalDescriptorData,
    data: loading
      ? {}
      : {
          descriptorData,
          hideDescriptor,
        },
    error: paymentDescriptor.error || hideDescriptorError,
  };
};

export default usePaymentDescriptor;
