enum MediaUploadActionsAppearance {
  /**
   * Displays one or more buttons based on supported
   * features via browser (such as "capture" feature, etc.)
   */
  BASED_ON_BROWSER_FEATURES = 'basedOnBrowserFeatures',

  /**
   * Default ones, but without icons.
   */
  WITHOUT_ICONS = 'withoutIcons',

  /**
   * Unified single action for joined photo and video upload
   */
  UNIFIED_FOR_JOINED_UPLOAD = 'unifiedForJoinedUpload',
}

export default MediaUploadActionsAppearance;
