import isMobileViewport from '@core/responsive/isMobileViewport';

/**
 * Helper methods for simplifying creation of
 * common urls such as user profile, etc.
 */

/**
 * @public
 * @const {string}
 */
export const USER_PROFILE_ROUTE = '/user/view/id/';

/**
 * @public
 * @type {string}
 */
export const CHAT_WITH_ROUTE = '/chat/with';

/**
 * Export used for unit test
 */
export const ROUTES = {
  USER_PROFILE: USER_PROFILE_ROUTE,
  CHAT: '/chat',
  CHAT_WITH: CHAT_WITH_ROUTE,
  CHAT_RECIPIENTS: '/chat/recipients',
  ROOMS: '/rooms',
  ROOMS_JOIN: '/rooms/join',
  NOT_FOUND: '/notFound',
  TRUSTED_VERIFICATION: '/trusted/trustedVerification',
  STORY: '/story',
  AUTOLOGIN: '/site/autologin/key',
};

/**
 * @param {string} id
 * @param {string} promocode
 * @returns {string}
 */
const userProfile = (id, promocode = '') =>
  `${USER_PROFILE_ROUTE}${id}${promocode ? `/fromPromocode/${promocode}` : ''}`;

/**
 * @param {string} id
 * @return {string}
 */
const chatWith = (id) => {
  if (id) {
    return `${ROUTES.CHAT_WITH}/${id}`;
  }

  // Special logic for mobile environment where exists separated recipients page
  return isMobileViewport() ? ROUTES.CHAT_RECIPIENTS : ROUTES.CHAT;
};

/**
 * @param {string} roomId
 * @return {string}
 */
const chatRoom = (roomId) => {
  if (roomId) {
    return `${ROUTES.ROOMS_JOIN}/${roomId}`;
  }
  return ROUTES.ROOMS;
};

/**
 * Function is generator of link for 404 page
 * @return {string}
 */
const notFound = () => ROUTES.NOT_FOUND;

/**
 * Function is generator of link for trusted verification page
 * @param via - via
 * @return {string}
 */
const trustedVerification = (via = '') => {
  return via
    ? `${ROUTES.TRUSTED_VERIFICATION}/via/${via}`
    : ROUTES.TRUSTED_VERIFICATION;
};

/**
 * Function is generator of link for story page
 * @param {string} userId - story user id
 * @param {number} [index]
 * @returns {string}
 */
const storyPage = (userId, index) => {
  return index >= 0
    ? `${ROUTES.STORY}/${userId}?pos=${index}`
    : `${ROUTES.STORY}/${userId}`;
};

/**
 * Function is generator of autologin link
 * @param autologinKey
 * @returns {string}
 */
const autologin = (autologinKey) => `${ROUTES.AUTOLOGIN}/${autologinKey}`;

export default {
  userProfile,
  chatWith,
  chatRoom,
  notFound,
  trustedVerification,
  storyPage,
  autologin,
};
