enum Action {
  FLIRT = 'flirt',
  CHAT = 'chat',
  LIKE = 'like',
  FAVORITE = 'favorite',
  CONTACT_US = 'contactUs',
  UN_BLOCK = 'unBlock',
  UN_REPORT = 'unReport',
  CHANGE_REASON = 'changeReason',
  GO_TO_SEARCH = 'goToSearch',
  DELETE_CHAT = 'deleteChat',
  EXTEND = 'extend',
}

export default Action;
