import {useQuery} from '@apollo/client';

import type {CurrentUserAccountStatusQuery} from '../graphql/queries/paymentAccountStatus';
import PAYMENT_ACCOUNT_STATUS_QUERY from '../graphql/queries/paymentAccountStatus.gql';

type UsePaymentStatusResult = {
  isFullyPaid: boolean;
  isPaid: boolean;
  notBoughtAccountFeatures?: Array<string> | null;
  loading: boolean;
};

/**
 * Separated hook for reusable check on paid status of user
 */
const usePaymentStatus = (): UsePaymentStatusResult => {
  const {loading, data} = useQuery<CurrentUserAccountStatusQuery>(
    PAYMENT_ACCOUNT_STATUS_QUERY,
  );
  const isPaid = data?.payment?.accountStatus?.isPaid || false;
  const notBoughtAccountFeatures =
    data?.payment?.accountStatus?.notBoughtAccountFeatures || [];

  return {
    isPaid,
    isFullyPaid: !notBoughtAccountFeatures.length,
    notBoughtAccountFeatures,
    loading,
  };
};

export default usePaymentStatus;
