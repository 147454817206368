import React, {useState, useCallback} from 'react';
import {useApolloClient} from '@apollo/client';
import {Formik} from 'formik';
import {object, string} from 'yup';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import logger from '@core/logger';
import normalizeMutationErrors from '@core/graphql/utils/normalizeMutationErrors';

import MAIL_ACTION from '../constants/mailAction';
import CONFIRMATION_EMAIL_MUTATION from '../graphql/mutations/confirmationEmail.gql';
import useSuccessMessageDisplayer from '../utils/useSuccessMessageDisplayer';
import updateEmailDataInCache from '../utils/updateEmailDataInCache';

/**
 * @const {String}
 */
export const FIELD_NAME = 'email';

/**
 * @const {Object.<string, string>}
 */
const INITIAL_VALUES = {
  [FIELD_NAME]: '',
};

/**
 * @returns {Function} validationSchema
 */
const getValidationSchema = () =>
  object().shape({
    [FIELD_NAME]: string()
      .trim()
      .required(t('account', 'error.email_is_required'))
      .email(t('emailValidationBase', 'error.invalid_email')),
  });

/**
 * Change email block for confirmation functionality.
 */
const ConfirmationChangeEmail = ({
  confirmationForm: Form,
  errorBoundary: ErrorBoundary,
}) => {
  const {showSuccessMessage, allowToShowSuccessMessage} =
    useSuccessMessageDisplayer();
  const [error, setError] = useState(false);

  const client = useApolloClient();

  const handleFormSubmit = useCallback(
    async (payload, {setSubmitting, setErrors, resetForm}) => {
      const variables = {
        [FIELD_NAME]: payload[FIELD_NAME].trim(),
        action: MAIL_ACTION.CHANGE_EMAIL,
        userId: null,
      };

      let response;
      try {
        response = await client.mutate({
          mutation: CONFIRMATION_EMAIL_MUTATION,
          variables,
        });
      } catch (e) {
        setError(true);
        logger.sendError(
          `[ConfirmationChangeEmail] Submitting error for payload: "${JSON.stringify(
            variables,
          )}".`,
          e,
        );
        return;
      }

      const errors = normalizeMutationErrors(
        response.data.confirm.resendConfirmMail.errors,
      );

      // Remove loader
      setSubmitting(false);

      if (errors) {
        /**
         * Set possible field errors inside Formik. Due to the fact
         * that form is small we can set 'general' errors under the field too.
         */
        setErrors({[FIELD_NAME]: errors[FIELD_NAME] || errors.general});
        return;
      }

      /**
       * Update cache based on mutation result. Set possible
       * email service, and new email globally.
       */
      const {email, url} =
        response.data.confirm.resendConfirmMail.result.emailServiceInfo;

      updateEmailDataInCache(client, email, url);

      // Display "success message" animation
      await allowToShowSuccessMessage();

      // Finally, clear all inputed values before
      resetForm();
    },
    [client, allowToShowSuccessMessage],
  );

  if (error) return <ErrorBoundary />;

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={getValidationSchema()}
      onSubmit={handleFormSubmit}
    >
      {({handleSubmit, isSubmitting}) => (
        <Form
          loading={isSubmitting}
          fieldLabel={t('emailValidationBase', 'text.new_email')}
          fieldName={FIELD_NAME}
          onSubmit={handleSubmit}
          submitText={t('emailValidationBase', 'button.update')}
          successMessage={t('emailValidationBase', 'text.email_sent')}
          showSuccessMessage={showSuccessMessage}
        />
      )}
    </Formik>
  );
};

ConfirmationChangeEmail.propTypes /* remove-proptypes */ = {
  confirmationForm: PropTypes.func.isRequired,
  errorBoundary: PropTypes.func.isRequired,
};

export default ConfirmationChangeEmail;
