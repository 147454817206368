import getActiveSplitGroup from '@core/utils/split/getActiveSplitGroup';
import {SPLITS} from '@core/utils/split';
import processPaymentData from '@core/payment/payProcess/utils/processPaymentData';

import payByMethod from '../../utils/payByMethod';
import getFormName from '../../utils/getFormName';
import backbonePay from './backbonePay';
import type {PaymentProcessStep} from '../../types/paymentProcess';

const makePay: PaymentProcessStep = async (data) => {
  const {paymentData} = data;
  const isFetchApi = await getActiveSplitGroup(
    SPLITS.PAYMENT_PROCESSING_FETCH_API.ID,
    SPLITS.PAYMENT_PROCESSING_FETCH_API.GROUP.ACTIVE,
  );

  const result = await (isFetchApi
    ? payByMethod(paymentData)
    : backbonePay({
        ...paymentData,
        formName: getFormName(
          // @ts-expect-error --> Ignore this legacy code
          paymentData,
        ),
      }));

  return processPaymentData(data, result);
};

export default makePay;
