import {MSG} from '../constants/socketEventNames';

/**
 * @description Resolver that react on change gender or orientation or etc
 * @type {object}
 */
export default {
  InteractionMessagesupportRequestProcessedSubscription: {
    incomingEvents: `${MSG}supportRequestProcessed`,

    fromServer: ({fromId}) => ({
      data: {fromId, __typename: 'SupportRequestProcessed'},
    }),
  },
};
