import merge from 'lodash/merge';
import fetch from 'unfetch';

import {getRelativeUrl} from '@core/utils/url/getUrl';

import prepareBody from './prepareBody';

// Does not matter what we send as body, it will be stringified
export type RequestBody = Record<string, any>;

// Replace body with Record<string, any> because we stringify it in prepareBody
type FetchRequest = Omit<RequestInit, 'body'> & {body?: RequestBody};

export const OBLIGATORY_OPTIONS: FetchRequest = {
  credentials: 'include',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

/**
 * Enhanced fetch with obligatory data.
 * Because without this data standard fetch don't work.
 * Useful for functionalities that are not on GraphQL and will never migrate there.
 */
const enhancedFetch = (
  url: string,
  options: FetchRequest = {},
): Promise<Response> => {
  const {body, ...rest} = options;

  if (body) {
    return fetch(
      getRelativeUrl(url),
      merge({}, OBLIGATORY_OPTIONS, rest, {
        body: prepareBody(body),
      }),
    );
  }

  return fetch(getRelativeUrl(url), merge({}, OBLIGATORY_OPTIONS, rest));
};

export default enhancedFetch;
