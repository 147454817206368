/**
 * Icon position for using outside of component
 * @see Button.jsx
 * @see InputFrame.jsx
 */
enum IconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export default IconPosition;
