import {getCookie} from '@core/utils/cookie';

import {WALLET_METHODS} from '../../common/constants/paymentMethods';
import {
  APPLE_PAY_TEST_ENVIRONMENT,
  GOOGLE_PAY_TEST_ENVIRONMENT,
} from '../../common/constants/walletVisualTestCookies';
import {GOOGLE_PAY_WITH_CVV} from '../constants/testPayLogic';

const getTestWalletCookieNameByMethod = (method) => {
  switch (method) {
    case WALLET_METHODS.APPLE_PAY:
      return APPLE_PAY_TEST_ENVIRONMENT;
    case WALLET_METHODS.GOOGLE_PAY:
      return GOOGLE_PAY_TEST_ENVIRONMENT;
    default:
      return null;
  }
};

// Check cvv logic for method
const getTestCvvLogicByMethod = (method, value) => {
  switch (method) {
    case WALLET_METHODS.GOOGLE_PAY:
      return value === GOOGLE_PAY_WITH_CVV;
    default:
      return false;
  }
};

const getTestWalletCookie = (method) => {
  const testWalletCookieName = getTestWalletCookieNameByMethod(method);
  return testWalletCookieName && getCookie(testWalletCookieName);
};

/**
 * Check the using test pay logic for wallet payment methods (applePay, googlePay...)
 * Check only pay logic, not view button
 * @see getWalletPay
 * @param {string} method - wallet method
 * @param {boolean} withCVV - pay logic with cvv
 * @returns {boolean}
 */
const checkTestWalletPayLogic = (method, withCVV = false) => {
  const testWalletCookieValue = getTestWalletCookie(method);

  if (withCVV) {
    return getTestCvvLogicByMethod(method, testWalletCookieValue);
  }
  return Boolean(testWalletCookieValue);
};

export default checkTestWalletPayLogic;
