/**
 * Converts End-of-Line sequences to \n.
 * @param {string} text
 * @return {*}
 */
export const fixEOL = (text) => text.replace(/\r\n?/g, '\n');

/**
 * Replace all text line breaks with <br>
 * @param {string} text
 * @returns {string}
 */
export const nl2br = (text) => fixEOL(text).replace(/\n/g, '<br>');
