import reject from 'lodash/reject';

import {getClientInstance} from '@core/graphql/client';
import CURRENT_USER_VIDEOS_QUERY from '@core/user/profile/current/graphql/queries/currentUserVideos.gql';

const putVideoInCache = async ({
  id,
  uploadId = null,
  url = '',
  previewUrl = '',
  isConverted = false,
  percentage = 0,
}) => {
  const client = getClientInstance();
  const {data} = await client.query({query: CURRENT_USER_VIDEOS_QUERY});
  let {allVideos} = data.myUser.profile.videos;

  if (uploadId) {
    allVideos = reject(allVideos, (video) => video.id === uploadId);
  }

  allVideos = [
    ...(allVideos || []),
    {
      id,
      url,
      previewUrl,
      isConverted,
      error: '',
      percentage,
      __typename: 'VideoType',
    },
  ];

  client.writeQuery({
    query: CURRENT_USER_VIDEOS_QUERY,
    data: {
      ...data,
      myUser: {
        ...data.myUser,
        profile: {
          ...data.myUser.profile,
          videos: {
            ...data.myUser.profile.videos,
            allVideos,
            count: allVideos.length,
          },
        },
      },
      disapprove: {
        ...data.disapprove,
        statuses: {
          ...data.disapprove.statuses,
          // when video is loaded disapprove no need
          video: false,
        },
      },
    },
  });
};

export default putVideoInCache;
