import type {FC, HTMLProps, ReactNode} from 'react';
import React, {isValidElement, cloneElement} from 'react';
import cn from 'classnames';

/**
 * Direct dependency since icon and row doesn't change in terms of
 * multitheme in @dating entry point.
 */
import {Icon, Row} from '@core/ui';
import type {IconType} from '@core/ui/constants';
import {SpacingSize, VerticalAlign, IconSize, Align} from '@core/ui/constants';

import H4 from '../heading/H4';
import type {TextProps, TextType} from './Text';
import Text from './Text';
import css from './TextWithIcon.css';

/**
 * Reusable component for very common situation:
 * text with icon placed before it. Used site-wide by designers team.
 */
export type TextWithIconProps = Omit<TextProps, 'type'> &
  HTMLProps<HTMLDivElement> & {
    heading?: boolean;
    inverse?: boolean;
    icon?: string | ReactNode;
    vertical?: boolean;
    verticalAlign?: VerticalAlign;
    space?: SpacingSize;
    iconType?: IconType;
    textType?: TextType;
  };

const TextWithIcon: FC<TextWithIconProps> & {TYPE?: typeof TextType} = ({
  heading,
  icon,
  children,
  inverse,
  inline,
  vertical,
  verticalAlign = VerticalAlign.CENTER,
  align = Align.LEFT,
  space = SpacingSize.NORMAL,
  small,
  iconType,
  textType,
  className,
  ...props
}) => {
  return (
    <div
      className={cn(
        className,
        css.wrap,
        css[align],
        vertical && css.vertical,
        verticalAlign && css[verticalAlign],
        inline && css.inline,
      )}
      {...props}
    >
      {icon && (
        <Row
          className={css.icon}
          horizontal={!vertical}
          flexibleWidth={false}
          space={space}
        >
          {isValidElement(icon)
            ? cloneElement<TextWithIconProps>(icon, {
                inverse,
                ...(iconType && {[iconType]: true}),
              })
            : typeof icon === 'string' && (
                <Icon
                  type={icon}
                  standard
                  inverse={inverse}
                  size={small ? IconSize.SIZE_16 : IconSize.SIZE_24}
                  {...(iconType && {[iconType]: true})}
                />
              )}
        </Row>
      )}
      <Row
        className={css.text}
        horizontal={!vertical}
        flexibleWidth={false}
        space={space}
      >
        {heading ? (
          <H4 inverse={inverse}>{children}</H4>
        ) : (
          <Text small={small} inverse={inverse} type={textType}>
            {children}
          </Text>
        )}
      </Row>
    </div>
  );
};

TextWithIcon.TYPE = Text.TYPE;

export default TextWithIcon;
