/**
 * Activity types that can arrive from users
 * @type {Object}
 */
export const ACTIVITY_TYPES = {
  VIEW: 'view',
  CROSS_IMB: 'crossImb',
  MAIL: 'mail',
  SPD_MATCH: 'spdMatch', // Only for SPD
  VIEW_MATCH: 'viewMatch',
  FAVOURITE: 'favourite',
  STORY_LIKE: 'likeFragmentUserStories', // Somebody liked our story fragment
  STORY_VIEW: 'viewFragmentUserStories', // Somebody viewed our story fragment
  NEWSFEED: 'newsFeed', // Webcam model activity
  NEWSFEED_LOGIN: 'newsFeedOnLoginComplete', // Webcam model activity
  NEWSFEED_ON_LOGIN_COMPLETE: 'newsFeedOnUserFirstLogin',
  NEWSFEED_ONLINE: 'newsFeedOnLivecamModelStatusOnline', // Webcam model activity
  NEWSFEED_INFO_UPLOADED: 'newsFeedOnUserInfoUpdated', // Webcam model activity (updated info)
  NEWSFEED_LIKE: 'newsFeedOnYouMayLike', // Webcam model activity (liked you)
  NEWSFEED_APPROVE_COMPLETE: 'newsFeedOnApproveComplete',
  PRIVATE_VIDEO: 'privateVideo',
  PRIVATE_CHAT: 'privateChat', // @todo Unhandled activity type. We should support it?
  FLIRTCAST: 'flirtcast',
  PMAREPLY_WINK: 'mail_sent_pmareply_wink',
  PMAREPLY_FAVOURITE: 'mail_sent_pmareply_favourite',
  PMAREPLY_MAIL: 'mail_sent_pmareply_mail',
  PMAREPLY_VIEW: 'mail_sent_pmareply_profile_view',
  PMAREPLY_FLIRTCAST: 'mail_sent_pmareply_flirtcast',
  USER_ALERTS_NEW_PHOTO: 'userAlertsNewPhoto', // @todo Unhandled activity type. We should support it?
  USER_ALERTS_NEW_MEMBER: 'userAlertsNewMember', // @todo Unhandled activity type. We should support it?
  REPEATABLE_WCL_MESSAGE: 'repeatableWlcMessage', // @todo Unhandled activity type. We should support it?
  REPEATABLE_WCL_CHAT: 'repeatableWlcChat', // @todo Unhandled activity type. We should support it?
  ICEBREAKER: 'icebreaker',
  DATECAST: 'datecast', // @todo Unhandled activity type. We should support it?
  LIKE_PROFILE: 'likeProfile',
  ADD_USER_LIKE: 'addUserLike',
  MATCH: 'match',
  WEBCAM: 'webcam', // Webcam model activity
  ASK_FOR_PHOTO: 'askForPhoto',
  ASK_FOR_MORE_PHOTOS: 'askForMorePhotos',
  ASK_FOR_PHOTO_UPLOADED: 'askForPhotoUploaded',
  ASK_FOR_MORE_PHOTOS_UPLOADED: 'askForMorePhotosUploaded',
  ASK_FOR_PRIVATE_PHOTO: 'askForPrivatePhoto', // @deprectated There is no more private photos
  ASK_FOR_PRIVATE_PHOTO_APPROVE: 'askForPrivatePhotoApprove', // @deprectated There is no more private photos
};

/**
 * Types of widgets that are intended as motivation.
 * @type {Object}
 */
export const MOTIVATION_TYPES = {
  NO_PHOTO: 'noPhoto', // Upload photo motivation
  NO_VIDEO: 'noVideo', // Upload video motivation
  NO_SCREENNAME: 'noScreenname', // Update user login (screenname)
  ACCOUNT_STATUS: 'accountStatus', // Update status motivation
  UPGRADE_TO_VIEW: 'upgrateToViewFullSizePhoto', // Buy membership motivation
  EXTRA_TOOLS: 'extraTools', // Buy features motivation
};

/**
 * Special motivation when user forgot to pay and return back to site
 * @see UnpaidMotivationDataSource.js
 * @type {string}
 */
export const UNPAID_MOTIVATION_TYPE = 'unpaidMotivation';

/**
 * Special motivation when user has global free messages
 * @see FreeMessageNotificationDataSource.js
 * @type {string}
 */
export const FREE_MESSAGE_MOTIVATION_TYPE = 'freeMessageMotivation';

/**
 * Remarketing motivation
 */
export const REMARKETING_TYPE = 'remarketing';

/**
 * Activity types that can be sent ONLY by webcam models.
 * @see WebcamActions.js
 * @type {Array}
 */
export const WEBCAM_ACTIVITY_TYPES = [
  ACTIVITY_TYPES.NEWSFEED,
  ACTIVITY_TYPES.NEWSFEED_LOGIN,
  ACTIVITY_TYPES.NEWSFEED_ONLINE,
  ACTIVITY_TYPES.WEBCAM,
];

export default {
  ACTIVITY_TYPES,
  MOTIVATION_TYPES,
};
