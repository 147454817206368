enum PhotoRestrictionReason {
  /**
   * Server error when user can't do some action until upload photo
   */
  NO_PHOTO = 'noPhoto',
  /**
   * Server error when user can't do some action until upload photo. Additional motivation
   */
  NO_PHOTO_MOTIVATION = 'NO_PHOTO_MOTIVATION',
  NO_PHOTO_GO_TO_PP = 'NO_PHOTO_GO_TO_PP',
  /**
   * Server error when user can't do some action until uploaded photo not approved
   */
  WAIT_FOR_APPROVE = 'waitForApprove',
}

export default PhotoRestrictionReason;
