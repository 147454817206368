import {getCookie} from '@core/utils/cookie';

/**
 * The pub_fragmentKey cookie is temporary and is set by backend only for inapp browsers.
 * It is needed to append it to each URL when it changes. When we want to switch from the inapp browser to the default one,
 * the backend will compare fragmentKey and fragmentToken. If they match, autologin will be performed.
 */
const getFragmentKey = (): null | string => {
  return getCookie('pub_fragmentKey') || null;
};

export default getFragmentKey;
