import t from '@core/translations/translate';

import TYPES from '../constants/types';

const getTypeContents = ({
  type,
  isProfileVideoUploadAllow = true,
  openPhotoUploadPopup,
  openVideoUploadPopup,
}) => {
  switch (type) {
    case TYPES.PHOTO:
      return {
        icon: 'photo',
        text: t('activityNotification', 'disapprovePhotoText'),
        buttonText: t('activityNotification', 'disapprovePhotoButton'),
        onClick: openPhotoUploadPopup,
      };

    case TYPES.VIDEO:
      return {
        icon: 'video',
        text: t('activityNotification', 'UserVideoDeclinedText'),
        buttonText: t('activityNotification', 'UserVideoDeclinedButton'),
        onClick: isProfileVideoUploadAllow ? openVideoUploadPopup : null,
      };

    case TYPES.SCREENNAME:
      return {
        icon: 'profile',
        text: t(
          'activityNotification',
          'adminApproveTextAttributescreennameText',
        ),
        buttonText: t(
          'activityNotification',
          'adminApproveTextAttributescreennameButton',
        ),
        href: '/profile/index/edit/screenname',
      };

    case TYPES.STATUS:
      return {
        icon: 'loudspeaker',
        text: t(
          'activityNotification',
          'adminApproveTextAttributechat_up_lineText',
        ),
        buttonText: t(
          'activityNotification',
          'adminApproveTextAttributechat_up_lineButton',
        ),
        href: '/profile/index/edit/status',
      };

    default:
      return null;
  }
};

export default getTypeContents;
