import {useState, useEffect} from 'react';

import paymentBinsInfoShare$ from '@core/payment/forms/card/utils/paymentBinsInfoShare';

/**
 * @desc Hook that return info by card number
 * @return {{bin, cardTypeLogo, debitType, financialServicePlatform}}
 */
const useCurrentCardBinInfo = () => {
  const [binInfo, setBinInfo] = useState({});

  useEffect(() => {
    const subscription = paymentBinsInfoShare$.subscribe((result) => {
      setBinInfo(result);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return binInfo;
};

export default useCurrentCardBinInfo;
