enum SearchMotivationWidgetAppearance {
  /**
   * All info is included in one card component for all types of widget
   */
  ALL_IN_CARD = 'allInCard',

  /**
   * All payment widgets are saturated with photo or icon outside of card bounds
   */
  SATURATED = 'saturated',

  /**
   * For widget is applied next logic:
   * - All editable widgets with border
   * - All other widgets became saturated and with icon/photo outside of card bounds
   */
  DANGEROUSLY_SATURATED_MINIMIZED = 'dangerouslySaturatedMinimized',

  /**
   * For widget is applied next logic:
   * - All widgets in colored light card
   * - All upgrade widgets has blurred background
   */
  ALL_IN_COLORED_LIGHT_CARD = 'allInColoredLightCard',

  /**
   * For widget is applied next logic:
   * - All widgets with content in line
   * - All upgrade widgets has dark background
   */
  INLINE = 'INLINE',

  /**
   * All widgets became flat, meaning that they have no colors - only button is colorized.
   */
  FLAT = 'flat',

  /**
   * Widgets without any wrapper, e.g. without borders.
   * Also with animated content on upgrade banners.
   */
  UNBORDERED_ANIMATED = 'unborderedAnimated',

  /**
   * All widgets became fullscreen and with blurred background.
   */
  FULLSCREEN = 'fullscreen',
}

export default SearchMotivationWidgetAppearance;
