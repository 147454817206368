import type {FocusEvent} from 'react';
import {useState} from 'react';

import getUserAgentParser from '@core/utils/getUserAgentParser';

type UsePreventIOSSelectErrorResult = {
  isIOS: boolean;
  isIOSError: boolean;
  onBlur: (e: FocusEvent<HTMLSelectElement>) => void;
  onChange: ({isDbChangeTrigger}: {isDbChangeTrigger: boolean}) => void;
  onTouchStart: () => void;
};

/**
 * We have an error with a picker on IOS, when switching between multiselect
 * triggered onChange func with prev data
 * By timeout we can skip first call onChange and select work correctly
 * @see https://stackoverflow.com/questions/26117323/ios-8-multiple-select-serious-bug-on-iphone
 */
const usePreventIOSSelectError = (): UsePreventIOSSelectErrorResult => {
  const isIOS = getUserAgentParser().getOS().name === 'iOS';
  const [isIOSError, setIOSError] = useState<boolean>(false);
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(
    null,
  );
  const [isTouched, setTouched] = useState<boolean>(false);

  const onBlur = (e: FocusEvent<HTMLSelectElement>): void => {
    if (!isIOS || isIOSError || !isTouched) return;

    setIOSError(true);
    setTimer(setTimeout(() => setIOSError(false), 900));
    setTouched(false);

    const parentNode = e.target.parentNode as HTMLElement;
    parentNode.focus();
    parentNode.blur();
  };

  const onChange = ({
    isDbChangeTrigger,
  }: {
    isDbChangeTrigger: boolean;
  }): void => {
    if (isDbChangeTrigger && !isIOS && !isIOSError) return;

    clearTimeout(timer);
    setIOSError(false);
  };

  const onTouchStart = (): void => {
    if (!isIOS) return;
    setTouched(true);
  };

  return {isIOS, isIOSError, onBlur, onChange, onTouchStart};
};

export default usePreventIOSSelectError;
