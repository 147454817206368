import type {ReactNode, FC} from 'react';

import MountName from '../constants/mountName';
import {useMountTracking} from '../utils/mountedComponents';

type MountTrackerProps = {
  name: MountName;
  children?: ReactNode | (() => ReactNode);
};

/**
 * Part of 'Page Load Complete' tracking.
 * Is used to build list of mounted elements.
 */
const MountTracker: FC<MountTrackerProps> & {NAMES: typeof MountName} = ({
  name,
  children,
}) => {
  useMountTracking(name);
  return typeof children === 'function' ? children() : children;
};

MountTracker.NAMES = MountName;

export default MountTracker;
