import {useQuery} from '@apollo/client';
import get from 'lodash/get';

import NAUGHTY_MODE_QUERY from '../graphql/queries/naughtyMode.gql';

const useNaughtyMode = () => {
  const {data, loading, error} = useQuery(NAUGHTY_MODE_QUERY);
  const {naughtyMode, availableModes} = get(
    data,
    'userFeatures.naughtyMode',
    {},
  );
  const {withCleanProductRestrictions, isStrictNudityLaws} = get(
    data,
    'userFeatures',
    false,
  );

  return {
    naughtyMode,
    availableModes,
    isStrictNudityLaws,
    withCleanProductRestrictions,
    loading,
    error,
  };
};

export default useNaughtyMode;
