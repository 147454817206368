import {getClientInstance} from '@core/graphql/client';
import SEARCH_PAGE_LINK from '@core/search/page/constants/route';
import type {DefaultRouteQuery} from '@core/utils/routing/graphql/queries/defaultRoute';

import DEFAULT_ROUTE_QUERY from './graphql/queries/defaultRoute.gql';

const getDefaultPage = async () => {
  const {data} = await getClientInstance().query<DefaultRouteQuery>({
    query: DEFAULT_ROUTE_QUERY,
  });

  return data?.site?.defaultRoute || SEARCH_PAGE_LINK;
};

export default getDefaultPage;
