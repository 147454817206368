import memoize from 'memoize-one';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import isMobileViewport from '@core/responsive/isMobileViewport';
import {ACTIVITY_TYPES} from '@core/activity/constants/notificationTypes';

/**
 * Those activities must not affect counters, and they
 * must appear only in 'real-time' notifications.
 *
 * @todo
 * Different entry-point doesn't require such filtering since they doesn't support such types,
 * but we leave them here for avoiding making code more complicated.
 * If in future we'll require more conditions here - make branching passing such function-resolver into
 * provider.
 */
const getClientDisabledActivityTypes = () =>
  isMobileViewport() ? [] : [ACTIVITY_TYPES.WEBCAM];

/**
 * Remove repeating by type activites.
 * Needed by bussiness logic. We must show only last activity from user, e.g.
 * if user sends you 5 messages - counter must be just 1.
 *
 * @param {Array} activities
 * @returns {Array}
 */
const removeRepeatingActivities = (activities) =>
  uniqBy(activities, (activity) => [activity.type, activity.userId].join());

/**
 * @param {Array} activities
 * @returns {Array}
 */
const removeClientDisabledActivities = (activities) =>
  filter(
    activities,
    (activity) => !includes(getClientDisabledActivityTypes(), activity.type),
  );

/**
 * @param {Array} activities
 * @param {Array} enabledTypes
 * @returns {Array}
 */
const removeServerDisabledActivities = (activities, enabledTypes) =>
  filter(activities, (activity) => includes(enabledTypes, activity.type));

/**
 * @param {Array} activities
 * @returns {Array}
 */
const getOnlyNew = (activities) =>
  filter(activities, (activity) => activity.isNew);

/**
 * Memoized order function. Reacts only if timestamp changes
 * @param {Object} activities
 * @returns {Object}
 */
const order = memoize(
  (activities) => orderBy(activities, 'timestamp', 'desc'),
  isEqual,
);

/**
 * Remove view activities if there is a mutual view activity
 * @param {Array} activities
 * @returns {Array}
 */
const filterMutualViewActivities = (activities) =>
  activities.filter(
    (activity) =>
      !(
        activity.type === ACTIVITY_TYPES.VIEW &&
        activities.find(
          (item) =>
            item.type === ACTIVITY_TYPES.VIEW_MATCH &&
            item.userId === activity.userId &&
            item?.direction === activity?.direction,
        )
      ),
  );

/**
 * Remove duplicates, order them, remove disabled activities.
 * @note Exported only for testing purposes
 * @param {Array} activities
 * @param {Array} enabledTypes
 * @param {boolean} onlyNew
 * @returns {Array}
 */
export const filterActivities = (
  activities,
  enabledTypes = [],
  onlyNew = true,
) => {
  const orderedActivities = order(activities);

  const cleanedupActivities = removeRepeatingActivities(
    filterMutualViewActivities(
      removeServerDisabledActivities(
        removeClientDisabledActivities(orderedActivities),
        enabledTypes,
      ),
    ),
  );

  if (onlyNew) {
    return getOnlyNew(cleanedupActivities);
  }

  return cleanedupActivities;
};

/**
 * @returns {Object}
 */
export const getFilteredActivitiesShape = () => ({
  all: [],
  messages: [],
  activities: [],
  likes: [],
  matches: [],
  views: [],
  favorites: [],
  other: [],
  income: [],
  outcome: [],
});

/**
 * Separate activities by categories
 * @param {Array} allActivities
 * @returns {Object}
 */
const splitActivities = (allActivities) => {
  const result = getFilteredActivitiesShape();

  allActivities.forEach((activity) => {
    if (activity.type !== ACTIVITY_TYPES.MAIL) {
      result[activity.direction].push(activity);
    }

    if (activity.type !== ACTIVITY_TYPES.SPD_MATCH) {
      result.all.push(activity);

      result[
        activity.type === ACTIVITY_TYPES.MAIL ? 'messages' : 'activities'
      ].push(activity);
    }

    switch (activity.type) {
      case ACTIVITY_TYPES.SPD_MATCH:
      case ACTIVITY_TYPES.MAIL:
        // Just ignore.
        break;
      case ACTIVITY_TYPES.MATCH:
      case ACTIVITY_TYPES.VIEW_MATCH:
        result.matches.push(activity);
      // fall through
      case ACTIVITY_TYPES.STORY_LIKE:
      case ACTIVITY_TYPES.ADD_USER_LIKE:
        // Always set likes counter for like gallery counters
        result.likes.push(activity);
        break;
      case ACTIVITY_TYPES.STORY_VIEW:
      case ACTIVITY_TYPES.VIEW:
        result.views.push(activity);
        break;
      case ACTIVITY_TYPES.FAVOURITE:
        result.favorites.push(activity);
        break;
      default:
        result.other.push(activity);
    }
  });

  return result;
};

/**
 * Prepare input activity data for usage inside provider.
 * @see ActivityProvider.js
 * @param {Array} activities
 * @param {Array} enabledTypes
 * @returns {Object}
 */
export const parseForProvider = (activities, enabledTypes) => {
  if (isEmpty(activities) || isEmpty(enabledTypes)) {
    return getFilteredActivitiesShape();
  }

  const filteredActivities = filterActivities(activities, enabledTypes, false);

  return splitActivities(filteredActivities);
};
