import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import PAYMENT_ACTIONS from '../constants/paymentActions';
import INITIAL_PAYMENT_PARAMS from '../constants/initialPaymentParams';
import PaymentParamsContext from './PaymentParamsContext';
import PAYMENT_SOURCES from '../constants/paymentSources';

/**
 * By default, we use 'action' payment parameter from current url,
 * but not all url actions are similar to correct payment 'action'.
 */
const ACTIONS_MAPPER = {
  appFunnel: PAYMENT_ACTIONS.MEMBERSHIP,
  iframePP: PAYMENT_ACTIONS.MEMBERSHIP,
  result: PAYMENT_ACTIONS.RESULT,
  verificationSuccess: PAYMENT_ACTIONS.RESULT,
};

const formatParams = ({action, ...params}) => ({
  ...INITIAL_PAYMENT_PARAMS,
  ...omitBy(
    {
      ...params,
      originalAction: ACTIONS_MAPPER[action] ? action : null,
      action: ACTIONS_MAPPER[action] || action,
    },
    isNil,
  ),
});

const PaymentParamsProvider = ({params, children}) => {
  const value = useMemo(() => formatParams(params || {}), [params]);

  return (
    <PaymentParamsContext.Provider value={value}>
      {children}
    </PaymentParamsContext.Provider>
  );
};

PaymentParamsProvider.propTypes /* remove-proptypes */ = {
  params: PropTypes.shape({
    action: PropTypes.string.isRequired,
    via: PropTypes.string.isRequired,
    source: PropTypes.oneOf(Object.values(PAYMENT_SOURCES)).isRequired,
    // may also contain some other params
  }),
  children: PropTypes.node,
};

export default PaymentParamsProvider;
