import type {Observable} from 'rxjs';
import {from} from 'rxjs';
import {map} from 'rxjs/operators';

import {getClientInstance} from '@core/graphql/client';

import type {UserPaymentProcessedSubscription} from '../../../paymentPending/graphql/subscriptions/userPaymentProcessed';
import USER_PAYMENT_PROCESSED_SUBSCRIPTION from '../../../paymentPending/graphql/subscriptions/userPaymentProcessed.gql';
import type UserPaymentProcessed from '../types/userPaymentProcessed';

const createUserPaymentProcessedObservable =
  (): Observable<UserPaymentProcessed> =>
    from(
      getClientInstance().subscribe<UserPaymentProcessedSubscription>({
        query: USER_PAYMENT_PROCESSED_SUBSCRIPTION,
      }),
    ).pipe(map(({data}) => data.paymentProcessed));

export default createUserPaymentProcessedObservable;
