import type {ReactElement, ReactNode} from 'react';

import type {ContainsValue} from '@core/types/utils';

import type {SimpleParams} from './utils/yii_t';
import yiiT from './utils/yii_t';
import type {ReactParams} from './utils/replaceParams';
import replaceParams from './utils/replaceParams';

export type Params =
  | Record<string, string | number | ReactElement>
  | string
  | number;

function translate<T extends Params>(
  componentName: string,
  key: string,
  params: T,
): ContainsValue<T, ReactElement, ReactNode, string>;

function translate(componentName: string, key: string): string;

/**
 * Function to translate messages.
 * Returns `ReactNode` if `params` contain `ReactElement`, otherwise `string`.
 */
function translate(componentName: string, key: string, params?: Params) {
  let reactParams: ReactParams;
  let simpleParams: SimpleParams;

  if (typeof params === 'object') {
    Object.keys(params).forEach((k) => {
      const v = params[k];
      if (typeof v !== 'object' || v === null) {
        simpleParams = simpleParams || {};
        simpleParams[k] = String(v);
      } else {
        reactParams = reactParams || {};
        reactParams[k] = v;
      }
    });
  } else {
    simpleParams = params;
  }

  const text = yiiT.t(componentName, key, simpleParams);
  if (!reactParams) {
    return text;
  }

  return replaceParams(text, reactParams);
}

/**
 * @deprecated
 * Rewrite createTranslationsMap instead of using this type.
 */
export type Translate = typeof translate;

export default translate;
