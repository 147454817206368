import {
  ERROR_TYPES,
  ERROR_MESSAGES,
  PERMISSION_DENIED_ERRORS,
} from '../constants/webCamErrors';

export default class NativeWebCamHelper {
  static getErrorType(error) {
    switch (true) {
      case this.isPermissionDeniedError(error):
        return ERROR_TYPES.PERMISSION_DENIED_ERROR_TYPE;
      default:
        return ERROR_TYPES.CAMERA_NOT_FOUND_ERROR_TYPE;
    }
  }

  static isPermissionDeniedError(error) {
    return error && PERMISSION_DENIED_ERRORS.indexOf(error.name) >= 0;
  }

  static get defaultConstraints() {
    return {
      video: true,
      audio: false,
      autoplay: true,
      width: 320,
      height: 240,
      container: null,
      minResolution: 480,
    };
  }

  constructor(constraints) {
    this.constraints = {...this.constructor.defaultConstraints, ...constraints};
    // Increase size for more quality image
    this.constraints.dest_width =
      this.constraints.dest_width || this.constraints.width * 2;
    this.constraints.dest_height =
      this.constraints.dest_height || this.constraints.height * 2;

    this.URL = window.URL || window.webkitURL || window.mozURL || window.msURL;
    this.canvas = document.createElement('canvas');
  }

  /**
   * @return {Promise}
   */
  start() {
    return this.isWebCamStarted()
      ? Promise.resolve()
      : this.getUserMedia()
          .then(this.streaming.bind(this))
          .catch((error) => {
            this.destroy();
            return Promise.reject(error);
          });
  }

  /**
   * The Navigator.getUserMedia() method prompts
   * the user for permission to use up to one video input device
   * (such as a camera or shared screen) and up to one audio
   * input device (such as a microphone)
   * @private
   * @returns {Promise}
   */
  getUserMedia() {
    let promise = null;
    const getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;
    if (this.isWebCamStarted()) {
      promise = Promise.resolve(this.stream);
    } else if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      promise = navigator.mediaDevices.getUserMedia(this.constraints);
    } else if (getUserMedia) {
      promise = new Promise((resolve, reject) => {
        getUserMedia.call(navigator, this.constraints, resolve, reject);
      });
    }
    return (
      promise ||
      // eslint-disable-next-line prefer-promise-reject-errors
      Promise.reject({message: ERROR_MESSAGES.GET_USER_MEDIA_NOT_SUPPORTED})
    );
  }

  /**
   * @private
   * Create video element for for displaying video.
   * Set width, height end autoplay params.
   */
  createVideoElement() {
    const container = this.getContainer();
    const video = document.createElement('video');

    const containerWidth = parseInt(container.offsetWidth, 10);
    const containerHeight = parseInt(container.offsetHeight, 10);

    video.width =
      !this.constraints.width || this.constraints.width < containerWidth
        ? containerWidth
        : this.constraints.width;
    video.height =
      !this.constraints.height || this.constraints.height < containerHeight
        ? containerHeight
        : this.constraints.height;

    video.autoplay = this.constraints.autoplay;
    return video;
  }

  /**
   * @private
   * Search end return container element for video by id or css selector - this.constraints.container
   * @return {Element}
   * @trow {Error} if element not found
   */
  getContainer() {
    if (this.constraints.container instanceof HTMLElement) {
      return this.constraints.container;
    }
    const container =
      document.getElementById(this.constraints.container) ||
      document.querySelector(this.constraints.container);
    if (!container) {
      throw new Error(ERROR_MESSAGES.CONTAINER_ELEMENT_NOT_FOUND);
    }
    return container;
  }

  /**
   * @private
   * Start streaming video
   * @param {MediaStream} stream
   */
  streaming(stream) {
    if (stream) {
      return new Promise((resolve, reject) => {
        const container = this.getContainer();
        this.video = this.createVideoElement();
        this.stream = stream;

        // Empty before appending for avoiding any unused trash rendering.
        container.innerHTML = '';
        container.appendChild(this.video);

        if ('srcObject' in this.video) {
          this.video.srcObject = this.stream;
        } else if ('mozSrcObject' in this.video) {
          this.video.mozSrcObject = this.stream;
        } else {
          // This for old browsers and it will going away
          this.video.src = this.URL.createObjectURL(this.stream);
        }

        this.video.onerror = reject;
        this.video.oncanplay = () => {
          resolve();
        };
      });
    }
    return null;
  }

  /**
   * @return {Promise}
   */
  getSnapshot() {
    return new Promise((resolve, reject) => {
      if (this.isWebCamStarted()) {
        const {width, height} = this.constructor.getSnapshotDimensions(
          this.video,
          {
            minResolution: this.constraints.minResolution,
          },
        );

        this.canvas.width = width;
        this.canvas.height = height;

        this.canvas
          .getContext('2d')
          .drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
        resolve(this.canvas.toDataURL('image/png'));
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({message: ERROR_MESSAGES.WEBCAM_NOT_STARTED});
      }
    });
  }

  /**
   * Provides snapshot dimensions according to the video resolution
   * and height to width ratio
   * @private
   * @type {HTMLElement} video
   * @return {Object}
   */
  static getSnapshotDimensions({videoWidth, videoHeight}, {minResolution}) {
    const dimensions = {
      width: videoWidth,
      height: videoHeight,
    };

    if (videoWidth < minResolution) {
      dimensions.height = Math.round(
        (minResolution / videoWidth) * videoHeight,
      );
      dimensions.width = minResolution;
    }

    if (videoHeight < minResolution) {
      dimensions.width = Math.round((minResolution / videoHeight) * videoWidth);
      dimensions.height = minResolution;
    }

    return dimensions;
  }

  isWebCamStarted() {
    return !!this.video;
  }

  destroy() {
    if (this.stream) {
      if (this.stream.getTracks) {
        this.stream.getTracks().forEach((track) => track.stop());
      } else if (this.stream.stop) {
        // deprecated, may be removed in future
        this.stream.stop();
      }
    }
    delete this.stream;
    delete this.video;
  }
}
