import {sessionStorage} from '../../utils/storage';
import isPayUrl from '../../utils/url/isPayUrl';

const CACHED_PURCHASED_PACKAGE = 'cachedPurchasedPackage';

export const getCachedPurchasedPackageData = () => {
  return sessionStorage.getItem(CACHED_PURCHASED_PACKAGE);
};

export const removeCachedPurchasedPackageData = () => {
  return sessionStorage.removeItem(CACHED_PURCHASED_PACKAGE);
};

/**
 * Cache selected package data for external payment
 * Used for restore the selected package if the user return from external payment website.
 */
const cachePurchasedPackageData = ({data = {}, model}) => {
  const redirectUrl = data.url || data.meta?.redirect;

  if (!redirectUrl || !model) {
    return;
  }

  const isInternalRedirect =
    isPayUrl(redirectUrl) || redirectUrl.includes(window.location.hostname);

  if (isInternalRedirect) {
    return;
  }

  // eslint-disable-next-line no-underscore-dangle
  const method = model._getMethod();
  const packageId = model.get('package_id');

  if (!method || !packageId) {
    return;
  }

  sessionStorage.setItem(CACHED_PURCHASED_PACKAGE, {
    method,
    packageId,
  });
};

export default cachePurchasedPackageData;
