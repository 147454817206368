import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import {IconPosition} from '@core/ui/constants';
import componentPropType from '@core/propTypes/component';

const INPUT_TYPE = {
  PASSWORD: 'password',
  TEXT: 'text',
};

const ICON = {
  [INPUT_TYPE.PASSWORD]: 'eye',
  [INPUT_TYPE.TEXT]: 'eye-off',
};

const FromPasswordInput = ({
  withShowPassword = true,
  inputComponent: FormInput,
  iconComponent: Icon,
  ...props
}) => {
  const [type, setType] = useState(INPUT_TYPE.PASSWORD);
  const asText = type === INPUT_TYPE.TEXT;
  const handleShowPasswordClick = useCallback(
    () => setType(asText ? INPUT_TYPE.PASSWORD : INPUT_TYPE.TEXT),
    [setType, asText],
  );

  return (
    <FormInput
      {...props}
      type={type}
      iconPosition={IconPosition.RIGHT}
      icon={
        withShowPassword && (
          <Icon
            type={ICON[type]}
            trackingName={asText ? 'hidePassword' : 'showPassword'}
            onClick={handleShowPasswordClick}
          />
        )
      }
    />
  );
};

FromPasswordInput.propTypes /* remove-proptypes */ = {
  withShowPassword: PropTypes.bool,
  inputComponent: componentPropType.isRequired,
  iconComponent: componentPropType.isRequired,
};

export default FromPasswordInput;
