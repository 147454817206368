import {onCLS, onFID, onLCP, onTTFB, onFCP, onINP} from 'web-vitals';

import sendPerformanceEventToBabcia from './sendPerformanceEventToBabcia';
import {
  Type,
  Category,
  Event,
  LoadProgress,
} from '../constants/babciaDataTypes';
import sendUBEventToBabcia from './sendUBEventToBabcia';

let tracked = false;

/**
 * Since at this stage there is no tracking service yet the settings have not been received
 * you need to track the bootstrap route outside the service.
 *
 * Also, we can't use 'useEffect' for tracking this thing only once
 * since 'useEffect' triggers by itself too late when possible redirect
 * was already performed e.g. from '/funnel' to '/funnel/photoUpload'
 * and we can receive wrong data.
 */
const useBootstrapBabciaTrack = (
  userId: string,
  skip: boolean = false,
): void => {
  if (tracked || skip) {
    return;
  }

  sendUBEventToBabcia({event: Event.BOOTSTRAP, value: LoadProgress.COMPLETE});

  const trackWebVitalsEvent = (metric: {name: string; value: number}): void => {
    sendPerformanceEventToBabcia({
      type: Type.EVENT,
      category: Category.WEB_VITALS,
      label: 'ms',
      event: metric.name,
      value: metric.value,
      userId,
    });
  };

  onCLS(trackWebVitalsEvent);
  onFID(trackWebVitalsEvent);
  onLCP(trackWebVitalsEvent);
  onTTFB(trackWebVitalsEvent);
  onINP(trackWebVitalsEvent);
  onFCP(trackWebVitalsEvent);

  tracked = true;
};

export default useBootstrapBabciaTrack;
