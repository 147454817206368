/**
 * Site-wide spacing sizes
 * @see Spacing.tsx
 * @see Row.jsx
 */
enum SpacingSize {
  NONE = 'none',
  MINIMAL = 'minimal', // Used for tags.
  SHORTEST = 'shortest', // Used for spacing between inline items, such as icons, text, etc.
  SHORT = 'short',
  NORMAL = 'normal',
  LARGE = 'large',
  LARGEST = 'largest',
}

export default SpacingSize;
