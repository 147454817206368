import React, {useEffect} from 'react';
import {useMutation} from '@apollo/client';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import useRedirectToDefaultPage from '@core/utils/routing/useRedirectToDefaultPage';
import {parsePathname} from '@core/utils/url';

import UPDATE_EMAIL_MUTATION from '../graphql/mutations/updateEmail.gql';

const UpdateEmail = ({location, componentLoader: Loader}) => {
  const history = useHistory();
  const [updateEmail] = useMutation(UPDATE_EMAIL_MUTATION);
  const redirectToDefaultPage = useRedirectToDefaultPage();

  const {
    options: {newEmail, verificationCode},
  } = parsePathname(location);

  useEffect(() => {
    updateEmail({
      variables: {
        newEmail,
        verificationCode,
      },
    })
      .then(({data}) => {
        const {redirectUrl} = data.userFeatures.updateEmail;

        if (redirectUrl) {
          history.push(redirectUrl);
        } else {
          redirectToDefaultPage();
        }
      })
      .catch(() => {
        redirectToDefaultPage();
      });
  }, [history, newEmail, redirectToDefaultPage, verificationCode, updateEmail]);

  return <Loader fullscreen />;
};

UpdateEmail.propTypes /* remove-proptypes */ = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  componentLoader: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.func,
  ]).isRequired,
};

export default UpdateEmail;
