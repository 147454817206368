import type {
  FC,
  ReactNode,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
} from 'react';
import React, {useEffect, useCallback, useRef} from 'react';
import {useTimer} from 'use-timer';
import isFunction from 'lodash/isFunction';

import {formatTime} from '@core/utils/timer/Timer';
import type {ProgressProps} from '@core/ui/components/progress/Progress';

let timeProgress: number | null;

export type ProgressWithTimerProps = {
  Progress: ForwardRefExoticComponent<
    PropsWithoutRef<ProgressProps> & RefAttributes<HTMLDivElement>
  >;
  initialTime: number;
  withRestore?: boolean;
  reversePercentage?: boolean;
  children?: ReactNode | ((formatted: string) => ReactNode);
} & Omit<ProgressProps, 'children'>;

const ProgressWithTimer: FC<ProgressWithTimerProps> = ({
  Progress,
  initialTime,
  type,
  withRestore = true,
  reversePercentage = false,
  children,
  ...props
}) => {
  const progressRef = useRef<HTMLDivElement>();
  const isVisible =
    progressRef.current && progressRef.current.offsetParent !== null;

  const onTimeUpdate = useCallback(
    (time: number) => {
      if (time !== initialTime && isVisible) {
        timeProgress = time;
      }
    },
    [isVisible, initialTime],
  );

  const {
    time,
    start: startTimer,
    reset: resetTimer,
    advanceTime,
  } = useTimer({
    onTimeUpdate,
    autostart: true,
    initialTime,
    timerType: 'DECREMENTAL',
    endTime: 0,
  });

  useEffect(() => {
    if (isVisible && timeProgress && withRestore) {
      advanceTime(initialTime - timeProgress);

      timeProgress = null;
    }
  }, [isVisible, initialTime, advanceTime, withRestore]);

  useEffect(() => {
    if (time === 0) {
      resetTimer();
      startTimer();
    }
  }, [resetTimer, startTimer, time]);

  const percentage = initialTime > 0 ? (time * 100) / initialTime : 0;
  const formatted = formatTime(time * 1000, 'mm:ss');

  return (
    <Progress
      ref={progressRef}
      startingPoint={0}
      rotate={false}
      percentage={reversePercentage ? 100 - percentage : percentage}
      round
      type={type}
      {...props}
    >
      {children && (isFunction(children) ? children(formatted) : children)}
    </Progress>
  );
};

export default ProgressWithTimer;
