import type {RavenOptions} from 'raven-js';
import Raven from 'raven-js';
import md5 from 'raven-js/vendor/md5/md5';

import hostnameConverter from '../../../../util/hostnameConverter';
import getUserAgentParser from '../utils/getUserAgentParser';

/**
 * Supported browser versions for which loggin is allowed.
 * Keep in sync with browserslist config.
 */
const SUPPORTED_BROWSERS = {
  'Mobile Safari': 12,
  'Samsung Browser': 10,
  Chrome: 77,
  Firefox: 109,
  Edge: 80,
};

type IgnoreErrorsType = (string | RegExp)[];

const IGNORE_ERRORS: IgnoreErrorsType = [
  'USER_IS_GUEST',
  /^\[GraphQL error\]: Message: Max query depth should be.*$/,
  /^\[GraphQL error\]: Message: GraphQL introspection is not allowed,.*$/,
];

type DataCallbackParams = {
  fingerprint?: string[];
  level?: string;
  message?: string;
  exception?: {
    values: {
      type: string;
      value: string;
    }[];
  };
};

const dataCallback = (data: DataCallbackParams): DataCallbackParams => {
  if (data.fingerprint) {
    return data;
  }

  let fingerprint: string;
  if ('message' in data) {
    fingerprint = `${data.level} ${data.message}`;
  } else if (data.exception) {
    const {type, value} = data.exception.values[0];
    fingerprint = `${type} ${value}`;
  } else {
    return data;
  }

  fingerprint = fingerprint.replace(/https:\/\/\S+/g, '').replace(/\d+/g, '');

  data.fingerprint = [md5(fingerprint)];
  return data;
};

type ErrorLevelsType = {
  raven: {
    info: string;
    warning: string;
    error: string;
  };
  console: {
    info: string;
    warning: string;
    error: string;
  };
};

type UserContextType = {
  id: string;
  username?: string;
  email?: string;
};

// Use instead of any for typing data for RavenOptions.extra that has type any
// Need to be updated when Raven types will be updated
type RavenExtraOptions = any;

let enabled = true;

export default class Logger {
  private ERROR_LEVELS: ErrorLevelsType = {
    raven: {
      info: 'info',
      warning: 'warning',
      error: 'error',
    },
    console: {
      info: 'log',
      warning: 'warn',
      error: 'error',
    },
  };

  install = (): void => {
    const browser = getUserAgentParser().getBrowser();
    const supportedVersion = SUPPORTED_BROWSERS[browser.name];

    if (supportedVersion && browser.major < supportedVersion) {
      console.error('[Logger] Browser not supported');
      return;
    }

    const hostname = hostnameConverter(window.location.hostname);

    try {
      Raven.config(`//${hostname}/storeSystemReport/`, {
        logger: 'OriginWeb', // Declare the environment
        release: window.SYSTEM_RELEASE_NAME, // Release version
        dataCallback,
        shouldSendCallback: () => enabled,
        ignoreErrors: IGNORE_ERRORS,
      }).install();

      // Prevent logging errors caused by cancelled network requests during page unload, as these errors are expected.
      window.addEventListener('pagehide', () => {
        enabled = false;
      });

      // Re-enable logging if the page is restored from the back/forward cache (bfcache).
      window.addEventListener('pageshow', () => {
        enabled = true;
      });
    } catch (e) {
      console.error(e);
    }
  };

  setUserContext = (userContext: UserContextType): void => {
    if (Raven.isSetup()) {
      Raven.setUserContext(userContext);
    }
  };

  sendInfo = (message: string, data: RavenExtraOptions = null): void => {
    this.captureMessage(message, 'info', data);
  };

  sendWarning = (message: string, data: RavenExtraOptions = null): void => {
    this.captureMessage(message, 'warning', data);
  };

  sendError = (message: string, data: RavenExtraOptions = null): void => {
    this.captureMessage(message, 'error', data);
  };

  consoleLoggingAllowed = (): boolean =>
    process.env.NODE_ENV === 'development' &&
    !window.IS_INTEGRATION_TEST_ENVIRONMENT;

  private captureMessage = (
    message: string,
    levelName: string,
    data: RavenExtraOptions,
  ): void => {
    if (Raven.isSetup()) {
      const options: RavenOptions = {
        level: this.ERROR_LEVELS.raven[levelName],
      };

      if (data) {
        options.extra = data;
      }

      Raven.captureMessage(message, options);
    }

    if (this.consoleLoggingAllowed()) {
      console[this.ERROR_LEVELS.console[levelName]](message);
    }
  };

  captureException = (error: Error | ErrorEvent | string): void => {
    if (Raven.isSetup()) {
      Raven.captureException(error);
    }

    if (this.consoleLoggingAllowed()) {
      console.error(error);
    }
  };
}
