import type {MutableRefObject} from 'react';
import {useContext, useRef, useEffect} from 'react';

import logger from '@core/logger';
import useEventCallback from '@core/utils/react/useEventCallback';

import {Event} from '../constants/babciaDataTypes';
import BabciaScopedContext from '../containers/BabciaScopedContext';
import formatTrackingName from './formatTrackingName';
import sendUBEventToBabcia from './sendUBEventToBabcia';
import babciaTrackingEventsController from './babciaTrackingEventsController';

const IN_VIEW_TIME = 100;

const THRESHOLD = 0.2;

type ExtendedMouseEvent = MouseEvent & {
  trackIsSent?: boolean;
  pointerId: number;
};

/**
 * Hook for adding user behavior tracking.
 * The hook adds two types of tracking:
 * 'display' - triggered on element entered the viewport
 * 'click' - triggered on element click.
 *
 * This hook is intended for attaching tracking only to interactive elements.
 *
 * An example of usage:
 *
 * const BazComponent = () => {
 *   const elementRef = useBabciaUBTrack('baz');
 *
 *   return <div ref={elementRef} />
 * }
 */
const useBabciaUBTrack = (
  trackingName: string,
): MutableRefObject<HTMLDivElement> => {
  const elementRef = useRef<HTMLDivElement>();
  const {
    getElementIndex,
    setElement,
    deleteElement,
    trackingLabel,
    trackingIndex,
    context,
    category,
    conceptId,
    eventType,
    skip,
  } = useContext(BabciaScopedContext);

  const objectName = formatTrackingName(trackingName);

  const getEventOptions = useEventCallback(() => ({
    category,
    context,
    conceptId,
    eventType,
    value: objectName,
    index:
      trackingIndex || String(getElementIndex(elementRef.current, objectName)),
  }));

  // Add intersection observer and click handlers
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (skip) {
      return;
    }

    const node = elementRef.current;

    if (objectName && node) {
      const handleClick = (event: ExtendedMouseEvent) => {
        /**
         * Prevent send double track when trackable elements is nested and skip simulated/generated events!
         *
         * Click events with {pointerId: -1} generated by browser in cases:
         *  - We has the next html:
         *     <label>
         *       <input type="radio" name="someName" value="" checked="">
         *       <div id="icon">Icon</div>
         *     </label>
         *   if ckicked on #icon, we will get a normal click event,
         *   after that the browser will generate another click on input tag,
         *   and this second click will contain {pointerId: -1}.
         * so we need skip they.
         */
        if (event.trackIsSent || event.pointerId === -1) {
          return;
        }

        event.trackIsSent = true;

        // If the user swiped clickable element on the web device, skip click track.
        if (babciaTrackingEventsController.paused) {
          return;
        }

        sendUBEventToBabcia(
          {
            ...getEventOptions(),
            label: formatTrackingName(trackingLabel),
            event: Event.CLICK,
          },
          node,
        );
      };

      const handleDisplay = () => {
        sendUBEventToBabcia(
          {
            ...getEventOptions(),
            label: formatTrackingName(trackingLabel),
            event: Event.DISPLAY,
          },
          node,
        );
      };

      setElement(node, objectName);

      let timeout: Timeout;
      const observerCallback = ([entry]: IntersectionObserverEntry[]) => {
        if (entry.isIntersecting) {
          // Prevent send "display" track on fast scroll
          timeout = setTimeout(handleDisplay, IN_VIEW_TIME);
        } else {
          clearTimeout(timeout);
        }
      };
      const observer = new IntersectionObserver(observerCallback, {
        threshold: THRESHOLD,
      });

      observer.observe(node);

      try {
        /**
         * 'contentWindow' exist in iframe elements,
         * so for track a click on them,
         * we subscribe to a click on window in the iframe.
         */
        (node instanceof HTMLIFrameElement
          ? node.contentWindow
          : node
        ).addEventListener('click', handleClick, {
          passive: true,
        });
      } catch (e) {
        logger.sendError(
          '[useBabciaUBTrack] It is not possible to add click tracking to an element. Most likely because, the iframe uses a third-party domain.',
        );
      }

      // eslint-disable-next-line consistent-return
      return () => {
        observer.disconnect();
        deleteElement(node, objectName);
        node.removeEventListener('click', handleClick);
      };
    }
  }, [
    skip,
    objectName,
    trackingLabel,
    getElementIndex,
    getEventOptions,
    setElement,
    deleteElement,
  ]);

  return elementRef;
};

export default useBabciaUBTrack;
