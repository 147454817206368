/**
 * List of values for API field package.type
 * @type {Object}
 */
export const PERIOD = {
  HOUR: 'H',
  DAY: 'D',
  WEEK: 'W',
  MONTH: 'M',
  YEAR: 'Y',
  FULL: 'F',
};
