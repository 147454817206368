import {createMessage} from '../../common/utils/addSendingMessageToCache';
import updateRecipientCache from '../../common/utils/updateRecipientCache';

/**
 * Update recipient cache for sending message after finished send process
 * @see sendMessage.js
 * @param {string} recipientId
 * @param {string} sendingId
 * @param {object} sendMessage
 */
const updateRecipientCacheAfterSendMessage = ({
  recipientId,
  sendingId,
  sendMessage,
}) => {
  const {countFreeMessages, __typename, ...message} = sendMessage;

  /**
   * Update temporary message with sendingId
   * Message id and timestamp from result is unused here
   * to avoid changing order of messages and useless re-renders.
   */
  updateRecipientCache(
    recipientId,
    ({messages, lastMessage, unsentMessageCount}) => {
      /**
       * hasOutgoingMessage: true; - optimization fix. Previously used - SEPARATED_DIALOGS_QUERY.
       * Used in case with separated dialogs.
       * Need update 'hasOutgoingMessage' after send message for a correct work active dialogs tab.
       * Need update unsentMessageCount after send text message or gift for correct work logic of coins popups
       */
      const newRecipientData = {
        unsentMessageCount: message.isUnsent
          ? unsentMessageCount + 1
          : unsentMessageCount,
        lastMessage: {
          ...lastMessage,
          id: message.id,
          timestamp: message.timestamp,
          type: message.type,
          isSending: false,
          __typename: 'LastMessage',
        },
        hasOutgoingMessage: true,
      };

      if (Array.isArray(messages)) {
        if (sendingId) {
          newRecipientData.messages = messages.map((cacheMessage) =>
            cacheMessage.id === sendingId
              ? {
                  ...cacheMessage,
                  isSending: false,
                  ...message,
                }
              : cacheMessage,
          );
        } else if (!messages.some(({id: cacheId}) => cacheId === message.id)) {
          newRecipientData.messages = [
            ...messages,
            {
              ...createMessage(),
              ...message,
              isSending: false,
              __typename: 'Message',
            },
          ];
        }
      }
      return newRecipientData;
    },
  );
};

export default updateRecipientCacheAfterSendMessage;
