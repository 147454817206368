export default {
  Query: {
    fields: {
      userList: (users, {args: {ids}, toReference, canRead}) => {
        const refs = ids.map((id) => toReference({__typename: 'UserData', id}));
        if (refs.some((ref) => !canRead(ref))) {
          return users;
        }
        return refs;
      },
      user: (user, {args: {id}, toReference, canRead}) => {
        const ref = toReference({__typename: 'UserData', id});
        return canRead(ref) ? ref : user;
      },
    },
  },
};
