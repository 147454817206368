import find from 'lodash/find';

/**
 * Possible codecs (mime types) for video recording
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/isTypeSupported
 * Add audio codecs (vorbis or opus), especial for FF.(https://bugzilla.mozilla.org/show_bug.cgi?id=1594466)
 * @see https://www.webmproject.org/docs/container/
 * @constant
 */
const MIME_TYPES = [
  'video/webm;codecs="vp8,vorbis"',
  'video/webm;codecs="vp8,opus"',
  'video/webm;codecs="vp9,vorbis"',
  'video/webm;codecs="vp9,opus"',
  'video/webm',
];

export default function getMediaRecorderOptions() {
  const options = {};

  if (typeof MediaRecorder.isTypeSupported === 'function') {
    const mimeType = find(MIME_TYPES, (type) =>
      MediaRecorder.isTypeSupported(type),
    );
    if (mimeType) {
      options.mimeType = mimeType;
    }
  }

  return options;
}
