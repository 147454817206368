/**
 * Size of icon
 * @see IconSubstrate.tsx
 */
enum IconSubstrateSize {
  SMALLEST = 'smallest',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

export default IconSubstrateSize;
