import React, {forwardRef} from 'react';
import cn from 'classnames';

import type {CSSModule} from '../../types';
import ProgressType from '../../constants/ProgressType';
import type {CommonProgressProps} from './CommonProgress';
import CommonProgress from './CommonProgress';
import baseCss from './Progress.css';

// `ProgressProps` does not include `css` to make it more suitable for '@phoenix/ui'.
export interface ProgressProps
  extends Omit<CommonProgressProps, 'css' | 'content'> {}

/**
 * @const {number}
 */
const RADIUS = 28.5;

/**
 * Circle progress created with SVG using "dash-offset" and "dash-array" properties
 */
const Progress = forwardRef<
  HTMLDivElement,
  CommonProgressProps & {css: CSSModule}
>(({css, ...props}, ref) => (
  <CommonProgress
    {...props}
    ref={ref}
    css={css}
    content={({
      percentage,
      strokeWidth,
      rotate,
      startingPoint,
      linearProgression,
    }) => {
      const rotateModifier = rotate ? 0.05 : 0;
      const sideLength = 2 * Math.PI * RADIUS;
      const progressLength = linearProgression
        ? sideLength + sideLength * (percentage / 100)
        : sideLength *
          (3 + rotateModifier + ((1 - rotateModifier) * percentage) / 100);

      const {type = ProgressType.PRIMARY, inverse, inherit} = props;

      return (
        <svg
          className={cn(
            rotate && baseCss.rotate,
            inverse && baseCss.inverse,
            inherit && baseCss.inherit,
            baseCss[type],
          )}
          style={{transform: `rotate(${startingPoint - 90}deg)`}}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 60 60"
        >
          <circle
            className={cn(
              baseCss.circle,
              css.circle,
              baseCss[`strokeWidth${strokeWidth}`],
            )}
            cx="30"
            cy="30"
            r={RADIUS}
          />
          <circle
            className={cn(
              baseCss.shareCircle,
              css.shareCircle,
              baseCss[`strokeWidth${strokeWidth}`],
            )}
            cx="30"
            cy="30"
            r={RADIUS}
            style={{
              strokeDasharray: sideLength,
              strokeDashoffset: progressLength,
            }}
          />
        </svg>
      );
    }}
  />
));

Progress.displayName = 'Progress';

export default Progress;
