import type {MouseEvent, ReactElement} from 'react';
import React from 'react';
import cn from 'classnames';

import createRouteName from '@core/tracking/babcia/utils/createRouteName';
import useEventCallback from '@core/utils/react/useEventCallback';
import useTabletBreakpoint from '@core/responsive/useTabletBreakpoint';
import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';
import isDeviceWithTouchScreen from '@core/utils/device/isDeviceWithTouchScreen';

import type {CSSModule} from '../../types';
import type {CommonTabProps} from './types';
import {Spacing} from '../spacing';
import SpacingDirection from '../../constants/SpacingDirection';
import SpacingSize from '../../constants/SpacingSize';
import baseCss from './Tab.css';

const DEFAULT_CSS: CSSModule = {};

const useTabWrapper = ({
  css = DEFAULT_CSS,
  onClick,
  trackingName,
  to,
  small,
  active,
  disabled,
  inverse,
  fullHeight,
  fullsize,
  fullWidth,
  transparent,
  oneLine,
  equalWidth,
  asIcon,
  className,
  value,
  children,
  spacingSize,
  prefix,
  suffix,
  floatingPrefix,
  floatingSuffix,
  dataTest: deprecatedDataTest,
  'data-test': dataTest = deprecatedDataTest,
}: CommonTabProps & {to?: string}) => {
  const isTablet = useTabletBreakpoint();

  const handleClick = useEventCallback((e: MouseEvent) => {
    e.stopPropagation();
    onClick(e, value);
  });

  const name = trackingName || (to && createRouteName(to)) || value;

  return {
    wrap: (el: ReactElement) => (
      <AddBabciaUBTracking trackingName={name && `${name}Tab`}>
        {el}
      </AddBabciaUBTracking>
    ),
    onClick: onClick && handleClick,
    className: cn(
      baseCss.tab,
      css.tab,
      small && [baseCss.small, css.small],
      active && [baseCss.active, css.active],
      disabled && [baseCss.disabled, css.disabled],
      inverse && [baseCss.inverse, css.inverse],
      (fullHeight || fullsize) && [css.fullHeight, baseCss.fullHeight],
      (fullWidth || fullsize) && [css.fullWidth, baseCss.fullWidth],
      transparent && [css.transparent, baseCss.transparent],
      !isDeviceWithTouchScreen &&
        !disabled && [baseCss.hoverable, css.hoverable],
      oneLine && baseCss.oneLine,
      equalWidth && baseCss.equalWidth,
      asIcon && css.asIcon,
      className,
    ),
    'data-test': dataTest,
    children: (
      <Spacing
        className={baseCss.tabInner}
        direction={SpacingDirection.HORIZONTAL}
        // In accordance with the design for phones and tablets, one view, and for PC another
        size={
          spacingSize || (isTablet ? SpacingSize.SHORT : SpacingSize.NORMAL)
        }
      >
        <div className={baseCss.content}>
          {prefix && (
            <div
              className={
                floatingPrefix
                  ? cn(baseCss.floatingPrefix, css.floatingPrefix)
                  : baseCss.prefix
              }
            >
              {prefix}
            </div>
          )}
          {children}
          {suffix && (
            <div
              className={
                floatingSuffix
                  ? cn(baseCss.floatingSuffix, css.floatingSuffix)
                  : baseCss.suffix
              }
            >
              {suffix}
            </div>
          )}
        </div>
      </Spacing>
    ),
  };
};

export default useTabWrapper;
