import isNull from 'lodash/isNull';

import isOneClickAllowedVar from '@core/graphql/vars/isOneClickAllowedVar';
import isOneClickUsedVar from '@core/graphql/vars/isOneClickUsedVar';

import PaymentScenario from '../constants/paymentScenario';
import isCardPaymentType from '../../forms/card/utils/isCardPaymentType';
import getFormName from '../../payProcess/utils/getFormName';
import getMethodScenario from '../../payProcess/utils/getMethodScenario';
import {getPageViewTime} from '../../payProcess/utils/pageViewTime';
import PAY_BUTTON_TRACKING from '../../widgets/buttons/constants/payButtonTracking';

/**
 * @param {Object} props
 * @return {Object}
 */
const getVariablesForPayTrack = async (props) => {
  const {
    action,
    activePackage: {paymentMethod},
    isFormHidden: isFormHiddenFromProps = null,
    isOneClickShown: isOneClickShownFromProps = null,
    paymentForm: paymentFormFromProps = '',
    isButtonActive = true,
    isFrontendValidationPassed = true,
    payButton = PAY_BUTTON_TRACKING.DEFAULT,
    via,
  } = props;

  const paymentForm = paymentFormFromProps || getFormName(props);
  const timeInterval = getPageViewTime();

  let isOneClickShown;
  let isFormHidden;

  if (isCardPaymentType(paymentMethod)) {
    isOneClickShown = isNull(isOneClickShownFromProps)
      ? Boolean(isOneClickAllowedVar())
      : isOneClickShownFromProps;

    isFormHidden = isNull(isFormHiddenFromProps)
      ? Boolean(isOneClickUsedVar())
      : isFormHiddenFromProps;
  } else {
    const isOneClickScenario =
      (await getMethodScenario(action, via, paymentMethod)) ===
      PaymentScenario.ONECLICK;
    isOneClickShown = isOneClickScenario;
    isFormHidden = isOneClickScenario;
  }

  return {
    timeInterval,
    action,
    paymentForm,
    payButton,
    isOneClickShown,
    isFormHidden,
    isFrontendValidationPassed,
    isButtonActive,
  };
};

export default getVariablesForPayTrack;
