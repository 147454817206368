import t from '@core/translations/translate';

import {PERIOD} from '../../package/constants/packageLogic';

/**
 * Get package repeat text
 * @param type - package period type
 * @param bits - package bits
 * @return {string}
 */
const getPackagePeriodRepeatText = (type, bits) => {
  const params = {'{n}': bits};

  switch (type) {
    case PERIOD.HOUR:
      return t('paymentPage', 'text.hours_period', params);
    case PERIOD.DAY:
      return t('paymentPage', 'text.days_period', params);
    case PERIOD.WEEK:
      return t('paymentPage', 'text.weeks_period', params);
    case PERIOD.MONTH:
      return t('paymentPage', 'text.months_period', params);
    case PERIOD.YEAR:
      return t('paymentPage', 'text.years_period', params);
    default:
      return '';
  }
};

export default getPackagePeriodRepeatText;
