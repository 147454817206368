import getCountryISO2 from 'country-iso-3-to-2';

import fetchData from '@core/application/utils/fetchData';
import logger from '@core/logger';
import createApplePaySession from '@core/payment/payProcess/utils/createApplePaySession';
import handleApplePayBillingData from '@core/payment/payProcess/utils/handleApplePayBillingData';
import {getLatestApplePayVersionSupported} from '@core/payment/payProcess/utils/applePay';
import {ApplePaySDKVersion} from '@core/payment/payProcess/constants/ApplePaySDKVersion';

import type {PaymentProcessStep} from '../../types/paymentProcess';
import type {PaymentDataResponseOrStatus} from '../../types/paymentFlow';
import {PaymentFlowStatus} from '../../utils/getProcessPaymentFlow';
import payByMethod from '../../utils/payByMethod';
import {addPaymentDataInner} from './addPaymentData';

const applePay: PaymentProcessStep = async (data) => {
  const {paymentData} = data;
  const {
    action,
    activePackage,
    country,
    method,
    siteName,
    settings,
    via,
    ...props
  } = paymentData;

  return new Promise((resolve) => {
    const controller = new AbortController();
    const {signal} = controller;

    const {
      tokenPrice: {amount, currencyCode},
    } = activePackage;

    const {
      supportedNetworks,
      merchantCapabilities,
      cardWalletAdditionalFields = [],
    } = settings;

    /**
     * Apple Pay session
     * @see https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession
     */
    const session = createApplePaySession({
      request: {
        currencyCode,
        countryCode: getCountryISO2(country),
        total: {
          label: siteName,
          amount,
        },
        supportedNetworks,
        merchantCapabilities,
      },
      params: {
        cardWalletAdditionalFields,
      },
    });

    /**
     * Merchant session validation.
     * @see https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778021-onvalidatemerchant
     */
    session.onvalidatemerchant = ({validationURL}) => {
      fetchData({url: `/api/v1/pay/appleSession?url=${validationURL}`, signal})
        .then((res) => res.json())
        .then((res) => {
          session.completeMerchantValidation(res);
        })
        .catch(() => {
          // We have issue that some devices can't support abort method
          try {
            session.abort();
          } catch (e) {
            logger.sendWarning(`[applePay]: no method 'session.abort()' ${e}`);
          }
          resolve({...data, flowStatus: PaymentFlowStatus.ABORTED});
        });
    };

    /**
     * Payment completion callback
     * @see https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778020-onpaymentauthorized
     */
    session.onpaymentauthorized = async (response) => {
      let result: PaymentDataResponseOrStatus = {};

      if (response) {
        try {
          await handleApplePayBillingData(response);
        } catch (error) {
          logger.sendWarning('[applePay]: Shipping contact processing failed');
        }

        const {
          payment: {token},
        } = response;

        /**
         * @TODO: Think about add 'addPaymentData' method to 'getApplePayFlow' util,
         *        after removing Backbone.
         */
        const additionalPaymentData = await addPaymentDataInner({
          action,
          altMethodsSettings: settings,
          activePackage,
          method,
          via,
        });

        try {
          result = await payByMethod({
            ...props,
            ...additionalPaymentData,
            country,
            currencyCode,
            domain: siteName,
            hidePaymentForm: 0,
            walletToken: JSON.stringify(token),
          });
        } catch (error) {
          result = error;
        }
      }

      let status:
        | typeof window.ApplePaySession.STATUS_SUCCESS
        | typeof window.ApplePaySession.STATUS_FAILURE;

      // we handle payment process flow here as payByMethod can return it
      if (typeof result === 'string') {
        status =
          result === PaymentFlowStatus.ABORTED
            ? window.ApplePaySession.STATUS_FAILURE
            : window.ApplePaySession.STATUS_SUCCESS;
      } else {
        status =
          typeof result !== 'string' && result.status
            ? window.ApplePaySession.STATUS_SUCCESS
            : window.ApplePaySession.STATUS_FAILURE;
      }

      session.completePayment(
        /**
         * Since version 3 this method accept object
         * @see https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_on_the_web_version_history/apple_pay_on_the_web_version_3_release_notes
         * */
        getLatestApplePayVersionSupported() >= ApplePaySDKVersion.VERSION_3
          ? {
              status,
            }
          : status,
      );

      if (typeof result === 'string') {
        resolve({...data, flowStatus: result});
      } else {
        resolve({...data, paymentResult: result});
      }
    };

    session.oncancel = () => {
      controller.abort();
      resolve({...data, flowStatus: PaymentFlowStatus.ABORTED});
    };

    session.begin();
  });
};

export default applePay;
