import {useState, useCallback, useMemo} from 'react';

import type {Photo} from '../../../photo/types';
import type {Video} from '../../../video/types';

/**
 * Count of visible media, other media will be hidden under 'show more' counter
 */
export const DEFAULT_VISIBLE_MEDIA_COUNT = 4;

type MediaListWithShowMoreParams = {
  photos?: Photo[];
  videos: {
    allVideos: Video[];
  };
  withLoadMore: boolean;
  visibleMediaCount?: number;
};

type MediaListWithShowMoreResult = {
  photoList: Photo[];
  videoList: {
    allVideos: Video[];
    count?: number;
  };
  mediaCounter: number;
  showMorePhoto: () => void;
  showMoreVideo: () => void;
  totalMediaCount: number;
};

/**
 * Util for forming media list with 'show more' option
 */
const useMediaListWithShowMore = ({
  photos = [],
  videos,
  withLoadMore,
  visibleMediaCount = DEFAULT_VISIBLE_MEDIA_COUNT,
}: MediaListWithShowMoreParams): MediaListWithShowMoreResult => {
  const [visible, showAllPhotos] = useState<boolean>(false);

  const showMore = useCallback(() => showAllPhotos(true), []);

  const totalVideoCount = videos?.allVideos?.length || 0;

  const totalMediaCount = photos.length + totalVideoCount;

  const needShowLoadMore = totalMediaCount > visibleMediaCount;

  const videoList = useMemo(() => {
    if (withLoadMore && !visible && totalVideoCount > visibleMediaCount) {
      const allVideos = videos.allVideos.slice(0, visibleMediaCount);

      return {
        ...videos,
        count: allVideos.length,
        allVideos,
      };
    }

    return videos;
  }, [withLoadMore, videos, visible, totalVideoCount, visibleMediaCount]);

  /**
   * Since the video list is rendered first, photo list is displayed considering the number of rendered videos
   */
  const photoList = useMemo(() => {
    if (!withLoadMore || visible) {
      return photos;
    }

    if (totalVideoCount > visibleMediaCount) {
      return [];
    }

    if (totalVideoCount) {
      return photos.slice(0, visibleMediaCount - totalVideoCount);
    }

    return photos.slice(0, visibleMediaCount);
  }, [withLoadMore, photos, visible, totalVideoCount, visibleMediaCount]);

  if (!withLoadMore) {
    return {
      photoList: photos,
      videoList: videos,
      mediaCounter: 0,
      showMorePhoto: null,
      showMoreVideo: null,
      totalMediaCount,
    };
  }

  // +1 because of blurred last one in DEFAULT_VISIBLE_MEDIA_COUNT
  const mediaCounter =
    !visible && needShowLoadMore ? totalMediaCount - visibleMediaCount + 1 : 0;

  return {
    mediaCounter,
    showMorePhoto:
      Boolean(photoList.length) && needShowLoadMore && !visible
        ? showMore
        : null,
    totalMediaCount,
    videoList,
    photoList,
    showMoreVideo:
      totalVideoCount >= visibleMediaCount && needShowLoadMore && !visible
        ? showMore
        : null,
  };
};

export default useMediaListWithShowMore;
