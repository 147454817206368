/**
 * Trusted verification statuses
 * @TODO: rename to `TrustedStatus`
 */
export enum TRUSTED_STATUSES {
  VERIFIED = 'VERIFIED',
  UNDER_VERIFICATION = 'UNDER_VERIFICATION',
  NON_VERIFIED = 'NON_VERIFIED',
}

export const STATUS_ID_MAP = {
  1: TRUSTED_STATUSES.VERIFIED,
  2: TRUSTED_STATUSES.UNDER_VERIFICATION,
  3: TRUSTED_STATUSES.NON_VERIFIED,
} as const;
