import getBoundingClientRect from '@core/application/utils/getBoundingClientRect';

import type {AvailableSize} from '../types';
import getOverflowParent from './getOverflowParent';

// This describes the virtual padding around the boundary to check for overflow.
const PADDING = 5;

const getAvailableSize = (
  element: HTMLElement,
  positionFixed: boolean,
): AvailableSize => {
  let availableWidth = document.documentElement.clientWidth;
  let availableHeight = document.documentElement.clientHeight;
  let offsetTop = 0;
  let offsetLeft = 0;

  const overflowParent = positionFixed ? null : getOverflowParent(element);

  if (overflowParent) {
    const overflowParentRect = getBoundingClientRect(overflowParent);

    offsetTop = overflowParentRect.top;
    offsetLeft = overflowParentRect.left;

    availableWidth = overflowParentRect.width;
    availableHeight = overflowParentRect.height;
  }

  offsetTop += PADDING;
  offsetLeft += PADDING;

  availableWidth -= PADDING;
  availableHeight -= PADDING;

  return {offsetTop, offsetLeft, availableWidth, availableHeight};
};

export default getAvailableSize;
