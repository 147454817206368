import t from '@core/translations/translate';

import type {NotificationTextOptions} from '../types';
import NOTIFICATION_TYPES from './notificationTypes';

type NotificationOption = {
  iconType: string;
  text: (params?: NotificationTextOptions) => string;
  type: 'danger' | 'highlighted' | 'success';
};

const notificationOptions: Record<string, NotificationOption> = {
  [NOTIFICATION_TYPES.DISAPPROVE_PHOTO]: {
    iconType: 'photo',
    text: () => t('activityNotification', 'disapprovePhotoText'),
    type: 'danger',
  },
  [NOTIFICATION_TYPES.DISAPPROVE_VIDEO]: {
    iconType: 'video',
    text: () => t('uploadButtonMessages', 'text.videoDeclined'),
    type: 'danger',
  },
  [NOTIFICATION_TYPES.DECLINE_VIDEO_GROUP]: {
    iconType: 'video',
    text: ({error}) => error,
    type: 'highlighted',
  },
  [NOTIFICATION_TYPES.DISAPPROVE_SCREENNAME]: {
    iconType: 'profile',
    text: () =>
      t('activityNotification', 'adminApproveTextAttributescreennameText'),
    type: 'danger',
  },
  [NOTIFICATION_TYPES.DISAPPROVE_STATUS]: {
    iconType: 'loudspeaker',
    text: () =>
      t('activityNotification', 'adminApproveTextAttributechat_up_lineText'),
    type: 'danger',
  },
  [NOTIFICATION_TYPES.STATUS_UPDATE]: {
    iconType: 'check',
    text: () => t('funnelStatusUpload', 'text.updated_successfully'),
    type: 'success',
  },
  [NOTIFICATION_TYPES.APPROVE_PHOTO]: {
    iconType: 'photo',
    text: () => t('notificationAlert', 'text.approved'),
    type: 'success',
  },
  [NOTIFICATION_TYPES.PHOTO_UPLOAD]: {
    iconType: 'photo',
    text: () => t('notificationAlert', 'text.photoUploaded'),
    type: 'success',
  },
  [NOTIFICATION_TYPES.APPROVE_VIDEO]: {
    iconType: 'video',
    text: () => t('notificationAlert', 'text.videoApproved'),
    type: 'success',
  },
  [NOTIFICATION_TYPES.CONVERT_VIDEO_SUCCESS]: {
    iconType: 'video',
    text: () => t('uploadButtonMessages', 'text.uploadCompleted'),
    type: 'success',
  },
  [NOTIFICATION_TYPES.CONVERT_VIDEO_ERROR]: {
    iconType: 'video',
    text: ({error}) => error,
    type: 'highlighted',
  },
  [NOTIFICATION_TYPES.VIDEO_MUTE]: {
    iconType: 'video',
    text: () => t('uploadButtonMessages', 'text.videoMuted'),
    type: 'highlighted',
  },
  [NOTIFICATION_TYPES.SUCCESS_PAYMENT]: {
    iconType: 'check',
    text: () => t('paymentPage', 'text.your_payment_was_successful'),
    type: 'success',
  },
  [NOTIFICATION_TYPES.CHANGE_EMAIL]: {
    iconType: 'check',
    text: () => t('emailValidationBase', 'text.updated_successfully'),
    type: 'success',
  },
  [NOTIFICATION_TYPES.PHOTO_REQUEST_IS_SUBMITTED]: {
    iconType: 'check',
    text: () => t('privatePhoto', 'text.request_submitted'),
    type: 'success',
  },
  [NOTIFICATION_TYPES.RESUBSCRIBE]: {
    iconType: 'check',
    text: () => t('unsubscribe', 'text.resubscribe_success'),
    type: 'success',
  },
  [NOTIFICATION_TYPES.CHANGE_PASSWORD]: {
    iconType: 'check',
    text: () => t('managePassword', 'title.successfully_changed'),
    type: 'success',
  },
  [NOTIFICATION_TYPES.CHANGE_TRUSTED]: {
    iconType: 'check',
    text: () => t('paymentTrusted', 'text.sms.valid.text3'),
    type: 'success',
  },
  [NOTIFICATION_TYPES.PERSONAL_IDENTITY]: {
    iconType: 'age-check',
    text: () =>
      t('personalIdentityRestrictions', 'text.verification_was_successful'),
    type: 'success',
  },
  [NOTIFICATION_TYPES.MATCH]: {
    iconType: 'chat',
    text: () => t('popupIsNewMatch', 'text.message_to_your_match_sent'),
    type: 'success',
  },
  [NOTIFICATION_TYPES.SEND_GIFT]: {
    iconType: 'check',
    text: () => t('gifts', 'text.notification_sent_gift'),
    type: 'success',
  },
  [NOTIFICATION_TYPES.SENT_MESSAGES_SUCCESS]: {
    iconType: 'check',
    text: (params) =>
      t('gifts', 'text.notification_sent_messages_count', params),
    type: 'success',
  },
  [NOTIFICATION_TYPES.UNSENT_MESSAGES]: {
    iconType: 'info',
    text: () => t('gifts', 'text.notification_unsent_messages'),
    type: 'danger',
  },
  [NOTIFICATION_TYPES.SEARCH_LIMIT]: {
    iconType: 'info',
    text: () => t('search', 'text.no_matches'),
    type: 'highlighted',
  },
};

export default notificationOptions;
