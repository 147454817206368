import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useApolloClient} from '@apollo/client';

import parsePathname from '@core/utils/url/parsePathname';

import RETRY_PERMISSION_URL_QUERY from '../common/graphql/queries/retryPermissionUrl.gql';

/**
 * Link can be aquired only from CRM letter where
 * user recieves failed payment with reason 'insufficient funds'.
 */
const RetryPermissionRoute = ({location, loader: Loader}) => {
  const {
    options: {orderId, via},
  } = parsePathname(location);
  const history = useHistory();
  const client = useApolloClient();

  useEffect(() => {
    const onFail = () => history.replace('/search');
    if (orderId) {
      client
        .query({query: RETRY_PERMISSION_URL_QUERY, variables: {orderId, via}})
        .then(({data}) => {
          // Usually this link proxies to PP
          history.replace(data?.payment.retryPermissionUrl.url);
        })
        .catch(onFail);
    } else {
      onFail();
    }
  }, [client, history, orderId, via]);

  return <Loader fullscreen />;
};

RetryPermissionRoute.propTypes /* remove-proptypes */ = {
  loader: PropTypes.oneOfType([PropTypes.func]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

export default RetryPermissionRoute;
