import PAYMENT_ACTIONS from '../../../common/constants/paymentActions';

/**
 * @const {Array}
 */
const ACTIONS_WITH_DESCRIPTOR = [
  PAYMENT_ACTIONS.FEATURES,
  PAYMENT_ACTIONS.MEMBERSHIP,
  PAYMENT_ACTIONS.REMARKETING_OFFER,
  PAYMENT_ACTIONS.SPEED_DATING,
  PAYMENT_ACTIONS.DATING_COURSE,
  PAYMENT_ACTIONS.MICRO_FEATURES,
  PAYMENT_ACTIONS.MESSAGE,
  PAYMENT_ACTIONS.VERIFICATION,
];

export default ACTIONS_WITH_DESCRIPTOR;
