import compact from 'lodash/compact';

import type {Params} from '@core/translations/translate';
import t from '@core/translations/translate';
import isMobileViewport from '@core/responsive/isMobileViewport';
import type {
  DistanceGroup,
  CountryType,
  RegionDictionary,
} from '@core/types/graphql';
import {DistanceGroupEnum, DistanceUnits} from '@core/types/graphql';

const LIMIT_DISTANCE = 75;

const checkDistance = (distance: number, type?: DistanceGroupEnum): boolean => {
  if (type === DistanceGroupEnum.more) return false;
  return Boolean(distance && distance < LIMIT_DISTANCE);
};

type GetExtendedDistanceTranslationParams = {
  type: DistanceGroupEnum;
  units: string;
  params: Params;
};

const getExtendedDistanceTranslation = ({
  type,
  units,
  params,
}: GetExtendedDistanceTranslationParams): (() => string) | null => {
  const isPhone = isMobileViewport();
  const distanceMore = type === DistanceGroupEnum.more;
  const distanceLess = type === DistanceGroupEnum.less;
  const isKmsDistance = units === DistanceUnits.kms;
  const isMilesDistance = units === DistanceUnits.miles;

  if (distanceMore && isKmsDistance) {
    return () => t('distance', 'title.more_than-kms', params);
  }

  if (distanceLess && isKmsDistance) {
    return () =>
      isPhone
        ? t('distance', 'title.less-kms', params)
        : t('distance', 'title.less_than-kms', params);
  }

  if (isKmsDistance) {
    return () =>
      isPhone
        ? t('distance', 'title.away-kms', params)
        : t('distance', 'title.away-number-kms', params);
  }

  if (distanceMore && isMilesDistance) {
    return () => t('distance', 'title.more_than-miles', params);
  }

  if (distanceLess && isMilesDistance) {
    return () =>
      isPhone
        ? t('distance', 'title.less-miles', params)
        : t('distance', 'title.less_than-miles', params);
  }

  if (isMilesDistance) {
    return () =>
      isPhone
        ? t('distance', 'title.away-miles', params)
        : t('distance', 'title.away-number-miles', params);
  }

  return null;
};

export type GetDistanceParams = {
  city?: string;
  country?: CountryType;
  regionAbbreviation?: string;
  distanceGroup?: DistanceGroup;
  distanceUnits: string;
  showExtendedDistance?: boolean;
  isHideCity?: boolean;
  regionList?: RegionDictionary[];
};

/**
 * Display distance from you based on passed params.
 * Be careful since distance can be replaced with location in some cases.
 */
const getDistance = ({
  city,
  country,
  regionAbbreviation,
  distanceGroup,
  distanceUnits,
  showExtendedDistance,
  isHideCity,
  regionList,
}: GetDistanceParams): string | null => {
  if (isHideCity) {
    return regionAbbreviation && regionList
      ? regionList.find(({key}) => key === regionAbbreviation)?.name || null
      : (country as string);
  }

  const {toMe, type} = distanceGroup || {};

  const params = {
    '{n}': toMe,
  };

  let distance: string | undefined;

  if (showExtendedDistance) {
    const extendedDistanceTranslation =
      Boolean(toMe) &&
      getExtendedDistanceTranslation({
        type,
        units: distanceUnits,
        params,
      });

    if (extendedDistanceTranslation) {
      distance = extendedDistanceTranslation();
    }
  } else if (checkDistance(toMe, type)) {
    const translate = {
      miles: () => t('distance', 'title.less-miles', params),
      kms: () => t('distance', 'title.less-kms', params),
    }[distanceUnits];

    distance = translate ? translate() : `title.less-${distanceUnits}`;
  }

  // If distance doesn't satisfy check - fallback to location display
  return distance || compact([city, regionAbbreviation || country]).join(', ');
};

export default getDistance;
