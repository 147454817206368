import createDefaults from '../utils/createDefaults';

export default {
  Credits: {
    keyFields: [],
    fields: createDefaults({
      // Credits balance last update time
      time: 0,
    }),
  },
  RewardForTasks: {
    keyFields: [],
  },
  Tasks: {
    keyFields: [],
  },
  ProfileCompletionTask: {
    keyFields: [],
  },
};
