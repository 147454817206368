/**
 * Position of bar. Used to set correctly divider between bar and content.
 * Be aware that divier can be invisible.
 * @see Bar.js
 */
enum BarPosition {
  TOP = 'top',
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom',
}

export default BarPosition;
