enum HorizontalWidgetAppearance {
  /**
   * All widgets has border as separator, but it covers only content, excluding user avatar on the left.
   */
  PARTIALLY_BORDERED = 'partiallyBordered',

  /**
   * All widgets without border
   */
  BOUNDLESS = 'boundless',

  /**
   * User widget spaced, rounded and with shadow
   */
  WRAPPED_IN_CARD = 'wrappedInCard',

  /**
   * Widget has increased sides spacing, accent highlight color and icon substrate
   */
  SPACED_SIDES = 'spacedSides',
}

export default HorizontalWidgetAppearance;
