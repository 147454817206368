import SERVER_ACTIVITIES_QUERY from '../graphql/queries/activities.gql';
import SERVER_ACTIVITIES_FOR_COUNTERS_QUERY from '../graphql/queries/activitiesForCounters.gql';
import ALL_ACTIVITIES_QUERY from '../graphql/queries/allActivities.gql';
import ACTIVITIES_QUERY_TYPES from '../constants/activitiesQueryTypes';
import {LIMIT_FETCH_ACTIVITIES} from '../constants/limitActivitiesForCounters';
import {
  COUNTER_MAIL,
  COUNTER_ACTIVITIES,
} from '../constants/activitiesCounterTypes';

const QUERY_VARIABLES_BY_TYPE = {
  [ACTIVITIES_QUERY_TYPES.LIMITED_ACTIVITIES_FOR_COUNTERS]: {
    counters: [
      {
        type: COUNTER_MAIL,
        isNew: true,
        limit: LIMIT_FETCH_ACTIVITIES,
      },
      {
        type: COUNTER_ACTIVITIES,
        isNew: true,
        limit: LIMIT_FETCH_ACTIVITIES,
      },
    ],
  },

  [ACTIVITIES_QUERY_TYPES.ONLY_NEW]: {
    onlyNew: true,
  },

  [ACTIVITIES_QUERY_TYPES.ALL_OLD]: {
    onlyNew: false,
  },

  [ACTIVITIES_QUERY_TYPES.OUTCOME]: {
    onlyNew: false,
    direction: 'outcome',
  },
};

/**
 * Fetch activities from server without cache
 * We always set activities to client cache @see allActivities.gql
 * @param {Object} client
 * @param {string} queryType
 */
const fetchActivitiesFromServer = ({client, queryType} = {}) => {
  const variables = QUERY_VARIABLES_BY_TYPE[queryType];

  return client
    .query({
      query:
        queryType === ACTIVITIES_QUERY_TYPES.LIMITED_ACTIVITIES_FOR_COUNTERS
          ? SERVER_ACTIVITIES_FOR_COUNTERS_QUERY
          : SERVER_ACTIVITIES_QUERY,
      variables,
      // We don't need to write any data into cache, because we write this data to another place
      fetchPolicy: 'no-cache',
    })
    .then(({data}) => {
      const activities = data.activities || data.activitiesForCounters;

      const allActivities = activities.map((item) => ({
        ...item,
        direction: variables?.direction || ACTIVITIES_QUERY_TYPES.INCOME,
      }));

      client.writeQuery({
        query: ALL_ACTIVITIES_QUERY,
        data: {
          allActivities,
        },
      });
    });
};

export default fetchActivitiesFromServer;
