export const TRACKING_ID = {
  PERFORMANCE: '0cb6e7f8612411ee9cbca3a07e923682',
  USER_BEHAVIOR: '3ed2fabeb46d11eeb0ed67d6a2ede854',
};

/**
 * Set enabled dubug
 *
 * localStorage.setItem('babciaDebugFilter', 1);
 *
 * And filter babcia events
 *
 * @example to use
 *
 * Some string:
 *   localStorage.setItem('babciaDebugFilter', '{"event": "Display"}');
 *
 * Or array:
 *   localStorage.setItem('babciaDebugFilter', '{"event":["Load","Bootstrap", "Redirect"]}');
 */
export const LOCAL_STORAGE_DEBUG_KEY = 'babciaDebug';
