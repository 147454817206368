import {MSG} from '../constants/socketEventNames';

export default {
  InteractionChangePersonalIdentitySubscription: {
    incomingEvents: [`${MSG}changePersonalIdentity`],

    fromServer: ({value}) => ({
      data: {
        personalIdentity: {
          value,
          __typename: 'PersonalIdentity',
        },
      },
    }),
  },

  PersonalIdentityDocumentStatusChangeSubscription: {
    incomingEvents: [`${MSG}documentStatusChange`],

    fromServer: ({documentStatus}) => ({
      data: {
        personalIdentity: {
          documentStatus,
          __typename: 'PersonalIdentity',
        },
      },
    }),
  },
};
