/**
 * Types of messages received and sent
 */
export enum StoryMessageType {
  LIKE_PHOTO_STORIES = 'likePhotoStories',
  LIKE_VIDEO_STORIES = 'likeVideoStories',
  COMMENT_PHOTO_STORIES = 'commentPhotoStories',
  COMMENT_VIDEO_STORIES = 'commentVideoStories',
  VIEW_PHOTO_STORIES = 'viewPhotoStories',
  VIEW_VIDEO_STORIES = 'viewVideoStories',
}

export const STORY_MESSAGE_TYPES: StoryMessageType[] =
  Object.values(StoryMessageType);

export default STORY_MESSAGE_TYPES;
