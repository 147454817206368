import type {FC} from 'react';
import React from 'react';
import cn from 'classnames';

import type {FormikFieldError} from '@core/types/formik';

import type {CSSModule} from '../../types';
import baseCss from './ErrorMessage.css';

export interface ErrorMessageProps {
  attachedToField?: boolean;
  error?: FormikFieldError;
  className?: string;
}

const DEFAULT_CSS: CSSModule = {};

const ErrorMessage: FC<
  // `ErrorMessageProps` without `css` inside to make it more suitable for `@phoenix/ui`.
  ErrorMessageProps & {css?: CSSModule}
> = ({error, css = DEFAULT_CSS, className, attachedToField = false}) => (
  <div
    data-test="errorMessage"
    className={cn(
      baseCss.errorText,
      error && baseCss.error,
      attachedToField && css.attachedToField,
      className,
    )}
  >
    {error}
  </div>
);

export default ErrorMessage;
