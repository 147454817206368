import URI from '@core/utils/url';

/**
 * Some page we receive from url. Example /staticPage/terms?html=notice
 * @param {String} url
 * @return {String}
 */
const getPageByUrlParams = (url = '') => {
  const uri = URI(url);

  return uri.query(true).html || uri.pathname();
};

export default getPageByUrlParams;
