export const getCSSZoom = (): number =>
  Number(
    (document.documentElement as any).currentCSSZoom ??
      window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('zoom'),
  ) || 1;

const getSizeBasedOnZoom = (size: number): number => {
  const zoom = getCSSZoom();

  if (zoom === 1) {
    return size;
  }

  return Number((size / zoom).toFixed(2));
};

export default getSizeBasedOnZoom;
