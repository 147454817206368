import {getClientInstance} from '@core/graphql/client';

import type {PaymentAdditionalTermsCheckedQuery} from '../graphql/queries/paymentAdditionalTermsChecked';
import PAYMENT_ADDITIONAL_TERMS_CHECKED_QUERY from '../graphql/queries/paymentAdditionalTermsChecked.gql';

const resetAdditionalTermsChecked = (checked: boolean = false) => {
  getClientInstance().writeQuery<PaymentAdditionalTermsCheckedQuery>({
    query: PAYMENT_ADDITIONAL_TERMS_CHECKED_QUERY,
    data: {
      payment: {
        isAdditionalTermsChecked: checked,
        __typename: 'Payment',
      },
    },
  });
};

export default resetAdditionalTermsChecked;
