import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import useEventCallback from '@core/utils/react/useEventCallback';
import getReconnectAfterResumeObservable from '@core/websocket/utils/getReconnectAfterResumeObservable';
import Context from '@core/activity/containers/ActivityContext';
import {parseForProvider} from '@core/activity/utils/parseForProvider';
import ACTIVITIES_QUERY_TYPES from '@core/activity/constants/activitiesQueryTypes';
import fetchActivitiesFromServer from '@core/activity/utils/fetchActivitiesFromServer';

const ALL_TYPES = new Set(Object.values(ACTIVITIES_QUERY_TYPES));

const COMPLEX_TYPES = {
  // ALL includes old and new activities, we are using 2 queries to get them.
  [ACTIVITIES_QUERY_TYPES.ALL]: [
    ACTIVITIES_QUERY_TYPES.ONLY_NEW,
    ACTIVITIES_QUERY_TYPES.ALL_OLD,
  ],
};

const DEFAULT_ACTIVITIES = [];

/**
 * Activity data provider.
 * If you need any data from activities, you must wrap your code with this provider
 * and use data consumers.
 */
const ActivityProvider = ({
  children,
  client,
  activities = DEFAULT_ACTIVITIES,
  enabledTypes,
  loading,
  dateFormat = '',
  additionalQueryType,
  requestAllowed,
  subscribeToActivity,
}) => {
  const [activitiesLoading, setActivitiesLoading] = useState(!requestAllowed);
  const requestedQueryTypes = useRef(new Set()).current;
  const isSubscribed = useRef(false);

  const fetchActivitiesByQueryType = useEventCallback(
    (queryType, force) => {
      const types = [additionalQueryType, queryType]
        .filter((type) => ALL_TYPES.has(type))
        .flatMap((type) => COMPLEX_TYPES[type] || type)
        .filter((type) => force || !requestedQueryTypes.has(type));

      if (!types.length) {
        return;
      }

      types.forEach((type) => requestedQueryTypes.add(type));

      setActivitiesLoading(true);
      Promise.all(
        types.map((type) =>
          fetchActivitiesFromServer({client, queryType: type}),
        ),
      ).then(() => setActivitiesLoading(false));
    },
    [additionalQueryType, client],
  );

  useEffect(() => {
    if (requestAllowed && !isSubscribed.current) {
      isSubscribed.current = true;
      subscribeToActivity();

      getReconnectAfterResumeObservable().subscribe(() => {
        fetchActivitiesByQueryType(ACTIVITIES_QUERY_TYPES.ONLY_NEW, true);
      });
    }
  }, [requestAllowed, subscribeToActivity, fetchActivitiesByQueryType]);

  const groupedActivities = parseForProvider(activities, enabledTypes);

  return (
    <Context.Provider
      value={{
        ...groupedActivities,
        dateFormat,
        requestAllowed,
        loading: loading || activitiesLoading,
        // Function, needed to change variables inside query.
        fetchActivitiesByQueryType,
      }}
    >
      {children}
    </Context.Provider>
  );
};

ActivityProvider.propTypes /* remove-proptypes */ = {
  children: PropTypes.element.isRequired,
  requestAllowed: PropTypes.bool.isRequired,
  client: PropTypes.objectOf(PropTypes.any).isRequired,
  subscribeToActivity: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
  additionalQueryType: PropTypes.oneOf(Object.values(ACTIVITIES_QUERY_TYPES)),
  enabledTypes: PropTypes.arrayOf(PropTypes.string),
  activities: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
};

export default ActivityProvider;
