import {useQuery, useReactiveVar} from '@apollo/client';

import paymentBaseDataLoadingVar from '@core/graphql/vars/paymentBaseDataLoadingVar';

import PAYMENT_ACTIONS from '../constants/paymentActions';
import getPaymentTemplate from './getPaymentTemplate';
import getPaymentMotivationTemplate from './getPaymentMotivationTemplate';
import usePaymentParams from './usePaymentParams';
import PAYMENT_TEMPLATE_SETTINGS_QUERY from '../graphql/queries/paymentTemplateSettings.gql';
import useRestApiPaymentTemplateSettings from './useRestApiPaymentTemplateSettings';
import useTemplateAction from './useTemplateAction';

const usePaymentTemplate = () => {
  const {via} = usePaymentParams();
  const action = useTemplateAction();
  const baseDataLoading = useReactiveVar(paymentBaseDataLoadingVar);

  const {
    template: preloadedTemplate,
    motivationTemplate: preloadedMotivationTemplate,
    isAllowed,
    loading: restApiTemplateSettingsLoading,
  } = useRestApiPaymentTemplateSettings();

  const skip = restApiTemplateSettingsLoading || baseDataLoading || isAllowed;

  const {
    data,
    loading: queryLoading,
    error,
  } = useQuery(PAYMENT_TEMPLATE_SETTINGS_QUERY, {
    variables: {action, via},
    skip,
  });

  const loading =
    baseDataLoading || queryLoading || restApiTemplateSettingsLoading;

  if (loading || error) {
    return {
      loading,
      template: null,
      error,
    };
  }

  const isRemarketingOffer = PAYMENT_ACTIONS.REMARKETING_OFFER === action;

  if (isAllowed) {
    return {
      loading: restApiTemplateSettingsLoading,
      error: null,
      template: getPaymentTemplate(preloadedTemplate, isRemarketingOffer),
      motivationTemplate: getPaymentMotivationTemplate(
        preloadedMotivationTemplate,
      ),
    };
  }

  const {template, motivationTemplate} =
    data.payment.templateSettings.templateSettingsData;

  return {
    loading,
    template: getPaymentTemplate(template, isRemarketingOffer),
    motivationTemplate: getPaymentMotivationTemplate(motivationTemplate),
    error,
  };
};

export default usePaymentTemplate;
