import type {FC, JSX, MutableRefObject} from 'react';
import React, {
  cloneElement,
  Children,
  isValidElement,
  useCallback,
} from 'react';
import cn from 'classnames';

import logger from '@core/logger';

import {TRACKING_ID} from '../constants/babcia';
import useBabciaUBTrack from '../utils/useBabciaUBTrack';

type AddBabciaUBTrackingProps = {
  children: JSX.Element;
  innerRef?: MutableRefObject<HTMLElement>;
  className?: string;
  trackingName?: string | null;
  [key: string]: unknown;
};

const getChildClassName = (childProps, parentProps) => {
  const className = cn(childProps?.className, parentProps?.className);

  if (className) {
    return {className};
  }

  return null;
};

const AddBabciaUBTrackingInner = ({
  children,
  trackingName,
  innerRef,
  ...props
}) => {
  const elementRef = useBabciaUBTrack(trackingName);

  const originalRef = innerRef || children.ref;
  const ref = useCallback(
    (reference) => {
      // Call the original ref
      if (typeof originalRef === 'function') {
        originalRef(reference);
      } else if (originalRef) {
        originalRef.current = reference;
      }

      elementRef.current = reference;
    },
    [elementRef, originalRef],
  );

  return cloneElement(children, {
    ...props,
    ...getChildClassName(children.props, props),
    ref,
  });
};

/**
 * Wrapper for adding user behavior tracking.
 *
 * !!!!!!!!!!!!!!!!!!!!!!! WARNING !!!!!!!!!!!!!!!!!!!!!!
 *
 *  - Wrap only those elements or components, that accept ref.
 *
 * !!!!!!!!!!!!!!!!!!!!!!! WARNING !!!!!!!!!!!!!!!!!!!!!!
 *
 * An example of usage:
 *
 * <AddBabciaUBTracking trackingName="baz">
 *   <div />
 * </AddBabciaUBTracking>
 */
const AddBabciaUBTracking: FC<AddBabciaUBTrackingProps> = ({
  children,
  trackingName,
  innerRef,
  ...props
}) => {
  const childrenArray = Children.toArray(children);

  if (!isValidElement(childrenArray[0])) {
    return children;
  }

  if (childrenArray.length > 1) {
    logger.sendError(
      '[AddBabciaUBTracking] Tracking is not handled. Only one child must be provided!',
    );

    return children;
  }

  if (!window?.[TRACKING_ID.USER_BEHAVIOR] || !trackingName) {
    const childProps = {...props};

    if (innerRef) {
      childProps.ref = innerRef;
    }

    return cloneElement(children, {
      ...childProps,
      ...getChildClassName(children.props, props),
    });
  }

  return (
    <AddBabciaUBTrackingInner
      // eslint-disable-next-line local-rules/no-non-clickable-tracking -- it doesn't need to be clickable by itself
      trackingName={trackingName}
      innerRef={innerRef}
      {...props}
    >
      {children}
    </AddBabciaUBTrackingInner>
  );
};

export default AddBabciaUBTracking;
