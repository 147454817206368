import {useMemo} from 'react';

import isCompactViewportCached from '@core/responsive/isCompactViewportCached';
import useActivePackage from '@core/payment/widgets/package/utils/useActivePackage';

import usePaymentParams from './usePaymentParams';

/**
 * Return true if user on first stage of compact(mob) payment page.
 * Detect by existing stockId in url.
 * @return {Boolean}
 */
const useIsMobFirstStage = () => {
  const {stockId, viaMethod, packageType} = usePaymentParams();
  const {activePackage} = useActivePackage();

  return useMemo(() => {
    if (!isCompactViewportCached() || packageType) {
      return false;
    }

    if (!stockId) {
      return true;
    }

    if (viaMethod && viaMethod !== activePackage?.paymentMethod) {
      return true;
    }

    return stockId !== activePackage?.stockId;
  }, [packageType, stockId, viaMethod, activePackage]);
};

export default useIsMobFirstStage;
