import type {FC, HTMLAttributes, MutableRefObject, ReactNode} from 'react';
import React from 'react';
import cn from 'classnames';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import type {CSSModule} from '../../types';
import baseCss from './Card.css';

export type CardShadowLevel = 0 | 1 | 2 | 3 | 4;

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Make difference between cards displayed directly on 'page' background
   * and somewhere inside container. Some themes rely on this flag for changing colors
   */
  onPageBackground?: boolean;
  /**
   * Looks strange that wrapping component can have optional children, but it is used as prop
   * @see ActivityPage.js
   */
  children?: ReactNode;
  trackingName?: string;
  className?: string;
  light?: boolean;
  active?: boolean;
  inverse?: boolean;
  highlighted?: boolean;
  success?: boolean;
  danger?: boolean;
  accent?: boolean;
  header?: boolean;
  boundless?: boolean;
  overflow?: boolean;
  shadowLevel?: 0 | 1 | 2 | 3 | 4;
  /**
   * Apply more saturated color to card. Actual if you use props like
   * 'success', 'danger', 'highlighted', etc.
   */
  saturated?: boolean;
  transparent?: boolean;
  innerRef?:
    | MutableRefObject<HTMLDivElement>
    | ((node: HTMLDivElement) => void);
}

/**
 * Just a white (or inverse) card widget.
 */
const Card: FC<
  // `CardProps` without `css` inside to make it more suitable for `@phoenix/ui`.
  CardProps & {
    // Styles are passed as props and mixed with own styles. Also is not required since we can use no extra styles
    css: CSSModule;
  }
> = ({
  css,
  onPageBackground = false,
  children,
  className,
  light = false,
  inverse = false,
  highlighted = false,
  success = false,
  danger = false,
  accent = false,
  /**
   * Yep, designers use a lot of cards with header color,
   * because they don't believe in "inverse" cards. Don't know why...
   */
  header = false,
  /**
   * Makes card as "selected" applying to it outline with primary color
   */
  active = false,
  trackingName,
  shadowLevel = 1,
  boundless = false,
  overflow = false,
  saturated = false,
  transparent = false,
  innerRef,
  ...props
}) => {
  const content = (
    <div
      className={cn(
        baseCss.card,
        baseCss[`shadow${shadowLevel}`],
        css[`shadow${shadowLevel}`],
        className,
        inverse && baseCss.inverse,
        inverse && css.inverse,
        onPageBackground && css.onPageBackground,
        highlighted && baseCss.highlighted,
        highlighted && css.highlighted,
        light && baseCss.light,
        light && css.light,
        success && baseCss.success,
        success && css.success,
        danger && baseCss.danger,
        danger && css.danger,
        accent && baseCss.accent,
        accent && css.accent,
        header && baseCss.header,
        header && css.header,
        active && baseCss.active,
        active && css.active,
        boundless && baseCss.boundless,
        boundless && css.boundless,
        overflow && baseCss.overflow,
        saturated && baseCss.saturated,
        saturated && css.saturated,
        transparent && baseCss.transparent,
      )}
      ref={innerRef}
      {...props}
    >
      {children}
    </div>
  );

  return trackingName ? (
    <AddBabciaUBTracking trackingName={trackingName}>
      {content}
    </AddBabciaUBTracking>
  ) : (
    content
  );
};

export default Card;
