import isNaN from 'lodash/isNaN';

import logger from '@core/logger';

export const PRICE_CHAR_THOUSANDS_VALUE = 4;
const PRICE_CHAR_MILLION_VALUE = 7;
const MILLION = 1000000;
const AMOUNT_ABBREVIATION = {
  MILLION: 'M',
  THOUSAND: 'K',
};

/**
 * Split amount value to currency and amount
 * TODO: this is temp solution, we need to remove this after fixing price format that we receive from GraphQL see FE-15341
 * @param {String} price
 * @return {Object}
 */
const splitAmountValue = (price) => {
  const currency = {
    prefix: '',
    suffix: '',
  };

  const amount = price.match(/[0-9.,]*/g).join('');
  const amountIndex = price.indexOf(amount);
  const currencySymbol = price.replace(amount, '');

  if (amountIndex) {
    currency.prefix = currencySymbol;
  } else {
    currency.suffix = currencySymbol;
  }

  return {
    amount,
    currency,
  };
};

const compactMillions = (value) => {
  const millionPartAmount = parseInt(value / MILLION, 10);
  const thousandsPartAmount = Math.round(
    (value - millionPartAmount * MILLION) / 1000,
  );

  /**
   * Check if thousands are not empty, to avoid situations when compact price is 1M0K
   * @type {string|string}
   */
  const thousandsPartString =
    thousandsPartAmount > 0
      ? `${thousandsPartAmount}${AMOUNT_ABBREVIATION.THOUSAND}`
      : '';

  return `${millionPartAmount}${
    AMOUNT_ABBREVIATION.MILLION
  }${thousandsPartString}`;
};

/**
 * @param amount {number|string}
 * @returns {string}
 */
const compactPriceValue = (amount) => {
  let clearedAmount = String(amount).replace(/,/g, '');

  if (isNaN(Number(clearedAmount))) {
    logger.sendWarning('Incorect package price value');

    return amount;
  }

  /**
   * Amount length without cents
   * @type {number}
   */
  const clearedAmountLength = clearedAmount.split('.')[0].length;

  /**
   * Checking if amount without cents is bigger than million or thousand.
   * If amount is bigger return compacted value otherwise original value
   * @type {string|string}
   */
  if (clearedAmountLength >= PRICE_CHAR_MILLION_VALUE) {
    clearedAmount = compactMillions(clearedAmount);
  } else {
    clearedAmount =
      clearedAmountLength >= PRICE_CHAR_THOUSANDS_VALUE
        ? `${Math.round(clearedAmount / 1000)}${AMOUNT_ABBREVIATION.THOUSAND}`
        : amount;
  }

  return clearedAmount;
};

/**
 *
 * @param {Array} data
 * @returns {Array}
 * @see src/packages/dating/billingHistory/components/BillingHistoryPopupLayout.js
 */
export const compactPriceValueInData = (data) => {
  return data.map((item) => {
    const {amount, currency} = splitAmountValue(item.amount);
    const clearedAmount = compactPriceValue(amount);

    /**
     * Restoring format that we received from server
     * @type {string}
     */
    return {
      ...item,
      amount: currency.prefix + clearedAmount + currency.suffix,
    };
  });
};

export default compactPriceValue;
