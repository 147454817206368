import type {HTMLAttributes, FC, ReactElement, MouseEvent} from 'react';
import React, {
  useRef,
  useState,
  useLayoutEffect,
  cloneElement,
  Children,
  isValidElement,
} from 'react';
import {animate, easeInOut} from 'popmotion';
import cn from 'classnames';

import useEventCallback from '@core/utils/react/useEventCallback';
import getAnimationTime from '@core/utils/animation/utils/getAnimationTime';

import type {CSSModule} from '../../types';
import baseCss from './TabGroup.css';

/**
 * Number how many pixels we add for calculating if element go out of parent width.
 * It's because some devices have width calculation problems, such as Samsung A51
 */
const ERROR_MARGIN = window.IS_INTEGRATION_TEST_ENVIRONMENT ? 0 : 10;

type Value = string | number;

interface ChildProps {
  onClick: (event: MouseEvent, value: Value) => void;
  fullsize: boolean;
  fullHeight: boolean;
  oneLine: boolean;
  equalWidth: boolean;
}

interface ChildElement
  extends ReactElement<Partial<{active: boolean; value: Value} & ChildProps>> {}

const childrenWithProps = (
  children: ChildElement | ChildElement[],
  activeValue: Value,
  propsToPass: ChildProps,
) =>
  Children.map(children, (child) => {
    if (!isValidElement(child)) return null;
    return cloneElement(child, {
      active: activeValue
        ? child.props.value === activeValue
        : child.props.active,
      ...propsToPass,
    });
  });

const performAnimation = (node: HTMLDivElement) =>
  animate({
    to: [{scrollLeft: 0}, {scrollLeft: 80}, {scrollLeft: 0}],
    ease: easeInOut,
    duration: getAnimationTime({duration: 1000}),
    onUpdate: ({scrollLeft}) => node.scrollTo(scrollLeft, 0),
  });

// Need for horizontal positioning element with paddings
const getElementInlineScrollPosition = (target: Element) => {
  if (!target.previousElementSibling) {
    return 'end';
  }

  if (!target.nextElementSibling) {
    return 'start';
  }

  return 'center';
};

const scrollTabIntoView = (event: MouseEvent) => {
  const target = event.currentTarget;
  if (!target) {
    return;
  }

  const inlinePosition = getElementInlineScrollPosition(target);

  target.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: inlinePosition,
  });
};

export interface TabGroupProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  children: ChildElement | ChildElement[];
  className?: string;
  onChange?: (value: Value) => void;
  activeValue?: Value;
  rightAligned?: boolean;
  leftAligned?: boolean;
  fullHeight?: boolean;
  /**
   * Means, that tabs should fill all available height.
   */
  fullsize?: boolean;
  /**
   * Means, that if tab content doesn't fit into 100% width - it goes
   * in horizontal scroll.
   */
  outOfWidth?: boolean;
  /**
   * Means, that we remove mask-image
   */
  showOverflow?: boolean;
  equalTabsWidth?: boolean;
  withDemoAnimation?: boolean;
}

const DEFAULT_CSS: CSSModule = {};

/**
 * Container around tabs. Manages setting active state
 * and bubbling it up by 'onChange' callback.
 */
const TabGroup: FC<
  // `TabGroupProps` without `css` inside to make it more suitable for `@phoenix/ui`.
  TabGroupProps & {css?: CSSModule}
> = ({
  children,
  activeValue = null,
  className,
  leftAligned = false,
  rightAligned = false,
  fullHeight,
  fullsize = false,
  outOfWidth = false,
  showOverflow,
  onChange,
  withDemoAnimation = true,
  equalTabsWidth = false,
  css = DEFAULT_CSS,
  ...props
}) => {
  const [areChildrenLarger, setChildrenLarger] = useState(false);
  const outOfWidthAllowed = outOfWidth && areChildrenLarger;

  const handleClick = useEventCallback(
    (event: MouseEvent<HTMLButtonElement>, value: Value) => {
      if (outOfWidthAllowed) {
        scrollTabIntoView(event);
      }

      if (activeValue !== value) {
        onChange?.(value);
      }
    },
  );

  const ref = useRef<HTMLDivElement>();

  /**
   * Yep, this logic doesn't track things such screen resize. And it was intentionally
   * undone for avoiding "over engineering".
   */
  useLayoutEffect(() => {
    if (!outOfWidth) return;

    const updateTabsOverflow = () => {
      const wrapWidth = ref.current.clientWidth + ERROR_MARGIN;
      const childrenWidth = [...ref.current.children].reduce(
        (acc, child: HTMLElement) => {
          const style = window.getComputedStyle(child);

          return (
            acc +
            child.offsetWidth +
            parseInt(style.getPropertyValue('margin-left'), 10) +
            parseInt(style.getPropertyValue('margin-right'), 10)
          );
        },
        0,
      );
      const isChildrenLarger = wrapWidth < childrenWidth;

      setChildrenLarger(isChildrenLarger);

      /**
       * Perform animation on first mount targeted to illustrate to user that
       * he can scroll tabs.
       */
      if (
        withDemoAnimation &&
        isChildrenLarger &&
        !window.IS_INTEGRATION_TEST_ENVIRONMENT
      ) {
        performAnimation(ref.current);
      }
    };

    updateTabsOverflow();

    // Fix bug when, first render tabs happens before apply themed styles
    if (ref.current?.firstChild && window.IS_INTEGRATION_TEST_ENVIRONMENT) {
      const resizeObserver = new ResizeObserver(updateTabsOverflow);

      resizeObserver.observe(
        // I've not found the correct way to fix type mismatch
        // and it's not so important for tests.
        ref.current.firstChild as HTMLElement,
      );

      // eslint-disable-next-line consistent-return
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [withDemoAnimation, outOfWidth]);

  return (
    <div
      className={cn(
        css.wrap,
        className,
        outOfWidthAllowed && [css.outOfWidth, baseCss.outOfWidth],
        showOverflow && baseCss.showOverflow,
        (fullsize || fullHeight) && [css.fullsize, baseCss.fullsize],
      )}
    >
      <div
        ref={ref}
        className={cn(
          (leftAligned || outOfWidthAllowed) && baseCss.left,
          rightAligned && baseCss.right,
          css.group,
          baseCss.group,
        )}
        {...props}
      >
        {childrenWithProps(children, activeValue, {
          onClick: handleClick,
          fullsize: fullsize && !outOfWidthAllowed,
          fullHeight: fullHeight || (fullsize && outOfWidthAllowed),
          oneLine: outOfWidth,
          equalWidth: equalTabsWidth,
        })}
      </div>
    </div>
  );
};

export default TabGroup;
