import React from 'react';
import PropTypes from 'prop-types';
import {Field, getIn} from 'formik';

import Captcha from '@core/massBlocked/containers/Captcha';
import componentPropType from '@core/propTypes/component';

/**
 * Wrapper around captcha
 */
const FormCaptcha = ({
  name,
  error,
  validate,
  protectType,
  captchaLayout: Layout,
  ...props
}) => (
  <Field name={name} validate={validate}>
    {({form}) => {
      const formError = getIn(form.errors, name);

      const touched = getIn(form.touched, name);

      const onChange = (value) => form.setFieldValue(name, value || '');

      return (
        <Captcha
          {...props}
          onChange={onChange}
          protectType={protectType}
          error={error || (touched && formError)}
          layout={Layout}
        />
      );
    }}
  </Field>
);

FormCaptcha.propTypes /* remove-proptypes */ = {
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  validate: PropTypes.func,
  protectType: PropTypes.string,
  captchaLayout: componentPropType.isRequired,
};

export default FormCaptcha;
