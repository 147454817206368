/**
 * Saturation level of overlay
 * @see Overlay.tsx
 */
enum OverlaySaturation {
  TRANSPARENT = 'transparent',
  EXTRA_LITE = 'extraLight',
  LITE = 'light',
  MEDIUM = 'medium',
  HEAVY = 'heavy',
}

export default OverlaySaturation;
