enum BannerWrapperAppearance {
  /**
   * Banner with colorized substrate, ex. danger, primary, success and so on
   */
  COLORIZED = 'colorized',

  /**
   * Banner without colorized substrate, ex. danger, primary, success and so on
   */
  TRANSPARENT = 'transparent',

  /**
   * Banner with light substrate
   */
  LIGHT = 'light',

  /**
   * Banner with colorized substrate and spacing
   */
  COLORIZED_WITH_SPACING = 'colorizedWithSpace',

  /**
   * Banner with colorized substrate and without shadow
   */
  COLORIZED_WITHOUT_SHADOW = 'colorizedWithoutShadow',
}

export default BannerWrapperAppearance;
