import logger from '@core/logger';

import getPriceWithCurrency from './getPriceWithCurrency';

/**
 * Get the formatted package price with amount and currency
 * @param {Object} priceDetails - type with Price
 * @param {Boolean} withCurrency - true - add currency to the formatted price
 * @return {String} - formatted price with currency
 */
const getFormattedPrice = (priceDetails, withCurrency = true) => {
  const {formatted: {shortPrice, amount: formattedAmount} = {}} = priceDetails;

  /**
   * If we get shortPrice value than we should use it, otherwise should use amount
   */
  const amount = shortPrice || formattedAmount;

  if (withCurrency) {
    const {currency: {prefix, suffix} = {}} = priceDetails;

    if (!prefix && !suffix) {
      logger.sendError(
        `[getFormattedPrice] There is no 'currency' in the price data!`,
      );
    }

    return getPriceWithCurrency(amount, {
      prefix,
      suffix,
    });
  }

  return amount;
};

export default getFormattedPrice;
