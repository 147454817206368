import {MSG} from '../constants/socketEventNames';

export default {
  InteractionWebPushSubscription: {
    incomingEvents: [
      `${MSG}sendFirstMessage`,
      `${MSG}addFirstFavorite`,
      `${MSG}freeMessageFinished`,
      `${MSG}openProfile`,
      `${MSG}addLike`,
      `${MSG}sendSecondMessage`,
    ],

    fromServer: ({type, recipientId}) => ({data: {action: type, recipientId}}),
  },
};
