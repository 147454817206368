import type {PaymentProcessStep} from '../../types/paymentProcess';
import selectedAdditionalPackagesVar, {
  DEFAULT_VALUE,
} from '../../../widgets/package/graphql/vars/selectedAdditionalPackagesVar';

/**
 * Restore selected additional packages to default value after success send payment data
 */
const resetSelectedAdditionalPackages: PaymentProcessStep = async (data) => {
  const {paymentResult, paymentData} = data;

  if (paymentData.replaceRedirectToPostMessage) {
    return data;
  }

  if (paymentResult.status) {
    selectedAdditionalPackagesVar(DEFAULT_VALUE);
  }

  return data;
};

export default resetSelectedAdditionalPackages;
