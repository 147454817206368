import type {ReactNode, HTMLAttributes} from 'react';
import React, {forwardRef} from 'react';
import cn from 'classnames';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import type {CSSModule} from '../../types';
import type BarPosition from '../../constants/BarPosition';
import baseCss from './Bar.css';
import SpacingDirection from '../../constants/SpacingDirection';
import SpacingSize from '../../constants/SpacingSize';

export type BarProps = {
  children: ReactNode;
  className?: string;
  trackingName?: string;
  inverse?: boolean;
  light?: boolean;
  /**
   * If 'true' border will be over content.
   * Warning: doesn't work with border radius.
   */
  overContent?: boolean;
  direction?: SpacingDirection;
  position?: BarPosition;
  /**
   * Special prop, used only for theming that sets "transparent" background on certain themes
   * Usefull for situations where we should use double light card color.
   */
  transparent?: boolean;
  /**
   * Sometimes we use such bar as heading in popup. And in some themes
   * such bars are spaced on sides. For avoiding overlapping with close button we should apply additional styles to them
   */
  asPopupHeading?: boolean;
  withoutTop?: boolean;
  withoutBottom?: boolean;
  withoutLeft?: boolean;
  withoutRight?: boolean;
  rounded?: boolean;
  spacingSize?: SpacingSize;
} & HTMLAttributes<HTMLDivElement>;

const DEFAULT_CSS: CSSModule = {};

/**
 * Highlighted part of card, needed to make sidebars, headers, footers
 */
const Bar = forwardRef<
  HTMLDivElement,
  // `BarProps` without `css` inside to make it more suitable for `@phoenix/ui`.
  BarProps & {css?: CSSModule}
>(
  (
    {
      css = DEFAULT_CSS,
      children,
      className,
      trackingName,
      inverse = false,
      position,
      light = false,
      overContent = false,
      asPopupHeading = false,
      direction = SpacingDirection.BOTH,
      withoutTop = false,
      withoutBottom = false,
      withoutLeft = false,
      withoutRight = false,
      spacingSize = SpacingSize.NORMAL,
      rounded = true,
      transparent,
      ...props
    },
    ref,
  ) => {
    const content = (
      <div
        ref={ref}
        className={cn(
          baseCss.bar,
          css.bar,
          position && baseCss[position],
          position && css[position],
          inverse && baseCss.inverse,
          light && baseCss.light,
          light && css.light,
          overContent && baseCss.overContent,
          asPopupHeading && baseCss.asPopupHeading,
          asPopupHeading && css.asPopupHeading,
          transparent && baseCss.transparent,
          transparent && css.transparent,
          // Used only for "fresh" theme since there bar have outer spacing and we should manipulate with them in some way
          css[direction],
          className,
          // All styles below used only for "fresh" theme since there bar have outer spacing and we should manipulate with them in some way
          css[direction],
          spacingSize && css[spacingSize],
          rounded && css.rounded,
          withoutTop && css.withoutTop,
          withoutBottom && css.withoutBottom,
          withoutLeft && css.withoutLeft,
          withoutRight && css.withoutRight,
        )}
        {...props}
      >
        {children}
      </div>
    );

    return trackingName ? (
      <AddBabciaUBTracking trackingName={trackingName}>
        {content}
      </AddBabciaUBTracking>
    ) : (
      content
    );
  },
);

Bar.displayName = 'Bar';

export default Bar;
