import {getClientInstance} from '@core/graphql/client';
import logger from '@core/logger';

import type {PaymentProcessStep} from '../../types/paymentProcess';
import PAY_ANSWER_QUERY from '../../graphql/queries/payAnswer.gql';
import type {
  PayAnswerQuery,
  PayAnswerQueryVariables,
} from '../../graphql/queries/payAnswer';

/**
 * Update cache fail page logic
 * @param {value: String|Null, cachedData: Object}
 */
export const updateFailPageLogic = ({value, cachedData}) => {
  const client = getClientInstance();
  const data =
    cachedData ||
    client.readQuery<PayAnswerQuery, PayAnswerQueryVariables>({
      query: PAY_ANSWER_QUERY,
    });

  client.writeQuery<PayAnswerQuery, PayAnswerQueryVariables>({
    query: PAY_ANSWER_QUERY,
    data: {
      ...data,
      payment: {
        ...data.payment,
        payAnswer: {
          ...data.payment.payAnswer,
          failPageLogic: value,
        },
      },
    },
  });
};

/**
 * Reload packages after declined payment attempt
 * Packages will be reloaded only with decline via(failPageLogic)
 * @param {Object} paymentAnswer - payment flow data
 * @returns {Object}
 */
const reloadPackagesAfterDecline: PaymentProcessStep = async (data) => {
  // Read data from cache for easier cache update, without parsing paymentAnswer
  const payAnswer = getClientInstance().readQuery<
    PayAnswerQuery,
    PayAnswerQueryVariables
  >({query: PAY_ANSWER_QUERY});

  const {paymentResult} = data;
  const {failPageLogic, status} = paymentResult;

  if (status === false) {
    if (!failPageLogic) {
      logger.sendWarning(
        '[reloadPackagesAfterDecline] empty failPageLogic after decline',
      );
      return data;
    }

    // Set failPageLogic payAnswer param that will be picked up in usePaymentPackages and packages will be reloaded
    updateFailPageLogic({value: failPageLogic, cachedData: payAnswer});
  } else if (!failPageLogic && payAnswer.payment.payAnswer.failPageLogic) {
    // Reset failPageLogic if isn't exist
    updateFailPageLogic({value: null, cachedData: payAnswer});
  }

  return data;
};

export default reloadPackagesAfterDecline;
