import {LEVEL} from '@core/user/photo/constants';

type FormatMediaLevelResult = {
  level: LEVEL;
  blurLevel: LEVEL;
};

/**
 * Formatting for the correct level for the split.
 */
const formatMediaLevel = (
  level: LEVEL,
  blurLevel: LEVEL,
  forceBlur: boolean,
): FormatMediaLevelResult => {
  if (level === LEVEL.SASSY) {
    return {level: LEVEL.BLURRED, blurLevel: LEVEL.BLURRED};
  }

  // Blur video preview in chat of globalFreeMessages ended
  if (forceBlur) {
    return {level: LEVEL.HIGHLY_BLURRED, blurLevel: LEVEL.HIGHLY_BLURRED};
  }

  return {level, blurLevel};
};

export default formatMediaLevel;
