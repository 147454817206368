/**
 * List of all disapprove reasons for Media (photo and video)
 */
export default {
  ADVERTISING: 'advertising',
  CELEBRITIES: 'celebrities',
  CORRESPONDENCE: 'correspondence',
  ESCORT_SERVICE: 'escortService',
  SCAM: 'scam',
  SPAM: 'spam',
  NO_PEOPLE: 'noPeople',
  GROUP_PHOTO: 'groupPhoto',
  GROUP_VIDEO: 'groupVideo',
  GROUP_MEDIA: 'groupMedia',
  OFFCENSE: 'offense',
  GENDER_MISMATCH: 'genderMismatch',
  PERSONAL_DETAILS: 'personalDetails',
  PERSONAL_DETAILS_JOINED: 'personalDetailsJoined',
  VIOLENCE_PROFILE: 'violenceProfile',
  VIOLENCE_MESSAGES: 'violenceMessages',
  UNDER18: 'under18',
  SEXUAL: 'sexual',
};
