import type {ApolloClient, NormalizedCacheObject} from '@apollo/client';

import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';

import CURRENT_USER_PRIMARY_PHOTO_QUERY from '../../profile/current/graphql/queries/currentUserPrimaryPhoto.gql';
import type {CurrentUserPrimaryPhotoQuery} from '../../profile/current/graphql/queries/currentUserPrimaryPhoto';
import CURRENT_USER_PHOTOS_QUERY from '../../profile/current/graphql/queries/currentUserPhotos.gql';
import type {CurrentUserPhotosQuery} from '../../profile/current/graphql/queries/currentUserPhotos';
import PRIMARY_PHOTO_SUBSCRIPTION from '../../profile/current/graphql/subscriptions/primaryPhoto.gql';
import type {InteractionPrimaryPhotoSubscription} from '../../profile/current/graphql/subscriptions/primaryPhoto';

let subscription;

const startPrimaryPhotoListener = (
  client: ApolloClient<NormalizedCacheObject>,
  isCoinsModel: boolean,
) => {
  if (subscription) {
    return subscription;
  }

  subscription = client
    .subscribe<InteractionPrimaryPhotoSubscription>({
      query: PRIMARY_PHOTO_SUBSCRIPTION,
    })
    .subscribe(async ({data: {id, pendingDelete = false, updatedOn}}) => {
      const data = client.readQuery<CurrentUserPrimaryPhotoQuery>({
        query: CURRENT_USER_PRIMARY_PHOTO_QUERY,
      });

      if (!data) {
        console.log('CurrentUserPrimaryPhotoQuery read failed');
        return;
      }

      // The case when user removes his last photo
      let updatePrimaryPhoto = null;

      if (id) {
        const photosData = client.readQuery<CurrentUserPhotosQuery>({
          query: CURRENT_USER_PHOTOS_QUERY,
        });

        if (!photosData) {
          console.log('CurrentUserPhotosQuery read failed');
          return;
        }

        const newPrimaryPhoto = photosData.myUser.profile.photos.allPhotos.find(
          (photo) => photo.id === id,
        );

        updatePrimaryPhoto = {
          id,
          level: (newPrimaryPhoto && newPrimaryPhoto.level) || null,
          pendingDelete,
          updatedOn,
          createdOn: '0000-00-00 00:00:00',
          censored: false,
          privateAttributes: null,
          // In case if user have no photo there is no typename.
          __typename: 'PhotoType',
        };
      }

      if (pendingDelete && !id) {
        updatePrimaryPhoto = {
          ...data.myUser.profile.photos.primaryPhoto,
          pendingDelete,
        };
      }

      client.writeQuery<CurrentUserPrimaryPhotoQuery>({
        query: CURRENT_USER_PRIMARY_PHOTO_QUERY,
        data: {
          ...data,
          myUser: {
            ...data.myUser,
            profile: {
              ...data.myUser.profile,
              photos: {
                ...data.myUser.profile.photos,
                primaryPhoto: updatePrimaryPhoto,
              },
            },
          },
        },
      });

      /**
       * We update this field because it comes from backend when primary photo was uploaded.
       * @see useBlurForUsersWithoutPhoto
       */
      if (isCoinsModel) {
        invalidateCacheByTypename(
          client,
          'Credits',
          'blurMediaIncomeInMessenger',
        );
      }
    });

  return subscription;
};

export default startPrimaryPhotoListener;
