import type {FC, ReactNode} from 'react';
import React from 'react';

import type {InputFrameProps} from '../input/InputFrame';
import Triangle from '../triangle';
import Direction from '../../constants/Direction';
import css from './Select.css';

export interface SelectFrameProps extends Omit<InputFrameProps, 'children'> {
  active?: boolean;
  frameComponent: FC<InputFrameProps>;
  children: ReactNode;
}

/**
 * Just an imitation of select. Is used when we need a thing, that looks like
 * select, but uses custom dropdowns.
 * @see AdvancedSearchParams.js
 */
const SelectFrame: FC<SelectFrameProps> = ({
  frameComponent: Frame,
  active = false,
  children,
  ...props
}) => (
  <Frame {...props} animatedLabel={false}>
    <div className={css.select}>
      <div className={css.container}>
        <div className={css.value}>{children}</div>
        <Triangle direction={active ? Direction.TOP : Direction.BOTTOM} />
      </div>
    </div>
  </Frame>
);

export default SelectFrame;
