import includes from 'lodash/includes';
import find from 'lodash/find';

import {sessionStorage} from '@core/utils/storage/storage';
import logger from '@core/logger';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import {CURRENT_USER_ID} from '@core/application/constants/bootstrapParams';

import {INPAGE_DECLINE_VIAS} from '../../common/constants/declineVia';
import PAYMENT_ACTIONS from '../../common/constants/paymentActions';
import {TELEGRAM_CONVERSATION_VIA} from '../../common/constants/vias';
import {PAYMENT_SUCCESS_ORDERS_KEY} from '../constants/storageKeys';

const EXCLUDE_VIA_LIST = [
  PAYMENT_ACTIONS.MEMBERSHIP,
  PAYMENT_ACTIONS.FEATURES,
  PAYMENT_ACTIONS.MICRO_FEATURES,
  PAYMENT_ACTIONS.REMARKETING_OFFER,
  PAYMENT_ACTIONS.VERIFICATION,
  PAYMENT_ACTIONS.MINI_MEMBERSHIP,
  PAYMENT_ACTIONS.CREDIT,
  TELEGRAM_CONVERSATION_VIA,
  ...INPAGE_DECLINE_VIAS,
  'features_rm_popup',
  'laststepcard',
];

const PaymentPageSuccessOrders = {
  /**
   * @TODO: rewrite sessionStorage.setItem and sessionSorage.getItem to writeToSessionStorage и readFromSessionStorage
   */
  getStorageKey() {
    const userId = getBootstrapParam(CURRENT_USER_ID);

    if (!userId) {
      logger.sendWarning('[PaymentPageSuccessOrders] userId is not set');
      return PAYMENT_SUCCESS_ORDERS_KEY;
    }

    return `${PAYMENT_SUCCESS_ORDERS_KEY}:${userId}`;
  },

  /**
   * if current via is non-payment (which means that is new visit to PP)
   * reset tracked orderId's
   * @deprecated Used only on Backbone
   * @param {string} via
   * @public
   */
  clearStoredOrders(via) {
    if (!includes(EXCLUDE_VIA_LIST, via)) {
      this.clear();
    }
  },

  /**
   * remember current orderId for use in success page
   * @public
   * @param {string} orderId
   * @param {string} via
   */
  add(orderId, via) {
    if (
      includes(INPAGE_DECLINE_VIAS, via) ||
      includes(this.getIds(), orderId)
    ) {
      return;
    }

    const orders = this.get();

    orders.push({
      id: orderId,
      via,
      withNotification: true,
      viewed: false,
    });

    sessionStorage.setItem(this.getStorageKey(), orders);
  },

  /**
   * If user has seen payment notification no need to show it after reload a page.
   * @case User buy membership, see notification in features page, skip it and in remarketingOffer page
   * he sees notification again.
   * @public
   */
  isNeedShowNotification() {
    return this.get().find(
      ({withNotification, via}) =>
        withNotification &&
        ![PAYMENT_ACTIONS.FEATURES, PAYMENT_ACTIONS.REMARKETING_OFFER].includes(
          via,
        ),
    );
  },

  /**
   * If user has seen payment notification no need to show it after reload a page.
   * Current case: user buy membership, see notification in features page, skip it and in remarketingOffer page
   * he sees notification again.
   * @public
   */
  disableNotification() {
    const orders = this.get();

    orders.forEach((element) => {
      element.withNotification = false;
    });

    sessionStorage.setItem(this.getStorageKey(), orders);
  },

  /**
   * Set orders has been viewed on success page.
   */
  setViewed() {
    const orders = this.get();

    orders.forEach((order) => {
      order.viewed = true;
    });

    sessionStorage.setItem(this.getStorageKey(), orders);
  },

  /**
   * @return {Array}
   * @public
   */
  getIds() {
    return this.get().map(({id}) => id);
  },

  /**
   * When user buys features after member right away
   * he can cancel membership, on other cases, can cancel current oder
   * @param {string} orderId
   * @param {string} via
   * @return {string}
   * @public
   */
  formatOrderId(orderId, via) {
    if (via === PAYMENT_ACTIONS.FEATURES) {
      const orderByVia =
        find(this.get(), {
          via: PAYMENT_ACTIONS.MEMBERSHIP,
        }) || [];

      return orderByVia.id || orderId;
    }

    return orderId;
  },

  /**
   * Get orders count
   * @return {number}
   * @public
   */
  getCount() {
    return this.get().length;
  },

  getNotViewedOrders() {
    const orders = this.get();

    if (!orders.length) {
      return [];
    }

    return orders.filter(({viewed}) => {
      return viewed === false;
    });
  },

  getNotViewedOrderIds() {
    return this.getNotViewedOrders().map(({id}) => id);
  },

  hasNotViewedOrders() {
    return Boolean(this.getNotViewedOrders().length);
  },

  getNotViewedOrderVia() {
    return this.getNotViewedOrders()[0]?.via || 'unknown';
  },

  /**
   * @public
   */
  clear() {
    sessionStorage.removeItem(this.getStorageKey());
  },

  /**
   * @return {Array|Null}
   * @private
   */
  get() {
    return sessionStorage.getItem(this.getStorageKey(), []);
  },
};

export default PaymentPageSuccessOrders;
