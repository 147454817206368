import type {FC} from 'react';
import React from 'react';

import type {RowProps} from './Row';
import Row from './Row';
import SpacingSize from '../../constants/SpacingSize';

const RowShortest: FC<Omit<RowProps, 'space'>> = (props) => (
  <Row {...props} space={SpacingSize.SHORTEST} />
);

export default RowShortest;
