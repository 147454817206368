import {NOT_FOUND, PATH_SEPARATOR, PROTOCOL_SEPARATOR} from '../constants/uri';

const parseOrigin = (urlString: string): string => {
  const indexOfProtocol = urlString.indexOf(PROTOCOL_SEPARATOR);

  if (indexOfProtocol === NOT_FOUND) {
    return '';
  }

  const endOfHostname = urlString.indexOf(
    PATH_SEPARATOR,
    indexOfProtocol + PROTOCOL_SEPARATOR.length,
  );

  if (endOfHostname === NOT_FOUND) {
    return urlString;
  }

  return urlString.slice(0, endOfHostname);
};

export default parseOrigin;
