const PATTERN = /\s/g;

/**
 * Clear card value, remove inner spaces
 * @param {String} value
 * @returns {String}
 */
const clearCardValue = (value) => {
  return value.replace(PATTERN, '');
};

export default clearCardValue;
