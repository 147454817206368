import type {Client} from '@core/graphql/types';

import type {GlobalSettingsCacheDependenciesQuery} from '../graphql/queries/globalSettingsCacheDependencies';
import GLOBAL_SETTINGS_CACHE_DEPENDENCIES from '../graphql/queries/globalSettingsCacheDependencies.gql';

/**
 * Executes callback in case of change of such global settings as:
 * 1. Naughty Mode
 * 2. Safe Mode
 * 3. Cross Vision settings
 *
 * Mainly used for invalidation of user data and re-querying them
 * from server, e.g. search, stories etc.
 *
 * @param client - Apollo client instance
 * @param onChange - Triggered when dependencies changed
 * @param ignoreNaughtyModeIncrease - if true, onChange will not be triggered after naughtyMode increase
 */
const globalSettingsCacheWatcher = (
  client: Client,
  onChange: (client?: Client) => void,
  ignoreNaughtyModeIncrease = false,
) => {
  let prevData: GlobalSettingsCacheDependenciesQuery;

  client
    .watchQuery<GlobalSettingsCacheDependenciesQuery>({
      query: GLOBAL_SETTINGS_CACHE_DEPENDENCIES,
    })
    .subscribe(({data}) => {
      /**
       * Apollo run callback each time when at least one field changed
       */
      if (
        prevData &&
        data !== prevData &&
        (!ignoreNaughtyModeIncrease ||
          data.userFeatures.naughtyMode.naughtyMode <=
            prevData.userFeatures.naughtyMode.naughtyMode)
      ) {
        onChange(client);
      }
      prevData = data;
    });
};

export default globalSettingsCacheWatcher;
