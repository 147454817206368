import {MSG} from '../constants/socketEventNames';

/**
 * Only for SPD
 * Triggered when somebody created match on event with current user.
 */

export default {
  SpdMatchSubscription: {
    incomingEvents: [`${MSG}spdMatch`],

    fromServer: ({eventId, fromId}) => ({
      data: {
        spdMatch: {
          __typename: 'spdMatch',
          eventId,
          userId: fromId,
        },
      },
    }),
  },
};
