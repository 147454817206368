export enum ApplePaySDKVersion {
  /** Lowest version */
  VERSION_1 = 1,
  /** Version contains breaking changes */
  VERSION_3 = 3,
  /** Full support for billing/shipping contacts */
  VERSION_10 = 10,
  /** Latest version */
  VERSION_14 = 14, // Latest version
}
