import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import loadable from '@loadable/component';

import setTextDirection from '@core/utils/textDirection/setTextDirection';
import getThemeName from '@core/application/utils/getThemeName';
import getParameter from '@core/application/utils/getParameter';

import yiiT from './utils/yii_t';
import getLocale from './utils/getLocale';
import setDayjsLocale from './setDayjsLocale';
import LOCALES from './constants/locales';
import DAYJS_LOCALES from './constants/dayjsLocales';
import {THEME_DELIMITER} from './constants/themedConstants';
import ENTRY_POINTS from '../../../../constants/entryPoints';

const addDictionaryOnce = memoizeOne((translations) => {
  /**
   * @deprecated Should be rewritten with universal function based on
   * @see src/packages/utils/translate/translate.ts
   */
  yiiT.addToDict(translations);
});

/**
 * Temporary solution for force theme
 * @type {string}
 */
const getThemeForTranslation = () => {
  if (getParameter('entry') === ENTRY_POINTS.SPEED_DATING) {
    return ENTRY_POINTS.SPEED_DATING;
  }

  return getThemeName();
};

const selectLocale = (locale) => LOCALES[locale.toUpperCase()] || LOCALES.EN;

const Po = loadable.lib((props) => import(`messages/${props.name}.po`), {
  cacheKey: ({name}) => name,
});

const Dayjs = loadable.lib(
  (props) => import(`dayjs/locale/${props.locale}.js`),
  {cacheKey: ({locale}) => locale},
);

const setTextDirectionOnce = memoizeOne(setTextDirection);

const poPreloadOnce = memoizeOne((name) => {
  // To load in parallel with Dayjs
  Po.preload({name});
});

const setDayjsLocaleOnce = memoizeOne(setDayjsLocale);

const TranslationsLoader = ({children}) => {
  const locale = getLocale();
  const theme = getThemeForTranslation();

  const fallback = children(false);

  if (!locale) return fallback;

  setTextDirectionOnce(locale);

  if (window.IS_INTEGRATION_TEST_ENVIRONMENT) {
    return children(true);
  }

  const poLocale = selectLocale(locale);
  const dayjsLocale = DAYJS_LOCALES[poLocale];

  const namePo = `${theme}${THEME_DELIMITER}${poLocale}`;

  poPreloadOnce(namePo);

  return (
    <Dayjs locale={dayjsLocale} fallback={fallback}>
      {() => (
        <Po name={namePo} fallback={fallback}>
          {(translations) => {
            setDayjsLocaleOnce(locale);
            addDictionaryOnce(translations);
            return children(true);
          }}
        </Po>
      )}
    </Dayjs>
  );
};

TranslationsLoader.propTypes /* remove-proptypes */ = {
  children: PropTypes.func.isRequired,
};

export default TranslationsLoader;
