import type {GenderTypeEnum} from '@core/types';
import {SexualOrientationAttribute} from '@core/types';

import isFemale from './isFemale';

const isLesbian = (
  gender: GenderTypeEnum | number,
  sexualOrientation: SexualOrientationAttribute,
) =>
  isFemale(gender) &&
  sexualOrientation === SexualOrientationAttribute.HOMOSEXUAL;

export default isLesbian;
