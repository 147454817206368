type SetStyleNodeOptions = {
  id: string;
  styles: string;
};

/**
 * Create/update style node by id.
 * @param id - style node id.
 * @param styles - CSS content.
 */
const setStyleNode = ({id, styles}: SetStyleNodeOptions) => {
  const styleNode = document.getElementById(id);

  if (styleNode) {
    styleNode.textContent = styles;
  } else {
    const style = document.createElement('style');

    style.type = 'text/css';
    style.id = id;
    style.textContent = styles;

    document.head.appendChild(style);
  }
};

export default setStyleNode;
