/* eslint-disable react/no-multi-comp */
import type {
  ReactNode,
  MutableRefObject,
  LegacyRef,
  ReactElement,
  ComponentType,
  ForwardedRef,
} from 'react';
import React, {
  Children,
  cloneElement,
  isValidElement,
  PureComponent,
  forwardRef,
} from 'react';
import cn from 'classnames';
import isFunction from 'lodash/isFunction';
import hoistStatics from 'hoist-non-react-statics';

import PopperPlacement from '../../constants/PopperPlacement';
import PopperTrigger from '../../constants/PopperTrigger';
import type {PopoverProps} from '../popper/Popover';
import type {MenuItemChangeHandler, MenuItemValue} from './types';
import type {MenuItemProps} from './MenuItem';
import css from './Menu.css';

export interface MenuProps {
  popoverComponent?: ComponentType<
    PopoverProps & {
      ref: MutableRefObject<HTMLElement>;
    }
  >;
  content:
    | ReactNode
    | ReactElement
    | ((props: {inverse: boolean}) => ReactElement[]);
  popoverRef?: MutableRefObject<HTMLElement>;
  parentRef?: MutableRefObject<HTMLElement>;
  boundariesRef?: MutableRefObject<string>;
  trigger?: PopperTrigger;
  children?: ReactElement;
  className?: string;
  /** See Popper.js for description of possible positions */
  placement?: PopperPlacement;
  usePortal?: boolean;
  /** Close by menu item click */
  closeByInsideClick?: boolean;
  showArrow?: boolean;
  fullWidth?: boolean;
  beyondBorders?: boolean;
  fixedHeight?: boolean;
  fullHeight?: boolean;
  positionFixed?: boolean;
  active?: boolean | null;
  nowrap?: boolean;
  /** Calls when menu item is selected */
  onChange?: MenuItemChangeHandler;
  /** Calls on menu show/hide */
  onActiveChange?: (state: boolean) => void;
  hideByScroll?: boolean;
  useReferenceWidth?: boolean;
  trackingName?: string | null;
}

type MenuInnerProps = MenuProps & {
  forwardedRef?: LegacyRef<HTMLDivElement>;
};

type MenuInnerState = {
  active: boolean;
};

/**
 * @class MenuInner
 * @classdesc Wrapping component around popover to make menu look great
 */
class MenuInner extends PureComponent<MenuInnerProps, MenuInnerState> {
  static defaultProps = {
    placement: PopperPlacement.BOTTOM_END,
    closeByInsideClick: true,
    usePortal: true,
    showArrow: true,
    fullHeight: false,
    trigger: PopperTrigger.CLICK,
    active: null,
    nowrap: false,
    boundariesRef: {
      current: 'scrollParent',
    },
  };

  static getDerivedStateFromProps({active}) {
    // The component may be controlled or uncontrolled
    return active !== null ? {active} : null;
  }

  constructor(props: MenuInnerProps) {
    super(props);

    this.state = {active: props.active};
  }

  /**
   * @param {Object} data
   * @param {Boolean} data.inverse
   */
  renderContent = ({inverse}: {inverse: boolean}) => {
    const {content, nowrap, forwardedRef, fullHeight} = this.props;

    /**
     * Content can arrive as 'render-prop'
     */
    const extractedContent = isFunction(content) ? content({inverse}) : content;

    /**
     * Only one element indicates that it's a fragment.
     * Because there is no way to pass as prop array of items
     */
    const filteredContent = Array.isArray(extractedContent)
      ? extractedContent
      : (extractedContent as ReactElement)?.props.children ?? null;

    const menuItem = Children.map(
      filteredContent,
      (child: ReactElement<MenuItemProps>) => {
        if (!isValidElement(child)) {
          return null;
        }

        return cloneElement<MenuItemProps>(child, {
          onParentClick: this.handleClick,
          inverse,
          nowrap,
        });
      },
    );

    return (
      <div
        ref={forwardedRef}
        className={cn(css.menu, fullHeight && css.fullHeight)}
      >
        {menuItem}
      </div>
    );
  };

  handleClick = (value: MenuItemValue) => {
    const {onChange, closeByInsideClick} = this.props;
    // Bubble event up
    onChange && onChange(value);
    closeByInsideClick && this.handleActiveChange(false);
  };

  handleActiveChange = (active: boolean) => {
    if (active === this.state.active) {
      return;
    }

    this.setState({active});

    this.props.onActiveChange && this.props.onActiveChange(active);
  };

  render() {
    const {
      popoverComponent: Popover,
      children,
      onChange,
      onActiveChange,
      closeByInsideClick,
      trigger,
      fullHeight,
      boundariesRef,
      trackingName,
      popoverRef,
      ...props
    } = this.props;

    return (
      <Popover
        {...props}
        trackingName={trackingName && `${trackingName}Menu`}
        active={this.state.active}
        onActiveChange={this.handleActiveChange}
        spaced={false}
        trigger={trigger}
        fullHeight={fullHeight}
        content={this.renderContent}
        ref={popoverRef}
      >
        {children}
      </Popover>
    );
  }
}

export type MenuComponent = (
  props: MenuProps,
  ref: ForwardedRef<HTMLDivElement>,
) => ReactNode;

const Menu: MenuComponent = (props, ref) => {
  return <MenuInner {...props} forwardedRef={ref} />;
};

const MenuForwardRef = hoistStatics(forwardRef(Menu), MenuInner);

export default MenuForwardRef;
