import {getClientInstance} from '@core/graphql/client';
import {ApplePaySDKVersion} from '@core/payment/payProcess/constants/ApplePaySDKVersion';

import {execApplePayFeature} from './applePay';
import type {
  ApplePayUserDetailsMutation,
  ApplePayUserDetailsMutationVariables,
} from '../graphql/mutations/applePayUserDetails';
import APPLE_PAY_USER_DETAILS_MUTATION from '../graphql/mutations/applePayUserDetails.gql';

const handleApplePayBillingData =
  execApplePayFeature<ApplePayJS.ApplePayPaymentAuthorizedEvent>(
    ApplePaySDKVersion.VERSION_10, // The version of Apple Pay available in macOS 11 and iOS 14.
    (response) => {
      const {payment} = response;

      const stringifiedPaymentResult = JSON.stringify(payment);
      const client = getClientInstance();

      return client.mutate<
        ApplePayUserDetailsMutation,
        ApplePayUserDetailsMutationVariables
      >({
        mutation: APPLE_PAY_USER_DETAILS_MUTATION,
        variables: {
          response: stringifiedPaymentResult,
        },
      });
    },
  );

export default handleApplePayBillingData;
