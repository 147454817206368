/**
 * Means that the logic is not available.
 */
export const NOT_ALLOWED = 'NOT_ALLOWED';

/**
 * Means that logic is allowed but there is
 * a request in progress and we are trying to make a lot of requests
 */
export const LIMIT_REACHED = 'LIMIT_REACHED';

/**
 * Some unpredictable error on the server happened.
 */
export const SERVER_ERROR = 'SERVER_ERROR';
