import type {PaymentProcessStepData} from '@core/payment/payProcess/types/paymentProcess';
import type {PaymentDataResponseOrStatus} from '@core/payment/payProcess/types/paymentFlow';

const processPaymentData = (
  data: PaymentProcessStepData,
  result: PaymentDataResponseOrStatus,
): PaymentProcessStepData => {
  if (typeof result === 'string') {
    return {
      ...data,
      flowStatus: result,
    };
  }

  return {
    ...data,
    paymentResult: result,
  };
};

export default processPaymentData;
