import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import forEach from 'lodash/forEach';

type Primitive = string | number | boolean;
export type BodyParams = Record<
  string,
  Primitive | Record<string, unknown> | [] | null
>;
type Value = BodyParams | Primitive | [];
type AddMethod = (key: string, value: Primitive) => void;

// "Space sign" in URL-encoding
const R20 = /%20/g;

function buildParams(prefix: string, param: Value, add: AddMethod): void {
  if (isObject(param)) {
    // Serialize object item
    forEach(param, (v: Value, k: string): void => {
      buildParams(`${prefix}[${k}]`, v, add);
    });
  } else {
    // Serialize scalar item
    add(prefix, param);
  }
}

/**
 * Set of key/values into a query string
 */
const nestedObjectForUrlSearchParams = (obj: BodyParams): string => {
  const s = [];

  const add: AddMethod = (key, value) => {
    const val = isNil(value) ? '' : value;
    s[s.length] = `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
  };

  forEach(obj, (v: Value, k: string): void => {
    buildParams(k, v, add);
  });

  return s.join('&').replace(R20, '+');
};

export default nestedObjectForUrlSearchParams;
