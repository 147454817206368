import React, {createElement} from 'react';
import PropTypes from 'prop-types';
import {Field, getIn} from 'formik';
import isFunction from 'lodash/isFunction';

import componentPropType from '@core/propTypes/component';

/**
 * Wrapper around abstract input widget to make it working without
 * passing additional props with 'Formik' forms
 */
const AbstractFormField = ({
  component,
  value,
  validate,
  name,
  parser,
  error,
  onChange,
  onBlur,
  ...props
}) => (
  <Field name={name} data-test={name} validate={validate}>
    {({field, form}) => {
      const formError = getIn(form.errors, name);
      const touched = getIn(form.touched, name);
      const {onChange: formikOnChange, onBlur: formikOnBlur, ...rest} = field;
      const handleChange = (event) => {
        const shouldContinue = onChange && onChange(event);
        // Skip default behaviour if onChange return 'false'
        if (shouldContinue === false) {
          return;
        }
        if (isFunction(parser)) {
          form.setFieldValue(name, parser(event.target.value));
        } else {
          formikOnChange(event);
        }
      };
      const handleBlur = (event) => {
        const shouldContinue = onBlur && onBlur(event);
        if (shouldContinue === false) {
          return;
        }
        formikOnBlur(event);
      };

      const customValue = isFunction(value) ? value(rest.value) : value;

      return createElement(component, {
        onChange: handleChange,
        onBlur: handleBlur,
        ...rest,
        ...props,
        ...(customValue ? {value: customValue} : {}),
        error: error || (touched && formError),
      });
    }}
  </Field>
);

AbstractFormField.propTypes /* remove-proptypes */ = {
  component: componentPropType.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    // Used for customizing values, in case when we have machine name inside formik
    // but we need to show on frontend custom translation.
    PropTypes.func,
  ]),
  validate: PropTypes.func,
  parser: PropTypes.func,
  formatter: PropTypes.func,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export default AbstractFormField;
