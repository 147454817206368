import type {FC, ReactElement, ChangeEvent} from 'react';
import React, {forwardRef, cloneElement, useCallback, useState} from 'react';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import type {BaseInputProps, BaseInputRenderProps} from './BaseInput';
import type {HTMLElementWithValue} from './types';

interface InputInnerProps extends Omit<BaseInputProps, 'children'> {
  inputComponent: FC<BaseInputProps>;
  value?: string;
  children?: ReactElement;
}

const InputInner = forwardRef<HTMLElementWithValue, InputInnerProps>(
  ({inputComponent: BaseInput, children = null, ...props}, ref) => {
    const [size, setSize] = useState(props.value?.length || 0);

    const handleChange = useCallback((e: ChangeEvent<HTMLElementWithValue>) => {
      const {value} = e.target;
      if (value) {
        setSize(value.length);
      }
    }, []);

    return (
      <BaseInput {...props}>
        {(inputProps: BaseInputRenderProps): ReactElement =>
          cloneElement(children || <input />, {
            ...inputProps,
            onChange: (e: ChangeEvent<HTMLElementWithValue>) => {
              inputProps.onChange?.(e);
              handleChange(e);
            },
            ref,
            size,
          })
        }
      </BaseInput>
    );
  },
);

export interface InputProps extends InputInnerProps {
  name?: string;
  trackingName?: string;
  disabled?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({trackingName, name, disabled, ...props}, ref) => (
    <AddBabciaUBTracking
      trackingName={
        (trackingName || name) && !disabled
          ? `${trackingName || name}Input`
          : null
      }
    >
      <InputInner
        name={name}
        disabled={disabled}
        // `onClick` is not required for input to use tracking:
        // eslint-disable-next-line local-rules/no-non-clickable-tracking
        {...props}
        ref={ref}
      />
    </AddBabciaUBTracking>
  ),
);

export default Input;
