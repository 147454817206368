import {Base64} from 'js-base64';

const BASE64_DELIMITER = '#@#';

export const decodeCopyright = (text: string) => {
  // Base64 encoding algorithm. Original code can be found here:
  // protected/assets/_default/plugins/noIndex/noIndex.js (phoenix_core repo)
  const decodedText = Base64.decode(text.trim());
  const delimiterPosition = decodedText.indexOf(BASE64_DELIMITER);
  if (delimiterPosition !== -1) {
    return decodedText.substring(0, delimiterPosition);
  }

  // TODO: remove reverseLetter legacy decoding and leave only base64 with delimiter.
  return decodedText.replace(/[a-zA-Z]/g, (key) => {
    const c = key.charCodeAt(0) + 13;
    return String.fromCharCode(c - 26 * Number((key <= 'Z' ? 90 : 122) < c));
  });
};

/**
 * Checks if copyright contains base64 decoded text with delimiter
 */
export const isBase64EncodedWithDelimiter = (text: string) => {
  return Base64.decode(text).indexOf(BASE64_DELIMITER) !== -1;
};

export const transformNoIndex = (elm: HTMLElement) => {
  const script = elm.querySelector('script');
  if (script) {
    // skip additional script loading
    window.NO_INDEX_SCRIPT_INIT = true;

    // remove script and re-create in current document context to make it executable.
    script.parentNode.removeChild(script);
    const newScript = document.createElement('script');
    newScript.innerHTML = script.innerHTML;
    // execute script before code below to fill window.NO_INDEX_CLASS_NAMES
    document.querySelector('head').appendChild(newScript);
  }

  const noIndexClasses = window.NO_INDEX_CLASS_NAMES;

  if (noIndexClasses) {
    noIndexClasses.forEach((className) => {
      const item = elm.querySelector(`.${className}`) as HTMLElement;
      if (!item) {
        return;
      }

      item.innerHTML = decodeCopyright(item.innerHTML);
      // Server markup have 'visibility: hidden' property. Need to remove it
      item.style.visibility = 'visible';
    });
  }
};

export const transformHash = (elm: HTMLElement | null) => {
  if (elm) {
    elm.innerHTML = atob(elm.innerHTML);
  }
};

export const processCopyright = (elm: HTMLElement) => {
  /**
   * There are few cases with copyright:
   * 1. It can arrive in hash, with <script> tag that contains another hash used to decode copyright
   * 2. It can arrive in just base64 coded style. We just decode it
   * @see plugins/processText
   */
  transformNoIndex(elm);
  transformHash(elm.querySelector('._hashCopyright'));
};
