import type {PaymentProcessStep} from '../../types/paymentProcess';
import getBrowserDetails from '../../utils/getBrowserDetails';

const addBrowserDetails: PaymentProcessStep = async (data) => {
  const {paymentData} = data;

  return {
    ...data,
    paymentData: {
      ...paymentData,
      browserDetails: getBrowserDetails(),
    },
  };
};

export default addBrowserDetails;
