import type {DocumentNode} from 'graphql';
import type {FetchResult, ApolloCache} from '@apollo/client';
import {gql} from '@apollo/client';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import type {
  ErrorObject,
  NormalizedErrors,
} from '@core/graphql/utils/normalizeMutationErrors';
import normalizeMutationErrors from '@core/graphql/utils/normalizeMutationErrors';
import {getClientInstance} from '@core/graphql/client';

import createMutation from './createMutation';

type TryToSaveDataReturn = Promise<void> | null;

type TryToSaveDataParams<TData, TVariables> = {
  values: TData;
  initialValues: TData;
  mutation?: DocumentNode;
  createMutation?: (preparedData: TVariables) => string;
  setSubmitting: (isSubmitting: boolean) => void;
  setErrors: (errors: NormalizedErrors) => void;
  getErrorsResponse: (response: FetchResult<TData>) => ErrorObject;
  prepareInfoBeforeSend: (values: TData, initialValues: TData) => TVariables;
  mutationRefetchQueries?: DocumentNode[];
  mutationUpdateCacheResolver: (
    cache: ApolloCache<object>,
    response: FetchResult<TData>,
    values: TData,
  ) => void;
  handleClose?: () => void;
};

/**
 * Try to save profile user attributes.
 * If trying save not changed data - just is calling handleClose, with out sending request to server
 * If data changed - sending request to server. If no errors from server - just is calling handleClose.
 * If have errors from server - setErrors.
 * @see EditableInfo or
 * @see ProfileAccordion
 */
const tryToSaveData = <TData, TVariables extends Record<string, unknown>>({
  values,
  initialValues,
  mutation,
  createMutation: customCreateMutation,
  setSubmitting,
  setErrors,
  getErrorsResponse,
  prepareInfoBeforeSend,
  mutationRefetchQueries,
  mutationUpdateCacheResolver,
  handleClose,
}: TryToSaveDataParams<TData, TVariables>): TryToSaveDataReturn => {
  // If values are identical to original ones - just switch back
  if (isEqual(values, initialValues)) {
    setSubmitting(false);
    handleClose && handleClose();
    return null;
  }

  const client = getClientInstance();
  const preparedData = prepareInfoBeforeSend(values, initialValues);

  return client
    .mutate<TData, TVariables>({
      mutation:
        mutation || gql((customCreateMutation || createMutation)(preparedData)),
      variables: preparedData,
      update: (cache, response) =>
        mutationUpdateCacheResolver(cache, response, values),
      refetchQueries: mutationRefetchQueries,
    })
    .then((response) => {
      const errors = normalizeMutationErrors(getErrorsResponse(response));

      setSubmitting(false);
      setErrors(errors);

      if (isEmpty(errors)) {
        handleClose && handleClose();
      }
    });
};

export default tryToSaveData;
