/* eslint-disable react/no-multi-comp */
import type {ReactElement, ReactNode, MouseEvent, ForwardedRef} from 'react';
import React, {forwardRef, cloneElement, isValidElement} from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';
import createRouteName from '@core/tracking/babcia/utils/createRouteName';
import isDeviceWithTouchScreen from '@core/utils/device/isDeviceWithTouchScreen';
import useEventCallback from '@core/utils/react/useEventCallback';

import type {MenuItemContentProps} from './MenuItemContent';
import type {MenuItemValue, MenuItemChangeHandler} from './types';
import css from './MenuItem.css';

type MenuItemChildren = ReactNode | ReactElement<MenuItemContentProps>;

export type MenuItemClickHandler = (
  value: MenuItemValue,
  e?: MouseEvent<HTMLElement>,
) => void;

export type MenuItemProps = {
  className?: string;
  children: MenuItemChildren;
  'data-test'?: string;
  active?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  muted?: boolean;
  /** Those value will be bubbled up on tree */
  value?: MenuItemValue;
  to?: string;
  target?: string;
  onClick?: MenuItemClickHandler;
  // We need to support 2 handlers and mix actions from passed by above component props
  // and current component together.
  onParentClick?: MenuItemChangeHandler;
  spaced?: boolean;
  nowrap?: boolean;
  inverse?: boolean;
  parse?: (children: MenuItemChildren) => ReactNode;
  icon?: string;
  trackingName?: string | null;
  id?: string;
};

const defaultParse = (value: MenuItemChildren) => value;

const MenuItem = forwardRef<HTMLElement, MenuItemProps>(
  (
    {
      onClick,
      onParentClick,
      value = null,
      disabled = false,
      hidden = false,
      trackingName,
      muted = false,
      active = false,
      className,
      'data-test': dataTest,
      spaced = false,
      nowrap = false,
      inverse,
      children,
      parse = defaultParse,
      to,
      ...rest
    },
    ref,
  ) => {
    const handleClick = useEventCallback((e: MouseEvent<HTMLElement>) => {
      if (disabled) return;
      if (onParentClick) onParentClick(value);
      if (onClick) onClick(value, e);
    });

    const babciaName = trackingName || (to ? createRouteName(to) : null);
    const child = isValidElement(children)
      ? cloneElement<MenuItemContentProps>(
          // type casting, because in this case we assume that children not ReactNode but MenuItems
          children as ReactElement<MenuItemContentProps>,
          {
            inverse,
          },
        )
      : children;

    const props = {
      'data-test': dataTest || 'menuItem',
      className: cn(
        isDeviceWithTouchScreen && css.touch,
        css.item,
        className,
        inverse && css.inverse,
        active && css.active,
        spaced && css.spaced,
        nowrap && css.nowrap,
        disabled && css.disabled,
        hidden && css.hidden,
        muted && css.muted,
        !onClick && !onParentClick && !to && css.static,
      ),
      onClick: handleClick,
      role: 'menuitem',
      tabIndex: 0,
      children: parse(child),
      ...rest,
    };

    return (
      <AddBabciaUBTracking
        trackingName={!disabled && babciaName ? `${babciaName}MenuItem` : null}
      >
        {to ? (
          <Link
            {...props}
            to={to}
            ref={ref as ForwardedRef<HTMLAnchorElement>}
          />
        ) : (
          <div {...props} ref={ref as ForwardedRef<HTMLDivElement>} />
        )}
      </AddBabciaUBTracking>
    );
  },
);

export default MenuItem;
