import dayjs from '@core/date/utils/dayjs';
import t from '@core/translations/translate';
import logger from '@core/logger';

import type {Timestamp} from './types';

const formatDate = (
  timestamp?: Timestamp,
  dateFormat?: string,
): string | null => {
  if (!timestamp) {
    logger.sendWarning('TimeHelper: empty timestamp');
  }

  return dayjs.unix(Number(timestamp)).calendar(null, {
    sameDay: `[${t('timeHelper', 'text.today')}]`,
    lastDay: `[${t('timeHelper', 'text.yesterday')}]`,
    lastWeek: 'dddd',
    sameElse: dateFormat,
  });
};

export default formatDate;
