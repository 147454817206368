import type {History} from 'history';

/**
 * Modifies history instance to use original window.history.length.
 * History instance from {@see createBrowserHistory} doesn't update
 * its length if history changed by iframe.
 * @param history - history object to fix length.
 */
export default function enhanceHistoryLength(history: History) {
  delete history.length;
  Object.defineProperty(history, 'length', {
    get: () => window.history.length,

    /**
     * To avoid errors when {@see createBrowserHistory} tries to change length.
     */
    set: () => {},
  });
}
