/**
 * List of possible actions that can be performed via mutation
 * @see confirmationEmail.gql
 */
const MAIL_ACTION = {
  CHANGE_EMAIL: 'changeEmail',
  RESEND_EMAIL: 'resendToOldMail',
};

export default MAIL_ACTION;
