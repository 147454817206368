import {useReactiveVar} from '@apollo/client';

import isPopupOpenVar from '@core/graphql/vars/isPopupOpenVar';

/**
 * Returns `true` if at least one popup currently open.
 */
const useIsPopupOpen = (): boolean => {
  return useReactiveVar(isPopupOpenVar);
};

export default useIsPopupOpen;
