import t from '@core/translations/translate';
import logger from '@core/logger';

const MEDIA_ERR_ABORTED = 'MEDIA_ERR_ABORTED';
const MEDIA_ERR_NETWORK = 'MEDIA_ERR_NETWORK';
const MEDIA_ERR_DECODE = 'MEDIA_ERR_DECODE';
const MEDIA_ERR_SRC_NOT_SUPPORTED = 'MEDIA_ERR_SRC_NOT_SUPPORTED';
const MEDIA_ERR_UNKNOWN = 'MEDIA_ERR_UNKNOWN';

/**
 * Errors indexed by the W3C standard. The order **CANNOT CHANGE**! See the
 * specification listed under {@link MediaError} for more information.
 *
 * Shamelessly borrowed from
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaError/code#Media_error_code_constants}
 *
 * @enum {Object}
 * @readonly
 * @property {String} 1 - MEDIA_ERR_ABORTED
 * @property {String} 2 - MEDIA_ERR_NETWORK
 * @property {String} 3 - MEDIA_ERR_SRC_NOT_SUPPORTED
 * @property {String} 4 - MEDIA_ERR_ENCRYPTED
 */
const ERROR_TYPES = {
  1: MEDIA_ERR_ABORTED,
  2: MEDIA_ERR_NETWORK,
  3: MEDIA_ERR_DECODE,
  4: MEDIA_ERR_SRC_NOT_SUPPORTED,
};

/**
 * @class MediaError
 * @author Ilya Zub <ilya.zub@together.com>
 */
export default class MediaError {
  /**
   * @param {{code: number, message: string}} error
   */
  constructor(error) {
    this.error = error;
  }

  /**
   * @return {Boolean}
   * @public
   */
  unsupportedCodec() {
    return this.type === MEDIA_ERR_SRC_NOT_SUPPORTED;
  }

  /**
   * @return {String}
   * @public
   */
  get message() {
    switch (this.type) {
      case MEDIA_ERR_ABORTED:
        return t('uploadButtonMessages', 'error.MEDIA_ERR_ABORTED');
      case MEDIA_ERR_NETWORK:
        return t('uploadButtonMessages', 'error.MEDIA_ERR_NETWORK');
      case MEDIA_ERR_DECODE:
        return t('uploadButtonMessages', 'error.MEDIA_ERR_DECODE');
      case MEDIA_ERR_SRC_NOT_SUPPORTED:
        return t('uploadButtonMessages', 'error.MEDIA_ERR_SRC_NOT_SUPPORTED');
      case MEDIA_ERR_UNKNOWN:
        return t('uploadButtonMessages', 'error.MEDIA_ERR_UNKNOWN');
      default:
        logger.sendWarning(
          `LOST TRANSLATE: (feature: uploadButtonMessages, alias: ${
            this.type
          })`,
        );
        return this.type;
    }
  }

  /**
   * @return {String}
   * @private
   */
  get type() {
    return ERROR_TYPES[this.error.code] || MEDIA_ERR_UNKNOWN;
  }
}
