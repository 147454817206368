import {useEffect} from 'react';
import {useLazyQuery, useMutation} from '@apollo/client';

import {
  readFromSessionStorage,
  writeToSessionStorage,
} from '@core/utils/storage';
import logger from '@core/logger';

import DEVICE_DETECTION_TRACK_MUTATION from '../graphql/mutations/deviceDetectionTrack.gql';
import DEVICE_DETECTION_SCRIPT_QUERY from '../graphql/queries/deviceDetectionScript.gql';

export const STORAGE_KEY = 'DEVICE_DETECTION_EXEC_ONCE';

const isDetected = () => readFromSessionStorage(STORAGE_KEY);

const markAsDetected = () => {
  writeToSessionStorage(STORAGE_KEY, true);
};

/**
 * Receiving additional parameters on the frontend for deeper analysis on the backend by the 51degrees service
 * @returns {null}
 * @constructor DeviceDetection
 */
const DeviceDetection = () => {
  const [sendDeviceDetectionTrack] = useMutation(
    DEVICE_DETECTION_TRACK_MUTATION,
  );
  const [loadDeviceDetectionScript, {data, loading, error}] = useLazyQuery(
    DEVICE_DETECTION_SCRIPT_QUERY,
  );

  useEffect(() => {
    if (isDetected()) return;

    if (!loading && !data && !error) {
      loadDeviceDetectionScript();

      return;
    }

    if (!loading && !error && data.deviceDetection.deviceDetectionJs) {
      try {
        /**
         * Eval js code received from backend 51degrees
         */
        // eslint-disable-next-line no-eval
        window.eval(data.deviceDetection.deviceDetectionJs);

        /**
         * When the script for determining the device is executed on the frontend
         * it need to make a track to the backend so that the backend can collect cookies
         * that were set by the script executed on the frontend
         */
        sendDeviceDetectionTrack().then(
          ({
            data: {
              deviceDetectionTrack: {
                result,
                errors: {general: generalError},
              },
            },
          }) => {
            if (!result || generalError) {
              logger.sendError(
                `[DeviceDetection] track response error: ${generalError}`,
              );

              return;
            }

            /**
             * Detect js of 51degrees should work only once after auto login for a session
             */
            markAsDetected();
          },
        );
      } catch (e) {
        logger.sendError(`[DeviceDetection] script eval error: ${e.message}`);
      }
    }
  }, [
    data,
    loading,
    error,
    sendDeviceDetectionTrack,
    loadDeviceDetectionScript,
  ]);

  return null;
};

export default DeviceDetection;
