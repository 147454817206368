import lowerCase from 'lodash/lowerCase';
import upperFirst from 'lodash/upperFirst';
import merge from 'lodash/merge';
import isUndefined from 'lodash/isUndefined';

import {localStorage} from '@core/utils/storage';

const GLOBAL_DICTIONARY_NAME = 'translationDictionary';

export type SimpleParams =
  | {
      [key: string]: string | number;
    }
  | number
  | string;

/**
 * @deprecated
 * Should be rewritten and integrated into main translation function
 * @see src/packages/core/translations/translate.ts
 */
class YiiT {
  options: {
    pluralRules: string[];
    separator: string;
  };

  dictionary: {[key: string]: string};

  constructor() {
    this.options = {
      pluralRules: ['n==1', 'true'],

      /*
       * Loader joins msgctxt and msgid with \u0004 symbol.
       * @see po-loader.js
       * Explanation:
       * https://github.com/SlexAxton/Jed/blob/2bebbacbb81bbdabdd437eeccd48344c77eb0d73/jed.js#L112-L116
       */
      separator: String.fromCharCode(4),
    };

    this.dictionary = {};
    if (typeof window !== 'undefined') {
      window[GLOBAL_DICTIONARY_NAME] = window[GLOBAL_DICTIONARY_NAME] || {};

      this.dictionary = window[GLOBAL_DICTIONARY_NAME];
    }
  }

  addToDict(newDictionary: {[key: string]: string}): void {
    merge(this.dictionary, newDictionary);
  }

  t(
    componentName: string,
    messageKey: string,
    paramsFromArguments: SimpleParams,
  ): string {
    let params = paramsFromArguments;
    /**
     * For integration tests, for avoiding continuous snapshots fixes after
     * merging new translations, we replace real keys with converted to
     * "readable" format values.
     */
    if (window.IS_INTEGRATION_TEST_ENVIRONMENT) {
      /**
       * Remove most commonly used prefixes in translations,
       * and after, convert to less or more readable and beautiful format.
       * `translationMocks` allows to set specific texts for some keys for more relevant test.
       */
      return (
        /* @ts-expect-error -- It's mock, so they decided don't type it */
        window.translationMocks?.[messageKey] ||
        upperFirst(
          lowerCase(
            messageKey.replace(
              /^(text\.select-|text\.app_funnel_|text\.|title\.|value\.|button\.|tab\.|placeholder\.)/,
              '',
            ),
          ),
        )
      );
    }

    let message =
      this.dictionary[componentName + this.options.separator + messageKey] ||
      messageKey;

    if (!isUndefined(params)) {
      if (typeof params !== 'object') {
        params = {'{n}': params};
      }

      if (!isUndefined(params['{n}'])) {
        if (message.indexOf('|') >= 0) {
          if (message.indexOf('#') <= -1) {
            const chunks = message.split('|');
            const expressions = this.getPluralRules();
            const $n = Math.min(chunks.length, expressions.length);
            if ($n) {
              let i = 0;
              while (i <= $n) {
                chunks[i] = `${expressions[i]}#${chunks[i]}`;
                i++;
              }
              message = chunks.join('|');
            }
          }

          message = this.format(message, params[`{n}`]);
        }
      }

      // eslint-disable-next-line
      for (const key in params) {
        const value = String(params[key]);
        /**
         * Dollar amounts are not converted to the correct value if you do not follow the rules from the
         * attached documentation, for this there is this crutch
         * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#Specifying_a_string_as_a_parameter
         * @type {boolean}
         */
        const needRegexp = !/^\$[\d.]+$/.test(value);

        if (needRegexp) {
          message = message.replace(new RegExp(key, 'g'), value);
        } else {
          message = message.split(key).join(value);
        }
      }
    }

    if (Number(localStorage.getItem('yiiTDebug')) === 1) {
      message += ` (${componentName}.${messageKey})`;
    }

    return message;
  }

  format(message: string, n: number | string): string {
    let returnValue = message;
    const newMessage = `${message}|`;
    // eslint-disable-next-line
    const expr = /\s*([^#]*)\s*#([^\|]*)\|/g;
    let expression;
    let stringExpression;
    let result;

    // eslint-disable-next-line
    while ((result = expr.exec(newMessage))) {
      // eslint-disable-next-line
      expression = result[1];
      // eslint-disable-next-line
      returnValue = result[2];

      stringExpression = parseInt(expression, 10).toString();
      if (expression === stringExpression) {
        // eslint-disable-next-line
        if (expression == n) {
          return returnValue;
        }
      } else {
        const expressionReplaced = expression.split('n').join('$n');
        if (this.evaluate(expressionReplaced, n)) {
          return returnValue;
        }
      }
    }
    return returnValue;
  }

  /**
   * @param $expression
   * @param $n - need  as lexical context for eval expression
   * @return {any}
   */
  // eslint-disable-next-line
  evaluate($expression: string, $n: number | string): any {
    // eslint-disable-next-line
    return eval($expression);
  }

  getPluralRules(): string[] {
    return this.options.pluralRules;
  }
}

export default new YiiT();
