import {UNKNOWN_VIA} from '@core/payment/common/constants/vias';

import {MSG} from '../constants/socketEventNames';

export default {
  UserPaymentProcessedSubscription: {
    incomingEvents: [`${MSG}userPaymentProcessed`],
    fromServer: (response) => {
      const {
        orderId,
        paymentStatus,
        redirectUrl,
        via = UNKNOWN_VIA,
        declineScreenTemplate = null,
      } = response;

      return {
        data: {
          paymentProcessed: {
            orderId,
            paymentStatus,
            declineScreenTemplate,
            redirectUrl,
            via,
          },
        },
      };
    },
  },
};
