/**
 * Privacy link
 */
export const PRIVACY_LINK = '/staticPage/privacypolicy';

/**
 * Terms link
 */
export const TERMS_LINK = '/staticPage/terms';

/**
 * Safedating terms link
 */
export const SAFEDATING_LINK = '/staticPage/terms?html=safedating';

/**
 * Notice terms link
 */
export const NOTICE_LINK = '/staticPage/terms?html=notice';

/**
 * Additional package conditions link
 */
export const ADDITIONAL_PACKAGES_CONDITIONS_LINK =
  '/staticPage/additionalPackageConditions';

/**
 * Subscription packages terms link
 */
export const PACKAGES_LINK = '/staticPage/terms?html=subscription_packages';

/**
 * DNSMPI link
 */
export const DNSMPI_URL = '/staticPage/dnsmpi';

/**
 * Digital service act link
 */
export const DIGITAL_SERVICE_ACT_URL = '/staticPage/digitalServiceAct';
