import React, {useEffect, useRef, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import dataURIToBlob from 'datauritoblob';
import {useQuery} from '@apollo/client';

import PHOTO_UPLOAD_SETTINGS_QUERY from '@core/mediaUpload/graphql/queries/photoUploadSettings.gql';

import WebCamHelper from '../utils/WebCamHelper';

const WebcamPhotoCaptureDisplayer = ({
  onCapture,
  webcamPhotoCaptureLayoutComponent: WebcamPhotoCaptureLayout,
}) => {
  const webCamHelperRef = useRef();
  const containerRef = useRef();
  const mountedRef = useRef(true);
  const [error, setError] = useState();
  const [isReady, setIsReady] = useState();
  const {data, loading} = useQuery(PHOTO_UPLOAD_SETTINGS_QUERY);

  const handleCapture = useCallback(() => {
    webCamHelperRef.current
      .getSnapshot()
      .then((url) => onCapture(dataURIToBlob(url)), setError);
  }, [onCapture]);

  useEffect(
    () => () => {
      mountedRef.current = false;
    },
    [],
  );

  useEffect(() => {
    /**
     * Height used because it always smaller in params from back end,
     * and in front end width calculated from height
     * @type {Number}
     */
    const height = data?.userFeatures?.photoSettings.minPhotoResolution.height;
    const webCamHelperParams = {
      container: containerRef.current,
      // Workaround for iPad when we click on black rect
      // see https://github.com/jhuckaby/webcamjs/blob/master/webcam.js
      user_callback: handleCapture,
    };
    if (!loading && height) {
      webCamHelperParams.minResolution = height;
    }

    const webCamHelper = new WebCamHelper(webCamHelperParams);
    webCamHelper
      .start()
      .then(() => {
        if (mountedRef.current) {
          setIsReady(true);
        }
      })
      .catch((...args) => {
        if (mountedRef.current) {
          setError(...args);
        }
      });
    webCamHelperRef.current = webCamHelper;

    return () => webCamHelper.destroy();
  }, [handleCapture, data, loading]);

  return (
    <WebcamPhotoCaptureLayout
      ref={containerRef}
      isReady={isReady && !error}
      error={error && WebCamHelper.getErrorType(error)}
      onCapture={handleCapture}
    />
  );
};

WebcamPhotoCaptureDisplayer.propTypes /* remove-proptypes */ = {
  onCapture: PropTypes.func.isRequired,
  webcamPhotoCaptureLayoutComponent: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.func,
  ]).isRequired,
};

export default WebcamPhotoCaptureDisplayer;
