import cachedPurchasedPackage from '../../../common/utils/cachedPurchasedPackage';
import type {PaymentProcessStep} from '../../types/paymentProcess';

/**
 * Caching active package for the possibility of obtaining purchased package data after payment
 */
const cachePurchasedPackage: PaymentProcessStep = async (data) => {
  const {paymentData} = data;

  cachedPurchasedPackage.set(paymentData.activePackage);

  return data;
};

export default cachePurchasedPackage;
