import type {
  RefObject,
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
  SyntheticEvent,
  ForwardedRef,
} from 'react';
import React, {Component, createRef, forwardRef} from 'react';
import {useLocation} from 'react-router-dom';

import isRtlDirection from '@core/utils/textDirection/isRtlDirection';
import isDeviceWithTouchScreen from '@core/utils/device/isDeviceWithTouchScreen';
import type {CSSModule} from '@core/ui/types';

import changeRtlDirection from './changeRtlDirection';
import PopperPlacement from '../../constants/PopperPlacement';
import PopperTrigger from '../../constants/PopperTrigger';
import type {PopperWrapperProps} from './PopperWrapper';
import PopperWrapper from './PopperWrapper';

const HOVER_DELAY = window.IS_INTEGRATION_TEST_ENVIRONMENT ? 0 : 200;

export type PopperProps = {
  children: PopperWrapperProps['reference'];
  content: PopperWrapperProps['children'];
  active?: boolean;
  className?: string;
  error?: boolean;
  spaced?: boolean;
  showArrow?: boolean;
  fixedHeight?: boolean;
  fullHeight?: boolean;
  usePortal?: boolean;
  positionFixed?: boolean;
  pathname?: string;
  fullWidth?: boolean;
  beyondBorders?: boolean;
  hideByScroll?: boolean;
  observeContentMutations?: boolean;
  trigger?: PopperTrigger | boolean;
  placement?: PopperPlacement;
  onActiveChange?: (active: boolean) => void;
  shouldToggle?: (active: boolean) => Promise<boolean>;
  parentRef?: RefObject<HTMLElement> | ForwardedRef<HTMLDivElement>;
  updateArrowPosition?: boolean;
  trackingName?: string | null;
};

type PopperInnerState = {
  active: boolean;
};
type PopperEventAttributes = Partial<{
  // eslint-disable-next-line no-use-before-define
  popper: PopperInner;
  isNestedPopper: boolean;
}>;
type PopperEventNative<T> = T & PopperEventAttributes;
type PopperEventSynthetic<T extends SyntheticEvent> = T & {
  nativeEvent: PopperEventAttributes;
};
type PopperInnerProps = PopperProps & {baseCss: CSSModule; css: CSSModule};

export class PopperInner extends Component<PopperInnerProps, PopperInnerState> {
  static defaultProps = {
    active: null,
    spaced: true,
    showArrow: true,
    fullHeight: false,
    placement: PopperPlacement.RIGHT,
    hideByScroll: false,
    observeContentMutations: false,
  };

  private hoverActivation: boolean;

  private readonly clickActivation: boolean;

  private showTimeout: number | null;

  private hideTimeout: number | null;

  private readonly scrollableRef: RefObject<HTMLDivElement>;

  constructor(props: PopperInnerProps) {
    super(props);

    const hasMouse = !isDeviceWithTouchScreen;

    this.state = {active: false};
    this.hoverActivation =
      (props.trigger === PopperTrigger.HOVER ||
        props.trigger === PopperTrigger.HOVER_CLICK) &&
      hasMouse;
    this.clickActivation =
      props.trigger === PopperTrigger.CLICK ||
      (props.trigger === PopperTrigger.HOVER_CLICK && !hasMouse);

    this.showTimeout = null;
    this.hideTimeout = null;
    this.scrollableRef = createRef();
  }

  static getDerivedStateFromProps(
    nextProps: PopperProps,
    prevState: PopperInnerState,
  ) {
    return nextProps.active !== null && nextProps.active !== prevState.active
      ? {active: nextProps.active}
      : null;
  }

  componentDidMount() {
    window.addEventListener('orientationchange', this.hide, true);
    this.updateActivationListeners();
  }

  componentDidUpdate(prevProps: PopperProps, prevState: PopperInnerState) {
    const {trigger, pathname} = this.props;
    const {pathname: prevPathname, trigger: prevTrigger} = prevProps;

    if (pathname !== prevPathname) {
      this.hide();
    }

    this.updateActivationListeners(prevState);

    if (process.env.NODE_ENV !== 'production' && trigger !== prevTrigger) {
      console.warn("Popper: 'trigger' prop change has no effect.");
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
    window.removeEventListener('orientationchange', this.hide, true);

    this.removeOutsideListener();
    clearTimeout(this.showTimeout!);
    clearTimeout(this.hideTimeout!);
  }

  show = () => this.toggleActive(true);

  hide = () => this.toggleActive(false);

  preventOutsideClose = (
    e: PopperEventSynthetic<
      ReactTouchEvent<HTMLElement> | ReactMouseEvent<HTMLElement>
    >,
  ) => {
    e.nativeEvent.popper = this;
  };

  handleOutsideClose = (e: PopperEventNative<MouseEvent | TouchEvent>) => {
    if ((e.target as HTMLElement).closest('[data-popper-ignore-close]')) {
      return;
    }

    if (e.popper !== this) {
      this.hide();
    }
  };

  handleContentClick = (
    e: PopperEventSynthetic<ReactMouseEvent<HTMLElement>>,
  ) => {
    const {target} = e;

    // Type casting is used because not understand correct type of EventTarget
    if ((target as HTMLElement).closest('[data-close-popper]')) {
      this.hide();
      e.preventDefault();
      e.stopPropagation();
    }

    e.nativeEvent.isNestedPopper = true;
  };

  handleReferenceClick = (
    e: PopperEventSynthetic<ReactMouseEvent<HTMLElement>>,
  ) => {
    if (e.nativeEvent.isNestedPopper) {
      return;
    }

    e.nativeEvent.isNestedPopper = true;

    this.toggleActive(!this.state.active);
  };

  handleMouseEnter = () => {
    clearTimeout(this.hideTimeout!);
    if (!this.state.active) {
      this.showTimeout = window.setTimeout(this.show, HOVER_DELAY);
    }
  };

  handleMouseLeave = () => {
    clearTimeout(this.showTimeout!);
    if (this.state.active) {
      this.hideTimeout = window.setTimeout(this.hide, HOVER_DELAY);
    }
  };

  handleScroll = (event: Event) => {
    const isInsidePopper = event.target === this.scrollableRef.current;

    if (isInsidePopper) {
      return;
    }

    this.hide();

    window.removeEventListener('scroll', this.handleScroll, true);
  };

  async toggleActive(active: boolean) {
    if (active === this.state.active) {
      return false;
    }

    if (this.props.shouldToggle) {
      const should = await this.props.shouldToggle(this.state.active);
      if (!should) {
        return false;
      }
    }

    if (this.props.active === null) {
      this.setState({active});
    }
    this.props.onActiveChange && this.props.onActiveChange(active);
    return true;
  }

  updateActivationListeners(prevState: PopperInnerState = {active: false}) {
    const prevActive = prevState.active;
    const {active} = this.state;

    if (this.clickActivation) {
      if (!prevActive && active) {
        this.addOutsideListener();
      }
      if (prevActive && !active) {
        this.removeOutsideListener();
      }
    }

    if (this.hoverActivation || this.props.hideByScroll) {
      if (!prevActive && active) {
        window.addEventListener('scroll', this.handleScroll, true);
      } else if (prevActive && !active) {
        window.removeEventListener('scroll', this.handleScroll, true);
      }
    }
  }

  addOutsideListener() {
    window.addEventListener('mousedown', this.handleOutsideClose);
    window.addEventListener('touchstart', this.handleOutsideClose);
  }

  removeOutsideListener() {
    window.removeEventListener('mousedown', this.handleOutsideClose);
    window.removeEventListener('touchstart', this.handleOutsideClose);
  }

  render() {
    const {
      children,
      content,
      active: activeProp,
      onActiveChange,
      parentRef,
      placement,
      ...props
    } = this.props;
    const bind = onActiveChange || activeProp !== false;
    const hover = (bind && this.hoverActivation) || null;
    const click = (bind && this.clickActivation) || null;
    const {active} = this.state;
    const handleTouch =
      active && this.clickActivation ? this.preventOutsideClose : null;

    return (
      <PopperWrapper
        /**
         * ATTENTION!!!!!!!!!!!!!!!!!!
         * We change the direction of the popper if the direction of the text is right to left.
         * We do this right in the popper so that we do not have to do a lot of conditions in the project and
         * encapsulate all this logic in one place due to the fact that the popover itself is used in menus,
         * tooltips and popovers.
         */
        placement={changeRtlDirection(placement, isRtlDirection())}
        active={active}
        reference={children}
        parentRef={parentRef}
        scrollableRef={this.scrollableRef}
        onReferenceClick={click && this.handleReferenceClick}
        // eslint-disable-next-line local-rules/tracking-name -- ...props should include `trackingName` if needed.
        onClick={this.handleContentClick} // content listener
        // reference and content listeners:
        onMouseEnter={hover && this.handleMouseEnter}
        onMouseLeave={hover && this.handleMouseLeave}
        onMouseDown={handleTouch}
        onTouchStart={handleTouch}
        hidePopover={this.hide}
        {...props}
      >
        {content}
      </PopperWrapper>
    );
  }
}

const Popper = forwardRef<PopperInner, PopperInnerProps>((props, ref) => {
  const {pathname} = useLocation();

  return <PopperInner {...props} pathname={pathname} ref={ref} />;
});

export default Popper;
