import isMobileViewport from '@core/responsive/isMobileViewport';
import isAppLayoutSpacedBottomVar from '@core/graphql/vars/isAppLayoutSpacedBottomVar';
import getUserAgentParser from '@core/utils/getUserAgentParser';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import {IN_APP_NAME} from '@core/application/constants/bootstrapParams';
import InAppBrowserName from '@core/application/constants/inAppBrowserName';

const IS_ANDROID: boolean = getUserAgentParser().getOS().name === 'Android';
const IS_MIUI_BROWSER: boolean =
  getUserAgentParser().getBrowser().name === 'MIUI Browser';
const DEFAULT_HEIGHT: number = document.documentElement.clientHeight;
const TIME_TO_OPEN_THE_KEYBOARD: number = 600;
const TIME_TO_WORK_ON_THE_CLICK_EVENT: number = 50;
const BROWSER_BOTTOM_MENU_HEIGHT: number = 60;

let timeout: ReturnType<typeof setTimeout> | null = null;

/**
 * Fixes bug in inAppBrowser, 'MIUI Browser' on Android.
 * InAppBrowser SNAPCHAT, 'MIUI Browser' - cannot listen for resizing when keyboard is opened.
 * InAppBrowser TIKTOK - when opening the site through ads, cannot listen for resizing when keyboard is opened.
 * We must invent a crutch.
 */
const toggleBottomSpace = (
  spaceNeeded: boolean,
  isPopup: boolean = false,
): void => {
  const isSnapchatOnAndroid: boolean =
    IS_ANDROID && getBootstrapParam(IN_APP_NAME) === InAppBrowserName.SNAPCHAT;
  const isTiktokOnAndroid: boolean =
    IS_ANDROID && getBootstrapParam(IN_APP_NAME) === InAppBrowserName.TIKTOK;
  const isMiuiBrowserAndroid: boolean = IS_ANDROID && IS_MIUI_BROWSER;

  /**
   * To avoid unnecessary setTimeout when quickly closing a popup or losing focus.
   */
  clearTimeout(timeout);

  const changeBottomSpace = (): void => {
    /**
     * Checking if the height has changed when the keyboard is opened.
     * Depending on scrolling, the browser's bottom menu may be shown or hidden,
     * it needs to be taken into.
     */
    const isHeightChanged: boolean =
      Math.abs(DEFAULT_HEIGHT - document.documentElement.clientHeight) >
      BROWSER_BOTTOM_MENU_HEIGHT;

    if (
      isMobileViewport() &&
      (isSnapchatOnAndroid ||
        ((isTiktokOnAndroid || isMiuiBrowserAndroid) && !isHeightChanged))
    ) {
      if (!spaceNeeded) {
        /**
         * The onClick event on the button must be handled first, and then only the style change.
         * Otherwise, the click event will fire on the wrong element.
         * @see RoomsSenderForm.js
         * @see SenderForm.js
         */
        setTimeout(() => {
          isAppLayoutSpacedBottomVar(spaceNeeded);
        }, TIME_TO_WORK_ON_THE_CLICK_EVENT);
      } else {
        isAppLayoutSpacedBottomVar(spaceNeeded);
      }
    } else if (
      spaceNeeded &&
      (isTiktokOnAndroid || isMiuiBrowserAndroid) &&
      isHeightChanged
    ) {
      /**
       * To avoid extra check timeout feature for isHeightNotChange
       * because isHeightNotChange might be wrong the first time.
       */
      isAppLayoutSpacedBottomVar(!spaceNeeded);
    }
  };

  /* The keyboard may not open immediately, so the popup needs a setTimeout. */
  if (isPopup) {
    timeout = setTimeout(() => {
      changeBottomSpace();
    }, TIME_TO_OPEN_THE_KEYBOARD);
  } else {
    changeBottomSpace();
  }
};

export default toggleBottomSpace;
