import {makeVar} from '@apollo/client';

/**
 * The flag that is set on the user's click on the DOM element, needed to understand the user gesture, since such
 * events as scrolling and the like will be considered to be from the user after the user interacts with
 * the DOM of the site
 */
const withUserGestureVar = makeVar(false);

export default withUserGestureVar;
