import type {ReactNode, FC} from 'react';
import React, {useMemo, useContext} from 'react';
import upperFirst from 'lodash/upperFirst';

import useEventCallback from '@core/utils/react/useEventCallback';

import {
  Category,
  EventAttributes,
  EventType,
} from '../constants/babciaDataTypes';
import BabciaScopedContext from './BabciaScopedContext';

const DEFAULT_ELEMENT_INDEX = 1;

const storage: Record<string, Record<string, Set<unknown>>> = {};

type BabciaScopedProviderProps = {
  children: ReactNode;
  context?: string;
  conceptId?: string;
  trackingLabel?: string;
  trackingIndex?: string;
  category?: Category;
  eventType?: EventType;
  // Skip tracking. Is used for hidden popups, LikeGallery inactive user widget.
  skip?: boolean;
};

const BabciaScopedProvider: FC<BabciaScopedProviderProps> & {
  CATEGORY: typeof Category;
  EVENT_TYPE: typeof EventType;
} = ({
  children,
  category,
  context,
  conceptId,
  eventType = EventType.MAIN,
  trackingLabel,
  trackingIndex,
  skip,
}) => {
  const parentContext = useContext(BabciaScopedContext);
  const contextString = useMemo(() => {
    if (parentContext.context || context) {
      return [parentContext.context, context]
        .filter(Boolean)
        .map(upperFirst)
        .join('_');
    }

    return null;
  }, [parentContext, context]);
  const storageKey = contextString || EventAttributes.NONE;

  storage[storageKey] ||= {};

  const getElementIndex = useEventCallback(
    (element: unknown, objectName: string) => {
      const index = [...(storage[storageKey][objectName] || [])].indexOf(
        element,
      );

      if (index === -1) {
        return DEFAULT_ELEMENT_INDEX;
      }

      return index + 1;
    },
  );

  const setElement = useEventCallback(
    (element: unknown, objectName: string) => {
      if (!storage[storageKey][objectName]) {
        storage[storageKey][objectName] = new Set();
      }

      storage[storageKey][objectName].add(element);
    },
  );

  const deleteElement = useEventCallback(
    (element: unknown, objectName: string) =>
      storage[storageKey][objectName]?.delete(element),
  );

  return (
    <BabciaScopedContext.Provider
      value={{
        getElementIndex,
        setElement,
        deleteElement,
        context: contextString,
        category: category || parentContext.category,
        conceptId: conceptId || parentContext.conceptId,
        eventType,
        trackingLabel: trackingLabel || parentContext.trackingLabel,
        trackingIndex: trackingIndex || parentContext.trackingIndex,
        skip: skip ?? parentContext.skip,
      }}
    >
      {children}
    </BabciaScopedContext.Provider>
  );
};

BabciaScopedProvider.CATEGORY = Category;
BabciaScopedProvider.EVENT_TYPE = EventType;

export default BabciaScopedProvider;
