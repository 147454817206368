enum EventName {
  VIEW_PROFILE = 'view_profile',
  VIEW_PHOTO = 'view_photo',
  VIEW_VIDEO = 'view_video',
  OPEN_CHAT = 'open_chat',
  CLOSE_CHAT = 'close_chat',
  MESSAGE_SENT = 'message_sent',
  ADD_PROFILE_PHOTO = 'profile_upload_photo',
  ADD_PROFILE_VIDEO = 'profile_upload_video',
  SKIP_FEATURES = 'skip_features',
  SKIP_FEATURES_RM = 'skip_features_rm',
  SKIP_MEMBERSHIP = 'skip_membership',
}

export default EventName;
