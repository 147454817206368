import {MEDIA_UPLOAD_ERROR} from '@core/tracking/constants/identities';

export default {
  TrackMediaUploadErrorMutation: {
    toServer: (params) => ({method: MEDIA_UPLOAD_ERROR.METHOD, params}),

    fromServer: (response, error) => ({
      data: {
        trackMediaUploadError: {
          error: error || null,
          __typename: 'MediaUploadErrorMutationResult',
        },
      },
    }),
  },
};
