/**
 * Available photo size presets. Only they should be used in components
 */
export enum PhotoPreset {
  NONE = 'none',
  NORMAL = 'normal1000',
  AVATAR_BIG = 'avatar480',
  AVATAR = 'avatar240',
  AVATAR_SMALL = 'avatar60',
}

/**
 * Censored photos e.g. photos with blurred parts.
 * Used for specific where by law we can't show some part of bodies. :)
 * @see createPhotoUrl.js
 * CENSORED_PRESET
 */
export enum CensoredPreset {
  NORMAL = 'censored1000',
  AVATAR_BIG = 'avatarCensored480',
  AVATAR = 'avatarCensored240',
  AVATAR_SMALL = 'avatarCensored60',
}

/**
 * Map between normal presets and censored ones.
 * @see createPhotoUrl.js
 */
export const normalToCensoredPhotoPresetMap = {
  [PhotoPreset.NORMAL]: CensoredPreset.NORMAL,
  [PhotoPreset.AVATAR_BIG]: CensoredPreset.AVATAR_BIG,
  [PhotoPreset.AVATAR]: CensoredPreset.AVATAR,
  [PhotoPreset.AVATAR_SMALL]: CensoredPreset.AVATAR_SMALL,
};

export default PhotoPreset;
