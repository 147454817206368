enum FavoritesAppearance {
  /**
   * Display content with search widgets and search-like.
   */
  AS_SEARCH_LIST = 'asSearchList',

  /**
   * Display content as regular widget as activity, recipients, etc..
   */
  AS_REGULAR_LIST = 'asRegularList',
}

export default FavoritesAppearance;
