import type {FC} from 'react';

/**
 * Helper method to redirect to root path ('/')
 * with reload. Useful in case if we are anonymous user and we go to
 * only-for-logger-in users page. If we use <RedirectToDefaultPage> we'll
 * redirect user on '/search' page, and only after, thanks to AppData, user
 * will be redirected to root path.
 */
const RedirectToRoot: FC = () => {
  window.location.pathname = '/';
  return null;
};

export default RedirectToRoot;
