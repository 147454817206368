import type {FC} from 'react';

import type {BreakpointProps} from './types';
import useSmallPhoneBreakpoint from './useSmallPhoneBreakpoint';

const SmallPhoneBreakpoint: FC<BreakpointProps> = ({children}) => {
  const isMatched = useSmallPhoneBreakpoint();

  return children(isMatched);
};
export default SmallPhoneBreakpoint;
