import {from, Subject} from 'rxjs';
import {ApolloLink} from '@apollo/client';

import {CONNECTED} from '../constants/socketEventNames';
import interaction from '../interaction';
import {listenToNames} from '../interactionEvents';
import resolvers from '../resolvers';

let wsLink;

const queue = [];

const processQueue = () => {
  if (interaction.isConnected()) {
    queue.forEach((fn) => fn());
    queue.length = 0;
  }
};

/**
 * Allows to delay requests until interaction connection will be established.
 * @param {Function} fn - function that performs request
 */
const enqueue = (fn) => {
  queue.push(fn);
  processQueue();
};

listenToNames([CONNECTED], processQueue);

const rpc = async ({toServer, fromServer}, variables) => {
  const {method, params} = await toServer(variables);

  return new Promise((resolve) => {
    enqueue(() => {
      interaction.request(method, params, async (response, error) => {
        resolve(await fromServer(response, error));
      });
    });
  });
};

/**
 * Creates WebSocket link.
 * @see client.ts
 * @returns {Object} SubscriptionClient
 */
export const getWebSocketLink = () => {
  if (wsLink) {
    return wsLink;
  }

  const subscriptions = {};

  Object.entries(resolvers)
    .filter(([, resolver]) => resolver.incomingEvents)
    .forEach(([operationName, {incomingEvents, fromServer}]) => {
      const events = Array.isArray(incomingEvents)
        ? incomingEvents
        : [incomingEvents];

      listenToNames(events, async (event) => {
        const subject = subscriptions[operationName];
        if (!subject) {
          return;
        }

        const response = await fromServer(event);
        if (response) {
          subject.next(response);
        }
      });
    });

  /**
   * We already have the socket.io client (wrapped by {@see Interaction}) that
   * receives all the events we could ever need from the server and can also run some RPC.
   * This custom link is used to emulate graphql subscriptions and perform some operations via socket.io.
   * Should be replaced with GraphQLWsLink if we sometime move to real graphql subscriptions
   */
  wsLink = new ApolloLink(({operationName, variables}) => {
    const resolver = resolvers[operationName];

    if (!resolver.incomingEvents) {
      // For 'query' and 'mutation' operations:
      return from(rpc(resolver, variables));
    }

    // For 'subscription' operations:
    if (!subscriptions[operationName]) {
      subscriptions[operationName] = new Subject();
    }

    return subscriptions[operationName];
  });

  return wsLink;
};

/**
 * Returns true if socket must be used instead of XHR for specified operationName.
 * @param {string} operationName
 * @return {boolean}
 */
export const isWebSocketOperation = (operationName) =>
  operationName in resolvers;

/**
 * Check is WebSocket is connected. Useful if we have split logic
 * where we should make XHR request instead of WS when WS is closed.
 * @returns {boolean}
 */
export const isWebSocketConnected = () => interaction.isConnected();
