import {useEffect} from 'react';
import {useQuery} from '@apollo/client';

import addScriptNode from '@core/application/utils/addScriptNode';

import FRAUD_NET_QUERY from '../graphql/queries/fraudNet.gql';

/**
 * Link to paypal script, that find and process fraudNet parameters.
 *
 * @link https://developer.paypal.com/limited-release/fraudnet/integrate/
 * @type {String}
 */
const FN_URL = 'https://c.paypal.com/da/r/fb.js';

/**
 * In order to find and process parameters,
 * FraudNet JavaScript searches for a script of type application/json with an attribute fncls,
 * and its value match that string.
 *
 * @link https://developer.paypal.com/limited-release/fraudnet/integrate/
 * @type {String}
 */
const FN_CLS = 'fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99';

function appendFraudnetParams(sessionId, webSiteId) {
  const script = document.createElement('script');

  script.type = 'application/json';
  script.setAttribute('fncls', FN_CLS);
  script.innerHTML = JSON.stringify({
    f: sessionId,
    s: webSiteId,
  });

  document.body.appendChild(script);
}

/**
 * Integrate FraudNet into your web app
 *
 * @link https://developer.paypal.com/limited-release/fraudnet/integrate/
 */
const PayPalV2FraudNet = () => {
  const {data} = useQuery(FRAUD_NET_QUERY);
  // 'sessionId' and 'webSiteId' are available only if the user has access to PayPalv2
  const {sessionId, webSiteId} = data?.payment.fraudNet || {};

  useEffect(() => {
    if (sessionId && webSiteId) {
      // Insert parameters for FraudNet in the document.
      appendFraudnetParams(sessionId, webSiteId);

      /**
       * Insert paypal resources.
       * Warning!!! Should be called only after 'appendFraudnetParams'.
       * See Option 2 in the docs
       *
       * @link https://developer.paypal.com/limited-release/fraudnet/integrate/
       */
      addScriptNode({id: 'fraudNet', src: FN_URL});
    }
  }, [sessionId, webSiteId]);

  return null;
};

export default PayPalV2FraudNet;
