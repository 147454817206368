import ADMIN_USER_QUERY from '@core/websocket/graphql/queries/adminUser.gql';
import type {AdminUserQuery} from '@core/websocket/graphql/queries/adminUser';
import type {Client} from '@core/graphql/types';

import SUPPORT_REQUEST_PROCESSED_SUBSCRIPTION from '../current/graphql/subscriptions/supportRequestProcessed.gql';
import type {InteractionMessagesupportRequestProcessedSubscription} from '../current/graphql/subscriptions/supportRequestProcessed';

let startedListeners = false;

/**
 * Starts subscriptions for admin change
 */
const startChangeAdminListener = async (client: Client) => {
  if (!startedListeners) {
    client
      .subscribe<InteractionMessagesupportRequestProcessedSubscription>({
        query: SUPPORT_REQUEST_PROCESSED_SUBSCRIPTION,
      })
      .subscribe(({data: {fromId}}) => {
        const data = client.readQuery<AdminUserQuery>({
          query: ADMIN_USER_QUERY,
        });

        const id = data?.currentUserAdmin?.id;
        if (!id || id === fromId) return;

        client.writeQuery<AdminUserQuery>({
          query: ADMIN_USER_QUERY,
          data: {
            ...data,
            currentUserAdmin: {
              ...data.currentUserAdmin,
              id: fromId,
            },
          },
        });
      });

    startedListeners = true;
  }
};

export default startChangeAdminListener;
