import type {FC} from 'react';
import React from 'react';
import cn from 'classnames';

import type {PlaceholderProps} from './Placeholder';
import Placeholder from './Placeholder';
import css from './PlaceholderBar.css';

type PixelSize = 40 | 50 | 70 | 100 | 120 | 150 | 200;
type PercentSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10; // x10
type BarSize = PercentSize | PixelSize;

export interface PlaceholderBarProps extends PlaceholderProps {
  /**
   * Numbers from 1 up to 9 indicates that width of bar will be calculated
   * in percents. For e.g. 1 mean 10%, 9 mean 90%.
   * Other sizes mean pixel width.
   *
   * TODO: Maybe instead of classes for pixel-width we can use inline styles?
   * But in this case we lost control of placeholders sizes according to styleguides.
   */
  size?: BarSize;
  small?: boolean; // For correct line-height in case of small text placeholders
}

const PlaceholderBar: FC<PlaceholderBarProps> = ({
  size = 10, // E.g. full-width
  small = false,
  inline = false,
  ...props
}) => {
  const className = cn(
    props.className,
    css.bar,
    small && css.small,
    inline && css.inline,
    size && css[`bar${size}`],
  );

  return <Placeholder {...props} inline={inline} className={className} />;
};

export default PlaceholderBar;
