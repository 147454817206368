import type {FC, HTMLAttributes, ReactNode} from 'react';
import React from 'react';
import cn from 'classnames';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import type {CSSModule} from '../../types';
import {Icon} from '../icon';
import {Spacing} from '../spacing';
import IconPosition from '../../constants/IconPosition';
import SpacingDirection from '../../constants/SpacingDirection';
import baseCss from './AttachedButton.css';

export interface AttachedButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  icon?: string;
  className?: string;
  trackingName?: string;
  iconPosition: IconPosition;
}

const AttachedButton: FC<
  // `AttachedButtonProps` without `css` inside to make it more suitable for `@phoenix/ui`.
  AttachedButtonProps & {css: CSSModule}
> = ({
  css,
  children,
  icon,
  iconPosition = IconPosition.LEFT,
  className,
  trackingName,
  ...props
}) => {
  const content = (
    <button
      type="button"
      {...props}
      className={cn(baseCss.attached, css.attached, className)}
    >
      <Spacing
        className={cn(baseCss.wrap, css.wrap)}
        direction={SpacingDirection.HORIZONTAL}
      >
        {icon && (
          <Icon
            className={cn(baseCss.icon, css.icon, baseCss[iconPosition])}
            type={icon}
          />
        )}
        {children}
      </Spacing>
    </button>
  );

  return trackingName ? (
    <AddBabciaUBTracking trackingName={`${trackingName}Btn`}>
      {content}
    </AddBabciaUBTracking>
  ) : (
    content
  );
};

export default AttachedButton;
