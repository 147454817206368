/**
 * Counters type mail: activities with only mail
 * @type {string}
 */
export const COUNTER_MAIL = 'mail';

/**
 * Counters type mail: activities without mail and only enabledTypes
 * @type {string}
 */
export const COUNTER_ACTIVITIES = 'activity';
