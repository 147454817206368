import cloneDeep from 'lodash/cloneDeep';

import {getClientInstance} from '@core/graphql/client';

import {VIDEO_CHAT_TYPES} from '../constants/messageType';
import COUNT_FREE_MESSAGES_QUERY from '../graphql/queries/countFreeMessages.gql';
import FREE_ASSISTANT_MESSAGES_QUERY from '../graphql/queries/freeAssistantMessages.gql';
import updateRecipientMessagePermissions from './updateRecipientMessagePermissions';

/**
 * Updates free messages data and replaces canSend in messagePermissions with canSendNext flag
 * Should be called after successful message sending
 * @param {ApolloClient} client
 * @param {number|string} count - count free messages
 * @param {boolean} show - show free messages banner
 */
const updateFreeMessagesInCache = ({client, count, show}) => {
  client
    .query({
      query: COUNT_FREE_MESSAGES_QUERY,
    })
    .then(({data: cachedData}) => {
      const data = cloneDeep(cachedData);
      const {
        messenger: {
          initialData: {freeMessages},
        },
      } = data;

      freeMessages.count = count;
      freeMessages.show = show;

      client.writeQuery({
        query: COUNT_FREE_MESSAGES_QUERY,
        data,
      });
    });
};

const updateFreeAssistantMessagesInCache = ({client, count, show}) => {
  const data = client.readQuery({query: FREE_ASSISTANT_MESSAGES_QUERY});

  if (!data) {
    return;
  }

  client.writeQuery({
    query: FREE_ASSISTANT_MESSAGES_QUERY,
    data: {
      ...data,
      messenger: {
        ...data.messenger,
        initialData: {
          ...data.messenger.initialData,
          freeAssistantMessages: {
            ...data.messenger.initialData.freeAssistantMessages,
            count,
            show,
          },
        },
      },
    },
  });
};

/**
 * Updates the permissions cache after sending or receiving message.
 * Should be called after successful message sending or after incoming message
 * @param {string} userId
 * @param {object} message data
 * @param {ApolloClient} [client]
 * @param {boolean} canSendNext
 * @param {boolean} needSendFirst
 * @param {string} reason
 */
export default async function updateMessageDataInCache({
  userId,
  message,
  client = getClientInstance(),
  canSendNext,
  needSendFirst,
  reason,
}) {
  if (VIDEO_CHAT_TYPES.includes(message.type)) {
    // Skip update for video chat messages, because they don't affect the free messages permissions.
    return;
  }

  updateRecipientMessagePermissions({
    client,
    userId,
    canSendNext,
    reason,
    needSendFirst,
  });

  if (message.countFreeMessages) {
    const {countFreeMessages, show} = message.countFreeMessages;
    updateFreeMessagesInCache({client, count: countFreeMessages, show});
  }

  if (message.freeAssistantMessages) {
    updateFreeAssistantMessagesInCache({
      ...message.freeAssistantMessages,
      client,
    });
  }
}
