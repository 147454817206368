import isUndefined from 'lodash/isUndefined';

import {getCookie} from '@core/utils/cookie';

import {DEFAULT_PARAMS, COOKIE_NAME} from '../constants/bootstrapParams';

const getBootstrapParam = (paramName: string): null | string | boolean => {
  const params = JSON.parse(getCookie(COOKIE_NAME) || null);

  if (!params || isUndefined(params[paramName])) {
    return isUndefined(DEFAULT_PARAMS[paramName])
      ? null
      : DEFAULT_PARAMS[paramName];
  }

  return params[paramName];
};

export default getBootstrapParam;
