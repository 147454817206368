import {useContext} from 'react';

import type {ResponsiveBreakpoints} from './ResponsiveContext';
import ResponsiveContext from './ResponsiveContext';

const useTabletBreakpoint = (): boolean => {
  const responsiveContext =
    useContext<ResponsiveBreakpoints>(ResponsiveContext);

  return responsiveContext.TABLET;
};

export default useTabletBreakpoint;
