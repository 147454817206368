import removeActivityInCache from '@core/activity/utils/removeActivityInCache';
import {ACTIVITY_TYPES} from '@core/activity/constants/notificationTypes';

import getEmptyLastMessage from './getEmptyLastMessage';
import updateRecipientCache from './updateRecipientCache';
import updateRecipientsMessagePermissions from './updateRecipientsMessagePermissions';

type DeleteMessageCacheParams = {
  recipientId: string;
  messageId: string;
  needUpdatePermissions?: boolean;
};

/**
 * When recipient get adult photo on traditional sites he could remove this photo forever.
 */
const deleteMessageCache = ({
  recipientId,
  messageId,
  needUpdatePermissions: updatePermissions = false,
}: DeleteMessageCacheParams): void => {
  let needUpdatePermissions = updatePermissions;
  updateRecipientCache(
    recipientId,
    ({
      lastMessage,
      messages,
      hasOutgoingMessage,
      unsentMessageCount,
      unreadMessageCount,
    }) => {
      // After delete all unsent messages of user.
      // Because after this one limits wont reached.
      if (!(unsentMessageCount - 1)) {
        needUpdatePermissions = true;
      }

      let updatedUnreadMessageCount = unreadMessageCount;
      let filteredMessages = [];
      if (messages?.length) {
        filteredMessages = messages.filter((message) => {
          // If the message being deleted is unread, decrement the unread message count
          if (message.id === messageId && message.isRead === false) {
            updatedUnreadMessageCount -= 1;
          }

          return message.id !== messageId;
        });
      }

      const filteredMessagesLength = filteredMessages.length;
      const recipientHasMessages = Boolean(filteredMessagesLength);
      const newLastMessage = recipientHasMessages
        ? filteredMessages[filteredMessagesLength - 1]
        : null;

      return {
        unreadMessageCount: updatedUnreadMessageCount,
        unsentMessageCount: unsentMessageCount ? unsentMessageCount - 1 : 0,
        messages: filteredMessages,
        lastMessage: newLastMessage
          ? {
              ...lastMessage,
              id: newLastMessage.id,
              timestamp: newLastMessage.timestamp,
              type: newLastMessage.type,
              text: newLastMessage.text,
              isSending: false,
              __typename: 'LastMessage',
            }
          : /**
             * lastMessage is marked as required in current scheme,
             * so using getEmptyLastMessage to provide default values for lastMessage
             */
            getEmptyLastMessage(recipientId),
        hasOutgoingMessage: hasOutgoingMessage && recipientHasMessages,
      };
    },
  );

  /**
   * Required if the recipient deletes an unread message.
   */
  removeActivityInCache(
    ({userId, type, id}) =>
      type === ACTIVITY_TYPES.MAIL &&
      userId === recipientId &&
      id === messageId,
  );

  // Need update permissions for all recipient,
  // if the user doesn't have any unsent messages or debt limit reached
  if (needUpdatePermissions) {
    updateRecipientsMessagePermissions((recipient) => {
      // Not update permissions current recipient because already updated above
      // when call direct updateRecipientMessagePermissions, see higher in code.
      if (recipient.id === recipientId) {
        return null;
      }
      return true;
    }, true);
  }
};

export default deleteMessageCache;
