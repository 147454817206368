import includes from 'lodash/includes';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import invert from 'lodash/invert';
import parseHostname from 'util/parseHostname';
import hostnameConverter from 'util/hostnameConverter';

import {
  ADDITIONAL_PACKAGES_CONDITIONS_LINK,
  DIGITAL_SERVICE_ACT_URL,
  DNSMPI_URL,
  NOTICE_LINK,
  PACKAGES_LINK,
  PRIVACY_LINK,
  SAFEDATING_LINK,
  TERMS_LINK,
} from '../constants/staticLinks';

/**
 * Links for hash
 */
const linksForHash = [
  PRIVACY_LINK,
  TERMS_LINK,
  ADDITIONAL_PACKAGES_CONDITIONS_LINK,
  SAFEDATING_LINK,
  NOTICE_LINK,
  PACKAGES_LINK,
  DNSMPI_URL,
  DIGITAL_SERVICE_ACT_URL,
];

/**
 * Cached hashed links
 */
const encodedLinks: Record<string, Record<string, string>> = {};

/**
 * Link to hash link
 * /staticPage/terms?html=safedating to /c3RhdGljUGFnZS90ZXJtcz9odG1sPXNhZmVkYXRpbmdiZW5hdWdodHkuY29t
 */
export const encodeLink = (link: string, externalDomain?: string): string => {
  if (window.IS_INTEGRATION_TEST_ENVIRONMENT) {
    return link;
  }

  const domain =
    externalDomain ||
    parseHostname(hostnameConverter(window.location.hostname)).sitename;

  if (!encodedLinks[domain]) {
    encodedLinks[domain] = {};
  }

  const links = encodedLinks[domain];

  if (links[link]) {
    return links[link];
  }

  const clearLink = link.replace('/', '');
  const hash = btoa(`${clearLink}${domain}`);

  links[link] = `/${hash}`;

  return links[link];
};

/**
 * Base logic for decode hashed link to static page link with outer hash function
 */
const decodeLinkBase = (
  hashedLink: string,
  sitename: string | null,
  decodeFromBase64: (encoded: string) => string,
  withCache: boolean = true,
): string => {
  if (hashedLink.startsWith('/staticPage')) {
    return hashedLink;
  }

  const invertEncodedList = invert(encodedLinks[sitename]);
  if (withCache && invertEncodedList[hashedLink]) {
    return invertEncodedList[hashedLink];
  }

  const clearHash = hashedLink.replace('/', '');
  let uri;

  try {
    uri = decodeFromBase64(clearHash).replace(sitename, '');
  } catch (error) {
    uri = clearHash;
  }

  const link = `/${uri}`;

  if (!encodedLinks[sitename]) {
    encodedLinks[sitename] = {};
  }

  if (withCache) {
    encodedLinks[sitename][link] = hashedLink;
  }

  return link;
};

/**
 * Hash link to static page link for frontend (browser version)
 * /c3RhdGljUGFnZS90ZXJtcz9odG1sPXNhZmVkYXRpbmdiZW5hdWdodHkuY29t to /staticPage/terms?html=safedating
 */
export const decodeLink = (
  hashedLink: string,
  externalDomain?: string,
): string => {
  if (window.IS_INTEGRATION_TEST_ENVIRONMENT) {
    return hashedLink;
  }

  const {sitename} = parseHostname(hostnameConverter(window.location.hostname));

  return decodeLinkBase(hashedLink, externalDomain || sitename, (clearHash) =>
    window.atob(clearHash),
  );
};

/**
 * Hash link to static page link for backend (node version)
 * /c3RhdGljUGFnZS90ZXJtcz9odG1sPXNhZmVkYXRpbmdiZW5hdWdodHkuY29t to /staticPage/terms?html=safedating
 */
export const decodeLinkBySiteName = (
  hashedLink: string,
  sitename: string = null,
): string => {
  /**
   * @throws Error - Node.js does not know how to throw errors when decrypting a string therefore we do it manually
   *    checking what came and what we decoded again we encode.
   */
  const decodeFromBase64 = (clearHash: string) => {
    const hashAsString = Buffer.from(clearHash, 'base64').toString();
    const stringAsHash = Buffer.from(hashAsString).toString('base64');

    if (stringAsHash !== clearHash) {
      throw new Error('decode error');
    }

    return hashAsString;
  };

  return decodeLinkBase(hashedLink, sitename, decodeFromBase64, false);
};

/**
 * If pathname is static page (terms, privacy or safedating)
 */
export const isHashed = (link: string) => includes(linksForHash, link);

/**
 * Route params to string
 * Example: `{html: 'safedating', page: 1}` to `html=safedating&page=1`.
 */
export const formatParams = (
  options: Record<string, string | number>,
): string => {
  const params = map(
    options,
    (optionData, optionName) => `${optionName}=${optionData}`,
  ).join('&');

  if (isEmpty(params)) {
    return '';
  }

  return `?${params}`;
};

export const getAllHashedLinksList = (externalDomain?: string): string[] =>
  linksForHash.map((link) => encodeLink(link, externalDomain));
