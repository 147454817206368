import type {FC} from 'react';
import React from 'react';

import type {TextProps} from './Text';
import Text from './Text';

const TextSuccess: FC<Omit<TextProps, 'type'>> = (props) => (
  <Text {...props} type={Text.TYPE.SUCCESS} />
);

export default TextSuccess;
