enum PaymentScenario {
  /**
   * Default scenario with payment form where it's needs
   */
  INITIAL = 'initial',
  /**
   * OneClick scenario mean that payment passes without filling any value in form (if form need's for method)
   */
  ONECLICK = 'oneClick',
}

export default PaymentScenario;
