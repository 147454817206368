/**
 * Key to save if mini messenger is visible in LocalStorage.
 */
export const MINI_MESSENGER_VISIBLE_RECIPIENT_KEY =
  'MINI_MESSENGER_VISIBLE_RECIPIENT';

/**
 * Key to save timestamp when contact should be deleted.
 * Used for 'upgradeReadTimer' feature.
 */
export const CONTACT_DELETION_SCHEDULE_KEY = 'contactDeletionSchedule';

/**
 * Key to save timestamp when message should be deleted.
 * Used for 'upgradeReadTimer' feature.
 */
export const MESSAGE_DELETION_SCHEDULE_KEY = 'messageDeletionSchedule';

/**
 * Key to save flag if will be skipped the first goto PP.
 * Need for other logic @see messenger.js
 */
export const SKIPPED_FIRST_GO_TO_PP_KEY = 'skippedFirstGoToPP';
