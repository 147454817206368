import {useEffect, useState} from 'react';

export default function useRecordingTime(isRecording) {
  const [recordingTime, setRecordingTime] = useState(0);

  useEffect(() => {
    let time = 0;
    const interval =
      isRecording && window.setInterval(() => setRecordingTime(++time), 1000);
    return () => interval && window.clearInterval(interval);
  }, [isRecording]);

  return recordingTime;
}
