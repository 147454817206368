import type {Observable} from 'rxjs';
import {from} from 'rxjs';
import {map} from 'rxjs/operators';

import getPhotoUploadSubscription from '@core/user/profile/current/utils/getPhotoUploadSubscription';

import NOTIFICATION_TYPES from '../constants/notificationTypes';
import type {CommonNotification} from '../types';

const photoUploadDataSource = (): Observable<CommonNotification> =>
  // 'from' is used to convert zen-observable into RxJS compatible observable
  from(getPhotoUploadSubscription()).pipe(
    map(() => ({
      type: NOTIFICATION_TYPES.PHOTO_UPLOAD,
    })),
  );

export default photoUploadDataSource;
