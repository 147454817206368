import {MSG} from '../constants/socketEventNames';

type DisplaySettings = {
  availableRoutes: string[];
  countPerSession: number;
  intervalInSeconds: number;
};

export default {
  RemarketingPopupSettingsSubscription: {
    incomingEvents: [`${MSG}paymentPopup.displaySettings`],
    fromServer: ({
      availableRoutes,
      countPerSession,
      intervalInSeconds,
    }: DisplaySettings) => ({
      data: {
        remarketing: {
          paymentPopup: {
            isPopupAvailable: true,
            displaySettings: {
              availableRoutes,
              countPerSession,
              intervalInSeconds,
              __typename: 'DisplaySettings',
            },
            __typename: 'PopupInfo',
          },
          __typename: 'Remarketing',
        },
      },
    }),
  },
};
