/**
 * Direction of widget
 * @see Triangle
 */
enum Direction {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
}

export default Direction;
