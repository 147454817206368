import type {ReactNode} from 'react';

import AbstractProxyService from '@core/utils/react/AbstractProxyService';

import type PopupSourceEvent from '../constants/PopupSourceEvent';
import type PopupProvider from '../containers/PopupProvider';
import type {PopupOptions} from '../types/PopupOptions';
import type PopupComponent from '../types/PopupComponent';

/**
 * @class PopupProxy
 * @classdesc Proxy class for providing access to popup functionality.
 * DO NOT IMPORT THIS CLASS IF YOU WANT TO USE POPUPS.
 * Use singleton instance of this class, that is created by PopupProvider.
 * HOC withPopup was removed because opening and closing popups were causing rerender of wrapped components.
 * This solution prevents redundant renders.
 * @see PopupProvider
 */
class PopupProxy extends AbstractProxyService {
  /**
   * @see `PopupService.setProxy` usage in `PopupProvider.constructor`.
   */
  proxy: PopupProvider | null = null;

  /**
   * If popupProvider available - opens popup
   */
  openPopup = (...args: [PopupComponent, PopupOptions?]) => {
    if (this.isProxyAvailable()) {
      this.proxy.open(...args);
    }
  };

  /**
   * If popupProvider available - closes popup
   */
  closePopup = (removeQueue?: boolean, sourceEventType?: PopupSourceEvent) => {
    /**
     * Popup can be close from historyMiddleware.js before proxy initialization
     * We can skip sending error to sentry in this method because if proxy isn't set
     * we don't have anything to close and won't broke any logic
     */
    if (this.isProxyAvailable(false)) {
      this.proxy.close(removeQueue, sourceEventType);
    }
  };

  /**
   * If popupProvider available - opens Backbone popup
   */
  openBackbonePopup = (...args: [ReactNode, PopupOptions?]) => {
    if (this.isProxyAvailable()) {
      this.proxy.openBackboneView(...args);
    }
  };

  /**
   * If popupProvider available - set popup props
   * @param {object} args
   */
  setPopupProps = (...args: [Partial<PopupOptions>]) => {
    if (this.isProxyAvailable()) {
      this.proxy.setPopupProps(...args);
    }
  };

  /**
   * @override
   */
  getName(): string {
    return 'PopupProxy';
  }
}

/**
 * Singleton class instance, that provides popup functionality for all application.
 * @see PopupService.js
 */
export default new PopupProxy();
