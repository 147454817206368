import once from 'lodash/once';

import {getClientInstance} from '@core/graphql/client';

import type {DisapproveVideoSubscription} from '../graphql/subscriptions/disapproveVideo';
import VIDEO_DISAPPROVE_SUBSCRIPTION from '../graphql/subscriptions/disapproveVideo.gql';

const getDisapproveVideoSubscription = once(() =>
  getClientInstance().subscribe<DisapproveVideoSubscription>({
    query: VIDEO_DISAPPROVE_SUBSCRIPTION,
  }),
);

export default getDisapproveVideoSubscription;
