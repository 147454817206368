/**
 * scrollingElement on ios its body, android - html.
 */
const getDefaultScrollableElement = (): HTMLElement | null => {
  if (typeof document === 'undefined') return null;

  return document.scrollingElement as HTMLElement;
};

export default getDefaultScrollableElement;
