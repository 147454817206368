import {getClientInstance} from '@core/graphql/client';

import {DEBT_LIMIT_REACHED} from '../constants/reasons';
import TARGET_USER_SEND_FORM_QUERY from '../graphql/queries/targetUserSendForm.gql';
import DELETE_MESSAGE_MUTATION from '../graphql/mutations/deleteMessage.gql';
import updateRecipientMessagePermissions from './updateRecipientMessagePermissions';
import deleteMessageCache from './deleteMessageCache';

/**
 * When recipient get adult photo on traditional sites he could remove this photo forever.
 * @param {string} messageId
 * @param {string} userId
 * @returns {Promise<<any>>}
 */
const deleteMessage = (messageId, userId) =>
  getClientInstance().mutate({
    mutation: DELETE_MESSAGE_MUTATION,
    variables: {
      messageId,
    },
    update: async (
      cache,
      {
        data: {
          messenger: {
            deleteMessage: {result, errors},
          },
        },
      },
    ) => {
      if ((errors && errors.general) || !result) return;
      let needUpdatePermissions = false;
      const client = getClientInstance();
      const {
        data: {
          user: {
            messagePermissions: {canSend, reason},
          },
        },
      } = await client.query({
        query: TARGET_USER_SEND_FORM_QUERY,
        variables: {userId},
      });

      if (reason === DEBT_LIMIT_REACHED && !canSend) {
        /*
         Before delete message should have actual data in cache.
         If after delete we try quickly write new message shouldn't show popup buy coins.
         */
        await updateRecipientMessagePermissions({
          client,
          userId,
          canSendNext: true,
          // update permissions current recipient only in cache, without fetching
          onlyInCache: true,
        });

        // If delete one message form one recipient when debt limit,
        // should remove this restrictions for other recipients too.
        // Shouldn't show popup buy coins for other recipients.
        needUpdatePermissions = true;
      }

      deleteMessageCache({
        recipientId: userId,
        messageId,
        needUpdatePermissions,
      });
    },
  });

export default deleteMessage;
