import forEach from 'lodash/forEach';

/**
 * Reset attributes of video elements to default
 * Drop down a view video to start
 * @see Gallery.js
 * @param galleryElem
 */
const resetVideoElements = (galleryElem) => {
  const videoList = galleryElem.querySelectorAll('video');
  if (videoList.length) {
    forEach(videoList, (video) => {
      if (video.currentTime !== 0) {
        video.pause();
        video.currentTime = 0;
      }
    });
  }
};

export default resetVideoElements;
