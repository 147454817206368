import map from 'lodash/map';
import isNil from 'lodash/isNil';

/**
 * @param {array} extensions
 * @param {boolean} upperCase
 * @return {string}
 */
const getAllowedExtensionsAsString = ({extensions, upperCase = false}) => {
  if (isNil(extensions)) {
    return '';
  }

  return map(extensions, (extension) => {
    return upperCase ? extension.toUpperCase() : extension;
  }).join(', ');
};

export default getAllowedExtensionsAsString;
