import {useRef} from 'react';
import {useQuery} from '@apollo/client';

import getPackageListFromMethodTabs from './getPackageListFromMethodTabs';
import PAYMENT_PACKAGES_QUERY from '../graphql/queries/paymentPackages.gql';
import usePaymentParams from '../../../common/utils/usePaymentParams';
import useFailVia from '../../../common/utils/useFailVia';

/**
 * WARNING: USE ONLY ON PAYMENT PAGE!
 * Hook for receiving cached payment packages that were received earlier by usePaymentPackages hook.
 * useQuery loading flag not working correctly with 'cache-only' fetch policy.
 * @see usePaymentPackages.js
 * @return {{packages: Array, error: ApolloError, loading: boolean}}
 * Props drilling hotfix. Need get packages inside usePaymentMotivation hook, because BU can`t implement discount value correctly.
 * TODO: Need remove after BU side fix discount.
 * @deprecated
 */
const useCachedPaymentPackages = () => {
  const {via, action, prevVia, source} = usePaymentParams();

  const failVia = useFailVia();

  const computedVia = failVia || via;
  const computedPrevVia = failVia ? via : prevVia;

  const {data, error, loading} = useQuery(PAYMENT_PACKAGES_QUERY, {
    variables: {
      action,
      via: computedVia,
      prevVia: computedPrevVia,
      source,
    },
    fetchPolicy: 'cache-only',
  });

  const packages = useRef([]);

  const packagesData = data?.payment?.packagesData;

  if (packagesData && !loading) {
    packages.current = getPackageListFromMethodTabs(packagesData.methodTabs);
  }

  return {
    packages: packages.current,
    error,
    loading,
  };
};

export default useCachedPaymentPackages;
