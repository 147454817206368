import type {FC, ReactNode} from 'react';
import React from 'react';
import cn from 'classnames';

import {Spacing} from '../spacing';
import css from './AccordionContent.css';

interface AccordionContentProps {
  children: ReactNode;
  spaced?: boolean;
}

const AccordionContent: FC<AccordionContentProps> = ({children, spaced}) => (
  <Spacing withoutTop>
    <div className={cn(spaced && css.spaced)}>{children}</div>
  </Spacing>
);

export default AccordionContent;
