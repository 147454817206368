import {useState, useCallback} from 'react';

import shouldUseInverseColorScheme from './shouldUseInverseColorScheme';

/**
 * Checks if we should use 'inversion' of colors for child elements
 * based on background rule attached to DOM note where we set reference returned
 * by this hook.
 *
 * @see shouldUseInverseColorScheme
 *
 * @param {Boolean} [skip]
 *
 * @returns {Array.<{ref: Object, inverseColor: Boolean}>}
 */
const useInverseColorScheme = (skip) => {
  /**
   * Use callback-ref since standard ref doesn't call re-render
   * when ref is setted on DOM node.
   */
  const [inverseColor, setInverseColor] = useState(null);

  const ref = useCallback(
    (node) => {
      if (node === null || skip) return;
      setInverseColor(shouldUseInverseColorScheme(node));
    },
    [skip],
  );

  return [ref, inverseColor];
};

export default useInverseColorScheme;
