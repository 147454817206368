import dayjs from '@core/date/utils/dayjs';

import type {Timestamp} from './types';

/**
 * Shift date by days
 */
const shiftDateByDays = (
  time: Timestamp,
  additionalDays: number = 0,
): number => {
  const dayjsTime = dayjs.unix(parseInt(time.toString(), 10));
  return dayjs(dayjsTime).add(additionalDays, 'days').unix();
};

export default shiftDateByDays;
