import {useReactiveVar} from '@apollo/client';

import googlePayMaskedCardVar from '@core/graphql/vars/googlePayMaskedCardVar';

const useIsGooglePayCvvForm = () => {
  return Boolean(useReactiveVar(googlePayMaskedCardVar));
};

export const closeGooglePayCvvForm = () => {
  googlePayMaskedCardVar(null);
};

export const openGooglePayCvvForm = (maskedCard) => {
  googlePayMaskedCardVar(maskedCard);
};

export default useIsGooglePayCvvForm;
