enum GalleryActionsAppearance {
  /**
   * For user actions that are in different placements,
   * we use all buttons of the Flat type, as well as with icons and text.
   */
  WITH_ALL_FLAT_ACTIONS = 'withAllFlatActions',

  /**
   * For user actions that are in different placements, we use all buttons of the Round type, only with icon.
   */
  WITH_ROUND_ACTIONS = 'withRoundActions',

  /**
   * For user actions that are in different placements, we use all buttons of the Round type, only with icon
   * but it has reversed typed comparing with WITH_ROUND_ACTIONS
   */
  WITH_ROUND_REVERSED_ACTIONS = 'withRoundReversedActions',

  /**
   * Flat actions without text and separators
   */
  WITH_FLAT_ONLY_ICONS_ACTIONS = 'withFlatOnlyIconsActions',

  /**
   * No actions at all
   */
  NONE = 'none',
}

export default GalleryActionsAppearance;
