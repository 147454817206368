/**
 * Scaling for images
 * @see FittingImage.tsx
 */
enum ImageScaling {
  COVER = 'cover',
  CONTAIN = 'contain',
}

export default ImageScaling;
