/**
 * Position of overlay
 * @see Overlay.tsx
 */
enum OverlayPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export default OverlayPosition;
