import type {FC, ReactNode} from 'react';
import React from 'react';
import classNames from 'classnames';

import css from './Title.css';

/**
 * Enumeration for aligning titles. This can be used to specify how a title
 * should be aligned within a given context, such as text alignment in a UI component.
 *
 * Properties:
 * - LEFT: Aligns the title to the left.
 * - CENTER: Centers the title.
 * - RIGHT: Aligns the title to the right.
 */
export enum TitleAlign {
  /** Aligns the title to the left */
  LEFT = 'left',
  /** Centers the title */
  CENTER = 'center',
  /** Aligns the title to the right */
  RIGHT = 'right',
}

export type TitleProps = {
  children?: ReactNode;
  primary?: boolean;
  success?: boolean;
  warning?: boolean;
  inherit?: boolean;
  muted?: boolean;
  className?: string;
  level?: 1 | 2 | 3 | 4;
  inverse?: boolean;
  uppercase?: boolean;
  align?: TitleAlign;
};

/**
 * Title Component
 *
 * This is a functional component that renders a title with various styling options.
 */
const Title: FC<TitleProps> & {ALIGN: typeof TitleAlign} = ({
  children,
  className,
  level = 2,
  muted,
  primary,
  warning,
  success,
  inverse,
  uppercase,
  align,
  inherit = false,
}) => (
  <div
    className={classNames(
      css.title,
      css[`title${level}`],
      success && css.success,
      primary && css.primary,
      warning && css.warning,
      inverse && css.inverse,
      inherit && css.inherit,
      muted && css.muted,
      uppercase && css.uppercase,
      align && css[align],
      className,
    )}
  >
    {children}
  </div>
);

Title.ALIGN = TitleAlign;

export default Title;
