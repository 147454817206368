export const CARD_HOLDER = 'card_holder';

export const CARD_NUMBER = 'card_number';

export const DNI_NUMBER = 'dniNumber';

export const EXPIRATION_DATE_M = 'expiration_date_m';

export const EXPIRATION_DATE_Y = 'expiration_date_y';

export const NAME = 'name';

export const SECURITY_NUMBER = 'security_number';

export const POSTAL_CODE = 'postal_code';

export const ADDRESS = 'address';

export const CITY = 'city';

export const COUNTRY_CODE = 'country_code';

export const NAME_FIRST = 'name_first';

export const NAME_LAST = 'name_last';

export const BIRTH_DATE = 'birthDate';

export const STREET = 'street';

export const HOUSE_NUMBER = 'houseNumber';

export const PHONE_NUMBER = 'phone_number';

export const STATE = 'state';

export default {
  CARD_HOLDER,
  CARD_NUMBER,
  DNI_NUMBER,
  EXPIRATION_DATE_M,
  EXPIRATION_DATE_Y,
  NAME,
  SECURITY_NUMBER,
  POSTAL_CODE,
  ADDRESS,
  CITY,
  COUNTRY_CODE,
  NAME_FIRST,
  NAME_LAST,
  BIRTH_DATE,
  STREET,
  HOUSE_NUMBER,
  PHONE_NUMBER,
  STATE,
};
