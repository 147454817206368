import find from 'lodash/find';
import includes from 'lodash/includes';

import type {NaughtyModeInfo} from '@core/types';
import {PhotoRequestTypeEnum} from '@core/types';
import type NaughtyMode from '@core/naughtyMode/types/naughtyMode';

import PhotoLevel from '../constants/PhotoLevel';
import type {Photo} from '../types';

/**
 * Checks if naughty mode holder should be visible on this photoLevel depending to user naughtyMode
 */
export const isNaughtyModeHolderOnMedia = (
  availableModes: NaughtyModeInfo[],
  naughtyMode: number,
  photoLevel: PhotoLevel,
): boolean => {
  const currentMode = find(availableModes, (mode) => mode.id === naughtyMode);
  if (!currentMode) {
    return false;
  }
  return !includes(currentMode.contentLevels, photoLevel);
};

type GetCorrectedPhotoLevelParams = Photo & {
  skipBlur?: boolean;
  isMy?: boolean;
} & NaughtyMode & {
    isStrictNudityLaws?: boolean;
  };

/**
 * Correct photo level based on its own photo level and current user level.
 * Additionally, there is a setting for some countries, where we must apply stronger level.
 * (always high-blurred for blurred and high-blurred values of 'level')
 */
const getCorrectedPhotoLevel = ({
  level,
  forceLevel,
  isMy,
  skipBlur,
  naughtyMode,
  availableModes,
  isStrictNudityLaws,
  privateAttributes,
}: GetCorrectedPhotoLevelParams): {
  level: PhotoLevel;
  blurLevel: PhotoLevel;
} => {
  if (isMy) {
    return {
      level: PhotoLevel.NORMAL,
      blurLevel: forceLevel || PhotoLevel.NORMAL,
    };
  }
  const isNaughtyModeHolderOnMediaResult = isNaughtyModeHolderOnMedia(
    availableModes,
    naughtyMode,
    level,
  );

  const {isPrivate, requestType, isRequested} = privateAttributes || {};

  if (isPrivate && !skipBlur) {
    if (
      requestType === PhotoRequestTypeEnum.pmaPhoto ||
      (requestType === PhotoRequestTypeEnum.privateAlbum && !isRequested)
    ) {
      return {
        level,
        blurLevel: PhotoLevel.HIGHLY_BLURRED,
      };
    }

    if (requestType === PhotoRequestTypeEnum.privateAlbum && isRequested) {
      return {
        level: PhotoLevel.NORMAL,
        blurLevel: PhotoLevel.NORMAL,
      };
    }
  }

  if (forceLevel) {
    const normalizedLevel =
      level === PhotoLevel.SASSY ? PhotoLevel.BLURRED : level;
    return {
      level: isNaughtyModeHolderOnMediaResult
        ? normalizedLevel
        : PhotoLevel.NORMAL,
      blurLevel: forceLevel,
    };
  }

  if (!level || !isNaughtyModeHolderOnMediaResult || skipBlur) {
    return {
      level: PhotoLevel.NORMAL,
      blurLevel: PhotoLevel.NORMAL,
    };
  }

  if (level === PhotoLevel.SASSY) {
    return {
      level: PhotoLevel.BLURRED,
      blurLevel: PhotoLevel.BLURRED,
    };
  }

  return {
    level,
    blurLevel: isStrictNudityLaws ? PhotoLevel.HIGHLY_BLURRED : level,
  };
};

export default getCorrectedPhotoLevel;
