import type {PaymentProcessStep} from '../../types/paymentProcess';
import {setProcessing} from '../../../common/utils/setPaymentProcessingStatus';

/**
 * Send track and set processing status that indicate start of payment process
 */
const reportStartPayment: PaymentProcessStep = async (data) => {
  setProcessing();
  return data;
};

export default reportStartPayment;
