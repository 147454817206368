const initialHeight = window.innerHeight;

/**
 * 25% of the screen height can be considered the minimum size for a keyboard.
 * Usually it is 30-50% of the screen height;
 */
const KEYBOARD_MIN_HEIGHT_PERCENTAGES = 25;

/**
 * 150px is a keyboard height. Can be changed if needed.
 * Used for check if keyboard is opened.
 */
const KEYBOARD_MIN_HEIGHT = 150;

const isKeyboardOpen = () => {
  const screenHeight = Math.max(window.innerHeight, initialHeight);
  const clippedHeight = screenHeight - window.visualViewport.height;

  return (
    (clippedHeight >= KEYBOARD_MIN_HEIGHT || // is more than 150px
      clippedHeight >=
        (screenHeight * KEYBOARD_MIN_HEIGHT_PERCENTAGES) / 100) && // is more than 25% of the screen height
    window.innerWidth < window.innerHeight // is vertical mode
  );
};

export default isKeyboardOpen;
