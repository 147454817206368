import {useMemo} from 'react';
import {useQuery, useReactiveVar} from '@apollo/client';

import activeMethodTabVar from '@core/graphql/vars/activeMethodTabVar';
import isOneClickUsedVar from '@core/graphql/vars/isOneClickUsedVar';

import PAYMENT_METHODS from '../../../common/constants/paymentMethods';
import isCardPaymentType from '../../../forms/card/utils/isCardPaymentType';
import useCachedPaymentPackages from '../../package/utils/useCachedPaymentPackages';
import WALLET_PAY_USER_DATA_QUERY from '../../../common/graphql/queries/walletPayUserData.gql';
import useAltMethodsSettings from '../../../common/utils/useAltMethodsSettings';

const usePaypalSettings = () => {
  const activeMethodTab = useReactiveVar(activeMethodTabVar);

  const isOneClickUsed = useReactiveVar(isOneClickUsedVar);

  const {packages, loading: packagesLoading} = useCachedPaymentPackages();

  const paymentMethod = useMemo(() => {
    if (packagesLoading) {
      return null;
    }
    // Check if Paypal payment allowed if package with paypal method is exists in packages list
    const paypalPackage = packages.find(({method}) => {
      return (
        method === PAYMENT_METHODS.PAY_PAL_V2 ||
        method === PAYMENT_METHODS.PAY_PAL
      );
    });

    return paypalPackage?.method;
  }, [packages, packagesLoading]);

  const {
    settings: altSettings,
    loading: altSettingsLoading,
    error: altSettingsError,
  } = useAltMethodsSettings({paymentMethod, skip: !paymentMethod});

  const {
    data: userData,
    loading: userDataLoading,
    error: userDataError,
  } = useQuery(WALLET_PAY_USER_DATA_QUERY);

  const loading = userDataLoading || altSettingsLoading || packagesLoading;

  const error = altSettingsError || userDataError;

  return useMemo(() => {
    const isCardPayment = isCardPaymentType(activeMethodTab);

    const isOneClickAllowed = altSettings?.isOneClickForm || false;

    return {
      loading,
      error,
      isAllowed: isCardPayment && Boolean(paymentMethod),
      paymentData: {
        country: userData?.myUser.profile.location.country || null,
        userId: userData?.myUser.profile.id || null,
        siteName: userData?.site.name || null,
        locale: userData?.locale || null,
      },
      isOneClickUsed: isCardPayment && isOneClickAllowed && isOneClickUsed,
      isOneClickAllowed,
      paymentMethod,
    };
  }, [
    loading,
    error,
    isOneClickUsed,
    altSettings,
    paymentMethod,
    activeMethodTab,
    userData,
  ]);
};

export default usePaypalSettings;
