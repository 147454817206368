enum BackendBasedAppearance {
  /**
   * Special flag if functionality is based on back-end flag instead of frontend.
   * It's an edge between "multitheme" and API-controlled design. But since it's
   * before all appearance-related things - it's part of "multitheme".
   */
  BACKEND_BASED = 'backendBased',
}

export default BackendBasedAppearance;
