import type {FC, ReactNode, ReactElement} from 'react';
import {Children, useMemo, useRef, useState, cloneElement} from 'react';

import useEventCallback from '@core/utils/react/useEventCallback';

import type {AccordionProps} from './Accordion';

export interface AccordionGroupProps {
  // If you have less than 2 accordions it makes no sense to use the "AccordionGroup".
  children: [ReactNode, ReactNode, ...ReactNode[]];
}

const NONE = -1;

/**
 * Container around 'accordions'.
 * Manages the setting of the active state of the accordions.
 */
const AccordionGroup: FC<AccordionGroupProps> = ({children}) => {
  const activeIndex = useRef(NONE);
  const [, update] = useState({});

  const defaultActiveIndex = useMemo(
    () =>
      Children.toArray(children).findIndex(
        (item: ReactElement<AccordionProps>) => item?.props?.defaultActive,
      ),
    [children],
  );

  /**
   * Reset default active tab in case when Accordion was rendered
   * and children updates with another defaultActive prop.
   * Case: user open my profile and click on notification for edit basic info
   */
  useMemo(() => {
    activeIndex.current = defaultActiveIndex;
  }, [defaultActiveIndex]);

  const handleClick = useEventCallback((index: number) => {
    activeIndex.current = activeIndex.current === index ? NONE : index;
    update({});
  });

  const closeCurrentItem = useEventCallback(() => {
    handleClick(NONE);
  });

  return Children.map(
    children,
    (child: ReactElement, index) =>
      child &&
      cloneElement(child, {
        active: activeIndex.current === index,
        onClick: () => {
          handleClick(index);
          child.props.onClick?.();
        },
        closeCurrentItem,
      }),
  );
};

export default AccordionGroup;
