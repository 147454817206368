import type {MouseEvent} from 'react';
import once from 'lodash/once';
import {useHistory} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';

import systemNotificationsVar from '@core/graphql/vars/systemNotificationsVar';
import generateUniqueId from '@core/utils/id/generateUniqueId';
import isInRouteList from '@core/utils/routing/isInRouteList';

import DISABLED_ROUTES from '../constants/disabledRoutes';
import NOTIFICATION_TYPES from '../constants/notificationTypes';
import RESTRICTIONS_BY_NOTIFICATION_TYPE from '../constants/restrictionsByNotificationType';
import NotificationsService from './NotificationsService';
import type {Notification} from '../types';

/**
 * Notification that can be only one in stack.
 * E.g. lost connection can appear and disappear, we really don't need to keep them in stack.
 */
const UNIQUE_NOTIFICATION_TYPES: string[] = [
  NOTIFICATION_TYPES.SEARCH_LIMIT,
  NOTIFICATION_TYPES.LOST_CONNECTION,
  NOTIFICATION_TYPES.AGE_VERIFICATION,
  NOTIFICATION_TYPES.MATCH,
  NOTIFICATION_TYPES.SUCCESS_PAYMENT,
];

const NOTIFICATION_ALLOWED_ON_PAYMENT_PAGE: string[] = [
  NOTIFICATION_TYPES.SUCCESS_PAYMENT,
];

type DataSource = {
  subscribe: (callback: (notification: Notification) => void) => void;
};

const canAddNotification = (
  notifications: Notification[],
  notification: Notification,
) =>
  !UNIQUE_NOTIFICATION_TYPES.includes(notification.type) ||
  !notifications.some(({type}) => type === notification.type);

/**
 * Notification will be stored here when it's coming on disabled route.
 * We will display delayed notifications later (after navigation to allowed route).
 */
const delayedNotifications: Notification[] = [];

/**
 * Listen for interaction and history events to add system notifications.
 */
const startListeners = once(
  (getDataSource: () => DataSource, history: ReturnType<typeof useHistory>) => {
    const addNotification = (options: Notification) => {
      const {pathname} = history.location;

      if (
        !options?.type ||
        (RESTRICTIONS_BY_NOTIFICATION_TYPE[options.type] &&
          isInRouteList(
            RESTRICTIONS_BY_NOTIFICATION_TYPE[options.type],
            pathname,
          ))
      ) {
        return;
      }

      const notification = {
        ...options,
        key: generateUniqueId(),
      };

      /**
       * There is a special case for payment page. We can display only restricted number of notifications.
       * If you need in near future other exclusions - make restrictions for each notifications,
       * as an example @see ACLRule.js
       */
      if (
        !NOTIFICATION_ALLOWED_ON_PAYMENT_PAGE.includes(notification.type) &&
        isInRouteList(DISABLED_ROUTES, pathname)
      ) {
        if (canAddNotification(delayedNotifications, notification)) {
          delayedNotifications.push(notification);
        }

        return;
      }

      if (canAddNotification(systemNotificationsVar(), notification)) {
        systemNotificationsVar([...systemNotificationsVar(), notification]);
      }
    };

    NotificationsService.setProxy({addNotification});

    // subscribe to notification events
    getDataSource().subscribe((notification) => {
      NotificationsService.addNotification(notification);
    });

    // listen for history change to display delayedNotifications if needed
    history.listen(({pathname}) => {
      if (
        delayedNotifications.length &&
        !isInRouteList(DISABLED_ROUTES, pathname)
      ) {
        systemNotificationsVar([
          ...systemNotificationsVar(),
          ...delayedNotifications,
        ]);
        delayedNotifications.length = 0;
      }
    });
  },
);

const handleNotificationClose = (event?: MouseEvent) => {
  event?.stopPropagation();

  const [notification, ...rest] = systemNotificationsVar();
  if (!notification) {
    return;
  }

  notification.onClose?.();

  systemNotificationsVar(rest);
};

// notification lifetime
const EXPIRE_TIME = 5000;

const useSystemNotifications = (
  getDataSource: () => DataSource,
): {
  onClose: (event?: MouseEvent) => void;
  notifications: Notification[];
  expireTime: number;
} => {
  const history = useHistory();
  startListeners(getDataSource, history);

  const notifications = useReactiveVar(systemNotificationsVar);
  return {
    onClose: handleNotificationClose,
    notifications,
    expireTime: EXPIRE_TIME,
  };
};

export default useSystemNotifications;
