/**
 * Button types for using outside of component
 * @see Button.tsx
 */
enum ButtonType {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  PAY = 'pay',
  DANGER = 'danger',
  FLAT = 'flat',
  FLAT_COVER = 'flatCover', // Flat button with cover by default
}

export default ButtonType;
