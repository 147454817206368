import React from 'react';
import PropTypes from 'prop-types';

import getItemIndex from '../utils/getItemIndex';

/**
 * @param {Function} renderItem - Render prop
 * @param {Array} items - Array of slider items
 * @param {Number} slideVirtualIndex - Current item virtual index
 */
const GallerySlideContent = React.memo(
  ({renderItem, items, slideVirtualIndex, isActive}) => {
    const slideItemIndex = getItemIndex(items.length, slideVirtualIndex);
    const item = items[slideItemIndex];

    return renderItem(item, isActive);
  },
);

GallerySlideContent.propTypes /* remove-proptypes */ = {
  renderItem: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  slideVirtualIndex: PropTypes.number,
  isActive: PropTypes.bool,
};

export default GallerySlideContent;
