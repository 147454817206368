import {MSG} from '../constants/socketEventNames';

export default {
  DisapproveVideoSubscription: {
    incomingEvents: [`${MSG}UserVideoDeclined`, `${MSG}UserVideoApproved`],

    fromServer: ({videoId, type}) => ({
      data: {
        disapprove: {
          id: videoId,
          video: type === 'UserVideoDeclined',
          __typename: 'VideoType',
        },
      },
    }),
  },
};
