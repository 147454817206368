import type {FC} from 'react';
import React from 'react';
import cn from 'classnames';

import {Icon} from '../icon';
import Direction from '../../constants/Direction';
import css from './Triangle.css';

interface TriangleProps {
  direction?: string;
  inverse?: boolean;
}

/**
 * It's a small triangle arrow used on selects
 */
const Triangle: FC<TriangleProps> = ({
  direction = Direction.BOTTOM,
  inverse,
}) => (
  <div className={cn(css.triangle, css[direction])}>
    <Icon
      type="select-triangle"
      standard
      inverse={inverse}
      className={css.icon}
    />
  </div>
);

export default Triangle;
