import type {FC} from 'react';
import React from 'react';

import type {TitleProps, TitleAlign} from './Title';
import Title from './Title';

const Title3: FC<Omit<TitleProps, 'level'>> & {ALIGN: typeof TitleAlign} = (
  props,
) => <Title {...props} level={3} />;

Title3.ALIGN = Title.ALIGN;

export default Title3;
