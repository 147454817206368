// Cookie for testing payment method buttons (buttons display only)

/**
 * @type {string}
 */
export const APPLE_PAY_TEST_ENVIRONMENT = 'applePayVisualTest';

/**
 * @type {string}
 */
export const PAY_PAL_TEST_ENVIRONMENT = 'payPalVisualTest';

/**
 * @type {string}
 */
export const GOOGLE_PAY_TEST_ENVIRONMENT = 'googlePayVisualTest';
