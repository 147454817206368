import type {FC} from 'react';
import React from 'react';
import type {Location} from 'history';
import {Route} from 'react-router-dom';

import {parsePathname} from '@core/utils/url';

import type {StaticPageChunkProps} from '../types';
import {decodeLink, formatParams, isHashed} from '../utils/hashedStaticLinks';

type HashedStaticPageRouteProps = {
  location: Location;
  staticPageChunk: FC<StaticPageChunkProps>;
  notFoundComponent: FC;
};

/**
 * Route for hashed static page links
 * s.u. /c3RhdGljUGFnZS90ZXJtcz9odG1sPXNhZmVkYXRpbmdiZW5hdWdodHkuY29t is /staticPage/terms?html=safedating
 * for concrete site domain
 * opens static page terms with params html=safedating
 */
const HashedStaticPageRoute: FC<HashedStaticPageRouteProps> = ({
  location,
  staticPageChunk: StaticPageChunk,
  notFoundComponent: NotFoundComponent,
}) => {
  const link = decodeLink(location.pathname);

  if (isHashed(link)) {
    const pathname = parsePathname(link);

    return (
      <StaticPageChunk
        page={pathname.action}
        params={formatParams(pathname.options)}
      />
    );
  }

  return <Route component={NotFoundComponent} />;
};

export default HashedStaticPageRoute;
