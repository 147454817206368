/**
 * Horizontal alignment for items
 *
 * @see Row.tsx
 * @see Action.tsx
 * @see Group.tsx
 */
enum Align {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
  /** Like "space between" */
  BETWEEN = 'between',
  /** Like "space around" */
  AROUND = 'around',
  STRETCH = 'stretch',
}

export default Align;
