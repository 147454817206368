import memoize from 'lodash/memoize';

/**
 * Check if user can make applePay payments
 * @param {String} merchantId
 * @return {Promise<Boolean>}
 */
async function canMakeApplePayPayments(merchantId) {
  if (!merchantId || !window.ApplePaySession) {
    return false;
  }

  return window.ApplePaySession.canMakePaymentsWithActiveCard(merchantId);
}

export default memoize(canMakeApplePayPayments);
