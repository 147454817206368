import dayjs from '@core/date/utils/dayjs';

import LOCALES from './constants/locales';
import DAYJS_LOCALES from './constants/dayjsLocales';

/**
 * Sets dayjs and update current locale if need
 * @param locale {string}
 */
const setDayjsLocale = (locale) => {
  /**
   * test environment uses the only one locale
   */
  const dayjsLocale = DAYJS_LOCALES[locale || LOCALES.EN];

  dayjs.locale(dayjsLocale);

  /**
   * Should use numbers instead of arabic symbols.
   * No need update all arabic locales because only dayjs/locale/ar.js uses arabic symbols.
   */
  if (locale === LOCALES.AR_EG) {
    dayjs.updateLocale(dayjsLocale, {
      preparse: (string) => string,
      postformat: (string) => string,
    });
  }
};

export default setDayjsLocale;
