/* eslint-disable no-empty */

import {getClientInstance} from '@core/graphql/client';

import {removeVideoFromCache} from './index';
import DELETE_VIDEO_MUTATION from '../graphql/mutations/deleteVideo.gql';
import type {
  DeleteVideoMutation,
  DeleteVideoMutationVariables,
} from '../graphql/mutations/deleteVideo';

const deleteVideo = (currentMediaId: string) => {
  getClientInstance().mutate<DeleteVideoMutation, DeleteVideoMutationVariables>(
    {
      mutation: DELETE_VIDEO_MUTATION,
      variables: {id: currentMediaId},
      update: (cache, response) => {
        try {
          const {result} = response.data.user.myUser.video.deleteVideo;
          if (!result) return;
          removeVideoFromCache({id: currentMediaId});
        } catch (e) {}
      },
      optimisticResponse: () => {
        return {
          user: {
            myUser: {
              video: {
                deleteVideo: {
                  result: true,
                  __typename: 'BooleanMutationResult',
                },
                __typename: 'MyUserVideoType',
              },
              __typename: 'MyUserMutation',
            },
            __typename: 'UserMutation',
          },
        };
      },
    },
  );
};

export default deleteVideo;
