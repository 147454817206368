import {BREAKPOINT} from './constants';

/**
 * The visual meaning of the platform is determined by the breakpoint.
 * If the functionality does not differ for platforms, the separation for platforms is not critical,
 * for example, a different appearance, then you can simply catch on the width of the screen
 */
const isSmallTabletViewport = (): boolean =>
  BREAKPOINT.SMALL_TABLET >= window.innerWidth;

export default isSmallTabletViewport;
