import type {Observable} from 'rxjs';
import {from} from 'rxjs';
import {map} from 'rxjs/operators';

import getVideoMuteSubscription from '@core/user/profile/current/utils/getVideoMuteSubscription';

import NOTIFICATION_TYPES from '../constants/notificationTypes';
import type {CommonNotification} from '../types';

/**
 * Returns an observable that emits normalized results based on the video mute subscription.
 */
const videoMuteDataSource = (): Observable<CommonNotification> =>
  // 'from' is used to convert zen-observable into RxJS compatible observable
  from(getVideoMuteSubscription()).pipe(
    map(() => ({
      type: NOTIFICATION_TYPES.VIDEO_MUTE,
    })),
  );

export default videoMuteDataSource;
