import {getClientInstance} from '@core/graphql/client';

import type {IsPreloadPaymentPageQuery} from '../graphql/queries/isPreloadPaymentPage';
import IS_PRELOAD_PAYMENT_PAGE_QUERY from '../graphql/queries/isPreloadPaymentPage.gql';

const isPreloadedPaymentData = (): boolean => {
  const data = getClientInstance().readQuery<IsPreloadPaymentPageQuery>({
    query: IS_PRELOAD_PAYMENT_PAGE_QUERY,
  });

  return data?.userFeatures?.isPreloadPaymentPage ?? false;
};

export default isPreloadedPaymentData;
