import isUndefined from 'lodash/isUndefined';

import type {URIFromWindowObject} from '../types/uri';
import {FALLBACK_URL} from '../constants/uri';

// Used for parse url from window object
const getUriStringFromWindowObject = (
  uri: URIFromWindowObject = {},
): string => {
  return uri?.location?.href || uri?.href || '';
};

const getUri = (uri: string | undefined | object): string => {
  if (isUndefined(uri)) {
    return typeof window !== 'undefined'
      ? window?.location?.href || FALLBACK_URL
      : FALLBACK_URL;
  }

  if (typeof uri === 'string') {
    return uri;
  }

  if (typeof uri === 'object') {
    return getUriStringFromWindowObject(uri);
  }

  return '';
};

export default getUri;
