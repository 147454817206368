import {getClientInstance} from '@core/graphql/client';
import type {MessageVideo} from '@core/types';

import type {MessageVideoFragment} from '../graphql/fragments/messageVideo';
import MESSAGE_VIDEO_FRAGMENT from '../graphql/fragments/messageVideo.gql';

/**
 * Updates apollo cache data of MessageVideo
 */
export default function updateVideoCache({id, ...data}: MessageVideo): void {
  const client = getClientInstance();
  const fragment = {
    fragment: MESSAGE_VIDEO_FRAGMENT,
    id: `MessageVideo:${id}`,
  };
  const video = client.readFragment<MessageVideoFragment>(fragment);

  // skip update when no video in cache
  if (video) {
    client.writeFragment<MessageVideoFragment>({
      ...fragment,
      data: {...video, ...data},
    });
  }
}
