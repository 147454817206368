import paymentDescriptorDataVar from '@core/graphql/vars/paymentDescriptorDataVar';

/**
 * Get current descriptor text
 * @see usePaymentDescriptor.js
 * @return {Object}
 */
const getPaymentDescriptorTrackData = () => {
  const descriptorData = paymentDescriptorDataVar();

  return {
    hideDescriptor: descriptorData?.hideDescriptor || false,
    descriptor: descriptorData?.descriptor || null,
    companyName: descriptorData?.companyName || null,
  };
};

export default getPaymentDescriptorTrackData;
