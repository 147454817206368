/**
 * Don't batch:
 *  - heavy requests to optimize small requests processing,
 *  - request that blocks main requests(for rendering the main content)
 */
const DONT_BATCH_OPERATION_NAMES = [
  // Separated SearchListQuery to load search ~ 2 seconds faster
  'SearchListQuery',
  // Removing this query from here you could potentially slow down several product routes @see usePreloadBasePaymentData.js
  'PaymentBaseDataQuery',
  'TargetUserViewMutation',
  'SimilarUsersQuery',
  /**
   * Needed here to separate the gallery query (shows users from the gallery in the list of recipients) which
   * is quite heavy and can get into the pack with messenger queries, since on the rice theme getting into
   * the pack with messenger requests can slow down the preloading of messenger data, and make
   * alerts like: "[...] The data not preloaded, it can increase requests count ..."
   */
  'LikeGalleryListQuery',
];

export default DONT_BATCH_OPERATION_NAMES;
