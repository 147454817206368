/* eslint-disable consistent-return, eqeqeq */

import {MSG} from '../constants/socketEventNames';

export default {
  DisapprovePhotoSubscription: {
    incomingEvents: `${MSG}disapprovePhoto`,

    fromServer: (response) => ({
      /**
       * It is a magic, but disapproved photo interaction came even photo was approved.
       * We must look at parameter 'disapproved'.
       */
      data: {
        disapprove: {
          id: response.photoId,
          // Just convert 'declined' and 'approved' word to boolean.
          photo: response.disapproved == 1,
          __typename: 'PhotoType',
        },
      },
    }),
  },
};
