import t from '@core/translations/translate';

import TYPES from '../constants/types';
import getTypeContents from './getTypeContents';

/**
 * Formats data for DisapproveBadgeContent.
 * @param data {object} myUser.profile.disapprove from GraphQL
 * @param isProfileVideoUploadAllow {boolean}
 * @return {*} | null when none of supported types were disapproved
 */
const formatDisapproveData = ({data, isProfileVideoUploadAllow, ...props}) => {
  const disapprovedTypes = Object.values(TYPES).filter((type) => data[type]);
  const count = disapprovedTypes.length;
  if (!count) {
    return null;
  }

  const oneType = count === 1 ? disapprovedTypes[0] : null;

  const {href, onClick, buttonText} = oneType
    ? getTypeContents({type: oneType, isProfileVideoUploadAllow, ...props})
    : {
        buttonText: t('activityNotification', 'button.makeChanges'),
        href: '/profile',
      };

  const typesList = disapprovedTypes.map((type) => {
    const {icon, text} = getTypeContents({type});
    return {type, icon, text};
  });

  return {
    disapprovedTypes: typesList,
    href,
    buttonText,
    onClick,
    isProfileVideoUploadAllow,
  };
};

export default formatDisapproveData;
