/**
 *  Need for Google test logic
 */
export const TEST_CARD = '5111 **** **** 1118';

/**
 * Cookie value for using the test flow for Google Pay with cvv
 * @type {number}
 */
export const GOOGLE_PAY_WITH_CVV = 'cvv';
