const MAX_SHOW_COUNT = 99;

/**
 * Convert number in readable user format
 * @param {number} count
 * @param {number} maxCount
 * @returns {string}
 */
const getFormattedCounter = (count, maxCount = MAX_SHOW_COUNT) =>
  count <= maxCount ? `${count > 0 ? count : ''}` : `${maxCount}+`;

export default getFormattedCounter;
