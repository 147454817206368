import type {InMemoryCacheConfig} from '@apollo/client/cache/inmemory/types';
import type {FieldMergeFunction} from '@apollo/client';
import {InMemoryCache} from '@apollo/client';

import typePolicies from '../typePolicies';
import getTypeNames from './getTypeNames';

const mergeFields: FieldMergeFunction = (existing, incoming, {mergeObjects}) =>
  mergeObjects(existing, incoming);

export default function createCache(
  config: InMemoryCacheConfig = {},
): InMemoryCache {
  const cache = new InMemoryCache({
    typePolicies,
    possibleTypes: {
      AdditionalPackageList: [
        'AdditionalPackageXSale',
        'AdditionalPackageCredit',
        'AdditionalPackageRemarketing',
        'AdditionalPackageMessage',
        'AdditionalPackageOther',
        'AdditionalPackageITunes',
      ],
      AltMethodsSettings: [
        'NovalNetSepaSettings',
        'VendoSepaSettings',
        'PaygardenSettings',
        'KonbiniSettings',
        'ApplePaySettings',
        'GooglePaySettings',
        'PaygardenSettings',
        'TrustPaySepaSettings',
        'CommDooSepaSettings',
        'TrustPayIdealSettings',
      ],
      SDAuthor: ['HostUser', 'UserDataOpen'],
    },
    ...config,
  });

  // @ts-expect-error I know this is a private method (see comment below).
  const {getTypePolicy} = cache.policies;

  /**
   * This hack with modifying private method
   * is used to allow fields merging by default for all types.
   */
  // @ts-expect-error It may fail after updating Apollo Client, but works for now.
  cache.policies.getTypePolicy = function getCustomTypePolicy(typename) {
    const policy = getTypePolicy.call(this, typename);
    if (!policy.merge) {
      policy.merge = mergeFields;
    }
    return policy;
  };

  /**
   * If you're reading this probably you're trying to refetch a query,
   * so you have to check either it causes overwriting of cached fields
   * and as a result - refetching of related query(s)
   * or fields are merging correctly, without an "extra" query(s)!
   *
   * This happens when fields (which is refetching) doesn't have an ID.
   * To fix it define "keyFields: []" for needed type in typePolicies.
   * https://www.apollographql.com/docs/react/caching/cache-configuration/#dataidfromobject
   */
  const {write} = cache;
  cache.write = (options) => {
    if (options.overwrite && options.dataId === 'ROOT_QUERY') {
      const typeNames = getTypeNames(options.result).filter((typeName) => {
        const policy = getTypePolicy.call(cache.policies, typeName);

        return !policy.keyFn;
      });

      if (typeNames.length) {
        console.warn(
          `[${typeNames.join(', ')}] Dangerous cache update`,
          options,
        );
      }
    }

    return write.call(cache, options);
  };

  return cache;
}
