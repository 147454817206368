import type {FC} from 'react';
import React from 'react';
import {Redirect} from 'react-router-dom';
import type {Location} from 'history';

import {parsePathname} from '@core/utils/url';

import type {StaticPageChunkProps} from '../types';
import {encodeLink, isHashed} from '../utils/hashedStaticLinks';

export type StaticPageToHashProps = {
  StaticPageChunk: FC<StaticPageChunkProps>;
  location: Location;
};

/**
 * Opens hashed route if link is isHashed
 * or opens static page without hash if it is not needed.
 */
const StaticPageToHash: FC<StaticPageToHashProps> = ({
  StaticPageChunk,
  location,
}) => {
  const url = `${location.pathname}${location.search}`;

  if (isHashed(url) && !window.IS_INTEGRATION_TEST_ENVIRONMENT) {
    return <Redirect to={encodeLink(url)} />;
  }

  const pathname = parsePathname(url);
  return <StaticPageChunk page={pathname.action} />;
};

export default StaticPageToHash;
