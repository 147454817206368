enum PaymentPackageHeaderAppearance {
  /**
   * Default package header
   */
  REGULAR = 'regular',

  /**
   * With rounded package price
   */
  WITH_ROUNDED_PRICE = 'withRoundedPrice',

  /**
   * With rounded package and muted price
   */
  WITH_ROUNDED_AND_MUTED_PRICE = 'withRoundedAndMutedPrice',

  /**
   * Just a highlighted widget, nothing more.
   */
  HIGHLIGHTED = 'highlighted',
}

export default PaymentPackageHeaderAppearance;
