import {writeToLocalStorage} from '@core/utils/storage';

import {MINI_MESSENGER_VISIBLE_RECIPIENT_KEY} from '../constants/storageKeys';
import isMiniMessengerAllowed from './isMiniMessengerAllowed';
import isMiniMessengerOpened from './isMiniMessengerOpened';

/**
 * Update visible recipient in mini messenger
 * @param {string|null} userId
 * @param {boolean} forceUpdate
 * @returns {Promise<void>}
 */
const updateMiniMessengerVisibleRecipient = async (
  userId,
  forceUpdate = false,
) => {
  const miniMessengerAllowed = await isMiniMessengerAllowed();
  const needUpdate =
    miniMessengerAllowed && (forceUpdate || isMiniMessengerOpened());

  if (needUpdate) {
    writeToLocalStorage(MINI_MESSENGER_VISIBLE_RECIPIENT_KEY, userId);
  }
};

export default updateMiniMessengerVisibleRecipient;
