import React, {type ComponentType} from 'react';

import PopupService from '@core/popup/utils/PopupService';
import paymentPendingVar from '@core/graphql/vars/paymentPendingVar';

const openPendingPopup = (PaymentPendingPopupLayout: ComponentType): void => {
  paymentPendingVar(true);

  PopupService.openPopup(<PaymentPendingPopupLayout />, {
    trackingName: 'pendingPage',
    autoLoadTracking: true,
    showCloseButton: false,
  });
};

export default openPendingPopup;
