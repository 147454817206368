import {MSG} from '../constants/socketEventNames';

export default {
  InteractionBlockUserSubscription: {
    incomingEvents: [`${MSG}blockUser`],

    fromServer: ({userId}) => ({data: {userId}}),
  },

  InteractionUnBlockUserSubscription: {
    incomingEvents: [`${MSG}unBlockUser`],

    fromServer: ({userId}) => ({data: {userId}}),
  },
};
