/**
 * Export for unit test
 */
export const PAY_SUCCESS_URL = '/pay/result/success';

const PAY_VERIFICATION_SUCCESS_URL = '/pay/verificationSuccess';

/**
 * @param {string} url
 * @returns {Boolean}
 */
const isPaySuccessUrl = (url) =>
  Boolean(url) &&
  (url.startsWith(PAY_SUCCESS_URL) ||
    url.startsWith(PAY_VERIFICATION_SUCCESS_URL));

export default isPaySuccessUrl;
