import type {PaymentProcessStep} from '../../types/paymentProcess';
import PAY_BUTTON_TRACKING from '../../../widgets/buttons/constants/payButtonTracking';
import {trackPayClickInner} from './trackPayClick';

const trackApplePayClick: PaymentProcessStep = async (data) => {
  const {paymentData} = data;

  trackPayClickInner(paymentData, PAY_BUTTON_TRACKING.APPLE_PAY);

  return data;
};

export default trackApplePayClick;
