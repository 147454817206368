import map from 'lodash/map';

import dayjs from '@core/date/utils/dayjs';
import MessageType from '@core/messenger/common/constants/messageType';
import getCurrentUserId from '@core/user/profile/current/utils/getCurrentUserId';
import {Direction} from '@core/types/graphql';

import {ROOMS, MSG} from '../constants/socketEventNames';

const ROOM_MESSAGE_METHOD = 'roomMessage';
const ROOM_JOIN_METHOD = 'roomJoin';
const ROOM_LEAVE_METHOD = 'roomLeave';
const ROOM_HISTORY_METHOD = 'roomHistory';
const INCOMING_MESSAGE_ROOM_EVENT_NAME = 'message';
const INCOMING_JOIN_USER_ROOM_EVENT_NAME = 'join';
const INCOMING_LEAVE_USER_ROOM_EVENT_NAME = 'leave';
const INCOMING_DELETE_ROOM_MESSAGE_EVENT_NAME = 'roomMessageDelete';
const INCOMING_MARK_AS_SCAMMER_ROOMS_EVENT_NAME = 'markAsScammerRooms';
const ROOM_UNBANNED_EVENT_NAME = `${MSG}roomsUnbanned`;
const ROOM_BANNED_EVENT_NAME = `${MSG}roomsBanned`;

const TYPE_NAMES = {
  USER: 'UserData',
  ROOM_MESSAGE: 'ChatRoomMessage',
  CHATROOMS: 'Chatrooms',
};

const prepareError = (response) => {
  const {
    data: {condition},
  } = response;
  if (condition === 'banned') {
    const startBan = dayjs(response.data.startBan);
    const endBan = dayjs(response.data.endBan);
    const period = endBan.diff(startBan, 'seconds');
    return {
      condition,
      period,
    };
  }
  return response.data;
};

/**
 * Send to server request to join to chat room
 */
export default {
  RoomSendMessageMutation: {
    toServer({roomId, message, copyPasteDetected}) {
      return {
        method: ROOM_MESSAGE_METHOD,
        params: {
          rid: roomId,
          message: {
            body: message,
            copyPasteDetected,
          },
        },
      };
    },

    fromServer(response, error) {
      const isSuccess = !error && response.status === 'success';

      return {
        data: {
          sendRoomMessage: {
            result: isSuccess ? Boolean(response.data) : false,
            errors: {
              general: isSuccess ? null : error || response.data,
              arguments: null,
              __typename: 'MutationPayloadErrors',
            },
            __typename: 'ChatroomsSendMessage',
          },
        },
      };
    },
  },

  JoinRoomMutation: {
    toServer({id}) {
      return {method: ROOM_JOIN_METHOD, params: {rid: id}};
    },

    fromServer(response) {
      const isSuccess = response.status === 'success';

      return {
        data: {
          chatrooms: {
            join: {
              ids: isSuccess ? response.data : [],
              errors: {
                general: isSuccess ? null : prepareError(response),
                arguments: null,
                __typename: 'MutationPayloadErrors',
              },
              __typename: 'ChatroomsJoin',
            },
            __typename: TYPE_NAMES.CHATROOMS,
          },
        },
      };
    },
  },

  RoomMessagesHistoryQuery: {
    toServer({id}) {
      return {method: ROOM_HISTORY_METHOD, params: {rid: id}};
    },

    async fromServer(response) {
      const currentUserId = await getCurrentUserId();
      const isSuccess = response.status === 'success';

      return {
        data: {
          chatrooms: {
            history: map(isSuccess ? response.data : [], (item) => ({
              id: item.data.id,
              text: item.data.body,
              timestamp: item.data.time,
              isDeleted: item.data.delete,
              senderId: item.uid,
              direction:
                currentUserId === item.uid
                  ? Direction.outgoing
                  : Direction.incoming,
              type: MessageType.CHAT,
              __typename: TYPE_NAMES.ROOM_MESSAGE,
            })),
            __typename: TYPE_NAMES.CHATROOMS,
          },
        },
      };
    },
  },

  LeaveRoomMutation: {
    toServer({id}) {
      return {method: ROOM_LEAVE_METHOD, params: {rid: id}};
    },

    fromServer(response) {
      const isSuccess = response.status === 'success';

      return {
        data: {
          chatrooms: {
            leave: {
              result: isSuccess,
              errors: {
                general: isSuccess ? null : response.data,
                arguments: null,
                __typename: 'MutationPayloadErrors',
              },
              __typename: 'ChatroomsLeave',
            },
            __typename: TYPE_NAMES.CHATROOMS,
          },
        },
      };
    },
  },

  InteractionRoomsIncomingLeave: {
    incomingEvents: ROOMS,
    fromServer({event, rid, uid} = {}) {
      if (event !== INCOMING_LEAVE_USER_ROOM_EVENT_NAME) return null;
      return {
        data: {
          roomLeave: {
            roomId: rid,
            user: {
              id: uid,
              __typename: TYPE_NAMES.USER,
            },
            __typename: 'IncomingChatroomsLeave',
          },
        },
      };
    },
  },

  InteractionRoomsIncomingMessage: {
    incomingEvents: ROOMS,
    async fromServer({event, rid, uid, data} = {}) {
      if (event !== INCOMING_MESSAGE_ROOM_EVENT_NAME) return null;
      const currentUserId = await getCurrentUserId();
      const {body, id: messageId, messageTime} = data;
      return {
        data: {
          roomMessage: {
            roomId: rid,
            message: {
              id: messageId,
              text: body,
              timestamp: messageTime,
              senderId: uid,
              direction:
                currentUserId === uid ? Direction.outgoing : Direction.incoming,
              type: MessageType.CHAT,
              isDeleted: false,
              __typename: TYPE_NAMES.ROOM_MESSAGE,
            },
            __typename: 'InteractionChatroomsMessage',
          },
        },
      };
    },
  },

  InteractionRoomsIncomingJoin: {
    incomingEvents: ROOMS,
    fromServer({event, rid, uid} = {}) {
      if (event !== INCOMING_JOIN_USER_ROOM_EVENT_NAME) return null;
      return {
        data: {
          roomJoin: {
            roomId: rid,
            user: {
              id: uid,
              __typename: TYPE_NAMES.USER,
            },
            __typename: 'IncomingChatroomsJoin',
          },
        },
      };
    },
  },

  InteractionRoomUnbanned: {
    incomingEvents: ROOM_UNBANNED_EVENT_NAME,
    fromServer: () => ({data: {roomUnbanned: true}}),
  },

  InteractionRoomBanned: {
    incomingEvents: ROOM_BANNED_EVENT_NAME,
    fromServer: ({period} = {}) => ({
      data: {
        roomBanned: {
          period,
          __typename: 'IncomingChatroomsBanned',
        },
      },
    }),
  },

  InteractionRoomDeleteMessage: {
    incomingEvents: ROOMS,
    fromServer({event, data} = {}) {
      if (event !== INCOMING_DELETE_ROOM_MESSAGE_EVENT_NAME) return null;
      return {
        data: {
          deletedRoomMessage: {
            id: data.messageId,
            __typename: TYPE_NAMES.ROOM_MESSAGE,
          },
        },
      };
    },
  },

  InteractionMarkAsScammerRoomsSubscription: {
    incomingEvents: ROOMS,
    fromServer({event, data} = {}) {
      if (event !== INCOMING_MARK_AS_SCAMMER_ROOMS_EVENT_NAME) return null;
      return {
        data: {
          markAsScammerRooms: {
            userId: data.userId,
            __typename: 'MarkAsScammerRooms',
          },
        },
      };
    },
  },
};
