import {useContext} from 'react';

import type {ResponsiveBreakpoints} from './ResponsiveContext';
import ResponsiveContext from './ResponsiveContext';

const useSmallPhoneBreakpoint = (): boolean => {
  const responsiveContext =
    useContext<ResponsiveBreakpoints>(ResponsiveContext);

  return responsiveContext.SMALL_PHONE;
};

export default useSmallPhoneBreakpoint;
