enum Size {
  /**
   * Items will have fixed width and height (used on WEB environment)
   */
  FIXED = 'fixed',

  /**
   * Apply not only fixed width, but height too. Useful for motivation widgets that should not
   * grow in terms of height.
   * @see getWidgetSize ("getMotivationWidgetSize" method)
   */
  FIXED_FULL = 'fixedFull',

  /**
   * Items will have fluid size, calculated in percent
   */
  FLUID = 'fluid',

  /**
   * Special fluid size, where widgets are 2 times smaller but remain with fluid width.
   * Is the same as fluid, but for 'free' users, where we should decrease size of user profiles by 2
   * for motivate them to pay.
   *
   * @todo Must be universalized or proven that is really indispensable.
   */
  FLUID_HALF = 'fluidHalf',

  /**
   * Used for special widgets that must be full-width such as:
   * RM banners, motivation banners, etc.
   */
  FULL_WIDTH = 'fullWidth',
}

export default Size;
