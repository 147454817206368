/*
 * This is a hard fork of the autosize library.
 * https://www.npmjs.com/package/autosize
 *
 * We were forced to fork the library because of the following issue:
 * - Library does not support non browser environments by design
 * - Library does not support SSR by design
 * - We don`t place it on GitHub anu more because it deletes nonactive repositories
 *
 * Autosize is a small, stand-alone script to automatically adjust textarea height to fit text.
 *
 * Install via NPM
 * npm install autosize
 * Usage
 * The autosize function accepts a single textarea element, or an array or array-like object (such as a NodeList or jQuery collection) of textarea elements.
 * from a NodeList
 * autosize(document.querySelectorAll('textarea'));
 *
 *  from a single Node
 *
 * autosize(document.querySelector('textarea'));
 *
 * from a jQuery collection
 * autosize($('textarea'));
 * */

const map = new Map();

const createEvent = (name) => new Event(name, {bubbles: true});

function assign(ta: HTMLTextAreaElement) {
  if (!ta || !ta.nodeName || ta.nodeName !== 'TEXTAREA' || map.has(ta)) return;

  let heightOffset = null;
  let clientWidth = null;
  let cachedHeight = null;

  function changeOverflow(value) {
    {
      // Chrome/Safari-specific fix:
      // When the textarea y-overflow is hidden, Chrome/Safari do not reflow the text to account for the space
      // made available by removing the scrollbar. The following forces the necessary text reflow.
      const {width} = ta.style;
      ta.style.width = '0px';
      // Force reflow:
      /* eslint-disable-next-line no-unused-expressions */
      ta.offsetWidth;
      ta.style.width = width;
    }

    ta.style.overflowY = value;
  }

  function getParentOverflows(el: Element) {
    const arr = [];
    // Fix of issue #343 of jackmoore/autosize. It is needed to check if the current element is not the 'body' to avoid the bug on iOS with jumping scroll to the very top and keypad overlapping
    while (
      el &&
      el.parentNode &&
      el.parentNode instanceof Element &&
      el.parentNode !== document.body
    ) {
      if (el.parentNode.scrollTop) {
        arr.push({
          node: el.parentNode,
          scrollTop: el.parentNode.scrollTop,
        });
      }
      // eslint-disable-next-line no-param-reassign
      el = el.parentNode;
    }

    return arr;
  }

  function resize() {
    if (ta.scrollHeight === 0) {
      // If the scrollHeight is 0, then the element probably has display:none or is detached from the DOM.
      return;
    }

    const overflows = getParentOverflows(ta);
    const docTop =
      document.documentElement && document.documentElement.scrollTop; // Needed for Mobile IE (ticket #240)

    ta.style.height = '';
    ta.style.height = `${ta.scrollHeight + heightOffset}px`;

    // used to check if an update is actually necessary on window.resize
    clientWidth = ta.clientWidth;

    // prevents scroll-position jumping
    overflows.forEach((el) => {
      el.node.scrollTop = el.scrollTop;
    });

    if (docTop) {
      document.documentElement.scrollTop = docTop;
    }
  }

  function update() {
    resize();

    const styleHeight = Math.round(parseFloat(ta.style.height));
    const computed = window.getComputedStyle(ta, null);

    // Using offsetHeight as a replacement for computed.height in IE, because IE does not account use of border-box
    let actualHeight =
      computed.boxSizing === 'content-box'
        ? Math.round(parseFloat(computed.height))
        : ta.offsetHeight;

    // The actual height not matching the style height (set via the resize method) indicates that
    // the max-height has been exceeded, in which case the overflow should be allowed.
    if (actualHeight < styleHeight) {
      if (computed.overflowY === 'hidden') {
        changeOverflow('scroll');
        resize();
        actualHeight =
          computed.boxSizing === 'content-box'
            ? Math.round(parseFloat(window.getComputedStyle(ta, null).height))
            : ta.offsetHeight;
      }
    } else if (computed.overflowY !== 'hidden') {
      // Normally keep overflow set to hidden, to avoid flash of scrollbar as the textarea expands.
      changeOverflow('hidden');
      resize();
      actualHeight =
        computed.boxSizing === 'content-box'
          ? Math.round(parseFloat(window.getComputedStyle(ta, null).height))
          : ta.offsetHeight;
    }

    if (cachedHeight !== actualHeight) {
      cachedHeight = actualHeight;
      const evt = createEvent('autosize:resized');
      try {
        ta.dispatchEvent(evt);
      } catch (err) {
        // Firefox will throw an error on dispatchEvent for a detached element
        // https://bugzilla.mozilla.org/show_bug.cgi?id=889376
      }
    }
  }

  function init() {
    const style = window.getComputedStyle(ta, null);

    if (style.resize === 'vertical') {
      ta.style.resize = 'none';
    } else if (style.resize === 'both') {
      ta.style.resize = 'horizontal';
    }

    if (style.boxSizing === 'content-box') {
      heightOffset = -(
        parseFloat(style.paddingTop) + parseFloat(style.paddingBottom)
      );
    } else {
      heightOffset =
        parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);
    }
    // Fix when a textarea is not on document body and heightOffset is Not a Number
    if (Number.isNaN(heightOffset)) {
      heightOffset = 0;
    }

    update();
  }

  const pageResize = () => {
    if (ta.clientWidth !== clientWidth) {
      update();
    }
  };

  const destroy = ((style: CSSStyleDeclaration) => {
    window.removeEventListener('resize', pageResize, false);
    ta.removeEventListener('input', update, false);
    ta.removeEventListener('keyup', update, false);
    ta.removeEventListener('autosize:destroy', destroy, false);
    ta.removeEventListener('autosize:update', update, false);

    Object.keys(style).forEach((key) => {
      ta.style[key] = style[key];
    });

    map.delete(ta);
  }).bind(ta, {
    height: ta.style.height,
    resize: ta.style.resize,
    overflowY: ta.style.overflowY,
    overflowX: ta.style.overflowX,
    wordWrap: ta.style.wordWrap,
  });

  ta.addEventListener('autosize:destroy', destroy, false);

  // IE9 does not fire onpropertychange or oninput for deletions,
  // so binding to onkeyup to catch most of those events.
  // There is no way that I know of to detect something like 'cut' in IE9.
  if ('onpropertychange' in ta && 'oninput' in ta) {
    ta.addEventListener('keyup', update, false);
  }

  window.addEventListener('resize', pageResize, false);
  ta.addEventListener('input', update, false);
  ta.addEventListener('autosize:update', update, false);
  ta.style.overflowX = 'hidden';
  ta.style.wordWrap = 'break-word';

  map.set(ta, {
    destroy,
    update,
  });

  init();
}

function destroyTextArea(ta: HTMLTextAreaElement) {
  const methods = map.get(ta);
  if (methods) {
    methods.destroy();
  }
}

function updateTextArea(ta: HTMLTextAreaElement) {
  const methods = map.get(ta);
  if (methods) {
    methods.update();
  }
}

// eslint-disable-next-line import/no-mutable-exports
let autosize = null;

type MethodOptions = HTMLTextAreaElement | HTMLTextAreaElement[];

// Do nothing in Node.js environment and IE8 (or lower)
if (
  typeof window === 'undefined' ||
  typeof window.getComputedStyle !== 'function'
) {
  autosize = (el) => el;
  autosize.destroy = (el) => el;
  autosize.update = (el) => el;
} else {
  autosize = (el: MethodOptions) => {
    if (el) {
      Array.prototype.forEach.call(Array.isArray(el) ? el : [el], (x) =>
        assign(x),
      );
    }
    return el;
  };
  autosize.destroy = (el: MethodOptions) => {
    if (el) {
      Array.prototype.forEach.call(
        Array.isArray(el) ? el : [el],
        destroyTextArea,
      );
    }
    return el;
  };
  autosize.update = (el: MethodOptions) => {
    if (el) {
      Array.prototype.forEach.call(
        Array.isArray(el) ? el : [el],
        updateTextArea,
      );
    }
    return el;
  };
}

export default autosize;
