import t from '@core/translations/translate';

import parseHostname from '../../../../../../../util/parseHostnameBase';

/**
 * Get payment descriptor text
 * @param {Object} props
 * @param {string} props.descriptor
 * @param {string} props.companyName
 * @return {string}
 */
const getPaymentDescriptorText = ({descriptor, companyName} = {}) => {
  if (!descriptor) {
    return t('paymentPage', 'text.card_statement_descriptor', {
      '{descriptor}': parseHostname(window.location.hostname).sitename,
    });
  }

  return companyName
    ? t('paymentPage', 'text.card_statement_descriptor_billedBy', {
        '{descriptor}': descriptor,
        '{companyName}': companyName,
      })
    : t('paymentPage', 'text.card_statement_descriptor', {
        '{descriptor}': descriptor,
      });
};

export default getPaymentDescriptorText;
