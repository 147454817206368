import lcfirst from './lcfirst';

/**
 * Convert string to Kebab Case from Camel Case
 * @example
 *     'testSimpleString'.toKebabCase(); // returns: test-simple-string
 *
 * @returns {string} string in Kebab Case format
 */
const toKebabCase = (str) =>
  lcfirst(str).replace(/([A-Z])/g, ($1) => `-${$1.toLowerCase()}`);

export default toKebabCase;
