import {getCookie} from '@core/utils/cookie';

import type DeclineMessageType from '../constants/paymentDeclineMessageType';
import {DECLINE_SCREEN_TEMPLATE_COOKIE} from '../constants/paymentDeclineMessageType';

/**
 * Simple cookie checker for comfortable testing
 */
const getDeclineScreenTemplate = (
  declineScreenTemplate: DeclineMessageType,
): DeclineMessageType => {
  return (
    getCookie<DeclineMessageType>(DECLINE_SCREEN_TEMPLATE_COOKIE) ||
    declineScreenTemplate
  );
};

export default getDeclineScreenTemplate;
