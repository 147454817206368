enum PaymentBannerPlacement {
  /**
   * Notifications popup on MOB (opens up on click by "ear" with bell icon and counter).
   */
  NOTIF = 'notif',

  /**
   * Search list. Displayed on bottom of search list if user is "free".
   */
  SEARCH = 'search',

  /**
   * On top of search list. Displayed in case of no search results.
   */
  SEARCH_NO_MATCH_USERS = 'search_no_match_users',

  /**
   * Search list. Displayed on top of search list.
   */
  SEARCH_TOP = 'search_top',

  /**
   * Target user profile.
   */
  USER = 'user',

  /**
   * Target user profile, media tab.
   */
  USER_MEDIA = 'user_media',

  /**
   * Sidebar user menu of mobile.
   */
  MENU = 'menu',

  /**
   * Pedal popup.
   */
  PEDAL = 'pedal',
}

export default PaymentBannerPlacement;
