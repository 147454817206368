import type {ReactNode} from 'react';
import React from 'react';
import cn from 'classnames';

import FittingImage from './FittingImage';
import ImageScaling from '../../constants/ImageScaling';
import css from './ImageSubstrate.css';

export interface ImageSubstrateProps {
  imageUrl: string;
  icon?: ReactNode;
  highlighted?: boolean;
  className?: string;
}

/**
 * Renders image with substrate under it
 */
const ImageSubstrate = ({icon, imageUrl, highlighted, className}) => (
  <div className={cn(css.container, className)}>
    <div className={cn(css.imageWrap, highlighted && css.highlighted)}>
      <FittingImage url={imageUrl} scaling={ImageScaling.CONTAIN} />
    </div>
    {icon && <div className={css.icon}>{icon}</div>}
  </div>
);

export default ImageSubstrate;
