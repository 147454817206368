import type {ReactElement, ReactNode, MouseEventHandler} from 'react';
import React, {Children, Fragment, forwardRef} from 'react';
import cn from 'classnames';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import {Row} from '../row';
import SpacingSize from '../../constants/SpacingSize';
import type Align from '../../constants/Align';
import VerticalAlign from '../../constants/VerticalAlign';
import css from './Group.css';

export interface GroupProps {
  // TODO: Try to avoid using `Group` with single child, then remove `| ReactNode` here.
  children: ReactNode[] | ReactNode;
  className?: string;
  trackingName?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  itemWithFlexibleWidth?: boolean;
  withLastFlexibleItem?: boolean;
  itemWithEqualWidth?: boolean;
  /**
   * Sometimes it is necessary to set different styles to certain item wrapper
   * Just pass class names as an array in right order
   */
  itemWrapperClassName?: string | string[];
  horizontal?: boolean;
  reverse?: boolean;
  inline?: boolean;
  withBar?: boolean;
  fullHeight?: boolean;
  adaptive?: boolean;
  space?: SpacingSize;
  align?: Align;
  verticalAlign?: VerticalAlign; // Used only in horizontal groups
  inverse?: boolean;
  forceInline?: boolean;
  /**
   * There are cases when content of group is smaller than parent container, but we don't
   * need to allow all elements grow in equal way. With such property we can deny growing
   * for certain item in group (via index).
   * Should work only together with "itemWithFlexibleWidth" property
   */
  strictSizeItemIndex?: number;
  // Alias to "strictSizeItemIndex" for avoiding growing first item in group
  strictSizeLastItem?: boolean;
  // Alias to "strictSizeItemIndex" for avoiding growing last item in group
  strictSizeFirstItem?: boolean;
}

/**
 * Group to manage multiple widgets.
 * Sets vertical or horizontal align for them. And passes up events via callbacks
 */
const Group = forwardRef<HTMLDivElement, GroupProps>(
  (
    {
      children,
      className,
      trackingName,
      itemWrapperClassName,
      itemWithFlexibleWidth = true,
      withLastFlexibleItem,
      itemWithEqualWidth,
      horizontal = false,
      reverse,
      inline = false,
      forceInline = false,
      space = SpacingSize.NORMAL,
      withBar = false,
      adaptive = true,
      fullHeight = false,
      inverse = false,
      align,
      verticalAlign = VerticalAlign.CENTER,
      strictSizeItemIndex,
      strictSizeLastItem,
      strictSizeFirstItem,
      ...props
    },
    ref,
  ) => {
    // 0 to keep original logic when `children.length` was used instead of this value.
    const elementsCount = Array.isArray(children) ? children.length : 0;

    const content = Children.toArray(children)
      .filter(
        /**
         * There is a case when something inside group is witten like:
         * {condition && <Something />}
         * In this case we receive error inside Row component.
         * So if there is nothing to render - we return nothing
         */
        Boolean,
      )
      .map((child: ReactElement, index) => {
        const key = child.key || index;

        if (space === SpacingSize.NONE && index > 0 && withBar) {
          return (
            <Fragment key={key}>
              <div className={cn(css.bar, inverse && css.inverse)} />
              {child}
            </Fragment>
          );
        }

        const wrapperClassName = Array.isArray(itemWrapperClassName)
          ? itemWrapperClassName[index]
          : itemWrapperClassName;

        if (inline) {
          if (index > 0 && withBar) {
            return (
              <Fragment key={key}>
                <div className={cn(css.bar, inverse && css.inverse)} />
                <Row
                  adaptive={adaptive}
                  horizontal
                  reverse={reverse}
                  space={space}
                  align={align}
                  className={cn(css.inlineItem, wrapperClassName)}
                >
                  {child}
                </Row>
              </Fragment>
            );
          }

          return (
            <Row
              key={key}
              adaptive={adaptive}
              horizontal
              reverse={reverse}
              space={space}
              align={align}
              className={cn(css.inlineItem, wrapperClassName)}
            >
              {child}
            </Row>
          );
        }

        if (index > 0 && withBar) {
          return (
            <Fragment key={key}>
              <div className={cn(css.bar, inverse && css.inverse)} />
              <Row
                adaptive={adaptive}
                horizontal={horizontal}
                reverse={reverse}
                space={space}
                align={align}
                className={wrapperClassName}
              >
                {child}
              </Row>
            </Fragment>
          );
        }

        const strictSizeRow =
          index === strictSizeItemIndex ||
          (strictSizeFirstItem && index === 0) ||
          (strictSizeLastItem && index === elementsCount - 1);

        const isFlexibleWidth =
          index === elementsCount - 1 && withLastFlexibleItem
            ? true
            : itemWithFlexibleWidth;

        return (
          <Row
            key={key}
            horizontal={horizontal}
            reverse={reverse}
            space={space}
            align={align}
            adaptive={adaptive}
            className={wrapperClassName}
            flexibleWidth={strictSizeRow ? false : isFlexibleWidth}
            equalWidth={itemWithEqualWidth}
          >
            {child}
          </Row>
        );
      });

    const wrappedContent = (
      <div
        className={cn(
          inline || (horizontal ? css.horizontal : css.vertical),
          fullHeight && css.fullHeight,
          forceInline && css.forceInline,
          (horizontal || inline) && css[verticalAlign],
          reverse && css.reverse,
          className,
        )}
        ref={ref}
        {...props}
      >
        {content}
      </div>
    );

    return trackingName ? (
      <AddBabciaUBTracking trackingName={trackingName}>
        {wrappedContent}
      </AddBabciaUBTracking>
    ) : (
      wrappedContent
    );
  },
);

export default Group;
