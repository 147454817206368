/**
 * All possible position variables for tooltip and popover
 * @see Popper.tsx
 * @see Tooltip.tsx
 * @see Popover.tsx
 */
enum PopperPlacement {
  RIGHT = 'right',
  RIGHT_START = 'right-start',
  RIGHT_END = 'right-end',
  LEFT = 'left',
  LEFT_START = 'left-start',
  LEFT_END = 'left-end',
  TOP = 'top',
  TOP_START = 'top-start',
  TOP_END = 'top-end',
  BOTTOM = 'bottom',
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',
}

export default PopperPlacement;
