import type {ReactNode} from 'react';
import React, {forwardRef} from 'react';
import cn from 'classnames';

import type {CSSModule} from '../../types';
import SpacingSize from '../../constants/SpacingSize';
import baseCss from './Separator.css';

export interface SeparatorProps {
  children?: ReactNode;
  className?: string;
  bordered?: boolean;
  skipFirstTopBorder?: boolean;
  inverse?: boolean;
  withOverlay?: boolean;
  /**
   * @deprecated Use `data-test` instead.
   */
  dataTest?: string;
  'data-test'?: string;
  spacingSize?: SpacingSize;
}

const DEFAULT_CSS: CSSModule = {};

const Separator = forwardRef<
  HTMLDivElement,
  // `SeparatorProps` without `css` inside to make it more suitable for `@phoenix/ui`.
  SeparatorProps & {
    /**
     * Can be used without `css` {@see Steps}.
     */
    css?: CSSModule;
  }
>(
  (
    {
      css = DEFAULT_CSS,
      children,
      className,
      bordered,
      skipFirstTopBorder,
      withOverlay,
      inverse,
      dataTest: deprecatedDataTest,
      'data-test': dataTest = deprecatedDataTest,
      spacingSize = SpacingSize.NONE,
    },
    ref,
  ) => (
    <div
      ref={ref}
      className={cn(
        baseCss.separator,
        css.separator,
        inverse && baseCss.inverse,
        inverse && css.inverse,
        bordered && baseCss.bordered,
        bordered && css.bordered,
        spacingSize && css[spacingSize],
        /**
         * There are different cases when we should avoid displaying first border on separator.
         * E.g. when we have top toolbar with border. Otherwise there will be duplications of borders.
         */
        skipFirstTopBorder && baseCss.skipFirstTopBorder,
        skipFirstTopBorder && css.skipFirstTopBorder,
        withOverlay && baseCss.withOverlay,
        withOverlay && css.withOverlay,
        !children && css.empty,
        className,
      )}
      data-test={dataTest}
    >
      {children && (
        <div
          className={cn(baseCss.content, css.content)}
          data-test="separatorContent"
        >
          {children}
        </div>
      )}
    </div>
  ),
);

export default Separator;
