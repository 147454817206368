import {IDEAL_METHODS, SEPA_METHODS} from './paymentMethods';

/**
 * Allowed sepa processors
 */
const ALLOWED_SEPA_NAMES = [
  ...Object.values(IDEAL_METHODS),
  ...Object.values(SEPA_METHODS),
];

export default ALLOWED_SEPA_NAMES;
