import logger from '@core/logger';
import type {CookieOptions} from '@core/utils/cookie/setCookie';

import {getCookie, setCookie, deleteCookie} from '../cookie';

export interface CookieStorage {
  getItem: (name: string) => string | undefined;
  setItem: (name: string, value: string, options?: CookieOptions) => void;
  removeItem: (name: string) => void;
  clear: () => void;
}

export interface StorageWrapper extends CookieStorage {
  getItem: <T = string>(key: string, defaultValue?: T) => T | null;
  setItem: <T = string>(name: string, value: T) => void;
}

const cookieStorage: CookieStorage = {
  getItem: getCookie,
  setItem: setCookie,
  removeItem: deleteCookie,
  clear: () => {
    logger.sendWarning('clear method is not implemented for cookie storage');
  },
};

/**
 * Tries to use storage to check if it works.
 */
const isAvailable = (storage: Storage): boolean => {
  const key = '__storage_test_key';
  const value = new Date().toString();
  try {
    storage.setItem(key, value);
    const ok = storage.getItem(key) === value;
    storage.removeItem(key);
    return ok;
  } catch (e) {
    return false;
  }
};

/**
 * Returns corresponding storage or cookieStorage fallback if storage doesn't work
 */
const getAvailableStorage = (
  type: 'localStorage' | 'sessionStorage',
): CookieStorage => {
  if (SERVER_ENVIRONMENT) {
    let storage: Record<string, any> = {};
    return {
      getItem: (key) => storage[key] || null,
      setItem: (key, value) => {
        storage[key] = value;
      },
      removeItem: (key) => {
        delete storage[key];
      },
      clear: () => {
        storage = {};
      },
    };
  }

  const storage = typeof window !== 'undefined' && window[type];
  return storage && isAvailable(storage) ? storage : cookieStorage;
};

/**
 * Creates wrapper around storage with the same API, but logs exceptions instead of throwing them.
 */
const wrap = (storage: Storage | CookieStorage): StorageWrapper => ({
  setItem: (key, value = null) => {
    if (value === null) {
      storage.removeItem(key);
      return;
    }

    try {
      storage.setItem(key, JSON.stringify(value));
    } catch (e) {
      logger.sendWarning(`Storage setItem error: ${(e as Error).message}`);
    }
  },
  getItem: (key, defaultValue = null) => {
    let value: string | null;
    try {
      value = storage.getItem(key);
    } catch (e) {
      logger.sendWarning(`Storage getItem error: ${(e as Error).message}`);
      return defaultValue;
    }

    if (typeof value !== 'string') {
      return defaultValue;
    }

    try {
      return JSON.parse(value);
    } catch (e) {
      // fallback for non json-encoded values stored earlier.
      return value;
    }
  },
  removeItem: (key: string) => storage.removeItem(key),

  clear: () => storage.clear(),
});

export const localStorage = wrap(getAvailableStorage('localStorage'));
export const sessionStorage = wrap(getAvailableStorage('sessionStorage'));
