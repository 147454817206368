/**
 * Export for unit test
 */
export const PAY_MEMBERSHIP_URL = '/pay/membership';

/**
 * @param {string} url
 * @returns {boolean}
 */
const isPayMembershipUrl = (url) =>
  Boolean(url) && url.startsWith(PAY_MEMBERSHIP_URL);

export default isPayMembershipUrl;
