import type {Client} from '@core/graphql/types';

import CURRENT_USER_CHAT_UP_LINE_QUERY from '../../current/graphql/queries/currentUserChatUpLine.gql';
import type {CurrentUserChatUpLineQuery} from '../../current/graphql/queries/currentUserChatUpLine';

const clearChatUpLineInCache = (client: Client) => {
  const data = client.readQuery<CurrentUserChatUpLineQuery>({
    query: CURRENT_USER_CHAT_UP_LINE_QUERY,
  });

  if (data) {
    client.writeQuery<CurrentUserChatUpLineQuery>({
      query: CURRENT_USER_CHAT_UP_LINE_QUERY,
      data: {
        ...data,
        myUser: {
          ...data.myUser,
          profile: {
            ...data.myUser.profile,
            chatUpLine: '',
          },
        },
      },
    });
  }
};

export default clearChatUpLineInCache;
