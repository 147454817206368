/* eslint-disable no-undefined, no-useless-escape */

import isString from 'lodash/isString';

/**
 * @todo Remove eslint-disable and fix all linting errors
 */
const getCookie = <T = string>(name: string): T | undefined => {
  if (SERVER_ENVIRONMENT || !name || !isString(name)) {
    return undefined;
  }

  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
    ),
  );
  return matches ? (decodeURIComponent(matches[1]) as T) : undefined;
};

export default getCookie;
