import isNumber from 'lodash/isNumber';

import logger from '@core/logger';

import {readFromLocalStorage, writeToLocalStorage} from './useStorage';

/**
 * Check if key is expired
 */
export const checkExpired = (key: string): boolean => {
  const timestamp = readFromLocalStorage<number>(key);

  if (!timestamp) {
    return true;
  }

  const isExpired = new Date().getTime() > timestamp;

  isExpired && writeToLocalStorage(key, null);

  return isExpired;
};

/**
 * Set expiring storage key
 */
export const setExpiringItem = (key: string, interval: number): void => {
  if (!isNumber(interval)) {
    logger.sendError(`[expiredStorage] wrong interval provided - ${interval}`);
    return;
  }

  writeToLocalStorage(key, new Date().getTime() + interval);
};
