import isEqual from 'lodash/isEqual';
import type {FC, ReactNode} from 'react';
import React, {useEffect, useState} from 'react';

import MEDIA_QUERY, {BREAKPOINT} from './constants';
import ResponsiveContext from './ResponsiveContext';

let queries;

type ResponsiveProviderProps = {
  /**
   * Custom constant viewport width (for SSR only).
   */
  width?: number;
  children: ReactNode;
};

const getBreakpoints = (width) => {
  const breakpoints = {
    DESKTOP: true,
    TABLET: false,
    PHONE: false,
    SMALL_TABLET: false,
    SMALL_PHONE: false,
    COMPACT_PHONE: false,
  };

  if (width) {
    // Breakpoints for SSR
    Object.keys(MEDIA_QUERY).forEach((name) => {
      breakpoints[name] = width <= BREAKPOINT[name];
      if (breakpoints[name]) {
        breakpoints.DESKTOP = false;
      }
    });
    return {breakpoints};
  }

  if (!queries) {
    queries = [];
    Object.keys(MEDIA_QUERY).forEach((name) => {
      queries.push([name, window.matchMedia(MEDIA_QUERY[name])]);
    });
  }

  /**
   * We don't need to listen for all media queries to skip extra updates.
   * It's enough to listen at maximum 2:
   * - trueQuery - the last query having matches = true.
   *   If viewport width become greater than it's value - it will trigger update.
   * - falseQuery - the first query having matches = false.
   *   If viewport width become less than it's value - it will trigger update.
   */
  let trueQuery;
  let falseQuery;

  queries.forEach(([name, query]) => {
    breakpoints[name] = query.matches;

    if (breakpoints[name]) {
      trueQuery = query;

      // DESKTOP is true only when all others are false.
      breakpoints.DESKTOP = false;
    } else if (!falseQuery) {
      falseQuery = query;
    }
  });

  return {
    breakpoints,
    trueQuery,
    falseQuery,
  };
};

/**
 * Custom functions use MediaQueryList listeners,
 * because iOS <= 13 supports only deprecated API.
 * https://caniuse.com/mdn-api_mediaquerylist_change_event
 */
const addListener = (
  query: MediaQueryList,
  onChange: (this: MediaQueryList, ev: MediaQueryListEvent) => any,
) =>
  query.addEventListener
    ? query.addEventListener('change', onChange)
    : query.addListener(onChange);

const removeListener = (
  query: MediaQueryList,
  onChange: (this: MediaQueryList, ev: MediaQueryListEvent) => any,
) =>
  query.removeEventListener
    ? query.removeEventListener('change', onChange)
    : query.removeListener(onChange);

const ResponsiveProvider: FC<ResponsiveProviderProps> = ({width, children}) => {
  const {breakpoints, trueQuery, falseQuery} = getBreakpoints(width);

  const [, update] = useState({});

  useEffect(() => {
    const handleChange = () => update({});

    // Trigger update in case when breakpoints changed during render.
    if (!isEqual(breakpoints, getBreakpoints(width).breakpoints)) {
      handleChange();
      return;
    }

    const mediaQueries = [trueQuery, falseQuery].filter(Boolean);
    mediaQueries.forEach((query) => {
      addListener(query, handleChange);
    });

    // eslint-disable-next-line consistent-return
    return () => {
      mediaQueries.forEach((query) => {
        removeListener(query, handleChange);
      });
    };
  });

  return (
    <ResponsiveContext.Provider value={breakpoints}>
      {children}
    </ResponsiveContext.Provider>
  );
};

export default ResponsiveProvider;
