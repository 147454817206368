import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import ThreeDSecurePopup from '../components/ThreeDSecurePopup';

type openThreeDSecurePopupProps = {
  iframeUrl?: string;
  /**
   * TODO: use correct type for `sourceEventType` {@see PopupProvider}.
   */
  onCloseClick: (sourceEventType: string) => boolean;
};

const openThreeDSecurePopup = ({
  iframeUrl,
  onCloseClick,
}: openThreeDSecurePopupProps): void => {
  PopupService.openPopup(<ThreeDSecurePopup iframeUrl={iframeUrl} />, {
    trackingName: '3Ds',
    autoLoadTracking: true,
    onCloseClick,
  });
};

export default openThreeDSecurePopup;
