import {getClientInstance} from '@core/graphql/client';

import CURRENT_USER_ID_QUERY from '../graphql/queries/currentUserId.gql';
import type {CurrentUserIdQuery} from '../graphql/queries/currentUserId';

const getCurrentUserId = async () => {
  const {
    data: {
      myUser: {
        profile: {id},
      },
    },
  } = await getClientInstance().query<CurrentUserIdQuery>({
    query: CURRENT_USER_ID_QUERY,
  });
  return id;
};

export default getCurrentUserId;
