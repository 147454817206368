import {getClientInstance} from '@core/graphql/client';

import INN_APP_PHOTO_RESTRICTION_QUERY from '../graphql/queries/inAppPhotoRestriction.gql';

/**
 * Check if upload is disabled
 * @return {Promise<Boolean>}
 */
export default async () => {
  const {data} = await getClientInstance().query({
    query: INN_APP_PHOTO_RESTRICTION_QUERY,
    fetchPolicy: 'network-only',
  });
  return data.userFeatures.inAppParams.photoRestriction;
};
