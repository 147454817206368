import createDefaults from '../utils/createDefaults';
import {mergeArrayFieldUniq} from '../utils/mergeArrayField';

export default {
  FreeViews: {
    keyFields: [],
  },
  FreeViewsReward: {
    keyFields: ['type'],
  },
  Recipient: {
    fields: {
      messages: {
        keyArgs: false,
        merge(prevMessages, fetchedMessages, {args}) {
          return args?.offset === 0
            ? fetchedMessages
            : mergeArrayFieldUniq(fetchedMessages, prevMessages);
        },
      },

      /**
       * Is used to identify if it's possible to load a new batch of messages
       * null - default value, shows that history was not loaded before
       * true - loading history is possible
       * false - all messages were loaded already
       * @see src/packages/dating/messenger/messagesList/containers/MessagesList.js
       * @see src/packages/dating/messenger/common/graphql/queries/messages.gql
       */
      hasMoreHistory: (hasMoreHistory = null) => hasMoreHistory,
    },
  },
  MessengerData: {
    // make this type accessible for invalidation.
    keyFields: [],
    fields: createDefaults({
      globalFreeMessages: {__typename: 'GlobalFreeMessages'},
    }),
  },
  GlobalFreeMessages: {
    keyFields: [],
    fields: createDefaults({
      time: 0,
    }),
  },
  Messenger: {
    keyFields: [],
    fields: {
      recipient: (recipient, {args, toReference, canRead}) => {
        const ref = toReference({
          __typename: 'Recipient',
          id: args.recipientId,
        });
        return canRead(ref) ? ref : recipient;
      },
    },
  },
  LastMessage: {
    fields: createDefaults({
      /**
       * Is used to check if last message is sending or not for disabling sender form
       * @see src/packages/dating/messenger/senderForm/containers/SenderForm.js
       */
      isSending: false,
    }),
  },
  Message: {
    fields: createDefaults({
      /**
       * Is used to write 'sending' message to apollo cache
       * @see src/packages/dating/messenger/chat/common/utils/sendMessage.js
       * @see src/packages/dating/messenger/common/graphql/fragments/message.gql
       */
      isSending: false,
    }),
  },
  MessageVideo: {
    fields: createDefaults({
      error: null, // upload/conversion error

      /**
       * 100% because this resolver works for already uploaded and converted videos.
       * Other values for this field used only while uploading/converting:
       * {@see addSendingMessageToCache} and {@see VideoMessageProcessingPlaceholder.updateProgress}
       */
      percentage: 100,
    }),
  },
  MessagePhoto: {
    fields: createDefaults({
      error: null, // /photo/upload error
    }),
  },
};
