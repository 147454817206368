import type {PaymentProcessStep} from '../../types/paymentProcess';
import trackPaymentClick from '../../../common/utils/trackPaymentClick';
import getVariablesForPayTrack from '../../../common/utils/getVariablesForPayTrack';
import type PAY_BUTTON_TRACKING from '../../../widgets/buttons/constants/payButtonTracking';
import type {PaymentDataRequest} from '../../types/paymentFlow';

export const trackPayClickInner = async (
  paymentRequest: PaymentDataRequest,
  payButton?: PAY_BUTTON_TRACKING,
): Promise<PaymentDataRequest> => {
  trackPaymentClick(
    await getVariablesForPayTrack({...paymentRequest, payButton}),
  );

  return paymentRequest;
};

const trackPayClick: PaymentProcessStep = async (data) => {
  const {paymentData} = data;

  await trackPayClickInner(paymentData);

  return data;
};

export default trackPayClick;
