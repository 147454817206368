import MessageType, {
  IMAGE_TYPES,
  VIDEO_TYPES,
  VIDEO_CHAT_TYPES,
  MEDIA_TYPES,
} from '../constants/messageType';

/**
 * @param {string} type
 * @returns {boolean}
 */
export const isPhotoMessage = (type) => IMAGE_TYPES.includes(type);

/**
 * @param {string} type
 * @returns {boolean}
 */
export const isStickerMessage = (type) => MessageType.STICKER === type;

/**
 * @param {string} type
 * @returns {boolean}
 */
export const isGiftMessage = (type) => MessageType.GIFT === type;

/**
 * @param {string} type
 * @returns {boolean}
 */
export const isVideoMessage = (type) => VIDEO_TYPES.includes(type);

/**
 * @param {string} type
 * @returns {boolean}
 */
export const isVideoChatMessage = (type) => VIDEO_CHAT_TYPES.includes(type);

/**
 * @param {string} type
 * @returns {boolean}
 */
export const isPrivatePhotoRequestMessage = (type) =>
  MessageType.PRIVATE_PHOTO_REQUEST === type;

/**
 * @param {string} type
 * @returns {boolean}
 */
export const isVirtualAssistantWelcomeMessage = (type) =>
  MessageType.VIRTUAL_ASSISTANT_WELCOME === type;

/**
 * @param {string} type
 * @returns {boolean}
 */
export const isPmaPhotoResponseMessage = (type) =>
  MessageType.PMA_PHOTO_RESPONSE === type;

/**
 * @param {string} type
 * @returns {boolean}
 */
export const isMediaMessage = (type) => MEDIA_TYPES.includes(type);
