import type {FC, ReactNode} from 'react';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import cn from 'classnames';

import {H3, Text} from '@core/typography';
import useSmallTabletBreakpoint from '@core/responsive/useSmallTabletBreakpoint';

import type {BarProps} from '../card/Bar';
import type {IconProps} from '../icon/Icon';
import {Spacing, SpacingLarge} from '../spacing';
import {Actions} from '../actions';
import {Row, RowShort} from '../row';
import SpacingSize from '../../constants/SpacingSize';
import Align from '../../constants/Align';
import css from './Notice.css';

export interface NoticeProps {
  className?: string;
  spacing?: SpacingSize;
  withoutLeft?: boolean;
  withoutRight?: boolean;
  withoutTop?: boolean;
  withoutBottom?: boolean;
  icon?: ReactNode;
  iconClassName?: string;
  actionClassName?: string;
  iconComponent?: FC<IconProps>;
  iconComponentProps?: IconProps;
  iconSpacing?: SpacingSize;
  heading?: ReactNode; // Sometimes we should display some widget above icon.
  title?: ReactNode;
  'data-test'?: string;
  subTitle?: ReactNode;
  inverse?: boolean;
  description?: ReactNode;
  descriptionSpacing?: SpacingSize;
  descriptionAlign?: Align;
  footer?: ReactNode;
  actionSpacing?: SpacingSize;
  subTitleMuted?: boolean;
  actionsVertical?: boolean;
  spacedFooter?: boolean;
  actions?: ReactNode[];
  actionsReverse?: boolean;
  adaptive?: boolean;
  barComponent?: FC<BarProps>;
}

/**
 * Notice universal component is used as a notice for those places where there are no expected results to display, as an example:
 * @see ReportedPage - when there are no reported users,
 * @see MediaUploadDisabledPopup - when no activity history.
 */
const Notice: FC<NoticeProps> = ({
  barComponent: Bar,
  className,
  spacing = SpacingSize.NORMAL,
  subTitleMuted = true,
  actionSpacing = SpacingSize.LARGE,
  withoutLeft,
  withoutRight,
  withoutTop,
  withoutBottom,
  icon,
  iconClassName,
  actionClassName,
  iconComponent: Icon,
  iconComponentProps,
  iconSpacing = SpacingSize.NORMAL,
  heading,
  title,
  footer,
  spacedFooter = false,
  subTitle,
  inverse,
  description,
  descriptionSpacing = SpacingSize.SHORT,
  descriptionAlign = Align.CENTER,
  actions = null,
  actionsReverse,
  actionsVertical,
  'data-test': dataTest,
  adaptive = false,
}) => {
  const isSmallTablet = useSmallTabletBreakpoint();

  /**
   * According to the design system.
   * If there is a gray button and a primer button,
   * the primer button should always be on the right side of the web.
   */
  const reverse = actionsReverse && !isSmallTablet;

  const isOneAction = actions?.length < 2;

  return (
    <div
      data-test={dataTest}
      className={cn(css.wrap, isSmallTablet && css.wrapColumn, className)}
    >
      <Spacing
        size={spacing}
        withoutLeft={withoutLeft}
        withoutRight={withoutRight}
        withoutTop={withoutTop}
        withoutBottom={withoutBottom}
        adaptive={adaptive}
      >
        {heading && <Row>{heading}</Row>}
        {icon && (
          <Row space={iconSpacing} className={css.icon}>
            {typeof icon === 'string' ? (
              <Icon
                {...iconComponentProps}
                inverse={inverse}
                className={iconClassName}
              />
            ) : (
              icon
            )}
          </Row>
        )}
        {title && (
          <RowShort>
            <H3 inverse={inverse} data-test="noticeTitle">
              {title}
            </H3>
          </RowShort>
        )}
        {subTitle && (
          <RowShort>
            <Text
              type={subTitleMuted ? Text.TYPE.MUTED : Text.TYPE.NORMAL}
              inverse={inverse}
              data-test="noticeSubTitle"
            >
              {subTitle}
            </Text>
          </RowShort>
        )}
        {description && (
          <Row
            align={descriptionAlign}
            space={descriptionSpacing}
            data-test="noticeDescription"
          >
            {description}
          </Row>
        )}
        {actions && (
          <Row space={actionSpacing}>
            <Actions
              vertical={
                isUndefined(actionsVertical) ? isSmallTablet : actionsVertical
              }
              align={isOneAction ? Align.CENTER : Align.STRETCH}
              itemSpacing={SpacingSize.SHORT}
              fullWidth
              className={cn(
                !isSmallTablet && !isOneAction && css.actionList,
                actionClassName,
              )}
              reverse={reverse}
            >
              {actions.map((action, index) => {
                if (isEmpty(action)) return null;

                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className={isOneAction ? css.button : ''} key={index}>
                    {action}
                  </div>
                );
              })}
            </Actions>
          </Row>
        )}
      </Spacing>
      {footer &&
        (spacedFooter ? (
          <SpacingLarge adaptive withoutTop>
            <Bar rounded spacingSize={SpacingSize.NONE}>
              <Spacing>{footer}</Spacing>
            </Bar>
          </SpacingLarge>
        ) : (
          <Bar>
            <Spacing>{footer}</Spacing>
          </Bar>
        ))}
    </div>
  );
};

export default Notice;
