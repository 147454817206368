import {useEffect} from 'react';
import {useQuery} from '@apollo/client';
import {useHistory} from 'react-router-dom';
import {userselect} from 'modernizr';

import disableCopyText from '@core/utils/string/disableCopyText';

import getPageByUrlParams from './getPageByUrlParams';
import getStaticPageEvents from './getStaticPageEvents';
import addEventListeners from './addEventListeners';
import STATIC_PAGES from '../constants/pages';
import STATIC_PAGE_QUERY from '../graphql/queries/staticPage.gql';

const useStaticPageContentLogic = ({
  incomingPage,
  isPopup,
  handleLinkClick,
  eventsParams,
  onRemove,
  siteId,
  externalDomain,
}) => {
  const page = getPageByUrlParams(incomingPage);

  const mappedPage = STATIC_PAGES[page] ? STATIC_PAGES[page] : page;

  const {
    data = {site: {}, staticPage: {isCopyEnabled: false}},
    loading,
    error,
  } = useQuery(STATIC_PAGE_QUERY, {
    variables: {page: mappedPage, siteId},
  });

  const history = useHistory();

  useEffect(() => {
    if (loading) {
      return () => {};
    }

    if (error) {
      history.replace('/');

      return () => {};
    }

    const {
      staticPage: {isCopyEnabled},
    } = data;

    if (!userselect && !isCopyEnabled) {
      disableCopyText.on();
    }

    const removeEventListeners = addEventListeners({
      events: getStaticPageEvents({
        ...eventsParams,
        isPopup,
        isCopyEnabled,
        handleLinkClick,
        externalDomain,
      }),
      history,
      page,
    });

    return () => {
      onRemove && onRemove(page);
      removeEventListeners();
      if (!userselect && !isCopyEnabled) {
        disableCopyText.off();
      }
    };
  }, [
    data,
    error,
    eventsParams,
    handleLinkClick,
    history,
    isPopup,
    loading,
    onRemove,
    page,
    externalDomain,
  ]);

  return {error, loading, data};
};

export default useStaticPageContentLogic;
