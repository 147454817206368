import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import isCardPaymentType from '@core/payment/forms/card/utils/isCardPaymentType';
import isSepaPaymentType from '@core/payment/forms/card/utils/isSepaPaymentType';
import type {PaymentMethods} from '@core/payment/common/types/paymentMethods';
import type {Action} from '@core/types/graphql';

type ActivePackage = {
  method?: PaymentMethods;
};

const isAllowedPaymentMethod = (
  activePackage: ActivePackage,
  action: Action,
): boolean => {
  return (
    isCardPaymentType(activePackage?.method) ||
    (isSepaPaymentType(activePackage?.method) &&
      action === PAYMENT_ACTIONS.MEMBERSHIP)
  );
};

export default isAllowedPaymentMethod;
