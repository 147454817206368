import type {
  SearchStringOperationParam,
  SearchStringOperationResult,
  URIObject,
} from '../types/uri';

const getSearchParamsAsString = (
  urlInstance: URL,
  withLeadingQuestionMark: boolean = false,
): string => {
  return withLeadingQuestionMark
    ? urlInstance.search
    : urlInstance.search.slice(1);
};

const getSearchParamsAsObject = (urlInstance: URL): Record<string, any> => {
  const searchParams: Record<string, any> = {};
  urlInstance.searchParams.forEach((value, key) => {
    if (searchParams[key]) {
      if (Array.isArray(searchParams[key])) {
        searchParams[key].push(value);
      } else {
        searchParams[key] = [searchParams[key], value];
      }
    } else {
      searchParams[key] = value;
    }
  });
  return searchParams;
};

const setSearchParamsAsString = (
  urlInstance: URL,
  urlObject: URIObject,
  param: string,
  withLeadingQuestionMark: boolean = false,
): URIObject => {
  if (withLeadingQuestionMark) {
    urlInstance.search = param.startsWith('?') ? param : `?${param}`;
  } else {
    urlInstance.search = param.startsWith('?') ? param.slice(1) : param;
  }

  return urlObject;
};

const setSearchParamsAsObject = (
  urlInstance: URL,
  urlObject: URIObject,
  param: any,
): URIObject => {
  const searchParams = new URLSearchParams();
  Object.keys(param).forEach((key) => {
    if (Array.isArray(param[key])) {
      param[key].forEach((value) => searchParams.append(key, value));
    } else {
      searchParams.set(key, param[key]);
    }
  });
  urlInstance.search = `?${searchParams.toString()}`;
  return urlObject;
};

const operateSearchString = (
  param: SearchStringOperationParam,
  urlInstance: URL,
  urlObject: URIObject,
  withLeadingQuestionMark: boolean = false,
): SearchStringOperationResult | URIObject => {
  switch (typeof param) {
    case 'undefined':
      return getSearchParamsAsString(urlInstance, withLeadingQuestionMark);
    case 'boolean':
      return param === true
        ? getSearchParamsAsObject(urlInstance)
        : setSearchParamsAsString(
            urlInstance,
            urlObject,
            '',
            withLeadingQuestionMark,
          );
    case 'object':
      return setSearchParamsAsObject(urlInstance, urlObject, param);
    case 'string':
      return setSearchParamsAsString(
        urlInstance,
        urlObject,
        param,
        withLeadingQuestionMark,
      );
    default:
      return getSearchParamsAsString(urlInstance, withLeadingQuestionMark);
  }
};

export default operateSearchString;
