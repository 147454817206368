import FilterType from './FilterType';

export {default as FILTER_TYPE} from './FilterType';

/**
 * Map between filter name and property name that
 * must be retrieved from consumer
 * @see ActivityList.js
 */
export const FILTER_TO_PROP_MAP = {
  [FilterType.ACTIVITIES]: 'activities',
  [FilterType.LIKES]: 'likes',
  [FilterType.VIEWS]: 'views',
  [FilterType.FAVORITES]: 'favorites',
  [FilterType.OTHER]: 'other',
  [FilterType.INCOME]: 'income',
  [FilterType.OUTCOME]: 'outcome',
};
