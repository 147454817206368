enum PopupAppearance {
  /**
   * Like default popups which we are used to seeing.
   * Appears on center of screen. Placed on center of screen.
   */
  CENTERED = 'centered',

  /**
   * Default popup but with dark overlay.
   */
  CENTERED_WITH_DARK_OVERLAY = 'centeredWithDarkOverlay',

  /**
   * On WEB environment - default popup (in center of screen, etc.)
   * On MOB - it's like "sliding toolbar" from bottom. Looks like another layer
   * on top of content.
   */
  AS_LAYER_FROM_BOTTOM = 'asLayerFromBottom',
}

export default PopupAppearance;
