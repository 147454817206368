import type {GenderTypeEnum} from '@core/types';

import type {PrimaryPhoto, PrimaryPhotoData} from '../types';
import PhotoLevel from '../constants/PhotoLevel';
import PhotoPreset from '../constants/PhotoPreset';

const photoCache = new Map();

const cache = <T>(fn: () => T, deps: unknown[]): T => {
  const {length} = deps;
  let data = photoCache;
  for (let i = 0; i < length; i++) {
    const d = deps[i];
    const isLast = i === length - 1;
    if (!data.has(d)) {
      data.set(d, isLast ? fn() : new Map());
    }
    data = data.get(d);
  }
  return data as T;
};

/**
 * Helper method for generating correct data about user primary photo
 * that can be passed down into photo component.
 */
const getPrimaryPhotoData = (
  data: {
    gender?: GenderTypeEnum;
    photos?: {
      primaryPhoto?: PrimaryPhoto;
    };
  },
  preset: PhotoPreset = PhotoPreset.AVATAR,
  forceLevel: PhotoLevel = PhotoLevel.NORMAL,
): PrimaryPhotoData | null => {
  if (!data?.gender) {
    console.warn(
      '[getPrimaryPhotoData] Invalid data passed. Check function invocation',
    );
    return null;
  }

  const {gender, photos: {primaryPhoto} = {}} = data;
  const extraData = cache(() => ({gender, preset}), [gender, preset]);

  if (!primaryPhoto) {
    return extraData;
  }

  return cache(
    () => ({
      id: primaryPhoto.id || null,
      censored: primaryPhoto.censored || false,
      updatedOn: primaryPhoto.updatedOn || null,
      level: primaryPhoto?.level ?? null,
      pendingDelete: primaryPhoto.pendingDelete || null,
      forceLevel:
        forceLevel ||
        (primaryPhoto.forceLevel && PhotoLevel.HIGHLY_BLURRED) ||
        PhotoLevel.NORMAL,
      privateAttributes: primaryPhoto.privateAttributes || null,
      ...extraData,
    }),
    [primaryPhoto, extraData, forceLevel],
  );
};

export default getPrimaryPhotoData;
