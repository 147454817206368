import {getClientInstance} from '@core/graphql/client';

import type {AuthorizedQuery} from '../graphql/queries/authorized';
import AUTHORIZED_QUERY from '../graphql/queries/authorized.gql';

/**
 * isAuthorizedZone is queried before starting the app.
 */
const isAuthorizedZoneSync = (): boolean =>
  getClientInstance().readQuery<AuthorizedQuery>({
    query: AUTHORIZED_QUERY,
  }).isAuthorizedZone;

export default isAuthorizedZoneSync;
