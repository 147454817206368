export default {
  BackOfferTargetUrlQuery: {
    toServer: (params) => ({method: 'getBackOfferTargetUrl', params}),

    fromServer(response) {
      const {setIsNewTabActive, targetUrl} = response || {};

      return {
        data: {
          backOfferTargetUrl: {
            targetUrl,
            setActiveNewTab: setIsNewTabActive,
            __typename: 'BackOfferTargetUrl',
          },
        },
      };
    },
  },
};
