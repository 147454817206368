import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import memoize from 'lodash/memoize';

const formatTrackingName = memoize((trackingName?: string): string | null => {
  if (!trackingName) {
    return null;
  }

  return upperFirst(camelCase(trackingName));
});

export default formatTrackingName;
