import type {MethodTabsParams} from '../types';

/**
 * New structure is array of objects
 * {
 *   tabName: SomeTabName
 *   method: SomeMethodName
 *   paymentMethod: SomePaymentMethodName
 *   tabLogo: SomeTabLogoName
 *   packages: [list of packages]
 * }
 * so we just simplify it to list of packages without broking any other logic
 */
const getPackageListFromMethodTabs = <T extends MethodTabsParams>(
  methodTabs: T[],
): T['packages'] &
  Pick<T, 'method' | 'tabName' | 'tabLogo' | 'paymentMethod'>[] =>
  methodTabs.flatMap(({method, tabName, tabLogo, paymentMethod, packages}) =>
    packages.map((pkg) => ({
      ...pkg,
      method,
      tabName,
      tabLogo,
      paymentMethod,
    })),
  );

export default getPackageListFromMethodTabs;
