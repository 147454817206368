/**
 * Type of icon
 * @see IconSubstrate.tsx
 */
enum IconType {
  DANGER = 'danger',
  PRIMARY = 'primary',
  ACCENT = 'accent',
  SUCCESS = 'success',
  WARNING = 'warning',
  TRANSLUCENT = 'translucent',
  MUTED = 'muted',
}

export default IconType;
