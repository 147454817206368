import isUndefined from 'lodash/isUndefined';
import isBoolean from 'lodash/isBoolean';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';

import logger from '@core/logger';

/**
 * @const {Array}
 */
const BOOLEAN_FIELDS = [
  'isOneClickShown',
  'isFormHidden',
  'isFrontendValidationPassed',
  'isButtonActive',
];

/**
 * @const {Array}
 */
const STRING_FIELDS = ['paymentForm', 'payButton', 'action'];

/**
 * @const {String}
 */
const NUMBER_FIELD = 'timeInterval';

/**
 * @const {Array}
 */
export const REQUIRED_VARIABLES_FOR_MUTATION = [
  ...BOOLEAN_FIELDS,
  ...STRING_FIELDS,
  NUMBER_FIELD,
];

/**
 * @param {Object} paymentData
 * @returns {Object}
 */
const normalizeVariablesForPayTrack = (paymentData = {}) => {
  const result = {...paymentData};

  REQUIRED_VARIABLES_FOR_MUTATION.forEach((fieldName) => {
    const fieldValue = paymentData[fieldName];
    let error = null;

    if (fieldName in result) {
      if (BOOLEAN_FIELDS.includes(fieldName) && !isBoolean(fieldValue)) {
        // @TODO remove this error after BU-180329 realization and stands some time(after browser reset cache)
        error = `Incorrect type for field "${fieldName}". Field should be a boolean!`;
        result[fieldName] = Boolean(fieldValue);
      } else if (isUndefined(fieldValue)) {
        error = `"${fieldName}" is undefined!`;
      } else if (STRING_FIELDS.includes(fieldName) && !isString(fieldValue)) {
        error = `Incorrect type for field "${fieldName}". Field should be a string!`;
      } else if (NUMBER_FIELD === fieldName && !isNumber(fieldValue)) {
        error = `Incorrect type for field "${fieldName}". Field should be a number!`;
      }
    } else {
      // @TODO remove this error after BU-180329 realization and stands some time(after browser reset cache)
      error = `"${fieldName}" is forgotten variable`;
    }

    if (error) {
      logger.sendError(`[normalizeVariablesForPayTrack] ${error}`);
    }
  });

  return result;
};

export default normalizeVariablesForPayTrack;
