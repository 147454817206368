/**
 * Export for unit test
 */
export const MICRO_FEATURES_URL = '/pay/microFeatures';

/**
 * @param {string} url
 * @returns {boolean}
 */
const isMicroFeaturesUrl = (url) =>
  Boolean(url) && url.startsWith(MICRO_FEATURES_URL);

export default isMicroFeaturesUrl;
