import type {FC, ReactEventHandler} from 'react';
import React from 'react';
import cn from 'classnames';

import type ImageScaling from '../../constants/ImageScaling';
import css from './FittingImage.css';

export interface FittingImageProps {
  url: string;
  className?: string;
  onLoad?: ReactEventHandler<HTMLImageElement>;
  onError?: ReactEventHandler<HTMLImageElement>;
  scaling?: ImageScaling;
  alt?: string;
  fullSize?: boolean;
}

const FittingImage: FC<FittingImageProps> = ({
  url,
  className,
  onLoad,
  onError,
  scaling,
  alt,
  fullSize = false,
}) => {
  /**
   * The img is not clickable due to the fact that it is used pointer-events: none;
   * @see .image /src/packages/dating/user/photo/styles/BlurredPhoto.css
   */
  return (
    /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */
    <img
      className={cn(className, css[scaling], fullSize && css.fullSize)}
      src={url}
      alt={alt || ''}
      onLoad={onLoad}
      onError={onError}
      draggable={false}
    />
  );
};

export default FittingImage;
