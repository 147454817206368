import type {Observable} from 'rxjs';
import {fromEvent} from 'rxjs';
import {map} from 'rxjs/operators';

import NOTIFICATION_TYPES from '../constants/notificationTypes';
import type {CommonNotification} from '../types';

const connectionDataSource = (): Observable<CommonNotification> =>
  fromEvent(window, 'offline').pipe(
    map(() => ({
      type: NOTIFICATION_TYPES.LOST_CONNECTION,
    })),
  );

export default connectionDataSource;
