import {createContext} from 'react';

import {BREAKPOINT} from './constants';
import type {BreakpointKeys} from './types';

export type ResponsiveBreakpoints = {
  [K in BreakpointKeys]: boolean;
};

// Default breakpoints is used to avoid additional mocks for multiple unit tests.
const breakpoints: ResponsiveBreakpoints = {} as ResponsiveBreakpoints;

Object.keys(BREAKPOINT).forEach((name) => {
  breakpoints[name] = false;
});

const ResponsiveContext = createContext(breakpoints);

export default ResponsiveContext;
