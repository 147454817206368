import makePay from './makePay';
import type {PaymentProcessStep} from '../../types/paymentProcess';

const payFromPopup: PaymentProcessStep = async (data) => {
  const {paymentData} = data;

  return makePay({
    ...data,
    paymentData: {
      ...paymentData,
      payFromPopup: true,
    },
  });
};

export default payFromPopup;
