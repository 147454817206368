import {
  LIMIT_REACHED,
  NOT_ALLOWED,
  SERVER_ERROR,
} from '@core/messenger/senderForm/constants/messageAssistantReasons';

export default {
  MessageAssistantQuery: {
    toServer: (params) => ({method: 'firstMessageGeneration', params}),

    fromServer: (response, error) => ({
      data: {
        userFeatures: {
          messageAssistant: {
            message: error ? null : response.message,
            __typename: 'MessageAssistant',
          },
          firstMessageGeneration: {
            reason:
              (error && SERVER_ERROR) ||
              (response.status === 'limitReached' && LIMIT_REACHED) ||
              (response.status === 'notAvailable' && NOT_ALLOWED) ||
              null,
            limit: error ? null : response.limit,
            left: error ? null : response.left,
            __typename: 'FirstMessageGeneration',
          },
          __typename: 'UserFeatures',
        },
      },
    }),
  },
};
