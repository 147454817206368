import memoize from 'lodash/memoize';

import {getClientInstance} from '@core/graphql/client';
import type {PayButtonEnum} from '@core/types';

import TRACK_SESSION_REQUEST_MUTATION from '../common/graphql/mutations/trackSessionRequest.gql';
import type {TrackSessionRequestMutationVariables} from '../common/graphql/mutations/trackSessionRequest';

const trackSessionRequest = (buttonType: PayButtonEnum) => {
  getClientInstance().mutate<never, TrackSessionRequestMutationVariables>({
    mutation: TRACK_SESSION_REQUEST_MUTATION,
    variables: {payButton: buttonType},
  });
};

export default memoize(trackSessionRequest);
