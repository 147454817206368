import type {Observable} from 'rxjs';
import {fromEvent} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import type PostMessage from '../types/postMessage';

const createPostMessageObservable = (): Observable<PostMessage> =>
  fromEvent<MessageEvent>(window, 'message').pipe(
    map(({data}) => data),
    filter(({resultPageMessage}) => resultPageMessage),
  );

export default createPostMessageObservable;
