import logger from '@core/logger';

import type {ACLCodesMap, ACLErrorRenderer} from '../types';

/**
 * Returns function if the error `code` found in `rulesMap`.
 * That function usually returns `<Redirect>` component or something similar.
 */
const getErrorRedirect = (
  code?: string,
  rulesMap?: ACLCodesMap,
): ACLErrorRenderer | null => {
  if (!code) return null;

  const redirect = rulesMap[code];

  // If no one handler was found - notify about it
  if (!redirect) {
    logger.sendWarning(
      `[GraphQLError]: No "action" was found for error: "${code}"`,
    );
    return null;
  }

  return redirect;
};

export default getErrorRedirect;
