import logger from '@core/logger';

import PaymentScenario from '../../common/constants/paymentScenario';
import type {
  PaymentProcessStep,
  PaymentProcessStepData,
} from '../types/paymentProcess';
import makePay from '../paymentFlow/methods/makePay';

const payByMethodFromFreeTrialSuccessPopup: PaymentProcessStep = async (
  data,
) => {
  const {paymentData} = data;
  let result: PaymentProcessStepData;

  const paymentDataForPay = {
    ...paymentData,
    scenario: PaymentScenario.ONECLICK,
  };

  try {
    result = await makePay({...data, paymentData: paymentDataForPay});
  } catch (error) {
    logger.sendError(
      `[payByMethodFromFreeTrialSuccessPopup] pay error ${error}`,
    );
    result = {
      ...data,
      paymentData: paymentDataForPay,
      paymentResult: {status: false},
    };
  }

  if (typeof result === 'string') {
    return {...data, flowStatus: result};
  }

  return result;
};

export default payByMethodFromFreeTrialSuccessPopup;
