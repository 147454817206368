import React, {memo} from 'react';
import cn from 'classnames';

import type {CSSModule} from '../../types';
import BadgeType from '../../constants/BadgeType';
import baseCss from './Badge.css';

export interface BadgeProps {
  className?: string;
  inverse?: boolean;
  // Badge color modification
  type?: BadgeType;
}

/**
 * Badge item. In nutshell is just a round item with outline color
 * In future, if we want another type of badge - we can modify 'type'.
 */
const Badge = memo<
  // `BadgeProps` without `css` inside to make it more suitable for `@phoenix/ui`.
  BadgeProps & {css: CSSModule}
>(({css, className, inverse = false, type = BadgeType.SUCCESS}) => (
  <div
    className={cn(
      css.badge,
      baseCss[type],
      baseCss.badge,
      inverse && [css.inverse, baseCss.inverse],
      className,
    )}
  />
));

export default Badge;
