import AbstractProxyService from '@core/utils/react/AbstractProxyService';

import NOTIFICATION_TYPES from '../constants/notificationTypes';
import type {Notification} from '../types';

type NotificationProxy = {
  addNotification: (...args: Notification[]) => void;
};

/**
 * @class NotificationsService
 * @classdesc Proxy class for providing access to notification functionality.
 * @see HeaderNotifications.js
 */
class NotificationsService extends AbstractProxyService<NotificationProxy> {
  TYPES = NOTIFICATION_TYPES;

  addNotification = (...args: Notification[]) => {
    if (this.isProxyAvailable()) {
      this.proxy.addNotification(...args);
    }
  };

  /**
   * @override
   */
  getName(): string {
    return 'NotificationsService';
  }
}

export default new NotificationsService();
