import React, {Component} from 'react';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';

import RenderBackbone from '@core/utils/backbone/RenderBackbone';
import {TOOLBAR_ID} from '@core/header/constants/header';
import {SpacingSize} from '@core/ui/constants';

import {defaultContext, Provider} from './HeaderToolbarContext';

/**
 * @const {{
 *    spacingSize: string,
 *    showBackButton: boolean,
 *    showSideRight: boolean,
 *    inverse: inverse,
 * }}
 */
const DEFAULT_OPEN_OPTIONS = {
  showBackButton: true,
  spacingSize: SpacingSize.SHORT,
  showSideRight: false,
  inverse: false,
};

/**
 * Manages the display of the sub toolbar
 * (came to replace the portal, as the portal doubled the content
 * if you try to add two toolbars in a row,
 * there was no re-reder of the toolbar)
 * @class HeaderToolbarProvider
 * @see Component
 */
export default class HeaderToolbarProvider extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    children: defaultContext.children,
    isHidden: defaultContext.isHidden,
    options: defaultContext.options,
  };

  componentDidMount() {
    // For Backbone.js app (remove after move app to react)
    // eslint-disable-next-line
    window.app.header = {
      changeToolbar: this.changeToolbar,
      hideToolbar: this.hideToolbar,
      removeToolbar: this.remove,
      showToolbar: this.showToolbar,
      getHeight: this.getHeight,
    };
  }

  /**
   * If you should do the logic of displaying the toolbar by parameter
   * (so that mob toolbars are not shown in unnecessary places)
   */
  remove = () => {
    this.setState({
      children: null,
      isHidden: true,
    });
  };

  /**
   * If you should do the logic of displaying the toolbar by parameter
   * (so that mob toolbars are not shown in unnecessary places)
   */
  open = (children, options = {}) => {
    defaults(options, DEFAULT_OPEN_OPTIONS);

    this.setState({
      children: children || defaultContext.children,
      options: options || defaultContext.options,
      isHidden: false,
    });
  };

  /**
   * Render custom toolbar. For calling by other views.
   * @param {object} view   - Backbone.View object of custom toolbar
   * @param {object} params - object of params for view rendering
   */
  changeToolbar = (
    view,
    {
      showBackButton = false,
      spacingSize = SpacingSize.NONE,
      showSideRight = false,
      inverse = false,
      ...options
    } = {},
  ) => {
    this.open(<RenderBackbone view={view} options={options} />, {
      showBackButton,
      spacingSize,
      showSideRight,
      inverse,
    });
  };

  hideToolbar = () => this.setState({isHidden: true});

  showToolbar = () => this.setState({isHidden: false});

  /**
   * @returns Number
   */
  getHeight = () => document.getElementById(TOOLBAR_ID).offsetHeight;

  render() {
    return (
      <Provider
        value={{
          open: this.open,
          remove: this.remove,
          ...this.state,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}
