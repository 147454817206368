import type {ApolloError} from '@apollo/client';

import useNaughtyMode from '@core/naughtyMode/utils/useNaughtyMode';

import type {Photo} from '../types';
import type {LEVEL} from '../constants';
import getCorrectedPhotoLevel from './getCorrectedPhotoLevel';

type UseCorrectedPhotoLevelParams = Photo & {
  skipBlur?: boolean;
  isMy?: boolean;
};

/**
 * Correct photo level based on its own photo level and current user level.
 * Additionally, there is a setting for some countries, where we must apply stronger level.
 * Provides object of shape:
 * {level, blurLevel}
 * Where:
 * level - is actual corrected photo level
 * blurLevel - level of photo blur
 * isMy - means that it is photo of current user
 * skipBlur - means that no need standard logic by content level.
 * (always high-blurred for blurred and high-blurred values of 'level')
 */
const useCorrectedPhotoLevel = ({
  level,
  forceLevel,
  isMy,
  skipBlur,
  privateAttributes,
}: UseCorrectedPhotoLevelParams = {}): {
  level: LEVEL;
  blurLevel: LEVEL;
  loading: boolean;
  error: ApolloError | null;
} => {
  const {naughtyMode, availableModes, isStrictNudityLaws, loading, error} =
    useNaughtyMode();

  const {level: correctLevel, blurLevel} = getCorrectedPhotoLevel({
    level,
    forceLevel,
    isMy,
    skipBlur,
    naughtyMode,
    availableModes,
    isStrictNudityLaws,
    privateAttributes,
  });

  return {level: correctLevel, blurLevel, loading, error};
};

export default useCorrectedPhotoLevel;
