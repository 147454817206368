import once from 'lodash/once';

import {getClientInstance} from '@core/graphql/client';

import type {VideoMutedSubscription} from '../graphql/subscriptions/videoMute';
import VIDEO_MUTE_SUBSCRIPTION from '../graphql/subscriptions/videoMute.gql';

const getVideoMuteSubscription = once(() =>
  getClientInstance().subscribe<VideoMutedSubscription>({
    query: VIDEO_MUTE_SUBSCRIPTION,
  }),
);

export default getVideoMuteSubscription;
