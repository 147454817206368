import {MSG} from '../constants/socketEventNames';

export default {
  LifetimeOfferSubscription: {
    incomingEvents: [`${MSG}lifetimeOffer`],

    fromServer: ({via}) => ({
      data: {
        via,
        __typename: 'LifetimeOffer',
      },
    }),
  },
};
