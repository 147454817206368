enum ActivityInteractAppearance {
  /**
   * Activity interaction is based on existance of "ear" on all pages
   * that opens notification popup via click. Activity page is lightweight
   * and doesn't display 'messenger' activities. Also notification popup displays
   * some additional banners inside it.
   *
   * @see NotificationPopup.js
   *
   * Also, such param is closely related to "bookmark" functionality in terms of themes.
   * @see ThemedActivityBookmark.js
   */
  WITH_EAR = 'withEar',

  /**
   * All activity functionality is incorporated on page. There is no "ears" on product.
   * Page displays all additional banners, RM banners and activities by itself.
   * Additionally, displays "all" activities tab.
   */
  ALL_ON_PAGE = 'allOnPage',

  /**
   * Activity page based on ALL_ON_PAGE, with messenger photo gallery banner.
   */
  ALL_ON_PAGE_AND_PHOTO_GALLERY_BANNER = 'allOnPageAndPhotoGalleryBanner',

  /**
   * Activity page based on ALL_ON_PAGE, but payment banner and all tabs is hidden on web
   */
  WITH_BANNERS_AND_TABS_DISPLAY_CONDITION = 'withBannersAndTabsDisplayCondition',

  /**
   * Activity page with carousel of random or match users
   * and with tabs of activity and recipients
   */
  UNITED = 'united',
}

export default ActivityInteractAppearance;
