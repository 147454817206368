enum StickedNotificationAppearance {
  /**
   * Means, that there will be no sticked notification on the site
   */
  NONE = 'none',

  DEFAULT = 'default',

  /**
   * Like default but with payment button on top
   */
  WITH_PAYMENT_BUTTON = 'withPaymentButton',

  OVER_SUBHEADER_WITH_PREVIEW = 'overSubheaderWithPreview',
}

export default StickedNotificationAppearance;
