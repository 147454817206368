/**
 * List of errors
 */

enum CommunicationRestriction {
  // Send forbidden. Response example: {error: "communication restriction", reason: "showAntiscamBlockAlert"}
  ANTISCAM_ALERT = 'showAntiscamBlockAlert',
  /* While conversation of the video in processing comes incoming communication. Then we try send video but free
  communication exhausted - our video message declined. */
  FREE_COMMUNICATION_EXHAUSTED = 'freeCommunicationExhausted',
  // Current user is blocked by recipient, communication is restricted and we need to show cap
  BLOCKED_USER = 'blockedUser',
}

export default CommunicationRestriction;
