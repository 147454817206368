import {PRESET} from '@core/user/photo/constants';

// @TODO refactor server route /video/preview to accept photo presets and remove this code
const PreviewPresetSize = {
  [PRESET.NORMAL]: 1000,
  [PRESET.AVATAR_BIG]: 480,
  [PRESET.AVATAR]: 240,
  [PRESET.AVATAR_SMALL]: 60,
};

export default PreviewPresetSize;
