/**
 * Is used to merge objects with getters created by {@see createTranslationsMap}, because they can't be merged as normal objects.
 * @param {...Object} translationsMaps
 * @returns {Object}
 */
const mergeTranslationMaps = (...translationsMaps) => {
  // Create an empty object to hold the merged result
  const result = {};

  // Merge all translationsMap into the new object, including their descriptors
  translationsMaps.forEach((translationsMap) => {
    Object.defineProperties(
      result,
      Object.getOwnPropertyDescriptors(translationsMap),
    );
  });

  return result;
};

export default mergeTranslationMaps;
