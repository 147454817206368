import isArray from 'lodash/isArray';

import {LOCAL_STORAGE_DEBUG_KEY} from '../constants/babcia';
import {Category, Event, EventAttributes} from '../constants/babciaDataTypes';

type DebugParams = {
  [key: string]: string | string[];
};

export type BabciaData = {
  debugInfo?: string;
  event: string;
  value: string;
  eventAttributes?: string[];
  via?: string;
  context?: string;
  label: string;
  category: Category;
  index?: string;
  element?: HTMLElement;
};

const storageData = window?.localStorage.getItem(LOCAL_STORAGE_DEBUG_KEY);
let debugParams: DebugParams | null = null;

/**
 * Displays load tracking log
 * @see BabciaNotifications
 */
const displayNotification = (data: BabciaData) => {
  if (data.event !== Event.LOAD) {
    return;
  }

  // Global is used instead of some util function to avoid babcia chunk expansion.
  if (window.babciaNotify) {
    window.babciaNotify(data);
  } else {
    console.error('Skipped babcia notification');
  }
};

const consoleGroup = (data: BabciaData) => {
  const {category, via, index, label} = data;
  const tableHeader = ['event', 'value'];

  if (category === Category.POPUP) {
    tableHeader.push('label');
  } else if (via) {
    tableHeader.push('via');
  } else {
    tableHeader.push('context');
  }

  console.group(
    '%cSend new event to babcia',
    'color: #868686; font-size: 13px; font-weight: 300;',
  );
  console.table({[index || label]: data}, tableHeader);
  console.groupEnd();
};

const log = ({debugInfo, ...data}: BabciaData) => {
  displayNotification({debugInfo, ...data});
  consoleGroup(data);
};

/**
 * @param {Object} data - The data to debug
 */
const babciaDebugLog = (data: BabciaData): void => {
  if (storageData) {
    const tableData = {...data};

    if (tableData.eventAttributes?.length) {
      const [via, context, index] = tableData.eventAttributes.slice(4);

      if ([Event.REDIRECT, Event.REPLACE].includes(tableData.event as Event)) {
        tableData.via = via;
      } else if (tableData.category !== Category.POPUP) {
        tableData.context = context;
      }

      tableData.index = index;
    } else {
      tableData.context = EventAttributes.NONE;
    }

    debugParams ||= JSON.parse(storageData);

    if (typeof debugParams === 'object') {
      Object.keys(debugParams).forEach((key) => {
        const filterValue = debugParams[key];
        const dataValue = tableData[key];

        if (
          isArray(filterValue)
            ? filterValue.includes(dataValue)
            : dataValue === filterValue
        ) {
          log(tableData);
        }
      });

      return;
    }

    log(tableData);
  }
};

export default babciaDebugLog;
