/* global MOCKED_API_ENABLED */

import Backoff from 'backo2';
import io from 'socket.io-client';

import logger from '@core/logger';
import {fetch} from '@core/utils/fetch';
import getCookie from '@core/utils/cookie/getCookie';
import TYPES from '@core/extraEvent/constants/extraEventTypes';

import WS_READY_STATE from './constants/wsReadyState';
import {dispatch} from './interactionEvents';
import {
  ACTION_POPUP,
  AUTH,
  CONNECTED,
  DISCONNECT,
  LIVECHAT_AUTH,
  MSG,
  PMA_POPUP,
  RECONNECT,
  ROOMS,
} from './constants/socketEventNames';

const NO_CONNECTION = 'no connection';
const CANCELLED = 'cancelled';

const CONNECTION_PATH = '/interaction/v3/';

/**
 * @type {number}
 */
const FORBIDDEN_ERROR = 403;

/**
 * @type {string}
 */
const ACCESS_ERROR = '400';

/**
 * Time generator to wait before try to connect once more
 * @type {number}
 */
const reconnectTimeWaitGenerator = new Backoff({
  min: 1000,
  max: 60000,
  factor: 2,
});

class Interaction {
  /**
   * @private
   */
  status = WS_READY_STATE.CLOSED;

  /**
   * Counter to identify callback
   * @private
   */
  reqId = 0;

  /**
   * Storage for save callback
   * @private
   * @type {Object}
   */
  storage = {};

  /**
   * Callbacks storage for deferred rpc responses.
   * @private
   */
  deferredStorage = {};

  constructor() {
    window.addEventListener('online', () => {
      if (this.reconnectTimeout) {
        clearTimeout(this.reconnectTimeout);
        this.reconnectTimeout = null;
        reconnectTimeWaitGenerator.reset();
        this.connect();
      }
    });
  }

  /**
   * Show if interaction is connected
   * @public
   * @return {boolean}
   */
  isConnected() {
    return this.status === WS_READY_STATE.OPEN;
  }

  connect() {
    if (
      this.status === WS_READY_STATE.CONNECTING ||
      this.status === WS_READY_STATE.OPEN
    ) {
      return;
    }
    this.status = WS_READY_STATE.CONNECTING;

    /**
     * Need for Long poll when websocket didn't available or crushed.
     * Long poll used as websocket polyfill in socket.io
     */
    window.io = io;

    if (window.IS_INTEGRATION_TEST_ENVIRONMENT || MOCKED_API_ENABLED) {
      this.connectForTest();
      return;
    }

    this.connectForProduction();
  }

  /**
   * Perform connection to integration testing socket IO server.
   * Only for testing purposes, we don't need to handle any reconnect things,
   * we need just to connect to one endpoint and anything will be fine.
   */
  connectForTest() {
    /**
     * Connect socket to mocked server url setup inside tests
     * @see setupBrowserTests.js ('beforeEach' method)
     */
    this.socket = io(global.BASE_TEST_URL, {
      reconnection: false,
      forceNew: true,
    });

    /**
     * Expose socket as global variable for using inside integration tests
     */
    window.socket = this.socket;

    /**
     * In production realization of WS we must wait for 'auth' event.
     * In case of mocked WS - we are authorized instantly. So we don't need to wait anything,
     * and we can set status already open.
     */
    this.status = WS_READY_STATE.OPEN;

    this.addListeners();
  }

  /**
   * Perform connection to production socket IO server.
   */
  connectForProduction() {
    this.getAuthParams()
      .then(({host, params, isControlledError}) => {
        if (isControlledError) {
          this.status = WS_READY_STATE.CLOSED;
          return;
        }

        if (params.interactionType) {
          params.query = `${params.query}&interactionType=${
            params.interactionType
          }`;
        }

        Object.assign(params, {
          path: CONNECTION_PATH,
          transports: ['websocket', 'polling'],
          reconnection: false,
          forceNew: true,
        });
        this.socket = io.connect(host, params);
        this.addListeners();
      })
      .catch((error) => {
        this.reconnect();
        logger.captureException(error);
      });
  }

  /**
   *
   * @param method
   * @param params
   * @param callback
   * @return {Number | undefined} - id
   */
  request(method, params, callback) {
    /* eslint-disable */

    if (typeof params === 'function') {
      callback = params;
      params = {};
    }

    // set showServerTranslationKeys cookie to show translates from backend from interaction
    params.showServerTranslationKeys = getCookie('showServerTranslationKeys');

    if (!this.isConnected()) {
      /**
       * Need send undefined to work ES6 default props
       */
      callback && callback(void 0, NO_CONNECTION);
      return;
    }

    let id;
    if (typeof callback === 'function') {
      this.reqId += 1;
      id = this.reqId;
      this.storage[id] = callback;
    }

    const {interactionType, ...paramsForSend} = params;
    this.socket.emit(interactionType || 'rpc', {
      id,
      method,
      params: paramsForSend,
    });
    return id;
    /* eslint-enable */
  }

  /**
   * On rpc handler
   * @private
   * @param response
   */
  onResponse(response) {
    const {id} = response;
    if (!this.storage[id]) {
      return;
    }

    const {deferredResultId} = response.result || {};

    if (deferredResultId) {
      this.deferredStorage[deferredResultId] = this.storage[id];
    } else {
      this.storage[id](
        response.result,
        response.error || response.result?.error,
      );
    }
    delete this.storage[id];
  }

  /**
   * Deferred rpc response handler.
   * @private
   * @param {Object} response
   * @param {number} response.deferredResultId
   * @param {Object} response.result
   * @param {string} response.error
   */
  onDeferredResponse({deferredResultId: id, result, error}) {
    if (!this.deferredStorage[id]) {
      return;
    }

    this.deferredStorage[id](result, error || result?.error || null);
    delete this.deferredStorage[id];
  }

  cancel(id) {
    if (this.storage[id]) {
      /**
       * Need send undefined for work ES6 default props
       */
      // eslint-disable-next-line
      this.storage[id](void 0, CANCELLED);
      delete this.storage[id];
    }
  }

  addListeners() {
    this.socket.on('connect_timeout', (timeout) => {
      logger.sendWarning(
        `[Interaction] socket.io connect_timeout handler. Data: ${timeout} | ${JSON.stringify(
          timeout,
        )}`,
      );
      this.reconnect();
    });
    this.socket.on('connect_error', (error) => {
      logger.sendWarning(
        `[Interaction] socket.io connect_error handler. Data: ${error} | ${JSON.stringify(
          error,
        )}`,
      );
      this.reconnect();
    });
    this.socket.on(DISCONNECT, () => {
      dispatch(DISCONNECT, {
        webSocketConnected: false,
      });

      this.reconnect();
    });
    this.socket.on('error', (data) => {
      logger.sendWarning(
        `[Interaction] socket.io onError handler. Data: ${data} | ${JSON.stringify(
          data,
        )}`,
      );
      this.reconnect();
    });
    this.socket.on(RECONNECT, (data) => {
      this.status = WS_READY_STATE.OPEN;
      reconnectTimeWaitGenerator.reset();

      dispatch(RECONNECT, data);
    });
    this.socket.on(AUTH, () => {
      this.status = WS_READY_STATE.OPEN;
      reconnectTimeWaitGenerator.reset();

      dispatch(AUTH);
    });

    [RECONNECT, LIVECHAT_AUTH, AUTH].forEach((type) => {
      this.socket.on(type, () => {
        dispatch(CONNECTED, {webSocketConnected: true});
      });
    });

    this.socket.on('rpc', (res) => {
      this.onResponse(res);
    });

    this.socket.on(ROOMS, (event, args) => {
      dispatch(ROOMS, {event, ...args});
    });

    this.socket.on(MSG, (data) => {
      const msg = JSON.parse(data);
      if (msg.deferredResultId) {
        this.onDeferredResponse(msg);
        return;
      }
      dispatch(MSG + msg.type, msg);
    });

    /**
     * Rooms for live cams, if they will be removed, then look, most likely, it will also be mowed down
     */
    [
      LIVECHAT_AUTH,
      'livechatUserJoined',
      'livechatModelPrivateIncoming',
      'livechatWantPrivate',
      'livechatRoomCreated',
      'livechatUsersList',
      'livechatMessage',
      'livechatUserLeaved',
      'livechatClosed',
    ].forEach((type) => {
      this.socket.on(type, (data) => {
        dispatch(type, data);
      });
    });

    [
      TYPES.SEARCH_LIMITS,
      PMA_POPUP,
      ACTION_POPUP,
      TYPES.RM_BUNDLE_POPUP_ONE_MONTH,
      TYPES.RM_BUNDLE_POPUP_ONE_WEEK,
      TYPES.SPIN_NOW,
      TYPES.EXTRA_DISCOUNT,
      TYPES.INTERACTIVE_LIKE,
      TYPES.PROFILES_VIEW,
      TYPES.BUY_THREE_DAY,
      TYPES.BUY_ONE_PLUS_ONE,
      TYPES.PRIORITY_MAN,
    ].forEach((type) => {
      this.socket.on(type, (data) => {
        const options = data ? JSON.parse(data) : {};

        dispatch(type, {
          type,
          ...options,
        });
      });
    });
  }

  reconnect() {
    this.status = WS_READY_STATE.CLOSED;
    delete this.authParamsPromise;

    this.reconnectTimeout = setTimeout(() => {
      this.reconnectTimeout = null;
      this.connect();
    }, reconnectTimeWaitGenerator.duration());
  }

  getAuthParams() {
    this.authParamsPromise =
      this.authParamsPromise ||
      fetch('/api/v1/interaction/generateAuthData')
        .then((response) => {
          /**
           * Close web socket client for forbidden error response since the server is closed
           * we will not be able to get the settings for connection
           * and therefore websockets are not needed
           */
          if (response.status === FORBIDDEN_ERROR) {
            delete this.authParamsPromise;

            return {
              isControlledError: true,
            };
          }
          if (response.ok) {
            return response.json();
          }
          delete this.authParamsPromise;
          throw new Error(
            `[Interaction] can't get auth params: ${response.statusText}`,
          );
        })
        .then((data) => {
          const {isControlledError, meta: {code, description} = {}} =
            data || {};
          if (isControlledError || String(code) === ACCESS_ERROR) {
            return {
              isControlledError: true,
            };
          }

          if (code && String(code) !== '200') {
            throw new Error(
              `[Interaction] can't get auth params: ${JSON.stringify(
                description,
              )}`,
            );
          }
          return data;
        });
    return this.authParamsPromise;
  }
}

export default new Interaction();
