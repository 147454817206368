import type {FC, MouseEventHandler, ReactNode} from 'react';
import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {gotoUrl} from '@core/utils/url';
import createRouteName from '@core/tracking/babcia/utils/createRouteName';

import type {ButtonProps} from './Button';

export interface ButtonLinkProps extends ButtonProps {
  href: string;
  target?: '_blank' | '_self';
  children?: ReactNode;
}

/**
 * Button as link. Created for simplifying working with links (using button appearance)
 */
const ButtonLink: FC<
  ButtonLinkProps & {
    // Original button component from "theme"
    component: FC<ButtonProps>;
  }
> = ({component: Button, href, target, onClick, children, ...props}) => {
  const history = useHistory();

  /**
   * @todo
   *   When entire application will migrate on React
   *   we can eradicate entire class and use React-router Link component
   *
   * @see https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/api/Link.md
   */
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      const url = href.startsWith('/')
        ? `${window.location.origin}${href}`
        : href;

      if (typeof onClick === 'function') {
        onClick(e);
      }

      // should not redirect if we are on the same page
      if (url === window.location.href) {
        return;
      }

      if (target) {
        gotoUrl(url, true, target);
      } else {
        history.push(href);
      }
    },
    [href, target, history, onClick],
  );

  return (
    <Button
      {...props}
      onClick={handleClick}
      trackingName={createRouteName(href)}
    >
      {children}
    </Button>
  );
};

export default ButtonLink;
