import {UPDATE_PROFILE} from './vias';
import * as UPLOAD_TYPES from './uploadTypes';
import * as SOURCES from './sources';
import {MIXED} from './accept';

/**
 * Common settings for upload popups
 */
export const COMMON = {
  type: null,
  accept: MIXED,
  source: SOURCES.DEFAULT,
  via: UPDATE_PROFILE,
  attachToUser: true,
  multiple: false,
  onSuccess: null,
  onUpload: null,
  isWebcamCapture: false,
  uploadCost: null,
  files: [],
};

/**
 * Default settings for photo upload popup
 */
export const PHOTO_SETTINGS = {
  ...COMMON,
  type: UPLOAD_TYPES.PHOTO,
  multiple: true,
};

/**
 * Default settings for video upload popup
 */
export const VIDEO_SETTINGS = {
  ...COMMON,
  type: UPLOAD_TYPES.VIDEO,
};
