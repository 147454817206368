import PAYMENT_METHODS from '../../common/constants/paymentMethods';
import {CARD_FORM_NAME, CARD_WALLET_FORM_NAME} from '../constants/formNames';
import getFormNameByMethod from './getFormNameByMethod';

type GetFormNameParams = {
  activePackage?: {paymentMethod?: string};
  method: typeof PAYMENT_METHODS;
};

const getFormName = ({
  activePackage: {paymentMethod: packagePaymentMethod} = {},
  method,
}: GetFormNameParams): string => {
  const paymentMethod = method || packagePaymentMethod;

  switch (paymentMethod) {
    case PAYMENT_METHODS.CARD:
      return CARD_FORM_NAME;
    case PAYMENT_METHODS.APPLE_PAY:
    case PAYMENT_METHODS.GOOGLE_PAY:
      return CARD_WALLET_FORM_NAME;
    default:
      return getFormNameByMethod(paymentMethod as string);
  }
};

export default getFormName;
