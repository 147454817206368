import {useEffect} from 'react';
import {useQuery, useReactiveVar} from '@apollo/client';

import canMakeApplePayPaymentsVar from '@core/graphql/vars/canMakeApplePayPaymentsVar';
import useActivePackage from '@core/payment/widgets/package/utils/useActivePackage';

import {WALLET_METHODS} from '../../../common/constants/paymentMethods';
import checkTestWalletPayLogic from '../../../payProcess/utils/checkTestWalletPayLogic';
import canMakeApplePayPayments from './canMakeApplePayPayments';
import WALLET_PAY_USER_DATA_QUERY from '../../../common/graphql/queries/walletPayUserData.gql';
import useAltMethodsSettings from '../../../common/utils/useAltMethodsSettings';

/**
 * Fetch Apple Pay settings
 * @return {{loading: Boolean, error: String, canMakeApplePayPayments: Boolean|null, settings: Object, country: String, siteName: String}}
 */
export default function useApplePaySettings() {
  const isTestWalletPay = checkTestWalletPayLogic(WALLET_METHODS.APPLE_PAY);
  const apiAvailable = Reflect.has(window, 'ApplePaySession');
  const canMakePayments = useReactiveVar(canMakeApplePayPaymentsVar);
  const {activePackage} = useActivePackage();
  const skip = !isTestWalletPay && !apiAvailable;

  const {settings, loading, error} = useAltMethodsSettings({
    paymentMethod: WALLET_METHODS.APPLE_PAY,
    skip,
  });

  const {
    data: userData,
    loading: userDataLoading,
    error: userDataError,
  } = useQuery(WALLET_PAY_USER_DATA_QUERY, {
    skip,
  });

  let country = null;
  let siteName = null;

  if (userData) {
    ({
      myUser: {
        profile: {
          location: {country},
        },
      },
      site: {name: siteName},
    } = userData);
  }

  const merchantId = settings?.merchantId;

  useEffect(() => {
    if (merchantId && canMakePayments === null) {
      canMakeApplePayPayments(merchantId).then(canMakeApplePayPaymentsVar);
    }
  }, [merchantId, canMakePayments]);

  return {
    loading:
      loading ||
      userDataLoading ||
      Boolean(apiAvailable && settings && canMakePayments === null),
    error: error || userDataError,
    settings,
    country,
    siteName,
    canMakeApplePayPayments: Boolean(
      isTestWalletPay ||
        (apiAvailable &&
          settings &&
          canMakePayments &&
          Boolean(activePackage?.tokenPrice)),
    ),
  };
}
