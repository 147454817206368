import type {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  HTMLAttributes,
  MouseEventHandler,
  MutableRefObject,
} from 'react';
import React, {Fragment, useEffect} from 'react';

import autosize from '@core/utils/autosize';
import useEventCallback from '@core/utils/react/useEventCallback';
import useForwardedRefFallback from '@core/utils/react/useForwardedRefFallback';
import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import baseCss from './TextareaAutosize.css';
import type {TextareaResizeEvent} from './types';

const RESIZE_EVENT_NAME = 'autosize:resized';

export interface TextareaAutosizeProps
  extends Omit<HTMLAttributes<HTMLTextAreaElement>, 'onResize'> {
  onResize?: (event: TextareaResizeEvent) => void;
  onClick?: MouseEventHandler<HTMLElement>;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  maxLength?: number;
  value?: string;
  name?: string;
  trackingName?: string;
  maxRows?: number; // After this count scrollbar will be enabled
  /**
   * min-height equivalent
   * when rows=1 min-height is 40px
   * when rows=2 min-height is 60px
   * when rows=3 min-height is 80px
   * ...
   */
  rows?: number;
  disabled?: boolean;
  textareaRef?: MutableRefObject<HTMLTextAreaElement>;
}

declare global {
  interface HTMLElementEventMap {
    [RESIZE_EVENT_NAME]: TextareaResizeEvent;
  }
}

/**
 * `textarea` wrapper for resizing to fit content.
 */
const TextareaAutosize: FC<TextareaAutosizeProps> = ({
  onResize,
  textareaRef,
  maxRows,
  rows = 1,
  value,
  name,
  trackingName = name,
  maxLength,
  onInput,
  disabled,
  ...props
}) => {
  const ref = useForwardedRefFallback(textareaRef);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    /**
     * Used forked autosize component because of problems with IOS keyboard overlapping.
     * TODO: replace by jackmoore/autosize, when issue #352 would be merged
     */
    autosize(ref.current);

    if (onResize) {
      const textarea = ref.current;
      textarea.addEventListener(RESIZE_EVENT_NAME, onResize);
      return () => textarea.removeEventListener(RESIZE_EVENT_NAME, onResize);
    }
  }, [onResize, ref]);

  useEffect(() => {
    autosize.update(ref.current);
  }, [value, ref]);

  useEffect(() => {
    // Blur for IOS to hide the keyboard when textarea becomes disabled.
    if (disabled && document.activeElement === ref.current) {
      ref.current.blur();
    }
  }, [disabled, ref]);

  // eslint-disable-next-line consistent-return
  const handleInput = useEventCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (ref.current.value.length > maxLength) {
        ref.current.value = ref.current.value.slice(0, maxLength);
      }
      onInput?.(event);
    },
  );

  /**
   * Fixing text area size when restriction mask changes string faster then text area input is changed
   */
  if (value === ref.current?.value) {
    autosize.update(ref.current);
  }

  const maxHeight =
    maxRows && ref.current
      ? parseInt(
          window.getComputedStyle(ref.current).getPropertyValue('line-height'),
          10,
        ) * maxRows
      : null;

  return (
    <Fragment>
      {disabled && (
        // To keep "textarea" clickable even when disabled.
        // eslint-disable-next-line local-rules/tracking-wrapper
        <div
          className={baseCss.disabled}
          onClick={props.onClick}
          role="button"
          tabIndex={0}
        />
      )}
      <AddBabciaUBTracking
        trackingName={
          !disabled && trackingName ? `${trackingName}Textarea` : null
        }
      >
        <textarea
          {...props}
          ref={ref}
          value={value}
          name={name}
          disabled={disabled}
          maxLength={maxLength}
          onInput={maxLength ? handleInput : onInput}
          rows={maxRows ? Math.min(maxRows, rows) : rows}
          style={{maxHeight}}
        />
      </AddBabciaUBTracking>
    </Fragment>
  );
};

export default TextareaAutosize;
