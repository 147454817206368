import {makeVar} from '@apollo/client';

type ErrorData = {
  accessError: string | null;
  accessErrorData: Record<string, any>;
};

const accessErrorVar = makeVar<ErrorData>({
  accessError: null,
  accessErrorData: {},
});

export default accessErrorVar;
