import getUserAgentParser from '@core/utils/getUserAgentParser';

/**
 * Check if is need set position manually
 */
const isVisualViewportOnIOSAvailable = () => {
  const {name} = getUserAgentParser().getOS();

  return name === 'iOS' && Boolean(window.visualViewport);
};

export default isVisualViewportOnIOSAvailable;
