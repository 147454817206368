import React, {forwardRef} from 'react';

import type {CSSModule} from '@core/ui/types';

import type {PopperProps, PopperInner} from './Popper';
import Popper from './Popper';
import PopperPlacement from '../../constants/PopperPlacement';
import PopperTrigger from '../../constants/PopperTrigger';
import baseCss from './Popover.css';

export type PopoverProps = PopperProps;

export type Popper = PopperInner;

/**
 * @class Popover
 * @classdesc Popover helper class to add widgets on page
 */
const Popover = forwardRef<PopperInner, PopoverProps & {css?: CSSModule}>(
  (
    {
      children,
      css = {},
      trackingName,
      placement = PopperPlacement.BOTTOM,
      trigger = PopperTrigger.CLICK,
      spaced = true,
      usePortal = true,
      ...props
    },
    ref,
  ) => (
    <Popper
      ref={ref}
      baseCss={baseCss}
      css={css}
      trackingName={trackingName && `${trackingName}Popover`}
      placement={placement}
      trigger={trigger}
      spaced={spaced}
      usePortal={usePortal}
      {...props}
    >
      {children}
    </Popper>
  ),
);

export default Popover;
