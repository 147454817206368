import type {FC, ReactNode, ReactElement} from 'react';
import React, {Children, isValidElement, cloneElement} from 'react';
import cn from 'classnames';
import get from 'lodash/get';

import Align from '../../constants/Align';
import SpacingSize from '../../constants/SpacingSize';
import SpacingDirection from '../../constants/SpacingDirection';
import BarPosition from '../../constants/BarPosition';
import {Bar} from '../card';
import {Spacing} from '../spacing';
import type {ActionProps} from './Action';
import Action from './Action';
import css from './Actions.css';

export interface ActionsProps {
  children: ReactNode;
  className?: string;
  spacing?: SpacingSize;
  withBar?: boolean; // @todo Useless property. Remove it together with 'position' and 'inverse'
  inverse?: boolean;
  vertical?: boolean;
  fullWidth?: boolean;
  stretch?: boolean;
  align?: Align;
  // Used only together with prop 'withBar'
  position?: BarPosition;
  itemSpacing?: SpacingSize;
  reverse?: boolean;
}

const renderSpacedActions = (
  spacing: SpacingSize,
  oneSidedMargin: boolean,
  actions: JSX.Element,
) => {
  if (spacing === SpacingSize.NONE) return actions;

  return (
    <Spacing
      size={spacing}
      direction={
        oneSidedMargin ? SpacingDirection.VERTICAL : SpacingDirection.BOTH
      }
      oneSidedMargin={oneSidedMargin}
      adaptive={false}
    >
      {actions}
    </Spacing>
  );
};

const renderActions = (
  children: ReactNode,
  vertical: boolean,
  fullWidth: boolean,
  align: string | number,
  className: string,
  itemSpacing: SpacingSize,
  reverse: boolean,
  stretch: boolean,
) => {
  const mappedChildren = Children.map(
    children,
    (child: ReactElement<ActionProps>) => {
      if (!isValidElement(child)) return null;

      if (
        child.type === Action ||
        // Since UI kit is dynamically loaded and all components are wrapped in some sort of proxy component
        // @see src/packages/dating/ui/index.js
        get(child, 'type.type.displayName') === 'DeferredAction'
      ) {
        return cloneElement(child, {
          className: cn(child.props.className, css.action),
          spacing: itemSpacing,
          vertical,
        });
      }

      return (
        <Action
          spacing={itemSpacing}
          vertical={vertical}
          className={css.action}
        >
          {child}
        </Action>
      );
    },
  );

  if (reverse) {
    mappedChildren.reverse();
  }

  return (
    <div
      className={cn(
        css.actions,
        css[align],
        stretch && css.stretch,
        vertical && css.vertical,
        reverse && css.reverse,
        fullWidth && css.fullWidth,
        className,
      )}
    >
      {mappedChildren}
    </div>
  );
};

/**
 * Bottom bar that shows action buttons
 */
const Actions: FC<ActionsProps> = ({
  children,
  className,
  inverse = false,
  spacing = SpacingSize.NONE,
  withBar = false,
  vertical = false,
  fullWidth = false,
  align = Align.RIGHT,
  position = BarPosition.BOTTOM,
  itemSpacing = SpacingSize.NORMAL,
  reverse = false,
  stretch = false,
}) => {
  const actions = renderActions(
    children,
    vertical,
    fullWidth,
    align,
    className,
    itemSpacing,
    reverse,
    stretch,
  );

  if (withBar) {
    return (
      <Bar inverse={inverse} position={position} data-test="actionsBar">
        {renderSpacedActions(spacing, false, actions)}
      </Bar>
    );
  }

  return renderSpacedActions(spacing, true, actions);
};

export default Actions;
