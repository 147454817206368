import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import {useTimer} from 'use-timer';

import dayjs from '@core/date/utils/dayjs';

/**
 * @const {Number}
 */
const MOCK_TIMER = 3600000; // 3600 * 1000(1 hour in milliseconds)

export const TIMER_TYPES = {
  incremental: 'INCREMENTAL',
  decremental: 'DECREMENTAL',
};

export const formatTime = (ms, format) => {
  if (isString(format)) {
    /**
     * Time in milliseconds since 1 january 1970 00:00:00 UTC, formatted by 'format' argument
     */
    return dayjs.utc(ms).format(format);
  }
  return format(ms);
};

const getEndTime = (initialTime, duration, timerType) => {
  if (timerType === TIMER_TYPES.incremental) {
    return initialTime + duration;
  }

  return initialTime - duration;
};

/**
 * Component to count time
 * @param initialTime - starting value for the timer in seconds
 * @param duration - duration of counting in seconds
 * @param interval - value to add to each increment / decrement in milliseconds
 * @param backward - option that allows count down or up
 * @param onTimeOver - callback that will be called after duration time expired.
 * @param onTimeUpdate - callback that calls after each time change
 * @param format - count format
 * @param children
 *
 * <Timer
     initialTime={200}
     duration={100000}
     backward
     onTimeOver={onTimeOver}
     onTimeUpdate={onTimeUpdate}
     interval={3000}
     format='mm:ss'
   >
      {(formattedTime) => {<div>{formattedTime}</div>}}
   </Timer>
 */
const Timer = ({
  initialTime = 0,
  duration,
  interval = 1000,
  backward = false,
  onTimeOver,
  onTimeUpdate,
  format = 'HH:mm:ss',
  children,
}) => {
  const timerType = backward
    ? TIMER_TYPES.decremental
    : TIMER_TYPES.incremental;

  const {time} = useTimer({
    autostart: true,
    initialTime,
    interval,
    onTimeOver,
    onTimeUpdate,
    timerType,
    endTime: duration ? getEndTime(initialTime, duration, timerType) : null,
  });

  const formattedTime = formatTime(
    window.IS_INTEGRATION_TEST_ENVIRONMENT ? MOCK_TIMER : time * 1000,
    format,
  );

  return isFunction(children) ? children(formattedTime) : formattedTime;
};

Timer.propTypes /* remove-proptypes */ = {
  initialTime: PropTypes.number,
  duration: PropTypes.number,
  step: PropTypes.number,
  backward: PropTypes.bool,
  onExpire: PropTypes.func,
  onTick: PropTypes.func,
  format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.func,
};

export default Timer;
