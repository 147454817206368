import {useMemo} from 'react';

import useActivePackage from '../../package/utils/useActivePackage';
import METHODS_WITH_PACKAGE_DESCRIPTION from '../constants/methodsWithPackageDescription';
import usePaymentPackageDescription from './usePaymentPackageDescription';
import {DESCRIPTION_WITH_BILLING_POLICY_SUFFIX} from '../constants/footerRuleSuffixes';

const usePaymentPackageDescriptionRule = () => {
  const {activePackage} = useActivePackage();
  const {packageDescriptions, strictLegalMode, loading} =
    usePaymentPackageDescription();

  const packageDescriptionRule = useMemo(() => {
    if (!activePackage) {
      return null;
    }

    const {packageId, paymentMethod} = activePackage;

    const isMethodWithPackageDescription =
      METHODS_WITH_PACKAGE_DESCRIPTION.includes(paymentMethod);

    if (
      !packageId ||
      !packageDescriptions.length ||
      !isMethodWithPackageDescription
    ) {
      return null;
    }

    // Get active package or package with empty packageList
    return (
      packageDescriptions.find(({packageList}) =>
        packageList.find((id) => Number(id) === packageId),
      ) || packageDescriptions.find(({packageList}) => !packageList.length)
    );
  }, [packageDescriptions, activePackage]);

  return {
    loading,
    strictLegalMode,
    packageDescriptionRule,
    isWithBillingPolicy: Boolean(
      packageDescriptionRule?.footerRule.includes(
        DESCRIPTION_WITH_BILLING_POLICY_SUFFIX,
      ),
    ),
  };
};

export default usePaymentPackageDescriptionRule;
