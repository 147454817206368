import type {CardWalletAdditionalFieldEnum} from '@core/types/graphql';

import {
  getLatestApplePayVersionSupported,
  createApplePayRequest,
} from './applePay';

type ApplePayPaymentRequest = ApplePayJS.ApplePayPaymentRequest;

/**
 * Represents the version of Apple Pay being used in the application.
 *
 * The Apple Pay version is critical for compatibility with different devices
 * and functionalities. This variable should be updated accordingly whenever
 * there is a change in the supported Apple Pay version.
 *
 * Version 11 of Apple Pay JS API is critical for support for billingContact in ApplePayPaymentMethod.
 * https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_on_the_web_version_history/apple_pay_on_the_web_version_10_release_notes
 * The version of Apple Pay available in macOS 11 and iOS 14.
 */
const APPLE_PAY_VERSION = getLatestApplePayVersionSupported();

type CreateApplePayOptions = {
  request: ApplePayJS.ApplePayPaymentRequest;
  params: {
    cardWalletAdditionalFields: CardWalletAdditionalFieldEnum[];
  };
};

/**
 * Function to create an ApplePaySession for handling Apple Pay transactions.
 *
 * @param {ApplePayJS.ApplePayPaymentRequest} request - The payment request object containing details about the transaction.
 * @returns {ApplePaySession} - A new instance of ApplePaySession configured with the provided payment request details.
 *
 * The session will handle the 'onshippingcontactselected' event to process shipping contact details,
 * and complete the shipping contact selection with the provided request's total.
 */
const createApplePaySession = ({
  request,
  params,
}: CreateApplePayOptions): ApplePaySession => {
  const applePaySessionRequest = createApplePayRequest({
    request,
    ...params,
    // type casting used to remove partial effect - we assume that we have complete request already in request param
  }) as ApplePayPaymentRequest;

  const session = new window.ApplePaySession(
    APPLE_PAY_VERSION,
    applePaySessionRequest,
  );

  return session;
};

export default createApplePaySession;
