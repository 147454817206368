import forEach from 'lodash/forEach';

const addEventListener = ({selector, callback, eventType}, pageEvents) => {
  const elements =
    selector === document ? [selector] : document.querySelectorAll(selector);

  if (!elements.length) return;

  forEach(elements, (element) => {
    pageEvents.push({element, callback, eventType});
    element.addEventListener(eventType, callback);
  });
};

const removeEventListeners = (eventsList) => {
  eventsList.forEach(({element, eventType, callback}) => {
    element.removeEventListener(eventType, callback);
  });
};

/**
 * Helper for add global events to Static Page.
 * Becouse we receive it from server.
 * @param {Array} events
 * @param {History} history
 * @param {String} page
 * @return {Function}
 */
const addEventListeners = ({events, history, page}) => {
  const pageEvents = [];

  events.forEach(({callback, ...props}) => {
    addEventListener(
      {
        callback: (event) => {
          callback({event, history, page});
        },
        ...props,
      },
      pageEvents,
    );
  });

  return () => {
    removeEventListeners(pageEvents);
  };
};

export default addEventListeners;
