import {getClientInstance} from '@core/graphql/client';

import MESSENGER_ACTIVE_DIALOG_ID_QUERY from '../graphql/queries/messengerActiveDialogId.gql';
import type {
  MessengerActiveDialogIdQuery,
  MessengerActiveDialogIdQueryVariables,
} from '../graphql/queries/messengerActiveDialogId';

/**
 * These functions help to keep active dialog messages
 * when invalidating others {@see CachePersistManager.restorePersistedCache}.
 * DO NOT USE FOR OTHER PURPOSES!!!
 */

export const getActiveDialogId = () =>
  getClientInstance().readQuery<MessengerActiveDialogIdQuery>({
    query: MESSENGER_ACTIVE_DIALOG_ID_QUERY,
  })?.messengerActiveDialogId;

export const setActiveDialogId = (id: string): void => {
  getClientInstance().writeQuery<
    MessengerActiveDialogIdQuery,
    MessengerActiveDialogIdQueryVariables
  >({
    query: MESSENGER_ACTIVE_DIALOG_ID_QUERY,
    data: {messengerActiveDialogId: id},
    broadcast: false,
  });
};
