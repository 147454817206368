import type {FieldMergeFunction} from '@apollo/client';

import {mergeArrayFieldUniq as merge} from '../utils/mergeArrayField';

const resultsWriter: FieldMergeFunction = (existing, incoming) => {
  if (existing && incoming) {
    return merge(existing, incoming);
  }

  return incoming;
};

export default {
  DiscreetProfile: {
    keyFields: [],
    fields: {
      users: {
        keyArgs: [],
        merge: resultsWriter,
      },
      opened: {
        keyArgs: [],
        merge: resultsWriter,
      },
    },
  },
};
