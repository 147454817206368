import scrollIntoView from 'scroll-into-view-if-needed';

import {HEADER_ID} from '@core/header/constants/header';
import {POPUP_ACTIONS_ID} from '@core/popup/constants/popup';

// The minimum padding between the popup and the edge of the display.
const EXTERNAL_INDENT_AT_POPUP = 10;

// Minimal scrolling error for correct display on multi-theme
const SCROLLING_ERROR = 10;

type ElementWithType = Element & {type: string};

/**
 * Provides distance to scroll down
 * if textarea overlapped by toolbar or keyboards
 */
const getDistanceToScroll = (centeredShift: number): number => {
  const {activeElement} = window.document;
  const header = window.document.getElementById(HEADER_ID) as HTMLElement;

  // Height HEADER_ID with indents
  const headerHeight = header.offsetHeight;

  /**
   * getThemeFeature cannot be used here,
   * had to use a crutch
   */
  const {top, height: headerBoundaryHeight} = header.getBoundingClientRect();
  const isHeaderPositionBottom = top !== 0;

  const textAreaBoundary = activeElement.getBoundingClientRect();
  const textAreaRowHeight = parseInt(
    window.getComputedStyle(activeElement).getPropertyValue('height'),
    10,
  );

  /**
   * Actions in the popup can overlap the textarea,
   * so need to take into account the height of the action.
   */
  const getFixedActionsHeight = (): number => {
    const node = window.document.getElementById(POPUP_ACTIONS_ID);

    if (node) {
      return node.clientHeight + EXTERNAL_INDENT_AT_POPUP;
    }

    return 0;
  };

  /**
   * If the header is at the bottom, need to take this into account.
   * 'textAreaRowHeight * 1.5' - it is necessary that the entire textarea
   * with the description is visible.
   */
  const deviationHeight =
    getFixedActionsHeight() === 0 && isHeaderPositionBottom
      ? headerHeight + SCROLLING_ERROR + textAreaRowHeight * 1.5
      : textAreaRowHeight * 1.5;

  textAreaBoundary.y =
    textAreaBoundary.y || textAreaBoundary.top + deviationHeight;

  let distanceToScroll = 0;

  if (textAreaBoundary.y > document.documentElement.clientHeight) {
    distanceToScroll = centeredShift;
  }

  if (
    textAreaBoundary.y +
      textAreaBoundary.height -
      textAreaRowHeight +
      distanceToScroll <
    headerBoundaryHeight
  ) {
    distanceToScroll =
      textAreaBoundary.y +
      textAreaBoundary.height -
      textAreaRowHeight +
      distanceToScroll -
      headerBoundaryHeight;
  }

  return distanceToScroll + getFixedActionsHeight();
};

/**
 * Scrolls to textarea
 * @param {string} elementType - dom element type
 */
const centerViewOnActiveElement = (elementType: string): void => {
  const {activeElement} = window.document;

  /*
    type is not a part of api for all elements, to avoid enumeration of all possible elements
  */
  if ((activeElement as ElementWithType).type !== elementType) {
    return;
  }

  const canSmoothScroll =
    !window.IS_INTEGRATION_TEST_ENVIRONMENT &&
    'scrollBehavior' in document.body.style;
  scrollIntoView(activeElement, {
    behavior: (items) => {
      items.forEach(({el, top}) => {
        const shiftToCenterElement = top - el.scrollTop;
        const newScrollPosition =
          el.scrollTop + getDistanceToScroll(shiftToCenterElement);
        if (el.scroll && canSmoothScroll) {
          el.scroll({top: newScrollPosition});
        } else {
          el.scrollTop = newScrollPosition;
        }
      });
    },
  });
};

export default centerViewOnActiveElement;
