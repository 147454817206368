import type {ReactElement, ReactNode} from 'react';
import React from 'react';
import flattenDeep from 'lodash/flattenDeep';

export type ReactParams = {
  [key: string]: ReactElement;
};

const findTag = (
  text: string,
): null | {
  start: number;
  openingTag: string;
  content: string;
  closingTag: string;
  end: number;
} => {
  const found = text.match(/({([^}/]+?)})((.*?)({\/\2}))?/);
  if (!found) {
    return null;
  }
  const [wholeTag, openingTag, , , content, closingTag] = found;
  const start = found.index;
  const end = start + wholeTag.length;
  return {start, openingTag, content, closingTag, end};
};

const replaceParams = (
  text: string,
  params: ReactParams,
  allowHtml = false,
): ReactNode => {
  let key = 0;

  const reduce = (arr: ReactNode[]): ReactNode => {
    if (!(arr instanceof Array)) {
      return arr;
    }

    let res = [];
    let i = 0;
    flattenDeep(arr)
      .filter(Boolean)
      .forEach((e) => {
        if (typeof e === 'object') {
          if (res[i]) {
            i++;
          }
          res[i] = e;
          i++;
          return;
        }

        res[i] = res[i] || '';
        res[i] += e;
      });

    if (allowHtml) {
      res = res.map((elm) => {
        if (typeof elm !== 'string') {
          return elm;
        }
        return <span key={++key} dangerouslySetInnerHTML={{__html: elm}} />;
      });
    }

    return res.length <= 1 ? res[0] || '' : res;
  };

  const replace = (textStr?: string, parameters?: ReactParams): ReactNode[] => {
    if (!textStr) {
      return [];
    }

    const tag = findTag(textStr);
    if (!tag) {
      return [textStr];
    }
    key++;

    const {start, openingTag, content, closingTag, end} = tag;

    const children = content && replace(content, parameters);
    const replacement =
      openingTag in parameters
        ? [
            typeof content === 'string'
              ? React.cloneElement(
                  parameters[openingTag],
                  {key},
                  reduce(children),
                )
              : React.cloneElement(parameters[openingTag], {key}),
          ]
        : [openingTag, children, closingTag].filter(Boolean);

    return [
      textStr.slice(0, start),
      ...replacement,
      ...replace(textStr.slice(end), parameters),
    ];
  };

  return reduce(replace(text, params));
};

export default replaceParams;
