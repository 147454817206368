enum Place {
  SEARCH = 'search', // Search widget buttons, separated with divider
  SEARCH_WEBCAM = 'searchWebcam',
  DEFAULT = 'default',
  ACTIVITY = 'activity',
  ACTIVE_DIALOGS = 'activeDialogs',
  USER_INFO = 'userInfo',
  BLOCKED_REPORTED = 'blockedReported',
  USER_TOOLBAR_SWITCHABLE = 'userToolbarSwitchable', // For minimized toolbar with round buttons without text(mob site)
  USER_TOOLBAR = 'userToolbar',
  FAVORITES = 'favorites', // Favorites page
  GALLERY = 'gallery',
  LIKE_GALLERY = 'likeGallery',
  TARGET_USER_INFO_GALLERY = 'targetUserInfoGallery',
  TARGET_USER_INFO_GALLERY_STICKED = 'targetUserInfoGallerySticked',
  TARGET_USER_INFO_GALLERY_FLOATING = 'targetUserInfoGalleryFloating',
  TARGET_USER_INFO_BOTTOM = 'targetUserInfoBottom',
  TARGET_USER_INFO_BOTTOM_IN_CARD = 'targetUserInfoBottomInCard',
  TARGET_USER_INFO_WITH_STORIES = 'targetUserInfoWithStories',
  SIMILAR_PROFILE = 'similarProfile',
}

export default Place;
