import {EMPTY, fromEvent, merge, Observable} from 'rxjs';
import {debounceTime, concatMap, filter, startWith} from 'rxjs/operators';

export const DEBOUNCE_RESIZE_DELAY = 300;

class PopupOverflowService {
  /**
   * Container for Popup content.
   * Used to determine whether its content is overflowing
   */
  private popupScrollableElement: HTMLElement | null = null;

  /**
   * Observer that emits when Popup content is changed and overflow needs to be recalculated.
   * E.g. Accordion expands or dynamically added content.
   */
  private popupContentObservable: Observable<void> | null = null;

  /**
   * Popup overflowing stream. Emits on every window resize event.
   * Emits true if Popup content is overflowing, otherwise emits false.
   * By default is EMPTY.
   * Initializes only after popupScrollableElement and popupContentObservable are set
   */
  public isOverflowingObservable: Observable<boolean> = EMPTY;

  /**
   * @see PopupLayout
   */
  public setPopupScrollableElement(element: HTMLElement): void {
    this.popupScrollableElement = element;
  }

  /**
   * Transforms MutationObserver to Observer.
   * @see PopupFrame
   */
  public setPopupContentMutationObservable(element: HTMLElement): void {
    this.popupContentObservable = new Observable<void>((observer) => {
      const mutation = new MutationObserver(() => {
        observer.next();
      });

      mutation.observe(element, {childList: true, subtree: true});

      return () => mutation.disconnect();
    });

    this.initialize();
  }

  private initialize(): void {
    this.isOverflowingObservable = merge(
      fromEvent(window, 'resize').pipe(debounceTime(DEBOUNCE_RESIZE_DELAY)),
      this.popupContentObservable || EMPTY,
    ).pipe(
      startWith(null),
      filter(() => Boolean(this.popupScrollableElement)),
      concatMap(() => this.checkPopupOverflowing()),
    );
  }

  /**
   * Main function that measures Popup overflowing.
   */
  private checkPopupOverflowing(): Promise<boolean> {
    /**
     * Wrap into promise and timeout since we should "jump from callstack"
     * since we get wrong calculations of "clientHeight" and "scrollHeight".
     */
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.popupScrollableElement) {
          const height = this.popupScrollableElement.clientHeight;
          const {scrollHeight} = this.popupScrollableElement;
          resolve(height < scrollHeight);
        } else {
          resolve(false);
        }
      }, 0);
    });
  }
}

export default new PopupOverflowService();
