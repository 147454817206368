/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/WebSocket#constants
 */
const WS_READY_STATE = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

export default WS_READY_STATE;
